import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import "./index.css";
import OrderDetails from "../../orders/order-details/OrderDetails";

const PosOrders = (props) => {
  const { rolePermission } = props;

  return (
    <>
      <div>
        <div>
          <div className="merchant_list_inner_title_div">
            <div className="add_merchant_list settlement_order_upper_div">
              <h3 className="merchant_list_inner_title">Order List</h3>
            </div>
          </div>
          <TableContainer className="table_container">
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Payment Type</TableCell>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>Tip Amount</TableCell>
                  <TableCell> Customer Name</TableCell>
                  <TableCell>Customer Email</TableCell>
                  <TableCell>Customer Phone</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row merchant_list_tbl_body">
                {props.data?.transactionData?.map((row, index) => (
                  <TableRow style={{ background: "none" }}>
                    <TableCell>
                      <OrderDetails
                        order_id={row._id}
                        display_order={row.order_id}
                        className={`table_id_data`}
                        rolePermission={rolePermission}
                        from="settlement"
                      />
                    </TableCell>

                    <TableCell>{row.type}</TableCell>

                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{row.tip_amount}</TableCell>

                    <TableCell>{row.from}</TableCell>
                    <TableCell>{row.fromEmail}</TableCell>
                    <TableCell>{row.fromMobileNumber}</TableCell>

                    <TableCell>{row.status}</TableCell>

                    <TableCell>
                      {moment(row.createdAt).format("YYYY/MM/DD hh:mm A")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {!props.data?.transactionData?.length && (
              <div className="no_data_found loader_data_tbl">
                <p>No Data Found</p>
              </div>
            )}
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default PosOrders;
