import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "./datatable.css";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AxiosBase from "../../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Filter from "../../filter-component/Filter";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { GetAllKyc } from "../../../networkRequest/request/kyc";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import CloseRounded from "@mui/icons-material/CloseRounded";
import Tooltip from "@mui/material/Tooltip";
import NavbarHoc from "../../../pages/navhoc/index";
import Config from "../../../config.json";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    border: "1px",
    borderRadius: "5px",
    font: "9px solid  #333333",
    fontWeight: 500,
  },
});
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});

export default function KycDataTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [count, setCount] = useState(0);
  //api data state
  const [userdata, setData] = React.useState([]);
  const [searchBy, setSearch] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [selectedmerchant, setselectedmerchant] = React.useState(null);
  const [searchByCalled, setsearchByCalled] = React.useState(false);
  const [rolePermission, setRolePermission] = React.useState([]);

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severiety: "success",
  });

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: "", severiety: "success" });
  };

  const handleAction = async () => {
    try {
      let isverified = action.action;
      if (!comments) {
        setSnackbar({
          open: true,
          message: "Comments are required",
          severiety: "error",
        });
        return false;
      }
      if (action.action == true) {
        setSnackbar({
          open: true,
          message: "Kyc is approved",
          severiety: "success",
        });
      } else {
        setSnackbar({
          open: true,
          message: "Kyc is rejected",
          severiety: "error",
        });
      }
      await AxiosBase.post(`/admin/kyc/change-kyc-status/${action.id}`, {
        isverified,
        comments,
      });
      const newUserData = { ...userdata };
      const data = userdata.map((d) => {
        if (d._id === action.id) {
          if (isverified) {
            return {
              ...d,
              isverified: true,
              tradeLicenseVerified: "Approved",
              emiratesIdVerified: "Approved",
              kycStatus: "Approved",
            };
          }
          return {
            ...d,
            isverified: true,
            tradeLicenseVerified: "Rejected",
            emiratesIdVerified: "Rejected",
            kycStatus: "Rejected",
          };
        }
        return d;
      });
      setData(data);
      handleCloseDialog();
    } catch (error) {
      console.log(error);
    }
  };
  const [action, setAction] = useState({
    id: "",
    action: "",
  });
  const [comments, setcomments] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  //filter
  const [filter, setFilter] = React.useState([
    { key: "", type: "", value: "" },
  ]);
  const filterOptons = [
    { property: "ID", value: "requestId" },
    { property: "Name", value: "full_name" },
    { property: "Number", value: "customersData.mobile_no" },
    { property: "Status", value: "kycStatus" },
  ];
  const filterContains = [
    { property: "Is", value: "is" },
    { property: "Like", value: "start_with" },
    { property: "Not Like", value: "not_contain" },
  ];

  const handleSearchButton = (e) => {
    setPage(0);
    setFilter([{ key: "", type: "", value: "" }]);
    setsearchByCalled(!searchByCalled);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.key === "Enter") {
      setPage(0);
      setFilter([{ key: "", type: "", value: "" }]);
      setsearchByCalled(!searchByCalled);
    }
  };

  const ResetFilter = async (event) => {
    setFilter([{ key: "", type: "", value: "" }]);
  };
  const handleFilter = async (JSONfilter) => {
    setFilter(JSONfilter);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const pophandleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const pophandleClose = () => {
    setAnchorE2(null);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setcomments("");
    setAction({
      id: "",
      action: "",
    });
    ResetFilter();
  };

  const handleCommentsChanges = (e) => {
    try {
      if (e.target.value.charAt(0) != " ") {
        setcomments(e.target.value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCommentsAction = (id, action) => {
    setAction({
      id: id,
      action: action,
    });
    setOpenDialog(true);
  };
  const openpop = Boolean(anchorE2);
  const id1 = openpop ? "simple-popover" : undefined;
  useEffect(() => {
    const filtersval = filter[0].key != "" ? JSON.stringify(filter) : "";
    GetAllKyc(page, rowsPerPage, searchBy, filtersval).then((response) => {
      setData(response.data);
      setCount(response.count);
      setLoading(false);
    });
  }, [searchByCalled, page, rowsPerPage, filter]);

  return (
    <NavbarHoc
      id={Config.PermissionId.KYC}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <Box>
        <h1 className="merchant_title">KYC Request List</h1>
      </Box>

      <div className="merchant_list_main_div">
        <div className="merchant_list_inner_title_div">
          <div className="merchant_list_search_filter">
            <Grid
              item
              xs={12}
              // sm={12}
              md={12}
              lg={12}
              // xl={4}
              className="merchant_grid_sec"
            >
              <div
                className="merchant_list_filter_div"
                style={{ height: "100%" }}
              >
                <CssTextField
                  className="serachBar"
                  id="new-menu"
                  placeholder="Search"
                  variant="outlined"
                  // fullWidth="false"
                  onKeyUp={handleSearch}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton className="merchant_search_icon">
                          <i
                            onClick={handleSearchButton}
                            class="fa fa-search"
                            aria-hidden="true"
                          ></i>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Tooltip title="Filter records">
                  <div>
                    <i
                      style={{
                        fontSize: "30px",
                        marginLeft: "10px",
                        color: "#757575",
                        cursor: "pointer",
                      }}
                      onClick={pophandleClick}
                      class="fa fa-filter"
                      aria-hidden="true"
                    ></i>
                  </div>
                </Tooltip>

                {filter[0].key != "" ? (
                  <Tooltip title="Refresh to records">
                    <div>
                      <i
                        style={{
                          fontSize: "30px",
                          marginLeft: "10px",
                          color: "#757575",
                          cursor: "pointer",
                        }}
                        onClick={ResetFilter}
                        class="fa fa-refresh"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </div>
            </Grid>
          </div>
        </div>

        <TableContainer
          className={
            userdata.length > 0 && !loading
              ? "table_container"
              : "table_container table_container_nodata"
          }
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="data_tbl_head_row">
              <TableRow style={{ background: "none" }}>
                <TableCell>Request ID</TableCell>

                <TableCell>Merchant Name</TableCell>

                <TableCell>Merchant Number</TableCell>

                <TableCell>Created on</TableCell>

                <TableCell>Status</TableCell>

                {/* <TableCell>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody className="data_tbl_body_row ">
              {userdata != " " &&
                userdata.map((row, index) => {
                  return (
                    <TableRow
                      style={{ background: "none" }}
                      className="order_list_data_body"
                      key={index}
                    >
                      <TableCell className="table_id_data">
                        {rolePermission.includes("EDIT") ||
                        rolePermission.includes("VIEW") ||
                        rolePermission.includes("APPROVE") ||
                        rolePermission.includes("REJECT") ? (
                          <Link
                            to={`/kyc/details?kycId=${row._id}&customerId=${row.customer_id}`}
                            className="tbl_id"
                          >
                            {row?._id}
                          </Link>
                        ) : (
                          row?._id
                        )}
                      </TableCell>

                      <TableCell>
                        {row?.customersData?.first_name}{" "}
                        {row?.customersData?.last_name}
                      </TableCell>

                      <TableCell>
                        {checkphone(row?.customersData?.mobile_no)}
                      </TableCell>

                      <TableCell>
                        {moment(row?.createdAt).format("YYYY/MM/DD hh:mm A")}
                      </TableCell>

                      <TableCell>
                        <KycStatus status={row?.kycStatus} />
                      </TableCell>

                      {/* <TableCell>
                        <div style={{ display: "flex" }}>
                         {rolePermission.includes("REJECT") && <Button
                            className="btn-action"
                            variant="contained"
                            startIcon={
                              <CancelIcon
                                style={{
                                  backgroundColor: [
                                    "Approved",
                                    "Rejected",
                                    "Expired",
                                  ].includes(row?.kycStatus)
                                    ? "#c0c0c0"
                                    : "#ff0000",
                                  borderRadius: "50%",
                                  marginRight: "-10px",
                                }}
                              />
                            }
                            disabled={[
                              "Approved",
                              "Rejected",
                              "Expired",
                            ].includes(row?.kycStatus)}
                            style={{
                              backgroundColor: [
                                "Approved",
                                "Rejected",
                                "Expired",
                              ].includes(row?.kycStatus)
                                ? "#0000001a"
                                : "rgba(255, 0, 0, 0.3)",
                              width: "10px",
                              width: "5px",
                              marginRight: "5px",
                            }}
                            onClick={
                              () => handleCommentsAction(row?._id, false)
                            }
                          ></Button>}

                         {rolePermission.includes("APPROVE") && <Button
                            className="btn-action"
                            variant="contained"
                            startIcon={
                              <CheckCircleOutlineIcon
                                style={{
                                  backgroundColor: [
                                    "Approved",
                                    "Rejected",
                                    "Expired",
                                  ].includes(row?.kycStatus)
                                    ? "#c0c0c0"
                                    : "#2ed06d",
                                  borderRadius: "50%",
                                  marginRight: "-10px",
                                }}
                              />
                            }
                            disabled={[
                              "Approved",
                              "Rejected",
                              "Expired",
                            ].includes(row?.kycStatus)}
                            style={{
                              backgroundColor: [
                                "Approved",
                                "Rejected",
                                "Expired",
                              ].includes(row?.kycStatus)
                                ? "#0000001a"
                                : "rgba(46, 208, 109, 0.25)",
                              width: "10px",
                              marginRight: "10px",
                            }}
                            onClick={() => handleCommentsAction(row?._id, true)}
                          ></Button>}
                        </div>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {loading ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress />
            </div>
          ) : userdata == "" ? (
            <div className="no_data_found loader_data_tbl">
              <p>No Data Found</p>
            </div>
          ) : (
            ""
          )}
        </TableContainer>
        {!loading ? (
          <TablePagination
            component="div"
            rowsPerPageOptions={[25, 50, 100]}
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          <></>
        )}

        {snackbar.message && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbar.severiety}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        )}

        {
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Please add your review comments:</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="comments"
                type="text"
                fullWidth
                variant="standard"
                value={comments}
                onChange={handleCommentsChanges}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button onClick={handleAction}>submit</Button>
            </DialogActions>
          </Dialog>
        }
      </div>
      <div>
        <Popover
          id={id1}
          open={openpop}
          anchorEl={anchorE2}
          onClose={pophandleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ marginLeft: "135px", height: "500px", marginTop: "10px" }}
        >
          <Typography sx={{ p: 2 }}>
            <Filter
              filter1={handleFilter}
              pophandleClose={pophandleClose}
              optionns={filterOptons}
              contains={filterContains}
              filter={filter}
            />
          </Typography>
        </Popover>
      </div>
    </NavbarHoc>
  );
}

function checkphone(val) {
  if (!val) {
    return "NA";
  }
  if (val?.charAt(0) == "+") {
    return checklenght(val, 14);
  } else {
    return "+" + checklenght(val, 14);
  }
}
function checklenght(val, lenght) {
  if (val?.length > lenght) {
    return val?.substring(0, lenght) + "..";
  }
  if (!val) {
    return "NA";
  }
  return val;
}

export const KycStatus = (props) => {
  const statusColor = (status) => {
    switch (status) {
      case "Approved":
        return "#2ed06d";
      case "Initiated":
        return "#1058ff";
      case "In progress":
        return "#e3b123";
      case "Expired":
        return "#ff0000";
      default:
        return "#ff0000";
    }
  };
  return (
    <p
      style={{
        color: statusColor(props.status),
        fontSize: "inherit",
        ...props.style,
      }}
    >
      {props.status}
    </p>
  );
};
