import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import AxiosBase from "../../../networkRequest/axiosBase";

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Custodian = (props) => {
  const { rolePermission, userId } = props;
  const [user, setUser] = useState({
    is_first_custodian: false,
    is_second_custodian: false,
  });
  const history = useNavigate();

  const getCustodian = async () => {
    try {
      const resp = await AxiosBase.get(`/admin/kek/custodian/config/${userId}`);
      setUser(resp.data.data);
    } catch (error) {
      toast(error?.response?.data?.message || "Something went wrong!", {
        type: "error",
        autoClose: 4000,
      });
    }
  };

  useEffect(() => {
    getCustodian();
  }, []);

  const saveData = async () => {
    try {
      const resp = await AxiosBase.post(
        `/admin/kek/custodian/assign/${userId}`,
        user
      );
      console.log(resp);
      toast(resp.data.message || "User Updated successfully", {
        type: "success",
        autoClose: 2000,
      });
      // history(`/users`);
    } catch (error) {
      console.log(error);
      toast(error?.response?.data?.message || "Something went wrong!", {
        type: "error",
        autoClose: 4000,
      });
    }
  };

  return (
    <>
      <div className="user_information_main_div">
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid item xs={4}>
            <p>First Custodian</p>
          </Grid>
          <Grid item xs={8} style={{ display: "flex" }}>
            <IOSSwitch
              sx={{ m: 1 }}
              checked={user.is_first_custodian}
              disabled={!rolePermission.includes("EDIT")}
              onChange={() => {
                setUser({
                  ...user,
                  is_first_custodian: !user.is_first_custodian,
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <p>Second Custodian</p>
          </Grid>
          <Grid item xs={8} style={{ display: "flex" }}>
            <IOSSwitch
              sx={{ m: 1 }}
              checked={user.is_second_custodian}
              disabled={!rolePermission.includes("EDIT")}
              onChange={() => {
                setUser({
                  ...user,
                  is_second_custodian: !user.is_second_custodian,
                });
              }}
            />
          </Grid>

          {rolePermission.includes("EDIT") && (
            <Grid item xs={12}>
              <div style={{ hight: "30px" }}></div>
              <div className="merchant_edit_button_dev Fees_Margin_save_cancel_btn">
                <Button
                  className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={() => {
                    history("/users");
                  }}
                >
                  Cancel
                </Button>

                <Button
                  className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={saveData}
                >
                  Save
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};

export default Custodian;
