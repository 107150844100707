import React from "react";
import { useState, useEffect } from "react";
import "./StatusCodeMapping.css";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const MCssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
    background: "white",
  },
});

const StatusCodeMapping = (props) => {
  const {
    rolePermission,
    allStatusCode,
    id,
    allStatusCodeMapping,
    AddAcquirerProp,
    UpdateAcquirerProp,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [innerLoader, setInnerLoader] = React.useState(false);
  const [statusCode, setStatusCode] = React.useState([]);
  const [controlDisable, setControlDisable] = React.useState(true);
  const [statusCodeMapping, setStatusCodeMapping] = React.useState([]);
  const [addCode, setAddCode] = React.useState({
    status_code: "",
    aq_code: "",
    trn_code: "",
    desc: "",
  });

  React.useEffect(() => {
    CheckAcquirer();
  }, [allStatusCode, allStatusCodeMapping]);

  const CheckAcquirer = async () => {
    allStatusCode && setStatusCode(allStatusCode);
    allStatusCodeMapping &&
      setStatusCodeMapping(
        allStatusCodeMapping?.status_mapping
          ? JSON.parse(JSON.stringify(allStatusCodeMapping.status_mapping))
          : []
      );
    setLoader(false);
    setInnerLoader(false);
    setOpen(false);
    setAddCode({
      status_code: "",
      aq_code: "",
      trn_code: "",
      status_id: "",
      desc: "",
    });
  };

  const CancelModal = () => {
    setOpen(false);
    setAddCode({
      status_code: "",
      aq_code: "",
      trn_code: "",
      status_id: "",
      desc: "",
    });
  };

  const SaveModal = () => {
    if (addCode?.status_code !== "") {
      setInnerLoader(true);
      let data = allStatusCodeMapping;
      data.status_mapping.push(addCode);
      UpdateAcquirerProp(data, data._id);
    } else {
      toast("Status Code Are Mandatory.", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };

  const UpdateData = () => {
    let data = allStatusCodeMapping;
    data.status_mapping = [...statusCodeMapping];
    UpdateAcquirerProp(data, data._id);
  };

  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="StatusCodeMapping">
          <Grid
            className="BasicStatusCodeMapping"
            container
            spacing={2}
            justifyContent="flex-end"
          >
            {rolePermission.includes("ADD") && (
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                className="merchant_list_add_new_merchant"
                style={{ marginRight: "29px" }}
                variant="contained"
              >
                Add
              </Button>
            )}
          </Grid>
          <TableContainer
            component={Paper}
            style={{ border: "1px solid #e0e0e0" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Status Code
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Acquirer Code
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statusCodeMapping.map((row, index) => (
                  <TableRow
                    // key={ro}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={row?.status_code + "=" + row?.status_id}
                          disabled={controlDisable}
                          label=""
                          placeholder="Select Status"
                          onChange={(e) => {
                            let data = statusCodeMapping;
                            data[index].status_code =
                              e.target.value.split("=")[0];
                            data[index].status_id =
                              e.target.value.split("=")[1];
                            setStatusCodeMapping([...data]);
                          }}
                        >
                          {statusCode.map((innerRow) => {
                            return (
                              <MenuItem
                                value={innerRow.code + "=" + innerRow._id}
                              >
                                {innerRow.abbreviation}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <CssTextField
                        className=""
                        id="new-menu"
                        placeholder="Code"
                        variant="outlined"
                        fullWidth
                        value={row.aq_code}
                        onChange={(e) => {
                          let data = statusCodeMapping;
                          data[index].aq_code = e.target.value;
                          setStatusCodeMapping([...data]);
                        }}
                        disabled={controlDisable}
                      />
                    </TableCell>
                    <TableCell>
                      <CssTextField
                        className=""
                        id="new-menu"
                        placeholder="Code"
                        variant="outlined"
                        fullWidth
                        value={row.desc}
                        onChange={(e) => {
                          let data = statusCodeMapping;
                          data[index].desc = e.target.value;
                          setStatusCodeMapping([...data]);
                        }}
                        disabled={controlDisable}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {statusCodeMapping?.length !== 0 && (
            <>
              {!id ? (
                <>
                  <Grid
                    className="BasicStatusCodeMapping"
                    container
                    spacing={2}
                    justifyContent="flex-end"
                  >
                    <Button
                      onClick={UpdateData}
                      className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                      style={{ marginRight: "29px" }}
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid
                  className="BasicStatusCodeMapping"
                  container
                  spacing={2}
                  justifyContent="flex-end"
                >
                  {!controlDisable ? (
                    <>
                      <Button
                        onClick={UpdateData}
                        className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                        style={{ marginRight: "29px" }}
                        variant="contained"
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          CheckAcquirer();
                          setControlDisable(true);
                        }}
                        className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      {rolePermission.includes("EDIT") && (
                        <Button
                          onClick={() => {
                            setControlDisable(false);
                          }}
                          className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                          style={{ marginRight: "29px" }}
                          variant="contained"
                        >
                          Edit
                        </Button>
                      )}
                    </>
                  )}
                </Grid>
              )}
            </>
          )}
          <Modal open={open} onClose={CancelModal}>
            <div className="ModalStatusMapping">
              <div className="ModalHeader">Map Status Code</div>
              {innerLoader ? (
                <div className="no_data_found loader_data_tbl">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div className="ModalBody">
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      className=""
                      style={{ marginTop: "-7%" }}
                    >
                      <p className="ModalTitle">
                        Status Code <span style={{ color: "red" }}>*</span>
                      </p>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label=""
                          placeholder="Select Status"
                          value={
                            addCode?.status_code + "=" + addCode?.status_id
                          }
                          onChange={(e) => {
                            setAddCode({
                              ...addCode,
                              status_code: e.target.value.split("=")[0],
                              status_id: e.target.value.split("=")[1],
                            });
                          }}
                        >
                          {statusCode.map((innerRow) => {
                            return (
                              <MenuItem
                                value={innerRow.code + "=" + innerRow._id}
                              >
                                {innerRow.abbreviation}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
                      <p className="ModalTitle">Acquirer Code</p>
                      <MCssTextField
                        className=""
                        id="new-menu"
                        placeholder="Acquirer Code"
                        variant="outlined"
                        fullWidth
                        value={addCode?.aq_code}
                        onChange={(e) => {
                          setAddCode({ ...addCode, aq_code: e.target.value });
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
                      <p className="ModalTitle">Description</p>
                      <MCssTextField
                        className=""
                        id="new-menu"
                        placeholder="Description"
                        variant="outlined"
                        fullWidth
                        value={addCode?.desc}
                        onChange={(e) => {
                          setAddCode({ ...addCode, desc: e.target.value });
                        }}
                      />
                    </Grid>
                  </div>
                  <div className="ModalFooter">
                    <Button
                      onClick={SaveModal}
                      className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                      style={{ marginRight: "14px" }}
                      variant="contained"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={CancelModal}
                      className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default StatusCodeMapping;
