import React from "react";
import { Route, Routes } from "react-router-dom";
import OrderList from "../../Components/orders/order-list/OrderList";
import Topheader from "../../universal-components/header/topHeader";

function Orders() {
  return (
    <div style={{ padding: "15px" }}>
      <Topheader />
      <OrderList />
    </div>
  );
}

export default Orders;
