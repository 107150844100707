import React, { useState, useEffect } from "react";
import AxiosBase from "../../../networkRequest/axiosBase";
import { useParams } from "react-router-dom";
import { Box, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import { unstable_styleFunctionSx } from "@mui/system";
import ListItem from "@mui/material/ListItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./EditMerchant.css";
import TextField from "@mui/material/TextField";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// side bar
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
//side bar table
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import NavbarHoc from "../../../pages/navhoc/index";
import Config from "../../../config.json";
//side bar table code
function createData(name) {
  return {
    name,
  };
}

const rows = [
  createData("Cupcake"),
  createData("Donut"),
  createData("Eclair"),
  createData("Frozen yoghurt"),
  createData("Gingerbread"),
  createData("Honeycomb"),
  createData("Ice cream sandwich"),
  createData("Jelly Bean"),
  createData("KitKat"),
  createData("Lollipop"),
  createData("Marshmallow"),
  createData("Nougat"),
  createData("Oreo"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//side bar table code end

const Div = styled("div")(unstable_styleFunctionSx);
const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "2px",
  },
});

// main function

const EditMerchant = (props) => {
  const id = useParams();
  const [data, setData] = React.useState([props.rows]);

  // side bar table code
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [alrt, setAlrt] = React.useState(true);
  const [merchantList, setMerchantlist] = React.useState([]);
  const [listIItem, setListIItem] = React.useState([]);
  const [rolePermission, setRolePermission] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const success = () => {
    return toast("Merchant Successfully Updated", {
      type: "success",
      autoClose: 2000,
    });
  };

  // side bar table code end

  // side bar merchant edit
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    AxiosBase.get("admin/merchant").then((response) => {
      setMerchantlist(response?.data?.data?.data);
    });
  }, []);

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
        overflowX: "hidden",
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem className="merchant_edit_drower_menu_top_sec">
          <p>
            Add Mapping
            <span>{` ( Hindustan Unilever)`}</span>
          </p>
        </ListItem>
      </List>
      <Divider />
      <List>
        <CssTextField
          className="merchant_edit_drower_serch_field"
          id="new-menu"
          placeholder="search"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment className="merchant_edit_drawer_search_icon">
                <IconButton>
                  <img src="/search.svg" alt="" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </List>
      <List className="merchant_edit_drower_menu_body_sec">
        <div className="drawer_merchant_tag">
          <p>Merchant:</p>
          <div style={{ marginRight: "2rem", alignItems: "center" }}>
            <IconButton>
              <AddCircleOutlineIcon />
            </IconButton>
            <Link
              className="merchant_edit_addmerchnt_txt"
              to={`/merchants/createmerchant`}
            >
              Add New Merchant
            </Link>
          </div>
        </div>
        <div>
          <Box>
            <TableContainer className="merchant_edit_drawer_tbl">
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          className="merchant_edit_drawer_tblBody_row"
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                          <Div className="merchant_edit_drawer_tbl_list_box">
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.name}
                            </TableCell>
                          </Div>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ borderBottom: "0.5px solid #C9C9C9" }}>
              <div className="merchant_edit_drawer_btn_sec">
                <Button
                  className="drawer_merchant_cancel_btn"
                  variant="contained"
                  onClick={toggleDrawer(anchor, false)}
                >
                  Cancel
                </Button>

                <Button
                  className="drawer_merchant_save_btn"
                  variant="contained"
                  onClick={success}
                >
                  Save
                </Button>
              </div>
            </div>
            <div style={{ marginRight: "25px" }}>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Box>
        </div>
      </List>
    </Box>
  );
  // end side bar

  const [enabl, setEnabl] = React.useState("disabled");
  const [enabl1, setEnabl1] = React.useState("disabled");
  const [enabl2, setEnabl2] = React.useState("disabled");
  const [enabl3, setEnabl3] = React.useState("disabled");
  const [MerchantData, setMerchantData] = React.useState({});

  const EnableField1 = (e) => {
    setEnabl1();
  };
  const EnableField2 = (e) => {
    setEnabl2();
  };
  const EnableField3 = (e) => {
    setEnabl3();
  };

  useEffect(() => {
    AxiosBase.get(`/admin/super-merchant/${id.id}`).then((response) => {
      setMerchantData(response?.data);
      setListIItem(response?.data?.merchant_data);
    });
  }, []);
  return (
    <NavbarHoc
      id={Config.PermissionId.SuperMerchants}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      {/* super merchant heading  */}
      <Box>
        <h1 className="merchant_title">Super Merchant ID</h1>
      </Box>
      {/* super merchant heading end  */}

      {/* Edit page code */}

      <div className="merchant_list_main_div">
        <div className="merchant_list_inner_title_div">
          <h3 className="merchant_list_inner_title">Edit Super Merchant</h3>
        </div>

        <div className="merchant_edit_inputs_div">
          <div style={{ width: "100%" }}>
            <div className="merchant_edit_sep_div">
              <p>Super Merchant ID :</p>
              <CssTextField
                id="new-menu"
                variant="outlined"
                value={MerchantData._id}
                placeholder="Merchant ID"
                disabled={enabl}
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="merchant_edit_editIcon">
                      <IconButton color="primary" disabled>
                        <img src="/editt.svg" alt="" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment className="merchant_edit_field_icongroup">
                      <IconButton color="primary">
                        <img src="/person.svg" alt="" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="merchant_edit_sep_div">
              <p>Super Merchant Name :</p>
              <CssTextField
                id="new-menu"
                variant="outlined"
                value={MerchantData.full_name}
                placeholder="Merchant Name"
                disabled={enabl1}
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="merchant_edit_editIcon">
                      <IconButton color="primary" onClick={EnableField1}>
                        <img src="/editt.svg" alt="" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment className="merchant_edit_field_icongroup">
                      <IconButton color="primary">
                        <img src="/person.svg" alt="" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="merchant_edit_sep_div">
              <p>Contact Number :</p>
              <CssTextField
                id="new-menu"
                variant="outlined"
                value={MerchantData.mobile_no}
                placeholder="Merchant Number"
                disabled={enabl2}
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="merchant_edit_editIcon">
                      <IconButton color="primary" onClick={EnableField2}>
                        <img src="/editt.svg" alt="" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment className="merchant_edit_field_icongroup">
                      <IconButton color="primary">
                        <img src="/contact.svg" alt="" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="merchant_edit_sep_div">
              <p>Email ID :</p>
              <CssTextField
                id="new-menu"
                variant="outlined"
                value={MerchantData.email_id}
                placeholder="Merchant Email"
                disabled={enabl3}
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="merchant_edit_editIcon">
                      <IconButton color="primary" onClick={EnableField3}>
                        <img src="/editt.svg" alt="" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment className="merchant_edit_field_icongroup">
                      <IconButton color="primary">
                        <img
                          src={`${process.env.PUBLIC_URL}/top-email.svg`}
                          alt=""
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="merchant_edit_merchnt_dev">
              <p>Merchant :</p>

              <div>
                <Div className="merchant_edit_list_box">
                  <div style={{ display: "flex" }}>
                    <IconButton style={{ height: "3rem" }} color="primary">
                      <img src="/merchant-persion-icon.svg" alt="" />
                    </IconButton>

                    <TableContainer className="merchant_edit_merchantname_tbl">
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead></TableHead>
                        <TableBody>
                          {listIItem.map((lst) => {
                            return (
                              <TableRow className="merchant_edit_merchantname_tbl_body_row">
                                <TableCell>
                                  {lst.merchant_name +
                                    "" +
                                    lst.name +
                                    "" +
                                    lst._id}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Div>

                {/* Edit page code */}

                {/* edit button drawer  */}
                <div>
                  {["right"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Button
                        className="merchant_edit_mrchnt_edt_btn"
                        onClick={toggleDrawer(anchor, true)}
                        variant="contained"
                        startIcon={<img src="/edit-icon-white.svg" alt="" />}
                      >
                        EDIT
                      </Button>
                      <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                      >
                        {list(anchor)}
                      </SwipeableDrawer>
                    </React.Fragment>
                  ))}
                </div>

                {/* edit button drawer  */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="merchant_edit_button_dev">
        <Button className="merchant_cancel_btn" variant="contained">
          Cancel
        </Button>
        <Button className="merchant_save_btn" variant="contained">
          Save
        </Button>
      </div>
    </NavbarHoc>
  );
};

export default EditMerchant;
