import React from "react";
import { Route, Routes } from "react-router-dom";
import Topheader from "../../universal-components/header/topHeader";
// import RefundList from "../../Components/refund/RefundList"
import RefundList from "../../Components/refunds/refund-list/RefundList";
function RefundRouts() {
  return (
    <div style={{ padding: "15px" }}>
      <Topheader />
      <RefundList />
    </div>
  );
}

export default RefundRouts;
