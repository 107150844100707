import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Box, Paper } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
// import "./LedgerDetails.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AxiosBase from "../../../networkRequest/axiosBase";
import { getLedgers } from "../../../networkRequest/request/getBusiness";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import TablePagination from "@mui/material/TablePagination";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CircularProgress from "@mui/material/CircularProgress";
import baseURL from "../../../utils/base_url";

const LedgerDetails = (props) => {
  const [state, setState] = React.useState({
    right: false,
  });
  const [ledgerdata, setledgerdata] = React.useState([]);
  const [loader, setloader] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
    setloader(true);
    getLedgers(props?.id, props?.bid).then((data1) => {
      setledgerdata(data1?.data);
      setloader(false);
    });
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
  };

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: "8px",
  }));

  const Root = styled("div")`
    table {
      font-family: "whitney", sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td,
    th {
      border: 0.5px solid #c9c9c9;
      text-align: left;
      padding: 8px 25px;
    }
    td {
      background-color: #ffffff;
      width: 160;
    }
    th {
      background-color: #f5f5f5;
    }
  `;

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 1000,
        overflowX: "hidden",
        // backgroundColor: "#F3F4F6",
      }}
      role="presentation"
    >
      {/* <List className="order_details_drawer_top_sec_list">
        <ListItem className="order_details_drawer_top_sec">
          <p>Ledger Details For {props?.name}</p>
          {isPostive(ledgerdata[0]?.balanceAmount) ? (
            <p>You will give {getBalance(ledgerdata)} </p>
          ) : <p> You will get {getBalance(ledgerdata)}</p>}
          <span></span>
        </ListItem>
      </List> */}

      <div className="ledger-table_container-margin  cashbook_title_date_div">
        <p>Ledger Details For {props?.name}</p>
        {isPostive(ledgerdata[0]?.balanceAmount) ? (
          <span>
            You will give {props?.currency} {getBalance(ledgerdata)}{" "}
          </span>
        ) : (
          <span>
            You will get {props?.currency} {getBalance(ledgerdata)}
          </span>
        )}
      </div>
      {ledgerdata.length > 0 && (
        <div>
          <AccordionDetails sx={{ p: "0px 8px " }}>
            <div className="table_container-margin cashbook_table_div">
              <Root sx={{ maxWidth: "100%" }}>
                <table
                  className="cashbook_table"
                  aria-label="custom pagination table"
                >
                  <thead className="cashbook_tbl_head">
                    <tr className="cashbook_tbl_head_frow">
                      <th>CR</th>
                      <th>DR</th>
                      <th>Balance Amount</th>
                      <th>Transaction Date</th>
                      <th>Description</th>
                      <th>Attachment</th>
                      <th>Deleted</th>
                    </tr>
                  </thead>
                  <tbody className="cashbook_tbl_body">
                    {ledgerdata?.map((curElm) => {
                      const {
                        amount,
                        balanceAmount,
                        trans_date,
                        type,
                        bills,
                        desc,
                        isdeleted,
                        currency,
                      } = curElm;
                      return (
                        <tr key={trans_date}>
                          <td class="cashbook_tbl_bodytd">
                            {type == "CR"
                              ? currency + " " + Number(amount.toFixed(2))
                              : currency + " " + 0}
                          </td>
                          <td class="cashbook_tbl_bodytd">
                            {type == "DR"
                              ? currency + " " + Number(amount.toFixed(2))
                              : currency + " " + 0}
                          </td>
                          <td class="cashbook_tbl_bodytd">
                            {currency} {Number(balanceAmount.toFixed(2))}
                          </td>
                          <td align="right">
                            {" "}
                            {moment(trans_date).format("YYYY/MM/DD hh:mm A")}
                          </td>
                          <td align="right">{desc}</td>
                          <td align="right">
                            {" "}
                            {bills?.map((a) => {
                              const billsval = `${baseURL()}/api/image/show/${a}`;
                              return (
                                <p>
                                  <a href={billsval} target="_blank" download>
                                    {checklenght(a, 20)}
                                  </a>
                                </p>
                              );
                            })}
                          </td>
                          <td>
                            <span>{isdeleted ? "Yes" : "No"}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>

                  {/* <tfoot className="cashbook_tbl_footer">
                 <tr>
                   <td align="right">Grand Total</td>
                   <td align="right">abc</td>
                   <td align="right">abc</td>
                   <td align="right">abc</td>
                   <td align="right">abc</td>
                 </tr>
               </tfoot> */}
                </table>
                <TablePagination
                  className="table_pagination"
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={ledgerdata.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Root>
            </div>
          </AccordionDetails>
        </div>
      )}
      {ledgerdata?.length == 0 && !loader && (
        <div className="no_data_found loader_data_tbl">
          <p>No data found</p>
        </div>
      )}

      {loader && (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      )}
    </Box>
  );
  return (
    <>
      <div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <p className={props.className} onClick={toggleDrawer(anchor, true)}>
              {props?.id}
            </p>
            <SwipeableDrawer
              className="order_drawer_main"
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

function checklenght(val, lenght) {
  if (val?.length > lenght) {
    return val?.substring(0, lenght) + "..";
  }
  if (!val) {
    return "NA";
  }
  return val;
}
function isPostive(val) {
  if (parseInt(val) < 0) {
    return false;
  }
  return true;
}
function makePositive(val) {
  if (parseInt(val) < 0) {
    return parseInt(val) * -1;
  }
  return val;
}

function getBalance(val) {
  let balance = 0;
  let isvalid = false;
  val.map((curElm) => {
    if (!curElm.isdeleted && !isvalid) {
      if (parseInt(curElm.balanceAmount) < 0) {
        balance = parseInt(curElm.balanceAmount) * -1;
      }
      balance = curElm.balanceAmount;
      isvalid = true;
    }
  });
  return Number(Math.abs(balance).toFixed(2));
}
export default LedgerDetails;
