import React from "react";
import { useEffect } from "react";
import "./index.css";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});

const BasicInformation = (props) => {
  const { selectedPlan, uploadFile } = props;
  const [countryList, setCountryList] = React.useState([]);

  const hiddenFileInput = React.useRef(null);
  useEffect(() => {
    setCountryList(props.countryList);
  }, [selectedPlan]);

  const handleFileClick = (event) => {
    hiddenFileInput.current.click();
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const onChangeFieldVal = async (key, val, type = "String") => {
    props.onChangeFieldVal(key, val, type, "basic");
  };

  return (
    <>
      <div className="Fees_Margin_input_field add_menu_role_star">
        <p style={{ padding: "0 10px" }}>Plan Name:</p>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="gatway_second_grid_sec premium-plan-field"
        >
          <CssTextField
            id={"name_" + selectedPlan?._id}
            name={"name_"}
            placeholder="name"
            variant="outlined"
            value={selectedPlan.name}
            style={{ width: "90%" }}
            onChange={(e) => onChangeFieldVal("name", e.target.value)}
          />
        </Grid>
      </div>
      <div className="Fees_Margin_input_field add_menu_role_star">
        <p style={{ padding: "0 10px" }}>Country:</p>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="gatway_second_grid_sec premium-plan-field"
        >
          <Select
            name="key1"
            labelId="country-lbl"
            label=""
            id={"country" + selectedPlan?.country_id}
            value={selectedPlan?.country_id || ""}
            style={{ width: "90%" }}
            disabled={selectedPlan._id != "add_new"}
            onChange={(e) => onChangeFieldVal("country_id", e.target.value)}
          >
            <MenuItem key="" value="" disabled>
              select country
            </MenuItem>
            {countryList.map((c) => {
              return (
                <MenuItem key={c._id} value={c._id}>
                  {c.country_name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </div>
      <div className="Fees_Margin_input_field add_menu_role_star">
        <p style={{ padding: "0 10px" }}>Plan Color:</p>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="gatway_second_grid_sec premium-plan-field"
        >
          <CssTextField
            id={"color_"}
            name={"color"}
            placeholder="name"
            variant="outlined"
            value={selectedPlan.color}
            style={{ width: "90%" }}
            onChange={(e) => onChangeFieldVal("color", e.target.value)}
          />
        </Grid>
      </div>
      <div className="Fees_Margin_input_field add_menu_role_star">
        <p style={{ padding: "0 10px" }}>Plan Sequence:</p>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="gatway_second_grid_sec premium-plan-field"
        >
          <CssTextField
            id={"sequence_"}
            name={"sequence"}
            placeholder="sequence"
            variant="outlined"
            value={selectedPlan.sequence}
            style={{ width: "90%" }}
            onWheel={numberInputOnWheelPreventChange}
            onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
            onChange={(e) =>
              onChangeFieldVal("sequence", e.target.value, "Int")
            }
          />
        </Grid>
      </div>
      <div className="Fees_Margin_input_field premium_switch">
        <p style={{ padding: "0 10px" }}>Is Default:</p>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="gatway_second_grid_sec premium-plan-field"
        >
          <Switch
            id={"is_default_"}
            name={"is_default"}
            label={selectedPlan.is_default ? "yes" : "no"}
            checked={selectedPlan.is_default}
            onChange={(e) =>
              onChangeFieldVal("is_default", e.target.checked, "Boolean")
            }
          />
        </Grid>
      </div>
      <div className="Fees_Margin_input_field premium_switch">
        <p style={{ padding: "0 10px" }}>Is Highest:</p>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="gatway_second_grid_sec premium-plan-field"
        >
          <Switch
            id={"is_highest_"}
            name={"is_highest_"}
            label={selectedPlan.is_highest ? "yes" : "no"}
            checked={selectedPlan.is_highest}
            onChange={(e) =>
              onChangeFieldVal("is_highest", e.target.checked, "Boolean")
            }
          />
        </Grid>
      </div>
      <div className="Fees_Margin_input_field premium_switch">
        <p style={{ padding: "0 10px" }}>Status:</p>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="gatway_second_grid_sec premium-plan-field"
        >
          <Switch
            id={"activeStatus_"}
            name={"activeStatus"}
            label={selectedPlan.activeStatus ? "yes" : "no"}
            checked={selectedPlan.activeStatus}
            onChange={(e) =>
              onChangeFieldVal("activeStatus", e.target.checked, "Boolean")
            }
          />
        </Grid>
      </div>
      <div className="Fees_Margin_input_field add_menu_role_star">
        <p style={{ padding: "0 10px" }}>Image:</p>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="gatway_second_grid_sec"
        >
          <input
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            ref={hiddenFileInput}
            onChange={uploadFile}
            style={{ display: "none" }}
          />
          <Button
            className="premium_image_upload_btn"
            onClick={handleFileClick}
          >
            Upload a Image
          </Button>
        </Grid>
      </div>
    </>
  );
};

export default BasicInformation;
