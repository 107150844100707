import React from "react";
import { Paper } from "@mui/material";
import Inforamtion from "./components/information/index";
import Role from "./components/role/index";

function UserDetails() {
  return (
    <div>
      {/* <Details/>
                <Permissions/> */}

      {/* <Inforamtion/> */}
      <Role />
    </div>
  );
}

export default UserDetails;
