import React from "react";
import { Route, Routes } from "react-router-dom";
import Topheader from "../../universal-components/header/topHeader";
import AccessControl from "../../Components/access-control/AccessControl";
function AccessControlRout() {
  return (
    <div style={{ padding: "15px", borderRadius: "5rem", width: "98%" }}>
      <Topheader />
      <AccessControl />
    </div>
  );
}

export default AccessControlRout;
