import AxiosBase from "../axiosBase";
import axios from "axios";
// Status Code

export const GetAllStatus = async () => {
  try {
    let AllStatus = await AxiosBase.get(`/admin/status-code`);
    return AllStatus;
  } catch (err) {
    return err;
  }
};

export const UpdateStatus = async (req) => {
  return AxiosBase.patch(`/admin/status-code/` + req._id, req);
};

export const AddStatus = async (req) => {
  return AxiosBase.post(`/admin/status-code`, req);
};

// Acquirer

export const GetAcquirer = async (req) => {
  try {
    let url = req ? `/admin/acquirers/${req}` : `/admin/acquirers`;
    let AllStatus = await AxiosBase.get(url);
    return AllStatus;
  } catch (err) {
    return err;
  }
};

export const AddAcquirer = async (req) => {
  try {
    let AllStatus = await AxiosBase.post(`/admin/acquirers`, req);
    return AllStatus;
  } catch (err) {
    return err.response;
  }
};

export const UpdateAcquirer = async (req, Id) => {
  try {
    let AllStatus = await AxiosBase.patch(`/admin/acquirers/${Id}`, req);
    return AllStatus;
  } catch (err) {
    console.log("===>>>>", err.response);
    return err.response;
  }
};

// Currency

export const GetCurrency = async () => {
  try {
    let AllCurrency = await AxiosBase.get(`/admin/country-master`);
    let Innerdata = AllCurrency?.data ? AllCurrency?.data : [];
    Innerdata = Innerdata.filter((fact) => fact.is_active);
    return Innerdata;
  } catch (err) {
    return err;
  }
};

export const getAcquirerList = () => {
  return AxiosBase.get("/admin/acquirers/list");
};
