import React from "react";
import DataTable from "./data-table";
import { Paper } from "@mui/material";

function History() {
  return (
    <>
      <div
        style={{
          display: "flex",
          backgroundColor: "#1455F5",
          marginBottom: "10px",
          paddingLeft: "15px",
          borderRadius: "15px",
        }}
      >
        <p style={{ color: "white", fontWeight: 800 }}>History</p>
      </div>
      <Paper>
        <DataTable></DataTable>
      </Paper>
    </>
  );
}

export default History;
