import React, { useEffect } from "react";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import TablePagination from "@mui/material/TablePagination";
import { getAllRoles } from "../../../networkRequest/request/useraccesscontrol";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import "./index.css";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Filter from "../../filter-component/Filter";
import CircularProgress from "@mui/material/CircularProgress";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import DateRangeComp from "../../datepicker";
import NavbarHoc from "../../../pages/navhoc/index";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import {
  getRolesById,
  getRoles,
  AddRole,
  UpdateRole,
} from "../../../networkRequest/request/useraccesscontrol";
import Config from "../../../config.json";
import { useNavigate } from "react-router-dom";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
  "input#Another-menu": {
    width: "520px",
    height: "3px",
    backgroundColor: "#FEFEFE",
  },
});

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const RoleList = (props) => {
  const [data, setData] = React.useState([]);
  const [state, setState] = React.useState({
    right: false,
  });
  const [date, setDate] = React.useState({
    startDate: "",
    endDate: "",
  });
  const [loaderFlag, setLoaderFlag] = React.useState(false);
  const [count, setCount] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearch] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [searchBar, setSearchBar] = React.useState("");
  const [rolePermission, setRolePermission] = React.useState([]);
  const history = useNavigate();
  const [filter, setFilter] = React.useState([
    { key: "", type: "", value: "" },
  ]);
  const filterOptons = [
    { property: "Role Id", value: "id" },
    { property: "Role Name", value: "role_name" },
    { property: "Created By", value: "created_by_name" },
  ];
  const [roleData, setRoleData] = React.useState({
    "Super Merchant": {
      "View Super Merchant": false,
      "Create new Super Merchant": false,
      "Update Super Merchant": false,
    },
    Merchant: {
      "View Merchant": false,
      "Create new Merchant": false,
      "Update Merchant": false,
      "View Premium": false,
      "Update Premium": false,
      "View Branch": false,
      "Create branch": false,
      "Update branch": false,
      "View Terminal": false,
      "Create Terminal": false,
      "Update Terminal": false,
    },
    Orders: {
      "View order": false,
      "Update order": false,
      "refund order": false,
      "void order": false,
    },
    Settlment: {
      "View Settlment": false,
      "Settlment Export": false,
    },
    KYC: {
      "View KYC details": false,
      "Add KYC": false,
      "Update KYC": false,
      "Approve KYC": false,
    },
    Menu: {
      "View Menu details": false,
      "Menu Update": false,
      "View User": false,
    },
    User: {
      "Add user": false,
      "Update user": false,
    },
    "ACCESS CONTROL": {
      VIEW: false,
      ADD: false,
      EDIT: false,
      DOWNLOAD: false,
    },
    Configuration: {
      "View Margin and Fees": false,
      "Update Margin and Fees": false,
      "View Fraud and Risk": false,
      "Update Fraud and Risk": false,
      "View Automatic KYC": false,
      "Update Automatic KYC": false,
      "View Premium charges": false,
      "Update Premium charges": false,
      "View Gateway": false,
      "Update Gateway": false,
      "View settlment": false,
      "Update settlment": false,
    },
    "Email configuration": {
      "View email": false,
      "Update email": false,
    },
    SMS: {
      "View SMS": false,
      "Update SMS": false,
    },
    Push: {
      "View Push": false,
      "Update Push": false,
    },
  });
  const filterContains = [
    { property: "Is", value: "is" },
    { property: "Like", value: "contain" },
    { property: "Not Like", value: "not_contain" },
  ];

  useEffect(() => {
    const filtersval = filter[0].key != "" ? JSON.stringify(filter) : "";
    let dateparams = "";
    if (date.startDate != "") {
      dateparams = `&dateRange=custom&dateFrom=${date.startDate}&dateTo=${date.endDate}&dateField=createdAt`;
    }
    getAllRoles(
      page,
      rowsPerPage,
      "_id",
      "desc",
      searchBy,
      filtersval,
      dateparams
    ).then((rolelist) => {
      setData(rolelist.data.data);
      setCount(rolelist.data.count);
      setLoading(false);
    });
  }, [page, rowsPerPage, count, searchBy, filter, date]);

  const handleSearch = (event) => {
    if (event.key === "Enter" || event.key === undefined) {
      setSearch(searchBar);
      setPage(0);
      setFilter([{ key: "", type: "", value: "" }]);
    }
  };

  const handledates = (values) => {
    setDate(values);
  };

  const handleChangeSwitch = (inner, outer) => {
    let innerdata = roleData;
    innerdata[inner][outer] = !innerdata[inner][outer];
    setRoleData({ ...innerdata });
  };

  const ResetFilter = async (event) => {
    setFilter([{ key: "", type: "", value: "" }]);
  };
  const [anchorE2, setAnchorE2] = React.useState(null);

  const handleFilter = async (JSONfilter) => {
    setFilter(JSONfilter);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const pophandleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const pophandleClose = () => {
    setAnchorE2(null);
  };

  // const redirecttodetail = (_id) => {
  //   history(`/role/information/${_id}`)
  // }
  const toggleDrawer = (anchor, open, curElm) => async (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    const rolelist = curElm && (await getRolesById(curElm._id));
    setState({ ...state, [anchor]: open });
  };

  // const list = (anchor) => (
  //   <Box
  //     sx={{
  //       width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
  //       overflowX: "hidden",
  //     }}
  //     role="presentation"
  //   >{loaderFlag ?
  //     <div className="center">
  //       <CircularProgress />
  //     </div> :
  //     <>
  //       <List className="merchant_edit_drower_menu_top" >
  //         <ListItem className="Role_merchant_edit_drower_menu_top_sec">
  //           <p>Edit: Permission</p>
  //         </ListItem>
  //         <List>
  //           <div className="Role_edit_branch_inpt_div">
  //             <p>
  //               Role Name <span style={{ color: 'red' }}>*</span>
  //             </p>
  //             <CssTextField
  //               name="branch_name"
  //               className="role_merchant_edit_drower_serch_field"
  //               id="new-menu"
  //               placeholder="Role Name"
  //               variant="outlined"
  //               required
  //               // value='ytfty'
  //               // helperText={`${inputData.branch_name.length}/${100}`}

  //             />
  //             <CssTextField
  //               name="branch_name"
  //               className="role_merchant_edit_drower_serch_field"
  //               id="new-menu"
  //               placeholder="Search"
  //               variant="outlined"
  //               required
  //               // value='ytfty'
  //               // helperText={`${inputData.branch_name.length}/${100}`}
  //               inputProps={{
  //                 maxlength: 100,
  //               }}
  //             />
  //           </div>
  //           {
  //            Object.keys(roleData).map(fact=>{
  //              return <div className="Role_edit_branch_inpt_div_Another">
  //                     <p>{fact}</p>
  //                     {Object.keys(roleData[fact]).map(innerfact=>{
  //                      return   <CssTextField
  //                      name={innerfact}
  //                      id="Another-menu"
  //                      className="role_merchant_edit_drower_serch_field_another"
  //                      value={innerfact}
  //                      variant="outlined"
  //                     //  disabled={true}
  //                      contentEditable={false}
  //                      InputProps={{
  //                       endAdornment:<FormControlLabel
  //                       control={
  //                         <IOSSwitch sx={{ m: 1 }}  checked={roleData[fact][innerfact]} onChange={()=>{handleChangeSwitch(fact,innerfact)}}   />
  //                       }
  //                       label={""}
  //                     />
  //                     }}
  //                    />
  //                     })}
  //              </div>
  //            })
  //           }
  //         </List>
  //       </List>
  //       <div className="role_merchant_edit_drawer_btn_sec edit_branch_drawer_btn">
  //       <Button
  //         className="drawer_merchant_cancel_btn"
  //         variant="contained"
  //         onClick={()=>history('/role')}
  //       >
  //         Cancel
  //       </Button>

  //       <Button
  //         className="drawer_merchant_save_btn"
  //         variant="contained"
  //       >
  //         Save
  //       </Button>
  //     </div>
  //     </>
  //     }
  //   </Box>
  // );

  const openpop = Boolean(anchorE2);
  const id1 = openpop ? "simple-popover" : undefined;
  return (
    <NavbarHoc
      id={Config.PermissionId.Role}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Role List</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter innerFilterUser">
              <Grid
                item
                xs={12}
                // sm={12}
                md={8}
                lg={6}
                // xl={4}
                className="merchant_grid_sec"
              >
                <div
                  className="merchant_list_filter_div"
                  style={{ height: "100%" }}
                >
                  <CssTextField
                    className="serachBar"
                    id="new-menu"
                    placeholder="Search"
                    variant="outlined"
                    // fullWidth="false"
                    onChange={(e) => {
                      setSearchBar(e.target.value);
                    }}
                    onKeyUp={handleSearch}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton className="merchant_search_icon">
                            <i
                              onClick={handleSearch}
                              class="fa fa-search"
                              aria-hidden="true"
                            ></i>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <CssTextField
                    className="merchant_list_search_bar"
                    id="new-menu"
                    placeholder="Search"
                    variant="outlined"
                    fullWidth
                    onChange={handleSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <IconButton className="merchant_search_icon">
                            <img style={{ marginLeft: '-26px' }} src="/search.svg" alt="" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                  {/* </Grid>
              <Grid
                item
                xs={4}
                sm={12}
                md={2}
                lg={4}
                xl={6}
                className="merchant_grid_sec merchantfiltersapce"
              > */}
                  {/* <img
                    className="merchant_list_filter_img"
                    src="/Filter.svg"
                    aria-describedby={id1}
                    alt=""
                    onClick={pophandleClick}
                  /> */}
                  <Tooltip title="Filter records">
                    <div>
                      <i
                        style={{
                          fontSize: "30px",
                          marginLeft: "10px",
                          color: "#757575",
                          cursor: "pointer",
                        }}
                        onClick={pophandleClick}
                        class="fa fa-filter"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Tooltip>
                  <DateRangeComp handledates={handledates}> </DateRangeComp>
                  {filter[0].key != "" && (
                    <Tooltip title="Refresh to records">
                      <div>
                        <i
                          style={{
                            fontSize: "30px",
                            marginLeft: "10px",
                            color: "#757575",
                            cursor: "pointer",
                          }}
                          onClick={ResetFilter}
                          class="fa fa-refresh"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={4}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className="merchant_grid_sec"
                style={{ display: "contents" }}
              >
                {/* {["right"].map((anchor) => (
                            <React.Fragment key={anchor}> */}
                {rolePermission.includes("ADD") && (
                  <Button
                    className="merchant_list_add_new_merchant"
                    variant="contained"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    // onClick={toggleDrawer(anchor, true, '')}
                    onClick={() => history(`/role/information`)}
                  >
                    Add Role
                  </Button>
                )}

                {/* </React.Fragment>
                 ))} */}
              </Grid>
            </div>
          </div>
          <TableContainer
            className={
              data.length > 0 && !loading
                ? "table_container"
                : "table_container table_container_nodata"
            }
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Role ID</TableCell>
                  <TableCell>Role Name</TableCell>
                  {/* <TableCell>Menu Permissions</TableCell> */}
                  <TableCell>Created By</TableCell>
                  <TableCell>Created at</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {!loading ? (
                <TableBody className="data_tbl_body_row">
                  {data?.map((curElm) => {
                    const {
                      _id,
                      role_name,
                      // menu_permissions,
                      created_by,
                      createdAt,
                      created_by_name,
                      is_active,
                    } = curElm;
                    return (
                      <TableRow className=" tbl_id merchant_data_tbl_row merchant_tbl_edit_icon">
                        <TableCell
                          className=" InnerColorCode"
                          onClick={() =>
                            (rolePermission.includes("EDIT") ||
                              rolePermission.includes("VIEW")) &&
                            history(`/role/information/${_id}`)
                          }
                        >
                          {_id}
                        </TableCell>

                        <TableCell
                          onClick={() =>
                            (rolePermission.includes("EDIT") ||
                              rolePermission.includes("VIEW")) &&
                            history(`/role/information/${_id}`)
                          }
                        >
                          {role_name?.length > 20 ? (
                            <Tooltip title={role_name}>
                              <Button className="tbl_id">
                                {checklenght(role_name, 20)}
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button className="tbl_id">
                              {checklenght(role_name, 20)}
                            </Button>
                          )}
                        </TableCell>
                        {/* <TableCell>{JSON.stringify(menu_permissions[0])}</TableCell> */}
                        <TableCell>{created_by_name}</TableCell>
                        <TableCell>
                          {/* {moment(createdAt).format("YYYY/MM/DD hh:mm A").utc()} */}
                          {moment.utc(createdAt).format("YYYY/MM/DD hh:mm A")}
                        </TableCell>
                        <TableCell>
                          {is_active ? "Active" : "Inactive"}
                        </TableCell>
                        <TableCell>
                          <IconButton sx={{ borderRadius: "50%" }}>
                            <img
                              src="/editt.svg"
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                              onClick={() =>
                                (rolePermission.includes("EDIT") ||
                                  rolePermission.includes("VIEW")) &&
                                history(`/role/information/${_id}`)
                              }
                            />
                          </IconButton>
                          {/* {["right"].map((anchor) => (
                            <React.Fragment key={anchor}>
                              <IconButton sx={{ borderRadius: "50%" }}>
                                <img
                                  src="/editt.svg"
                                  alt=""
                                  style={{ height: "25px", width: "25px" }}
                                  onClick={toggleDrawer(anchor, true, curElm)}
                                />
                              </IconButton>
                              <SwipeableDrawer
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false, curElm)}
                                onOpen={toggleDrawer(anchor, true, curElm)}
                              >
                                {list(anchor)}
                              </SwipeableDrawer>
                            </React.Fragment>
                          ))} */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <></>
              )}
            </Table>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress style={{ color: "#1058ff" }} />
              </div>
            ) : data == "" ? (
              <div className="no_data_found loader_data_tbl">
                <p>No Data Found</p>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          {!loading ? (
            <TablePagination
              className="table_pagination"
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </div>
        <Popover
          id={id1}
          open={openpop}
          anchorEl={anchorE2}
          onClose={pophandleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ marginLeft: "135px", height: "500px", marginTop: "10px" }}
        >
          <Typography sx={{ p: 2 }}>
            <Filter
              filter1={handleFilter}
              pophandleClose={pophandleClose}
              optionns={filterOptons}
              contains={filterContains}
              filter={filter}
            />
          </Typography>
        </Popover>
      </div>
    </NavbarHoc>
  );
};

function checklenght(val, lenght) {
  if (val?.length > lenght) {
    return val?.substring(0, lenght) + "..";
  }
  if (!val) {
    return "NA";
  }
  return val;
}

export default RoleList;
