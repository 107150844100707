import React from "react";
import { useState, useEffect } from "react";
import "./AcquirerMapping.css";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import * as credentialSchema from "./../../../acquirer/Credential/schema";
import {
  GetAcquirer,
  GetAllStatus,
  GetCurrency,
  AddAcquirer,
  UpdateAcquirer,
} from "../../../../networkRequest/request/acquirer";
import baseURL from "../../../../utils/base_url";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const MCssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
    background: "white",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 45,
  height: 22,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AcquirerMapping = (props) => {
  const { rolePermission, acquirers, updateAcquirerMapping } = props;
  const [loader, setLoader] = React.useState(false);
  const [acquirersArr, setAcquirerMappingArr] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openReset, setResetOpen] = React.useState(false);
  const [countryList, setCountryList] = useState([]);
  const [currentSaveCancelId, setCurrentSaveCancelId] = useState(-1);
  const [defaultValue, setDefaultValue] = useState(-1);
  const [selectedDelete, setSelectedDelete] = useState(-1);
  const [newAdded, setNewAdded] = React.useState(false);
  const [credentialsOpen, setCredentialsOpen] = React.useState(false);
  const [selectedObj, setSelectedObj] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [globalAcquirer, setGlobalAcquirer] = React.useState([]);
  const [eppDeleteMsg, setEppDeleteMsg] = React.useState(false);

  React.useEffect(() => {
    setAcquirerMappingArr(acquirers);
    setDefaultValue(JSON.parse(JSON.stringify(acquirers)));
    axios.get(`${baseURL()}/api/country-master`).then((res) => {
      setCountryList(res?.data);
    });
    GetAcquirer().then((acquirer) => {
      setGlobalAcquirer(acquirer?.data?.data);
    });
  }, [acquirers]);

  const cancelChanges = () => {
    setAcquirerMappingArr(JSON.parse(JSON.stringify(defaultValue)));
    setCurrentSaveCancelId(-1);
    setNewAdded(false);
  };

  const save = () => {
    let priorities = [];
    let duplicate_priority = false;
    let title_empty = false;
    let acquirer_id_empty = false;
    let priority_empty = false;
    for (let acq of acquirersArr) {
      if (priorities.includes(acq.priority)) {
        duplicate_priority = true;
        break;
      }
      priorities.push(acq.priority);
      if (!acq.name) {
        title_empty = true;
      }
      if (!acq.acquirer_id) {
        acquirer_id_empty = true;
      }
      if (
        typeof acq.priority !== "number" ||
        isNaN(acq.priority) ||
        acq.priority === null ||
        acq.priority === undefined
      ) {
        priority_empty = true;
      }
    }
    if (duplicate_priority) {
      return toast("Duplicate priority", {
        type: "error",
        autoClose: 2000,
      });
    }
    if (title_empty) {
      return toast("Title required", {
        type: "error",
        autoClose: 2000,
      });
    }
    if (acquirer_id_empty) {
      return toast("Select acquirer", {
        type: "error",
        autoClose: 2000,
      });
    }
    if (priority_empty) {
      return toast("Priority required", {
        type: "error",
        autoClose: 2000,
      });
    }
    setCurrentSaveCancelId(-1);
    updateAcquirerMapping(acquirersArr);
    setNewAdded(false);
  };
  const add = () => {
    if (newAdded) {
      return;
    }
    acquirersArr.push({
      name: "",
      type: "",
      acquirer_id: "",
      priority: "",
      credential: {},
      subscription: false,
      is_merchant_credentials: false,
      epp_payment: false,
      status: true,
    });
    setAcquirerMappingArr(acquirersArr);
    setCurrentSaveCancelId(acquirersArr.length - 1);
    setNewAdded(true);
  };

  const deleteCharges = (ind, data) => {
    if (data.epp_payment) {
      let eppMsg = true;
      for (let acq of acquirersArr) {
        if (acq._id != data._id && acq?.epp_payment) {
          eppMsg = false;
          break;
        }
      }
      setEppDeleteMsg(eppMsg);
    } else {
      setEppDeleteMsg(false);
    }
    setSelectedDelete(ind);
    setOpen(true);
  };

  const handleDelete = () => {
    let priorities = [];
    let duplicate_priority = false;
    let title_empty = false;
    let acquirer_id_empty = false;
    let priority_empty = false;
    for (let acq of acquirersArr) {
      if (priorities.includes(acq.priority)) {
        duplicate_priority = true;
        break;
      }
      priorities.push(acq.priority);
      if (!acq.name) {
        title_empty = true;
      }
      if (!acq.acquirer_id) {
        acquirer_id_empty = true;
      }
      if (
        typeof acq.priority !== "number" ||
        isNaN(acq.priority) ||
        acq.priority === null ||
        acq.priority === undefined
      ) {
        priority_empty = true;
      }
    }
    if (duplicate_priority) {
      return toast("Duplicate priority", {
        type: "error",
        autoClose: 2000,
      });
    }
    if (title_empty) {
      return toast("Title required", {
        type: "error",
        autoClose: 2000,
      });
    }
    if (acquirer_id_empty) {
      return toast("Select acquirer", {
        type: "error",
        autoClose: 2000,
      });
    }
    if (priority_empty) {
      return toast("Priority required", {
        type: "error",
        autoClose: 2000,
      });
    }
    setOpen(false);
    setEppDeleteMsg(false);
    acquirersArr.splice(selectedDelete, 1);
    setSelectedDelete(-1);
    setCurrentSaveCancelId(-1);
    updateAcquirerMapping(acquirersArr);
  };

  const handleClose = () => {
    setOpen(false);
    setCredentialsOpen(false);
    setCurrentSaveCancelId(-1);
    setSelectedDelete(-1);
    setSelectedRow(null);
  };

  const handleCloseCred = () => {
    setCredentialsOpen(false);
    setSelectedRow(null);
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const loadToolTip = () => {
    return (
      <React.Fragment>
        <p>Please enter the acquirer priority</p>
      </React.Fragment>
    );
  };

  const openCredentials = (row) => {
    if (!row?.acquirer_id) {
      toast("Please select acquirer.", {
        type: "warning",
        autoClose: 2000,
      });
      return;
    }
    let acInd = globalAcquirer.findIndex((x) => x._id == row.acquirer_id);
    const ind =
      credentialSchema[
        `${globalAcquirer[acInd]?.name}_${globalAcquirer[acInd]?.type}`
      ];

    if (ind && ind.length) {
      setSelectedObj(ind);
    } else {
      setSelectedObj([
        {
          key: "MID",
          label: "mid",
          type: "text",
          required: false,
        },
        {
          key: "secret_key",
          label: "Secret Key",
          type: "text",
          required: false,
        },
      ]);
    }
    setSelectedRow(row);
    setCredentialsOpen(true);
  };

  const handleCredConfirm = () => {
    let index = acquirersArr.findIndex(
      (x) => x.acquirer_id == selectedRow?.acquirer_id
    );
    let flag = true;
    selectedObj.map((elm) => {
      if (
        elm?.required &&
        (!selectedRow?.credential[elm.key] ||
          selectedRow?.credential[elm.key] == "")
      ) {
        flag = false;
      }
    });
    if (!flag) {
      return toast("Please Fill All The Mandatory Details.", {
        type: "warning",
        autoClose: 2000,
      });
    }
    setAcquirerMappingArr(
      Object.values({
        ...acquirersArr,
        [index]: {
          ...acquirersArr[index],
          credential: selectedRow.credential,
        },
      })
    );
    setSelectedRow(null);
    setCredentialsOpen(false);
  };

  const confirmReset = () => {
    setResetOpen(true);
  };

  const handleResetClose = () => {
    setResetOpen(false);
  };

  const resetData = async () => {
    setLoader(true);
    setAcquirerMappingArr([]);
    await updateAcquirerMapping([]);
    setResetOpen(false);
    setLoader(false);
  };

  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="MOPSettingMain">
          <Grid
            className="MOPSettingMapping"
            container
            spacing={2}
            justifyContent="flex-end"
          >
            <Button
              className="merchant_reset_btn merchant_detail_tab_save-cancel_btn reset_btn"
              style={{
                "background-color": "#1058FF",
                "margin-right": "5px",
                "border-radius": "10px",
              }}
              variant="contained"
              disabled={!acquirersArr.length}
              onClick={confirmReset}
            >
              Reset
            </Button>
            {rolePermission.includes("ADD") && (
              <Button
                onClick={() => {
                  add();
                }}
                className="merchant_list_add_new_merchant"
                variant="contained"
              >
                Add New
              </Button>
            )}
          </Grid>
          <TableContainer
            component={Paper}
            style={{ border: "1px solid #e0e0e0" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Title
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Acquirer
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Priority
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Status
                  </TableCell>
                  {/* <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Subscription
                  </TableCell> */}
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Merchant Credentials
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    EPP
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ fontWeight: "bold" }}
                  ></TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {acquirersArr.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ width: 180 }} component="th">
                      {currentSaveCancelId == index ? (
                        <CssTextField
                          className=""
                          id="new-menu"
                          placeholder="title"
                          variant="outlined"
                          fullWidth
                          value={row.name}
                          onWheel={numberInputOnWheelPreventChange}
                          onChange={(e) => {
                            setAcquirerMappingArr(
                              Object.values({
                                ...acquirersArr,
                                [index]: {
                                  ...acquirersArr[index],
                                  name: e.target.value,
                                },
                              })
                            );
                          }}
                        />
                      ) : (
                        <label>{row?.name}</label>
                      )}
                    </TableCell>
                    <TableCell style={{ width: "26%" }} component="th">
                      {currentSaveCancelId == index ? (
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label"></InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={row?.acquirer_id}
                            label=""
                            placeholder="Select Acquirer"
                            onChange={(e) => {
                              row.acquirer_id = e.target.value;
                              let ind = globalAcquirer.findIndex(
                                (x) => x._id == e.target.value
                              );
                              let credential =
                                ind > -1 ? globalAcquirer[ind]?.credential : {};
                              let label =
                                ind > -1 ? globalAcquirer[ind]?.label : "";
                              let type =
                                ind > -1 ? globalAcquirer[ind]?.type : "";
                              let epp_payment =
                                ind > -1
                                  ? globalAcquirer[ind]?.epp_payment
                                  : false;

                              setAcquirerMappingArr(
                                Object.values({
                                  ...acquirersArr,
                                  [index]: {
                                    ...acquirersArr[index],
                                    acquirer_id: e.target.value,
                                    credential: credential,
                                    label: label,
                                    type,
                                    epp_payment,
                                  },
                                })
                              );
                            }}
                          >
                            {globalAcquirer.map((innerRow) => {
                              return (
                                <MenuItem value={innerRow._id}>
                                  {innerRow.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      ) : (
                        <label>{row?.label}</label>
                      )}
                    </TableCell>
                    <TableCell>
                      {currentSaveCancelId == index ? (
                        <Tooltip title={loadToolTip()} placement="right">
                          <CssTextField
                            className=""
                            id="new-menu"
                            placeholder="priority"
                            variant="outlined"
                            fullWidth
                            value={row.priority}
                            type="number"
                            onWheel={numberInputOnWheelPreventChange}
                            onChange={(e) => {
                              let val = e.target.value;
                              row.priority = val;
                              setAcquirerMappingArr(
                                Object.values({
                                  ...acquirersArr,
                                  [index]: {
                                    ...acquirersArr[index],
                                    priority: parseInt(val),
                                  },
                                })
                              );
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <label>{row?.priority}</label>
                      )}
                    </TableCell>
                    <TableCell>
                      {currentSaveCancelId == index ? (
                        <>
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={row?.status || false}
                            onChange={(e) => {
                              setAcquirerMappingArr(
                                Object.values({
                                  ...acquirersArr,
                                  [index]: {
                                    ...acquirersArr[index],
                                    status: e.target.checked,
                                  },
                                })
                              );
                            }}
                          />
                        </>
                      ) : (
                        <label>{row?.status ? "Yes" : "No"}</label>
                      )}
                    </TableCell>
                    {/* <TableCell>
                      {currentSaveCancelId == index ? (
                        <>
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={row?.subscription || false}
                            onChange={(e) => {
                              setAcquirerMappingArr(
                                Object.values({
                                  ...acquirersArr,
                                  [index]: {
                                    ...acquirersArr[index],
                                    subscription: e.target.checked,
                                  },
                                })
                              );
                            }}
                          />
                        </>
                      ) : (
                        <label>{row?.subscription ? "Yes" : "No"}</label>
                      )}
                    </TableCell> */}
                    <TableCell>
                      {currentSaveCancelId == index ? (
                        <>
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={row?.is_merchant_credentials || false}
                            onChange={(e) => {
                              setAcquirerMappingArr(
                                Object.values({
                                  ...acquirersArr,
                                  [index]: {
                                    ...acquirersArr[index],
                                    is_merchant_credentials: e.target.checked,
                                  },
                                })
                              );
                            }}
                          />
                        </>
                      ) : (
                        <label>
                          {row?.is_merchant_credentials ? "Yes" : "No"}
                        </label>
                      )}
                    </TableCell>
                    <TableCell>
                      {currentSaveCancelId == index ? (
                        <>
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={row?.epp_payment || false}
                            onChange={(e) => {
                              setAcquirerMappingArr(
                                Object.values({
                                  ...acquirersArr,
                                  [index]: {
                                    ...acquirersArr[index],
                                    epp_payment: e.target.checked,
                                  },
                                })
                              );
                            }}
                          />
                        </>
                      ) : (
                        <label>{row?.epp_payment ? "Yes" : "No"}</label>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        id={"BtnCancel_" + index}
                        name={"BtnCancel_" + index}
                        className="merchant_list_add_new_merchant"
                        variant="contained"
                        title="Cancel"
                        disabled={currentSaveCancelId != index}
                        onClick={(e) => {
                          openCredentials(row);
                        }}
                      >
                        <i style={{ "font-size": "12px", color: "#ffffff" }}>
                          Credentials
                        </i>
                      </Button>
                    </TableCell>
                    <TableCell style={{ width: "110px" }}>
                      <div className="cronjob_cronjob_edit_button_dev cronjob_cronjob_drawer_save_cancel_btn">
                        <span
                          className={
                            currentSaveCancelId == index ? "visible" : "hidden"
                          }
                        >
                          <Button
                            id={"BtnCancel_" + index}
                            name={"BtnCancel_" + index}
                            className="premium_cancel_btn"
                            variant="contained"
                            title="Cancel"
                            onClick={(e) => {
                              cancelChanges(index);
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                          <Button
                            id={"BtnSave_" + index}
                            name={"BtnSave_" + index}
                            className="premium_save_btn"
                            variant="contained"
                            title="Save Plan"
                            onClick={(e) => {
                              save();
                            }}
                          >
                            <i className="fa fa-paper-plane"></i>
                          </Button>
                        </span>
                        <span
                          className={
                            currentSaveCancelId != index ? "visible" : "hidden"
                          }
                        >
                          {rolePermission.includes("EDIT") && (
                            <Button
                              id={"BtnEdit_" + index}
                              name={"BtnEdit_" + index}
                              className="premium_edit_btn"
                              variant="contained"
                              title="Edit Plan"
                              onClick={(e) => {
                                setAcquirerMappingArr(
                                  JSON.parse(JSON.stringify(defaultValue))
                                );
                                setNewAdded(false);
                                setCurrentSaveCancelId(index);
                              }}
                            >
                              <i class="fa fa-pencil"></i>
                            </Button>
                          )}
                          <Button
                            id={"BtnEdit_" + index}
                            name={"BtnEdit_" + index}
                            className="premium_delete_btn"
                            variant="contained"
                            title="Delete Plan"
                            onClick={(e) => {
                              deleteCharges(index, row);
                            }}
                          >
                            <i class="fa fa-trash"></i>
                          </Button>
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <BootstrapDialog
              className="order_detail_refund_popup"
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogContent>
                <Typography
                  className="order_detail_refund_popup_title"
                  gutterBottom
                >
                  <p>Confirm Delete</p>
                  <span>Are you sure want to delete this record?</span>
                  {eppDeleteMsg && (
                    <>
                      <br />
                      <span>
                        All EPP configurations will be removed from terminals
                      </span>
                    </>
                  )}
                  <div className="order_detail_refund_popup_btn">
                    <Button
                      className="refund_save_btn"
                      variant="contained"
                      autoFocus
                      fullWidth
                      onClick={handleDelete}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="refund_cancel_btn"
                      variant="contained"
                      autoFocus
                      onClick={handleClose}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </div>
                </Typography>
              </DialogContent>
            </BootstrapDialog>
          </div>
          <div>
            <BootstrapDialog
              className="order_detail_refund_popup"
              onClose={handleCloseCred}
              aria-labelledby="customized-dialog-title"
              open={credentialsOpen}
            >
              <DialogContent>
                <Typography className="" gutterBottom>
                  {selectedObj.map((elm) => {
                    return (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className=""
                        >
                          <p className="">
                            {elm.label}{" "}
                            {elm?.required && (
                              <span className="Mandatory">*</span>
                            )}
                          </p>
                          <CssTextField
                            className=""
                            type={elm?.type || "text"}
                            id="new-menu"
                            placeholder={elm.label}
                            variant="outlined"
                            style={{ width: "100%" }}
                            value={selectedRow?.credential[elm.key]}
                            onChange={(e) => {
                              let val = e.target.value;
                              let data = selectedRow;
                              data.credential[elm.key] = val;
                              setSelectedRow({ ...data });
                            }}
                          />
                        </Grid>
                      </>
                    );
                  })}
                  <div className="order_detail_refund_popup_btn">
                    <Button
                      className="refund_save_btn"
                      variant="contained"
                      autoFocus
                      fullWidth
                      onClick={handleCredConfirm}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="refund_cancel_btn"
                      variant="contained"
                      autoFocus
                      onClick={handleCloseCred}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </div>
                </Typography>
              </DialogContent>
            </BootstrapDialog>
          </div>
          <div>
            <BootstrapDialog
              className="order_detail_refund_popup"
              onClose={handleResetClose}
              aria-labelledby="customized-dialog-title"
              open={openReset}
            >
              <DialogContent>
                <Typography
                  className="order_detail_refund_popup_title"
                  gutterBottom
                >
                  <p>Confirm Reset</p>
                  <span>Are you sure want to reset configuration?</span>
                  <div className="order_detail_refund_popup_btn">
                    <Button
                      className="refund_save_btn"
                      variant="contained"
                      autoFocus
                      fullWidth
                      onClick={resetData}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="refund_cancel_btn"
                      variant="contained"
                      autoFocus
                      onClick={handleResetClose}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </div>
                </Typography>
              </DialogContent>
            </BootstrapDialog>
          </div>
        </div>
      )}
    </>
  );
};

export default AcquirerMapping;
