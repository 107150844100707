import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Filter from "../../filter-component/Filter";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import PopupState, {
  bindTrigger,
  bindPopover,
  bindToggle,
} from "material-ui-popup-state";
import "./CashBook-details.css";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import List from "@mui/material/List";
import DateRangeComp from "../../datepicker";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { cashBookDetails } from "../../../networkRequest/request/cashBook";
import { getAllBusiness } from "../../../networkRequest/request/getBusiness";
import CircularProgress from "@mui/material/CircularProgress";

function createData(name, calories, fat) {
  return { name, calories, fat };
}
const rows = [
  createData("07:44 AM", "AED 24513.00", "AED 75754.00"),
  createData("07:44 AM", "AED 24513.00", "AED 75754.00"),
  createData("07:44 AM", "AED 24513.00", "AED 75754.00"),
  createData("07:44 AM ", "AED 24513.00", "AED 75754.00"),
  createData("07:44 AM", "AED 24513.00", "AED 75754.00"),
  createData("07:44 AM", "AED 24513.00", "AED 75754.00"),
];
const Root = styled("div")`
  table {
    font-family: "whitney", sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    border: 0.5px solid #c9c9c9;
    text-align: left;
    padding: 8px 25px;
  }
  td {
    background-color: #ffffff;
    width: 160;
    align: right;
  }
  th {
    background-color: #f5f5f5;
  }
`;
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "8px",
}));

const CashBookDetails = () => {
  const [anchorE2, setAnchorE2] = useState(null);
  const inputEl = useRef(null);
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get("merchantId");
  const [data, setData] = React.useState([]);
  const [totalBalence, setTotalBalence] = React.useState([]);
  const [grandTotal, setGrandTotal] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedId, setSelectedId] = React.useState({});
  const [loadMore, setLoadMore] = React.useState(true);
  const [loader, setLoader] = React.useState(false);
  const [maxRows, setMaxRows] = React.useState(11);
  const [businessdata, setBusinessData] = React.useState([]);
  const [filter, setFilter] = useState([{ key: "", type: "", value: "" }]);
  const [date, setDate] = React.useState({
    startDate: "",
    endDate: "",
  });
  const filterOptons = [
    { property: "Single-day", value: "full_name" },
    { property: "Last week", value: "email_id" },
    { property: "Last month", value: "mobile_no" },
    { property: "Date Range", value: "mobile_no" },
  ];
  const filterContains = [
    { property: "Is", value: "is" },
    { property: "Like", value: "start_with" },
    { property: "Not Like", value: "not_contain" },
  ];
  const ResetFilter = async (event) => {
    setFilter([{ key: "", type: "", value: "" }]);
  };
  const handleFilter = async (JSONfilter) => {
    setFilter(JSONfilter);
  };
  const pophandleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const pophandleClose = () => {
    setAnchorE2(null);
  };
  const openpop = Boolean(anchorE2);
  const id1 = openpop ? "simple-popover" : undefined;
  // calling API
  useEffect(() => {
    getAllBusinessData();
  }, []);

  const getAllBusinessData = async () => {
    let InnerResponse = await getAllBusiness(customerId);
    setLoadMore(true);
    setMaxRows(11);
    setBusinessData(InnerResponse?.data);
    setLoading(true);
    setSelectedId({
      id: InnerResponse?.data[0]?.uid,
      name: InnerResponse?.data[0]?.name,
    });
    setLoader(true);
    const response = await cashBookDetails("", InnerResponse?.data[0]?.uid);
    setLoader(false);
    setData(response?.data);
    setTotalBalence(response?.total_balance);
    setGrandTotal(response?.grand_total);
    makeTableScroll(0);
  };

  useEffect(() => {
    getCashbookDetails();
  }, [date, selectedId]);

  const getCashbookDetails = async () => {
    setLoading(true);
    let dateparams = "";
    if (date.startDate != "") {
      dateparams = `?dateRange=custom&dateFrom=${date.startDate}&dateTo=${date.endDate}&dateField=data.date`;
    }
    setLoader(true);
    const response = await cashBookDetails(dateparams, selectedId.id);
    setLoader(false);
    setData(response?.data);
    setTotalBalence(response?.total_balance);
    setGrandTotal(response?.grand_total);
    setLoadMore(true);
    setMaxRows(11);
    makeTableScroll(0);
  };

  const handledates = (values) => {
    setDate(values);
  };

  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const makeTableScroll = (Count) => {
    let addrow = Count == 0 ? 11 : maxRows;
    var innerCountRow = Count + addrow;
    var table = inputEl?.current;
    var wrapper = table?.parentNode;
    var rowsInTable = table?.rows.length;
    innerCountRow = rowsInTable >= innerCountRow ? innerCountRow : rowsInTable;
    var height = 0;
    for (var i = 0; i < innerCountRow; i++) {
      height += table.rows[i].clientHeight;
    }
    innerCountRow && (wrapper.style.height = height + 1 + "px");
    if (rowsInTable <= innerCountRow) {
      setLoadMore(false);
    }

    setMaxRows(innerCountRow);
  };

  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div className="cashbook_title_date_div title_drop cashbook_filter_div">
                <div className="title_div">
                  <p className="title_p" {...bindTrigger(popupState)}>
                    {" "}
                    {selectedId?.name}
                  </p>

                  {anchorE2 === true ? <ExpandLess /> : <ExpandMore />}
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                      aria-label="contacts"
                      {...bindToggle(popupState)}
                    >
                      {businessdata.map((fact) => {
                        return (
                          <>
                            {!fact.isdeleted && (
                              <ListItem
                                sx={{ p: 0 }}
                                onClick={() => {
                                  setSelectedId({
                                    id: fact.uid,
                                    name: fact.name,
                                  });
                                }}
                              >
                                <ListItemButton>
                                  <ListItemText
                                    sx={{ p: 0 }}
                                    primary={fact.name}
                                  />
                                </ListItemButton>
                              </ListItem>
                            )}
                          </>
                        );
                      })}
                    </List>
                  </Popover>
                </div>

                <DateRangeComp
                  handledates={handledates}
                  DisableCurrent={true}
                  position="right center"
                >
                  {" "}
                </DateRangeComp>
                {date.startDate !== "" && data.endDate !== "" && (
                  <Tooltip title="Refresh to records">
                    <div>
                      <i
                        style={{
                          fontSize: "30px",
                          marginLeft: "10px",
                          color: "#757575",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setDate({
                            startDate: "",
                            endDate: "",
                          });
                        }}
                        class="fa fa-refresh"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
          </PopupState>

          <AccordionDetails>
            <div className="cashbook_title_date_div">
              <p>Cashbook Statement</p>
              <span>
                {date.startDate && date.endDate
                  ? moment(date.startDate).format("DD MMM YYYY") +
                    " - " +
                    moment(date.endDate).format("DD MMM YYYY")
                  : ""}
              </span>
            </div>

            <div className="cashbook_balance_details_div">
              <div>
                <div className="cashbook_balance_inner">
                  <p>Total IN (+)</p>
                  <h4 className="total_in">
                    {totalBalence
                      ? totalBalence?.currency
                        ? totalBalence?.currency +
                          " " +
                          totalBalence?.cash_in?.toFixed(2)
                        : "" + " " + totalBalence?.cash_in?.toFixed(2)
                      : 0}
                  </h4>
                </div>
                <div className="cashbook_balance_inner">
                  <span>
                    Cash:{" "}
                    {totalBalence
                      ? totalBalence?.currency
                        ? totalBalence?.currency +
                          " " +
                          totalBalence?.cash_cash_in?.toFixed(2)
                        : "" + " " + totalBalence?.cash_cash_in?.toFixed(2)
                      : 0}
                  </span>
                  <span className="online_ADE">
                    Online:{" "}
                    {totalBalence
                      ? totalBalence?.currency
                        ? totalBalence?.currency +
                          " " +
                          totalBalence?.online_cash_in?.toFixed(2)
                        : "" + " " + totalBalence?.online_cash_in?.toFixed(2)
                      : 0}
                  </span>
                </div>
              </div>
              <div>
                <p>-</p>
              </div>
              <div>
                <div className="cashbook_balance_inner">
                  <p>Total OUT (-)</p>
                  <h4 className="total_out">
                    {totalBalence
                      ? totalBalence?.currency
                        ? totalBalence?.currency +
                          " " +
                          totalBalence?.cash_out?.toFixed(2)
                        : "" + " " + totalBalence?.cash_out?.toFixed(2)
                      : 0}
                  </h4>
                </div>
                <div className="cashbook_balance_inner">
                  <span>
                    Cash:{" "}
                    {totalBalence
                      ? totalBalence?.currency
                        ? totalBalence?.currency +
                          " " +
                          totalBalence?.cash_cash_out?.toFixed(2)
                        : "" + " " + totalBalence?.cash_cash_out?.toFixed(2)
                      : 0}
                  </span>
                  <span className="online_ADE">
                    Online:{" "}
                    {totalBalence
                      ? totalBalence?.currency
                        ? totalBalence?.currency +
                          " " +
                          totalBalence?.online_cash_out?.toFixed(2)
                        : "" + " " + totalBalence?.online_cash_out?.toFixed(2)
                      : 0}
                  </span>
                </div>
              </div>
              <div>=</div>
              <div>
                <div className="cashbook_balance_inner">
                  <p>Net Balance</p>
                  <h4
                    className={
                      totalBalence?.cash_in_hand &&
                      totalBalence?.cash_in_hand >= 0
                        ? "total_in"
                        : "total_out"
                    }
                  >
                    {totalBalence
                      ? totalBalence?.currency
                        ? totalBalence?.currency +
                          " " +
                          totalBalence?.cash_in_hand?.toFixed(2)
                        : "" + " " + totalBalence?.cash_in_hand?.toFixed(2)
                      : 0}
                  </h4>
                  <p>
                    {totalBalence?.cash_in_hand == 0
                      ? ""
                      : totalBalence?.cash_in_hand > 0
                      ? "CR"
                      : "DR"}
                  </p>
                </div>
                <div className="cashbook_balance_inner">
                  {/* <span>Cash: AED 1,03,544.00</span>
                <span className="online_ADE">Online: AED 764.00</span> */}
                </div>
              </div>
            </div>
          </AccordionDetails>

          <AccordionDetails sx={{ p: "0px 8px " }}>
            <div className="cashbook_table_div">
              <Root sx={{ maxWidth: "100%" }}>
                <table
                  ref={inputEl}
                  className="cashbook_table"
                  aria-label="custom pagination table"
                >
                  <thead className="cashbook_tbl_head">
                    <tr className="cashbook_tbl_head_frow">
                      <th>Date</th>
                      <th className="total_in">Total IN</th>
                      <th className="total_out">Total OUT</th>
                      <th>Daily Balance</th>
                      <th>Cash in Hand</th>
                    </tr>
                  </thead>
                  {data &&
                    data.map((rows) => {
                      return (
                        <>
                          <tr className="cashbook_tbl_head_srow">
                            <th>
                              {moment(rows.date).format("DD MMM YYYY")} |{" "}
                              {rows.count} Entries
                            </th>
                            <th>
                              {rows.currency
                                ? rows.currency + " " + rows.cash_in?.toFixed(2)
                                : "" + " " + rows.cash_in?.toFixed(2)}
                            </th>
                            <th>
                              {rows.currency
                                ? rows.currency +
                                  " " +
                                  rows.cash_out?.toFixed(2)
                                : "" + " " + rows.cash_out?.toFixed(2)}
                            </th>
                            <th
                              className={
                                rows.daily_balance >= 0
                                  ? "total_in"
                                  : "total_out"
                              }
                            >
                              {rows.currency
                                ? rows.currency +
                                  " " +
                                  rows.daily_balance?.toFixed(2)
                                : "" + " " + rows.daily_balance?.toFixed(2)}
                            </th>
                            <th
                              className={
                                rows.cash_in_hand >= 0
                                  ? "total_in"
                                  : "total_out"
                              }
                            >
                              {rows.currency
                                ? rows.currency +
                                  " " +
                                  rows.cash_in_hand?.toFixed(2)
                                : "" + " " + rows.cash_in_hand?.toFixed(2)}
                            </th>
                          </tr>

                          <tbody className="cashbook_tbl_body">
                            {rows.cashbook.map((row) => (
                              <>
                                <tr key={row.entryId}>
                                  <td>
                                    <div className="cash_status_td">
                                      {/* <p>{moment(row.updatedAt).format("h:mm A")} </p> */}
                                      <p>
                                        {moment(row.createdAt).format("h:mm A")}{" "}
                                      </p>
                                      {row.isdeleted && (
                                        <span
                                          style={{
                                            backgroundColor: "red",
                                            color: "white",
                                          }}
                                        >
                                          deleted
                                        </span>
                                      )}
                                      <span>{row.paymentMode}</span>
                                    </div>
                                  </td>
                                  <td>
                                    {row.entryType == "IN" &&
                                      row.amount?.toFixed(2)}
                                  </td>
                                  <td>
                                    {row.entryType == "OUT" &&
                                      row.amount?.toFixed(2)}
                                  </td>

                                  <td></td>
                                  <td></td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </>
                      );
                    })}
                  <tfoot className="cashbook_tbl_footer">
                    <tr>
                      <td>Grand Total</td>
                      <td>{grandTotal?.cash_in?.toFixed(2)}</td>
                      <td>{grandTotal?.cash_out?.toFixed(2)}</td>
                      <td></td>
                      <td>{grandTotal?.cash_in_hand?.toFixed(2)}</td>
                    </tr>
                  </tfoot>
                </table>
              </Root>
            </div>
            {loadMore && (
              <div
                className="cashbook_load_more_div"
                onClick={() => {
                  makeTableScroll(10);
                }}
              >
                <p>Load more</p>
              </div>
            )}
          </AccordionDetails>
          <Popover
            id={id1}
            open={openpop}
            anchorEl={anchorE2}
            onClose={pophandleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{ marginLeft: "-200px", height: "500px", marginTop: "10px" }}
          >
            <Typography sx={{ p: 2 }}>
              <Filter
                filter1={handleFilter}
                pophandleClose={pophandleClose}
                optionns={filterOptons}
                contains={filterContains}
                filter={filter}
              />
            </Typography>
          </Popover>
        </>
      )}
    </>
  );
};

export default CashBookDetails;
