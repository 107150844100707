import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css";

function NotFound() {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        {/* <img
          src="/urban-logo.svg"
          alt="UL_Logo"
          className="not-found-page-ul-logo"
        /> */}
        <h1 className="not-found-title">404</h1>
        <h4>PAGE NOT FOUND !</h4>
        <p className="not-found-message">
          You seem to be trying to find his way home
        </p>
        <Link to="/" className="not-found-link">
          Go Back Home
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
