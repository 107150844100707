import React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useParams, Link } from "react-router-dom";
import AxiosBase from "../../../networkRequest/axiosBase";
import "./Settlement-details.css";
import { toast } from "react-toastify";
import moment from "moment";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import SettlementOrders from "../settlement-orders/Orders";
import CircularProgress from "@mui/material/CircularProgress";
import TablePagination from "@mui/material/TablePagination";
import NavbarHoc from "../../../pages/navhoc/index";
import Config from "../../../config.json";
import { Input } from "@mui/material";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
  },
  "input#another-menu": {
    width: "520px",
    height: "30px",
  },
});

function Settlements() {
  const [search, setSearch] = useState("");
  const [data, setData] = useState({});
  const [next, setNext] = useState({});
  const [previous, setPrev] = useState({});
  const [count, setCount] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rolePermission, setRolePermission] = React.useState([]);
  const [controlEnable, setControlEnable] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [addComment, setAddComment] = React.useState("");
  const [PopUploading, setPopUploading] = React.useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event));
    setPage(0);
  };

  const id = useParams();

  useEffect(() => {
    Checkchanges();
  }, [page, rowsPerPage]);

  const Checkchanges = () => {
    AxiosBase.get(
      `/admin/merchant-settlement/${
        id.id != "" ? id.id : ""
      }?pageNo=${page}&rowsPerPage=${rowsPerPage}&sortOrder=des`
    ).then((response) => {
      setData(response.data.data);
      setNext(response.data.next);
      setPrev(response.data.previous);
      setCount(response.data.count);
      setLoading(false);
    });
  };

  const changeStatus = () => {
    if (addComment !== "") {
      setPopUploading(true);
      AxiosBase.patch(`/admin/merchant-settlement/${id.id}`, {
        status: data.status,
        comment: addComment,
      }).then((response) => {
        toast(response.data.message, {
          type: "warning",
          autoClose: 2000,
        });
        setPopUploading(false);
        setOpenModal(false);
        setControlEnable(false);
        setPage(0);
      });
      setPopUploading(false);
    } else {
      toast("Comments are required", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };
  return (
    <NavbarHoc
      id={Config.PermissionId.Settlements}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <Box>
        <h1 className="merchant_title">Settlement Details</h1>
      </Box>
      <div className="merchant_list_main_div">
        {/* <Grid item xs={12} sm={12} md={5} lg={5} xl={5} className="">
          <CssTextField
            className="merchant_list_search_bar"
            id="new-menu"
            fullWidth
            placeholder="search"
            variant="outlined"
            // fullWidth
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <IconButton className="merchant_search_icon">
                    <img src="/search.svg" alt="" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid> */}
        <div className="settlement_detail_inner_title_div">
          <p className="settlement_title">Settlement Details</p>

          {next && next._id ? (
            <>
              <a href={next && next._id ? `/settlements/` + next._id : ""}>
                <Button className="next_previous_btn">
                  <KeyboardArrowLeftIcon />
                  Previous
                </Button>
              </a>
            </>
          ) : (
            <>
              <a>
                <Button className="next_previous_btn">
                  <KeyboardArrowLeftIcon />
                  Previous
                </Button>
              </a>
            </>
          )}

          <h3>{data.settlement_id}</h3>
          {previous && previous._id ? (
            <a
              href={
                previous && previous._id ? `/settlements/` + previous?._id : ""
              }
            >
              <Button className="next_previous_btn">
                Next
                <KeyboardArrowRightIcon />
              </Button>
            </a>
          ) : (
            <a>
              <Button className="next_previous_btn">
                Next
                <KeyboardArrowRightIcon />
              </Button>
            </a>
          )}
          {rolePermission.includes("EDIT") && !controlEnable && (
            <Button
              className="EditButton"
              onClick={() => {
                setControlEnable(true);
              }}
            >
              Edit
            </Button>
          )}
        </div>

        {loading ? (
          <div className="no_data_found loader_data_tbl">
            <CircularProgress />
          </div>
        ) : data == "" ? (
          <div className="no_data_found loader_data_tbl">
            <p>No Data Found</p>
          </div>
        ) : (
          <>
            <div className="settlement_detail_input_dev">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Settlement ID</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={data.settlement_id}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span>Amount</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={
                        data.total_settlement_amount != ""
                          ? data.total_settlement_amount.toFixed(2) +
                            " " +
                            data.currency
                          : 0 + " " + data.currency
                      }
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Bank Name</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={data.bank_name}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Start Date</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={moment(data.from_date).format(
                        "YYYY/MM/DD hh:mm A"
                      )}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">End Date</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={moment(data.to_date).format("YYYY/MM/DD hh:mm A")}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Completion Date</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={moment(data.from_date)
                        .add(15, "days")
                        .format("YYYY/MM/DD hh:mm A")}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Status</span>
                    {/* <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={
                        data.status === "PENDING_FROM_BANK"
                          ? "pending"
                          : data.status === "FAILED"
                          ? "failed"
                          : data.status === "COMPLETED"
                          ? "successful"
                          : ""
                      }
                      disabled
                    /> */}
                    <FormControl
                      sx={{ width: "100%", fontSize: "12px !important" }}
                    >
                      <InputLabel id="settlement_currency-lbl"></InputLabel>
                      <Select
                        name="key2"
                        labelId="settlement_currency-lbl"
                        label=""
                        id="settlement_currency"
                        className="SettlementStatus"
                        value={data.status}
                        disabled={!controlEnable}
                        onChange={(event) => {
                          setData({ ...data, status: event.target.value });
                        }}
                      >
                        <MenuItem key="pending" value="PENDING_FROM_BANK">
                          Pending
                        </MenuItem>
                        <MenuItem key="failed" value="FAILED">
                          Failed
                        </MenuItem>
                        <MenuItem key="successful" value="COMPLETED">
                          Successful
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Charges</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={
                        data.total_fixed_fees_amount != ""
                          ? data.total_fixed_fees_amount + " " + data.currency
                          : 0 + "  " + data.currency
                      }
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Margin(%)</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={
                        data.total_margin_amount != ""
                          ? data.total_margin_amount + "  " + data.currency
                          : 0 + "  " + data.currency
                      }
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">VAT</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={
                        data.total_vat_amount != ""
                          ? data.total_vat_amount + "  " + data.currency
                          : 0 + "  " + data.currency
                      }
                      disabled
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {controlEnable && (
              <div className="settlement_detail_Hide_dev">
                <Button
                  className="SaveButton"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Save
                </Button>
                <Button
                  className="CancelButton"
                  onClick={() => {
                    setControlEnable(false);
                    Checkchanges();
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
            <div>
              <SettlementOrders
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                data={data}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rolePermission={rolePermission}
                from="settlement"
              />
            </div>
          </>
        )}
      </div>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Box className="Settlement_ModalPopUp">
          {PopUploading ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress />
            </div>
          ) : (
            <>
              <CssTextField
                id="another-menu"
                placeholder="Add Comment"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  setAddComment(e.target.value);
                }}
                value={addComment}
              />
              <Button className="SaveButton" onClick={changeStatus}>
                Update Status
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </NavbarHoc>
  );
}

export default Settlements;
