import React, { useState, useEffect } from "react";
import { Box, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import "./List.css";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../networkRequest/axiosBase";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Filter from "../../filter-component/Filter";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { getAllSuperMerchantList } from "../../../networkRequest/request/merchant";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import Search from "@mui/icons-material/Search";
import CloseRounded from "@mui/icons-material/CloseRounded";
import NavbarHoc from "../../../pages/navhoc/index";
import Config from "../../../config.json";

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});

// main function

const SuperMerchantList = (props) => {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("_id");
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(25);
  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearch] = React.useState();
  const [searchByCalled, setsearchByCalled] = React.useState(false);
  const [merchantSearch, setMerchantSearch] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [height, setHeight] = React.useState(170);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dummyselectedmerchant, setDummySelectedmerchant] = React.useState();
  const [selectedmerchant, setselectedmerchant] = React.useState(null);
  const [rolePermission, setRolePermission] = React.useState([]);

  const open = Boolean(anchorEl);
  const history = useNavigate();

  const [filter, setFilter] = React.useState([
    { key: "", type: "", value: "" },
  ]);
  const filterOptons = [
    { property: "Super Merchant Name", value: "merchant_name" },
    { property: "Email", value: "email_id" },
    { property: "Contact Number", value: "mobile_no" },
  ];
  const filterContains = [
    { property: "Is", value: "is" },
    { property: "Like", value: "contain" },
    { property: "Not Like", value: "not_contain" },
  ];
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setselectedmerchant(row.merchant_data);
    setDummySelectedmerchant(row.merchant_data);
    if (row.merchant_data?.length > 2) {
      setHeight(250);
    }
    if (row.merchant_data?.length > 5) {
      setHeight(350);
    }
    if (row.merchant_data?.length > 10) {
      setHeight(500);
    }
  };
  const handleClose = (id) => {
    setAnchorEl(null);
  };
  const redirecttodetail = (_id) => {
    history(`/supermerchant/addmerchant/${_id}`);
  };
  const redirecttoMerchantdetail = (_id) => {
    history(`/merchants/profile?merchantId=${_id}`);
  };
  const handleFilter = async (filter1) => {
    setFilter(filter1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value));
    setPage1(0);
  };

  const handleSearchButton = (e) => {
    setsearchByCalled(!searchByCalled);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.key === "Enter") {
      setsearchByCalled(!searchByCalled);
    }
  };
  const handleInnerSearch = async (value) => {
    setMerchantSearch(value);
    let data1 = selectedmerchant.filter((row) => {
      let name =
        (row?.first_name != "" ? row?.first_name : row?.merchant_name) == ""
          ? row?._id
          : row?.first_name != ""
          ? row?.first_name
          : row?.merchant_name;
      return name && name.toUpperCase().indexOf(value.toUpperCase()) > -1;
    });

    if (value !== "") {
      setDummySelectedmerchant([...data1]);
    } else {
      setDummySelectedmerchant([...selectedmerchant]);
    }
  };

  useEffect(() => {
    setLoading(true);
    setData([]);
    if (containsSpecialCharacters(searchBy)) {
      setCount(0);
      setLoading(false);
    } else {
      const filtersval = filter[0].key != "" ? JSON.stringify(filter) : "";
      getAllSuperMerchantList(
        page,
        rowsPerPage,
        orderBy,
        order,
        searchBy,
        filtersval
      ).then((response) => {
        setData(response.data.data);
        setCount(response.data.count);
        setLoading(false);
      });
    }
  }, [page, rowsPerPage, count,searchByCalled]);

  useEffect(() => {
    const filtersval = filter[0].key != "" ? JSON.stringify(filter) : "";
    if (filtersval != "") {
      setData([]);
      setLoading(true);
      getAllSuperMerchantList(
        page,
        rowsPerPage,
        orderBy,
        order,
        searchBy,
        filtersval
      ).then((response) => {
        setData(response.data.data);
        setCount(response.data.count);
        setLoading(false);
      });
    }
  }, [filter]);

  // filter popup sections
  const [anchorE2, setAnchorE2] = React.useState(null);

  const pophandleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const pophandleClose = () => {
    setAnchorE2(null);
  };
  const ResetFilter = async (event) => {
    setFilter([{ key: "", type: "", value: "" }]);
    setsearchByCalled(!searchByCalled);
  };

  function checklenght(val, lenght) {
    if (val?.length > lenght) {
      return val?.substring(0, lenght) + "..";
    }
    if (!val) {
      return "NA";
    }
    return val;
  }

  // const addSuperMerchant = (event) => {
  //   window.location.href = "/supermerchant/addmerchant";
  // };
  const openpop = Boolean(anchorE2);
  const id1 = openpop ? "simple-popover" : undefined;

  // filter popup sections end

  return (
    <NavbarHoc
      id={Config.PermissionId.SuperMerchants}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Super Merchant</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                // sm={12}
                md={12}
                lg={12}
                // xl={4}
                className="merchant_grid_sec"
              >
                <div
                  className="merchant_list_filter_div"
                  style={{ height: "100%" }}
                >
                  <CssTextField
                    className="serachBar"
                    id="new-menu"
                    placeholder="Search"
                    variant="outlined"
                    // fullWidth="false"
                    onKeyUp={handleSearch}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton className="merchant_search_icon">
                            <i
                              onClick={handleSearchButton}
                              class="fa fa-search"
                              aria-hidden="true"
                            ></i>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Tooltip title="Filter records">
                    <div>
                      <i
                        style={{
                          fontSize: "30px",
                          marginLeft: "10px",
                          color: "#757575",
                          cursor: "pointer",
                        }}
                        onClick={pophandleClick}
                        class="fa fa-filter"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Tooltip>

                  {filter[0].key != "" ? (
                    <Tooltip title="Refresh to records">
                      <div>
                        <i
                          style={{
                            fontSize: "30px",
                            marginLeft: "10px",
                            color: "#757575",
                            cursor: "pointer",
                          }}
                          onClick={ResetFilter}
                          class="fa fa-refresh"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Tooltip>
                  ) : (
                    // {filter[0].key != "" ? (
                    //   <Button
                    //     className="ClearFilter"
                    //     variant="contained"
                    //     startIcon={<CloseRounded />}
                    //     onClick={ResetFilter}
                    //   >
                    //     Clear
                    //   </Button>
                    // <Button
                    //   variant="outlined"
                    //   color="secondary"
                    //   startIcon={<CloseTwoTone />}
                    //   onClick={ResetFilter}
                    //  className="clearfilter"
                    // >
                    //   Clear
                    // </Button>
                    <></>
                  )}
                </div>
              </Grid>
              {rolePermission.includes("ADD") && (
                <Link className="margin-auto" to={`/supermerchant/addmerchant`}>
                  <Button
                    className="merchant_list_add_new_merchant"
                    variant="contained"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                  >
                    Add
                  </Button>
                </Link>
              )}
            </div>
          </div>

          <TableContainer
            className={
              data.length > 0 && !loading
                ? "table_container"
                : "table_container table_container_nodata"
            }
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Super Merchant ID</TableCell>
                  <TableCell>Super Merchant Name</TableCell>
                  <TableCell> Email ID</TableCell>
                  <TableCell>Contact Number</TableCell>

                  <TableCell>Merchant</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row">
                {data != " " &&
                  data.map((row, index) => (
                    <TableRow
                      class="merchant_tbl_edit_icon"
                      style={{ background: "none" }}
                    >
                      <TableCell
                        onClick={(e) =>
                          (rolePermission.includes("EDIT") ||
                            rolePermission.includes("VIEW")) &&
                          redirecttodetail(row?._id)
                        }
                        className="tbl_id"
                      >
                        <Link
                          to="#"
                          style={{ textDecoration: "none", color: "#1058ff" }}
                        >
                          {row?._id?.length > 25 ? (
                            <Tooltip title={row?._id}>
                              <Button className="tbl_id1">
                                {checklenght(row?._id, 25)}
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button className="tbl_id1">
                              {checklenght(row?._id, 25)}
                            </Button>
                          )}
                        </Link>
                      </TableCell>

                      <TableCell
                        onClick={(e) =>
                          rolePermission.includes("EDIT") &&
                          redirecttodetail(row?._id)
                        }
                      >
                        {row?.merchant_name?.length > 25 ? (
                          <Tooltip title={row?.merchant_name}>
                            <Button className="tbl_id1">
                              {checklenght(row?.merchant_name, 25)}
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button className="tbl_id1">
                            {checklenght(row?.merchant_name, 25)}
                          </Button>
                        )}
                      </TableCell>

                      <TableCell
                        onClick={(e) =>
                          rolePermission.includes("EDIT") &&
                          redirecttodetail(row?._id)
                        }
                      >
                        {row?.email_id?.length > 25 ? (
                          <Tooltip title={row?.email_id}>
                            <Button className="tbl_id1">
                              {checklenght(row?.email_id, 25)}
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button className="tbl_id1">
                            {checklenght(row?.email_id, 25)}
                          </Button>
                        )}
                      </TableCell>

                      <TableCell
                        onClick={(e) =>
                          rolePermission.includes("EDIT") &&
                          redirecttodetail(row?._id)
                        }
                      >
                        {row?.mobile_no?.length > 25 ? (
                          <Tooltip title={row?.mobile_no}>
                            <Button className="tbl_id1">
                              {checklenght(row?.mobile_no, 25)}
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button className="tbl_id1">
                            {checklenght(row?.mobile_no, 25)}
                          </Button>
                        )}
                      </TableCell>

                      <TableCell>
                        {row?.merchant_data?.length > 0 ? (
                          <Link
                            to="#"
                            className="merchant_list_more_option"
                            onClick={(e) => handleClick(e, row)}
                          >
                            {/* {row?.merchant_data[0]?.merchant_name} */}
                            {/* {(row?.merchant_data[0]?.merchant_name != '' ? row?.merchant_data[0]?.merchant_name : row?.merchant_data[0]?.first_name) == null ? row?.merchant_data[0]?._id : (row?.merchant_data[0]?.merchant_name != '' ? row?.merchant_data[0]?.merchant_name : row?.merchant_data[0]?.first_name)} */}
                            {row?.merchant_data[0]?.first_name != ""
                              ? row?.merchant_data[0]?.first_name
                              : row?.merchant_data[0]?.merchant_name}
                            {/* {row?.merchant_data[0]?.first_name} */}
                            &nbsp;
                            {`${
                              row?.merchant_data?.length - 1 > 0
                                ? "...(" +
                                  parseInt(row?.merchant_data?.length - 1) +
                                  " more) "
                                : ""
                            }  `}
                          </Link>
                        ) : (
                          <>0</>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress style={{ color: "#1058ff" }} />
              </div>
            ) : data == "" ? (
              <div className="no_data_found loader_data_tbl">
                <p>No Records Found</p>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          {!loading && data.length > 0 ? (
            <TablePagination
              className="table_pagination"
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}

          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            className="merchant_popup_list"
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: height,
                padding: "15px",
              },
            }}
          >
            <TableContainer sx={{ maxHeight: 410, overflowX: "hidden" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <CssTextField
                      id="new-menu"
                      placeholder="search"
                      variant="outlined"
                      value={merchantSearch}
                      onChange={(e) => handleInnerSearch(e.target.value)}
                      sx={{ width: "70%", borderRadius: "50px" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="merchant_popup_search_icon">
                            <IconButton color="primary">
                              <SearchOutlinedIcon sx={{ color: "#C9C9C9" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dummyselectedmerchant
                    ?.slice(
                      page1 * rowsPerPage1,
                      page1 * rowsPerPage1 + rowsPerPage1
                    )
                    .map((row) => {
                      return (
                        <TableRow
                          className="merchant_popup_itemList_row"
                          key={row.code}
                        >
                          <Div
                            className=""
                            style={{ "border-bottom": "0.5px solid #c9c9c9" }}
                          >
                            <MenuItem
                              key={row}
                              selected={row === "none"}
                              onClick={(e) => redirecttoMerchantdetail(row._id)}
                            >
                              {row.first_name
                                ? row.first_name
                                : row.merchant_name}
                              {/* {row.first_name} */}
                              {/* {row.merchant_name} */}
                              {/* {row._id} */}
                            </MenuItem>
                          </Div>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <div>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={dummyselectedmerchant?.length}
                rowsPerPage={rowsPerPage1}
                page={page1}
                onPageChange={handleChangePage1}
                onRowsPerPageChange={handleChangeRowsPerPage1}
              />
            </div>
          </Menu>
        </div>
      </div>

      <div>
        <Popover
          id={id1}
          open={openpop}
          anchorEl={anchorE2}
          onClose={pophandleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ marginLeft: "125px", height: "500px", marginTop: "10px" }}
        >
          <Typography sx={{ p: 2 }}>
            <Filter
              filter1={handleFilter}
              pophandleClose={pophandleClose}
              optionns={filterOptons}
              contains={filterContains}
              filter={filter}
            />
          </Typography>
        </Popover>
      </div>
    </NavbarHoc>
  );
};

function containsSpecialCharacters(str) {
  var regex = /[~`!#$%\^&*+=\-\[\]\';,/{}|\\":<>\?]/g;
  return regex.test(str);
}

export default SuperMerchantList;
