import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import {
  getBankList,
  addBank,
  updateBank,
} from "../../../networkRequest/request/banks";
import "./Banks.css";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import BanksDetail from "./BanksDetail";
import { toast } from "react-toastify";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});

const BankList = (props) => {
  const { rolePermission } = props;
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchBy, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedBank, setSeletedBank] = React.useState({
    name: "",
    icon: "",
    logo: "",
    instalment: [],
    min_epp_amount: 0,
    is_epp_available: false,
    is_deleted: false,
    is_active: false,
  });
  const [type, setType] = React.useState("ADD");

  const closeDrawer = () => {
    setSeletedBank({
      name: "",
      icon: "",
      logo: "",
      instalment: [],
      min_epp_amount: 0,
      is_epp_available: false,
      is_deleted: false,
      is_active: false,
    });
    setDrawerOpen(false);
    setType("ADD");
  };

  const openDrawer = (data, mode) => {
    setSeletedBank(data);
    setDrawerOpen(true);
    setType(mode);
  };

  const handleSearchButton = (e) => {
    setPage(0);
    getList(searchBy);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.key === "Enter") {
      setPage(0);
      return getList(e.target.value);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getList = async (search) => {
    try {
      const query = `?pageNo=${page}&rowsPerPage=${rowsPerPage}&search=${search}`;
      const response = await getBankList(query);
      console.log(response.data.data);
      setData(response.data.data);
      setCount(response.data.count || 0);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    } finally {
    }
  };

  useEffect(() => {
    getList(searchBy);
  }, [page, rowsPerPage]);

  const handleChange = (event) => {
    const name = event.target.name;
    if (name == "is_epp_available" || name == "is_active") {
      setSeletedBank({ ...selectedBank, [name]: event.target.checked });
    } else {
      setSeletedBank({ ...selectedBank, [name]: event.target.value });
    }
  };
  const handleInstallmentChange = (event, value, reason) => {
    const new_data = value.filter(
      (v) => !isNaN(Number(v)) && Number(v) > 0 && Number(v) <= 100
    );
    setSeletedBank({ ...selectedBank, instalment: new_data });
  };

  const handleSubmit = async () => {
    try {
      let res = null;
      const saveData = JSON.parse(JSON.stringify(selectedBank));
      if (saveData.is_epp_available) {
        if (!saveData.instalment?.length) {
          return toast("Select Number of instalment", {
            type: "error",
            autoClose: 5000,
          });
        }

        if (
          Number(saveData.min_epp_amount) < 0 ||
          saveData.min_epp_amount?.toString() == ""
        ) {
          return toast(
            "The minimum amount for an installments should be zero or higher",
            {
              type: "error",
              autoClose: 5000,
            }
          );
        }
        saveData.min_epp_amount = Number(saveData.min_epp_amount);
      }
      if (type == "ADD") {
        res = await addBank(saveData);
      } else {
        res = await updateBank(saveData._id, saveData);
      }
      if (res) {
        toast(res.data.message, {
          type: "success",
          autoClose: 5000,
        });
        await getList(searchBy);
        closeDrawer();
      }
    } catch (error) {
      return toast(error?.response?.data?.message || "Something went wrong!", {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="bank-master">
      <div className="bank-master-heading">
        <p>Banks List</p>
      </div>
      <div className="bank-master-filter-section">
        <CssTextField
          className="serachBar"
          id="new-menu"
          placeholder="Search"
          variant="outlined"
          // fullWidth="false"
          onKeyUp={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton className="merchant_search_icon">
                  <i
                    onClick={handleSearchButton}
                    class="fa fa-search"
                    aria-hidden="true"
                  ></i>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {rolePermission.includes("ADD") && (
          <Button
            className="merchant_list_add_new_merchant"
            variant="contained"
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => openDrawer({}, "ADD")}
          >
            Add
          </Button>
        )}
      </div>

      <TableContainer
        className={
          data.length > 0 && !loading
            ? "table_container"
            : "table_container table_container_nodata"
        }
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="data_tbl_head_row">
            <TableRow style={{ background: "none" }}>
              <TableCell>name</TableCell>
              <TableCell>EPP</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {data.map((row) => (
              <TableRow key={row._id}>
                <TableCell>
                  <div className="name-logo">
                    {row.icon && (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${row.icon}`}
                      />
                    )}
                    <p>{row.name}</p>
                  </div>
                </TableCell>
                <TableCell>{row.is_epp_available ? "Yes" : "No"}</TableCell>
                <TableCell>{row.is_active ? "Active" : "Inactive"}</TableCell>
                <TableCell>
                  {moment(row.createdAt).format("YYYY/MM/DD hh:mm A")}
                </TableCell>
                <TableCell>
                  <div
                    className="edit"
                    onClick={() => openDrawer(row, "UPDATE")}
                  >
                    <i className="fa fa-pencil"></i>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!data.length && (
          <div className="no_data_found loader_data_tbl">
            <p>No Data Found</p>
          </div>
        )}
      </TableContainer>
      <TablePagination
        className="table_pagination"
        rowsPerPageOptions={data.length ? [25, 50, 100] : []}
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <SwipeableDrawer
        anchor={"right"}
        open={drawerOpen}
        onClose={closeDrawer}
        onOpen={() => {}}
      >
        <Box
          className="kyc_detail_box"
          sx={{
            width: "right" === "top" || "right" === "bottom" ? "auto" : 600,
            overflowX: "hidden",
          }}
          role="presentation"
        >
          <List>
            <ListItem className="merchant_edit_drower_menu_top_sec">
              <p>Bank Details</p>
            </ListItem>
          </List>
          <Divider />
          <div className="kyc_array">
            <BanksDetail
              data={selectedBank}
              closeDrawer={closeDrawer}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleInstallmentChange={handleInstallmentChange}
            />
          </div>
        </Box>
      </SwipeableDrawer>
    </div>
  );
};

export default BankList;
