import AxiosBase from "../axiosBase";

export const getAllBusiness = (id) => {
  return new Promise((resolve, reject) => {
    // const data = { pageNo, rowsPerPage, sortBy, sortOrder };
    AxiosBase.get(`/admin/merchant/${id}/business-account?sortOrder=asc`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}


export const getContacts = (pageNo, rowsPerPage, sortBy, sortOrder,id) => {
    return new Promise((resolve, reject) => {
      AxiosBase.get(`/admin/business-account/${id}/contact?rowsPerPage=${rowsPerPage}&pageNo=${pageNo}&sortBy=${sortBy}&sortOrder=${sortOrder}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("getAllBusiness123", err);
          reject(err);
        });
    })
  }


export const getLedgers = (id,bid) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/admin/business-account/${bid}/contact/${id}/ledger`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("getAllBusiness123", err);
        reject(err);
      });
  })
}


