import React, { useEffect } from "react";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import TablePagination from "@mui/material/TablePagination";
import { getAllMenus } from "../../../../networkRequest/request/useraccesscontrol";
import Grid from "@mui/material/Grid";
import "./index.css";
import Filter from "../../../filter-component/Filter";
import CircularProgress from "@mui/material/CircularProgress";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import NavbarHoc from "../../../../pages/navhoc/index";
import DateRangeComp from "../../../datepicker";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import { useNavigate } from "react-router-dom";
import Config from "../../../../config.json";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});
const MenuLayout = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [date, setDate] = React.useState({
    startDate: "",
    endDate: "",
  });
  const [rolePermission, setRolePermission] = React.useState([]);
  const [searchBy, setSearch] = React.useState();
  const [searchBar, setSearchBar] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const history = useNavigate();
  const [filter, setFilter] = React.useState([
    { key: "", type: "", value: "" },
  ]);
  const [searchByCalled, setsearchByCalled] = React.useState(false);

  const filterOptons = [
    { property: "Menu Id", value: "id" },
    { property: "Menu Name", value: "title" },
    { property: "Created By", value: "created_by_name" },
    // { property: "Email", value: "email_id" },
    // { property: "Contact Number", value: "mobile_no" },
  ];
  const filterContains = [
    { property: "Is", value: "is" },
    { property: "Like", value: "contain" },
    { property: "Not Like", value: "not_contain" },
  ];
  useEffect(() => {
    const filtersval = filter[0].key != "" ? JSON.stringify(filter) : "";
    let dateparams = "";
    if (date.startDate != "") {
      dateparams = `&dateRange=custom&dateFrom=${date.startDate}&dateTo=${date.endDate}&dateField=createdAt`;
    }
    getAllMenus(
      page,
      rowsPerPage,
      "_id",
      "desc",
      searchBy,
      filtersval,
      dateparams
    ).then((menulist) => {
      setData(menulist.data.data);
      setCount(menulist.data.count);
      setLoading(false);
    });
  }, [page, rowsPerPage, count, searchByCalled, filter, date]);

  const handleSearch = (e) => {
    if (e.key === "Enter" || e.key == undefined) {
      setSearch(searchBar);
      setsearchByCalled(!searchByCalled);
    }
  };
  const handleSearchButton = (e) => {
    setsearchByCalled(!searchByCalled);
  };
  const ResetFilter = async (event) => {
    setFilter([{ key: "", type: "", value: "" }]);
  };
  const [anchorE2, setAnchorE2] = React.useState(null);
  const handleFilter = async (JSONfilter) => {
    setFilter(JSONfilter);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handledates = (values) => {
    setDate(values);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const pophandleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const pophandleClose = () => {
    setAnchorE2(null);
  };

  // const redirecttodetail = (_id) => {
  //   history(`/menu/addmenu/${_id}`)
  // };

  const openpop = Boolean(anchorE2);
  const id1 = openpop ? "simple-popover" : undefined;
  return (
    <NavbarHoc
      id={Config.PermissionId.Menu}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Menu</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter innerFilterUser">
              <Grid
                item
                xs={12}
                // sm={12}
                md={8}
                lg={6}
                // xl={4}
                className="merchant_grid_sec"
              >
                <div
                  className="merchant_list_filter_div"
                  style={{ height: "100%" }}
                >
                  <CssTextField
                    className="serachBar"
                    id="new-menu"
                    placeholder="Search"
                    variant="outlined"
                    // fullWidth="false"
                    onChange={(e) => {
                      setSearchBar(e.target.value);
                    }}
                    onKeyUp={handleSearch}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton className="merchant_search_icon">
                            <i
                              onClick={handleSearch}
                              class="fa fa-search"
                              aria-hidden="true"
                            ></i>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  placeholder="Search"
                  variant="outlined"
                  fullWidth
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton className="merchant_search_icon">
                          <img style={{marginLeft:'-26px'}} src="/search.svg" alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
       */}
                  {/* <img
                    className="merchant_list_filter_img"
                    src="/Filter.svg"
                    aria-describedby={id1}
                    alt=""
                    onClick={pophandleClick}
                  />
                 */}
                  <Tooltip title="Filter records">
                    <div>
                      <i
                        style={{
                          fontSize: "30px",
                          marginLeft: "10px",
                          color: "#757575",
                          cursor: "pointer",
                        }}
                        onClick={pophandleClick}
                        class="fa fa-filter"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Tooltip>
                  <DateRangeComp handledates={handledates}> </DateRangeComp>
                  {filter[0].key != "" && (
                    <Tooltip title="Refresh to records">
                      <div>
                        <i
                          style={{
                            fontSize: "30px",
                            marginLeft: "10px",
                            color: "#757575",
                            cursor: "pointer",
                          }}
                          onClick={ResetFilter}
                          class="fa fa-refresh"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </Grid>
              <Link className="margin-auto" to={`/menu/addmenu`}></Link>
            </div>
          </div>
          <TableContainer
            className={
              data.length > 0 && !loading
                ? "table_container"
                : "table_container table_container_nodata"
            }
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Menu ID</TableCell>
                  <TableCell>Menu Name</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Created at</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              {!loading ? (
                <TableBody className="data_tbl_body_row">
                  {data?.map((curElm) => {
                    const {
                      _id,
                      title,
                      created_by_name,
                      createdAt,
                      is_active,
                    } = curElm;

                    return (
                      <TableRow
                        // onClick={(e) => redirecttodetail(_id)}
                        className=" tbl_id merchant_data_tbl_row merchant_tbl_edit_icon"
                      >
                        <TableCell>
                          {rolePermission.includes("EDIT") ||
                          rolePermission.includes("VIEW") ? (
                            <Link
                              to={`/menu/addmenu/${_id}`}
                              style={{
                                textDecoration: "none",
                                color: "#1058ff",
                              }}
                            >
                              {_id}
                            </Link>
                          ) : (
                            _id
                          )}
                        </TableCell>

                        <TableCell>
                          {title?.length > 10 ? (
                            <Tooltip title={title}>
                              <Button className="tbl_id">
                                {checklenght(title, 20)}
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button className="tbl_id">
                              {checklenght(title, 20)}
                            </Button>
                          )}
                        </TableCell>
                        <TableCell>{created_by_name}</TableCell>
                        <TableCell>
                          {moment.utc(createdAt).format("YYYY/MM/DD hh:mm A")}
                          {/* {moment().utc().format("YYYY/MM/DD hh:mm A")} */}
                        </TableCell>
                        <TableCell>
                          {is_active ? "Active" : "Inactive"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <></>
              )}
            </Table>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress style={{ color: "#1058ff" }} />
              </div>
            ) : data == "" ? (
              <div className="no_data_found loader_data_tbl">
                <p>No Data Found</p>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          {!loading ? (
            <TablePagination
              className="table_pagination"
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </div>
        <Popover
          id={id1}
          open={openpop}
          anchorEl={anchorE2}
          onClose={pophandleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ marginLeft: "135px", height: "500px", marginTop: "10px" }}
        >
          <Typography sx={{ p: 2 }}>
            <Filter
              filter1={handleFilter}
              pophandleClose={pophandleClose}
              optionns={filterOptons}
              contains={filterContains}
              filter={filter}
            />
          </Typography>
        </Popover>
      </div>
    </NavbarHoc>
  );
};

function checklenght(val, lenght) {
  if (val?.length > lenght) {
    return val?.substring(0, lenght) + "..";
  }
  if (!val) {
    return "NA";
  }
  return val;
}

export default MenuLayout;
