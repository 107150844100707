export const safexpay_seamless = [
  {
    key: "mid",
    label: "MID",
    type: "text",
    required: false,
  },
  {
    key: "secret_key",
    label: "Secret Key",
    type: "text",
    required: false,
  },
  {
    key: "aggregator_id",
    label: "Aggregator Id",
    type: "text",
    required: false,
  },
  {
    key: "pg_id",
    label: "PD Id",
    type: "text",
    required: false,
  },
  {
    key: "scheme_id",
    label: "Scheme Id",
    type: "text",
    required: false,
  },
];
export const safexpay_nonseamless = safexpay_seamless;

export const checkout_seamless = [
  {
    key: "mid",
    label: "MID",
    type: "text",
    required: false,
  },
  {
    key: "secret_key",
    label: "Secret Key",
    type: "text",
    required: false,
  },
];
export const checkout_nonseamless = checkout_seamless;

export const stripe_seamless = [
  {
    key: "product_id",
    label: "Product Id",
    type: "text",
    required: false,
  },
  {
    key: "secret_key",
    label: "Secret Key",
    type: "text",
    required: false,
  },
  {
    key: "public_key",
    label: "Public Key",
    type: "text",
    required: false,
  },
];
export const stripe_nonseamless = stripe_seamless;

export const paytabs_seamless = [
  {
    key: "server_key",
    label: "Server Key",
    type: "text",
    required: false,
  },
  {
    key: "client_key",
    label: "Client Key",
    type: "text",
    required: false,
  },
];
export const paytabs_nonseamless = paytabs_seamless;

export const myfatoorah_seamless = [
  {
    key: "api_key",
    label: "API Key",
    type: "text",
    required: true,
  },
  {
    key: "url",
    label: "Base URL",
    type: "text",
    required: false,
  },
  {
    key: "script_url",
    label: "Script URL",
    type: "text",
    required: false,
  },
  {
    key: "payment_method_id",
    label: "Payment Method Id",
    type: "text",
    required: false,
  },
  {
    key: "base_currency",
    label: "Base Currency",
    type: "text",
    required: false,
  },
  {
    key: "supplier_code",
    label: "Supplier Code",
    type: "text",
    required: false,
  },
];
export const myfatoorah_nonseamless = myfatoorah_seamless;
