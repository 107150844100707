import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";

import AxiosBase from "../../../networkRequest/axiosBase";
import "./index.css";

const menus = [
  { label: "Dashboard", code: "DASHBOARD" },
  { label: "Account", code: "ACCOUNT" },
  { label: "Orders", code: "ORDERS" },
  { label: "Refunds", code: "REFUNDS" },
  { label: "Reports", code: "REPORTS" },
  { label: "Customers", code: "CUSTOMERS" },
  { label: "Suppliers", code: "SUPPLIERS" },
  { label: "Invoices", code: "INVOICES" },
  { label: "Invoice Setting", code: "INVOICE_SETTING" },
  { label: "Terminals", code: "TERMINALS" },
  { label: "Split Invoices", code: "SPLIT_INVOICE" },
  { label: "Menu", code: "MENU" },
  { label: "Hosted Page", code: "HOSTED_PAGE" },
  { label: "Team Members", code: "TEAM_MEMBERS" },
  { label: "Roles", code: "ROLES" },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

export default function MerchantPanelMenus(props) {
  const { rolePermission } = props;
  const [ogData, setOgData] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [resetEnabled, setResetEnabled] = useState(false);

  useEffect(() => {
    if (props.parent === "MERCHANT_DETAILS") {
      if (!props.data?.merchant_panel_menus?.length) {
        setOgData(null);
        setData(null);
        setResetEnabled(false);
      } else {
        setOgData(props.data?.merchant_panel_menus || null);
        setData(props.data?.merchant_panel_menus || null);
        setResetEnabled(
          props.data?.merchant_panel_menus?.length ? true : false
        );
      }
    } else {
      getData();
    }
  }, []);

  const getData = async () => {
    try {
      const resp = await AxiosBase.get("/admin/app/config");
      setOgData(resp.data?.merchant_panel_menus || []);
      setData(resp.data?.merchant_panel_menus || []);
    } catch (error) {
      toast(error?.response?.data?.message || "Something Went Wrong", {
        type: "error",
        autoClose: 2000,
      });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const confirmReset = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setData(ogData);
    setIsEdit(false);
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      setData([...data, e.target.value]);
    } else {
      const newData = data.filter((dt) => dt !== e.target.value);
      setData(newData);
    }
  };

  const handleSave = async () => {
    try {
      if (props.parent === "MERCHANT_DETAILS") {
        await AxiosBase.patch(`/admin/customers/config/${props.customerId}`, {
          merchant_panel_menus: data,
        });
        setResetEnabled(true);
      } else {
        await AxiosBase.patch("/admin/app/config", {
          merchant_panel_menus: data,
        });
      }
      setIsEdit(false);
      setOgData(data);
      toast("Merchant Menus Successfully Updated", {
        type: "success",
        autoClose: 2000,
      });
    } catch (error) {
      toast(error?.response?.data?.message || "Something Went Wrong", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  const handleReset = async () => {
    try {
      if (props.parent === "MERCHANT_DETAILS") {
        await AxiosBase.patch(`/admin/customers/config/${props.customerId}`, {
          merchant_panel_menus: null,
        });
        setOgData(null);
        setData(null);
        setResetEnabled(false);
        setOpen(false);
      }
      toast("Merchant Menus Reset Successfully", {
        type: "success",
        autoClose: 2000,
      });
    } catch (error) {
      toast(error?.response?.data?.message || "Something Went Wrong", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  const customizeMenu = async () => {
    try {
      const resp = await AxiosBase.get("/admin/app/config");
      setData(resp.data?.merchant_panel_menus || []);
      setIsEdit(true);
    } catch (error) {
      toast(error?.response?.data?.message || "Something Went Wrong", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  if (data == null && props.parent === "MERCHANT_DETAILS") {
    return (
      <div className="mp-menu-main-mrt mp-menu-main-mrt-customize">
        <p>To Customized Menu for this merchant Click on below button</p>
        <Button variant="contained" onClick={customizeMenu}>
          Customize menu
        </Button>
      </div>
    );
  }

  return (
    <div
      className={`mp-menu-main ${
        props.parent === "MERCHANT_DETAILS" ? "mp-menu-main-mrt" : ""
      }`}
    >
      <div className="mp-menus-list">
        {menus.map((menu) => (
          <div className="mp-menus-list-card">
            <FormControlLabel
              value="start"
              control={
                <Switch
                  color="primary"
                  value={menu.code}
                  checked={data?.includes(menu.code)}
                  onChange={handleChange}
                />
              }
              label={menu.label}
              disabled={!isEdit}
              labelPlacement="start"
            />
          </div>
        ))}
      </div>
      {!isEdit ? (
        <div className="button-section">
          {rolePermission && rolePermission.includes("EDIT") && (
            <Button
              className="merchant_edit_btn mrt-btn"
              variant="contained"
              onClick={() => {
                setIsEdit(!isEdit);
              }}
            >
              Edit
            </Button>
          )}
          {props.parent === "MERCHANT_DETAILS" && (
            <Button
              className={`merchant_reset_btn mrt-btn ${
                !resetEnabled ? "merchant_reset_btn_disabled" : ""
              }`}
              variant="contained"
              onClick={confirmReset}
              disabled={!resetEnabled}
            >
              Reset
            </Button>
          )}
        </div>
      ) : (
        <div className="button-section">
          <Button
            className="merchant_cancel_btn mrt-btn"
            variant="contained"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          {rolePermission && rolePermission.includes("EDIT") && (
            <Button
              className="merchant_save_btn mrt-btn"
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          )}
        </div>
      )}

      <div>
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              <p>Confirm Reset</p>
              <span>
                Are you sure you wish to reset merchants' menu settings?
              </span>
              <br />
              <span>
                This action will display the default menu options to the
                merchant.
              </span>
              <div className="order_detail_refund_popup_btn">
                <Button
                  className="refund_save_btn"
                  variant="contained"
                  autoFocus
                  fullWidth
                  onClick={handleReset}
                >
                  Confirm
                </Button>
                <Button
                  className="refund_cancel_btn"
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  fullWidth
                >
                  Cancel
                </Button>
              </div>
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </div>
  );
}
