import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import AxiosBase from "../../../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import "./index.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams, Link } from "react-router-dom";
import NavbarHoc from "../../../../pages/navhoc/index";
import Config from "../../../../config.json";
import SettlementOrders from "../../../settlements/settlement-orders/Orders";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "500px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});
const MerchantUserDetail = (props) => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState({});
  const [next, setNext] = useState({});
  const [previous, setPrev] = useState({});
  const [count, setCount] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rolePermission, setRolePermission] = React.useState([]);
  const [controlEnable, setControlEnable] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [addComment, setAddComment] = React.useState("");
  const [PopUploading, setPopUploading] = React.useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event));
    setPage(0);
  };

  const id = useParams();

  useEffect(() => {
    Checkchanges();
  }, [page, rowsPerPage]);

  const Checkchanges = () => {
    AxiosBase.get(`/admin/merchant-contact/${id.id != "" ? id.id : ""}`).then(
      (response) => {
        console.log(response);
        setData(response.data.data);
        setLoading(false);
      }
    );
  };

  return (
    <NavbarHoc
      id={Config.PermissionId.Settlements}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <Box>
        <h1 className="merchant_title">Customer Details</h1>
      </Box>
      <div className="merchant_list_main_div">
        <div className="settlement_detail_inner_title_div">
          <p className="settlement_title">Customer Details</p>
        </div>

        {loading ? (
          <div className="no_data_found loader_data_tbl">
            <CircularProgress />
          </div>
        ) : data == "" ? (
          <div className="no_data_found loader_data_tbl">
            <p>No Data Found</p>
          </div>
        ) : (
          <>
            <div className="settlement_detail_input_dev">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Name</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={data.name}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Mobile No</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={data.mobile_no}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Email ID</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={data.email_id}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Status</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={data.status}
                      disabled
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div>
              <SettlementOrders
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                data={data}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rolePermission={rolePermission}
                from="customer"
              />
            </div>
          </>
        )}
      </div>
    </NavbarHoc>
  );
};

export default MerchantUserDetail;
