import React from "react";
import { Route, Switch } from "react-router-dom";
import Topheader from "../../universal-components/header/topHeader";
import Reports from "../../Components/reports";

function ReportsPage() {
  return (
    <div style={{ padding: "15px" }}>
      <Topheader />
      <Reports />
    </div>
  );
}

export default ReportsPage;
