import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import moment from "moment";

const SettlementStatement = React.forwardRef((props) => {
  let captureAmt = 0, refundAmt = 0, voidAmt = 0;
  props.data?.transactionData.map((transaction) => {
    // if(transaction.status == "Voided"){
    //   voidAmt += Number(transaction?.amount);
    // } else {
      captureAmt += Number(transaction?.capture_amount);
      refundAmt += Number(transaction?.refunded_amount);
    // }
  });
  return (
    <>
    <img
        src="/pdf_top_sec.svg"
        alt=""
        className="settlement_pdf_templete header-img"
      />
      <div className="inner_pdf_sec">
        <h3 className="Settlement_breakdown_heading">Settlement Statement</h3>
        <div className="Settlement_pdf_sectionD_chargeback">
          <Button className="order_lst_btn">Merchant Information</Button>
        </div>
        <div className="Settlement_pdf_section_Sett_Statement_mrchnt_info">
          <div className="Settlement_date">
            <p className="first-txt">merchant name:</p>
            <p className="second-txt-statemnt">{`${props.data?.customer_data?.first_name} ${props.data?.customer_data?.last_name}`}</p>
          </div>
          <div className="Settlement_date">
            <p className="first-txt">mobile number:</p>
            <p className="second-txt-statemnt">+{props.data?.customer_data?.mobile_no}</p>
          </div>
          <div className="Settlement_date">
            <p className="first-txt">Email:</p>
            <p className="second-txt-statemnt">{props.data?.customer_data?.email_id}</p>
          </div>
          <div className="Settlement_date">
            <p className="first-txt">bank name:</p>
            <p className="second-txt-statemnt">{props.data?.bank_name}</p>
          </div>
          {/* <div className="Settlement_date">
            <p className="first-txt">vat number:</p>
            <p className="second-txt-statemnt">NA</p>
          </div> */}
          <div className="Settlement_date">
            <p className="first-txt">settlement period:</p>
            <p className="second-txt-statemnt">{moment(props.data?.from_date).format("DD/MM/YYYY")} to {moment(props.data?.to_date).format("DD/MM/YYYY")}</p>
          </div>
        </div>
        <div className="Settlement_pdf_sectionD_chargeback">
          <Button className="order_lst_btn">settlement summary</Button>
        </div>
        <div className="settlement_view_pdf_tbl">
          <TableContainer>
            <Table className="settlement-summary-table" stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row_pdf settlement_view_pdf_tbl_head">
                <TableRow style={{ background: "none" }}>
                  <TableCell className="settlement-id-cell">Settlement Id</TableCell>
                  <TableCell>Settlement Currency</TableCell>
                  <TableCell>Amounts carried forward</TableCell>
                  <TableCell>Amounts this period</TableCell>
                  <TableCell>Captured</TableCell>
                  <TableCell>Refund Amount</TableCell>
                  {/* <TableCell>Void Amount</TableCell> */}
                  <TableCell>Adjustment</TableCell>
                  <TableCell>Settlement Fees</TableCell>
                  <TableCell>Refund Charges</TableCell>
                  <TableCell>Net amount paid out</TableCell>
                  <TableCell style={{"width": "40px"}}>Date to be paid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row_pdf ">
                <TableCell>{props.data?.settlement_id}</TableCell>
                <TableCell>{props.data?.currency}</TableCell>
                <TableCell>0</TableCell>
                <TableCell>{Number(props.data?.total_actual_amount).toFixed(2)}</TableCell>
                <TableCell>{Number(captureAmt).toFixed(2)}</TableCell>
                <TableCell>{Number(refundAmt).toFixed(2)}</TableCell>
                {/* <TableCell>{Number(voidAmt).toFixed(2)}</TableCell> */}
                <TableCell>{props.data?.refund_adjustment_amount >= 0 ? props.data?.refund_adjustment_amount : `(${props.data?.refund_adjustment_amount})`}</TableCell>
                <TableCell>{Number(props.data?.settlement_fees || 0).toFixed(2)}</TableCell>
                <TableCell>{Number(props.data?.refund_charges_amount).toFixed(2)}</TableCell>
                <TableCell>{Number(props.data?.total_settlement_amount).toFixed(2)}</TableCell>
                <TableCell>{moment(props.data?.createdAt).format("DD/MM/YYYY")}</TableCell>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div class="footer-img" style={{"position": "relative"}}>
        <div style={{"width": "100%"}}>
          <img src="/images/Urban Ledger Export PDF-07.jpg" style={{"height": "90px", "width": "100%"}} alt="img" />
        </div>
        <div style={{"width": "45%","position": "absolute", "bottom": "30%", "left": "6%","z-index":"999"}}>
              <b style={{"color":" #fff"}}><i class="fa fa-phone-square" aria-hidden="true"></i> Helpline: </b>
              <a target="_blank" href={props.contactLink
            } style={{"color": "#fff","text-decoration": "none"}}>{props.contactLink}</a><br/>
              <span style={{"color": "#fff", "font-size": "12px"}}>T & C apply.</span>
        </div>
        <div style={{"width": "40%","position": "absolute", "bottom": "37%", "right": "6%","text-align": "right"}}>
            <span style={{"color": "white"}}>Use Urban Ledger Now</span>
            <a target="_blank" href={props.appLink
          } style={{"margin-left": "5px", "color": "#726f7a","background-color": "white","border-radius": "4px","padding": "3px 10px","text-decoration": "none"}}>Install <img style={{"width": "13px"}} src="/images/Install-01.svg" alt="img" /></a>
        </div>
      </div>
    </>
  );
});

export default SettlementStatement;
