import AxiosBase from "../axiosBase";

export const getBranch = (AllBranch, MerchantId, BranchId) => {
  let Url = AllBranch
    ? `/admin/merchant/${MerchantId}/branch`
    : `/admin/merchant/${MerchantId}/branch/${BranchId}`;
  return new Promise((resolve, reject) => {
    AxiosBase.get(Url).then((response) => {
      resolve(response);
    });
  });
};

export const deleteTerminal = (Id) => {
  return new Promise((resolve, reject) => {
    AxiosBase.delete(`/admin/terminal/${Id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err.response);
      });
  });
};

export const addBranch = (MerchantId, reqBody) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/admin/merchant/${MerchantId}/branch`, reqBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("error", err.response);
        resolve(err.response);
      });
  });
};

export const getTerminal = (Flag, BranchId, TerminalId) => {
  let Url = "";
  if (Flag == "Branch") {
    Url = `/admin/terminal/${BranchId}/branch`;
  } else if (Flag == "Terminal") {
    Url = `/admin/terminal/${TerminalId}`;
  } else {
    Url = `/admin/terminal`;
  }
  return AxiosBase.get(Url);
};

export const updateTerminal = (Flag, TerminalId, reqBody) => {
  let Url = "";
  if (Flag == "Branch") {
    Url = `/admin/terminal`;
  } else if (Flag == "Terminal") {
    Url = `/admin/terminal/${TerminalId}`;
  } else {
    Url = `/admin/terminal/${TerminalId}/status`;
  }
  return new Promise((resolve, reject) => {
    AxiosBase.put(Url, reqBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("error", err);
        resolve(err.response);
      });
  });
};

export const addTerminal = (reqBody) => {
  return new Promise((resolve, reject) => {
    AxiosBase.put(`/admin/terminal`, reqBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("error", err);
        resolve(err.response);
      });
  });
};

export const UpdateTerminalStatus = (Id, reqBody) => {
  return new Promise((resolve, reject) => {
    AxiosBase.put(`/admin/terminal/${Id}/status`, reqBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("error", err);
        resolve(err.response);
      });
  });
};
