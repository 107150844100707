import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import styles from "./resetpassword.module.css";
import "font-awesome/css/font-awesome.css";
import { login } from "../../networkRequest/request/auth";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/auth";
import LockOutlined from "@mui/icons-material/LockOutlined";
import PersonOutline from "@mui/icons-material/PersonOutline";
import passwordValidator from "password-validator";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
function Resetpassword(props) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newError, setNewError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [flag, setFlag] = useState(false);

  const history = useNavigate();
  const schema = new passwordValidator();
  schema
    .is()
    .min(8, "Password must use 8 or more characters")
    .has()
    .uppercase(1, "Password must contain uppercase characters(e.g A-Z)")
    .has()
    .lowercase(1, "Password must contain lowercase characters(e.g a-z)")
    .has()
    .symbols(1, "Password must contain at least one symbol")
    .has()
    .digits(1, "Password must contain at least one number")
    .has()
    .not()
    .spaces()
    .is()
    .not()
    .oneOf(
      ["Pa$$w0rd", "UrbanLeder@123"],
      "Password must not be a commonly used word or phrase"
    );

  useEffect(() => {
    if (props.error) {
      props.onResetError();
      toast(props.error ? props.error : "Invalid Credentials", {
        type: "error",
        autoClose: 5000,
      });
      setSpinner(false);
    }
  }, [props.error]);

  const submitClick = () => {
    props.onReset({
      inputPass: newPassword,
      confirmpassword: confirmPassword,
      user_id: props.userData._id,
    });
  };

  const newPasswordChange = (e) => {
    if (e.target.value !== "") {
      let validate = schema.validate(e.target.value, { details: true });
      if (validate.length > 0) {
        setNewError(validate[0].message);
        setFlag(false);
      } else {
        if (confirmPassword !== "" && confirmPassword != e.target.value) {
          setConfirmError(`Password does not match`);
          setFlag(false);
        } else if (confirmPassword == e.target.value) {
          setConfirmError("");
          setFlag(true);
        } else {
          setFlag(false);
          setNewError("");
        }
      }
    } else {
      setNewError("");
      setFlag(false);
    }
    setNewPassword(e.target.value);
  };

  const confirmPasswordChange = (e) => {
    if (e.target.value !== "") {
      if (newPassword == e.target.value) {
        setConfirmError("");
        setFlag(true);
      } else {
        setConfirmError(`Password does not match`);
        setFlag(false);
      }
    } else {
      setConfirmError("");
    }
    setConfirmPassword(e.target.value);
  };

  if (!props.isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (props.isAuthenticated && !props.isTemporaryPassword) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <div className={styles.alignBox}>
        <div className={styles.outBox}>
          <div className={styles.ublSp}>
            <img src="/urban-logo.svg" alt="Urban-Logo" />
          </div>
          <div className={`${styles.ublSp} ${styles.ubdr}`}>
            <h1>Reset Password</h1>
            {props.isTemporaryPasswordMsg && (
              <p className={styles.passMsg}>{props.isTemporaryPasswordMsg}</p>
            )}
            <form className={styles.form}>
              <div className={styles.inputContainer}>
                <TextField
                  id="NewPassword"
                  className={styles.innerHelperText}
                  type="password"
                  name="NewPassword"
                  placeholder="New Password"
                  onChange={(e) => newPasswordChange(e)}
                  fullWidth
                  variant="outlined"
                  size="small"
                  helperText={newError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlined htmlColor="#8f8c8cbf" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.inputContainer}>
                  <TextField
                    id="Confirmpassword"
                    disabled={!(newError == "" && newPassword !== "")}
                    className={styles.innerHelperText}
                    type="text"
                    name="Confirmpassword"
                    placeholder="Confirm Password"
                    onChange={(e) => confirmPasswordChange(e)}
                    fullWidth
                    helperText={confirmError}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOutlined htmlColor="#8f8c8cbf" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <button
                type="submit"
                className={flag ? styles.signupBtn : styles.signupBlurBtn}
                onClick={(e) => {
                  e.preventDefault();
                  submitClick();
                }}
              >
                {spinner ? (
                  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (
                  "Save"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
    accessToken: state.auth.accessToken,
    refreshToken: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated,
    isTemporaryPassword: state.auth.isTemporaryPassword,
    isTemporaryPasswordMsg: state.auth.isTemporaryPasswordMsg,
    error: state.auth.error,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (emailId, password) =>
      dispatch(actions.authLogin({ email_id: emailId, password })),
    onReset: (data) => dispatch(actions.authReset(data)),
    onLogout: () => dispatch(actions.logoutUser()),
    onResetError: () => dispatch(actions.resetError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Resetpassword);
