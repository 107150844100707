import React from "react";
import { useState, useEffect } from "react";
import "./Settlement.css";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import AxiosBase from "../../../networkRequest/axiosBase";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  updateMerchantFees,
  getMerchantFees,
} from "../../../networkRequest/request/configuration";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { ArrowBack } from "@mui/icons-material";
import axios from "axios";
import RefundCharges from "./../../RefundCharges/RefundCharges";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Settlement = (props) => {
  const { rolePermission } = props;
  const [spinner, setSpinner] = useState(false);
  const [controlDisable, setControlDisable] = useState(false);
  const [editVisibility, setEditVisibility] = useState("hidden");
  const [saveCancelVisibility, setSaveCancelVisibility] = useState("visible");
  const [currentSaveCancelId, setCurrentSaveCancelId] = useState("");
  const [currentEditId, setCurrentEditId] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [selectedCharges, setSelectedCharges] = useState([]);
  const [open, setOpen] = useState(false);
  const [refundCharges, setRefundCharges] = React.useState(null);
  const [feesdata, setFeesData] = useState({
    _id: "",
    settlement_time: 0,
    refund_after_settlement: false,
    settlement_refund_limit: 0,
    hold_refund_active: false,
    refund_allowed: false,
    refund_charges: 0,
    settlement_fees: 0,
  });

  const { fromSource, customerId } = props;
  useEffect(() => {
    setSpinner(true);
    AxiosBase.get(`/admin/country-master`).then((res) => {
      setCountryList(res?.data);
    });
    getConfigData();
  }, []);

  const getConfigData = () => {
    if (fromSource == "merchant") {
      if (customerId !== "")
        getMerchantFees(customerId).then((response) => {
          setRefundCharges(response?.data?.refund_charges);
          setFeesData({
            settlement_time: response?.data?.settlement_time,
            refund_after_settlement: response?.data?.refund_after_settlement,
            settlement_refund_limit: response?.data?.settlement_refund_limit,
            hold_refund_active: response?.data?.hold_refund_active,
            refund_allowed: response?.data?.refund_allowed,
            settlement_fees: response?.data?.settlement_fees,
          });
          setSpinner(false);
        });
      else setSpinner(false);
    } else {
      AxiosBase.get(`/admin/app/config`).then((response) => {
        setRefundCharges(response?.data?.refund_charges);
        setFeesData({
          settlement_time: response?.data?.settlement_time,
          refund_after_settlement: response?.data?.refund_after_settlement,
          settlement_refund_limit: response?.data?.settlement_refund_limit,
          hold_refund_active: response?.data?.hold_refund_active,
          refund_allowed: response?.data?.refund_allowed,
          settlement_fees: response?.data?.settlement_fees,
        });
        setSpinner(false);
      });
    }
  };

  const editFees = () => {
    setSaveCancelVisibility("visible");
    setEditVisibility("hidden");
    setControlDisable(false);
  };

  const cancelFees = () => {
    setSpinner(true);
    if (fromSource == "merchant") {
      if (customerId !== "")
        getMerchantFees(customerId).then((response) => {
          setFeesData({
            settlement_time: response?.data?.settlement_time,
            refund_after_settlement: response?.data?.refund_after_settlement,
            settlement_refund_limit: response?.data?.settlement_refund_limit,
            hold_refund_active: response?.data?.hold_refund_active,
            refund_allowed: response?.data?.refund_allowed,
            refund_charges: response?.data?.refund_charges,
          });
          setSaveCancelVisibility("hidden");
          setEditVisibility("visible");
          setControlDisable(true);
          setSpinner(false);
        });
      else {
        setSaveCancelVisibility("hidden");
        setEditVisibility("visible");
        setControlDisable(true);
        setSpinner(false);
      }
    } else {
      AxiosBase.get(`/admin/app/config`).then((response) => {
        setFeesData({
          settlement_time: response?.data?.settlement_time,
          refund_after_settlement: response?.data?.refund_after_settlement,
          settlement_refund_limit: response?.data?.settlement_refund_limit,
          hold_refund_active: response?.data?.hold_refund_active,
          refund_allowed: response?.data?.refund_allowed,
          refund_charges: response?.data?.refund_charges,
        });
        setSaveCancelVisibility("hidden");
        setEditVisibility("visible");
        setControlDisable(true);
        setSpinner(false);
      });
    }
  };

  const handleChange = (event) => {
    try {
      if (event.target.checked)
        setFeesData({
          settlement_time: feesdata.settlement_time,
          hold_refund_active: feesdata.hold_refund_active,
          refund_after_settlement: event.target.checked,
          settlement_refund_limit: feesdata.settlement_refund_limit,
        });
      else
        setFeesData({
          settlement_time: feesdata.settlement_time,
          hold_refund_active: feesdata.hold_refund_active,
          refund_after_settlement: event.target.checked,
          settlement_refund_limit: 0,
        });
    } catch (err) {
      return toast(JSON.stringify(err), {
        type: "error",
        autoClose: 5000,
      });
    }
  };
  const saveFees = async (refundCharges = null) => {
    try {
      feesdata._id = customerId;
      if (refundCharges) {
        feesdata.refund_charges = refundCharges;
      }
      setSpinner(true);
      if (fromSource == "merchant") {
        var res = await updateMerchantFees(feesdata);
        if (res?.status == 200) {
          getConfigData();
          return toast("Setting Updated Successfully.", {
            type: "success",
            autoClose: 2000,
          });
        } else {
          setSpinner(false);
          return toast(JSON.stringify(res?.response?.data), {
            type: "error",
            autoClose: 2000,
          });
        }
      } else {
        AxiosBase.patch("/admin/app/config", feesdata).then((response) => {
          if (response?.status == 200) {
            if (
              response?.data?.message === "App Config Updated Successfully!"
            ) {
              getConfigData();
              return toast(response?.data?.message, {
                type: "success",
                autoClose: 2000,
              });
            }
          } else {
            setSpinner(false);
            return toast(JSON.stringify(response?.response?.data), {
              type: "error",
              autoClose: 2000,
            });
          }
        });
      }
    } catch (err) {
      setSpinner(false);
      return toast(JSON.stringify(err), {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  const updateRefundCharges = (refund_charges) => {
    saveFees(refund_charges);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const confirmReset = () => {
    setOpen(true);
  };

  const resetFees = async () => {
    try {
      feesdata._id = customerId;
      feesdata.settlement_time = "";
      feesdata.settlement_refund_limit = "";
      feesdata.refund_after_settlement = null;
      feesdata.refund_allowed = null;
      feesdata.hold_refund_active = null;
      feesdata.refund_charges = null;
      feesdata.settlement_fees = "";
      setRefundCharges(null);
      setFeesData({ ...feesdata });
      setSpinner(true);
      if (fromSource == "merchant") {
        var res = await updateMerchantFees(feesdata);
        setOpen(false);
        if (res?.status == 200) {
          setSpinner(false);
          return toast("Settlement Reset Successfully.", {
            type: "success",
            autoClose: 2000,
          });
        } else {
          setSpinner(false);
          return toast(JSON.stringify(res?.response?.data), {
            type: "error",
            autoClose: 2000,
          });
        }
      }
    } catch (err) {
      setSpinner(false);
      return toast(JSON.stringify(err), {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  return (
    <>
      {spinner ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="Fees_Margin_main_div">
          {fromSource !== "merchant" ? (
            <div className="Fees_Margin_title_div">
              <p>Settlement Time</p>
            </div>
          ) : (
            <></>
          )}
          <div className="Fees_Margin_input_field">
            <p>Settlement Fees</p>

            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              className="gatway_second_grid_sec"
            >
              <CssTextField
                className="settlement_field"
                id="new-menu"
                placeholder="Fees"
                variant="outlined"
                fullWidth
                value={feesdata.settlement_fees}
                disabled={controlDisable}
                onChange={(e) => {
                  if (!Object.is(NaN, parseFloat(e.target.value))) {
                    let val = e.target.value;
                    const isDecimal = val % 1 != 0;
                    if (isDecimal) {
                      val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                    }
                    if (val >= 0 && val <= 15) {
                      setFeesData({ ...feesdata, settlement_fees: val });
                    }
                  } else {
                    setFeesData({ ...feesdata, settlement_fees: "" });
                  }
                }}
              />
              <label
                style={{
                  "line-height": "2.2",
                  "padding-left": "5px",
                  color: "#666666",
                }}
              ></label>
            </Grid>
          </div>
          <div className="Fees_Margin_input_field">
            <p>Settlement Period</p>

            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              className="gatway_second_grid_sec"
            >
              <CssTextField
                className="settlement_field"
                id="new-menu"
                placeholder="Period"
                variant="outlined"
                fullWidth
                value={feesdata.settlement_time}
                disabled={controlDisable}
                onChange={(e) => {
                  if (!Object.is(NaN, parseInt(e.target.value))) {
                    let val = parseInt(e.target.value);
                    if (val >= 0 && val <= 15) {
                      setFeesData({ ...feesdata, settlement_time: val });
                    }
                  } else {
                    setFeesData({ ...feesdata, settlement_time: "" });
                  }
                }}
              />
              <label
                style={{
                  "line-height": "2.2",
                  "padding-left": "5px",
                  color: "#666666",
                }}
              >
                Days
              </label>
            </Grid>
          </div>
          <div className="Fees_Margin_input_field">
            <p>
              Initiate Refund after settlement
              <Switch
                disabled={controlDisable}
                checked={feesdata.refund_after_settlement}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </p>

            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              className="gatway_second_grid_sec"
            >
              <CssTextField
                className="settlement_field"
                id="new-menu"
                placeholder="Charges"
                variant="outlined"
                fullWidth
                value={feesdata.settlement_refund_limit}
                disabled={controlDisable || !feesdata.refund_after_settlement}
                onChange={(e) => {
                  if (!Object.is(NaN, parseFloat(e.target.value))) {
                    let val = e.target.value;
                    const isDecimal = val % 1 != 0;
                    if (isDecimal) {
                      val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                    }
                    if (val >= 0 && val <= 999999) {
                      setFeesData({
                        ...feesdata,
                        settlement_refund_limit: val,
                      });
                    }
                  } else {
                    setFeesData({ ...feesdata, settlement_refund_limit: "" });
                  }
                }}
              />
              <label
                style={{
                  "line-height": "2.2",
                  "padding-left": "5px",
                  color: "#666666",
                }}
              >
                AED
              </label>
            </Grid>
          </div>
          <div className="Fees_Margin_title_div">
            <p>Refund</p>
          </div>
          <div className="Fees_Margin_input_field">
            <p>Refund Allowed</p>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              className="gatway_second_grid_sec"
            >
              <Switch
                disabled={controlDisable}
                checked={feesdata.refund_allowed}
                onChange={(e) => {
                  setFeesData({
                    ...feesdata,
                    refund_allowed: e.target.checked,
                  });
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
          </div>
          <div className="Fees_Margin_input_field">
            <p>Refund on hold</p>

            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              className="gatway_second_grid_sec"
            >
              <Switch
                disabled={controlDisable}
                checked={feesdata.hold_refund_active}
                onChange={(e) => {
                  setFeesData({
                    ...feesdata,
                    hold_refund_active: e.target.checked,
                  });
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
          </div>
          <div
            className="refund_charges_top_div"
            style={{ position: "relative" }}
          >
            <p style={{ position: "absolute", "font-size": "17px" }}>
              Refund Charges
            </p>
            <RefundCharges
              refundCharges={refundCharges}
              rolePermission={rolePermission}
              updateRefundCharges={updateRefundCharges}
            />
          </div>
          <div className="merchant_edit_button_dev Fees_Margin_save_cancel_btn edit_button_set">
            <span className={saveCancelVisibility}>
              {fromSource == "merchant" ? (
                <Button
                  className="merchant_reset_btn merchant_detail_tab_save-cancel_btn reset_btn"
                  style={{ "background-color": "#1058FF" }}
                  variant="contained"
                  disabled={
                    !feesdata.settlement_time &&
                    !feesdata.settlement_refund_limit &&
                    !feesdata.refund_after_settlement &&
                    !feesdata.refund_allowed &&
                    !feesdata.hold_refund_active &&
                    !refundCharges
                  }
                  onClick={confirmReset}
                >
                  Reset
                </Button>
              ) : (
                <></>
              )}
              {rolePermission.includes("ADD") && (
                <Button
                  className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={() => {
                    saveFees();
                  }}
                >
                  Save
                </Button>
              )}
            </span>
          </div>
        </div>
      )}
      <div>
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              <p>Confirm Reset</p>
              <span>Are you sure want to reset configuration?</span>
              <div className="order_detail_refund_popup_btn">
                <Button
                  className="refund_save_btn"
                  variant="contained"
                  autoFocus
                  fullWidth
                  onClick={resetFees}
                >
                  Confirm
                </Button>
                <Button
                  className="refund_cancel_btn"
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  fullWidth
                >
                  Cancel
                </Button>
              </div>
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

Settlement.propTypes = {
  fromSource: PropTypes.string,
  customerId: PropTypes.string,
};
export default Settlement;
