import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

const SectionE = (props) => {
  return (
    <>
      <div className="inner_pdf_sec">
        <h3 className="Settlement_breakdown_heading">SECTION E</h3>
        
        <div className="Settlement_pdf_sectionD_chargeback">
          <Button className="order_lst_btn">Urban Ledger details</Button>
          {/* <p>Urban Ledger details</p> */}
        </div>
        <div className="Settlement_pdf_section_Sett_Statement_mrchnt_info">
          {/* <div className="Settlement_date">
            <p className="first-txt">VAT Number:</p>
            <p className="second-txt">NA</p>
          </div> */}
          <div className="Settlement_date">
            <p className="first-txt">Trade license:</p>
            <p className="second-txt">{props.data?.kyc_data?.tradeLicenseData?.licenseNumber}</p>
          </div>
          <div className="Settlement_date">
            <p className="first-txt">Address:</p>
            <p className="second-txt">{props.data?.customer_data?.address}</p>
          </div>
        </div>
      </div>
      <div class="footer-img" style={{"position": "relative"}}>
        <div style={{"width": "100%"}}>
          <img src="/images/Urban Ledger Export PDF-07.jpg" style={{"height": "90px", "width": "100%"}} alt="img" />
        </div>
        <div style={{"width": "45%","position": "absolute", "bottom": "30%", "left": "6%","z-index":"999"}}>
              <b style={{"color":" #fff"}}><i class="fa fa-phone-square" aria-hidden="true"></i> Helpline: </b>
              <a target="_blank" href={props.contactLink
            } style={{"color": "#fff","text-decoration": "none"}}>{props.contactLink}</a><br/>
              <span style={{"color": "#fff", "font-size": "12px"}}>T & C apply.</span>
        </div>
        <div style={{"width": "40%","position": "absolute", "bottom": "37%", "right": "6%","text-align": "right"}}>
            <span style={{"color": "white"}}>Use Urban Ledger Now</span>
            <a target="_blank" href={props.appLink
          } style={{"margin-left": "5px", "color": "#726f7a","background-color": "white","border-radius": "4px","padding": "3px 10px","text-decoration": "none"}}>Install <img style={{"width": "13px"}} src="/images/Install-01.svg" alt="img" /></a>
        </div>
      </div>
    </>
  );
};

export default SectionE;
