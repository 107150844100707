import React from 'react';
import CustomerDetailsAndHistory from './components/customerDetails-history/customerDetailsAndHistory';

function ProfileHome() {
  return (
    <div style={{display:'flex', width:'100%'}}>
      <CustomerDetailsAndHistory></CustomerDetailsAndHistory>
    </div>
    // Over here there will be the profile componenet 


    // Over here there will be one componenet inside that once componenet there will be 2 other component 
    // Those 2 component will show conditional rendering based on TAB in query of the url.
    // Those 2 components will be OVERVIEW & EVENTS NAD LOGS
    
  )
}

export default ProfileHome