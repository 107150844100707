import React from "react";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "./merchant-kyc.css";
import Button from "@mui/material/Button";
import { styled } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const BootstrapButton = styled(Button)({
  marginLeft: "40px",
  textTransform: "none",
  width: "220px",
  padding: "6px 12px",
  borderRadius: "20px",
  lineHeight: 1.5,
  backgroundColor: "#ECE6FF",
  borderColor: "#0063cc",
  boxShadow: "none",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "#ECE6FF",
  },
  "&:focus": {
    boxShadow: "none",
  },
  color: "#1058ff",
});

const CssTextField = styled(TextField)({
  "input#national-id": {
    padding: 9,
  },
});

const MerchantKyc = (props) => {
  return (
    <>
      <div style={{ padding: "2px 0px 2px 20px" }}>
        <div className="branch_add_btn_div">
          <Button
            className="merchant_list_add_new_merchant branch_add_btn"
            variant="contained"
            startIcon={<AutorenewIcon />}
          >
            UPDATE KYC
          </Button>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="formcontrol">
              <p>Status : </p>
              <FormControl>
                <p>
                  {props?.kycdata?.isverified == true
                    ? "Verified"
                    : "Not Verified"}
                </p>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="formcontrol">
              <p>National Id Number:</p>
              <CssTextField
                id="national-id"
                className="create_kyc_input_feild"
                variant="outlined"
                value={props?.kycdata?.emiratesData?.idNumber}
                fullWidth
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="create_merchn_inpt_icon merchant_details_tab_input_location_icon">
                      <IconButton color="primary">
                        <img src="/location-icon-feild.svg" alt="" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="formcontrol">
              <p>Emirates Id</p>
              <p style={{ marginLeft: "55px" }}>Step 1</p>
              <BootstrapButton variant="contained" disableRipple>
                Emirates Id
              </BootstrapButton>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="formcontrol">
              <p>Trade License</p>
              <p style={{ marginLeft: "55px" }}>Step 2</p>
              <BootstrapButton variant="contained" disableRipple>
                UAE Trade License
              </BootstrapButton>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default MerchantKyc;
