import React from "react";
import Table from "@mui/material/Table";
import { toast } from "react-toastify";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Delete from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";
import PhoneInput from "react-phone-input-2";
import Radio from "@mui/material/Radio";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import TableHead from "@mui/material/TableHead";
import Menu from "@mui/material/Menu";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import { styled } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import {
  UpdateTerminalStatus,
  getTerminal,
  updateTerminal,
  deleteTerminal,
} from "../../../networkRequest/request/branch";
import {
  AddAccount,
  getBankList,
  getBranchBankList,
  UpdateAccount,
} from "../../../networkRequest/request/bankaccount";
import baseURL from "../../../utils/base_url";

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  background: ${theme?.palette?.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme?.palette?.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 5px;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme?.palette?.mode === "dark" ? grey[300] : grey[900]};

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 320px;
  background: ${theme?.palette?.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme?.palette?.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme?.palette?.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const Div = styled("div")(unstable_styleFunctionSx);
const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "5px",
  },
});

function checklenght(val, lenght) {
  if (val?.length > lenght) {
    return val?.substring(0, lenght) + "..";
  }
  if (!val) {
    return "NA";
  }
  return val;
}

const TerminalActiveInactive = (props) => {
  const { rolePermission, currency } = props;
  const [phoneNumber, setPhoneNumber] = React.useState([]);
  const [bankaccountlist, setBankaccountList] = React.useState([]);
  const [branchBankAccount, setBranchBankAccount] = React.useState([]);
  const [loaderFlag, setLoaderFlag] = React.useState(0);
  var [listTerminal, setListTerminal] = React.useState([]);
  const [age, setAge] = React.useState("");
  const [innerFlag, setInnerFlag] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [existingClick, setExistingClick] = React.useState({
    Id: "",
    TerminalName: "",
    phoneNumber: "",
    newphoneNumber: "",
    bankAccountId: "",
    mobile_code: "",
    bankAccountDetails: {
      BankId: "",
      BankName: "",
      icon: "",
      AccountHolderName: "",
      AccountNumber: "",
    },
    doneButton: true,
    useExistenceNumber: "use_existing",
    useExistenceAccount: "use_existing",
    TerminalId: "",
    access_to: [],
  });
  const [paymentAccess, setPaymentAccess] = React.useState(false);
  const [invoiceAccess, setInvoiceAccess] = React.useState(false);
  const [staticPosPayment, setStaticPosPayment] = React.useState(false);
  const [splitPayment, setSplitPosPayment] = React.useState(false);
  const [seamlessPayment, setSeamlessPayment] = React.useState(false);
  const [eppPayment, setEppPayment] = React.useState(false);

  const updateBranchAccount = (banklist, branchbank) => {
    if (branchbank && banklist && branchbank.length !== 0) {
      for (let i = 0; i < branchbank.length; i++) {
        let fact = branchbank[i];
        let bankId = banklist.filter((innerfact) => {
          return innerfact._id == fact.selected_bank_id;
        });
        fact.Bankname = bankId.length !== 0 && bankId[0].name;
        fact.icon = bankId.length !== 0 && bankId[0].icon;
        if (i == branchbank.length - 1) {
          return branchbank;
        }
      }
    } else {
      return [];
    }
  };

  React.useEffect(() => {
    getBankData();
  }, [props.branchId]);

  const getBankData = async () => {
    let bankList = await getBankList();
    setBankaccountList(bankList.data);
    if (!props.branchId) {
      return;
    }
    try {
      let branchAccount = await getBranchBankList(props.branchId);
      let TerminalData = await getTerminal("Branch", props.branchId, "");
      setListTerminal(TerminalData.data.data);
      if (TerminalData?.data?.data && TerminalData.data.data.length !== 0) {
        setLoaderFlag(1);
      } else {
        setLoaderFlag(2);
      }
      setPhoneNumber(TerminalData.data.mobileNumberList);
      let branchAccountBank = await updateBranchAccount(
        bankList.data,
        branchAccount.data.data
      );
      setBranchBankAccount(branchAccountBank);
    } catch (error) {}
  };

  const handleInnerClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteTerminal = async (Id) => {
    try {
      let res = await deleteTerminal(Id);
      if (res?.status !== 400) {
        let TerminalData = await getTerminal("Branch", props.branchId, "");
        listTerminal = TerminalData.data.data;
        setListTerminal([...listTerminal]);
        if (TerminalData?.data?.data && TerminalData.data.data.length !== 0) {
          setLoaderFlag(1);
        } else {
          setLoaderFlag(2);
        }
        return toast("Terminal Deleted Successfully", {
          type: "success",
          autoClose: 2000,
        });
      } else {
        return toast(res?.data?.message, {
          type: "error",
          autoClose: 2000,
        });
      }
    } catch (error) {}
  };

  const handleClose = (e, row) => {
    existingClick.bankAccountDetails.BankId = row._id;
    existingClick.bankAccountDetails.BankName = row.name;
    existingClick.bankAccountDetails.icon = row.icon;
    setExistingClick({ ...existingClick });
    setAnchorEl(null);
  };

  const handleClick = async (row, index) => {
    let stateShare = !row.is_active;
    let reqbody = {
      is_active: stateShare,
    };
    let innerdata = await UpdateTerminalStatus(row._id, reqbody);

    if (innerdata.length !== 0) {
      listTerminal[index].is_active = stateShare;
      setListTerminal([...listTerminal]);
    }
    return toast(
      innerdata.length !== 0
        ? stateShare
          ? "Terminal activated Successfully"
          : "Terminal Deactivated Successfully"
        : "Internal Server Error",
      {
        type: innerdata.length !== 0 ? "success" : "error",
        autoClose: 2000,
      }
    );
  };

  const handleRowClick = async (row, index) => {
    listTerminal.map((fact, ind) => {
      ind == index ? (fact.UpdateRow = true) : (fact.UpdateRow = false);
      if (ind == listTerminal.length - 1) {
        setListTerminal([...listTerminal]);
        setExistingClick({
          Id: row._id,
          TerminalName: row.terminal_name,
          phoneNumber: row.mobile_no,
          newphoneNumber: "",
          mobile_code: row.mobile_code,
          bankAccountId: row.bank_account_id,
          bankAccountDetails: {
            BankId: "",
            BankName: "",
            icon: "",
            AccountHolderName: "",
            AccountNumber: "",
          },
          doneButton: true,
          useExistenceNumber: "use_existing",
          useExistenceAccount: "use_existing",
          TerminalId: row.terminal_id,
        });
        if (row?.access_to) {
          let indPayment = row.access_to.findIndex((x) => x == "PAYMENTS");
          let indInvoice = row.access_to.findIndex((x) => x == "INVOICES");
          let indStaticPayment = row.access_to.findIndex(
            (x) => x == "STATIC_POS_PAYMENT"
          );
          let indSplitPayment = row.access_to.findIndex(
            (x) => x == "SPLIT_PAYMENT"
          );
          let indSeamlessPayment = row.access_to.findIndex(
            (x) => x == "SEAMLESS_PAYMENT"
          );
          let indEppPayment = row.access_to.findIndex(
            (x) => x == "EPP_PAYMENT"
          );
          if (indPayment != -1) {
            setPaymentAccess(true);
          }
          if (indInvoice != -1) {
            setInvoiceAccess(true);
          }
          if (indStaticPayment != -1) {
            setStaticPosPayment(true);
          }
          if (indSplitPayment != -1) {
            setSplitPosPayment(true);
          }
          if (indSeamlessPayment != -1) {
            setSeamlessPayment(true);
          }
          if (indEppPayment != -1) {
            setEppPayment(true);
          }
        }
      }
    });
  };

  const AddAccountData = async (index) => {
    try {
      let innerdata = existingClick.bankAccountDetails;
      if (
        innerdata.AccountHolderName &&
        props.merchantId &&
        props.branchId &&
        innerdata.BankId &&
        innerdata.AccountNumber
      ) {
        if (
          innerdata.AccountNumber.length < 23 ||
          innerdata.AccountNumber[0] + innerdata.AccountNumber[1] !== "AE"
        ) {
          return toast(
            innerdata.AccountNumber.length < 23
              ? "The total length of the IBAN number should be 23 characters"
              : "Please double-check and enter a correct IBAN",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        }
        let reqBody = {
          account_holders_name:
            existingClick.bankAccountDetails.AccountHolderName,
          selected_bank_id: existingClick.bankAccountDetails.BankId,
          ibann_number: existingClick.bankAccountDetails.AccountNumber,
          merchant_id: props.merchantId,
          branch_id: props.branchId,
        };
        let AddData = await AddAccount(reqBody);
        if (AddData?.status !== 400) {
          AddData.data.Bankname = existingClick.bankAccountDetails.BankName;
          AddData.data.icon = existingClick.bankAccountDetails.icon;
          branchBankAccount.push(AddData.data);
          setBranchBankAccount([...branchBankAccount]);
          existingClick.bankAccountId = AddData.data._id;
          existingClick.doneButton = false;

          setExistingClick({ ...existingClick });
          if (branchBankAccount.length == 1) {
            let innerNumber =
              existingClick.useExistenceNumber !== "use_existing"
                ? existingClick.newphoneNumber.replace(/[^0-9]/gi, "")
                : existingClick.phoneNumber.replace(/[^0-9]/gi, "");
            if (
              !(
                (existingClick.TerminalName && innerNumber) // &&
                // existingClick.bankAccountId
              )
            ) {
              return toast(
                !existingClick.TerminalName
                  ? "Bank account added but could not be linked to the selected terminal as terminal name is required for this linking."
                  : "Bank account added but could not be linked to the selected terminal as terminal mobile number is required for this linking.",
                {
                  type: "error",
                  autoClose: 2000,
                }
              );
            }
            let reqBody = {
              terminal_name: existingClick.TerminalName,
              mobile_no:
                existingClick.useExistenceNumber !== "use_existing"
                  ? existingClick.newphoneNumber.replace(/[^0-9]/gi, "")
                  : existingClick.phoneNumber.replace(/[^0-9]/gi, ""),
              mobile_code: existingClick?.mobile_code,
              bank_account_id: AddData.data._id,
              branch_id: props.branchId,
              merchant_id: props.merchantId,
              access_to: existingClick.access_to,
            };
            let terminaldatares = await updateTerminal(
              "Terminal",
              existingClick.Id,
              reqBody
            );
            if (terminaldatares?.status == 400) {
              setLoaderFlag(1);
              return toast(terminaldatares?.data?.message, {
                type: "error",
                autoClose: 2000,
              });
            }
          } else {
            return toast(
              "Bank account added successfully, click Update Terminal button to link this bank account to the selected terminal",
              {
                type: "error",
                autoClose: 2000,
              }
            );
          }
          return toast("Account Added Successfully", {
            type: "success",
            autoClose: 2000,
          });
        } else {
          return toast(AddData?.data.message, {
            type: "error",
            autoClose: 2000,
          });
        }
      } else {
        return toast(
          !innerdata.AccountHolderName
            ? "Account Holder Name Cannot Be Empty"
            : !innerdata.BankId
            ? "Please select your bank name before entering your IBAN number"
            : "Please enter a valid IBAN number",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      }
    } catch (err) {
      return toast("Internal Server Error", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  const Validation = async () => {
    let innerNumber =
      existingClick.useExistenceNumber !== "use_existing"
        ? existingClick.newphoneNumber.replace(/[^0-9]/gi, "")
        : existingClick.phoneNumber.replace(/[^0-9]/gi, "");
    if (
      !(
        (existingClick.TerminalName && innerNumber) // &&
        // existingClick.bankAccountId
      )
    ) {
      return toast(
        !existingClick.TerminalName
          ? "Please Fill Terminal Name"
          : !innerNumber
          ? "Please Select Phone Number"
          : "Please select Bank Account",
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else {
      try {
        let TerminalData = await getTerminal("Branch", props.branchId, "");
        TerminalData = TerminalData.data.data;
        let checkData = await TerminalData.filter((fact1) => {
          return (
            fact1.terminal_name == existingClick.TerminalName &&
            existingClick.Id !== fact1._id
          );
        });
        if (checkData && checkData.length !== 0) {
          return toast("terminal name already exists.", {
            type: "error",
            autoClose: 2000,
          });
        }
      } catch (error) {}
    }
  };

  const UpdateTerminal = async () => {
    try {
      if (!(await Validation())) {
        setLoaderFlag(0);
        let reqBody = {
          terminal_name: existingClick.TerminalName,
          mobile_no:
            existingClick.useExistenceNumber !== "use_existing"
              ? existingClick.newphoneNumber.replace(/[^0-9]/gi, "")
              : existingClick.phoneNumber.replace(/[^0-9]/gi, ""),
          mobile_code: existingClick.mobile_code,
          bank_account_id: existingClick.bankAccountId,
          branch_id: props.branchId,
          merchant_id: props.merchantId,
          access_to: existingClick.access_to,
        };
        let terminaldatares = await updateTerminal(
          "Terminal",
          existingClick.Id,
          reqBody
        );
        if (terminaldatares?.status !== 400) {
          let TerminalData = await getTerminal("Branch", props.branchId, "");
          listTerminal = TerminalData.data.data;
          setListTerminal([...listTerminal]);
          setLoaderFlag(1);
          closeTerminal();
          return toast("Terminal Updated Successfully", {
            type: "success",
            autoClose: 2000,
          });
        } else {
          setLoaderFlag(1);
          return toast(terminaldatares?.data?.message, {
            type: "error",
            autoClose: 2000,
          });
        }
      }
    } catch (error) {}
  };

  const SelectBankAccount = (value, index) => {
    existingClick.bankAccountId = value;
    setExistingClick({ ...existingClick });
  };

  const unsetSelectedBranch = () => {
    existingClick.bankAccountId = null;
    setExistingClick({ ...existingClick });
  };

  const deleteBranch = async (reqBody, index) => {
    reqBody.isdeleted = true;
    reqBody.merchant_id = reqBody.customer_id;
    let deleteData = await UpdateAccount(reqBody?._id, reqBody);
    if (deleteData?.status == 200) {
      branchBankAccount.splice(index, 1);
      setBranchBankAccount([...branchBankAccount]);
      if (reqBody._id.toString() == existingClick.bankAccountId.toString()) {
        existingClick.bankAccountId = null;
        setExistingClick({ ...existingClick });
      }
      return toast("Account Deleted Successfully", {
        type: "success",
        autoClose: 2000,
      });
    } else {
      return toast(deleteData?.data.message, {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  const handlePhoneNumber = (pno, index) => {
    existingClick.useExistenceNumber =
      existingClick.useExistenceNumber == "use_existing"
        ? "new_phone"
        : "use_existing";
    setExistingClick({ ...existingClick });
  };

  const handleChange = (event, index) => {
    existingClick.phoneNumber = event;
    setExistingClick({ ...existingClick });
  };

  const handleBankAccount = (bac, index) => {
    existingClick.useExistenceAccount =
      existingClick.useExistenceAccount == "use_existing"
        ? "new_bank"
        : "use_existing";
    setExistingClick({ ...existingClick });
  };

  const closeTerminal = (index) => {
    listTerminal.map((fact, ind) => {
      fact.UpdateRow = false;
      if (ind == listTerminal.length - 1) {
        setListTerminal([...listTerminal]);
        setExistingClick({
          TerminalName: "",
          phoneNumber: "",
          newphoneNumber: "",
          mobile_code: "",
          bankAccountId: "",
          bankAccountDetails: {
            BankId: "",
            BankName: "",
            icon: "",
            AccountHolderName: "",
            AccountNumber: "",
          },
          useExistenceNumber: "use_existing",
          useExistenceAccount: "use_existing",
          access_to: [],
        });
      }
    });
  };

  const handleAccessTo = async (val, key) => {
    let access_to = [];
    if (key == "PAYMENTS") {
      val = !paymentAccess;
      setPaymentAccess(val);
      if (val) {
        access_to.push(key);
      }
    } else if (paymentAccess) {
      access_to.push("PAYMENTS");
    }

    if (key == "INVOICES") {
      val = !invoiceAccess;
      setInvoiceAccess(val);
      if (val) {
        access_to.push(key);
      }
    } else if (invoiceAccess) {
      access_to.push("INVOICES");
    }

    if (key == "STATIC_POS_PAYMENT") {
      val = !staticPosPayment;
      setStaticPosPayment(val);
      if (val) {
        access_to.push(key);
      }
    } else if (staticPosPayment) {
      access_to.push("STATIC_POS_PAYMENT");
    }

    if (key == "SPLIT_PAYMENT") {
      val = !splitPayment;
      setSplitPosPayment(val);
      if (val) {
        access_to.push(key);
      }
    } else if (splitPayment) {
      access_to.push("SPLIT_PAYMENT");
    }

    if (key == "SEAMLESS_PAYMENT") {
      val = !seamlessPayment;
      setSeamlessPayment(val);
      if (val) {
        access_to.push(key);
      }
    } else if (seamlessPayment) {
      access_to.push("SEAMLESS_PAYMENT");
    }

    if (key == "EPP_PAYMENT") {
      val = !eppPayment;
      setEppPayment(val);
      if (val) {
        access_to.push(key);
      }
    } else if (eppPayment) {
      access_to.push("EPP_PAYMENT");
    }

    existingClick.access_to = access_to;
    setExistingClick({ ...existingClick });
  };

  return (
    <>
      {loaderFlag == 1 ? (
        <div className="terminal_active_inactive_comp">
          {listTerminal.length !== 0 && (
            <p>
              <IconButton color="primary">
                <img
                  style={{ height: "25px", width: "25px" }}
                  src="/Terminal-icon.svg"
                  alt=""
                />
              </IconButton>
              Terminals
            </p>
          )}
          <TableContainer className="terminals_list">
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <TableBody>
                {listTerminal.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <>
                      <TableRow
                        className="merchant_edit_drawer_tblBody_row terminal_row_body"
                        hover
                        key={row.name}
                      >
                        {row.UpdateRow ? (
                          <div
                            className="add_terminal_main_div"
                            key={index}
                            style={{ width: "76%", marginBottom: "6px" }}
                          >
                            <div className="edit_branch_inpt_div ">
                              <div className="add_terminal_cancel_icon">
                                <p>
                                  <IconButton color="primary">
                                    <img
                                      style={{ height: "25px", width: "25px" }}
                                      src="/Terminal-icon.svg"
                                      alt=""
                                    />
                                  </IconButton>
                                  Terminal Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                                <IconButton
                                  className="terminal_cancel"
                                  color="primary"
                                  onClick={() => {
                                    closeTerminal(index);
                                  }}
                                >
                                  <img
                                    style={{ height: "25px", width: "25px" }}
                                    src="/Terminal-cancel-icon.svg"
                                    alt=""
                                  />
                                </IconButton>
                              </div>
                              <CssTextField
                                name="Terminal name"
                                className="merchant_edit_drower_serch_field"
                                id="new-menu"
                                placeholder="Terminal name"
                                helperText={`${
                                  existingClick?.TerminalName?.length
                                }/${100}`}
                                onChange={(e) => {
                                  let valueinner = e.target.value;
                                  existingClick.TerminalName = valueinner;
                                  setExistingClick({ ...existingClick });
                                }}
                                value={existingClick.TerminalName}
                                variant="outlined"
                                required
                                inputProps={{
                                  maxlength: 100,
                                }}
                              />
                            </div>
                            <div className="terminal_add_phone_bank">
                              <p>Terminal Id </p>
                              <div className="terminal_add_phone_bank_radio_btn">
                                <CssTextField
                                  name="TerminalId"
                                  id="new-menu"
                                  placeholder="Terminal Id"
                                  value={existingClick.TerminalId}
                                  variant="outlined"
                                  required
                                  readOnly={true}
                                  disabled
                                />
                                <Button
                                  variant="contained"
                                  sx={{ marginBottom: "10px" }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      existingClick?.TerminalId
                                    );
                                    return (
                                      toast("Terminal Id copied successfully"),
                                      {
                                        type: "success",
                                        autoClose: 2000,
                                      }
                                    );
                                  }}
                                >
                                  Copy Id
                                </Button>
                              </div>
                            </div>
                            <div className="terminal_add_phone_bank">
                              <p>
                                Phone Number{" "}
                                <span style={{ color: "red" }}>*</span>
                              </p>
                              <div className="terminal_add_phone_bank_radio_btn">
                                <RadioGroup
                                  row
                                  defaultValue={
                                    existingClick.useExistenceNumber
                                  }
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  onChange={(e) =>
                                    handlePhoneNumber(e.target.value, index)
                                  }
                                >
                                  <div style={{ width: "50%" }}>
                                    <FormControlLabel
                                      value="use_existing"
                                      control={<Radio />}
                                      label="Use Existing"
                                    />
                                  </div>
                                  <div style={{ width: "50%" }}>
                                    <FormControlLabel
                                      value="new_phone"
                                      control={<Radio />}
                                      label="Add New Phone Number"
                                    />
                                  </div>
                                  <div
                                    style={{ display: "flex", width: "100%" }}
                                  >
                                    <div
                                      style={{
                                        width: "50%",
                                        position: "relative",
                                      }}
                                    >
                                      {existingClick.useExistenceNumber ==
                                      "use_existing" ? (
                                        <Select
                                          id="demo-simple-select"
                                          style={{ width: "100%" }}
                                          placeholder="Select Number"
                                          defaultValue={
                                            existingClick.phoneNumber
                                          }
                                          onChange={(e) =>
                                            handleChange(e, index)
                                          }
                                        >
                                          {phoneNumber.map((fact, index) => {
                                            return (
                                              <MenuItem value={fact}>
                                                {fact}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      ) : (
                                        " "
                                      )}
                                    </div>
                                    <div style={{ width: "50%" }}>
                                      {existingClick.useExistenceNumber ==
                                      "new_phone" ? (
                                        <PhoneInput
                                          className="mobile-input"
                                          name="phone"
                                          country={
                                            currency == "INR" ? "in" : "ae"
                                          }
                                          countryCodeEditable={true}
                                          placeholder="Enter Phone Number"
                                          required
                                          value={existingClick.newphoneNumber}
                                          onChange={(e, countrycode) => {
                                            existingClick.mobile_code =
                                              countrycode.dialCode;
                                            existingClick.newphoneNumber = e;
                                            setExistingClick({
                                              ...existingClick,
                                            });
                                          }}
                                        />
                                      ) : (
                                        " "
                                      )}
                                    </div>
                                  </div>
                                </RadioGroup>
                              </div>
                            </div>
                            <div className="terminal_add_phone_bank">
                              <p>
                                Bank Account{" "}
                                <span style={{ color: "red" }}>*</span>
                              </p>
                              <div className="terminal_add_phone_bank_radio_btn">
                                <RadioGroup
                                  row
                                  defaultValue={
                                    existingClick.useExistenceAccount
                                  }
                                  aria-labelledby="demo-row-radio-buttons-group-label1"
                                  name="row-radio-buttons-group1"
                                  onChange={(e) =>
                                    handleBankAccount(e.target.value, index)
                                  }
                                  sx={{ width: "100%" }}
                                >
                                  <div style={{ width: "50%" }}>
                                    <FormControlLabel
                                      value="use_existing"
                                      control={<Radio />}
                                      label="Use Existing"
                                    />
                                  </div>
                                  <div style={{ width: "50%" }}>
                                    <FormControlLabel
                                      value="new_bank"
                                      control={<Radio />}
                                      label="Add New Bank Accounts"
                                    />
                                  </div>
                                </RadioGroup>
                              </div>

                              <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" }}>
                                  {existingClick.useExistenceAccount ==
                                    "use_existing" && (
                                    <>
                                      <div className="bank_account">
                                        <div>
                                          {existingClick.bankAccountId && (
                                            <Button
                                              className=""
                                              variant="contained"
                                              sx={{ marginBottom: "10px" }}
                                              onClick={() => {
                                                unsetSelectedBranch();
                                              }}
                                            >
                                              Unset Selected Bank
                                            </Button>
                                          )}
                                        </div>
                                        <RadioGroup
                                          value={existingClick.bankAccountId}
                                          sx={{ width: "100%" }}
                                        >
                                          {branchBankAccount.map(
                                            (fact, index) => {
                                              return (
                                                <div>
                                                  <FormControlLabel
                                                    name="bank_selected"
                                                    value={fact._id}
                                                    style={{
                                                      width: "90%",
                                                    }}
                                                    control={
                                                      <Radio
                                                        onClick={(e) =>
                                                          SelectBankAccount(
                                                            e.target.value,
                                                            index
                                                          )
                                                        }
                                                      />
                                                    }
                                                    label={
                                                      <>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          {fact.icon && (
                                                            <img
                                                              className="bank_icon_new"
                                                              src={`${baseURL()}/${
                                                                fact.icon
                                                              }`}
                                                            />
                                                          )}
                                                          <div>
                                                            <h6>
                                                              {fact.Bankname}
                                                            </h6>
                                                            <p>
                                                              {
                                                                fact.ibann_number
                                                              }
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </>
                                                    }
                                                  />
                                                  <i
                                                    class="fa fa-trash"
                                                    style={{
                                                      color: "red",
                                                      cursor: "pointer",
                                                      float: "right",
                                                      "margin-top": "10px",
                                                    }}
                                                    onClick={() =>
                                                      deleteBranch(fact, index)
                                                    }
                                                  ></i>
                                                </div>
                                              );
                                            }
                                          )}
                                        </RadioGroup>
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div>
                                  <div>
                                    {existingClick.useExistenceAccount ==
                                    "new_bank" ? (
                                      <>
                                        <CssTextField
                                          className=""
                                          id="new-menu"
                                          placeholder="Account Holder Name"
                                          variant="outlined"
                                          value={
                                            existingClick.bankAccountDetails
                                              .AccountHolderName
                                          }
                                          onChange={(e) => {
                                            existingClick.bankAccountDetails.AccountHolderName =
                                              e.target.value;
                                            setExistingClick({
                                              ...existingClick,
                                            });
                                          }}
                                          fullWidth
                                          sx={{ marginBottom: "10px" }}
                                        />
                                        <FormControl fullWidth>
                                          <Autocomplete
                                            id="custom-input-demo"
                                            options={bankaccountlist}
                                            autoHighlight
                                            onChange={(e, newValue) => {
                                              existingClick.bankAccountDetails.BankId =
                                                newValue._id;
                                              const ind =
                                                bankaccountlist.findIndex(
                                                  (x) => x._id == newValue._id
                                                );
                                              if (ind > -1) {
                                                existingClick.bankAccountDetails.BankName =
                                                  bankaccountlist[ind].name;
                                                existingClick.bankAccountDetails.icon =
                                                  bankaccountlist[ind].icon;
                                              }
                                              setExistingClick({
                                                ...existingClick,
                                              });
                                            }}
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            renderOption={(props, option) => (
                                              <Box component="li" {...props}>
                                                {option.name}
                                              </Box>
                                            )}
                                            renderInput={(params) => (
                                              <div ref={params.InputProps.ref}>
                                                <input
                                                  style={{
                                                    height: "36px",
                                                    width: "98%",
                                                    marginBottom: "10px",
                                                    borderRadius: "3px",
                                                    border: "1px solid #c4c4c4",
                                                  }}
                                                  type="text"
                                                  placeholder="Select Your Bank"
                                                  {...params.inputProps}
                                                />
                                              </div>
                                            )}
                                          />
                                        </FormControl>
                                        <CssTextField
                                          className=""
                                          id="new-menu"
                                          placeholder="IBAN Number"
                                          value={
                                            existingClick.bankAccountDetails
                                              .AccountNumber
                                          }
                                          onChange={(e) => {
                                            existingClick.bankAccountDetails.AccountNumber =
                                              e.target.value.replace(
                                                /[^0-9A-Za-z]/gi,
                                                ""
                                              );
                                            setInnerFlag(
                                              e.target.value.replace(
                                                /[^0-9A-Za-z]/gi,
                                                ""
                                              ).length !== 0
                                                ? true
                                                : false
                                            );
                                            setExistingClick({
                                              ...existingClick,
                                            });
                                          }}
                                          variant="outlined"
                                          helperText={
                                            existingClick.bankAccountDetails
                                              .BankId === "" &&
                                            innerFlag &&
                                            `Please select your bank name before entering your IBAN number`
                                          }
                                          fullWidth
                                          inputProps={{
                                            maxlength: 23,
                                          }}
                                          sx={{ marginBottom: "10px" }}
                                        />
                                        {existingClick.doneButton && (
                                          <Button
                                            className="terminal_done_btn"
                                            variant="contained"
                                            sx={{ marginBottom: "10px" }}
                                            onClick={() => {
                                              AddAccountData(index);
                                            }}
                                          >
                                            Done
                                          </Button>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="terminal_add_phone_bank"
                              style={{ display: "flow-root" }}
                            >
                              <p>Access To</p>
                              <div className="access_to_checkbox">
                                <label>Payments</label>
                                <IOSSwitch
                                  sx={{ m: 1 }}
                                  defaultChecked={paymentAccess ? true : false}
                                  onChange={() => {
                                    handleAccessTo(paymentAccess, "PAYMENTS");
                                  }}
                                />
                              </div>
                              <div className="access_to_checkbox">
                                <label>Invoice</label>
                                <IOSSwitch
                                  sx={{ m: 1 }}
                                  defaultChecked={invoiceAccess ? true : false}
                                  onChange={() => {
                                    handleAccessTo(invoiceAccess, "INVOICES");
                                  }}
                                />
                              </div>
                              <div className="access_to_checkbox">
                                <label>Static Payments</label>
                                <IOSSwitch
                                  sx={{ m: 1 }}
                                  defaultChecked={
                                    staticPosPayment ? true : false
                                  }
                                  onChange={() => {
                                    handleAccessTo(
                                      staticPosPayment,
                                      "STATIC_POS_PAYMENT"
                                    );
                                  }}
                                />
                              </div>
                              <div className="access_to_checkbox">
                                <label>Split Payments</label>
                                <IOSSwitch
                                  sx={{ m: 1 }}
                                  defaultChecked={splitPayment ? true : false}
                                  onChange={() => {
                                    handleAccessTo(
                                      splitPayment,
                                      "SPLIT_PAYMENT"
                                    );
                                  }}
                                />
                              </div>
                              <div className="access_to_checkbox">
                                <label>Seamless Payments</label>
                                <IOSSwitch
                                  sx={{ m: 1 }}
                                  defaultChecked={
                                    seamlessPayment ? true : false
                                  }
                                  onChange={() => {
                                    handleAccessTo(
                                      seamlessPayment,
                                      "SEAMLESS_PAYMENT"
                                    );
                                  }}
                                />
                              </div>
                              <div className="access_to_checkbox">
                                <label>EPP Payments</label>
                                <IOSSwitch
                                  sx={{ m: 1 }}
                                  defaultChecked={eppPayment ? true : false}
                                  onChange={() => {
                                    handleAccessTo(eppPayment, "EPP_PAYMENT");
                                  }}
                                />
                              </div>
                            </div>
                            <Button
                              variant="contained"
                              className="terminal_UpdateButton"
                              onClick={() => {
                                UpdateTerminal();
                              }}
                            >
                              Update Terminal
                            </Button>
                          </div>
                        ) : (
                          <>
                            <Checkbox checked={row.is_active ? true : false} />
                            <div className="terminal_active_inactive_box">
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                style={{
                                  cursor: "pointer",
                                  width: "21%",
                                  color: row.is_active ? "#8175fc" : "grey",
                                }}
                                onClick={() => {
                                  row.is_active &&
                                    rolePermission.includes("EDIT") &&
                                    handleRowClick(row, index);
                                }}
                              >
                                {row.terminal_name.length > 10 ? (
                                  <Tooltip title={row?.terminal_name}>
                                    <Button
                                      style={{
                                        color: row.is_active
                                          ? "#8175fc"
                                          : "grey",
                                        padding: "0%",
                                      }}
                                    >
                                      {checklenght(row?.terminal_name, 10)}
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  row.terminal_name
                                )}
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                style={{
                                  color: row.is_active ? "#8175fc" : "grey",
                                  width: "90px",
                                }}
                              >
                                {row.mobile_no}
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                color=""
                                style={{
                                  color: row.is_active ? "#8175fc" : "grey",
                                }}
                              >
                                {row.is_active ? "Active" : "InActive"}
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                <div className="gatway_toggal_btn terminal-list-section">
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        sx={{ m: 1 }}
                                        disabled={
                                          rolePermission.includes("EDIT")
                                            ? false
                                            : true
                                        }
                                        defaultChecked={
                                          row.is_active ? true : false
                                        }
                                        onChange={() => {
                                          rolePermission.includes("EDIT") &&
                                            handleClick(row, index);
                                        }}
                                      />
                                    }
                                    label={""}
                                  />
                                </div>
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                <i
                                  class="fa fa-trash"
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    "margin-right": "15px",
                                  }}
                                  onClick={() => {
                                    rolePermission.includes("DELETE") &&
                                      handleDeleteTerminal(row._id);
                                  }}
                                ></i>
                              </TableCell>
                            </div>
                          </>
                        )}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : loaderFlag == 0 ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: "2%" }}>
          No Terminal Data Found
        </div>
      )}
    </>
  );
};

export default TerminalActiveInactive;
