import React from "react";
import "./index.scss";

const statusColors = {
  "Success Rate": {
    color: "#1058ff",
    icon: "/images/dashboard/Dashboard ICONS 1-07.svg",
  },
  Authorized: {
    color: "#2C1867",
    icon: "/images/dashboard/Dashboard ICONS 1-04.svg",
  },
  Captured: {
    color: "#38EDCC",
    icon: "/images/dashboard/Dashboard ICONS 1-02.svg",
  },
  Pending: {
    color: "#F58500",
    icon: "/images/dashboard/Dashboard ICONS 1-01.svg",
  },
  Refunded: {
    color: "#D14DFF",
    icon: "/images/dashboard/Dashboard ICONS 1-06.svg",
  },
  Voided: {
    color: "#0F79DB",
    icon: "/images/dashboard/Dashboard ICONS 1-03.svg",
  },
  "Payment Declined": {
    color: "#C90000",
    icon: "/images/dashboard/Dashboard ICONS 1-09.svg",
  },
  "Card Declined": {
    color: "#A7A7A7",
    icon: "/images/dashboard/Dashboard ICONS 1-08.svg",
  },
  "Session Expired": {
    color: "#A7A7A7",
    icon: "/images/dashboard/Dashboard ICONS 1-08.svg",
  },
  "Session Created": {
    color: "#A7A7A7",
    icon: "/images/dashboard/Dashboard ICONS 1-08.svg",
  },
};

const StatusCard = (props) => {
  const { data, filter } = props;
  return (
    <div
      className="dashboard-status-card"
      style={{ color: statusColors[data?._id].color }}
    >
      <div className="dashboard-status-card-top">
        <h2>{data?._id}</h2>
        <img src={statusColors[data?._id].icon} alt={data?._id} />
      </div>
      <div className="dashboard-status-card-bottom">
        {data?._id == "Success Rate" ? (
          <div>
            <p>{data?.amount.toFixed(2)}%</p>
          </div>
        ) : (
          <div>
            <span className="dashboard-status-card-bottom__currency">
              {data?._id === "Session Created" ? "Count" : filter?.currency}
            </span>
            <p>
              {Intl.NumberFormat("en-IN").format(
                parseInt(
                  data?._id === "Session Created" ? data?.count || 0 : data?.amount || 0
                )
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default function FirstSection(props) {
  const data = props?.data?.status
    ? Object.keys(statusColors).map((key) => {
        let resData = null;
        props?.data?.status?.map((status) => {
          if (key == status._id) {
            resData = status;
          }
        });
        if (!resData) {
          resData = {
            _id: key,
            amount: 0,
          };
        }
        return resData;
      })
    : [];
  return (
    <div>
      <div className="dashboard-status">
        {props?.data && (
          <StatusCard
            data={{
              _id: "Success Rate",
              amount: props.data?.dateRangeData?.success_rate || 0,
            }}
            filter={props.filter}
          />
        )}
        {data?.map(
          (status, index) =>
            statusColors[status._id] &&
            status._id !== "Success Rate" && (
              <StatusCard data={status} filter={props.filter} key={index} />
            )
        )}
      </div>
      <hr></hr>
    </div>
  );
}
