import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useState, useEffect } from "react";
import axios from "axios";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AxiosBase from "../../../../../../../networkRequest/axiosBase";

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
    borderRadius: "10px",
  },
});

const userlist = [
  {
    _id: 12345678910111213,
    firstName: "alamalamalam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "Approved",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "Approved",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "Approved",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "Initiated",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "Initiated",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "Initiated",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "Initiated",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "Expired",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "Expired",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "Expired",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "Expired",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "Expired",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "Expired",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    phone: "7276765423",
    date: "2021082323:24",
    status: "active",
  },
];

export default function History() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [count, setCount] = useState([]);
  //api data state
  const [userdata, setUserData] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    AxiosBase.get(
      `/admin/user?pageNo=0&rowsPerPage=2&sortBy=createdAt&sortOrder=des`
    ).then((response) => {
      setUserData(response.data);
    });
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: "#ffffff",
          padding: "10px",
          marginTop: "1rem",
          borderRadius: "10px",
          boxShadow: "0 5px 5px 0 rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        <TableContainer sx={{ maxHeight: 365, ml: "-15px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className="tbldata" style={{ background: "none" }}>
                <Div
                  className="tbl-box-rows"
                  sx={{
                    py: 1,
                    border: 1,
                  }}
                >
                  <TableCell
                    style={{
                      width: 90,
                    }}
                  >
                    Request ID
                  </TableCell>

                  <TableCell
                    style={{
                      width: 95,
                    }}
                  >
                    Customer Name
                  </TableCell>

                  <TableCell
                    style={{
                      width: 90,
                    }}
                  >
                    Customer No.
                  </TableCell>

                  <TableCell
                    style={{
                      width: 120,
                    }}
                  >
                    Created on:z
                  </TableCell>

                  <TableCell
                    style={{
                      width: 100,
                    }}
                  >
                    Status
                  </TableCell>
                </Div>
              </TableRow>
            </TableHead>
            <TableBody>
              {userlist &&
                userlist.map((row, index) => {
                  {
                    /* const { _id, firstName, lastName, emailId, roleData, status, } = row; */
                  }
                  return (
                    <TableRow
                      className="tblrowdata"
                      style={{ background: "none" }}
                    >
                      <Div
                        className="tbl-box-rows-body"
                        sx={{
                          py: 1,
                        }}
                      >
                        <TableCell
                          style={{
                            width: 90,
                          }}
                        >
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "#2160F6",
                            }}
                          >
                            {row._id}
                          </Link>
                        </TableCell>

                        <TableCell
                          style={{
                            width: 95,
                          }}
                        >
                          {row.firstName}
                        </TableCell>

                        <TableCell
                          style={{
                            width: 90,
                          }}
                        >
                          {row.phone}
                        </TableCell>

                        <TableCell
                          style={{
                            width: 120,
                          }}
                        >
                          {row.date}
                        </TableCell>

                        <TableCell
                          style={{
                            width: 100,
                          }}
                        >
                          <KycStatus status={row.status} />
                        </TableCell>
                      </Div>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={userlist.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="pagination-sec"
        />
      </div>
    </>
  );
}

const KycStatus = (props) => {
  const statusColor = (status) => {
    switch (status) {
      case "Approved":
        return "#2ed06d";
      case "Initiated":
        return "#1058ff";
      case "In progress":
        return "#e3b123";
      case "Expired":
        return "#ff0000";
      default:
        return "#ff0000";
    }
  };
  return (
    <p style={{ color: statusColor(props.status), fontSize: "inherit" }}>
      {props.status}
    </p>
  );
};
