import React from "react";
import { useState, useEffect } from "react";
import "./BasicInformation.css";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "grey" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const BasicInformation = (props) => {
  const {
    rolePermission,
    allStatusCodeMapping,
    id,
    allCurrency,
    AddAcquirerProp,
    UpdateAcquirerProp,
  } = props;
  const [controlDisable, setControlDisable] = React.useState(false);
  const [allCurrencyData, setAllCurrencyData] = React.useState([]);
  const [allStatusData, setAllStatusData] = React.useState({});
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    CommonCallFunction();
  }, [id, allStatusCodeMapping, allCurrency]);

  const CommonCallFunction = () => {
    setAllCurrencyData(allCurrency);
    setAllStatusData(allStatusCodeMapping);
    setLoader(false);
  };

  const SaveCode = async () => {
    const { services } = allStatusData;
    let checkService =
      services?.creditcards?.status || services?.debitcards?.status;
    if (services && Object.keys(services).length !== 0 && checkService) {
      setLoader(true);
      let data = allStatusData;
      await UpdateAcquirerProp(data, data._id);
      setLoader(false);
    } else {
      toast("Please Fill All Mandatory Details", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };

  const ChangeSwitch = (flag, type) => {
    let data = allStatusData;
    if (allStatusData?.services?.[type] && id) {
      allStatusData.services[type].status = flag;
    } else {
      allStatusData.services[type] = {
        fixed_fees: "",
        margin: "",
        status: flag,
        vat: "",
      };
    }
    setAllStatusData({ ...data });
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const confirmReset = () => {
    setOpen(true);
  };

  const resetData = async () => {
    let data = allStatusData;
    data.services = {};
    setLoader(true);
    setAllStatusData({ ...data });
    await UpdateAcquirerProp(data, data._id);
    setOpen(false);
    setLoader(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="BasicInformation">
          <p className="BasicTitle">
            Services <span style={{ color: "red" }}>*</span>
          </p>
          <Grid className="BasicInformationRow2" container spacing={2}>
            <Grid
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              className=""
            >
              <p className="PayMentTitle">Credit Cards</p>
              <IOSSwitch
                checked={allStatusData?.services?.creditcards?.status}
                onChange={(e) => {
                  ChangeSwitch(e.target.checked, "creditcards");
                }}
                label=""
                disabled={!rolePermission.includes("EDIT") || controlDisable}
              />
            </Grid>
            <Grid
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              className=""
            >
              <p className="PayMentTitle">Debit Cards </p>
              <IOSSwitch
                checked={allStatusData?.services?.debitcards?.status}
                label=""
                disabled={!rolePermission.includes("EDIT") || controlDisable}
                onChange={(e) => {
                  ChangeSwitch(e.target.checked, "debitcards");
                }}
              />
            </Grid>
            <Grid
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              className=""
            >
              <p className="PayMentTitle">UPI</p>
              <IOSSwitch
                checked={allStatusData?.services?.upis?.status}
                label=""
                disabled={!rolePermission.includes("EDIT") || controlDisable}
                onChange={(e) => {
                  ChangeSwitch(e.target.checked, "upis");
                }}
              />
            </Grid>
            <Grid
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              className=""
            >
              <p className="PayMentTitle">Wallet</p>
              <IOSSwitch
                checked={allStatusData?.services?.wallets?.status}
                label=""
                disabled={!rolePermission.includes("EDIT") || controlDisable}
                onChange={(e) => {
                  ChangeSwitch(e.target.checked, "wallets");
                }}
              />
            </Grid>
            <Grid
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              className=""
            >
              <p className="PayMentTitle">Net Banking</p>
              <IOSSwitch
                checked={allStatusData?.services?.banks?.status}
                label=""
                disabled={!rolePermission.includes("EDIT") || controlDisable}
                onChange={(e) => {
                  ChangeSwitch(e.target.checked, "banks");
                }}
              />
            </Grid>
            <Grid
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              className=""
            >
              <p className="PayMentTitle">Apple Pay</p>
              <IOSSwitch
                checked={allStatusData?.services?.applepay?.status}
                label=""
                disabled={!rolePermission.includes("EDIT") || controlDisable}
                onChange={(e) => {
                  ChangeSwitch(e.target.checked, "applepay");
                }}
              />
            </Grid>
            <Grid
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              className=""
            >
              <p className="PayMentTitle">Google Pay</p>
              <IOSSwitch
                checked={allStatusData?.services?.googlepay?.status}
                label=""
                disabled={!rolePermission.includes("EDIT") || controlDisable}
                onChange={(e) => {
                  ChangeSwitch(e.target.checked, "googlepay");
                }}
              />
            </Grid>
          </Grid>
          <Grid
            className="BasicInformationRow"
            container
            spacing={2}
            justifyContent="flex-end"
          >
            <Button
              className="merchant_reset_btn merchant_detail_tab_save-cancel_btn reset_btn"
              style={{ "background-color": "#1058FF" }}
              variant="contained"
              disabled={
                !allStatusData?.services?.creditcards?.status &&
                !allStatusData?.services?.debitcards?.status &&
                !allStatusData?.services?.upis?.status &&
                !allStatusData?.services?.wallets?.status &&
                !allStatusData?.services?.banks?.status &&
                !allStatusData?.services?.applepay?.status &&
                !allStatusData?.services?.googlepay?.status
              }
              onClick={confirmReset}
            >
              Reset
            </Button>
            <Button
              disabled={
                !allStatusData?.services?.creditcards?.status &&
                !allStatusData?.services?.debitcards?.status &&
                !allStatusData?.services?.upis?.status &&
                !allStatusData?.services?.wallets?.status &&
                !allStatusData?.services?.banks?.status &&
                !allStatusData?.services?.applepay?.status &&
                !allStatusData?.services?.googlepay?.status
              }
              onClick={SaveCode}
              className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
              style={{ marginRight: "29px" }}
              variant="contained"
            >
              Save
            </Button>
          </Grid>
        </div>
      )}
      <div>
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              <p>Confirm Reset</p>
              <span>Are you sure want to reset configuration?</span>
              <div className="order_detail_refund_popup_btn">
                <Button
                  className="refund_save_btn"
                  variant="contained"
                  autoFocus
                  fullWidth
                  onClick={resetData}
                >
                  Confirm
                </Button>
                <Button
                  className="refund_cancel_btn"
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  fullWidth
                >
                  Cancel
                </Button>
              </div>
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default BasicInformation;
