import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

const SectionC = (props) => {
  const refundData = props.data?.transactionStatusData.filter(x => x.status == "Refund");
  let filterArr = [];
  refundData.map(item => {
    let type = "", order_id = "";
    let ind1 = props.data?.transactionData.findIndex(x => x._id == item.transaction_id);
    type = ind1 > -1 ? props.data?.transactionData[ind1].type : "";
    order_id = ind1 > -1 ? props.data?.transactionData[ind1].order_id : "";
    let ind = filterArr.findIndex(x => x.paymentMethod == type && x.order_id == order_id);
    if(ind == -1){
      filterArr.push({
        paymentMethod: type,
        order_id: order_id,
        count: 1,
        refund_amount: Number(item?.refund_amount),
        // settlementAmt: Number(item?.settlement_amount)
      })
    } else {
      filterArr[ind].count += 1;
      filterArr[ind].refund_amount += Number(item?.refund_amount);
      // filterArr[ind].settlementAmt += Number(item?.settlement_amount);
    }
  });
  return (
    <>
    <img
        src="/pdf_top_sec.svg"
        alt=""
        className="settlement_pdf_templete header-img"
      />
      <div className="inner_pdf_sec">
        <h3 className="Settlement_breakdown_heading">SECTION C</h3>
        
        <div className="Settlement_pdf_sectionD_chargeback">
          <Button className="order_lst_btn">Refunds</Button>
          {/* <p>Refunded Payment occurring Within The Settlement Period.</p> */}
        </div>
        <div className="settlement_view_pdf_tbl">
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row_pdf settlement_view_pdf_tbl_head">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Order Id</TableCell>
                  <TableCell>Processing Currency</TableCell>
                  <TableCell>Payment Type</TableCell>
                  <TableCell>Number of Refunds</TableCell>
                  <TableCell>Refund Amount</TableCell>
                  {/* <TableCell>Amount in Settlement Currency</TableCell> */}
                </TableRow>
              </TableHead>
              {filterArr.map((item) => (
                <TableBody className="data_tbl_body_row_pdf ">
                <TableCell>{item?.order_id}</TableCell>
                <TableCell>{props.data?.currency}</TableCell>
                <TableCell>{item?.paymentMethod}</TableCell>
                <TableCell>{item?.count ? item.count : 0}</TableCell>
                <TableCell>{Number(item?.refund_amount).toFixed(2)}</TableCell>
                {/* <TableCell>{Number(item?.settlementAmt).toFixed(2)}</TableCell> */}
              </TableBody>
              ))}
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default SectionC;
