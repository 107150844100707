import React, { useState, useEffect } from "react";
import AxiosBase from "../../../networkRequest/axiosBase";
import { useParams } from "react-router-dom";
import { Box, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import { unstable_styleFunctionSx } from "@mui/system";
import ListItem from "@mui/material/ListItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import PhoneInput from "react-phone-input-2";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./AddMerchant.css";
import TextField from "@mui/material/TextField";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
// side bar
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
//side bar table
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { PhoneNumberUtil } from "google-libphonenumber";
import {
  AddSuperMerchant,
  UpdateSuperMerchant,
} from "../../../networkRequest/request/merchant";
import CircularProgress from "@mui/material/CircularProgress";
import NavbarHoc from "../../../pages/navhoc/index";
import Config from "../../../config.json";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  if (array?.length) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  return array;
}

//side bar table code end

const Div = styled("div")(unstable_styleFunctionSx);
const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "552px",
    height: "2px",
  },
});

// main function

const AddMerchant = (props) => {
  const id = useParams();
  const history = useNavigate();
  // side bar table code
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [alrt, setAlrt] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [Merchantrows, setMerchantrows] = React.useState([]);
  const [listName, setlistName] = React.useState([]);
  const [pagename, setPageName] = React.useState("Add");
  const [listCnName, setConstlistName] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [rolePermission, setRolePermission] = React.useState([]);
  const [allPermission, setAllPermission] = React.useState([]);
  const [count, setCount] = React.useState(0);

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severiety: "success",
  });

  const [validation, setValidation] = useState({
    merchant_name: "",
    email_id: "",
    mobile_no: "",
    merchant_ids: [],
  });

  const [numbererror, setNumberError] = useState({
    error: false,
    message: "",
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const searchMerchant = (event) => {
    var Filteredrows = [];
    if (event.target.value == "") {
      Filteredrows = Merchantrows;
    } else {
      Merchantrows.map((row, index) => {
        if (row.name.includes(event.target.value)) {
          Filteredrows.push(row);
        }
      });
    }
    setRows(Filteredrows);
  };

  const handleClick = (event, name, index) => {
    let innerrow = rows;
    let innerSelected = selected;
    let innerindex = page * rowsPerPage + index;
    innerrow[innerindex].isSelected = !innerrow[innerindex].isSelected;
    if (!innerrow[innerindex].isSelected) {
      innerSelected = innerSelected.filter((fact) => {
        return fact != name._id;
      });
    } else {
      innerSelected.push(name._id);
    }
    // const selectedIndex = selected.indexOf(name);
    // let newSelected = [];

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }
    setRows([...innerrow]);
    setSelected([...innerSelected]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(+parseInt(event.target.value));
    setPage1(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const success = (anchor) => {
    setlistName(selected);
    setState({ ...state, [anchor]: false });
    return toast("Merchant Successfully Linked", {
      type: "success",
      autoClose: 2000,
    });
  };

  // side bar table code end

  // side bar merchant edit
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
        overflowX: "hidden",
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem className="merchant_edit_drower_menu_top_sec">
          <p>{setPageName} Merchant</p>
        </ListItem>
      </List>
      <Divider />
      <>
        <List>
          <CssTextField
            className="merchant_edit_drower_serch_field"
            id="new-menu"
            placeholder="search"
            onChange={(event) => searchMerchant(event)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment className="merchant_edit_drawer_search_icon">
                  <IconButton>
                    <img src="/search.svg" alt="" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </List>
        <List className="merchant_edit_drower_menu_body_sec">
          <div className="drawer_merchant_tag">
            <p>Merchant:</p>
            {allPermission[Config.PermissionId.Merchants] &&
              allPermission[Config.PermissionId.Merchants].includes("ADD") && (
                <div style={{ marginRight: "2rem", alignItems: "center" }}>
                  <IconButton>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  <Link
                    className="merchant_edit_addmerchnt_txt"
                    to={`/merchants/createmerchant`}
                  >
                    Add New Merchant
                  </Link>
                </div>
              )}
          </div>
          <div>
            <Box>
              <TableContainer className="merchant_edit_drawer_tbl">
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <TableBody>
                    {rows?.length &&
                      rows?.map((row, index) => {
                        const isItemSelected = isSelected(row._id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            className="merchant_edit_drawer_tblBody_row"
                            hover
                            onClick={(event) => handleClick(event, row, index)}
                            role="checkbox"
                            aria-checked={row.isSelected}
                            key={row.id}
                            selected={row.selected}
                          >
                            <Checkbox
                              color="primary"
                              checked={row.isSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                            <Div className="merchant_edit_drawer_tbl_list_box">
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.name}
                              </TableCell>
                            </Div>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <div style={{ borderBottom: "0.5px solid #C9C9C9" }}>
                <div className="merchant_edit_drawer_btn_sec">
                  <Button
                    className="drawer_merchant_cancel_btn"
                    variant="contained"
                    onClick={toggleDrawer(anchor, false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="drawer_merchant_save_btn"
                    variant="contained"
                    onClick={() => {
                      success(anchor);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Box>
          </div>
        </List>
      </>
    </Box>
  );
  // end side bar

  const savedata = async () => {
    if (numbererror.error) {
      return toast(numbererror.message.toString(), {
        type: "error",
        autoClose: 1500,
      });
    }

    if (
      validation.merchant_name &&
      validation.email_id &&
      validation.mobile_no
    ) {
      if (
        !validation.email_id.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        return toast("Invalid Email Id", {
          type: "error",
          autoClose: 2000,
        });
      }
      if (validation.mobile_no) {
        // validation.mobile_no = validation.mobile_no.substring(1);
        validation.mobile_no = validation.mobile_no.replace(/\+/g, "");
        // let phoneUtil = PhoneNumberUtil.getInstance();
        // let NumberFlag = phoneUtil.isValidNumber(
        //   phoneUtil.parse(validation.mobile_no)
        // );
        // if (!NumberFlag)
        //   return toast("Invalid Mobile Number", {
        //     type: "warning",
        //     autoClose: 2000,
        //   });
      }
      validation.merchant_ids = selected;
      let respose;
      if (pagename == "Add") {
        respose = await AddSuperMerchant(validation);
      } else {
        respose = await UpdateSuperMerchant(id.id, validation);
      }
      if (respose?.status !== 400) {
        history("/supermerchant");
        return toast("Super Merchant Added Succesfully", {
          type: "success",
          autoClose: 2000,
        });
      } else {
        return toast(respose?.data.message, {
          type: "error",
          autoClose: 2000,
        });
      }
    } else {
      return toast(
        !validation.merchant_name
          ? "Please Fill Super Merchant Name"
          : !validation.email_id
          ? "Please Fill Super Merchant Email"
          : "Please Fill Super Merchant Mobile Number",
        {
          type: "error",
          autoClose: 2000,
        }
      );
    }
  };

  useEffect(() => {
    getData();
  }, [page]);

  const getData = () => {
    setLoading(true);
    var innerlistname = [];
    if (id?.id) {
      setPageName("Edit");
      AxiosBase.get(`/admin/super-merchant/${id.id}`).then((response) => {
        var reqdata = {
          merchant_name: response?.data?.merchant_name,
          email_id: response?.data?.email_id,
          mobile_no: response?.data?.mobile_no,
          merchant_ids: response?.data?.merchant_ids,
        };
        reqdata.mobile_no = `+${reqdata.mobile_no}`;
        setValidation(reqdata);
        innerlistname = response?.data?.merchant_ids;
        setlistName(response?.data?.merchant_ids);
        setConstlistName(response?.data?.merchant_ids);
        setLoading(false);
      });
    }
    AxiosBase.get(
      `/admin/merchant?pageNo=${page}&rowsPerPage=${rowsPerPage}`
    ).then((response) => {
      var merdata = [];
      response?.data?.data?.map((lst) => {
        // if (lst?.merchant_name != undefined || lst.first_name != undefined) {
        merdata.push({
          name:
            (lst?.merchant_name == "undefined"
              ? lst?.first_name == "undefined"
                ? ""
                : lst?.first_name
              : lst?.merchant_name) +
            "(" +
            lst._id +
            ")",
          _id: lst?._id,
          selected: true,
          isSelected: innerlistname.includes(lst._id),
        });
        // }
      });
      setSelected(innerlistname);
      // merdata.sort(dynamicSort("name"));
      setRows(merdata);
      setMerchantrows(merdata);
      setCount(response?.data?.count || 0);
    });
  };

  function dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  return (
    <NavbarHoc
      id={Config.PermissionId.SuperMerchants}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
        setAllPermission(e.AllData);
      }}
    >
      {/* super merchant heading  */}
      <Box>
        <h1 className="merchant_title">Super Merchant ID</h1>
      </Box>
      {/* super merchant heading end  */}

      {/* Edit page code */}

      <div
        className="merchant_list_main_div"
        style={{ "padding-bottom": "5px" }}
      >
        <div className="merchant_list_inner_title_div">
          <h3 className="merchant_list_inner_title">
            {pagename} Super Merchant
          </h3>
        </div>

        <div className="merchant_edit_inputs_div">
          <div style={{ width: "100%" }}>
            <div className="merchant_edit_sep_div">
              <p>
                Super Merchant Name <span style={{ color: "red" }}> *</span>:
              </p>

              <CssTextField
                id="new-menu"
                variant="outlined"
                value={validation.merchant_name}
                placeholder="Super Merchant Name"
                onChange={(e) => {
                  setValidation({
                    ...validation,
                    merchant_name: e.target.value,
                  });
                }}
                inputProps={{
                  maxlength: 100,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="merchant_edit_field_icongroup">
                      <IconButton color="primary">
                        <img src="/person.svg" alt="" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="merchant_edit_sep_div">
              <p>
                Mobile Number <span style={{ color: "red" }}> *</span> :
              </p>

              <PhoneInput
                className="mobile-input"
                name="mobile_no"
                country={"ae"}
                countryCodeEditable={true}
                placeholder="Super Merchant Number"
                value={validation.mobile_no}
                onChange={(e, countrycode) => {
                  let val = e.replace(/-|\s/g, "");
                  if (val.length < 12 || val.length > 13) {
                    setNumberError({
                      error: true,
                      message: "Please enter valid mobile number",
                    });
                  } else {
                    setNumberError({ error: false, message: "" });
                  }
                  setValidation({
                    ...validation,
                    mobile_no: val,
                    mobile_code: countrycode.dialCode,
                  });
                }}
              />
            </div>
            <div className="merchant_edit_sep_div">
              <p>
                Email ID <span style={{ color: "red" }}> *</span> :
              </p>
              <CssTextField
                id="new-menu"
                type="email"
                variant="outlined"
                value={validation.email_id}
                placeholder="Super Merchant Email"
                required
                error={
                  !validation.email_id.match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ) && validation.email_id !== ""
                }
                helperText={
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    validation.email_id
                  ) || validation.email_id === ""
                    ? " "
                    : "email must be between 6 to 30 characters"
                }
                onChange={(e) => {
                  setValidation({
                    ...validation,
                    email_id: e.target.value,
                  });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="merchant_edit_field_icongroup">
                      <IconButton color="primary">
                        <img
                          src={`${process.env.PUBLIC_URL}/top-email.svg`}
                          alt=""
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="merchant_edit_merchnt_dev">
              <p>Merchant :</p>

              <div className="super-merchant-merchant">
                <Div className="merchant_edit_list_box">
                  <div style={{ display: "flex" }}>
                    <IconButton style={{ height: "3rem" }} color="primary">
                      <img src="/merchant-persion-icon.svg" alt="" />
                    </IconButton>

                    <TableContainer className="merchant_edit_merchantname_tbl">
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead></TableHead>
                        <TableBody>
                          {listName.map((lst) => {
                            return (
                              <TableRow className="merchant_edit_merchantname_tbl_body_row">
                                <TableCell>{lst}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Div>

                {/* Edit page code */}

                {/* edit button drawer  */}
                <div>
                  {["right"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      {rolePermission.includes(
                        pagename == "Add" ? "ADD" : "EDIT"
                      ) && (
                        <Button
                          className="merchant_edit_mrchnt_edt_btn"
                          onClick={toggleDrawer(anchor, true)}
                          variant="contained"
                          startIcon={<AddCircleOutlineOutlinedIcon />}
                        >
                          {pagename}
                        </Button>
                      )}
                      <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                      >
                        {list(anchor)}
                      </SwipeableDrawer>
                    </React.Fragment>
                  ))}
                </div>

                {/* edit button drawer  */}
              </div>
            </div>
          </div>
        </div>
        <div className="merchant_edit_button_dev">
          <Button
            className="merchant_cancel_btn"
            variant="contained"
            onClick={() => {
              history("/supermerchant");
            }}
          >
            Cancel
          </Button>
          {rolePermission.includes(pagename == "Add" ? "ADD" : "EDIT") && (
            <Button
              onClick={() => {
                savedata();
              }}
              className="merchant_save_btn"
              variant="contained"
            >
              Save
            </Button>
          )}
        </div>
      </div>
    </NavbarHoc>
  );
};

export default AddMerchant;
