import React, { useState, useEffect } from "react";
import "./KycDetails.css";
import AxiosBase from "../../../networkRequest/axiosBase";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { Box, Paper, styled } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import moment from "moment";
import KycDocument from "./kyc-document/KycDocument";
import { saveAs } from "file-saver";

//side bar table code
const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const KycDetails = (props) => {
  const { rolePermission, showFrom, status } = props;
  const search = useLocation().search;
  let kycId = new URLSearchParams(search).get("kycId");
  const [image, setImage] = React.useState("/yash1.jpg");
  const [merchantDocument, setMerchantDocument] = React.useState([]);
  const [document, setDocument] = React.useState([]);
  const [finalDocument, setFinalDocument] = React.useState([]);
  const [loader, setloader] = React.useState(false);
  const [datafound, setdatafound] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severiety: "success",
  });
  const [selected, setSelected] = React.useState([]);
  const [spinner, setSpinner] = useState(false);
  const [state, setState] = React.useState({
    right: false,
  });
  const [selectedKyc, setSelectedKyc] = React.useState({});
  const [currentSaveCancelId, setCurrentSaveCancelId] = useState("");
  const [currentEditId, setCurrentEditId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [ext, setExt] = React.useState("");
  const [docStatusArr, setDocStatusArr] = React.useState([]);
  const [selectedKycId, setSelectedKycId] = React.useState(null);

  useEffect(() => {
    setSelectedKycId(props?.merchantDocument?._id);
    var url = `/admin/kyc/country/${props.user?.alphaThreeCode}`;
    setMerchantDocument(props?.merchantDocument);
    AxiosBase.get(url).then((response) => {
      const filterDoc = response.data.filter((x) => x.status);
      setDocument(filterDoc);
      let arr = [];
      filterDoc.map((doc) => {
        let ind = props?.merchantDocument?.kyc_data.findIndex(
          (x) => x.type == doc.type
        );
        if (ind > -1) {
          doc.is_verified = props?.merchantDocument?.kyc_data[ind]?.is_verified;
          doc.url = props?.merchantDocument?.kyc_data[ind]?.url;
          doc.data = props?.merchantDocument?.kyc_data[ind]?.data;
          doc.date = props?.merchantDocument?.kyc_data[ind]?.date;
          arr.push(doc);
        } else if (props.showFrom == "upload_doc") {
          doc.is_verified = "Not Submitted";
          doc.url = "";
          doc.data = {};
          doc.date = "";
          arr.push(doc);
        }
      });
      props?.merchantDocument?.kyc_data?.map((doc) => {
        let ind = filterDoc.findIndex((x) => x.type == doc.type);
        if (ind == -1) {
          arr.push({
            business_type: "",
            country: props.user?.alphaThreeCode,
            document_type: "Other",
            id_classification: false,
            identities_extractions: false,
            image_quality: false,
            is_expiry: false,
            is_required: true,
            is_verified: doc?.is_verified,
            isdeleted: false,
            label: doc.name,
            name: doc?.name,
            type: doc?.type,
            url: doc?.url,
            data: doc?.data,
            _id: doc?._id,
            date: doc?.date,
          });
        }
      });
      setFinalDocument(arr);
    });
  }, [merchantDocument]);

  const onSubmitKyc = (doc, selectedDoc) => {
    setSelectedKycId(doc.kycId);
    const ind = finalDocument.findIndex((x) => x.type == selectedDoc.type);
    if (ind != -1) {
      finalDocument[ind].is_verified = doc?.doc_status;
      finalDocument[ind].url = doc?.url;
      finalDocument[ind].date = new Date();
      setFinalDocument(
        Object.values({
          ...finalDocument,
          [ind]: {
            ...finalDocument[ind],
            is_verified: doc?.doc_status,
            url: doc?.url,
          },
        })
      );
    } else {
      finalDocument.push({
        business_type: "",
        country: props.user?.alphaThreeCode,
        document_type: selectedDoc?.document_type,
        id_classification: false,
        identities_extractions: false,
        image_quality: false,
        is_expiry: false,
        is_required: true,
        is_verified: doc?.doc_status,
        isdeleted: false,
        label: selectedDoc?.name,
        name: selectedDoc?.name,
        type: selectedDoc?.type,
        url: doc?.url,
        date: new Date(),
      });
      setFinalDocument(finalDocument);
    }
    props.onUploadDocument(doc.kycId);
    setState({ ...state, ["right"]: false });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const detailKyc = (anchor, open, selected) => {
    selected.allow_next = false;
    selected.allow_preview = false;
    let ind = finalDocument.findIndex((x) => x.type == selected.type);
    selected.allow_next = ind < finalDocument.length - 1;
    selected.allow_preview = ind > 0;
    setSelectedKyc(selected);
    setState({ ...state, [anchor]: open });
  };

  const addKycDocument = () => {
    setSelectedKyc({
      name: "",
      document_type: "Other",
      type: "",
      is_verified: "Pending",
      url: "",
      allow_next: false,
      allow_preview: finalDocument?.length > 0,
    });
    setState({ ...state, ["right"]: true });
  };

  const handleClose = () => {
    setOpen(false);
    setExt("");
  };

  const showDocument = (doc) => {
    if (doc.is_verified == "Not Submitted" || doc.url == "") {
      return;
    }
    setSelectedKyc(doc);
    var file_ext = doc?.url.substr(
      doc?.url.lastIndexOf(".") + 1,
      doc?.url.length
    );
    if (file_ext == "pdf") {
      saveAs(doc?.url, `${doc?.name}.${file_ext}`);
    } else {
      setOpen(true);
    }
  };

  const onChangeKycStatus = (obj, doc) => {
    const ind = finalDocument.findIndex((x) => x.type == selected.type);
    if (ind != -1) {
      finalDocument[ind].is_verified = doc?.is_verified;
      setFinalDocument(
        Object.values({
          ...finalDocument,
          [ind]: {
            ...finalDocument[ind],
            is_verified: doc?.is_verified,
            url: doc?.url,
          },
        })
      );
    }
    docStatusArr.push(obj);
    setDocStatusArr(docStatusArr);
  };

  const onFinalSubmitDocStatus = () => {
    AxiosBase.post(`/admin/kyc/change-kyc-document-status/${selectedKycId}`, {
      doc: docStatusArr,
    })
      .then((res) => {
        if (res?.status == 200) {
          setState({ ...state, ["right"]: false });
          toast("Successfully updated kyc status", {
            type: "success",
            autoClose: 2000,
          });
          return true;
        }
      })
      .catch((err) => {
        toast(
          err?.response?.data?.message
            ? err?.response?.data?.message.toString()
            : "Something went wrong",
          {
            type: "error",
            autoClose: 2000,
          }
        );
        return false;
      });
  };

  const onNextDoc = (doc, selectedDoc, type = "next") => {
    if (type == "save_and_next" && doc && selectedDoc) {
      setSelectedKycId(doc.kycId);
      const ind = finalDocument.findIndex((x) => x.type == selectedDoc.type);
      if (ind != -1) {
        finalDocument[ind].is_verified = doc?.doc_status;
        finalDocument[ind].url = doc?.url;
        finalDocument[ind].date = new Date();
        setFinalDocument(
          Object.values({
            ...finalDocument,
            [ind]: {
              ...finalDocument[ind],
              is_verified: doc?.doc_status,
              url: doc?.url,
            },
          })
        );
      } else {
        finalDocument.push({
          business_type: "",
          country: props.user?.alphaThreeCode,
          document_type: selectedDoc?.document_type,
          id_classification: false,
          identities_extractions: false,
          image_quality: false,
          is_expiry: false,
          is_required: true,
          is_verified: doc?.doc_status,
          isdeleted: false,
          label: selectedDoc?.name,
          name: selectedDoc?.name,
          type: selectedDoc?.type,
          url: doc?.url,
          date: new Date(),
        });
        setFinalDocument(finalDocument);
      }
      props.onUploadDocument(doc.kycId);
    }
    let ind = finalDocument.findIndex((x) => x.type == selectedKyc.type);
    if (ind < finalDocument.length - 1) {
      let selectedNew = finalDocument[ind + 1];
      let ind1 = finalDocument.findIndex((x) => x.type == selectedNew.type);
      selectedNew.allow_next = ind1 < finalDocument.length - 1;
      selectedNew.allow_preview = true;
      setSelectedKyc(selectedNew);
      setState({ ...state, ["right"]: false });
      setState({ ...state, ["right"]: true });
    }
  };

  const onPreviewDoc = () => {
    let ind = finalDocument.findIndex((x) => x.type == selectedKyc.type);
    if (ind < finalDocument.length) {
      let selected = finalDocument[ind - 1];
      let ind1 = finalDocument.findIndex((x) => x.type == selected.type);
      selected.allow_next = true;
      selected.allow_preview = ind1 > 0;
      setSelectedKyc(selected);
      setState({ ...state, ["right"]: false });
      setState({ ...state, ["right"]: true });
    }
  };

  const list = (anchor) => (
    <Box
      className="kyc_detail_box"
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
        overflowX: "hidden",
      }}
      role="presentation"
    >
      <List>
        <ListItem className="kyc-doc-flyout-heading">
          <p>
            Viewing requirements for{" "}
            {selectedKyc?.name ? selectedKyc?.name : "New Document"}
          </p>
        </ListItem>
      </List>
      <Divider />
      {
        <>
          <div className="kyc_array">
            {
              <KycDocument
                SelectedKyc={selectedKyc}
                kyc_id={selectedKycId}
                user={props.user}
                showFrom={props?.showFrom}
                toggleDrawer={toggleDrawer}
                onSubmitKyc={onSubmitKyc}
                onNextDoc={onNextDoc}
                onPreviewDoc={onPreviewDoc}
                onChangeKycStatus={onChangeKycStatus}
                onFinalSubmitDocStatus={onFinalSubmitDocStatus}
                status={status}
              />
            }
          </div>
        </>
      }
    </Box>
  );

  return (
    <>
      {spinner ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress style={{ color: "#1058ff" }} />
        </div>
      ) : (
        <>
          <div className="kyc_kyc_input_div">
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="kyc_kyc_input_grid th_heading"
            >
              <p>{"Document Name"}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="kyc_kyc_input_grid th_heading"
            >
              <p>{"Document Type"}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="kyc_kyc_input_grid th_heading"
            >
              <p>{"Created On"}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="kyc_kyc_input_grid th_heading"
            >
              <p>{"Required"}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="kyc_kyc_input_grid"
            >
              <p>{"Status"}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              style={{ width: "150px" }}
              className="kyc_kyc_input_grid th_heading"
            >
              <p>{"Action"}</p>
            </Grid>
          </div>
          {finalDocument.map((elm, index) => {
            const { _id } = elm;
            return (
              <div key={_id} className="kyc_kyc_input_div kyc_row">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={1}
                  lg={1}
                  xl={1}
                  className={
                    elm.is_verified != "Not Submitted"
                      ? "kyc_row_name kyc_name_cell"
                      : "kyc_row_name"
                  }
                >
                  <p
                    onClick={() => {
                      showDocument(elm);
                    }}
                  >
                    {elm.name}
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={1}
                  lg={1}
                  xl={1}
                  className="kyc_kyc_input_grid kyc_row_name"
                >
                  <p>{elm.document_type}</p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={1}
                  lg={1}
                  xl={1}
                  className="kyc_kyc_input_grid"
                >
                  <p>
                    {elm.date ? moment(elm.date).format("DD/MM/YYYY") : "NA"}
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={1}
                  lg={1}
                  xl={1}
                  className="kyc_kyc_input_grid"
                >
                  <p>{elm.is_required ? "Yes" : "No"}</p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={1}
                  lg={1}
                  xl={1}
                  className="kyc_kyc_input_grid kyc_row_name"
                >
                  <p>
                    {elm.is_verified != "Approved" &&
                    elm.is_verified != "Rejected" ? (
                      <label>{elm.is_verified}</label>
                    ) : elm.is_verified == "Approved" ? (
                      <label style={{ color: "green" }}>Approved</label>
                    ) : (
                      <label style={{ color: "red" }}>Rejected</label>
                    )}
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={1}
                  lg={1}
                  xl={1}
                  style={{ width: "150px" }}
                  className="kyc_kyc_second_grid_sec"
                >
                  <div className="kyc_kyc_edit_button_dev kyc_kyc_drawer_save_cancel_btn">
                    {["right"].map((anchor) => (
                      <React.Fragment key={anchor}>
                        <Button
                          className="kyc_detailed_btn"
                          onClick={(e) => {
                            detailKyc(anchor, true, elm);
                          }}
                          variant="contained"
                          title="upload kyc document"
                        >
                          <i className="fa fa-pencil"></i>
                        </Button>
                        <SwipeableDrawer
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                          onOpen={toggleDrawer(anchor, true)}
                        >
                          {list(anchor)}
                        </SwipeableDrawer>
                      </React.Fragment>
                    ))}
                  </div>
                </Grid>
              </div>
            );
          })}
          {props.showFrom == "upload_doc" ? (
            <div
              className="add-document-section"
              style={{ "margin-top": "10px" }}
            >
              <a
                onClick={() => {
                  addKycDocument();
                }}
              >
                <i className="fa fa-plus"></i>&nbsp;&nbsp;ADD DOCUMENT
              </a>
            </div>
          ) : (
            <></>
          )}
          <div>
            <BootstrapDialog
              className="order_detail_refund_popup"
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <img src={selectedKyc?.url} width="100%" />
            </BootstrapDialog>
          </div>
        </>
      )}
    </>
  );
};

export default KycDetails;
