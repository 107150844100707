import React, { useEffect } from "react";
import "./FraudAndRisk.css";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Box, Paper } from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AxiosBase from "../../../networkRequest/axiosBase";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import PayerVelocity from "./payerVelocity";
import ReceiverVelocity from "./receiverVelocity";
import {
  updateMerchantFees,
  getMerchantFees,
} from "../../../networkRequest/request/configuration";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const CssTextField = styled(TextField)({
  "input#new-menuTransaction": {
    height: "5px",
    color: "#666666",
  },
  "input#new-menu": {
    width: "520px",
    height: "5px",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const default_velocity = {
  max_amt: [
    {
      value: "",
      error_code: "",
      error_msg: "",
      status: true,
      title: "Maximum Amount",
      type: "Per Hour",
    },
    {
      value: "",
      error_code: "",
      error_msg: "",
      status: true,
      title: "Maximum Amount",
      type: "Per Day",
    },
    {
      value: "",
      error_code: "",
      error_msg: "",
      status: true,
      title: "Maximum Amount",
      type: "Per Week",
    },
  ],
  transaction_limit: [
    {
      value: "",
      error_code: "",
      error_msg: "",
      status: true,
      title: "Number of transactions",
      type: "Per Hour",
    },
    {
      value: "",
      error_code: "",
      error_msg: "",
      status: true,
      title: "Number of transactions",
      type: "Per Day",
    },
    {
      value: "",
      error_code: "",
      error_msg: "",
      status: true,
      title: "Number of transactions",
      type: "Per Week",
    },
  ],
  min_single_amt: {
    value: "",
    error_code: "",
    error_msg: "",
    status: true,
    title: "Max and Min Transactions amount",
    type: "Minimum Amount",
  },
  max_single_amt: {
    value: "",
    error_code: "",
    error_msg: "",
    status: true,
    title: " Max and Min Transactions amount",
    type: "Maximum Amount",
  },
};

const default_velocity_receiver = {
  max_amt_receiver: [
    {
      value: "",
      error_code: "",
      error_msg: "",
      status: true,
      title: "Maximum Amount",
      type: "Per Hour",
    },
    {
      value: "",
      error_code: "",
      error_msg: "",
      status: true,
      title: "Maximum Amount",
      type: "Per Day",
    },
    {
      value: "",
      error_code: "",
      error_msg: "",
      status: true,
      title: "Maximum Amount",
      type: "Per Week",
    },
  ],
  transaction_limit_receiver: [
    {
      value: "",
      error_code: "",
      error_msg: "",
      status: true,
      title: "Number of transactions",
      type: "Per Hour",
    },
    {
      value: "",
      error_code: "",
      error_msg: "",
      status: true,
      title: "Number of transactions",
      type: "Per Day",
    },
    {
      value: "",
      error_code: "",
      error_msg: "",
      status: true,
      title: "Number of transactions",
      type: "Per Week",
    },
  ],
  min_single_amt_receiver: {
    value: "",
    error_code: "",
    error_msg: "",
    status: true,
    title: "Max and Min Transactions amount",
    type: "Minimum Amount",
  },
  max_single_amt_receiver: {
    value: "",
    error_code: "",
    error_msg: "",
    status: true,
    title: " Max and Min Transactions amount",
    type: "Maximum Amount",
  },
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: "15px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const FraudAndRisk = (props) => {
  const { rolePermission } = props;
  const [editorState, setEditorState] = React.useState({});
  const [errorList, setErrorList] = React.useState({});
  const [errorString, setErrorString] = React.useState("");
  const [errorView, setErrorView] = React.useState({});
  const [controlDisable, setControlDisable] = React.useState(true);
  const [loader, setLoader] = React.useState(true);
  const [messageLoader, setMessageLoader] = React.useState(false);
  const [countryList, setCountryList] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState({});
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [isValChange, serIsValChange] = React.useState(false);
  const [isValChangePopup, setIsValChangePopup] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [resetEnable, setResetEnable] = React.useState(false);

  useEffect(() => {
    setSelectedIndex(0);
    setLoader(true);
    if (props.fromSource == "merchant") {
      GetVelocity("");
    } else {
      AxiosBase.get(`/admin/country-master`).then((res) => {
        setCountryList(res?.data);
        if (res?.data?.length) {
          let ind = res.data.findIndex((x) => x.mobile_code == "971");
          setSelectedCountry(
            ind != -1 ? res.data[ind].currency_code : res.data[0].currency_code
          );
          GetVelocity(
            ind != -1 ? res.data[ind].currency_code : res.data[0].currency_code
          );
        }
      });
    }
  }, []);

  const onChangeCountry = (country) => {
    setSelectedCountry(country);
    setLoader(true);
    GetVelocity(country);
  };

  const GetVelocity = (country) => {
    if (props.fromSource == "merchant") {
      getMerchantFees(props.customerId).then((response) => {
        setResetEnable(
          response?.data?.pay_velocity || response?.data?.receive_velocity
        );
        const max_amt =
          response?.data?.pay_velocity &&
          response?.data?.pay_velocity?.max_amt.length
            ? response.data?.pay_velocity.max_amt
            : JSON.parse(JSON.stringify(default_velocity.max_amt));
        const transaction_limit =
          response?.data?.pay_velocity &&
          response?.data?.pay_velocity?.transaction_limit.length
            ? response.data?.pay_velocity.transaction_limit
            : JSON.parse(JSON.stringify(default_velocity.transaction_limit));
        const min_single_amt =
          response?.data?.pay_velocity &&
          response?.data?.pay_velocity?.min_single_amt
            ? response.data?.pay_velocity.min_single_amt
            : JSON.parse(JSON.stringify(default_velocity.min_single_amt));
        const max_single_amt =
          response?.data?.pay_velocity &&
          response?.data?.pay_velocity?.max_single_amt
            ? response.data?.pay_velocity.max_single_amt
            : JSON.parse(JSON.stringify(default_velocity.max_single_amt));

        const max_amt_receiver =
          response?.data?.receive_velocity &&
          response?.data?.receive_velocity?.max_amt_receiver.length
            ? response.data?.receive_velocity.max_amt_receiver
            : JSON.parse(
                JSON.stringify(default_velocity_receiver.max_amt_receiver)
              );
        const transaction_limit_receiver =
          response?.data?.receive_velocity &&
          response?.data?.receive_velocity?.transaction_limit_receiver.length
            ? response.data?.receive_velocity.transaction_limit_receiver
            : JSON.parse(
                JSON.stringify(
                  default_velocity_receiver.transaction_limit_receiver
                )
              );
        const min_single_amt_receiver =
          response?.data?.receive_velocity &&
          response?.data?.receive_velocity?.min_single_amt_receiver
            ? response.data?.receive_velocity.min_single_amt_receiver
            : JSON.parse(
                JSON.stringify(
                  default_velocity_receiver.min_single_amt_receiver
                )
              );
        const max_single_amt_receiver =
          response?.data?.receive_velocity &&
          response?.data?.receive_velocity?.max_single_amt_receiver
            ? response.data?.receive_velocity.max_single_amt_receiver
            : JSON.parse(
                JSON.stringify(
                  default_velocity_receiver.max_single_amt_receiver
                )
              );
        setErrorList({
          max_amt,
          transaction_limit,
          min_single_amt,
          max_single_amt,
          max_amt_receiver,
          transaction_limit_receiver,
          min_single_amt_receiver,
          max_single_amt_receiver,
        });
        setLoader(false);
      });
    } else {
      AxiosBase.get(`/admin/velocity/${country}`).then((response) => {
        const payerData =
          response?.data && response?.data?.min_single_amt
            ? response.data
            : JSON.parse(JSON.stringify(default_velocity));

        const max_amt_receiver =
          response?.data && response?.data?.max_amt_receiver.length
            ? response.data.max_amt_receiver
            : JSON.parse(
                JSON.stringify(default_velocity_receiver.max_amt_receiver)
              );
        const transaction_limit_receiver =
          response?.data && response?.data?.transaction_limit_receiver.length
            ? response.data.transaction_limit_receiver
            : JSON.parse(
                JSON.stringify(
                  default_velocity_receiver.transaction_limit_receiver
                )
              );
        const min_single_amt_receiver =
          response?.data && response?.data?.min_single_amt_receiver
            ? response.data.min_single_amt_receiver
            : JSON.parse(
                JSON.stringify(
                  default_velocity_receiver.min_single_amt_receiver
                )
              );
        const max_single_amt_receiver =
          response?.data && response?.data?.max_single_amt_receiver
            ? response.data.max_single_amt_receiver
            : JSON.parse(
                JSON.stringify(
                  default_velocity_receiver.max_single_amt_receiver
                )
              );
        setErrorList({
          ...payerData,
          max_amt_receiver,
          transaction_limit_receiver,
          min_single_amt_receiver,
          max_single_amt_receiver,
        });
        setLoader(false);
      });
    }
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open, elm, string) => (event) => {
    if (controlDisable) {
      return;
    }
    setIsValChangePopup(false);
    setErrorView({ ...elm });
    setErrorString(string);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const SaveRule = async (anchor) => {
    if (
      errorView.value !== "" &&
      errorView.error_code !== "" &&
      errorView.title !== "" &&
      errorView.error_msg !== ""
    ) {
      let requestObject = {};
      setMessageLoader(true);
      if (
        errorString == "min_single_amt" ||
        errorString == "max_single_amt" ||
        errorString == "min_single_amt_receiver" ||
        errorString == "max_single_amt_receiver"
      ) {
        requestObject[errorString] = errorView;
      } else {
        requestObject[errorString] = errorList[errorString];
        let index = requestObject[errorString].findIndex(
          (e) => e.type == errorView.type
        );
        requestObject[errorString][index] = errorView;
      }
      if (props.fromSource == "merchant") {
        errorList[errorString] = requestObject[errorString];
        var res = await updateMerchantFees({
          _id: props.customerId,
          pay_velocity: {
            max_amt: errorList?.max_amt,
            transaction_limit: errorList?.transaction_limit,
            min_single_amt: errorList?.min_single_amt,
            max_single_amt: errorList?.max_single_amt,
          },
          receive_velocity: {
            max_amt_receiver: errorList?.max_amt_receiver,
            transaction_limit_receiver: errorList?.transaction_limit_receiver,
            min_single_amt_receiver: errorList?.min_single_amt_receiver,
            max_single_amt_receiver: errorList?.max_single_amt_receiver,
          },
        });
       if(res?.status == 200){ 
        toast("Error Message Successfully Updated", {
          type: "success",
          autoClose: 2000,
        });
        GetVelocity("");
        setState({ ...state, [anchor]: false });
      }else{
        toast(JSON.stringify(res?.response?.data?.message || "Something went wrong"), {
          type: "error",
          autoClose: 2000,
        });
      }
        
      } else {
        requestObject.currency_code = selectedCountry;
        await AxiosBase.patch(`/admin/velocity`, requestObject)
          .then((response) => {
            toast("Error Message Successfully Updated", {
              type: "success",
              autoClose: 2000,
            });
            GetVelocity(selectedCountry);
            setState({ ...state, [anchor]: false });
          })
          .catch((err) => {
            toast(err?.response?.data?.message, {
              type: "error",
              autoClose: 2000,
            });
          });
      }
      setMessageLoader(false);
    } else {
      toast("Please Fill The Mandatory Details.", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
        overflowX: "hidden",
      }}
      role="presentation"
    >
      <List>
        <ListItem className="merchant_edit_drower_menu_top_sec fraud_risk_drawer_top_sec">
          <p>
            Mobile App Message
            <span>{` (` + errorView?.title + `-` + errorView?.type + `)`}</span>
          </p>
        </ListItem>
      </List>
      <Divider />
      {messageLoader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <List className="fraud_risk_drawer_main_body">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="fraud_risk_drawer_grid"
          >
            <p>
              Transaction Amount <span>*</span>
            </p>
            <CssTextField
              className="merchant_list_search_bar"
              id="new-menuTransaction"
              placeholder="Transaction Amount"
              value={errorView?.value}
              disabled={controlDisable}
              onChange={(e) => {
                let expiryval = e.target.value.match("^[0-9]*$");
                if (expiryval) {
                  setIsValChangePopup(true);
                  let data = errorView;
                  data.value = e.target.value;
                  setErrorView({ ...data });
                }
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="fraud_risk_drawer_grid"
          >
            <p>
              Error Code <span>*</span>
            </p>
            <CssTextField
              className="merchant_list_search_bar"
              id="new-menuTransaction"
              placeholder="Error Code"
              value={errorView?.error_code}
              disabled={controlDisable}
              onChange={(e) => {
                let expiryval = e.target.value.match("^[0-9]*$");
                if (expiryval) {
                  setIsValChangePopup(true);
                  let data = errorView;
                  data.error_code = e.target.value;
                  setErrorView({ ...data });
                }
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="fraud_risk_drawer_grid"
          >
            <p>
              Title <span>*</span>
            </p>
            <CssTextField
              className="merchant_list_search_bar"
              id="new-menuTransaction"
              disabled={controlDisable}
              value={errorView?.title}
              placeholder="Title"
              onChange={(e) => {
                setIsValChangePopup(true);
                let data = errorView;
                data.title = e.target.value;
                setErrorView({ ...data });
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="fraud_risk_drawer_grid"
          >
            <p>
              Body <span>*</span>
            </p>
            <TextareaAutosize
              aria-label="empty textarea"
              disabled={controlDisable}
              onChange={(e) => {
                setIsValChangePopup(true);
                let data = errorView;
                data.error_msg = e.target.value;
                setErrorView({ ...data });
              }}
              fullWidth
              style={{ padding: "3%" }}
              value={errorView?.error_msg}
              minRows={10}
              className="fraud_risk_drawer_textarea"
            />
          </Grid>
          <div className="merchant_edit_button_dev fraud_risk_drawer_save_cancel_btn">
            <Button
              className="merchant_cancel_btn"
              variant="contained"
              onClick={toggleDrawer(anchor, false, {})}
            >
              Cancel
            </Button>
            {rolePermission.includes("EDIT") && !controlDisable && (
              <Button
                className="merchant_save_btn"
                variant="contained"
                disabled={!isValChangePopup}
                onClick={() => SaveRule(anchor)}
              >
                Save
              </Button>
            )}
          </div>
        </List>
      )}
    </Box>
  );

  const ChangeValue = (val, maintype, type, index) => {
    let ErrorData = errorList;
    serIsValChange(true);
    if (type == "status") {
      if (
        maintype === "min_single_amt" ||
        maintype === "max_single_amt" ||
        maintype === "min_single_amt_receiver" ||
        maintype === "max_single_amt_receiver"
      ) {
        ErrorData[maintype].status = val;
      } else {
        ErrorData[maintype][index].status = val;
      }
      setErrorList({ ...ErrorData });
    } else {
      if (
        maintype == "max_amt" ||
        maintype == "transaction_limit" ||
        maintype == "max_amt_receiver" ||
        maintype == "transaction_limit_receiver"
      ) {
        ErrorData[maintype][index].helperText = "";
        if (index == 0) {
          val < ErrorData[maintype][1].value &&
            (ErrorData[maintype][1].helperText = "");
          val < ErrorData[maintype][2].value &&
            (ErrorData[maintype][2].helperText = "");
          if (val == "") {
            ErrorData[maintype][index].helperText =
              "The Per Hour value cannot be empty or zero";
          } else if (
            val >= ErrorData[maintype][1].value ||
            val >= ErrorData[maintype][2].value
          ) {
            ErrorData[maintype][index].helperText =
              "The Per Hour value should be smaller than the Per Day/Per Week.";
          } else {
            ErrorData[maintype][index].helperText = "";
          }
        } else if (index == 1) {
          if (val > ErrorData[maintype][0].value) {
            ErrorData[maintype][0].helperText = "";
          }
          if (val == "") {
            ErrorData[maintype][index].helperText =
              "The Per Day value cannot be empty or zero";
          }
          if (
            val > ErrorData[maintype][0].value &&
            val < ErrorData[maintype][2].value
          ) {
            ErrorData[maintype][index].helperText = "";
          } else if (val > ErrorData[maintype][2].value) {
            ErrorData[maintype][index].helperText =
              "The Per Day value should be smaller than the Per Week.";
          } else if (val < ErrorData[maintype][0].value) {
            ErrorData[maintype][index].helperText =
              "The Per Day value should be greater than the Per Hour.";
          }
        } else if (index == 2) {
          val > ErrorData[maintype][0].value &&
            (ErrorData[maintype][0].helperText = "");
          val > ErrorData[maintype][1].value &&
            (ErrorData[maintype][1].helperText = "");

          if (val == "") {
            ErrorData[maintype][index].helperText =
              "The Per Week value cannot be empty or zero";
          } else if (
            val > ErrorData[maintype][0].value &&
            val > ErrorData[maintype][1].value
          ) {
            ErrorData[maintype][index].helperText = "";
          } else {
            ErrorData[maintype][index].helperText =
              "The Per Week value should be greater than the Per Day/Per Hour.";
          }
        }
        ErrorData[maintype][index].value = Number(val);
      } else {
        if (maintype == "min_single_amt") {
          if (val == "") {
            ErrorData[maintype].helperText =
              "Minimum value cannot be empty or zero";
          } else if (val == 0 || val < 0) {
            ErrorData[maintype].helperText =
              "Minium value can not be less than or equal to Zero.";
          } else if (val >= ErrorData["max_single_amt"].value) {
            ErrorData[maintype].helperText =
              "The minimum value should be less than the maximum value.";
          } else {
            ErrorData[maintype].helperText = "";
          }
          if (val && val < ErrorData["max_single_amt"].value) {
            ErrorData["max_single_amt"].helperText = "";
          }
        } else if (maintype == "max_single_amt") {
          if (val == "") {
            ErrorData[maintype].helperText =
              "Maximum value cannot be empty or zero";
          } else if (val <= ErrorData["min_single_amt"].value) {
            ErrorData[maintype].helperText =
              "The maximum value should be greater than the minimum value.";
          } else {
            ErrorData[maintype].helperText = "";
          }
          if (val && val > ErrorData["min_single_amt"].value) {
            ErrorData["min_single_amt"].helperText = "";
          }
        } else if (maintype == "min_single_amt_receiver") {
          if (val == "") {
            ErrorData[maintype].helperText =
              "Minimum value cannot be empty or zero";
          } else if (val == 0 || val < 0) {
            ErrorData[maintype].helperText =
              "Minium value can not be less than or equal to Zero.";
          } else if (val >= ErrorData["max_single_amt_receiver"].value) {
            ErrorData[maintype].helperText =
              "The minimum value should be less than the maximum value.";
          } else {
            ErrorData[maintype].helperText = "";
          }
          if (val && val < ErrorData["max_single_amt_receiver"].value) {
            ErrorData["max_single_amt"].helperText = "";
          }
        } else if (maintype == "max_single_amt_receiver") {
          if (val == "") {
            ErrorData[maintype].helperText =
              "Maximum value cannot be empty or zero";
          } else if (val <= ErrorData["min_single_amt_receiver"].value) {
            ErrorData[maintype].helperText =
              "The maximum value should be greater than the minimum value.";
          } else {
            ErrorData[maintype].helperText = "";
          }
          if (val && val > ErrorData["min_single_amt_receiver"].value) {
            ErrorData["min_single_amt_receiver"].helperText = "";
          }
        }
        ErrorData[maintype].value = Number(val);
      }
      setErrorList({ ...ErrorData });
    }
  };

  const CheckValidation = () => {
    let check1 = errorList?.transaction_limit.filter(
      (fact) => fact?.helperText && fact?.helperText !== ""
    );
    let check2 = errorList?.max_amt.filter(
      (fact) => fact?.helperText && fact?.helperText !== ""
    );
    let check3 =
      errorList?.min_single_amt?.helperText &&
      errorList?.min_single_amt?.helperText !== ""
        ? true
        : false;
    let check4 =
      errorList?.max_single_amt?.helperText &&
      errorList?.max_single_amt?.helperText !== ""
        ? true
        : false;

    let check5 = errorList?.transaction_limit_receiver.filter(
      (fact) => fact?.helperText && fact?.helperText !== ""
    );
    let check6 = errorList?.max_amt_receiver.filter(
      (fact) => fact?.helperText && fact?.helperText !== ""
    );
    let check7 =
      errorList?.min_single_amt_receiver?.helperText &&
      errorList?.min_single_amt_receiver?.helperText !== ""
        ? true
        : false;
    let check8 =
      errorList?.max_single_amt_receiver?.helperText &&
      errorList?.max_single_amt_receiver?.helperText !== ""
        ? true
        : false;
    if (
      check1.length == 0 &&
      check2.length == 0 &&
      !check3 &&
      !check4 &&
      check5.length == 0 &&
      check6.length == 0 &&
      !check7 &&
      !check8
    ) {
      return true;
    } else {
      return false;
    }
  };

  const UpdateVelocity = async () => {
    if (CheckValidation()) {
      setLoader(true);
      setControlDisable(true);
      for (const property in errorList) {
        if (default_velocity[property]) {
          if (default_velocity[property]?.length) {
            default_velocity[property].map((x, ind) => {
              if (!errorList[property][ind].title) {
                errorList[property][ind].title =
                  default_velocity[property][ind].title;
              }
              if (!errorList[property][ind]?.type) {
                errorList[property][ind].type =
                  default_velocity[property][ind].type;
              }
            });
          } else {
            if (!errorList[property]?.title) {
              errorList[property].title = default_velocity[property].title;
            }
            if (!errorList[property].type) {
              errorList[property].type = default_velocity[property].type;
            }
          }
        }
        if (default_velocity_receiver[property]) {
          if (default_velocity_receiver[property]?.length) {
            default_velocity_receiver[property].map((x, ind) => {
              if (!errorList[property][ind].title) {
                errorList[property][ind].title =
                  default_velocity_receiver[property][ind].title;
              }
              if (!errorList[property][ind]?.type) {
                errorList[property][ind].type =
                  default_velocity_receiver[property][ind].type;
              }
            });
          } else {
            if (!errorList[property]?.title) {
              errorList[property].title =
                default_velocity_receiver[property].title;
            }
            if (!errorList[property]?.type) {
              errorList[property].type =
                default_velocity_receiver[property].type;
            }
          }
        }
      }
      if (props.fromSource == "merchant") {
        var res = await updateMerchantFees({
          _id: props.customerId,
          pay_velocity: {
            max_amt: errorList?.max_amt,
            transaction_limit: errorList?.transaction_limit,
            min_single_amt: errorList?.min_single_amt,
            max_single_amt: errorList?.max_single_amt,
          },
          receive_velocity: {
            max_amt_receiver: errorList?.max_amt_receiver,
            transaction_limit_receiver: errorList?.transaction_limit_receiver,
            min_single_amt_receiver: errorList?.min_single_amt_receiver,
            max_single_amt_receiver: errorList?.max_single_amt_receiver,
          },
        });
        if (res?.status == 200) {
          toast("Configuration Successfully Updated", {
            type: "success",
            autoClose: 2000,
          });
          GetVelocity("");
        }else{
          toast(JSON.stringify(res?.response?.data?.message || "Something went wrong"), {
            type: "error",
            autoClose: 2000,
          });
        }
      } else {
        errorList.currency_code = selectedCountry;
        AxiosBase.post(`/admin/velocity`, errorList)
          .then((response) => {
            toast("Configuration Successfully Updated", {
              type: "success",
              autoClose: 2000,
            });
            GetVelocity(selectedCountry);
          })
          .catch((err) => {
            setLoader(false);
            toast(err?.response?.data?.message, {
              type: "error",
              autoClose: 2000,
            });
          });
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmReset = () => {
    setOpen(true);
  };

  const resetConfig = async () => {
    try {
      setLoader(true);
      if (props.fromSource == "merchant") {
        var res = await updateMerchantFees({
          _id: props.customerId,
          pay_velocity: null,
          receive_velocity: null,
        });
        setOpen(false);
        toast("Configuration Successfully Reset", {
          type: "success",
          autoClose: 2000,
        });
        GetVelocity("");
      }
    } catch (err) {
      setLoader(false);
      return toast(JSON.stringify(err), {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  return loader ? (
    <div className="no_data_found loader_data_tbl">
      <CircularProgress />
    </div>
  ) : (
    <>
      <div>
        <div className="fraud_risk_Configuration">
          <p className="thm-heading-p-margin">Mobile App Messages</p>
        </div>
        {/* <div className="fraud_risk_Configuration">
            <p className="thm-heading-p-margin">Configuration</p>
          </div> */}
        {props.fromSource != "merchant" && (
          <div className="merchant_list_search_filter kyc-list">
            <Grid
              item
              xs={12}
              // sm={12}
              md={12}
              lg={12}
              // xl={4}
              className="kyc-filter"
            >
              <div
                className="merchant_list_filter_div"
                style={{ height: "100%", "margin-bottom": "15px" }}
              >
                <Select
                  name="key1"
                  labelId="country-lbl"
                  label=""
                  id="country"
                  value={selectedCountry}
                  style={{ width: "50%" }}
                  onChange={(e) => {
                    onChangeCountry(e.target.value);
                  }}
                >
                  {countryList &&
                    countryList.map((c) => {
                      return (
                        <MenuItem key={c._id} value={c.currency_code}>
                          {c.country_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
            </Grid>
            <Grid
              item
              xs={4}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className="merchant_grid_sec"
              style={{ display: "contents" }}
            ></Grid>
          </div>
        )}

        <div>
          <Box className="" sx={{ borderColor: "divider" }}>
            <Tabs
              value={selectedIndex}
              aria-label="basic tabs example"
              className=""
              style={{ "border-bottom": "1px solid #c9c9c9" }}
            >
              <Tab
                id="1"
                label="Customer"
                onClick={() => setSelectedIndex(0)}
                style={{ "text-transform": "none" }}
                {...a11yProps(0)}
              />
              <Tab
                id="2"
                label="Merchant"
                onClick={() => setSelectedIndex(1)}
                style={{ "text-transform": "none" }}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <TabPanel value={selectedIndex} index={0}>
            <PayerVelocity
              rolePermission={rolePermission}
              errorList={{
                max_amt: errorList?.max_amt,
                transaction_limit: errorList?.transaction_limit,
                min_single_amt: errorList?.min_single_amt,
                max_single_amt: errorList?.max_single_amt,
              }}
              controlDisable={controlDisable}
              loader={loader}
              ChangeValue={ChangeValue}
              toggleDrawer={toggleDrawer}
              list={list}
              state={state}
            />
          </TabPanel>
          <TabPanel value={selectedIndex} index={1}>
            <ReceiverVelocity
              rolePermission={rolePermission}
              errorList={{
                max_amt_receiver: errorList?.max_amt_receiver,
                transaction_limit_receiver:
                  errorList?.transaction_limit_receiver,
                min_single_amt_receiver: errorList?.min_single_amt_receiver,
                max_single_amt_receiver: errorList?.max_single_amt_receiver,
              }}
              controlDisable={controlDisable}
              loader={loader}
              ChangeValue={ChangeValue}
              toggleDrawer={toggleDrawer}
              list={list}
              state={state}
            />
          </TabPanel>
        </div>

        {props.fromSource != "merchant" && (
          <div className="fraud_risk_Configuration_expiry">
            <p>Expiry Minutes For Dynamic Payment link and QR code </p>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className="fraud_risk_input_grid"
            >
              <CssTextField
                className="merchant_list_search_bar_1"
                id="new-menuTransaction"
                style={{ width: "auto" }}
                value={errorList?.expiry}
                onChange={(e) => {
                  if (e.target.value) {
                    let expiryval = e.target.value.match("^[1-9][0-9]*$");
                    expiryval &&
                      setErrorList({ ...errorList, expiry: e.target.value });
                  } else {
                    setErrorList({ ...errorList, expiry: "" });
                  }
                }}
                disabled={controlDisable}
                placeholder="Expiry Hours"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </div>
        )}

        {rolePermission.includes("EDIT") && (
          <div className="merchant_edit_button_dev">
            {controlDisable ? (
              <Button
                className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                variant="contained"
                onClick={() => {
                  setControlDisable(false);
                }}
              >
                Edit
              </Button>
            ) : (
              <>
                <Button
                  className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={() => {
                    setControlDisable(true);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  disabled={!isValChange}
                  onClick={() => {
                    UpdateVelocity();
                  }}
                >
                  Save
                </Button>
              </>
            )}
            {props.fromSource == "merchant" && controlDisable && (
              <Button
                className="merchant_reset_btn merchant_detail_tab_save-cancel_btn reset_btn"
                style={{ "background-color": "#1058FF" }}
                variant="contained"
                disabled={!resetEnable}
                onClick={confirmReset}
              >
                Reset
              </Button>
            )}
          </div>
        )}
      </div>
      <div>
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              <p>Confirm Reset</p>
              <span>Are you sure want to reset configuration?</span>
              <div className="order_detail_refund_popup_btn">
                <Button
                  className="refund_save_btn"
                  variant="contained"
                  autoFocus
                  fullWidth
                  onClick={resetConfig}
                >
                  Confirm
                </Button>
                <Button
                  className="refund_cancel_btn"
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  fullWidth
                >
                  Cancel
                </Button>
              </div>
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default FraudAndRisk;
