import React, { useEffect, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
// import styles from "./dataTable.module.scss";
import { useLocation } from "react-router-dom";
// import { getAllCustomers } from "../../../../../../../../networkRequest/request/customers";
import { fetchCustomersTransactionHistory } from "../../../networkRequest/request/customers";
import { Link } from "react-router-dom";
import moment from "moment";

const columns = [
  { id: "order_id", label: "Order ID", disableSorting: true },
  { id: "to", label: "Merchant Name", disableSorting: true },
  { id: "type", label: "Payment Type", disableSorting: true },
  { id: "status", label: "Status", disableSorting: true },
  { id: "amount", label: "Total Amount", disableSorting: true },
  { id: "refundedAmount", label: "Refunded Amount", disableSorting: true },
  { id: "amount", label: "Amount Paid", disableSorting: true },
  { id: "createdAt", label: "Created On", disableSorting: true },
];

// Over here we have to do the changes for the customer history

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    // maxHeight: 440,
    // minHeight: 400
  },
});

export default function MerchantHistory() {
  //   const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortBy, setSortBy] = React.useState("createdAt");
  const [sortOrder, setSortOrder] = React.useState("desc");

  const [loading, setLoading] = React.useState(true);

  const [rows, setRows] = React.useState();
  const [totalRows, setTotalRows] = React.useState(0);
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get("customerId");

  const fetchData = useCallback(() => {
    fetchCustomersTransactionHistory(page, rowsPerPage, customerId).then(
      (resp) => {
        setRows(resp.transactions);
        setLoading(false);
        setTotalRows(resp.totalCount);
      }
    );
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = sortBy === cellId && sortOrder === "asc";
    // const sortType = isAsc ? -1 : 1;
    // Over here we will make an api call
    setSortOrder(isAsc ? "desc" : "asc");
    setSortBy(cellId);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {/* <div style={{paddingTop:'15px'}}>
        <input className={styles.input} type="text" placeholder="Search.."/>
      </div> */}

      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow style={{}}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sortDirection={sortBy === column.id ? sortOrder : false}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "white",
                    borderBottom: "1px solid #B2B2B2",
                    padding: "12px",
                  }}
                >
                  {column.disableSorting ? (
                    <p>{column.label}</p>
                  ) : (
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortBy === column.id ? sortOrder : "asc"}
                      onClick={() => handleSortRequest(column.id)}
                    >
                      <p>{column.label}</p>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              rows.map((row) => {
                return (
                  <TableRow key={row._id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return column.id === "refundedAmount" ? (
                        <TableCell key={column.id} align={column.align}>
                          {0}
                        </TableCell>
                      ) : column.id === "createdAt" ? (
                        <TableCell key={column.id} align={column.align}>
                          {moment(value).format("YYYY/MM/DD hh:mm A")}
                        </TableCell>
                      ) : (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          style={{ fontWeight: "900 !important", color: "#666666" }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Showing Entries :"
          labelDisplayedRows={({ from, to, count }) =>
            `Showing ${from} to ${to} of ${count !== -1 ? count : to} entries`
          }
        />
      )}
    </>
  );
}
