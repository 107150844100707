import AxiosBase from "../axiosBase";

export const getAllCustomers = (pageNo, rowsPerPage, sortBy, sortOrder, search) => {
  return new Promise((resolve, reject) => {
    const data = { pageNo, rowsPerPage, sortBy, sortOrder };
    AxiosBase.get(`/admin/customers/view-customers?rowsPerPage=${rowsPerPage}&pageNo=${pageNo}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}

export const viewCustomerDetails = (customerId) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/admin/customers/view-customer-details?customerId=${customerId}`)
      .then((res) => {
        resolve({ data: res.data });
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}


export const merchantsettlement = (customerId) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/admin/merchant-settlement/merchant/${customerId}`).then((respSettlement) => {
      resolve({ respSettlement: respSettlement?.data });
    })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}

export const kycbymerchantid = (id) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/admin/kycbymerchantid/${id}`)
      .then((response) => {
        resolve({ kycdata: response.data });
      })
  
    .catch((err) => {
      console.log("error", err);
      reject(err);
    })
  })
}

export const fetchCustomersTransactionHistory = (pageNo = 1, rowsPerPage = 10, customerId) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/admin/customers/view-customer-transaction-history?customerId=${customerId}&rowsPerPage=${rowsPerPage}&pageNo=${pageNo}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}

