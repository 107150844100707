import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import AxiosBase from "../../../../networkRequest/axiosBase";
import {
  AddMenuData,
  GetMenu,
  GetPermission,
} from "../../../../networkRequest/request/menu";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./AddMenu.css";
import NavbarHoc from "../../../../pages/navhoc/index";
import Config from "../../../../config.json";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});
const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 38,
  marginTop: "1%",
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function AddMenu() {
  const { id } = useParams();
  const [loadFlag, setLoadFlag] = useState(true);
  const history = useNavigate();
  const [subMenuList, setData] = useState({
    title: "",
    permissions: [],
    subMenuData: "",
    is_active: false,
  });
  const [selectAllFlag, setSelectAllFlag] = useState(true);
  const [permissionArr, setPermissionArr] = useState([]);

  useEffect(() => {
    getPermissionData();
  }, []);

  const getPermissionData = async () => {
    let permission = await GetPermission();
    permission = permission?.data?.permission;
    permission = permission
      .filter((value) => value.is_acitve)
      .map((fact) => {
        return fact.value.toUpperCase();
      });
    if (id) {
      let res = await GetMenu(id);
      setData(res.data);
    }
    setPermissionArr(permission);
    setLoadFlag(false);
  };

  const editMenu = async () => {
    if (subMenuList.title !== "" && subMenuList.permissions.length !== 0) {
      let data = {
        title: subMenuList.title,
        menu_type: "MAIN_MENU",
        main_menu_id: "",
        permissions: subMenuList.permissions,
        is_active: subMenuList.is_active,
      };
      let res = await AddMenuData(data);
      if (res?.status == 200) {
        history("/menu");
        return toast("Successfully Menu Added", {
          type: "success",
          autoClose: 2000,
        });
      } else {
        return toast(res.data.message, {
          type: "warning",
          autoClose: 2000,
        });
      }
    } else {
      return toast(
        subMenuList.title == ""
          ? "Menu Name Cannot Be Empty"
          : "Please Select Atleast One Permission",
        {
          type: "warning",
          autoClose: 2000,
        }
      );
    }
  };

  const selectAll = (type) => {
    let list = subMenuList;
    list.permissions = type ? [...permissionArr] : [];
    setData(list);
    setSelectAllFlag(!type);
  };

  const selectCheckbox = (value) => {
    let list = subMenuList;
    if (list.permissions.includes(value)) {
      list.permissions.splice(list.permissions.indexOf(value), 1);
    } else {
      list.permissions.push(value);
    }
    setData({ ...list });
  };

  const handleStatus = () => {
    let list = subMenuList;
    list.is_active = !list.is_active;
    setData({ ...list });
  };

  const handleMenu = (value, type) => {
    let list = subMenuList;
    if (type == "menu") {
      list.title = value;
    } else {
      list.subMenuData = value;
    }
    setData({ ...list });
  };

  return (
    <NavbarHoc id={Config.PermissionId.Menu} onGetReports={(e) => {}}>
      <Box>
        <h1 className="merchant_title">Menu Management</h1>
      </Box>
      {loadFlag ? (
        <div className="center">
          <CircularProgress />
        </div>
      ) : (
        <div className="merchant_list_main_div add_menu">
          <p className="add_menu_title">{!id ? "Add" : "Edit"} Menu</p>
          <div className="add_menu_inner_div">
            <div style={{ width: "20%" }}>
              <p>
                {!id ? "New" : ""} Menu<span style={{ color: "red" }}>*</span>
              </p>
              {/* <p>Sub Menu</p> */}
              <p>
                Permissions<span style={{ color: "red" }}>*</span>
              </p>
              <p>Status</p>
            </div>

            <div className="add_menu_feild_div">
              <CssTextField
                name="menu"
                id="new-menu"
                variant="outlined"
                className="select_feild"
                placeholder="Menu"
                value={subMenuList?.title}
                disabled={true}
                inputProps={{
                  maxLength: 15,
                }}
                onChange={(e) => {
                  handleMenu(e.target.value, "menu");
                }}
              />
              {/* <CssTextField
              name="submenu"
              id="new-menu"
              variant="outlined"
              placeholder="Sub Menu Name"
              value={subMenuList?.subMenuData}
              sx={{ marginBottom: "30px" }}
              onChange={(e)=>{handleMenu(e.target.value,'submenu')}}
            /> */}

              <div className="add_menu_checkbox_div">
                <FormGroup row style={{ textTransform: "uppercase" }}>
                  {permissionArr.map((fact) => {
                    return (
                      <FormControlLabel
                        control={<Checkbox />}
                        label={fact}
                        disabled={true}
                        value={fact}
                        checked={subMenuList.permissions.includes(fact)}
                        onChange={() => {
                          selectCheckbox(fact);
                        }}
                      />
                    );
                  })}
                </FormGroup>
                {/* {selectAllFlag ? (
                <Button
                  className="select_btn"
                  variant="text"
                  onClick={()=>selectAll(true)}
                >
                  Select All
                </Button>
              ) : (
                <Button
                  className="select_btn"
                  variant="text"
                  onClick={()=>selectAll(false)}
                >
                  UnSelect All
                </Button>
              )} */}
              </div>
              <FormControlLabel
                style={{ marginTop: "17px" }}
                control={
                  <IOSSwitch
                    disabled={true}
                    sx={{ m: 1 }}
                    checked={subMenuList?.is_active}
                    onChange={() => {
                      handleStatus();
                    }}
                  />
                }
                label={""}
              />
            </div>
          </div>
          <div className="add_menu_btn_div">
            <Button
              variant="contained"
              className="cancel_btn"
              onClick={() => {
                history("/menu");
              }}
            >
              Cancel
            </Button>
            {/* <Button variant="contained" className="save_btn" onClick={editMenu}>
            Save
          </Button> */}
          </div>
        </div>
      )}
    </NavbarHoc>
  );
}

export default AddMenu;
