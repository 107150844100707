import React from "react";
import "./index.scss";

export default function BusinessSection(props) {
  return (
    <div className="business-main">
      <div className="business-section">
        <div className="business-section-card business-section-card--revenue">
          <div className="business-section-card--top">
            <h5>Revenue</h5>
            <img
              src="/images/dashboard/Dashboard ICONS 2-04.svg"
              alt="Revenue"
            />
          </div>
          <h6>
          <span className="business-section-card__currency">{props.filter?.currency}</span>
            {Intl.NumberFormat("en-IN").format(
              props?.data?.dateRangeData?.commission.toFixed(2) || 0
            )}
          </h6>
        </div>
        <div className="business-section-card business-section-card--total_collection">
          <div className="business-section-card--top">
            <h5>Total Volume <span className="business-section-card__currency">(Excluded Void)</span></h5>
            <img
              src="/images/dashboard/Dashboard ICONS 2-03.svg"
              alt="Revenue"
            />
          </div>
          <h6>
          <span className="business-section-card__currency">{props.filter?.currency}</span>
            {Intl.NumberFormat("en-IN").format(
              props?.data?.dateRangeData?.amount.toFixed(2) || 0
            )}
          </h6>
        </div>
        <div className="business-section-card business-section-card--total_count">
          <div className="business-section-card--top">
            <h5>Total Count</h5>
            <img
              src="/images/dashboard/Dashboard ICONS 2-02.svg"
              alt="Revenue"
            />
          </div>
          <h6>
            {Intl.NumberFormat("en-IN").format(
              props?.data?.dateRangeData?.total_count || 0
            )}
          </h6>
        </div>
        <div className="business-section-card business-section-card--total_premium_purchase">
          <div className="business-section-card--top">
            <h5>Total Premium Purchase</h5>
            <img
              src="/images/dashboard/Dashboard ICONS 2-01.svg"
              alt="Revenue"
            />
          </div>
          <h6>{Intl.NumberFormat("en-IN").format(props?.premiumCount || 0)}</h6>
        </div>
        <div className="business-section-card business-section-card--total_kyc_approved">
          <div className="business-section-card--top">
            <h5>Total KYC Approved</h5>
            <img
              src="/images/dashboard/Dashboard ICONS 2-05.svg"
              alt="Revenue"
            />
          </div>
          <h6>{Intl.NumberFormat("en-IN").format(props?.kycCount || 0)}</h6>
        </div>
      </div>
    </div>
  );
}
