import React from "react";
import TopBank from "./TopBank";
import ApprovalRateVsPaymentMethod from "./ApprovalRateVsPaymentMethod";
import "./index.scss";

export default function ThirdSection(props) {
  return (
    <div className="third-main">
      <div className="third-section">
        <div className="third-section__left third-section-card">
          <h6>Approval Rate Vs Payment Method</h6>
          <div className="third-section-cart">
            <ApprovalRateVsPaymentMethod
              data={props?.data?.approval_rate_vs_payment_method}
              filter={props.filter}
            />
          </div>
        </div>
        <div className="third-section__right third-section-card">
          <h6>Top Bank</h6>
          <div className="third-section-cart">
            <TopBank data={props?.data?.top_bank} filter={props.filter} />
          </div>
        </div>
      </div>
    </div>
  );
}
