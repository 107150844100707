import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import PhoneInput from "react-phone-input-2";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import "./Branches.css";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import { Box, Paper } from "@mui/material";
import List from "@mui/material/List";
import InputAdornment from "@mui/material/InputAdornment";
import ListItem from "@mui/material/ListItem";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { styled } from "@mui/material";
import TerminalActiveInactive from "./TerminalActiveInactive";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import Terminal from "./Terminal";
import AddBranch from "./AddBranch";
import {
  addBranch,
  addTerminal,
  getBranch,
  getTerminal,
} from "../../../networkRequest/request/branch";
import Autocomplete from "@mui/material/Autocomplete";
import { PhoneNumberUtil } from "google-libphonenumber";
import Config from "../../../config.json";

function checklenght(val, lenght) {
  if (val?.length > lenght) {
    return val?.substring(0, lenght) + "..";
  }
  if (!val) {
    return "NA";
  }
  return val;
}

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});
const Branches = (props) => {
  const { rolePermission, allPermission, currency } = props;
  const [count, setCount] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [addterminalData, setAddTerminalData] = React.useState([]);
  const [searchBy, setSearch] = React.useState(null);
  const [selectedBranch, setselectedBranch] = React.useState();
  const [BranchList, setBranchList] = React.useState([]);
  const [allInnerList, setAllInnerList] = React.useState([]);
  const [openModal, setOpenModal] = React.useState("");
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get("merchantId");
  const [loaderFlag, setLoaderFlag] = React.useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClose = () => {
    AddCountToTerminal();
    setOpenModal(false);
  };
  const openModalFunction = (Id) => {
    setOpenModal(Id);
  };
  const CheckBranck = (variable, id) => {
    return allInnerList.filter((fact) => {
      return fact.branch_name == variable && fact._id !== id;
    });
  };

  const CheckTerminal = async (Id) => {
    if (!Id) {
      return;
    }
    try {
      let terminalArray = [];
      let flag = true;
      let TerminalData = await getTerminal("Branch", Id, "");
      TerminalData = TerminalData.data.data;
      for (let index = 0; index < addterminalData.length; index++) {
        let fact = addterminalData[index];
        let checkData = await TerminalData.filter((fact1) => {
          return fact1.terminal_name == fact.TerminalName;
        });
        if (
          terminalArray.includes(fact.TerminalName) ||
          checkData.length != 0
        ) {
          flag = false;
        } else {
          terminalArray.push(fact.TerminalName);
        }
        if (index == addterminalData.length - 1) {
          return flag;
        }
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    AddCountToTerminal();
  }, []);

  function dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  const AddCountToTerminal = async () => {
    try {
      let AllInnerList = await getBranch(true, customerId, null);
      AllInnerList = AllInnerList.data.data;
      for (let index = 0; index < AllInnerList.length; index++) {
        let fact = AllInnerList[index];
        let terminalcount = await getTerminal("Branch", fact._id, "");
        terminalcount = terminalcount.data.data;
        terminalcount = terminalcount.filter((inner) => {
          return inner.is_active;
        });
        fact.TerminalCount = terminalcount.length;
        if (index == AllInnerList.length - 1) {
          AllInnerList.sort(dynamicSort("branch_name"));
          setBranchList([...AllInnerList]);
          setAllInnerList([...AllInnerList]);
        }
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    let InnerList = props?.branchdata ? props.branchdata : [];
    setBranchList(InnerList);
  }, [props.branchdata]);

  const Validation = async () => {
    if (!(inputData.branch_name.trim() && inputData.email_id.trim())) {
      return toast(
        !inputData.branch_name.trim()
          ? "Please Fill Branch Name"
          : "Please Fill Email",
        {
          type: "warning",
          autoClose: 2000,
        }
      );
    }
    if (inputData.email_id) {
      let Setflag = inputData.email_id.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!Setflag)
        return toast("Email ID is invalid", {
          type: "warning",
          autoClose: 2000,
        });
    }
    if (inputData.mobile_no.length > 5) {
      let NumberFlag = false;
      let phoneUtil = PhoneNumberUtil.getInstance();
      NumberFlag = phoneUtil.isValidNumber(
        phoneUtil.parse(
          inputData.mobile_no.startsWith("+")
            ? inputData.mobile_no
            : "+" + inputData.mobile_no
        )
      );
      if (!NumberFlag)
        return toast("Invalid Mobile Number", {
          type: "warning",
          autoClose: 2000,
        });
    }
    let innerdata = await CheckBranck(inputData.branch_name, inputData._id);
    if (innerdata.length !== 0) {
      return toast("branch name already exists.", {
        type: "warning",
        autoClose: 2000,
      });
    }
    if (addterminalData.length !== 0) {
      let termianldata = addterminalData.filter((fact) => {
        return !(fact.TerminalName && fact.phoneNumber && fact.bankAccountId);
      });
      if (termianldata.length !== 0) {
        return toast(
          !termianldata[0].TerminalName
            ? "Please Fill Terminal Name"
            : !termianldata[0].phoneNumber
            ? "Please Select Phone Number"
            : "Please select Bank Account",
          {
            type: "warning",
            autoClose: 2000,
          }
        );
      }
      let flag = await CheckTerminal(inputData._id);
      if (!flag) {
        return toast("terminal name already exists.", {
          type: "warning",
          autoClose: 2000,
        });
      }
    }
  };

  const success = async (anchor) => {
    if (!(await Validation())) {
      setLoaderFlag(true);
      let addBranchBody = {
        _id: inputData._id,
        branch_name: inputData.branch_name.trim(),
        email_id: inputData.email_id,
        mobile_no:
          inputData.mobile_no == "+" + inputData.mobile_code
            ? ""
            : inputData.mobile_no.replace(/[^0-9]/gi, ""),
        address: inputData.address,
        zipCode: inputData.zipCode,
        mobile_code: inputData.mobile_code,
      };
      let addTerminalBody = [];
      for (let i = 0; i < addterminalData.length; i++) {
        addTerminalBody.push({
          is_active: true,
          terminal_name: addterminalData[i].TerminalName,
          mobile_no: addterminalData[i].phoneNumber.replace(/[^0-9]/gi, ""),
          merchant_id: customerId,
          branch_id: inputData._id,
          bank_account_id: addterminalData[i].bankAccountId,
          mobile_code: addterminalData[i].mobile_code,
          access_to: addterminalData[i].access_to,
        });
      }
      let branchSuccess = await addBranch(customerId, addBranchBody);
      setLoaderFlag(false);
      if (branchSuccess?.status !== 400) {
        let terminalSuccess = [];
        if (addterminalData.length !== 0) {
          terminalSuccess = await addTerminal({ terminals: addTerminalBody });
        }
        if (terminalSuccess?.status !== 400) {
          await AddCountToTerminal();
          setState({ ...state, [anchor]: false });
          return toast("Record has been successfully added.", {
            type: "success",
            autoClose: 2000,
          });
        } else {
          return toast(terminalSuccess?.data.message, {
            type: "warning",
            autoClose: 2000,
          });
        }
      } else {
        return toast(branchSuccess?.data.message, {
          type: "warning",
          autoClose: 2000,
        });
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+parseInt(event.target.value));
    setPage(0);
  };
  // drawer edit branch
  const [state, setState] = React.useState({
    right: false,
  });

  const [inputData, setInputData] = React.useState({
    branch_name: "",
    email_id: "",
    mobile_no: "",
    address: "",
    zipCode: "",
    mobile_code: "",
  });

  const toggleDrawer = (anchor, open, curElm) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setInputData(curElm);
    setselectedBranch(curElm);
    !open && AddCountToTerminal();
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
        overflowX: "hidden",
      }}
      role="presentation"
    >
      {loaderFlag ? (
        <div className="center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <List className="merchant_edit_drower_menu_top">
            <ListItem className="merchant_edit_drower_menu_top_sec">
              <p>Edit Branch</p>
            </ListItem>
          </List>
          <List>
            <div className="edit_branch_inpt_div">
              <p>
                <IconButton color="primary">
                  <img
                    style={{ height: "15px", width: "25px" }}
                    src="/user-icon.svg"
                    alt=""
                  />
                </IconButton>
                Branch Name <span style={{ color: "red" }}>*</span>
              </p>
              <CssTextField
                name="branch_name"
                className="merchant_edit_drower_serch_field"
                id="new-menu"
                placeholder="Branch Name"
                variant="outlined"
                required
                value={inputData?.branch_name}
                helperText={`${inputData?.branch_name?.length}/${100}`}
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    branch_name: e.target.value,
                  });
                }}
                inputProps={{
                  maxlength: 100,
                }}
              />
            </div>
            <div className="edit_branch_inpt_div">
              <p>
                <IconButton color="primary">
                  <img
                    style={{ height: "15px", width: "25px" }}
                    src={`${process.env.PUBLIC_URL}/top-email.svg`}
                    alt=""
                  />
                </IconButton>
                Email ID <span style={{ color: "red" }}>*</span>
              </p>
              <CssTextField
                name="email"
                className="merchant_edit_drower_serch_field"
                id="new-menu"
                placeholder="Email"
                variant="outlined"
                required
                value={inputData?.email_id}
                error={
                  !inputData?.email_id?.match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ) && inputData?.email_id !== ""
                }
                helperText={
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    inputData?.email_id
                  ) || inputData?.email_id === ""
                    ? "can accept up to 30 Alphanumeric characters "
                    : "email must be between 6 to 30 characters"
                }
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    email_id: e.target.value,
                  });
                }}
                inputProps={{
                  maxlength: 30,
                }}
              />
            </div>
            <div className="edit_branch_inpt_div">
              <p>
                <IconButton color="primary">
                  <img
                    style={{ height: "15px", width: "25px" }}
                    src="/contact.svg"
                    alt=""
                  />
                </IconButton>
                Phone Number
              </p>
              <PhoneInput
                className="mobile-input branch-mobile-input"
                name="phone"
                country={currency == "INR" ? "in" : "ae"}
                countryCodeEditable={true}
                placeholder="Enter Phone Number"
                required
                value={inputData?.mobile_no}
                onChange={(val, countryCode) => {
                  setInputData({
                    ...inputData,
                    mobile_code: countryCode.dialCode,
                    mobile_no: val,
                  });
                }}
              />
            </div>
            <div className="edit_branch_inpt_div">
              <p>
                <IconButton color="primary">
                  <img
                    style={{ height: "25px", width: "25px" }}
                    src="/location-icon-feild.svg"
                    alt=""
                  />
                </IconButton>
                Address
              </p>
              <CssTextField
                name="address"
                className="merchant_edit_drower_serch_field"
                id="new-menu"
                placeholder="Address"
                variant="outlined"
                required
                value={inputData?.address}
                inputProps={{
                  maxlength: 100,
                }}
                helperText={`${inputData?.address?.length}/${100}`}
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    address: e.target.value,
                  });
                }}
              />
            </div>

            <div className="edit_branch_inpt_div">
              <p>
                <IconButton color="primary">
                  <img
                    style={{ height: "25px", width: "25px" }}
                    src="/location-icon-feild.svg"
                    alt=""
                  />
                </IconButton>
                Zipcode
              </p>
              <CssTextField
                name="zipcode"
                className="merchant_edit_drower_serch_field"
                id="new-menu"
                placeholder="Zip Code"
                variant="outlined"
                value={inputData?.zipCode}
                required
                helperText={`${
                  inputData?.zipCode?.length ? inputData?.zipCode?.length : 0
                }/${6}`}
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    zipCode: e.target.value.replace(/[^0-9]/gi, ""),
                  });
                }}
                inputProps={{
                  maxlength: 6,
                }}
              />
            </div>
            <div className="merchant_edit_drower_serch_field edit_branch_drawer_devider"></div>
          </List>
          <div className="">
            <Terminal
              currency={currency}
              AddBranch={true}
              BranchId={inputData?._id}
              UpdateTerminalData={(terminal) => {
                setAddTerminalData(terminal);
              }}
              rolePermission={
                allPermission[Config.PermissionId.Terminal]
                  ? allPermission[Config.PermissionId.Terminal]
                  : []
              }
            />
          </div>
          <div className="merchant_edit_drawer_btn_sec edit_branch_drawer_btn">
            <Button
              className="drawer_merchant_cancel_btn"
              variant="contained"
              onClick={toggleDrawer(anchor, false, inputData)}
            >
              Cancel
            </Button>
            {rolePermission.includes("EDIT") && (
              <Button
                className="drawer_merchant_save_btn"
                variant="contained"
                onClick={() => {
                  success(anchor);
                }}
              >
                Save
              </Button>
            )}
          </div>
        </>
      )}
    </Box>
  );
  //drawer edit branch end

  return (
    <>
      <div className="branch_add_btn_div">
        {rolePermission.includes("ADD") && (
          <AddBranch
            btnName={"Add Branch"}
            className={`merchant_list_add_new_merchant branch_add_btn`}
            inputData={inputData}
            outCall={(data) => {
              AddCountToTerminal();
            }}
          />
        )}
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer className="table_container">
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="data_tbl_head_row">
              <TableRow style={{ background: "none" }}>
                <TableCell>Branch ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Created on</TableCell>
                <TableCell>Changed on</TableCell>
                <TableCell>Terminal</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="data_tbl_body_row branche_tbl_body">
              {BranchList.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((curElm) => {
                const {
                  _id,
                  branch_name,
                  createdAt,
                  updatedAt,
                  TerminalCount,
                } = curElm;
                return (
                  <TableRow style={{ background: "none" }}>
                    <TableCell className="branches_tbl_id">{_id}</TableCell>
                    <TableCell>
                      {branch_name.length > 20 ? (
                        <Tooltip title={branch_name}>
                          <Button className="merchantTool">
                            {checklenght(branch_name, 20)}
                          </Button>
                        </Tooltip>
                      ) : (
                        branch_name
                      )}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {createdAt && moment(createdAt).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {updatedAt &&
                        moment(updatedAt).format("DD/MM/YYYY HH:mm")}
                    </TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      className="branches_tbl_id"
                      onClick={() => {
                        allPermission[Config.PermissionId.Terminal] &&
                          openModalFunction(_id);
                      }}
                    >
                      {TerminalCount ? TerminalCount : 0}
                    </TableCell>
                    <TableCell>
                      {["right"].map((anchor) => (
                        <React.Fragment key={anchor}>
                          <IconButton sx={{ borderRadius: "50%" }}>
                            <img
                              src="/editt.svg"
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                              onClick={
                                (rolePermission.includes("EDIT") ||
                                  rolePermission.includes("VIEW")) &&
                                toggleDrawer(anchor, true, curElm)
                              }
                            />
                          </IconButton>
                          <SwipeableDrawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false, curElm)}
                            onOpen={toggleDrawer(anchor, true, curElm)}
                          >
                            {list(anchor)}
                          </SwipeableDrawer>
                        </React.Fragment>
                      ))}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            {/* {data == "" ? (
          <div className="no_data_found">
            <p>No Data Found</p>
          </div>
        ) : (
          ""
        )} */}
          </Table>
        </TableContainer>
        <TablePagination
          className="table_pagination"
          rowsPerPageOptions={[5, 25, 100]}
          component="div"
          count={BranchList.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Modal open={openModal ? true : false} onClose={handleClose}>
        <Box className="terminal_ModalPopUp">
          <TerminalActiveInactive
            currency={currency}
            merchantId={customerId}
            branchId={openModal}
            rolePermission={
              allPermission[Config.PermissionId.Terminal]
                ? allPermission[Config.PermissionId.Terminal]
                : []
            }
          />
        </Box>
      </Modal>
      <div></div>
    </>
  );
};

export default Branches;
