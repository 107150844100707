import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { Box, Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import BasicInformation from "./../Basic-Information";
import ModulePermission from "./../Module-Permission";
import PlanDate from "./../Plan-Date";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { EmojiObjects } from "@mui/icons-material";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: "15px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const PlanDetail = (props) => {
  const { selectedPlan, anchor, countryList, uploadFile } = props;
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [basic, setBasic] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [monthly, setMonthly] = React.useState({});
  const [annually, setAnually] = React.useState({});

  useEffect(() => {
    setSelectedIndex(0);
    setBasic({
      _id: selectedPlan?._id,
      name: selectedPlan?.name,
      country_id: selectedPlan?.country_id,
      country_name: selectedPlan?.country_name,
      color: selectedPlan?.color,
      sequence: selectedPlan?.sequence,
      is_default: selectedPlan?.is_default,
      is_highest: selectedPlan?.is_highest,
      activeStatus: selectedPlan?.activeStatus,
      is_monthly: selectedPlan?.is_monthly,
      is_annually: selectedPlan?.is_annually,
      currency_code: selectedPlan?.currency_code,
    });
  }, [selectedPlan]);

  useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  useEffect(() => {
    setMonthly(props.monthly);
  }, [props.monthly]);

  useEffect(() => {
    setAnually(props.annually);
  }, [props.annually]);

  const onChangeFieldVal = async (
    key,
    val,
    type = "String",
    from = "basic"
  ) => {
    if (type == "Int") {
      val = val.toString().replaceAll("+", "");
      let val1 = val.match("^[0-9]*$");
      if (val1) {
        val = isNaN(parseInt(val)) ? "" : parseInt(val);
      } else if (!val) {
        val = "";
      } else {
        return;
      }
    }
    if (type == "Float") {
      val = val.toString().replaceAll("+", "");
      const isDecimal = val % 1 != 0;
      if (isDecimal) {
        val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      }
      if (!val) {
        val = "";
      }
    }
    if (key == "country_id") {
      const ind = countryList.findIndex((x) => x._id == val);
      if (ind != -1) {
        basic.country_name = countryList[ind].country_name;
        basic.alphaTwoCode = countryList[ind].alphaTwoCode;
        basic.alphaThreeCode = countryList[ind].alphaThreeCode;
        basic.currency_name = countryList[ind].currency_name;
        basic.currency_code = countryList[ind].currency_code;
        basic.currency_symbol = countryList[ind].currency_symbol;
        basic.numeric_code = countryList[ind].numeric_code;
        basic.timezone = countryList[ind].timezone;
        basic.timezone_name = countryList[ind].timezone_name;
      }
    }
    if (from == "basic") {
      basic[key] = val;
      await setBasic({ ...basic });
    }
    if (from == "monthly") {
      monthly[key] = val;
      await setMonthly({ ...monthly });
    }
    if (from == "annually") {
      annually[key] = val;
      await setAnually({ ...annually });
    }
  };

  const success = () => {
    const obj = { ...basic, module_permission: rows, monthly, annually };
    props.success(obj);
  };

  return (
    <>
      <Box
        className="premium_detail_box"
        sx={{
          width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
          overflowX: "hidden",
        }}
        role="presentation"
      >
        <List>
          <ListItem className="merchant_edit_drower_menu_top_sec">
            <p>Premium Configurations</p>
          </ListItem>
        </List>
        <Divider />
        <Box className="" sx={{ borderColor: "divider" }}>
          <Tabs
            value={selectedIndex}
            aria-label="basic tabs example"
            className=""
          >
            <Tab
              id="1"
              label="Basic Information"
              onClick={() => setSelectedIndex(0)}
              {...a11yProps(0)}
            />
            <Tab
              id="2"
              label="Module Permission"
              onClick={() => setSelectedIndex(1)}
              {...a11yProps(1)}
            />
            <Tab
              id="3"
              label="Monthly"
              onClick={() => setSelectedIndex(2)}
              {...a11yProps(2)}
            />
            <Tab
              id="4"
              label="Annually"
              onClick={() => setSelectedIndex(3)}
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <TabPanel value={selectedIndex} index={0}>
          <BasicInformation
            selectedPlan={basic}
            countryList={countryList}
            uploadFile={uploadFile}
            onChangeFieldVal={onChangeFieldVal}
          />
        </TabPanel>
        <TabPanel value={selectedIndex} index={1}>
          <ModulePermission rows={rows} setRows={setRows} />
        </TabPanel>
        <TabPanel value={selectedIndex} index={2}>
          <div className="plan_array">
            Add Monthly Plan
            <Switch
              id={"monthly_" + basic?._id}
              name={"monthly_" + basic?._id}
              label={basic.is_monthly ? "yes" : "no"}
              checked={basic.is_monthly}
              onChange={(e) => {
                onChangeFieldVal("is_monthly", e.target.checked, "basic");
              }}
            />
            <br />
            {basic && basic?.is_monthly && monthly && (
              <PlanDate
                SelectedPlan={monthly}
                Name={"monthly"}
                currency_code={basic?.currency_code}
                onChangeFieldVal={onChangeFieldVal}
              />
            )}
          </div>
        </TabPanel>
        <TabPanel value={selectedIndex} index={3}>
          <div className="plan_array">
            Add Annual Plan
            <Switch
              id={"annually_" + basic?._id}
              name={"annually_" + basic?._id}
              label={basic.is_annually ? "yes" : "no"}
              checked={basic.is_annually}
              onChange={(e) => {
                onChangeFieldVal("is_annually", e.target.checked, "basic");
              }}
            />
            {basic && (basic?.is_annually || basic?.is_default) && annually && (
              <PlanDate
                SelectedPlan={annually}
                Name={"annually"}
                currency_code={basic?.currency_code}
                onChangeFieldVal={onChangeFieldVal}
              />
            )}
          </div>
        </TabPanel>
        <div>
          <div className="merchant_edit_drawer_btn_sec">
            <Button
              className="drawer_merchant_cancel_btn on_disable_btn"
              variant="contained"
              onClick={props.toggleDrawer(anchor, false)}
              disabled={props.spinner}
            >
              Cancel
            </Button>

            <Button
              className="drawer_merchant_save_btn on_disable_btn"
              variant="contained"
              onClick={() => {
                success(anchor);
              }}
              disabled={props.spinner}
            >
              {props.spinner ? (
                <i
                  className="fa fa-spinner fa-spin spinner"
                  aria-hidden="true"
                  style={{ padding: "5px" }}
                >
                  {" "}
                </i>
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
};

export default PlanDetail;
