import React from "react";
import { useState, useEffect } from "react";
import "./PlanGrid.css";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Switch from "@mui/material/Switch";
import AxiosBase from "../../../../networkRequest/axiosBase";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { unstable_styleFunctionSx } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import { Box, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { toast } from "react-toastify";
import PlanDetail from "./Plan-Detail";

//side bar table code
const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});

const PlanGrid = (props) => {
  const [rolePermission, setRolePermission] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [controlDisable, setControlDisable] = useState(true);
  const [currentSaveCancelId, setCurrentSaveCancelId] = useState("");
  const [currentEditId, setCurrentEditId] = useState("");
  const [premiumdata, setPremiumData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [state, setState] = React.useState({
    right: false,
  });
  const [rows, setRows] = React.useState([]);
  const [module, setModule] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState({});
  const [plan, setPlan] = React.useState([]);
  const [monthly, setMonthly] = React.useState({});
  const [annually, setAnnual] = React.useState({});
  const [searchPlan, setSearchPlan] = React.useState("");
  const [filterPremiumdata, setFilterPremiumData] = useState([]);
  useEffect(() => {
    setModule(props.module);
    setPremiumData(props.data);
    setFilterPremiumData(props.data);
    setRolePermission(props.rolePermission);
    AxiosBase.get(`/admin/country-master`).then((res) => {
      setCountryList(res?.data);
      setSpinner(false);
    });
  }, [props]);

  const uploadFile = (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    AxiosBase.post("/app/image/upload", formData, config).then((res) => {
      if (res?.status == 200) {
        selectedPlan.icon = res.data.file_name;
        setSelectedPlan({
          ...selectedPlan,
          icon: res.data.file_name,
        });
      }
    });
  };

  const onSubmitPlan = (p, type) => {
    selectedPlan[type] = p;
    if (type == "monthly") {
      setSelectedPlan({
        ...selectedPlan,
        monthly: p,
      });
    } else {
      setSelectedPlan({
        ...selectedPlan,
        annually: p,
      });
    }
  };

  const handleDelete = () => {
    setOpen(false);
    setSpinner(true);
    AxiosBase.delete(`/admin/premium/${selectedPlan?._id}`).then((res) => {
      props.cancelPremium({}, selectedPlan?._id);
    });
  };

  const editPremium = (e, id) => {
    setCurrentSaveCancelId(id);
    setCurrentEditId(id);
    setControlDisable(false);
  };

  const deletePremium = (e, plan) => {
    setSelectedPlan(plan);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addPremium = () => {
    if (currentEditId == "add_new") {
      return;
    }
    const obj = {
      _id: "add_new",
      name: null,
      is_monthly: false,
      is_annually: false,
      module_permission: [],
      activeStatus: true,
    };
    setCurrentSaveCancelId("add_new");
    setCurrentEditId("add_new");
    setControlDisable(false);
    setSelectedPlan(obj);
    let arr = [];
    module.map((m) => {
      const ind = selected?.module_permission?.findIndex(
        (x) => x.code == m.code
      );
      if ((!ind && ind != 0) || ind == -1) {
        arr.push(m);
      } else {
        arr.push(selected?.module_permission[ind]);
      }
    });
    setRows(arr);
    if (selected?.monthly) {
      setMonthly(selected?.monthly);
    } else {
      setMonthly({
        days: 0,
        amount: 0,
        discount: 0,
        final_amount: 0,
        fixed_fees: "",
        margin: "",
        settlement_time: "",
        secondary_label: "",
        active_at: "",
        expired_at: "",
        activeStatus: true,
      });
    }

    if (selected?.annually) {
      setAnnual(selected?.annually);
    } else {
      setAnnual({
        days: 0,
        amount: 0,
        discount: 0,
        final_amount: 0,
        fixed_fees: "",
        margin: "",
        settlement_time: "",
        secondary_label: "",
        active_at: "",
        expired_at: "",
        activeStatus: true,
      });
    }
    setState({ ...state, ["right"]: true });
  };

  const savePremium = (e, _id = "add_new", premium) => {
    try {
      if (!premium?.name || premium?.name == "") {
        return toast("name is required", {
          type: "error",
          autoClose: 2000,
        });
      }
      setSpinner(true);
      if (_id == "add_new") {
        delete premium._id;
        AxiosBase.post("/admin/premium", premium)
          .then((res) => {
            if (res?.status == 200) {
              if (res?.data?._id !== "") {
                setCurrentSaveCancelId("");
                setCurrentEditId("");
                setControlDisable(true);
                setSpinner(false);
                setState({ ...state, ["right"]: false });
                setSelectedPlan(null);
                props.cancelPremium({}, premium?._id);
                props.list();
                return toast("Plan added Successfully!", {
                  type: "success",
                  autoClose: 2000,
                });
              }
            } else {
              setCurrentSaveCancelId("");
              setCurrentEditId("");
              setControlDisable(true);
              setSpinner(false);
              props.list();
              return toast(JSON.stringify(res?.response?.data), {
                type: "error",
                autoClose: 2000,
              });
            }
          })
          .catch((err) => {
            // console.log(err);
            return toast(
              err?.response?.data?.message || "Something went wrong!",
              {
                type: "error",
                autoClose: 5000,
              }
            );
          });
      } else {
        AxiosBase.patch("/admin/premium/" + _id, premium)
          .then((res) => {
            if (res?.status == 200) {
              if (res?.data?._id !== "") {
                setCurrentSaveCancelId("");
                setCurrentEditId("");
                setControlDisable(true);
                setSpinner(false);
                setState({ ...state, ["right"]: false });
                setSelectedPlan(null);
                props.cancelPremium({}, premium?._id);
                props.list();
                return toast("Plan Updated Successfully!", {
                  type: "success",
                  autoClose: 2000,
                });
              }
            } else {
              setCurrentSaveCancelId("");
              setCurrentEditId("");
              setControlDisable(true);
              setSpinner(false);
              props.list();
              return toast(JSON.stringify(res?.response?.data), {
                type: "error",
                autoClose: 2000,
              });
            }
          })
          .catch((err) => {
            // console.log(err);
            return toast(
              err?.response?.data?.message || "Something went wrong!",
              {
                type: "error",
                autoClose: 5000,
              }
            );
          });
      }
    } catch (err) {
      console.log(err);
      setCurrentSaveCancelId("");
      setCurrentEditId("");
      setControlDisable(true);
      setSpinner(false);
      return toast(err?.response?.data?.message || "Something went wrong!", {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  const success = (data) => {
    let obj = [];
    data.module_permission
      .filter((x) => x.isSelected)
      .map((r, index) => {
        obj.push({
          type: r.code,
          limit: r.limit ? r.limit : "Unlimited",
          label: r.label ? r.label : r.name,
          sequence: index,
        });
      });
    data.modules = obj.sort((a, b) => {
      if (a.sequence > b.sequence) {
        return 1;
      } else if (a.sequence < b.sequence) {
        return -1;
      } else {
        return 0;
      }
    });

    let objPermission = [];
    data.module_permission.map((r, index) => {
      objPermission.push({
        ...r,
        sequence: index,
      });
    });
    data.module_permission = objPermission;

    if (!data?.is_monthly) {
      delete data.monthly;
    } else {
      if (data.monthly.days == "" || data.monthly.amount == "") {
        return toast("monthly days and amount is required", {
          type: "error",
          autoClose: 2000,
        });
      }
      if (data.monthly?.acquirer_services) {
        Object.keys(data.monthly?.acquirer_services).forEach(function (
          key,
          index
        ) {
          if (data.monthly?.acquirer_services[key]) {
            Object.keys(data.monthly?.acquirer_services[key]).forEach(function (
              key1,
              index1
            ) {
              if (data.monthly?.acquirer_services[key][key1]) {
                data.monthly.acquirer_services[key][key1] = Number(
                  data.monthly?.acquirer_services[key][key1]
                );
              }
            });
          }
        });
      }
    }
    if (!data?.is_annually && !data?.is_default) {
      delete data.annually;
    } else {
      if (
        !data?.is_default &&
        (data.annually.days == "" || data.annually.amount == "")
      ) {
        return toast("annually days and amount is required", {
          type: "error",
          autoClose: 2000,
        });
      }
      if (data.annually?.acquirer_services) {
        Object.keys(data.annually?.acquirer_services).forEach(function (
          key,
          index
        ) {
          if (data.annually?.acquirer_services[key]) {
            Object.keys(data.annually?.acquirer_services[key]).forEach(
              function (key1, index1) {
                if (data.annually?.acquirer_services[key][key1]) {
                  data.annually.acquirer_services[key][key1] = Number(
                    data.annually?.acquirer_services[key][key1]
                  );
                }
              }
            );
          }
        });
      }
    }
    savePremium({}, data?._id, data);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    props.cancelPremium({}, selectedPlan?._id);
  };

  const detailPremium = async (anchor, open, selected) => {
    await setSelectedPlan(JSON.parse(JSON.stringify(selected)));
    let arr = [];
    module.map((m) => {
      const ind = selected?.module_permission?.findIndex(
        (x) => x.code == m.code
      );
      if ((!ind && ind != 0) || ind == -1) {
        arr.push(m);
      } else {
        arr.push(selected?.module_permission[ind]);
      }
    });
    arr = arr.sort((a, b) => {
      if (a?.sequence > b?.sequence) {
        return 1;
      } else if (a?.sequence < b?.sequence) {
        return -1;
      } else {
        return 0;
      }
    });
    await setRows(arr);
    if (selected?.monthly) {
      await setMonthly(selected?.monthly);
    } else {
      await setMonthly({
        days: 0,
        amount: 0,
        discount: 0,
        final_amount: 0,
        fixed_fees: "",
        margin: "",
        settlement_time: "",
        active_at: "",
        expired_at: "",
        activeStatus: true,
      });
    }

    if (selected?.annually) {
      await setAnnual(selected?.annually);
    } else {
      await setAnnual({
        days: 0,
        amount: 0,
        discount: 0,
        final_amount: 0,
        fixed_fees: "",
        margin: "",
        settlement_time: "",
        active_at: "",
        expired_at: "",
        activeStatus: true,
      });
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <PlanDetail
      selectedPlan={selectedPlan}
      annually={annually}
      monthly={monthly}
      anchor={anchor}
      toggleDrawer={toggleDrawer}
      countryList={countryList}
      uploadFile={uploadFile}
      success={success}
      rows={rows}
      spinner={spinner}
    />
  );

  const onSearchEnter = () => {
    if (!searchPlan) {
      setFilterPremiumData(premiumdata);
      return;
    }
    const filterPlan = premiumdata.filter((pln) => {
      if (pln?.name?.toLowerCase()?.includes(searchPlan)) {
        return pln;
      }
    });
    setFilterPremiumData(filterPlan);
    return;
  };

  const onSearchChange = (e) => {
    if (e.key === "Enter") {
      onSearchEnter();
    }
    setSearchPlan(e.target.value?.toLowerCase() || "");
  };

  return (
    <>
      <div className="merchant_list_search_filter">
        <Grid
          item
          xs={12}
          // sm={12}
          md={12}
          lg={12}
          // xl={4}
          className=""
        >
          <div className="merchant_list_filter_div" style={{ height: "100%" }}>
            <CssTextField
              className="serachBar"
              id="new-menu"
              placeholder="Search plan name"
              variant="outlined"
              // fullWidth="false"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton className="merchant _search_icon">
                      <i
                        class="fa fa-search"
                        aria-hidden="true"
                        onClick={onSearchEnter}
                      ></i>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyUp={onSearchChange}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={4}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className="merchant_grid_sec"
          style={{ display: "contents" }}
        >
          {rolePermission.includes("ADD") && (
            <Link to={`#`}>
              <Button
                className="merchant_list_add_new_merchant"
                variant="contained"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={(e) => {
                  addPremium();
                }}
              >
                Add PLAN{" "}
              </Button>
            </Link>
          )}
        </Grid>
      </div>
      <div className="add_merchant_list"></div>
      <div className="premium_plan_input_div plan-heading">
        <Grid
          item
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          className="premium_plan_input_grid th_heading"
        >
          <p>{"Plan Name"}</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          className="premium_plan_input_grid th_heading"
        >
          <p>{"Country"}</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          className="premium_plan_input_grid th_heading"
        >
          <p>{"Color"}</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          className="premium_plan_input_grid th_heading"
        >
          <p>{"Sequence"}</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          className="premium_plan_input_grid is_default_head th_heading"
        >
          <p>{"Is Monthly"}</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          className="premium_plan_input_grid is_default_head th_heading"
        >
          <p>{"Is Annually"}</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          className="premium_plan_input_grid is_default_head th_heading"
        >
          <p>{"Is Default"}</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          className="premium_plan_input_grid is_default_head th_heading"
        >
          <p>{"Is Highest"}</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          className="premium_plan_input_grid active_status_head th_heading"
        >
          <p>{"Status"}</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          className="premium_plan_second_grid_sec th_heading"
          style={{ width: "auto" }}
        >
          <p style={{ width: "100px" }}>{"Action"}</p>
        </Grid>
      </div>
      {filterPremiumdata.map((elm, index) => {
        const { _id } = elm;
        return (
          <div key={_id} className="premium_plan_input_div premium_row">
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="premium_plan_input_grid premium_row_name"
            >
              <p>{elm.name}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="premium_plan_input_grid"
            >
              <p>{elm.country_name}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="premium_plan_input_grid premium_row_name"
            >
              <p>{elm.color}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="premium_plan_input_grid premium_row_name"
            >
              <p>{elm.sequence}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="premium_plan_input_grid is_default_cell"
            >
              <p>{elm.is_monthly ? "Yes" : "No"}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="premium_plan_input_grid is_default_cell"
            >
              <p>{elm.is_annually ? "Yes" : "No"}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="premium_plan_input_grid is_default_cell"
            >
              <p>{elm.is_default ? "Yes" : "No"}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="premium_plan_input_grid is_default_cell"
            >
              <p>{elm.is_highest ? "Yes" : "No"}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="premium_plan_input_grid active_status_cell"
            >
              <p>{elm.activeStatus ? "Yes" : "No"}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              className="premium_plan_second_grid_sec action-cell-premium"
            >
              <div className="premium_plan_edit_button_dev premium_plan_drawer_save_cancel_btn">
                {["right"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button
                      className="premium_edit_btn"
                      onClick={(e) => {
                        detailPremium(anchor, true, elm);
                      }}
                      variant="contained"
                      startIcon={<img src="/edit-icon-white.svg" alt="" />}
                      title="Module Permission"
                    >
                      <i className="fa fa-pencil"></i>
                    </Button>
                    <SwipeableDrawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      onOpen={toggleDrawer(anchor, true)}
                    >
                      {list(anchor)}
                    </SwipeableDrawer>
                  </React.Fragment>
                ))}
                <span className={currentEditId !== _id ? "visible" : "hidden"}>
                  <Button
                    id={"BtnEdit_" + _id}
                    name={"BtnEdit_" + _id}
                    className="premium_delete_btn"
                    variant="contained"
                    title="Delete Plan"
                    onClick={(e) => {
                      deletePremium(e, elm);
                    }}
                  >
                    <i class="fa fa-trash"></i>
                  </Button>
                </span>
              </div>
            </Grid>
          </div>
        );
      })}
      <div>
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              {
                <>
                  {" "}
                  <p>Confirm Delete</p>
                  <span>Are you sure want to delete this plan?</span>
                  <div className="order_detail_refund_popup_btn">
                    <Button
                      className="refund_save_btn"
                      variant="contained"
                      autoFocus
                      fullWidth
                      onClick={handleDelete}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="refund_cancel_btn"
                      variant="contained"
                      autoFocus
                      onClick={handleClose}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              }
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default PlanGrid;
