import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useLocation } from "react-router-dom";
import AxiosBase from "../../../networkRequest/axiosBase";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";

const PremiumPlan = () => {
  const [data, setData] = useState([]);
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get("merchantId");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    AxiosBase.get(`admin/subscriptions/getPlanByMerchant/${customerId}`).then(
      (response) => {
        if (response.data?.current_plan.length > 0) {
          setData(response.data?.current_plan[0]);
        }
        setLoading(false);
      }
    );
  }, []);

  return (
    <>
      {data != 0 ? (
        <div className="premium_tabs_btns">
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="merchant_grid_sec premium_tabs_grid"
          >
            <div className="premium_plam_inner_div">
              <p>Current Plan:</p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>{data.currency}</p>
                <span>{data.amount}</span>
                <p>{data?.plan_type}</p>
                <div>
                  <Button
                    className="order_drawer_btn premium_plan_btn"
                    variant="contained"
                  >
                    {data?.plan_name} plan
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="merchant_grid_sec"
          >
            <div className="premium_plam_inner_div">
              <div className="premium_plan_date_div">
                <Button className="premium_plan_btn_date_btn">
                  Start Date:
                  {data.start_at != "NA"
                    ? moment(data.start_at).format("YYYY-MM-DD")
                    : "NA"}
                </Button>
                <Button className="premium_plan_btn_date_btn">
                  End Date:{" "}
                  {data.expiry_at != "NA"
                    ? moment(data.expiry_at).format("YYYY-MM-DD")
                    : "NA"}
                </Button>
              </div>
              <div className="premium_plan_active_sec">
                <p>{data.planStatus ? "Active" : "Inactive"}</p>
                {data.planStatus ? (
                  <CheckCircleIcon className="active_icon" />
                ) : (
                  <CancelIcon className="inactive_icon" />
                )}

                {/*  */}
              </div>
            </div>
          </Grid>
        </div>
      ) : loading ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : data == "" ? (
        <div className="no_data_found loader_data_tbl">
          <p>No Data Found</p>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PremiumPlan;
