import React from "react";
import { Route, Routes } from "react-router-dom";
// import KycHome from '../../page-components/kyc/kycHome/home';
// import KycDetails from '../../page-components/kyc/kycDetails/home/components/kycDetails';
// import KycRequestDetails from '../../page-components/kyc/kycDetails/home/components/kycDetails/kycRequestDetails';
// import KycHistory from '../../page-components/kyc/kycDetails/home/components/kycDetails/history';
import UsersHome from "../../page-components/users/usersHome";
import UserDetails from "../../page-components/users/usersDetails";
import Topheader from "../../universal-components/header/topHeader";
import UserList from "../../Components/Users/user-list/UserList";
import AddNewMember from "../../Components/Users/add-new-member/AddNewMember";
import UserTabs from "../../Components/Users/UserTabs";
import UserInfo from "../../Components/Users/user-information/UserInfo";
function UsersMain() {
  return (
    <div style={{ padding: "15px" }}>
      <Topheader />
      <Routes>
        <Route exact path="information/:id" element={<UserTabs />} />
        <Route exact path="" element={<UserList />} />
        <Route exact path="add-user" element={<AddNewMember />} />
        <Route path="details" element={<UserDetails />} />
        <Route exact path="information" element={<UserTabs />} />
        {/* <Route path="information" element={<UserInfo />} /> */}
        {/* <Route path="kyc/documents" element={<KycDetails />} /> */}
      </Routes>
    </div>
  );
}

export default UsersMain;
