import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Paper, styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useState, useEffect } from "react";
import axios from "axios";
import Divider from "@mui/material/Divider";
import "./usersDataTable.module.css";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Filtericon1 from "../../../../../Icons/Filter.jpg";
import Sorticon from "../../../../../Icons/Sort-01.jpg";
import AxiosBase from "../../../../../networkRequest/axiosBase";

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
    borderRadius: "10px",
  },
});

const userlist = [
  {
    _id: 12345678910111213,
    firstName: "alamalamalam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "memberrrrrrrrrrrrrr",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khannnnnnnnnnnnn",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
  {
    _id: 12345678910111213,
    firstName: "alam",
    lastName: "khan",
    emailId: "alam@gmail.com",
    roleData: "member",
    status: "active",
  },
];

export default function UsersDataTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [count, setCount] = useState([]);
  //api data state
  const [userdata, setUserData] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //dropdown short by feathured
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //end dropdown

  //search logic
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
  };
  // end search

  useEffect(() => {
    AxiosBase.get(
      `/admin/user?pageNo=0&rowsPerPage=2&sortBy=createdAt&sortOrder=des`
    ).then((response) => {
      setUserData(response.data);
    });
  }, []);

  return (
    <>
      <Box
        sx={{ display: "flex", mb: "1rem", mt: "-3rem", alignItems: "center" }}
      >
        <div>
          <IconButton sx={{}} aria-label="search">
            <Link to="#">
              <ArrowBackIosNewIcon
                style={{
                  border: "3px solid",
                  color: "#2160F6",
                  borderColor: "#7c7c7c",
                  borderRadius: "7px",
                }}
              />
            </Link>
          </IconButton>
        </div>
        <h1
          style={{
            color: "#2160F6",
            margin: "0",
            fontweight: "bold",
            fontSize: "30px",
            marginLeft: "10px",
            marginTop: "-10px",
          }}
        >
          User
        </h1>
      </Box>
      <div
        style={{
          backgroundColor: "#ffffff",
          padding: "10px",
          marginTop: "1rem",
          borderRadius: "10px",
          boxShadow: "0 5px 5px 0 rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <CssTextField
              id="new-menu"
              placeholder="search"
              variant="outlined"
              sx={{
                width: "30%",
                borderRadius: "50px",
                ml: "0.2rem",
                mb: "1rem",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      color="primary"
                      sx={{ mr: "-1.5rem", color: "#C0C0C0", width: "2rem" }}
                    >
                      <SearchOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                handleSearch(event);
              }}
            />
            <div>
              <div style={{ marginTop: "18px", marginLeft: "0.6rem" }}>
                <span style={{ color: "#1058FF", fontWeight: 600 }}>
                  (Advance Search)
                </span>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginRight: "1.5rem" }}>
            {/* <div >
                            <Button aria-describedby={id} variant="contained" onClick={handleClick} sx={{ mt: '1rem', borderRadius: '1rem', fontSize:'10px' }} endIcon={<KeyboardArrowDownIcon/>} >
                                sort by: featured
                            </Button>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                
                            >
                                <Typography sx={{ p: 0.5, align:'center' , fontSize: '10px' }}>
                                    <div style={{ display: 'flex' , alignItems:'center', marginLeft: '15px'}}>
                                        <div className='shortlist-dropdown'>
                                            <ul>
                                                <li>Name</li>
                                                <li>UL ID</li>
                                                <li>country</li>
                                                <li>Mobile No.</li>
                                            </ul>
                                        </div>
                                        <Divider orientation="vertical" variant="middle" flexItem sx={{ ml:1 }} />
                                        <div style={{marginLeft:'20px'}}>
                                        <ul>
                                            <li>Date</li>
                                            <li>Email ID</li>
                                            <li>Business Name</li>
                                            <li>Status</li>
                                        </ul>
                                            
                                        </div>
                                    </div>
                                </Typography>
                            </Popover>
                        </div> */}

            <IconButton color="primary">
              <img
                src={Filtericon1}
                alt="filter img"
                style={{ height: "1.5rem", width: "1.5rem" }}
              />
            </IconButton>
          </div>
        </div>

        <TableContainer sx={{ maxHeight: 365, ml: "-15px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{ background: "none" }}>
                <Div
                  className="tbldiv"
                  sx={{
                    mx: "1rem",
                    mt: "0.5rem",
                    py: 1,
                    border: 1,
                    borderRadius: "0.5rem",
                    height: "1.5rem",
                    width: "98%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    bgcolor: "#ebebeb",
                    borderColor: "#C0C0C0",
                  }}
                >
                  <TableCell
                    style={{
                      background: "none",
                      border: "none",
                      textAlign: "left",
                      width: 90,
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                    }}
                  >
                    UserID
                    <img
                      className="imgico"
                      src={Sorticon}
                      alt="filter img"
                      style={{
                        height: "0.8rem",
                        width: "0.8rem",
                        marginLeft: "5px",
                      }}
                    />
                  </TableCell>

                  <TableCell
                    style={{
                      background: "none",
                      border: "none",
                      textAlign: "left",
                      width: 80,
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                    }}
                  >
                    First Name
                    <img
                      className="imgico"
                      src={Sorticon}
                      alt="filter img"
                      style={{
                        height: "0.8rem",
                        width: "0.8rem",
                        marginLeft: "5px",
                      }}
                    />
                  </TableCell>

                  <TableCell
                    style={{
                      background: "none",
                      border: "none",
                      textAlign: "left",
                      width: 80,
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    Last Name
                    <img
                      className="imgico"
                      src={Sorticon}
                      alt="filter img"
                      style={{
                        height: "0.8rem",
                        width: "0.8rem",
                        marginLeft: "5px",
                      }}
                    />
                  </TableCell>

                  <TableCell
                    style={{
                      background: "none",
                      border: "none",
                      textAlign: "left",
                      width: 120,
                      fontSize: "12px",
                    }}
                  >
                    Email
                    <img
                      className="imgico"
                      src={Sorticon}
                      alt="filter img"
                      style={{
                        height: "0.8rem",
                        width: "0.8rem",
                        marginLeft: "5px",
                      }}
                    />
                  </TableCell>

                  <TableCell
                    style={{
                      background: "none",
                      border: "none",
                      textAlign: "left",
                      width: 60,
                      fontSize: "12px",
                    }}
                  >
                    Role
                    <img
                      className="imgico"
                      src={Sorticon}
                      alt="filter img"
                      style={{
                        height: "0.8rem",
                        width: "0.8rem",
                        marginLeft: "5px",
                      }}
                    />
                  </TableCell>

                  <TableCell
                    style={{
                      background: "none",
                      border: "none",
                      textAlign: "left",
                      width: 205,
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    Status
                    <img
                      className="imgico"
                      src={Sorticon}
                      alt="filter img"
                      style={{
                        height: "0.6rem",
                        width: "0.6rem",
                        marginLeft: "5px",
                      }}
                    />
                  </TableCell>
                </Div>
              </TableRow>
            </TableHead>
            <TableBody>
              {userdata?.data &&
                userdata?.data.map((row, index) => {
                  {
                    /* const { _id, firstName, lastName, emailId, roleData, status, } = row; */
                  }
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      style={{ background: "none" }}
                    >
                      <Div
                        className=""
                        sx={{
                          mx: "1rem",
                          mt: "0.5rem",
                          py: 1,
                          border: "0.5px solid",
                          borderRadius: "0.5rem",
                          height: "1.5rem",
                          width: "98%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          fontWeight: "bold",
                          borderColor: "#C0C0C0",
                        }}
                        style={{
                          backgroundColor:
                            index % 2 == 0 ? "#99b8ff4d" : "#fff",
                          borderColor:
                            index % 2 == 0
                              ? "rgba(16, 88, 255, 0.5)"
                              : "#C0C0C0",
                        }}
                      >
                        <TableCell
                          style={{
                            bachground: "none",
                            border: "none",
                            width: 90,
                            fontSize: "11px",
                          }}
                        >
                          <Link
                            style={{ textDecoration: "none", color: "#2160F6" }}
                          >
                            {row._id}
                          </Link>
                        </TableCell>

                        <TableCell
                          style={{
                            bachground: "none",
                            border: "none",
                            width: 80,
                            fontSize: "11px",
                          }}
                        >
                          {row.firstName}
                        </TableCell>

                        <TableCell
                          style={{
                            bachground: "none",
                            border: "none",
                            width: 80,
                            fontSize: "11px",
                          }}
                        >
                          {row.lastName}
                        </TableCell>

                        <TableCell
                          style={{
                            bachground: "none",
                            border: "none",
                            width: 120,
                            fontSize: "11px",
                          }}
                        >
                          {row.emailId}
                        </TableCell>

                        <TableCell
                          style={{
                            bachground: "none",
                            border: "none",
                            width: 60,
                            fontSize: "11px",
                          }}
                        >
                          {row.roleData}
                        </TableCell>

                        <TableCell
                          style={{
                            bachground: "none",
                            border: "none",
                            width: 205,
                            fontSize: "11px",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <FormControl component="fieldset">
                              <RadioGroup
                                row
                                defaultValue={row.is_active ? true : false}
                                name="radio-buttons-group"
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio />}
                                  label="Active"
                                  labelPlacement="start"
                                />
                                <Divider
                                  orientation="vertical"
                                  variant="middle"
                                  flexItem
                                  sx={{ ml: 1 }}
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<Radio />}
                                  label="Inactive"
                                  labelPlacement="start"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </TableCell>
                      </Div>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={userdata.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="pagination-sec"
        />
      </div>
    </>
  );
}
