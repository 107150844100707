import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import "./Settlement.css";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";

const Settlement = (props) => {
  const [count, setCount] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchBy, setSearch] = React.useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  return (
    <>
      <TableContainer className="table_container">
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="data_tbl_head_row">
            <TableRow style={{ background: "none" }}>
              <TableCell>Statement ID</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Bank Name</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Completion Date</TableCell>
              <TableCell>UL Charges</TableCell>
              <TableCell>Margin</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="data_tbl_body_row branche_tbl_body">
            {props?.settlementdtl?.data?.map((curElm) => {
              const {
                _id,
                settlement_id,
                total_actual_amount,
                bank_name,
                from_date,
                to_date,
                createdAt,
                total_margin_amount,
                total_settlement_amount,
                total_fixed_fees_amount,
                status,
                full_name,
                currency,
              } = curElm;
              return (
                <TableRow
                  style={{ background: "none" }}
                  className="tbl_id merchant_tbl_edit_icon"
                  key={settlement_id}
                >
                  <TableCell className="tbl_id">
                    <Link
                      to={`/settlements/${_id}`}
                      style={{ textDecoration: "none", color: "#1058ff" }}
                    >
                      {settlement_id}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {total_settlement_amount} {currency}
                  </TableCell>
                  <TableCell>{!bank_name ? "NAN" : bank_name}</TableCell>
                  <TableCell>
                    {moment(from_date).format("YYYY/MM/DD hh:mm A")}
                  </TableCell>

                  <TableCell>
                    {moment(to_date).format("YYYY/MM/DD hh:mm A")}
                  </TableCell>
                  <TableCell>
                    {moment(from_date)
                      .add(15, "days")
                      .format("YYYY/MM/DD hh:mm A")}{" "}
                  </TableCell>
                  <TableCell>
                    {total_fixed_fees_amount} {currency}
                  </TableCell>
                  <TableCell>
                    {total_margin_amount} {currency}
                  </TableCell>

                  <TableCell>{status}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {/* {data == "" ? (
          <div className="no_data_found">
            <p>No Data Found</p>
          </div>
        ) : (
          ""
        )} */}
      </TableContainer>
      <TablePagination
        className="table_pagination"
        rowsPerPageOptions={[5, 25, 100]}
        component="div"
        count={props?.settlementdtl?.count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Settlement;
