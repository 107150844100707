import AxiosBase from "../axiosBase";

export const AddMenuData = (Data) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/admin/menus`,Data).then((respFees) => {
      resolve(respFees);
    })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}
export const GetMenu = (Id) => {
  return new Promise((resolve, reject) => {
      AxiosBase.get(
          `/admin/menus/${Id}`
        ).then((response) => {
          resolve(response);
      }).catch((err) => {
          console.log("error", err);
          resolve(err);
        });
  })
}

export const GetPermission = (Id) => {
  return new Promise((resolve, reject) => {
      AxiosBase.get(
          `/admin/permissions`
        ).then((response) => {
          resolve(response);
      }).catch((err) => {
          console.log("error", err);
          resolve(err);
        });
  })
}
