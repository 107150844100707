import React from "react";
import { useState, useEffect } from "react";
import "./RefundCharges.css";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import baseURL from "../../utils/base_url";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const MCssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
    background: "white",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const RefundCharges = (props) => {
  const { rolePermission, refundCharges, updateRefundCharges } = props;
  const [loader, setLoader] = React.useState(false);
  const [refundChargesArr, setRefundChargesArr] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [countryList, setCountryList] = useState([]);
  const [currentSaveCancelId, setCurrentSaveCancelId] = useState(-1);
  const [defaultValue, setDefaultValue] = useState(-1);
  const [selectedDelete, setSelectedDelete] = useState(-1);
  const [newAdded, setNewAdded] = React.useState(false);
  const [combinationCheck, setCombinationCheck] = React.useState([]);

  React.useEffect(() => {
    const arr = [];
    if (refundCharges) {
      Object.entries(refundCharges).map(([key, value]) => {
        arr.push({
          country: value.country,
          currency_code: value.currency_code,
          amount: value.amount,
          percent: value.percent,
        });
      });
    }
    setRefundChargesArr(arr);
    setDefaultValue(JSON.parse(JSON.stringify(arr)));
    setCombinationCheck(Object.keys(refundCharges || {}));
    axios.get(`${baseURL()}/api/country-master`).then((res) => {
      setCountryList(res?.data);
    });
  }, [refundCharges]);

  const cancelChanges = () => {
    setRefundChargesArr(JSON.parse(JSON.stringify(defaultValue)));
    setCurrentSaveCancelId(-1);
    setNewAdded(false);
  };

  const save = () => {
    let refund_charges = {};
    let empty = null;
    refundChargesArr.map((row) => {
      if (!row.country) {
        empty = "Select Country";
      }
      if (!row.currency_code) {
        empty = "Select Currency";
      }
      refund_charges[row.country + "_" + row.currency_code] = {
        country: row.country,
        currency_code: row.currency_code,
        amount: row.amount,
        percent: row.percent,
      };
    });
    if (empty) {
      toast(empty, {
        type: "error",
        autoClose: 2000,
      });
      return false;
    }
    setCurrentSaveCancelId(-1);
    setCombinationCheck(Object.keys(refund_charges || {}));
    updateRefundCharges(refund_charges);
    setNewAdded(false);
  };
  const add = () => {
    if (newAdded) {
      return;
    }
    refundChargesArr.push({
      country: "ARE",
      currency_code: "",
      amount: 0,
      percent: 0,
    });
    setRefundChargesArr(refundChargesArr);
    setCurrentSaveCancelId(refundChargesArr.length - 1);
    setNewAdded(true);
  };

  const deleteCharges = (ind) => {
    setSelectedDelete(ind);
    setOpen(true);
  };

  const handleDelete = () => {
    setOpen(false);
    refundChargesArr.splice(selectedDelete, 1);
    setSelectedDelete(-1);
    let refund_charges = {};
    refundChargesArr.map((row) => {
      refund_charges[row.country + "_" + row.currency_code] = {
        country: row.country,
        currency_code: row.currency_code,
        amount: row.amount,
        percent: row.percent,
      };
    });
    setCurrentSaveCancelId(-1);
    setCombinationCheck(Object.keys(refund_charges || {}));
    updateRefundCharges(refund_charges);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentSaveCancelId(-1);
    setSelectedDelete(-1);
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const loadToolTipAmount = () => {
    return (
      <React.Fragment>
        <p>Please enter the refund charges.</p>
      </React.Fragment>
    );
  };

  const loadToolTipPercent = () => {
    return (
      <React.Fragment>
        <p>Please enter the refund charges in percentage</p>
      </React.Fragment>
    );
  };

  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="">
          <Grid
            className="refund-charges-section"
            container
            spacing={2}
            justifyContent="flex-end"
          >
            {rolePermission.includes("ADD") && (
              <Button
                onClick={() => {
                  add();
                }}
                className="merchant_list_add_new_merchant"
                variant="contained"
                style={{ marginTop: 0, marginRight: 10 }}
              >
                Add New
              </Button>
            )}
          </Grid>
          <TableContainer
            component={Paper}
            style={{ border: "1px solid #e0e0e0" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Country
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Currency Code
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Charges(amount)
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Charges(%)
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {refundChargesArr.map((row, index) => (
                  <TableRow
                    key={`${row.country}_${row.currency_code}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ width: "26%" }} component="th">
                      {currentSaveCancelId == index ? (
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label"></InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={row?.country}
                            label="Select Country"
                            onChange={(e) => {
                              row.country = e.target.value;
                              setRefundChargesArr(
                                Object.values({
                                  ...refundChargesArr,
                                  [index]: {
                                    ...refundChargesArr[index],
                                    country: e.target.value,
                                  },
                                })
                              );
                            }}
                          >
                            {countryList.map((innerRow) => {
                              return (
                                <MenuItem value={innerRow.alphaThreeCode}>
                                  {innerRow.country_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      ) : (
                        <label>{row?.country}</label>
                      )}
                    </TableCell>
                    <TableCell style={{ width: "26%" }} component="th">
                      {currentSaveCancelId == index ? (
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label"></InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={row?.currency_code}
                            label="Select Currency"
                            onChange={(e) => {
                              row.currency_code = e.target.value;
                              setRefundChargesArr(
                                Object.values({
                                  ...refundChargesArr,
                                  [index]: {
                                    ...refundChargesArr[index],
                                    currency_code: e.target.value,
                                  },
                                })
                              );
                            }}
                          >
                            {countryList.map((innerRow) => {
                              if (
                                combinationCheck.includes(
                                  refundChargesArr[index].country +
                                    "_" +
                                    innerRow.currency_code
                                )
                              ) {
                                return (
                                  <MenuItem
                                    value={innerRow.currency_code}
                                    disabled
                                  >
                                    {innerRow.currency_code}
                                  </MenuItem>
                                );
                              } else {
                                return (
                                  <MenuItem value={innerRow.currency_code}>
                                    {innerRow.currency_code}
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                        </FormControl>
                      ) : (
                        <label>{row?.currency_code}</label>
                      )}
                    </TableCell>
                    <TableCell>
                      {currentSaveCancelId == index ? (
                        <Tooltip title={loadToolTipAmount()} placement="right">
                          <CssTextField
                            className=""
                            id="new-menu"
                            placeholder="amount"
                            variant="outlined"
                            fullWidth
                            value={row.amount}
                            onWheel={numberInputOnWheelPreventChange}
                            onKeyDown={(evt) =>
                              evt.key === "e" && evt.preventDefault()
                            }
                            onChange={(e) => {
                              let val = e.target.value;
                              const isDecimal = val % 1 != 0;
                              if (isDecimal) {
                                val = val
                                  .toString()
                                  .match(/^-?\d+(?:\.\d{0,2})?/)[0];
                              }
                              row.amount = val;
                              setRefundChargesArr(
                                Object.values({
                                  ...refundChargesArr,
                                  [index]: {
                                    ...refundChargesArr[index],
                                    amount: val,
                                  },
                                })
                              );
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <label>{row?.amount}</label>
                      )}
                    </TableCell>
                    <TableCell>
                      {currentSaveCancelId == index ? (
                        <Tooltip title={loadToolTipPercent()} placement="right">
                          <CssTextField
                            className=""
                            id="new-menu"
                            placeholder="percent"
                            variant="outlined"
                            fullWidth
                            value={row.percent}
                            onWheel={numberInputOnWheelPreventChange}
                            onKeyDown={(evt) =>
                              evt.key === "e" && evt.preventDefault()
                            }
                            onChange={(e) => {
                              let val = e.target.value;
                              const isDecimal = val % 1 != 0;
                              if (isDecimal) {
                                val = val
                                  .toString()
                                  .match(/^-?\d+(?:\.\d{0,2})?/)[0];
                              }
                              if (val >= 0 && val <= 100) {
                                row.percent = val;
                                setRefundChargesArr(
                                  Object.values({
                                    ...refundChargesArr,
                                    [index]: {
                                      ...refundChargesArr[index],
                                      percent: val,
                                    },
                                  })
                                );
                              }
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <label>{row?.percent}</label>
                      )}
                    </TableCell>
                    <TableCell style={{ width: "110px" }}>
                      <div className="cronjob_cronjob_edit_button_dev cronjob_cronjob_drawer_save_cancel_btn">
                        <span
                          className={
                            currentSaveCancelId == index ? "visible" : "hidden"
                          }
                        >
                          <Button
                            id={"BtnCancel_" + index}
                            name={"BtnCancel_" + index}
                            className="premium_cancel_btn"
                            variant="contained"
                            title="Cancel"
                            onClick={(e) => {
                              cancelChanges(index);
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                          <Button
                            id={"BtnSave_" + index}
                            name={"BtnSave_" + index}
                            className="premium_save_btn"
                            variant="contained"
                            title="Save Plan"
                            onClick={(e) => {
                              save();
                            }}
                          >
                            <i className="fa fa-paper-plane"></i>
                          </Button>
                        </span>
                        <span
                          className={
                            currentSaveCancelId != index ? "visible" : "hidden"
                          }
                        >
                          {rolePermission.includes("EDIT") && (
                            <Button
                              id={"BtnEdit_" + index}
                              name={"BtnEdit_" + index}
                              className="premium_edit_btn"
                              variant="contained"
                              title="Edit Plan"
                              onClick={(e) => {
                                setRefundChargesArr(
                                  JSON.parse(JSON.stringify(defaultValue))
                                );
                                setNewAdded(false);
                                setCurrentSaveCancelId(index);
                              }}
                            >
                              <i class="fa fa-pencil"></i>
                            </Button>
                          )}
                          <Button
                            id={"BtnEdit_" + index}
                            name={"BtnEdit_" + index}
                            className="premium_delete_btn"
                            variant="contained"
                            title="Delete Plan"
                            onClick={(e) => {
                              deleteCharges(index);
                            }}
                          >
                            <i class="fa fa-trash"></i>
                          </Button>
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <BootstrapDialog
              className="order_detail_refund_popup"
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogContent>
                <Typography
                  className="order_detail_refund_popup_title"
                  gutterBottom
                >
                  <p>Confirm Delete</p>
                  <span>Are you sure want to delete this record?</span>
                  <div className="order_detail_refund_popup_btn">
                    <Button
                      className="refund_save_btn"
                      variant="contained"
                      autoFocus
                      fullWidth
                      onClick={handleDelete}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="refund_cancel_btn"
                      variant="contained"
                      autoFocus
                      onClick={handleClose}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </div>
                </Typography>
              </DialogContent>
            </BootstrapDialog>
          </div>
        </div>
      )}
    </>
  );
};

export default RefundCharges;
