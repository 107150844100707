import React, { useState, useRef } from "react";
import "./index.scss";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import AxiosBase from "../../../networkRequest/axiosBase";

export default function Custodian(props) {
  let dummyText =
    "In life, most short cuts end up taking longer than taking the longer route.";
  const Ref = useRef(null);
  const [kek, setKek] = useState(dummyText);
  const [viewActive, setViewActive] = useState(false);
  const [otpSend, setOtpSend] = useState(false);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState("00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    return {
      total,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(seconds > 9 ? seconds : "0" + seconds);
    }
  };

  const clearTimer = (e) => {
    setTimer("30");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
      if (new Date() > e) {
        resetKek();
      }
    }, 1000);
    Ref.current = id;
  };

  const resetKek = () => {
    if (Ref.current) clearInterval(Ref.current);
    setKek(dummyText);
    setTimer("00");
    setOtp("");
    setOtpSend(false);
    setViewActive(false);
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  const changeOtp = (event) => {
    const value = event.target.value;
    const regex = /^[\d]*$/g;
    if (regex.test(value)) {
      setOtp(value);
    }
  };

  const sendOtp = async () => {
    try {
      const resp = await AxiosBase.post("/admin/kek/otp/send");
      toast(resp?.data?.message, {
        type: "success",
        autoClose: 6000,
      });
      setOtpSend(true);
    } catch (error) {
      toast(error?.response?.data?.message || "Something went wrong!", {
        type: "error",
        autoClose: 4000,
      });
    }
  };

  const reSendOtp = async () => {
    try {
      const resp = await AxiosBase.post("/admin/kek/otp/resend");
      toast(resp?.data?.message, {
        type: "success",
        autoClose: 6000,
      });
    } catch (error) {
      toast(error?.response?.data?.message || "Something went wrong!", {
        type: "error",
        autoClose: 4000,
      });
    }
  };

  const verifyOtp = async () => {
    try {
      if (!otp) {
        return toast("OTP required", {
          type: "error",
          autoClose: 4000,
        });
      }
      const resp = await AxiosBase.post("/admin/kek/otp/verify", { otp });
      toast(resp?.data?.message, {
        type: "success",
        autoClose: 6000,
      });
      setOtpSend(false);
      setViewActive(true);
      setKek(resp?.data?.key);
      setOtp("");
      clearTimer(getDeadTime());
    } catch (error) {
      if (error?.response?.data?.lockout) {
        resetKek();
      }
      toast(error?.response?.data?.message || "Something went wrong!", {
        type: "error",
        autoClose: 4000,
      });
    }
  };

  const copyKek = () => {
    navigator.clipboard.writeText(kek);
    toast("Key encryption key copied", {
      type: "success",
      autoClose: 6000,
    });
  };

  return (
    <div className="config-custodian">
      <p className="custodian-label">Encryption Key</p>
      <div className="config-custodian__control_grp">
        <TextField
          id="kek"
          type={viewActive ? "text" : "password"}
          name="kek"
          variant="outlined"
          value={kek}
          size="small"
          fullWidth
        />
        {!otpSend && !viewActive && (
          <Button
            size="small"
            className="custodian__control_grp-btn"
            variant="contained"
            onClick={sendOtp}
          >
            View
          </Button>
        )}
        {!otpSend && viewActive && (
          <>
            <Button
              size="small"
              className="custodian__control_grp-btn"
              variant="contained"
              onClick={copyKek}
            >
              Copy
            </Button>
            <p className="custodian-copy-timer">{timer}</p>
          </>
        )}
      </div>
      {otpSend && (
        <div className="config-custodian__control_grp-otp">
          <p className="custodian-label">Enter OTP*</p>
          <div className="config-custodian__control_grp">
            <TextField
              id="otp"
              type="password"
              name="otp"
              variant="outlined"
              value={otp}
              size="small"
              fullWidth
              placeholder="Eg: 8***67"
              onChange={changeOtp}
            />
            <Button
              size="small"
              className="custodian__control_grp-btn"
              variant="contained"
              onClick={verifyOtp}
            >
              verify
            </Button>
            <Button
              size="small"
              className="custodian__control_grp-btn custodian__control_grp-btn-resend"
              variant="contained"
              onClick={reSendOtp}
            >
              Resend OTP
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
