import AxiosBase from "../axiosBase";

export const RefundLists = (pageNo, rowsPerPage, searchBy, filter) => {
  try {
    return new Promise((resolve, reject) => {
      AxiosBase.get(
        `/admin/order/refund?pageNo=${pageNo}&rowsPerPage=${rowsPerPage}&sortBy=createdAt&search=${
          searchBy ? searchBy : ""
        }&filter=${filter}`
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("error", err);
          reject(err);
        });
    });
  } catch (error) {
    console.log(error);
    return;
  }
};

export const refundInit = (orderId,reqbody) => {
  try {
    return new Promise((resolve, reject) => {
      AxiosBase.post(
        `/admin/payments/${orderId}/refunds`,reqbody
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("error1", err);
          resolve(err.response.data);
        });
    });
  } catch (error) {
    console.log("error",error);
    return error;
  }
};

export const refundSubscription = (id) => {
  try {
    return new Promise((resolve, reject) => {
      AxiosBase.get(`/admin/subscriptions/refund/`+id)
        .then((res) => {
          res.data.status = true;
          resolve(res.data);
        })
        .catch((err) => {
          console.log("error", err);
          resolve(err.response.data);
        });
    });
  } catch (error) {
    console.log("error",error);
    return error;
  }
};

export const voidInit = (orderId,reqbody) => {
  try {
    return new Promise((resolve, reject) => {
      AxiosBase.post(
        `/admin/payments/${orderId}/voids`,reqbody
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("error1", err);
          resolve(err.response.data);
        });
    });
  } catch (error) {
    console.log("error",error);
    return error;
  }
};