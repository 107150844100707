import React, { useEffect, useState } from "react";
import ConfigurationTab from './ConfigurationTab';
import { Link, NavLink, useLocation } from "react-router-dom";

const ConfigTabMain = () => {
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState([]);

  const search = useLocation().search;

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const id = new URLSearchParams(search).get("id");
    if(id){
    setValue(parseInt(id));
    }
  })
  return (
    <>
      <ConfigurationTab
        value={value}
        setValue={setValue}
        handleChange={handleTabChange}
      />
    </>
  );
};

export default ConfigTabMain;
