import React, { useEffect } from "react";
import TradeLicense from "./TradeLicense";
import EmiratesId from "./emiratesId/EmiratesId";
import { toast } from "react-toastify";
import {
  saveKycProfileImage,
  viewKycDetails,
} from "../../../../../../../networkRequest/request/kyc";
import { useLocation } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import TradView from "../viewDocument/tradeLicense/index";
import EmirView from "../viewDocument/emiratesId/index";
import { KycStatus } from "../../../../../kycHome/home/components/kycRequests/data-table/index";
import "./KycDetail.css";
import KycDefaultProfile from "../../../../../../../Icons/KycDefaultPic.jpg";
import AxiosBase from "../../../../../../../networkRequest/axiosBase";
import CircularProgress from "@mui/material/CircularProgress";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "315px",
    height: "4px",
    color: "#666666",
    fontWeight: "bold",
  },
});

const KycDetail = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get("customerId");
  const kycId = new URLSearchParams(search).get("kycId");
  const [image, setImage] = React.useState("/yash1.jpg");
  const [userDetail, setUserDetail] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile_no: "",
    gender: "",
    dateOfBirth: "",
    address: "",
  });
  const submitUserData = (e) => {
    e.preventDefault();
  };
  const clicked = (e) => {
    const file = e.target.files;
    setImage(file);
  };
  const selectProfilePic = () => {
    document.getElementById("chooseFile").click();
  };
  const fileChange = (e) => {
    const image = e.target.files[0];
    if (e.target.files && image) {
      if (image.type === "image/jpeg" || image.type === "image/x-png") {
        const reader = new FileReader();
        const test1 = (reader.onload = (e) => setImage(e.target.result));
        const test = reader.readAsDataURL(e.target.files[0]);
        saveKycProfileImage(test);
      } else {
        toast("Invalid file type", { type: "error", autoClose: 5000 });
      }
    }
  };
  const stringVal = React.useState();
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severiety: "success",
  });
  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: "", severiety: "success" });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const changeTab = () => {
    setValue(0);
  };
  const changeTab1 = () => {
    setValue(2);
  };
  useEffect(() => {
    try {
      AxiosBase.get(`/admin/kyc/view-kyc-details/${kycId}`).then((response) => {
        setLoader(false);
        setData(response.data);
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "something went wrong, pls try again!",
        severiety: "error",
      });
    }
  }, []);
  const handleTradeLicenseReject = async () => {
    if (
      !data?.tradeLicenseData?.comments ||
      !data?.tradeLicenseData?.licenseNumber ||
      !data?.tradeLicenseData?.tradeName ||
      !data?.tradeLicenseData?.issueDate ||
      !data?.tradeLicenseData?.endDate
    ) {
      setSnackbar({
        open: true,
        message: "All field are required",
        severiety: "error",
      });
      return false;
    }

    try {
      const res = await AxiosBase.post(
        `/admin/kyc/change-kyc-trade-license-status/${kycId}`,
        { isverified: false, data: data.tradeLicenseData }
      );
      setData(res.data.data);
      setSnackbar({
        open: true,
        message: "Trade license is rejected",
        severiety: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleTradeLicenseApproved = async () => {
    try {
      if (
        !data?.tradeLicenseData?.comments ||
        !data?.tradeLicenseData?.licenseNumber ||
        !data?.tradeLicenseData?.tradeName ||
        !data?.tradeLicenseData?.issueDate ||
        !data?.tradeLicenseData?.endDate
      ) {
        setSnackbar({
          open: true,
          message: "All field are required",
          severiety: "error",
        });
        return false;
      }

      const res = await AxiosBase.post(
        `/admin/kyc/change-kyc-trade-license-status/${kycId}`,
        { isverified: true, data: data.tradeLicenseData }
      );
      setData(res.data.data);
      setSnackbar({
        open: true,
        message: "Trade license is approved",
        severiety: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const removeEmptySpaces = stringVal => {
  //   return /\s/g.test(stringVal);
  // };

  const handleTradeLicenseChange = (e) => {
    try {
      let val = e.target.value;
      setData({
        ...data,
        tradeLicenseData: {
          ...data.tradeLicenseData,
          [e.target.name]: val.trimLeft(),
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTradeLicenseDateChange = (date, name) => {
    try {
      if (name == "issueDate") {
        setData({
          ...data,
          tradeLicenseData: {
            ...data.tradeLicenseData,
            [name]: date,
            endDate: "",
          },
        });
        return true;
      }
      setData({
        ...data,
        tradeLicenseData: {
          ...data.tradeLicenseData,
          [name]: date,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTradeLicenseSaveApproved = async () => {
    try {
      if (
        !data?.tradeLicenseData?.comments ||
        !data?.tradeLicenseData?.licenseNumber ||
        !data?.tradeLicenseData?.tradeName ||
        !data?.tradeLicenseData?.issueDate ||
        !data?.tradeLicenseData?.endDate
      ) {
        setSnackbar({
          open: true,
          message: "All field are required",
          severiety: "error",
        });
        return false;
      }
      const res = await AxiosBase.post(
        `/admin/kyc/update-trade-license/${kycId}`,
        { ...data.tradeLicenseData }
      );
      setData(res.data.data);
      setSnackbar({
        open: true,
        message: "Trade license is approved",
        severiety: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  // emirates  handle changes starte here
  const handleEmiratesReject = async () => {
    try {
      if (
        !data?.emiratesData?.comments ||
        !data?.emiratesData?.idNumber ||
        !data?.emiratesData?.name ||
        !data?.emiratesData?.expiryDate
      ) {
        setSnackbar({
          open: true,
          message: "All field are required",
          severiety: "error",
        });
        return false;
      }
      const res = await AxiosBase.post(
        `/admin/kyc/change-kyc-emirates-id-status/${kycId}`,
        { isverified: false, data: data.emiratesData }
      );
      setData(res.data.data);
      setSnackbar({
        open: true,
        message: "Emirates id is rejected",
        severiety: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmiratesApproved = async () => {
    try {
      if (
        !data?.emiratesData?.comments ||
        !data?.emiratesData?.idNumber ||
        !data?.emiratesData?.name ||
        !data?.emiratesData?.expiryDate
      ) {
        setSnackbar({
          open: true,
          message: "All field are required",
          severiety: "error",
        });
        return false;
      }
      const res = await AxiosBase.post(
        `/admin/kyc/change-kyc-emirates-id-status/${kycId}`,
        { isverified: true, data: data.emiratesData }
      );
      setData(res.data.data);
      setSnackbar({
        open: true,
        message: "Emirates id is approved",
        severiety: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmiratesChange = (e) => {
    try {
      let val = e.target.value;
      setData({
        ...data,
        emiratesData: {
          ...data.emiratesData,
          [e.target.name]: val.trimLeft(),
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmiratesDateChange = (date, name) => {
    try {
      setData({
        ...data,
        emiratesData: {
          ...data.emiratesData,
          [name]: date,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmiratesSaveApproved = async () => {
    try {
      if (
        !data?.emiratesData?.comments ||
        !data?.emiratesData?.idNumber ||
        !data?.emiratesData?.name ||
        !data?.emiratesData?.expiryDate
      ) {
        setSnackbar({
          open: true,
          message: "All field are required",
          severiety: "error",
        });
        return false;
      }
      const res = await AxiosBase.post(
        `/admin/kyc/update-emirates-id/${kycId}`,
        { ...data.emiratesData }
      );
      setData(res.data.data);
      setSnackbar({
        open: true,
        message: "Emirates id is approved",
        severiety: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (loader) {
    return (
      <div className="center">
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <div className="kyc-detail-main-div">
        <div style={{ display: "flex" }}>
          <div className="kyc-detail-top-profileArea">
            <div className="kyc-detail-profile">
              <img src={KycDefaultProfile} alt="Profile Pic" accept="" />
            </div>
            <p
              style={{
                fontSize: "28px",
              }}
            >
              {data?.customersData?.first_name} {data?.customersData?.last_name}
            </p>
            <p
              style={{
                fontSize: "14px",
                color: "#2160f6",
              }}
            >
              {data?._id}
            </p>

            <KycStatus
              status={data?.kycStatus}
              style={{ margin: "0", fontSize: "18px" }}
            />
            {/* {data?.kycStatus} */}
          </div>

          <input
            id="chooseFile"
            type="file"
            onChange={(e) => fileChange(e)}
            style={{ display: "none" }}
          />
          <div className="kyc-detail-inputFeild">
            <div style={{ display: "flex", marginLeft: "1rem" }}>
              <div className="kyc-detail-data-sec-div">
                <p>First Name:</p>

                <CssTextField
                  id="new-menu"
                  variant="outlined"
                  value={data?.customersData?.first_name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonOutlineIcon className="kyc-icon-label" />
                      </InputAdornment>
                    ),
                  }}
                  disabled
                />
              </div>
              <div className="kyc-detail-data-sec-div">
                <p>Last Name:</p>
                <CssTextField
                  id="new-menu"
                  variant="outlined"
                  value={data?.customersData?.last_name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <PersonOutlineIcon className="kyc-icon-label" />
                      </InputAdornment>
                    ),
                  }}
                  disabled
                />
              </div>
            </div>

            <div style={{ display: "flex", marginLeft: "1rem" }}>
              <div className="kyc-detail-data-sec-div">
                <p>Email:</p>
                <CssTextField
                  id="new-menu"
                  variant="outlined"
                  value={data?.customersData?.email_id}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <EmailOutlinedIcon className="kyc-icon-label" />
                      </InputAdornment>
                    ),
                  }}
                  disabled
                />
              </div>
              <div className="kyc-detail-data-sec-div">
                <p>Phone Number:</p>
                <CssTextField
                  id="new-menu"
                  variant="outlined"
                  value={data?.customersData?.mobile_no}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <LocalPhoneOutlinedIcon className="kyc-icon-label" />
                      </InputAdornment>
                    ),
                  }}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            // display: "flex",
            padding: "10px 20px",
            marginTop: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TradeLicense
              data={data}
              handleTradeLicenseReject={handleTradeLicenseReject}
              handleTradeLicenseApproved={handleTradeLicenseApproved}
              handleTradeLicenseSaveApproved={handleTradeLicenseSaveApproved}
              handleTradeLicenseChange={handleTradeLicenseChange}
              handleViewDocument={props.handleViewDocument}
              handleTradeLicenseDateChange={handleTradeLicenseDateChange}
              setSnackbar={setSnackbar}
            />
            <TradView data={data} setData={setData} setSnackbar={setSnackbar} />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <EmiratesId
              data={data}
              handleEmiratesReject={handleEmiratesReject}
              handleEmiratesApproved={handleEmiratesApproved}
              handleEmiratesChange={handleEmiratesChange}
              handleEmiratesSaveApproved={handleEmiratesSaveApproved}
              handleViewDocument={props.handleViewDocument}
              handleEmiratesDateChange={handleEmiratesDateChange}
              setSnackbar={setSnackbar}
            />
            <EmirView data={data} setData={setData} setSnackbar={setSnackbar} />
          </div>
        </div>
        {snackbar.message && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbar.severiety}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        )}
        {/* <Alert severity="error">This is an error message!</Alert>
<Alert severity="warning">This is a warning message!</Alert>
<Alert severity="info">This is an information message!</Alert>
<Alert severity="success">This is a success message!</Alert> */}
      </div>
    </>
  );
};

export default KycDetail;
