import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import DateRangeComp from "../datepicker";
import "./Filter.scss";

export default function Filter(props) {
  return (
    <div className="dashboard-filter">
      <h5 className="dashboard-filter-heading">Key indicators</h5>
      <div className="dashboard-filter__filter-clear">
        {props.filter.currency != "AED" && (
          <Chip
            color="primary"
            className="dashboard-filter__filter-clear--chip"
            label={props.filter.currency}
            onDelete={() => props.handleClear("currency")}
          />
        )}
        {props.filter.country != "ARE" && props.filter.country != "" && (
          <Chip
            color="primary"
            className="dashboard-filter__filter-clear--chip"
            label={props.filter.country}
            onDelete={() => props.handleClear("country")}
          />
        )}
        {props.filter.dateRange != "Today" && (
          <Chip
            color="primary"
            className="dashboard-filter__filter-clear--chip"
            label={props.filter.dateRange}
            onDelete={() => props.handleClear("dateRange")}
          />
        )}
      </div>
      <div>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">Currency</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={props.filter.currency}
            label="currency"
            name="currency"
            onChange={props.handleFilter}
          >
            <MenuItem value={"AED"}>AED</MenuItem>
            <MenuItem value={"INR"}>INR</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">Country</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={props.filter.country}
            label="country"
            name="country"
            onChange={props.handleFilter}
          >
            <MenuItem value={""}>None</MenuItem>
            <MenuItem value={"ARE"}>UAE</MenuItem>
            <MenuItem value={"IND"}>India</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">Date Range</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={props.filter.dateRange}
            label="Date Range"
            name="dateRange"
            onChange={props.handleFilter}
          >
            <MenuItem value={"Today"}>Today</MenuItem>
            <MenuItem value={"Yesterday"}>Yesterday</MenuItem>
            <MenuItem value={"This Week"}>This Week</MenuItem>
            <MenuItem value={"This Month"}>This Month</MenuItem>
            <MenuItem value={"This Year"}>This Year</MenuItem>
            <MenuItem value={"Custom"}>Custom Range</MenuItem>
          </Select>
        </FormControl>
        {props.filter.dateRange == "Custom" && (
          <DateRangeComp
            handledates={props.handledates}
            position="bottom right"
            style={{ display: "inline-block", marginTop: 10, marginRight: 4 }}
            open={true}
          />
        )}
      </div>
    </div>
  );
}
