import React, { useState } from "react";
import "./CreateMerchant.css";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneInput from "react-phone-input-2";
import KYCDetails from "./KYCDetails";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AxiosBase from "../../../networkRequest/axiosBase";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { toast } from "react-toastify";
import { addMerchant } from "../../../networkRequest/request/merchant";
import { useNavigate } from "react-router-dom";
import NavbarHoc from "../../../pages/navhoc/index";
import Config from "../../../config.json";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});

// const cuntryLength=[{india}]

const CreateMerchant = () => {
  const [validation, setValidation] = useState({
    first_name: "",
    mobile_no: "",
    last_name: "",
    email_id: "",
    address: "",
    zipCode: "",
    merchant_name: "",
  });
  const history = useNavigate();
  const [kycdata, setKycData] = useState({});
  const [rolePermission, setRolePermission] = React.useState([]);

  const [firsterror, setFirstError] = useState({
    error: false,
    message: "",
  });
  const [lasterror, setLastError] = useState({
    error: false,
    message: "",
  });
  const [merchantError, setMerchantError] = useState({
    error: false,
    message: "",
  });

  const [numbererror, setNumberError] = useState({
    error: false,
    message: "",
  });

  const [BusinessEmailError, setBusinessEmailError] = useState({
    error: false,
    message: "",
  });

  const [AddressError, setAddressError] = useState({
    error: false,
    message: "",
  });

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severiety: "success",
  });
  const [age, setAge] = React.useState("1");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const saveData = async (e) => {
    if (validation.first_name != "" && firsterror.error) {
      return (
        toast(firsterror.message.toString()),
        {
          type: "error",
          autoClose: 1500,
        }
      );
    } else if (validation.last_name != "" && lasterror.error) {
      return (
        toast(lasterror.message.toString()),
        {
          type: "error",
          autoClose: 1500,
        }
      );
    } else if (merchantError.error) {
      return (
        toast(merchantError.message.toString()),
        {
          type: "error",
          autoClose: 1500,
        }
      );
    } else if (numbererror.error) {
      return (
        toast(numbererror.message.toString()),
        {
          type: "error",
          autoClose: 1500,
        }
      );
    } else if (BusinessEmailError.error) {
      return (
        toast(BusinessEmailError.message.toString()),
        {
          type: "error",
          autoClose: 1500,
        }
      );
    } else if (AddressError.error) {
      return (
        toast(AddressError.message.toString()),
        {
          type: "error",
          autoClose: 1500,
        }
      );
    } else if (
      validation.mobile_no == "" ||
      validation.email_id == "" ||
      validation.merchant_name == ""
    ) {
      return (
        toast("Please fill merchant information"),
        {
          type: "error",
          autoClose: 1500,
        }
      );
    } else if (validateKYCDATA(kycdata.data) != "") {
      return (
        toast(validateKYCDATA(kycdata.data)),
        {
          type: "error",
          autoClose: 1500,
        }
      );
    } else {
      try {
        if (!kycdata.data) {
          setValidation({
            ...validation,
            is_active: false,
          });
        }
        validation.is_active = false;
        validation.channelId = "Admin";
        validation.mobile_no = validation.mobile_no;
        const reqBody = {
          MERCHANT_DATA: JSON.stringify(validation),
          EMIRATES_DATA: JSON.stringify(kycdata?.data?.emiratesData),
          TRADE_DATA: JSON.stringify(kycdata?.data?.tradeLicenseData),
          dtlImgUrl: kycdata?.data?.dtlImgUrl,
          emiratesImgUrl: kycdata?.data?.emiratesImgUrl,
        };
        var res = await addMerchant(reqBody);

        if (res?.status == 200 && res.data) {
          history("/merchants");
          return toast("Merchant Data Added Successfully.", {
            type: "success",
            autoClose: 1500,
          });
        } else {
          return toast(res?.response?.data?.message, {
            type: "error",
            autoClose: 1500,
          });
        }
      } catch (err) {
        console.log(err);
        return toast(JSON.stringify(res), {
          type: "error",
          autoClose: 5000,
        });
      }
    }
  };

  const kycdatafetch = (data) => {
    setKycData({ ...kycdata, data });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: "", severiety: "success" });
  };

  const CHARACTER_LIMIT = 50;
  const ZIP_LIMIT = 6;
  const mobile_LIMIT = 14;

  return (
    <NavbarHoc
      id={Config.PermissionId.Merchants}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <div className="CreateMerchants_main_div">
        <p className="create_merchant_title">Merchant Information</p>
        <form>
          <div className="create_merhant_inpt_bdy">
            <Grid container spacing={2} className="merchant_main_grid">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="merchant_grid_sec"
              >
                <div className="sep_inpt_sec">
                  <p>First Name</p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="First name"
                      fullWidth
                      helperText={
                        firsterror.error == true ? firsterror.message : ""
                      }
                      inputProps={{
                        maxlength: 15,
                      }}
                      value={validation.first_name}
                      required
                      onChange={(e) => {
                        if (
                          e.target.value.length < 3 ||
                          e.target.value.length > 15 ||
                          e.target.value.charAt(0) === " "
                        ) {
                          setFirstError({
                            error: true,
                            message:
                              "First Name must be between 3 to 15 characters",
                          });
                        } else {
                          setFirstError({ error: false, message: "" });
                        }
                        setValidation({
                          ...validation,
                          first_name: e.target.value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <PersonOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Business Name<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      helperText={
                        merchantError.error == true ? merchantError.message : ""
                      }
                      fullWidth
                      placeholder="Business Name"
                      value={validation.merchant_name}
                      required
                      inputProps={{
                        maxlength: 100,
                      }}
                      onChange={(e) => {
                        if (
                          e.target.value.length < 6 ||
                          e.target.value.length > 100 ||
                          e.target.value.charAt(0) === " "
                        ) {
                          setMerchantError({
                            error: true,
                            message:
                              "Business Name must be between 6 to 100 characters",
                          });
                        } else {
                          setMerchantError({ error: false, message: "" });
                        }

                        setValidation({
                          ...validation,
                          merchant_name: e.target.value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <PersonOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>Last Name</p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      helperText={
                        lasterror.error == true ? lasterror.message : ""
                      }
                      fullWidth
                      placeholder="Last name"
                      value={validation.last_name}
                      required
                      inputProps={{
                        maxlength: 15,
                      }}
                      onChange={(e) => {
                        if (
                          e.target.value.length < 3 ||
                          e.target.value.length > 15 ||
                          e.target.value.charAt(0) === " "
                        ) {
                          setLastError({
                            error: true,
                            message:
                              "Last Name must be between 3 to 15 characters",
                          });
                        } else {
                          setLastError({ error: false, message: "" });
                        }
                        setValidation({
                          ...validation,
                          last_name: e.target.value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <PersonOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Mobile Number<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <PhoneInput
                      className="mobile-input"
                      name="mobile_no"
                      country={"ae"}
                      countryCodeEditable={true}
                      // helperText={`${validation.address.length}/${CHARACTER_LIMIT}`}
                      // helperText={`${validation.mobile_no.length
                      // }/${mobile_LIMIT} ${numbererror.error == true ? numbererror.message : ""
                      // }`}
                      // inputProps={{
                      //   maxlength: mobile_LIMIT,
                      // }}

                      value={validation.mobile_no}
                      onChange={(e, countrycode) => {
                        let val = e.replace(/-|\s/g, "");
                        if (val.length < 12 || val.length > 13) {
                          setNumberError({
                            error: true,
                            message: "Please enter valid mobile number",
                          });
                        } else {
                          setNumberError({ error: false, message: "" });
                        }
                        setValidation({
                          ...validation,
                          mobile_no: val,
                          mobile_code: countrycode.dialCode,
                        });
                      }}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>Address</p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Address"
                      fullWidth
                      inputProps={{
                        maxlength: CHARACTER_LIMIT,
                      }}
                      helperText={`${validation.address.length}/${CHARACTER_LIMIT}`}
                      value={validation.address}
                      onChange={(e) => {
                        if (
                          e.target.value.length > 99 ||
                          e.target.value.charAt(0) === " "
                        ) {
                          setAddressError({
                            error: true,
                            message: "Enter Address less than 100 Characters",
                          });
                        } else {
                          setAddressError({ error: false, message: "" });
                        }
                        setValidation({
                          ...validation,
                          address: e.target.value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <LocationOnOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Business Email<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="email"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Business Email"
                      fullWidth
                      inputProps={{
                        maxlength: CHARACTER_LIMIT,
                      }}
                      value={validation.email_id}
                      required
                      error={
                        !validation.email_id.match(
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        ) && validation.email_id !== ""
                      }
                      helperText={
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                          validation.email_id
                        ) || validation.email_id === ""
                          ? " "
                          : "Business email must be between 6 to 50 characters"
                      }
                      onChange={(e) => {
                        if (
                          !e.target.value.match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                          ) &&
                          e.target.value !== ""
                        ) {
                          setBusinessEmailError({
                            error: true,
                            message: "Please enter valid business email id",
                          });
                        } else {
                          setBusinessEmailError({ error: false, message: "" });
                        }
                        setValidation({
                          ...validation,
                          email_id: e.target.value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <img
                                src={`${process.env.PUBLIC_URL}/top-email.svg`}
                                alt=""
                                style={{ width: "25px", height: "25px" }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>Zip Code</p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="number"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Zipcode"
                      fullWidth
                      inputProps={{
                        maxlength: ZIP_LIMIT,
                      }}
                      helperText={`${validation.zipCode.length}/${ZIP_LIMIT}`}
                      value={validation.zipCode}
                      onChange={(e) => {
                        if (e.target.value.length <= 6) {
                          setValidation({
                            ...validation,
                            zipCode: e.target.value,
                          });
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <LocationOnOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div style={{ padding: "20px" }}>
            <Button
              variant="contained"
              onClick={() => {
                history("/merchants");
              }}
              sx={{ backgroundColor: "#FF5B55" }}
            >
              Cancel
            </Button>
            <Button
              onClick={(event) => saveData(event)}
              variant="contained"
              sx={{ backgroundColor: "#C9C9C9", marginLeft: "1rem" }}
            >
              Submit
            </Button>
          </div>
        </form>
        {snackbar.message && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbar.severiety}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        )}
      </div>
    </NavbarHoc>
  );
};

function validateKYCDATA(kycdata) {
  var errormsg = "";
  if (kycdata) {
    if (kycdata.dtlImg != "" || kycdata.emiratesImgUrl != "") {
      if (!kycdata.tradeLicenseData) {
        errormsg = "Please enter valid Trade license data";
      } else if (
        !kycdata.tradeLicenseData.licenseNumber ||
        kycdata.tradeLicenseData.licenseNumber == ""
      ) {
        errormsg = "Please enter valid license number";
      } else if (
        !kycdata.tradeLicenseData?.tradeName ||
        kycdata.tradeLicenseData?.tradeName == ""
      ) {
        errormsg = "Please enter valid Trade Name";
      } else if (
        !kycdata.tradeLicenseData.issueDate ||
        kycdata.tradeLicenseData.issueDate == ""
      ) {
        errormsg = "Please enter Trade license valid Issue Date";
      } else if (
        !kycdata.tradeLicenseData.endDate ||
        kycdata.tradeLicenseData.endDate == ""
      ) {
        errormsg = "Please enter Trade license valid End Date";
      } else if (
        new Date(kycdata.tradeLicenseData.endDate) -
          new Date(kycdata.tradeLicenseData.issueDate) <
        0
      ) {
        errormsg = "Issue date is greater than end date.";
        // } else if (!kycdata.tradeLicenseData.comments || kycdata.tradeLicenseData.comments == "") {
        //   errormsg = "Please enter Trade license Comments"
      } else if (!kycdata.emiratesData) {
        errormsg = "Please enter valid Emirates data";
      } else if (
        !kycdata.emiratesData.idNumber ||
        kycdata.emiratesData.idNumber == "" ||
        kycdata.emiratesData.idNumber.length != 15
      ) {
        errormsg = "Please enter valid 15 characters Emirates id Number";
      } else if (
        !kycdata.emiratesData.name ||
        kycdata.emiratesData.name == ""
      ) {
        errormsg = "Please enter Emirates name";
      } else if (
        !kycdata.emiratesData.expiryDate ||
        kycdata.emiratesData.expiryDate == ""
      ) {
        errormsg = "Please enter valid Emirates expiryDate";
      }
      // else if (!kycdata.emiratesData.comments || kycdata.emiratesData.comments == "") {
      //   errormsg = "Please enter Emirates Comments"
      // }
    }
  }
  return errormsg;
}

export default CreateMerchant;
