import React, { useState, useEffect } from "react";
import AxiosBase from "../../../networkRequest/axiosBase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PremiumPlan from "./PremiumPlan.js";
import "./PremiumPlan.css";
import Button from "@mui/material/Button";
import History from "./History";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";

function PremiumTabs(props) {
  const { rolePermission } = props;
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState([]);
  const [premiumdata, setpremiumdata] = useState([]);
  const [premiumplan, setpremiumplan] = useState([]);
  const [premiumplanval, setpremiumplanval] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [comments, setcomments] = React.useState("");
  const [transactionId, setTransactionId] = React.useState("");
  const [loader, setloader] = React.useState(false);
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get("merchantId");

  const CssTextField = styled(TextField)({
    "input#new-menu": {
      height: "0.5px",
      fontSize: "14px",
      fontFamily: "SF Pro Display",
    },
    "input#comment-text": {
      minHeight: "1rem",
    },
  });

  /**handle changes tabs */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /** starts handle popup events */
  const handleClickOpen = () => {
    const ind = premiumdata?.findIndex(
      (x) => x._id.toString() == data?.plan.toString()
    );
    setData({
      ...data,
      sequence: ind > -1 ? premiumdata[ind].sequence : 0,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /** ends handle popup events */

  /** starts premium select events */
  const handlepremiumselect = (e) => {
    setpremiumplan(e.target.value);
    setpremiumplanval(e.target.value);
  };
  /** ends premium select events */

  const commentchange = (e) => {
    setcomments(e.target.value);
  };

  useEffect(() => {
    //get premuim list
    setcomments("");
    getMyPremiumPlan();
    //get premuim plans
  }, []);

  const getMyPremiumPlan = () => {
    AxiosBase.get(`admin/subscriptions/getPlanByMerchant/${customerId}`).then(
      (response) => {
        setData(response?.data?.current_plan[0]);
      }
    );
    AxiosBase.get(
      `admin/premium?is_active=true&&merchant_id=${customerId}`
    ).then((response) => {
      setpremiumdata(response.data?.data);
    });
  };

  const upgradePremium = () => {
    //upgrade premuim
    let planId,
      plan_type_id = premiumplan;
    premiumdata?.map((p) => {
      if (p?.monthly?._id.toString() == premiumplan.toString()) {
        planId = p?._id;
      } else if (p?.annually?._id.toString() == premiumplan.toString()) {
        planId = p?._id;
      }
    });
    if (!planId || !plan_type_id) {
      toast("Please select plan", {
        type: "Warning",
        autoClose: 2000,
      });
      return;
    }
    if (comments == "" || transactionId == "") {
      toast(
        transactionId == ""
          ? "Please enter transaction id."
          : "Please enter comment.",
        {
          type: "Warning",
          autoClose: 2000,
        }
      );
    } else {
      let reqbody = {
        planId: planId,
        message: comments,
        customer_id: customerId,
        reference_id: transactionId,
        plan_type_id: plan_type_id,
      };
      setloader(true);
      AxiosBase.post(`admin/subscriptions/upgradePremium`, reqbody)
        .then((response) => {
          setloader(false);
          if (response.status) {
            toast("Premium subscription upgraded successfully", {
              type: "success",
              autoClose: 2000,
            });
            getMyPremiumPlan();
            setOpen(false);
          } else {
            toast("Premium subscription not upgraded", {
              type: "Warning",
              autoClose: 2000,
            });
          }
        })
        .catch((err) => {
          setloader(false);
          toast(err?.response?.data?.message || "Something went wrong!", {
            type: "Warning",
            autoClose: 2000,
          });
        });
    }
  };

  return (
    <>
      <div className="premium_plan_tabs_div">
        <div className="premium_tabs_btns">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Current Plan" />
            <Tab label="History" />
          </Tabs>
          {rolePermission.includes("EDIT") && (
            <Button
              className="merchant_list_add_new_merchant branch_add_btn"
              variant="contained"
              startIcon={<img src="/Upgrade-01.svg" alt="" />}
              onClick={handleClickOpen}
            >
              Upgrade
            </Button>
          )}
        </div>
        {!loader && (
          <div>{value === 0 ? <PremiumPlan data={data} /> : <History />}</div>
        )}
      </div>
      <div className="premium_upgrade_popup">
        <Dialog
          fullWidth="true"
          maxWidth="sm"
          open={open}
          onClose={handleClose}
        >
          <DialogTitle style={{ marginLeft: "30px" }}>
            Upgrade Premium Plan
          </DialogTitle>
          <DialogContent>
            <Box
              noValidate
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                m: "auto",
                width: "fit-content",
              }}
            >
              <FormControl sx={{ mt: 2, minWidth: 260 }}>
                {!loader ? (
                  <>
                    <RadioGroup
                      defaultValue={premiumplanval}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e) => handlepremiumselect(e)}
                      row
                    >
                      {premiumdata
                        .filter((x) => {
                          if (data?.plan_type == "year") {
                            return x.sequence > data?.sequence;
                          } else {
                            return x.sequence >= data?.sequence;
                          }
                        })
                        .map(
                          (row, index) =>
                            !row?.is_default &&
                            row?.activeStatus && (
                              <div
                                style={{
                                  width: "100%",
                                  "margin-bottom": "15px",
                                }}
                              >
                                <div>
                                  <span style={{ "font-size": "20px" }}>
                                    {row?.name} Plan
                                  </span>
                                  <ul className="plan_access_list">
                                    {row?.modules &&
                                      row.modules.map((md) => {
                                        return (
                                          <li
                                            style={{
                                              "font-size": "12px",
                                              color: "black",
                                            }}
                                          >
                                            {md.label}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                                {row?.is_monthly &&
                                row.sequence != data?.sequence ? (
                                  <div
                                    style={{
                                      width: "41%",
                                      border: "2px solid whitesmoke",
                                      position: "relative",
                                      padding: "13px",
                                      margin: "5px",
                                      borderRadius: "5px",
                                      float: "left",
                                    }}
                                  >
                                    <span style={{ fontSize: "10px" }}>
                                      {row?.currency_code}
                                    </span>
                                    <span>
                                      {" " +
                                        row?.monthly.final_amount +
                                        " Monthly"}
                                    </span>
                                    <Radio
                                      sx={{
                                        color: "#00C9A5",
                                        "&.Mui-checked": { color: "#00C9A5" },
                                      }}
                                      style={{
                                        top: "0",
                                        right: "0",
                                        position: "absolute",
                                      }}
                                      value={row?.monthly._id}
                                    />
                                    <p
                                      style={{
                                        marginTop: "7px",
                                        fontSize: "10px",
                                        color: "grey",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      Per active user,per Monthly billed Monthly
                                    </p>
                                    {row?.monthly.discount !== 0 && (
                                      <div
                                        style={{
                                          position: "absolute",
                                          bottom: "8%",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "11px",
                                            color: "grey",
                                            fontWeight: "bold",
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          AED {row?.amount}{" "}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "13px",
                                            color: "#43caa6",
                                            border: "1px solid #43caa6",
                                            fontWeight: "bold",
                                            padding: "1px",
                                            background: "aliceblue",
                                            borderRadius: "3px",
                                          }}
                                        >
                                          {row?.monthly.discount}% OFF
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                                {row?.annually ? (
                                  <div
                                    style={{
                                      width: "41%",
                                      border: "2px solid whitesmoke",
                                      position: "relative",
                                      padding: "13px",
                                      margin: "5px",
                                      borderRadius: "5px",
                                      float: "left",
                                    }}
                                  >
                                    <span style={{ fontSize: "10px" }}>
                                      {row?.currency_code}
                                    </span>
                                    <span>
                                      {" " +
                                        row?.annually.final_amount +
                                        " Annually"}
                                    </span>
                                    <Radio
                                      sx={{
                                        color: "#00C9A5",
                                        "&.Mui-checked": { color: "#00C9A5" },
                                      }}
                                      style={{
                                        top: "0",
                                        right: "0",
                                        position: "absolute",
                                      }}
                                      value={row?.annually._id}
                                    />
                                    <p
                                      style={{
                                        marginTop: "7px",
                                        fontSize: "10px",
                                        color: "grey",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      Per active user,per Annually billed
                                      Annually
                                    </p>
                                    {row?.annually.discount !== 0 && (
                                      <div
                                        style={{
                                          position: "absolute",
                                          bottom: "8%",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "11px",
                                            color: "grey",
                                            fontWeight: "bold",
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          AED {row?.amount}{" "}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "13px",
                                            color: "#43caa6",
                                            border: "1px solid #43caa6",
                                            fontWeight: "bold",
                                            padding: "1px",
                                            background: "aliceblue",
                                            borderRadius: "3px",
                                          }}
                                        >
                                          {row?.annually.discount}% OFF
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            )
                        )}
                    </RadioGroup>

                    <p
                      style={{
                        width: "150px",
                      }}
                    >
                      Transaction ID<span style={{ color: "red" }}> *</span>
                    </p>
                    <TextField
                      value={transactionId}
                      onChange={(e) => {
                        setTransactionId(e.target.value);
                      }}
                      fullWidth
                      label="Enter Transaction ID"
                      id="fullWidth"
                    />
                    <p
                      style={{
                        width: "150px",
                      }}
                    >
                      Comment<span style={{ color: "red" }}> *</span>
                    </p>
                    <TextField
                      value={comments}
                      onChange={(e) => commentchange(e)}
                      fullWidth
                      label="Add your comment"
                      id="fullWidth"
                    />
                    <div className="premium_upgrade_popup_btn">
                      <Button
                        onClick={upgradePremium}
                        className="btn1 btnwidth"
                        disabled={loader}
                        variant="contained"
                      >
                        Upgrade
                      </Button>
                      <Button
                        className="btn2 btnwidth"
                        variant="contained"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="no_data_found loader_data_tbl">
                    <CircularProgress />
                  </div>
                )}
              </FormControl>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default PremiumTabs;
