import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import moment from "moment";
import { useLocation } from "react-router-dom";
import AxiosBase from "../../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import "./Kyc.css";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import Filter from "../../filter-component/Filter";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
    borderRadius: "10px",
  },
});

export default function KycHistory() {
  const classes = useStyles();
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get("customerId");
  const kycId = new URLSearchParams(search).get("kycId");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [count, setCount] = useState([]);
  const [searchBy, setSearch] = React.useState();
  const [searchByCalled, setsearchByCalled] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [userdata, setUserData] = React.useState([]);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [filter, setFilter] = React.useState([
    { key: "", type: "", value: "" },
  ]);
  const filterOptons = [
    { property: "Request ID", value: "requestId" },
    { property: "Status", value: "kycStatus" },
  ];
  const filterContains = [
    { property: "Is", value: "is" },
    { property: "Like", value: "contain" },
    { property: "Not Like", value: "not_contain" },
  ];

  const pophandleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleFilter = async (JSONfilter) => {
    setFilter(JSONfilter);
  };
  const handleSearchButton = (e) => {
    setPage(0);
    setFilter([{ key: "", type: "", value: "" }]);
    setsearchByCalled(!searchByCalled);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.key === "Enter") {
      setPage(0);
      setFilter([{ key: "", type: "", value: "" }]);
      setsearchByCalled(!searchByCalled);
    }
  };
  const pophandleClose = () => {
    setAnchorE2(null);
  };
  const ResetFilter = async (event) => {
    setFilter([{ key: "", type: "", value: "" }]);
    setsearchByCalled(!searchByCalled);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const filtersval = filter[0].key != "" ? JSON.stringify(filter) : "";

    AxiosBase.get(
      `/admin/kyc/view-kyc-history/${customerId}?kycId=${kycId}&pageNo=${page}&rowsPerPage=${rowsPerPage}&sortBy=createdAt&search=${
        searchBy ? searchBy : ""
      }&filter=${filtersval}`
    ).then((response) => {
      setUserData(response.data.data);
      setCount(response.data.count);
      setLoading(false);
    });
  }, [searchByCalled, filter, page, rowsPerPage]);

  const openpop = Boolean(anchorE2);
  const id1 = openpop ? "simple-popover" : undefined;

  return (
    <>
      <div>
        <div className="merchant_list_search_filter">
          <Grid item xs={12} md={12} lg={12} className="merchant_grid_sec">
            <div
              className="merchant_list_filter_div"
              style={{ height: "100%" }}
            >
              <CssTextField
                className="serachBar"
                id="new-menu"
                placeholder="Search"
                variant="outlined"
                onKeyUp={handleSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton className="merchant_search_icon">
                        {/* <img
                        className="merchant_list_filter_img"
                        src="/search5.jpeg"
                        // aria-describedby={id1}
                        alt=""
                         onClick={handleSearchButton}
                      /> */}
                        <SearchOutlinedIcon onClick={handleSearchButton} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <img
                className="merchant_list_filter_img"
                src="/Filter.svg"
                aria-describedby={id1}
                alt=""
                onClick={pophandleClick}
              />
              {filter[0].key != "" ? (
                <img
                  className="merchant_list_filter_img"
                  src="/circle.png"
                  alt=""
                  onClick={ResetFilter}
                />
              ) : (
                <></>
              )}
            </div>
          </Grid>
        </div>
        <div style={{ borderTop: "0.5px solid #C9C9C9" }}>
          <TableContainer className="table_container">
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Request ID</TableCell>
                  <TableCell>Created on</TableCell>
                  <TableCell>Emirates ID</TableCell>
                  <TableCell>Emirates Modified Date</TableCell>
                  <TableCell>Emirates Modified By</TableCell>
                  <TableCell>Trade License</TableCell>
                  <TableCell>Trade Modified Date</TableCell>
                  <TableCell>Trade Modified By</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row">
                {userdata &&
                  userdata?.map((row, index) => {
                    return (
                      <TableRow
                        className="tblrowdata"
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        style={{ background: "none" }}
                      >
                        <TableCell>
                          <Link
                            to={`/kyc/details?kycId=${row._id}&customerId=${row.customer_id}`}
                            style={{ textDecoration: "none", color: "#2160F6" }}
                          >
                            {row.requestId}
                          </Link>
                        </TableCell>

                        <TableCell>
                          {moment(row.createdAt).format("YYYY/MM/DD hh:mm A")}
                        </TableCell>
                        <TableCell>
                          <KycStatus status={row.emiratesIdVerified} />
                        </TableCell>

                        <TableCell>
                          {row.emiratesData.updatedAt
                            ? moment(row.emiratesData.updatedAt).format(
                                "YYYY/MM/DD hh:mm A"
                              )
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          <TableCell>
                            {row.emirates_action_by?.first_name}{" "}
                            {row.emirates_action_by?.last_name}
                          </TableCell>
                        </TableCell>
                        <TableCell>
                          <KycStatus status={row.tradeLicenseVerified} />
                        </TableCell>

                        <TableCell>
                          {row.tradeLicenseData.updatedAt
                            ? moment(row.tradeLicenseData.updatedAt).format(
                                "YYYY/MM/DD hh:mm A"
                              )
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          {row.tradeLicense_action_by?.first_name}{" "}
                          {row.tradeLicense_action_by?.last_name}{" "}
                        </TableCell>
                        <TableCell>
                          <KycStatus status={row.kycStatus} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {userdata == "" && loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress />
              </div>
            ) : userdata.length == 0 ? (
              <div className="no_data_found loader_data_tbl">
                <p>No Data Found</p>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
        </div>
        {!loading && userdata.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="pagination-sec"
          />
        ) : (
          <></>
        )}
      </div>
      <div>
        <Popover
          id={id1}
          open={openpop}
          anchorEl={anchorE2}
          onClose={pophandleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ marginLeft: "125px", height: "500px", marginTop: "10px" }}
        >
          <Typography sx={{ p: 2 }}>
            <Filter
              filter1={handleFilter}
              pophandleClose={pophandleClose}
              optionns={filterOptons}
              contains={filterContains}
              filter={filter}
            />
          </Typography>
        </Popover>
      </div>
    </>
  );
}

const KycStatus = (props) => {
  const statusColor = (status) => {
    switch (status) {
      case "Approved":
        return "#00C9A5";
      case "Initiated":
        return "#1058ff";
      case "In progress":
        return "#ff0000";
      case "Expired":
        return "#FF5B55";
      case "Pending":
        return "#1058ff";
      default:
        return "#ff0000";
    }
  };
  return (
    <p style={{ color: statusColor(props.status), fontSize: "inherit" }}>
      {props.status}
    </p>
  );
};
