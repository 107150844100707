import DashboardMain from "../../Components/dashboard";
import Topheader from "../../universal-components/header/topHeader";

const Dashboard = (props) => {
  return (
    <div style={{ padding: "15px 0" }}>
      <Topheader />
      <DashboardMain />
    </div>
  );
};

export default Dashboard;
