import React from "react";
import { useState, useEffect } from "react";
import "./Credential.css";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import * as credentialSchema from "./schema";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const Credential = (props) => {
  const {
    rolePermission,
    allStatusCodeMapping,
    id,
    AddAcquirerProp,
    UpdateAcquirerProp,
  } = props;
  const [loader, setLoader] = React.useState(false);
  const [controlDisable, setControlDisable] = React.useState(false);
  const [allStatusData, setAllStatusData] = React.useState({ credential: {} });
  const [selectedObj, setSelectedObj] = React.useState([]);
  React.useEffect(() => {
    const ind =
      credentialSchema[
        `${allStatusCodeMapping?.name}_${allStatusCodeMapping?.type}`
      ];

    if (ind && ind.length) {
      setSelectedObj(ind);
    } else {
      setSelectedObj([
        {
          key: "MID",
          label: "mid",
        },
        {
          key: "secret_key",
          label: "Secret Key",
        },
      ]);
    }
    CommonCallFunction();
  }, [id, allStatusCodeMapping]);

  const CommonCallFunction = () => {
    id && setControlDisable(true);
    setAllStatusData(JSON.parse(JSON.stringify(allStatusCodeMapping)));
    setLoader(false);
  };

  const SaveCode = () => {
    let flag = true;
    selectedObj.map((elm) => {
      if (
        !allStatusData?.credential[elm.key] ||
        allStatusData?.credential[elm.key] == ""
      ) {
        flag = false;
      }
    });
    if (flag) {
      setLoader(true);
      let data = allStatusData;
      UpdateAcquirerProp(data, data._id);
    } else {
      toast("Please Fill All The Mandatory Details.", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };
  const AddCode = () => {
    let flag = true;
    selectedObj.map((elm) => {
      if (
        !allStatusData?.credential[elm.key] ||
        allStatusData?.credential[elm.key] == ""
      ) {
        flag = false;
      }
    });
    if (flag) {
      AddAcquirerProp(allStatusData, 2);
    } else {
      toast("Please Fill All The Mandatory Details.", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="Credential">
          <Grid className="CredentialRow" container spacing={2}>
            {selectedObj.map((elm) => {
              return (
                <>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
                    <p className="TaxTitle">
                      {elm.label} <span className="Mandatory">*</span>
                    </p>
                    <CssTextField
                      className=""
                      id="new-menu"
                      placeholder={elm.label}
                      variant="outlined"
                      fullWidth
                      disabled={controlDisable}
                      value={allStatusData?.credential[elm.key]}
                      onChange={(e) => {
                        let val = e.target.value;
                        let data = allStatusData;
                        data.credential[elm.key] = val;
                        setAllStatusData({ ...data });
                      }}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
          {!id ? (
            <Grid
              className="CredentialRow2"
              container
              spacing={2}
              justifyContent="flex-end"
            >
              <Button
                onClick={AddCode}
                className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                style={{ marginRight: "29px" }}
                variant="contained"
              >
                Next
              </Button>
            </Grid>
          ) : (
            <Grid
              className="CredentialRow2"
              container
              spacing={2}
              justifyContent="flex-end"
            >
              {!controlDisable ? (
                <>
                  <Button
                    onClick={SaveCode}
                    className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                    style={{ marginRight: "29px" }}
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      CommonCallFunction();
                      setControlDisable(true);
                    }}
                    className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  {rolePermission.includes("EDIT") && (
                    <Button
                      onClick={() => {
                        setControlDisable(false);
                      }}
                      className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                      style={{ marginRight: "29px" }}
                      variant="contained"
                    >
                      Edit
                    </Button>
                  )}
                </>
              )}
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

export default Credential;
