import React from "react";
import { useState, useEffect } from "react";
import "./CountryDetail.css";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import AxiosBase from "../../../../networkRequest/axiosBase";
import baseURL from "../../../../utils/base_url";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});

const CountryDetail = (props) => {
  const { SelectedCountry } = props;
  const [country, setCountry] = React.useState([]);
  const [taxValue, setTaxValue] = React.useState([]);
  const [value, setValue] = React.useState(null);

  useEffect(() => {
    setCountry(SelectedCountry);
    setTaxValue(SelectedCountry?.tax?.value || []);
  }, [props.SelectedCountry]);

  const hiddenFileInput = React.useRef(null);
  const handleFileClick = (event) => {
    hiddenFileInput.current.click();
  };

  const addTaxValue = () => {
    if (!value) {
      return;
    }
    taxValue.push(Number(value));
    country.tax.value = taxValue;
    setCountry({
      ...country,
    });
    setValue("");
  };

  const deleteTaxValue = (index) => {
    taxValue.splice(index, 1);
    country.tax.value = taxValue;
    setCountry({
      ...country,
    });
  };

  const addKyc = () => {
    if (!country?.country_name || country?.country_name == "") {
      return toast("name is required", {
        type: "error",
        autoClose: 2000,
      });
    }
    props.onSubmitCountry(country);
  };

  const onChangeInput = (e, key, type = "String") => {
    let val = e.target.value;
    if (type == "Int") {
      val = val.toString().replaceAll("+", "");
      let val1 = val.match(/[0-9]/);
      if (val1) {
        val = isNaN(parseInt(val)) ? "" : parseInt(val);
      } else if (!val) {
        val = "";
      } else {
        return;
      }
    } else {
      val = val.replace(/^\s+/, "").replace("  ", " ");
    }
    if (key == "tax_name") {
      country.tax.name = val;
      setCountry({
        ...country,
      });
    } else if (key == "tax_document") {
      country.tax.document = val;
      setCountry({
        ...country,
      });
    } else {
      country[key] = val;
      setCountry({
        ...country,
      });
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const uploadFile = (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    AxiosBase.post("/app/image/upload", formData, config).then((res) => {
      if (res?.status == 200) {
        country.icon = res.data.file_name;
        setCountry({
          ...country,
        });
      }
    });
  };

  return (
    <>
      <div style={{ width: "100%" }} className="kyc_detail_ctrl">
        <div className="">
          <div>
            <div className="add_menu_role_star">
              <p>Country Name:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"name_"}
                  placeholder="name"
                  variant="outlined"
                  aria-readonly
                  value={country.country_name}
                  onChange={(e) => {
                    onChangeInput(e, "country_name");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>AlphaTwoCode:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"alphaTwoCode_"}
                  placeholder="alphaTwoCode"
                  variant="outlined"
                  aria-readonly
                  value={country.alphaTwoCode}
                  onChange={(e) => {
                    onChangeInput(e, "alphaTwoCode");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>AlphaThreeCode:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"alphaThreeCode_"}
                  placeholder="alphaThreeCode"
                  variant="outlined"
                  aria-readonly
                  value={country.alphaThreeCode}
                  onChange={(e) => {
                    onChangeInput(e, "alphaThreeCode");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Mobile No Regex:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"mobile_no_regex_"}
                  placeholder="mobile_no_regex"
                  variant="outlined"
                  aria-readonly
                  value={country.mobile_no_regex}
                  onChange={(e) => {
                    onChangeInput(e, "mobile_no_regex");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Currency Name:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"currency_name_"}
                  placeholder="currency_name"
                  variant="outlined"
                  aria-readonly
                  value={country.currency_name}
                  onChange={(e) => {
                    onChangeInput(e, "currency_name");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Currency Code:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"currency_code_"}
                  placeholder="currency_code"
                  variant="outlined"
                  aria-readonly
                  value={country.currency_code}
                  onChange={(e) => {
                    onChangeInput(e, "currency_code");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Currency Symbol:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"currency_symbol_"}
                  placeholder="currency_symbol"
                  variant="outlined"
                  aria-readonly
                  value={country.currency_symbol}
                  onChange={(e) => {
                    onChangeInput(e, "currency_symbol");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Mobile Code:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"mobile_code_"}
                  placeholder="mobile_code"
                  variant="outlined"
                  aria-readonly
                  value={country.mobile_code}
                  onChange={(e) => {
                    onChangeInput(e, "mobile_code", "Int");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Numeric Code:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"numeric_code_"}
                  placeholder="numeric_code"
                  variant="outlined"
                  aria-readonly
                  value={country.numeric_code}
                  onChange={(e) => {
                    onChangeInput(e, "numeric_code", "Int");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>TimeZone:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"timezone_"}
                  placeholder="timezone"
                  variant="outlined"
                  aria-readonly
                  value={country.timezone}
                  onChange={(e) => {
                    onChangeInput(e, "timezone");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>TimeZone Name:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"timezone_name_"}
                  placeholder="timezone_name"
                  variant="outlined"
                  aria-readonly
                  value={country.timezone_name}
                  onChange={(e) => {
                    onChangeInput(e, "timezone_name");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Tax Name:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"tax_name_"}
                  placeholder="tax_name"
                  variant="outlined"
                  aria-readonly
                  value={country?.tax?.name}
                  onChange={(e) => {
                    onChangeInput(e, "tax_name");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Tax Document:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"tax_document_"}
                  placeholder="tax_document"
                  variant="outlined"
                  aria-readonly
                  value={country?.tax?.document}
                  onChange={(e) => {
                    onChangeInput(e, "tax_document");
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Tax Values:</p>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"value_"}
                  placeholder="value"
                  variant="outlined"
                  aria-readonly
                  value={value}
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  onWheel={numberInputOnWheelPreventChange}
                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                  onChange={(e) => {
                    let val = e.target.value;
                    val = val.toString().replaceAll("+", "");
                    const isDecimal = val % 1 != 0;
                    if (isDecimal) {
                      val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                    }
                    if (!val) {
                      val = "";
                    }
                    if (val == "" || (val > 0 && val <= 100)) {
                      setValue(val);
                    }
                  }}
                />
                <div className="country_edit_drawer_btn_sec">
                  <Button
                    className="add_tax_btn"
                    variant="contained"
                    onClick={() => {
                      addTaxValue();
                    }}
                  >
                    Add Value
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                className="gatway_second_grid_sec country-field"
              >
                <ul>
                  {taxValue.map((v, index) => (
                    <>
                      <li>
                        {index + 1}. {v}%{" "}
                        <i
                          class="fa fa-trash"
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => deleteTaxValue(index)}
                        ></i>
                      </li>
                    </>
                  ))}
                </ul>
              </Grid>
            </div>

            <div className="add_menu_role_star">
              <p>Flag Icon Path:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec country-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"icon_"}
                  placeholder="icon"
                  variant="outlined"
                  aria-readonly
                  value={country?.icon}
                  onChange={(e) => {
                    onChangeInput(e, "icon");
                  }}
                />
                {/* <img
                  src={`${baseURL()}/images/${country.icon}`}
                  style={{ width: "10%", "margin-top": "15px" }}
                /> */}
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec kyc-field"
              >
                <Checkbox
                  id={"is_active_"}
                  name={"is_active_"}
                  label={country.is_active ? "yes" : "no"}
                  checked={country.is_active ? true : false}
                  onChange={(e) => {
                    country.is_active = e.target.checked;
                    setCountry({
                      ...country,
                      is_active: e.target.checked,
                    });
                  }}
                />
                <label>Active</label>
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec kyc-field"
              >
                <Checkbox
                  id={"is_payment_allowed_"}
                  name={"is_payment_allowed_"}
                  label={country.is_payment_allowed ? "yes" : "no"}
                  checked={country.is_payment_allowed ? true : false}
                  onChange={(e) => {
                    country.is_payment_allowed = e.target.checked;
                    setCountry({
                      ...country,
                      is_payment_allowed: e.target.checked,
                    });
                  }}
                />
                <label>Payment Allowed</label>
              </Grid>
            </div>
          </div>
        </div>
        <div>
          <div className="country_edit_drawer_btn_sec">
            <Button
              className="drawer_merchant_cancel_btn"
              variant="contained"
              onClick={props.toggleDrawer("right", false)}
            >
              Cancel
            </Button>

            <Button
              className="drawer_merchant_save_btn"
              variant="contained"
              onClick={() => {
                addKyc();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CountryDetail;
