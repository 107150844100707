import React from "react";
import { useState, useEffect } from "react";
import "./BasicInformation.css";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "grey" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const BasicInformation = (props) => {
  const {
    rolePermission,
    allStatusCodeMapping,
    id,
    allCurrency,
    AddAcquirerProp,
    UpdateAcquirerProp,
  } = props;
  const [controlDisable, setControlDisable] = React.useState(false);
  const [allCurrencyData, setAllCurrencyData] = React.useState([]);
  const [allStatusData, setAllStatusData] = React.useState({});
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    CommonCallFunction();
  }, [id, allStatusCodeMapping, allCurrency]);

  const CommonCallFunction = () => {
    id && setControlDisable(true);
    setAllCurrencyData(allCurrency);
    setAllStatusData(allStatusCodeMapping);
    setLoader(false);
  };

  const SaveCode = () => {
    const { code, services, name, type } = allStatusData;
    let checkService =
      services?.creditcards?.status ||
      services?.debitcards?.status ||
      services?.upis?.status ||
      services?.wallets?.status ||
      services?.banks?.status;
    if (
      code !== "" &&
      type !== "" &&
      name !== "" &&
      services &&
      Object.keys(services).length !== 0 &&
      checkService
    ) {
      setLoader(true);
      let data = allStatusData;
      UpdateAcquirerProp(data, data._id);
    } else {
      toast("Please Fill All Mandatory Details", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };

  const AddCode = () => {
    const { code, services, name, type } = allStatusData;
    let checkService =
      services?.creditcards?.status ||
      services?.debitcards?.status ||
      services?.upis?.status ||
      services?.wallets?.status ||
      services?.banks?.status;
    if (
      code &&
      services &&
      name &&
      type &&
      type !== "" &&
      name !== "" &&
      services &&
      Object.keys(services).length !== 0 &&
      checkService
    ) {
      AddAcquirerProp(allStatusData, 0);
    } else {
      toast("Please Fill All Mandatory Details", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };

  const ChangeSwitch = (flag, type) => {
    let data = allStatusData;
    if (allStatusData?.services?.[type] && id) {
      allStatusData.services[type].status = flag;
    } else {
      allStatusData.services[type] = {
        fixed_fees: 0,
        margin: 0,
        status: flag,
        vat: 0,
      };
    }
    setAllStatusData({ ...data });
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };
  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="BasicInformation">
          <Grid className="BasicInformationRow" container spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="BasicTitle">
                Acquirer Code <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Code"
                variant="outlined"
                fullWidth
                value={allStatusData?.code}
                onChange={(e) => {
                  let val = e.target.value.match("^[0-9]*$");
                  if (val) {
                    setAllStatusData({
                      ...allStatusData,
                      code: e.target.value,
                    });
                  }
                }}
                disabled={controlDisable}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="">
              <p className="BasicTitle">
                Acquirer Name <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Name"
                variant="outlined"
                value={allStatusData?.name}
                onChange={(e) => {
                  setAllStatusData({ ...allStatusData, name: e.target.value });
                }}
                fullWidth
                disabled={controlDisable}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="BasicTitle">
                Integration Type <span className="Mandatory">*</span>
              </p>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    allStatusData?.type !== undefined
                      ? allStatusData?.type
                      : null
                  }
                  label=""
                  onChange={(e) => {
                    setAllStatusData({
                      ...allStatusData,
                      type: e.target.value,
                    });
                  }}
                  fullWidth
                  disabled={controlDisable}
                >
                  <MenuItem value="seamless">seamless</MenuItem>
                  <MenuItem value="nonseamless">non seamless</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid className="BasicInformationRow" container spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="BasicTitle">
                Acquirer Label <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="acquirer label"
                variant="outlined"
                fullWidth
                value={allStatusData?.label}
                onChange={(e) => {
                  setAllStatusData({ ...allStatusData, label: e.target.value });
                }}
                disabled={controlDisable}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="BasicTitle">
                Priority <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="priority"
                variant="outlined"
                fullWidth
                value={allStatusData?.priority}
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                onChange={(e) => {
                  let val = e.target.value.match("^[0-9]*$");
                  if (val) {
                    setAllStatusData({
                      ...allStatusData,
                      priority: e.target.value,
                    });
                  }
                }}
                disabled={controlDisable}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className="">
              <p className="BasicTitle">Subscription Payment</p>
              <IOSSwitch
                checked={allStatusData?.subscription}
                label=""
                disabled={controlDisable}
                onChange={(e) => {
                  setAllStatusData({
                    ...allStatusData,
                    subscription: e.target.checked,
                  });
                }}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className="">
              <p className="BasicTitle">EPP Payment</p>
              <IOSSwitch
                checked={allStatusData?.epp_payment}
                label=""
                disabled={controlDisable}
                onChange={(e) => {
                  setAllStatusData({
                    ...allStatusData,
                    epp_payment: e.target.checked,
                  });
                }}
              />
            </Grid>
          </Grid>
          <p className="BasicTitle">
            Services <span style={{ color: "red" }}>*</span>
          </p>
          <Grid className="BasicInformationRow2" container spacing={2}>
            <Grid
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              className=""
            >
              <p className="PayMentTitle">Credit Cards</p>
              <IOSSwitch
                checked={allStatusData?.services?.creditcards?.status}
                onChange={(e) => {
                  ChangeSwitch(e.target.checked, "creditcards");
                }}
                label=""
                disabled={!rolePermission.includes("EDIT") || controlDisable}
              />
            </Grid>
            <Grid
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              className=""
            >
              <p className="PayMentTitle">Debit Cards </p>
              <IOSSwitch
                checked={allStatusData?.services?.debitcards?.status}
                label=""
                disabled={!rolePermission.includes("EDIT") || controlDisable}
                onChange={(e) => {
                  ChangeSwitch(e.target.checked, "debitcards");
                }}
              />
            </Grid>
            <Grid
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              className=""
            >
              <p className="PayMentTitle">UPI</p>
              <IOSSwitch
                checked={allStatusData?.services?.upis?.status}
                label=""
                disabled={!rolePermission.includes("EDIT") || controlDisable}
                onChange={(e) => {
                  ChangeSwitch(e.target.checked, "upis");
                }}
              />
            </Grid>
            <Grid
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              className=""
            >
              <p className="PayMentTitle">Wallet</p>
              <IOSSwitch
                checked={allStatusData?.services?.wallets?.status}
                label=""
                disabled={!rolePermission.includes("EDIT") || controlDisable}
                onChange={(e) => {
                  ChangeSwitch(e.target.checked, "wallets");
                }}
              />
            </Grid>
            <Grid
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              className=""
            >
              <p className="PayMentTitle">Net Banking</p>
              <IOSSwitch
                checked={allStatusData?.services?.banks?.status}
                label=""
                disabled={!rolePermission.includes("EDIT") || controlDisable}
                onChange={(e) => {
                  ChangeSwitch(e.target.checked, "banks");
                }}
              />
            </Grid>
            <Grid
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              className=""
            >
              <p className="PayMentTitle">Apple Pay</p>
              <IOSSwitch
                checked={allStatusData?.services?.applepay?.status}
                label=""
                disabled={!rolePermission.includes("EDIT") || controlDisable}
                onChange={(e) => {
                  ChangeSwitch(e.target.checked, "applepay");
                }}
              />
            </Grid>
            <Grid
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              className=""
            >
              <p className="PayMentTitle">Google Pay</p>
              <IOSSwitch
                checked={allStatusData?.services?.googlepay?.status}
                label=""
                disabled={!rolePermission.includes("EDIT") || controlDisable}
                onChange={(e) => {
                  ChangeSwitch(e.target.checked, "googlepay");
                }}
              />
            </Grid>
          </Grid>
          <Grid className="BasicInformationRow" container spacing={3}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="BasicTitle">Base Currency</p>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    allStatusData?.basecurrency
                      ? allStatusData?.basecurrency
                      : ""
                  }
                  label=""
                  disabled={controlDisable}
                  onChange={(e) => {
                    setAllStatusData({
                      ...allStatusData,
                      basecurrency: e.target.value,
                    });
                  }}
                >
                  {allCurrencyData &&
                    allCurrencyData.map((fact) => {
                      return (
                        <MenuItem value={fact.currency_code}>
                          {fact.currency_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="BasicTitle">Status </p>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    allStatusData?.status !== undefined
                      ? allStatusData?.status
                      : null
                  }
                  label=""
                  onChange={(e) => {
                    setAllStatusData({
                      ...allStatusData,
                      status: e.target.value,
                    });
                  }}
                  disabled={controlDisable}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>InActive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {id ? (
            <Grid
              className="BasicInformationRow"
              container
              spacing={2}
              justifyContent="flex-end"
            >
              {!controlDisable ? (
                <>
                  <Button
                    onClick={SaveCode}
                    className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                    style={{ marginRight: "29px" }}
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      CommonCallFunction();
                      setControlDisable(true);
                    }}
                    className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  {rolePermission.includes("EDIT") && (
                    <Button
                      onClick={() => {
                        setControlDisable(false);
                      }}
                      className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                      style={{ marginRight: "29px" }}
                      variant="contained"
                    >
                      Edit
                    </Button>
                  )}
                </>
              )}
            </Grid>
          ) : (
            <Grid
              className="BasicInformationRow"
              container
              spacing={2}
              justifyContent="flex-end"
            >
              {rolePermission.includes("ADD") && (
                <Button
                  onClick={AddCode}
                  className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                  style={{ marginRight: "29px" }}
                  variant="contained"
                >
                  NEXT
                </Button>
              )}
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

export default BasicInformation;
