import React from "react";
import { useState, useEffect } from "react";
import "./CronJobDetail.css";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Link } from "react-router-dom";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CronJobCodeList from "./../../../../utils/cronjob.json";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CronJobDetail = (props) => {
  const [cronjobdata, setCronJobData] = useState([]);
  const [controlDisable, setControlDisable] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [currentSaveCancelId, setCurrentSaveCancelId] = useState("");
  const [currentEditId, setCurrentEditId] = useState("");
  const [rolePermission, setRolePermission] = useState([]);
  const [selectedCronJob, setSelectedCronJob] = React.useState({});

  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setRolePermission(props.rolePermission);
    setCronJobData(props.data);
    setCount(props.data.length);
    setLoading(false);
  }, [props.data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const editCronJob = (e, id) => {
    setCurrentSaveCancelId(id);
    setCurrentEditId(id);
    setControlDisable(false);
  };

  const saveCronJob = (e, id, elm) => {
    if (!elm?.code || elm?.code == "") {
      return toast("code is required", {
        type: "error",
        autoClose: 2000,
      });
    }
    if (!elm?.name || elm?.name == "") {
      return toast("name is required", {
        type: "error",
        autoClose: 2000,
      });
    }
    if (!elm?.time || elm?.time == "") {
      return toast("time is required", {
        type: "error",
        autoClose: 2000,
      });
    }
    const ind = cronjobdata.findIndex((x) => x.code == elm.code && x._id != id);
    if (ind > -1) {
      return toast("you cannot use same code for multiple records", {
        type: "error",
        autoClose: 2000,
      });
    }
    setCurrentSaveCancelId("");
    setCurrentEditId("");
    setControlDisable(true);
    props.saveCronJob(e, id, elm);
  };

  const deleteCronJob = (e, cronjob) => {
    setSelectedCronJob(cronjob);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addCronJob = () => {
    if (currentEditId == "add_new") {
      return;
    }
    setCurrentEditId("add_new");
    setControlDisable(false);
    setCurrentSaveCancelId("add_new");
    props.addCronJob();
  };

  return (
    <>
      <div className="cronjob_cronjob_top_div">
        <div className="Fees_Margin_title_div" style={{ position: "relative" }}>
          <p>Cron Job</p>
          {rolePermission.includes("ADD") && (
            <Link
              style={{
                position: "absolute",
                right: "0",
                top: "16px",
              }}
            >
              <Button
                className="merchant_list_add_new_merchant"
                variant="contained"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={(e) => {
                  addCronJob();
                }}
              >
                Add
              </Button>
            </Link>
          )}
        </div>
        <div className="add_merchant_list"></div>
        <div>
          <TableContainer
            className={
              cronjobdata.length > 0 && !loading
                ? "table_container"
                : "table_container table_container_nodata"
            }
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Code</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Running Time</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row cronjob-table">
                {cronjobdata.map((elm, index) => {
                  const { _id } = elm;
                  return (
                    <>
                      <TableRow
                        style={{ background: "none" }}
                        className="order_list_data_body"
                      >
                        <TableCell>
                          <Select
                            name="key1"
                            labelId="code-lbl"
                            label=""
                            id={"code_" + elm?._id}
                            value={elm.code}
                            disabled={!(currentSaveCancelId === _id)}
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              elm.code = e.target.value;
                              setSelectedCronJob(
                                Object.values({
                                  ...cronjobdata,
                                  [index]: {
                                    ...cronjobdata[index],
                                    code: e.target.value,
                                  },
                                })
                              );
                            }}
                          >
                            {CronJobCodeList &&
                              CronJobCodeList.map((c) => {
                                return (
                                  <MenuItem key={c.code} value={c.code}>
                                    {c.code}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <CssTextField
                            className="txt_input"
                            id={"name_" + _id}
                            name={"name_" + _id}
                            placeholder="name"
                            variant="outlined"
                            disabled={!(currentSaveCancelId === _id)}
                            value={elm.name}
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              let val = e.target.value;
                              elm.name = val;
                              setSelectedCronJob(
                                Object.values({
                                  ...cronjobdata,
                                  [index]: {
                                    ...cronjobdata[index],
                                    name: e.target.value,
                                  },
                                })
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <CssTextField
                            className="txt_input"
                            id={"time_" + _id}
                            name={"time_" + _id}
                            placeholder="time"
                            variant="outlined"
                            disabled={!(currentSaveCancelId === _id)}
                            value={elm.time}
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              let val = e.target.value;
                              elm.time = val;
                              setSelectedCronJob(
                                Object.values({
                                  ...cronjobdata,
                                  [index]: {
                                    ...cronjobdata[index],
                                    time: e.target.value,
                                  },
                                })
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            id={"status_" + _id}
                            name={"status_" + _id}
                            label={elm.status ? "yes" : "no"}
                            checked={elm.status}
                            disabled={
                              !(currentSaveCancelId === _id) ||
                              !rolePermission.includes("EDIT")
                            }
                            onClick={(e) => {
                              elm.status = !elm.status;
                              setCronJobData(
                                Object.values({
                                  ...cronjobdata,
                                  [index]: {
                                    ...cronjobdata[index],
                                    status: elm.status,
                                  },
                                })
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <div className="cronjob_cronjob_edit_button_dev cronjob_cronjob_drawer_save_cancel_btn">
                            <span
                              className={
                                currentSaveCancelId == _id
                                  ? "visible"
                                  : "hidden"
                              }
                            >
                              <Button
                                id={"BtnCancel_" + _id}
                                name={"BtnCancel_" + _id}
                                className="premium_cancel_btn"
                                variant="contained"
                                title="Cancel"
                                onClick={(e) => {
                                  props.cancelCronJob(e, _id);
                                }}
                              >
                                <i className="fa fa-times"></i>
                              </Button>
                              <Button
                                id={"BtnSave_" + _id}
                                name={"BtnSave_" + _id}
                                className="premium_save_btn"
                                variant="contained"
                                title="Save Plan"
                                onClick={(e) => {
                                  saveCronJob(e, elm._id, elm);
                                }}
                              >
                                <i className="fa fa-paper-plane"></i>
                              </Button>
                            </span>
                            <span
                              className={
                                currentSaveCancelId != _id
                                  ? "visible"
                                  : "hidden"
                              }
                            >
                              {rolePermission.includes("EDIT") && (
                                <Button
                                  id={"BtnEdit_" + _id}
                                  name={"BtnEdit_" + _id}
                                  className="premium_edit_btn"
                                  variant="contained"
                                  title="Edit Plan"
                                  onClick={(e) => {
                                    editCronJob(e, _id);
                                  }}
                                >
                                  <i class="fa fa-pencil"></i>
                                </Button>
                              )}
                              <Button
                                id={"BtnEdit_" + _id}
                                name={"BtnEdit_" + _id}
                                className="premium_delete_btn"
                                variant="contained"
                                title="Delete Plan"
                                onClick={(e) => {
                                  deleteCronJob(e, elm);
                                }}
                              >
                                <i class="fa fa-trash"></i>
                              </Button>
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress />
              </div>
            ) : cronjobdata == "" ? (
              <div className="no_data_found loader_data_tbl">
                <p>No Data Found</p>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          {!loading && cronjobdata.length > 0 ? (
            <TablePagination
              className="table_pagination"
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              {
                <>
                  {" "}
                  <p>Confirm Delete</p>
                  <span>Are you sure want to delete this document?</span>
                  <div className="order_detail_refund_popup_btn">
                    <Button
                      className="refund_save_btn"
                      variant="contained"
                      autoFocus
                      fullWidth
                      onClick={() => {
                        props.handleDelete(selectedCronJob._id);
                      }}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="refund_cancel_btn"
                      variant="contained"
                      autoFocus
                      onClick={handleClose}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              }
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default CronJobDetail;
