import React from "react";
import { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import "./MerchantOnBoarding.css";
import AxiosBase from "../../../networkRequest/axiosBase";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const momentTimeArr = [
  {
    label: "Minutes",
    value: "minutes",
  },
  {
    label: "Hours",
    value: "hours",
  },
  {
    label: "Days",
    value: "days",
  },
  {
    label: "Months",
    value: "months",
  },
  {
    label: "Years",
    value: "years",
  },
];
const MerchantOnBoarding = (props) => {
  const { rolePermission } = props;
  const [spinner, setSpinner] = useState(false);
  const [controlDisable, setControlDisable] = useState(true);
  const [onBoarding, setOnBoarding] = useState({
    automatic_kyc: false,
    nudge_scheduler: {
      value: "",
      time: "",
    },
    version_no: "",
    is_force: false,
  });
  const handleChange = (event) => {
    try {
      onBoarding.automatic_kyc = event.target.checked;
      setOnBoarding({ ...onBoarding });
    } catch (err) {
      setSpinner(false);
      setControlDisable(false);
      return toast(JSON.stringify(err), {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  const onSubmit = () => {
    try {
      setSpinner(true);
      setControlDisable(true);
      AxiosBase.patch("/admin/app/config", onBoarding).then((response) => {
        if (response?.status == 200) {
          if (response?.data?.message === "App Config Updated Successfully!") {
            setSpinner(false);
            setControlDisable(false);
            return toast(response?.data?.message, {
              type: "success",
              autoClose: 2000,
            });
          }
        } else {
          setSpinner(false);
          setControlDisable(false);
          return toast(JSON.stringify(response?.response?.data), {
            type: "error",
            autoClose: 2000,
          });
        }
      });
    } catch (err) {
      setSpinner(false);
      setControlDisable(false);
      return toast(JSON.stringify(err), {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    setSpinner(true);
    setControlDisable(true);
    try {
      AxiosBase.get(`/admin/app/config`).then((response) => {
        setOnBoarding({
          automatic_kyc: response.data.automatic_kyc,
          nudge_scheduler: response.data?.nudge_scheduler,
          version_no: response.data?.version_no,
          is_force: response.data?.is_force,
        });
        setSpinner(false);
        setControlDisable(false);
      });
    } catch (err) {
      setSpinner(false);
      setControlDisable(false);
      return toast(JSON.stringify(err), {
        type: "error",
        autoClose: 5000,
      });
    }
  }, []);
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };
  return (
    <>
      <div>
        <div className="Merchant_Onboarding_main_div">
          <p className="configuration-heading">Nudge Scheduler</p>
          <div className="Fees_Margin_input_field">
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              className="gatway_second_grid_sec"
            >
              <CssTextField
                className="settlement_field"
                id="new-menu"
                placeholder="Period"
                variant="outlined"
                fullWidth
                value={onBoarding.nudge_scheduler.value}
                onWheel={numberInputOnWheelPreventChange}
                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                disabled={spinner}
                onChange={(e) => {
                  let val = e.target.value;
                  const isDecimal = val % 1 != 0;
                  if (isDecimal) {
                    val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                  }
                  if (val >= 0 && val <= 100) {
                    onBoarding.nudge_scheduler.value = val;
                    setOnBoarding({ ...onBoarding });
                  }
                }}
              />
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                style={{ width: "12%", "margin-left": "5px" }}
                value={onBoarding.nudge_scheduler.time}
                label="Select Time"
                disabled={spinner}
                onChange={(e) => {
                  onBoarding.nudge_scheduler.time = e.target.value;
                  setOnBoarding({ ...onBoarding });
                }}
              >
                {momentTimeArr.map((innerRow) => {
                  return (
                    <MenuItem value={innerRow.value}>{innerRow.label}</MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </div>

          <p className="configuration-heading">App Version & Force Update</p>
          <div className="Fees_Margin_input_field">
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              className="gatway_second_grid_sec"
            >
              <CssTextField
                className="settlement_field"
                id="new-menu"
                placeholder="00.0.0"
                variant="outlined"
                fullWidth
                value={onBoarding.version_no}
                disabled={spinner}
                onChange={(e) => {
                  onBoarding.version_no = e.target.value;
                  setOnBoarding({ ...onBoarding });
                }}
              />
              <Switch
                disabled={!rolePermission.includes("EDIT") || spinner}
                checked={onBoarding.is_force || false}
                onChange={(e) => {
                  onBoarding.is_force = e.target.checked;
                  setOnBoarding({ ...onBoarding });
                }}
              />
            </Grid>
          </div>

          <div className="merchant_edit_button_dev edit_button_set">
            <span>
              <Button
                className="merchant_detail_tab_save-cancel_btn"
                style={{ "background-color": "#00c9a5" }}
                variant="contained"
                disabled={spinner}
                onClick={() => {
                  onSubmit();
                }}
              >
                Save
              </Button>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantOnBoarding;
