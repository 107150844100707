import React, { useEffect, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import styles from "./dataTable.module.scss";
import { getAllCustomers } from "../../../../networkRequest/request/customers";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import debounce from "@mui/utils/debounce";
import moment from "moment";

const columns = [
  { id: "_id", label: "Customer ID", disableSorting: true },
  { id: "first_name", label: "First Name" },
  { id: "last_name", label: "Last Name" },
  { id: "mobile_no", label: "Phone No." },
  { id: "email_id", label: "Email ID" },
  { id: "createdAt", label: "Created At" },
  { id: "action", label: "Action", disableSorting: true },
  { id: "isActive", label: "Is Active", disableSorting: true },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    // maxHeight: 440,
    // minHeight: 400
  },
});

export default function DataTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortBy, setSortBy] = React.useState("_id");
  const [sortOrder, setSortOrder] = React.useState("desc");

  const [loading, setLoading] = React.useState(true);

  const [rows, setRows] = React.useState();
  const [totalRows, setTotalRows] = React.useState(0);
  const [search, setSearch] = React.useState("");

  const fetchData = useCallback(() => {
    getAllCustomers(page, rowsPerPage, sortBy, sortOrder, search).then(
      (resp) => {
        if (resp.customers) {
          setRows(resp.customers);
          setLoading(false);
          setTotalRows(resp.totalCount);
        } else {
          toast("Invalid Credentials", { type: "error", autoClose: 5000 });
        }
      }
    );
  }, [page, rowsPerPage, sortBy, sortOrder, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = sortBy === cellId && sortOrder === "asc";
    // const sortType = isAsc ? -1 : 1;
    // Over here we will make an api call
    setSortOrder(isAsc ? "desc" : "asc");
    if (cellId === "createdAt") {
      setSortBy("_id");
    } else {
      setSortBy(cellId);
    }
  };

  const handleSearch = debounce((query) => {
    setSearch(query);
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div style={{ paddingTop: "15px" }}>
        <input
          className={styles.input}
          type="text"
          onChange={(event) => handleSearch(event.target.value)}
          placeholder="Search.."
        />
      </div>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow style={{}}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sortDirection={sortBy === column.id ? sortOrder : false}
                  className={styles.thead}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "white",
                    borderBottom: "1px solid #B2B2B2",
                    padding: "12px",
                  }}
                >
                  {column.disableSorting ? (
                    <p className={styles.thead}>{column.label}</p>
                  ) : (
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortBy === column.id ? sortOrder : "asc"}
                      onClick={() => handleSortRequest(column.id)}
                    >
                      <p className={styles.thead}>{column.label}</p>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              rows.map((row) => {
                return (
                  <TableRow className={styles.tr} key={row._id}>
                    {columns.map((column) => {
                      const value = row[column.id];

                      return column.id === "action" ? (
                        <TableCell key={column.id} align={column.align}>
                          {/* {'View / Modify'} */}
                          <Link to={`/customers/profile?customerId=${row._id}`}>
                            {" "}
                            View{" "}
                          </Link>
                          {/* <Link to="/customers/modify"> Modify </Link> */}
                        </TableCell>
                      ) : column.id === "isActive" ? (
                        <TableCell key={column.id} align={column.align}>
                          Yes
                        </TableCell>
                      ) : column.id === "createdAt" ? (
                        <TableCell key={column.id} align={column.align}>
                          {moment(
                            row.createdAt ? row.createdAt : row.registered_on
                          ).format("YYYY/MM/DD hh:mm A")}
                        </TableCell>
                      ) : (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          style={{ fontWeight: "900 !important", color: "#666666" }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Showing Entries :"
          labelDisplayedRows={({ from, to, count }) =>
            `Showing ${from} to ${to} of ${count !== -1 ? count : to} entries`
          }
        />
      )}
    </>
  );
}
