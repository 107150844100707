import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { toast } from "react-toastify";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import TerminalActiveInactive from "./TerminalActiveInactive";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Menu from "@mui/material/Menu";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  AddAccount,
  getBankList,
  getBranchBankList,
} from "../../../networkRequest/request/bankaccount";
import { getTerminal } from "../../../networkRequest/request/branch";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material";
import Switch from "@mui/material/Switch";

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  background: ${theme?.palette?.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme?.palette?.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 5px;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme?.palette?.mode === "dark" ? grey[300] : grey[900]};

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 320px;
  background: ${theme?.palette?.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme?.palette?.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme?.palette?.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "5px",
  },
});

const Terminal = (props) => {
  const { rolePermission, currency } = props;
  const [age, setAge] = React.useState("");
  const [terminal, setTerminal] = React.useState([]);
  const [listTerminal, setListTerminal] = React.useState([]);
  const [phoneNumber, setPhoneNumber] = React.useState([]);
  const [innerFlag, setInnerFlag] = React.useState(false);
  const [bankaccountlist, setBankaccountList] = React.useState([]);
  const [branchBankAccount, setBranchBankAccount] = React.useState([]);
  const [paymentAccess, setPaymentAccess] = React.useState(false);
  const [invoiceAccess, setInvoiceAccess] = React.useState(false);
  const [staticPosPayment, setStaticPosPayment] = React.useState(false);
  const [splitPayment, setSplitPosPayment] = React.useState(false);
  const [seamlessPayment, setSeamlessPayment] = React.useState(false);
  const [eppPayment, setEppPayment] = React.useState(false);
  const search = useLocation().search;

  const customerId = new URLSearchParams(search).get("merchantId");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleChange = (event, index) => {
    let InnerTerminal = terminal;
    InnerTerminal[index].phoneNumber = event;
    setTerminal([...InnerTerminal]);

    setAge(event);
  };

  const updateBranchAccount = (banklist, branchbank) => {
    if (branchbank && banklist && branchbank.length !== 0) {
      for (let i = 0; i < branchbank.length; i++) {
        let fact = branchbank[i];
        let bankId = banklist.filter((innerfact) => {
          return innerfact._id == fact.selected_bank_id;
        });
        fact.Bankname = bankId.length !== 0 && bankId[0].name;
        if (i == branchbank.length - 1) {
          return branchbank;
        }
      }
    } else {
      return [];
    }
  };

  React.useEffect(() => {
    getBankData();
  }, []);

  const getBankData = async () => {
    let bankList = await getBankList();
    if (props.BranchId) {
      try {
        let branchAccount = await getBranchBankList(props.BranchId);
        let TerminalData = await getTerminal("Branch", props.BranchId, "");
        setListTerminal(TerminalData.data.data);
        setPhoneNumber(TerminalData.data.mobileNumberList);
        let branchAccountBank = await updateBranchAccount(
          bankList.data,
          branchAccount.data.data
        );
        setBranchBankAccount(branchAccountBank);
      } catch (error) {}
    }
    setBankaccountList(bankList.data);
  };

  React.useEffect(() => {
    props.UpdateTerminalData(terminal);
  }, [terminal]);

  const SelectBankAccount = (value, index) => {
    let InnerTerminal = terminal;
    InnerTerminal[index].bankAccountId = value;
    setTerminal([...InnerTerminal]);
  };

  const handlePhoneNumber = (pno, index) => {
    let InnerTerminal = terminal;
    InnerTerminal[index].useExistenceNumber =
      InnerTerminal[index].useExistenceNumber == "use_existing"
        ? "new_phone"
        : "use_existing";
    setTerminal([...InnerTerminal]);
  };

  const handleBankAccount = (bac, index) => {
    let InnerTerminal = terminal;
    InnerTerminal[index].useExistenceAccount =
      InnerTerminal[index].useExistenceAccount == "use_existing"
        ? "new_bank"
        : "use_existing";
    setTerminal([...InnerTerminal]);
  };

  const addTerminal = () => {
    let InnerTerminal = terminal;
    InnerTerminal.push({
      TerminalName: "",
      phoneNumber: "",
      bankAccountId: "",
      mobile_code: "",
      bankAccountDetails: {
        BankId: "",
        BankName: "",
        AccountHolderName: "",
        AccountNumber: "",
      },
      doneButton: true,
      useExistenceNumber: "use_existing",
      useExistenceAccount: "use_existing",
      access_to: [],
    });
    setTerminal([...InnerTerminal]);
  };

  const AddAccountData = async (index) => {
    try {
      let innerdata = terminal[index].bankAccountDetails;
      if (
        innerdata.AccountHolderName &&
        customerId &&
        props.BranchId &&
        innerdata.BankId &&
        innerdata.AccountNumber
      ) {
        if (
          innerdata.AccountNumber.length < 23 ||
          innerdata.AccountNumber[0] + innerdata.AccountNumber[1] !== "AE"
        ) {
          return toast(
            innerdata.AccountNumber.length < 23
              ? "The total length of the IBAN number should be 23 characters"
              : "Please double-check and enter a correct IBAN",
            {
              type: "error",
              autoClose: 2000,
            }
          );
        }
        let reqBody = {
          account_holders_name:
            terminal[index].bankAccountDetails.AccountHolderName,
          selected_bank_id: terminal[index].bankAccountDetails.BankId,
          ibann_number: terminal[index].bankAccountDetails.AccountNumber,
          merchant_id: customerId,
          branch_id: props.BranchId,
        };
        let AddData = await AddAccount(reqBody);
        if (AddData?.status !== 400) {
          AddData.data.Bankname = terminal[index].bankAccountDetails.BankName;
          branchBankAccount.push(AddData.data);
          setBranchBankAccount([...branchBankAccount]);
          let InnerTerminal = terminal;
          InnerTerminal[index].bankAccountId = AddData.data._id;
          InnerTerminal[index].doneButton = false;
          setTerminal([...InnerTerminal]);
          return toast("Account Added Successfully", {
            type: "success",
            autoClose: 2000,
          });
        } else {
          return toast(AddData?.data.message, {
            type: "error",
            autoClose: 2000,
          });
        }
      } else {
        return toast(
          !innerdata.AccountHolderName
            ? "Account Holder Name Cannot Be Empty"
            : !innerdata.BankId
            ? "Please select your bank name before entering your IBAN number"
            : "Please enter a valid IBAN number",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      }
    } catch (err) {
      return toast("Internal Server Error", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  const closeTerminal = (index) => {
    let InnerTerminal = terminal;
    InnerTerminal.splice(index, 1);
    setTerminal([...InnerTerminal]);
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e, row, index) => {
    let InnerTerminal = terminal;
    InnerTerminal[index].bankAccountDetails.BankId = row._id;
    InnerTerminal[index].bankAccountDetails.BankName = row.name;
    setTerminal([...InnerTerminal]);
    setAnchorEl(null);
  };

  const handleAccessTo = async (val, key, index) => {
    let access_to = [];
    if (key == "PAYMENTS") {
      val = !paymentAccess;
      setPaymentAccess(val);
      if (val) {
        access_to.push(key);
      }
    } else if (paymentAccess) {
      access_to.push("PAYMENTS");
    }

    if (key == "INVOICES") {
      val = !invoiceAccess;
      setInvoiceAccess(val);
      if (val) {
        access_to.push(key);
      }
    } else if (invoiceAccess) {
      access_to.push("INVOICES");
    }

    if (key == "STATIC_POS_PAYMENT") {
      val = !staticPosPayment;
      setStaticPosPayment(val);
      if (val) {
        access_to.push(key);
      }
    } else if (staticPosPayment) {
      access_to.push("STATIC_POS_PAYMENT");
    }

    if (key == "SPLIT_PAYMENT") {
      val = !splitPayment;
      setSplitPosPayment(val);
      if (val) {
        access_to.push(key);
      }
    } else if (splitPayment) {
      access_to.push("SPLIT_PAYMENT");
    }

    if (key == "SEAMLESS_PAYMENT") {
      val = !seamlessPayment;
      setSeamlessPayment(val);
      if (val) {
        access_to.push(key);
      }
    } else if (seamlessPayment) {
      access_to.push("SEAMLESS_PAYMENT");
    }

    if (key == "EPP_PAYMENT") {
      val = !eppPayment;
      setEppPayment(val);
      if (val) {
        access_to.push(key);
      }
    } else if (eppPayment) {
      access_to.push("EPP_PAYMENT");
    }

    let InnerTerminal = terminal;
    InnerTerminal[index].access_to = access_to;
    setTerminal([...InnerTerminal]);
  };

  return (
    <>
      <div>
        {props.AddBranch && (
          <TerminalActiveInactive
            currency={currency}
            rolePermission={rolePermission}
            merchantId={customerId}
            branchId={props.BranchId}
          />
        )}
      </div>
      <>
        {terminal.map((fact, index) => {
          return (
            <div className="add_terminal_main_div" key={index}>
              <div className="edit_branch_inpt_div ">
                <div className="add_terminal_cancel_icon">
                  <p>
                    <IconButton color="primary">
                      <img
                        style={{ height: "25px", width: "25px" }}
                        src="/Terminal-icon.svg"
                        alt=""
                      />
                    </IconButton>
                    Terminal Name <span style={{ color: "red" }}>*</span>
                  </p>
                  <IconButton
                    className="terminal_cancel"
                    color="primary"
                    onClick={() => {
                      closeTerminal(index);
                    }}
                  >
                    <img
                      style={{ height: "25px", width: "25px" }}
                      src="/Terminal-cancel-icon.svg"
                      alt=""
                    />
                  </IconButton>
                </div>
                <CssTextField
                  name="Terminal name"
                  className="merchant_edit_drower_serch_field"
                  id="new-menu"
                  placeholder="Terminal name"
                  onChange={(e) => {
                    let InnerTerminal = terminal;
                    InnerTerminal[index].TerminalName = e.target.value;
                    setTerminal([...InnerTerminal]);
                  }}
                  value={fact.TerminalName}
                  variant="outlined"
                  required
                  helperText={`${terminal[index].TerminalName.length}/${100}`}
                  inputProps={{
                    maxlength: 100,
                  }}
                />
              </div>
              <div className="terminal_add_phone_bank">
                <p>
                  Phone Number <span style={{ color: "red" }}>*</span>
                </p>
                <div className="terminal_add_phone_bank_radio_btn">
                  <RadioGroup
                    row
                    defaultValue={fact.useExistenceNumber}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => handlePhoneNumber(e.target.value, index)}
                  >
                    <div style={{ width: "50%" }}>
                      <FormControlLabel
                        value="use_existing"
                        control={<Radio />}
                        label="Use Existing"
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <FormControlLabel
                        value="new_phone"
                        control={<Radio />}
                        label="Add New Phone Number"
                      />
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ width: "50%" }}>
                        {fact.useExistenceNumber == "use_existing" ? (
                          <Select
                            id="demo-simple-select"
                            style={{ width: "100%" }}
                            placeholder="Select Number"
                            defaultValue={fact.phoneNumber}
                            onChange={(e) => handleChange(e, index)}
                          >
                            {phoneNumber.map((fact, index) => {
                              return <MenuItem value={fact}>{fact}</MenuItem>;
                            })}
                          </Select>
                        ) : (
                          " "
                        )}
                      </div>
                      <div style={{ width: "50%" }}>
                        {fact.useExistenceNumber == "new_phone" ? (
                          <PhoneInput
                            className="mobile-input"
                            name="phone"
                            country={currency == "INR" ? "in" : "ae"}
                            countryCodeEditable={true}
                            placeholder="Enter Phone Number"
                            required
                            value={fact.phoneNumber}
                            onChange={(e, countryCode) => {
                              let InnerTerminal = terminal;
                              InnerTerminal[index].mobile_code =
                                countryCode.dialCode;
                              InnerTerminal[index].phoneNumber = e;
                              setTerminal([...InnerTerminal]);
                            }}
                          />
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </RadioGroup>
                </div>
              </div>
              <div className="terminal_add_phone_bank">
                <p>
                  Bank Account <span style={{ color: "red" }}>*</span>
                </p>
                <div className="terminal_add_phone_bank_radio_btn">
                  <RadioGroup
                    row
                    defaultValue={fact.useExistenceAccount}
                    aria-labelledby="demo-row-radio-buttons-group-label1"
                    name="row-radio-buttons-group1"
                    onChange={(e) => handleBankAccount(e.target.value, index)}
                    sx={{ width: "100%" }}
                  >
                    <div style={{ width: "50%" }}>
                      <FormControlLabel
                        value="use_existing"
                        control={<Radio />}
                        label="Use Existing"
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <FormControlLabel
                        value="new_bank"
                        control={<Radio />}
                        label="Add New Bank Accounts"
                      />
                    </div>
                  </RadioGroup>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", minWidth: "50%" }}>
                    {fact.useExistenceAccount == "use_existing" && (
                      <>
                        <div className="bank_account">
                          <RadioGroup
                            defaultValue={""}
                            onChange={(e) =>
                              SelectBankAccount(e.target.value, index)
                            }
                            sx={{ width: "100%" }}
                          >
                            {branchBankAccount.map((fact, index) => {
                              return (
                                <FormControlLabel
                                  value={fact._id}
                                  control={<Radio />}
                                  label={
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          className="bank_icon"
                                          src="../user-icon.svg"
                                        />
                                        <div>
                                          <h6>{fact.Bankname}</h6>
                                          <p>{fact.ibann_number}</p>
                                        </div>
                                      </div>
                                    </>
                                  }
                                />
                              );
                            })}
                          </RadioGroup>
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <div>
                      {fact.useExistenceAccount == "new_bank" ? (
                        <>
                          <CssTextField
                            className=""
                            id="new-menu"
                            placeholder="Account Holder Name"
                            variant="outlined"
                            value={fact.bankAccountDetails.AccountHolderName}
                            onChange={(e) => {
                              let InnerTerminal = terminal;
                              InnerTerminal[
                                index
                              ].bankAccountDetails.AccountHolderName =
                                e.target.value;
                              setTerminal([...InnerTerminal]);
                            }}
                            fullWidth
                            sx={{ marginBottom: "10px" }}
                          />
                          <FormControl fullWidth>
                            <Autocomplete
                              id="custom-input-demo"
                              options={bankaccountlist}
                              autoHighlight
                              onChange={(e, newValue) =>
                                handleClose(e, newValue, index)
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                  <input
                                    style={{
                                      height: "36px",
                                      width: "98%",
                                      marginBottom: "10px",
                                      borderRadius: "3px",
                                      border: "1px solid #c4c4c4",
                                    }}
                                    type="text"
                                    placeholder="Select Your Bank"
                                    {...params.inputProps}
                                  />
                                </div>
                              )}
                            />
                          </FormControl>
                          <CssTextField
                            className=""
                            id="new-menu"
                            placeholder="IBAN Number"
                            value={fact.bankAccountDetails.AccountNumber}
                            onChange={(e) => {
                              let InnerTerminal = terminal;
                              InnerTerminal[
                                index
                              ].bankAccountDetails.AccountNumber =
                                e.target.value.replace(/[^0-9A-Za-z]/gi, "");
                              setInnerFlag(
                                e.target.value.replace(/[^0-9A-Za-z]/gi, "")
                                  .length !== 0
                                  ? true
                                  : false
                              );
                              setTerminal([...InnerTerminal]);
                            }}
                            inputProps={{
                              maxlength: 23,
                            }}
                            helperText={
                              terminal[index].bankAccountDetails.BankId ===
                                "" &&
                              innerFlag &&
                              `Please select your bank name before entering your IBAN number`
                            }
                            variant="outlined"
                            fullWidth
                            sx={{ marginBottom: "10px" }}
                          />
                          {fact.doneButton && (
                            <Button
                              className="terminal_done_btn"
                              variant="contained"
                              sx={{ marginBottom: "10px" }}
                              onClick={() => {
                                AddAccountData(index);
                              }}
                            >
                              Done
                            </Button>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="terminal_add_phone_bank"
                style={{ display: "flow-root" }}
              >
                <p>Access To</p>
                <div className="access_to_checkbox">
                  <label>Payments</label>
                  <IOSSwitch
                    sx={{ m: 1 }}
                    defaultChecked={paymentAccess ? true : false}
                    onChange={() => {
                      handleAccessTo(paymentAccess, "PAYMENTS", index);
                    }}
                  />
                </div>
                <div className="access_to_checkbox">
                  <label>Invoice</label>
                  <IOSSwitch
                    sx={{ m: 1 }}
                    defaultChecked={invoiceAccess ? true : false}
                    onChange={() => {
                      handleAccessTo(invoiceAccess, "INVOICES", index);
                    }}
                  />
                </div>
                <div className="access_to_checkbox">
                  <label>Static Payments</label>
                  <IOSSwitch
                    sx={{ m: 1 }}
                    defaultChecked={staticPosPayment ? true : false}
                    onChange={() => {
                      handleAccessTo(
                        staticPosPayment,
                        "STATIC_POS_PAYMENT",
                        index
                      );
                    }}
                  />
                </div>
                <div className="access_to_checkbox">
                  <label>Split Payments</label>
                  <IOSSwitch
                    sx={{ m: 1 }}
                    defaultChecked={splitPayment ? true : false}
                    onChange={() => {
                      handleAccessTo(splitPayment, "SPLIT_PAYMENT", index);
                    }}
                  />
                </div>
                <div className="access_to_checkbox">
                  <label>Seamless Payments</label>
                  <IOSSwitch
                    sx={{ m: 1 }}
                    defaultChecked={seamlessPayment ? true : false}
                    onChange={() => {
                      handleAccessTo(
                        seamlessPayment,
                        "SEAMLESS_PAYMENT",
                        index
                      );
                    }}
                  />
                </div>
                <div className="access_to_checkbox">
                  <label>EPP Payments</label>
                  <IOSSwitch
                    sx={{ m: 1 }}
                    defaultChecked={eppPayment ? true : false}
                    onChange={() => {
                      handleAccessTo(eppPayment, "EPP_PAYMENT", index);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </>
      <div className="edit_branch_drawer_add_terminal_main_div">
        {rolePermission.includes("ADD") && (
          <Button
            className="edit_branch_drawer_add_terminal"
            onClick={() => {
              addTerminal();
            }}
          >
            <IconButton>
              <AddCircleOutlineIcon
                sx={{ color: "#1058ff", borderRadius: "50%" }}
              />
            </IconButton>
            ADD TERMINAL
          </Button>
        )}
      </div>
    </>
  );
};

export default Terminal;
