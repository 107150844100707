import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const DecimalSettings = (props) => {
  const {
    rolePermission,
    allStatusCodeMapping,
    id,
    AddAcquirerProp,
    UpdateAcquirerProp,
  } = props;
  const [loader, setLoader] = React.useState(true);
  const [controlDisable, setControlDisable] = React.useState(false);
  const [allStatusData, setAllStatusData] = React.useState({});
  let month_days = [];

  for (let i = 0; i <= 30; i++) {
    month_days.push(i);
  }
  React.useEffect(() => {
    CommonCallFunction();
  }, [id, allStatusCodeMapping]);

  const CommonCallFunction = () => {
    id && setControlDisable(true);
    setAllStatusData(allStatusCodeMapping.decimal_mdr_format);
    setLoader(false);
  };

  const SaveCode = () => {
    if (
      allStatusData?.fixed_fees &&
      allStatusData?.margin &&
      allStatusData?.vat &&
      allStatusData?.settlement_amount &&
      allStatusData?.settlement_commission_amount
    ) {
      let data = {
        ...allStatusCodeMapping,
        decimal_mdr_format: allStatusData,
      };
      setLoader(true);
      UpdateAcquirerProp(data, data._id);
    } else {
      toast("Please fill all the mandatory details.", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };

  const AddCode = () => {
    if (
      allStatusData?.fixed_fees &&
      allStatusData?.margin &&
      allStatusData?.vat &&
      allStatusData?.settlement_amount &&
      allStatusData?.settlement_commission_amount
    ) {
      let data = {
        ...allStatusCodeMapping,
        decimal_mdr_format: allStatusData,
      };
      setLoader(true);
      AddAcquirerProp(data, 4);
    } else {
      toast("Please fill all the mandatory details.", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };

  const onChange = (e) => {
    const value = e.target.value;
    if (!value) {
      setAllStatusData({
        ...allStatusData,
        ...(allStatusData?.decimal_mdr_format || {}),
        [e.target.name]: "",
      });
      return true;
    }
    if (Number(value) > -1 && Number(value) < 10) {
      setAllStatusData({
        ...allStatusData,
        ...(allStatusData?.decimal_mdr_format || {}),
        [e.target.name]: parseInt(value),
      });
    }
  };

  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="OtherSettings">
          <Grid className="OtherSettingsRow" container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Fixed Fees <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Fixed Fees"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                name="fixed_fees"
                value={allStatusData?.fixed_fees}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Margin <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Margin"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                name="margin"
                value={allStatusData?.margin}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Vat <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Vat"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                name="vat"
                value={allStatusData?.vat}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Settlement Amount <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Settlement Amount"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                name="settlement_amount"
                value={allStatusData?.settlement_amount}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Settlement Commission Amount{" "}
                <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Settlement Commission Amount"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                name="settlement_commission_amount"
                value={allStatusData?.settlement_commission_amount}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          {!id ? (
            <Grid
              className="OtherSettingsRow2"
              container
              spacing={2}
              justifyContent="flex-end"
            >
              <Button
                onClick={AddCode}
                className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                style={{ marginRight: "29px" }}
                variant="contained"
              >
                Save
              </Button>
            </Grid>
          ) : (
            <Grid
              className="OtherSettingsRow2"
              container
              spacing={2}
              justifyContent="flex-end"
            >
              {!controlDisable ? (
                <>
                  <Button
                    onClick={SaveCode}
                    className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                    style={{ marginRight: "29px" }}
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      CommonCallFunction();
                      setControlDisable(true);
                    }}
                    className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  {rolePermission.includes("EDIT") && (
                    <Button
                      onClick={() => {
                        setControlDisable(false);
                      }}
                      className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                      style={{ marginRight: "29px" }}
                      variant="contained"
                    >
                      Edit
                    </Button>
                  )}
                </>
              )}
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

export default DecimalSettings;
