import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

const SectionD = (props) => {
  const margin = (props.data?.total_margin_amount/props.data?.total_actual_amount)*100;
  const vat = (props.data?.total_vat_amount/(props.data?.total_margin_amount + props.data?.total_fixed_fees_amount))*100;
  return (
    <>
      <div className="inner_pdf_sec">
        <h3 className="Settlement_breakdown_heading">SECTION D</h3>
        
        <div className="Settlement_pdf_sectionD_chargeback">
          <Button className="order_lst_btn">fees</Button>
          {/* <p>fees charged for all payments. learn more about fees</p> */}
        </div>
        <div className="settlement_view_pdf_tbl">
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row_pdf settlement_view_pdf_tbl_head">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Fees Currency</TableCell>
                  <TableCell>Margin</TableCell>
                  <TableCell>VAT</TableCell>
                  <TableCell>Refund Charges</TableCell>
                  <TableCell>Settlement Fees</TableCell>
                  <TableCell>Fixed Fees</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row_pdf ">
                <TableCell>{props.data?.currency}</TableCell>
                <TableCell>{props.data?.total_margin_amount}</TableCell>
                <TableCell>{props.data?.total_vat_amount}</TableCell>
                <TableCell>{props.data?.refund_charges_amount}</TableCell>
                <TableCell>{props.data?.settlement_fees}</TableCell>
                <TableCell>{props.data?.total_fixed_fees_amount}</TableCell>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default SectionD;
