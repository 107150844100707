import React from 'react';
import SectionA from "./SectionA";
import SectionD from "./SectionD";
import SectionC from "./SectionC";
import SectionF from "./SectionF";
import SectionE from "./SectionE";
import SectionB from "./SectionB";
import SettlementStatement from "./SettlementStatement";

const PdfSection =  React.forwardRef((props, ref) => {
  return (
    <>
    <div ref={ref} className="pdf-section">
      <table>
        <thead><tr><td>
          <div class="header-space"> </div>
        </td></tr></thead>
        <tbody><tr><td>
          <div class="content">
            <SettlementStatement id="settlement-section" data={props?.data} contactLink={props?.contactLink} appLink={props?.appLink}/>
            <SectionA id="section-A" data={props?.data} contactLink={props?.contactLink} appLink={props?.appLink}/>
            <SectionB id="section-B" data={props?.data} contactLink={props?.contactLink} appLink={props?.appLink}/>
            <SectionC id="section-C" data={props?.data} contactLink={props?.contactLink} appLink={props?.appLink}/>
            <SectionD id="section-D" data={props?.data} contactLink={props?.contactLink} appLink={props?.appLink}/>
            <SectionE id="section-E" data={props?.data} contactLink={props?.contactLink} appLink={props?.appLink}/>
            {/* <SectionF data={props?.data}/> */}
          </div>
        </td></tr></tbody>
        <tfoot><tr><td>
          <div class="footer-space"> </div>
        </td></tr></tfoot>
      </table>
    </div>
    </>
  );
})

export default PdfSection