import React, { useEffect, useState } from "react";
import FirstSection from "./first-section";
import SecondSection from "./second-section";
import ThirdSection from "./third-section";
import FourthSection from "./fourth-section";
import BusinessSection from "./business-section";
import Filter from "./Filter";
import AxiosBase from "../../networkRequest/axiosBase";
import "./index.scss";
import moment from "moment";
export default function DashboardMain(props) {
  const [filter, setFilter] = useState({
    dateRange: "Today",
    from_date: moment().format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    currency: "AED",
    country: "ARE",
  });
  const [data, setData] = useState({});
  const [kycCount, setKycCount] = useState(0);
  const [premiumCount, setPremiumCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    getData(
      filter.dateRange,
      filter.from_date,
      filter.to_date,
      filter.currency,
      filter.country
    );
  }, []);

  const handledates = (values) => {
    const from_date = moment(values.startDate).format("YYYY-MM-DD");
    const to_date = moment(values.endDate).format("YYYY-MM-DD");
    getData(
      filter.dateRange,
      from_date,
      to_date,
      filter.currency,
      filter.country
    );
    setDate(values);
  };

  const handleClear = (value) => {
    if (value == "dateRange") {
      setFilter({
        ...filter,
        dateRange: "Today",
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      });
      const filterDate = moment().format("YYYY-MM-DD");
      getData("Today", filterDate, filterDate, filter.currency, filter.country);
      return true;
    }
    if (value == "currency") {
      setFilter({
        ...filter,
        currency: "AED",
      });
      getData(
        filter.dateRange,
        filter.from_date,
        filter.to_date,
        "AED",
        filter.country
      );
      return true;
    }
    if (value == "country") {
      setFilter({
        ...filter,
        country: "ARE",
      });
      getData(
        filter.dateRange,
        filter.from_date,
        filter.to_date,
        filter.currency,
        ""
      );
      return true;
    }
  };
  const handleFilter = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDate({
      startDate: "",
      endDate: "",
    });
    if (name == "dateRange") {
      if (value == "Custom") {
        setFilter({
          ...filter,
          dateRange: value,
          from_date: filter.from_date,
          to_date: filter.to_date,
        });
        return true;
      }
      const { from_date, to_date } = getDateRange(value);
      getData(value, from_date, to_date, filter.currency, filter.country);
      setFilter({
        ...filter,
        dateRange: value,
        from_date,
        to_date,
      });
      return true;
    }
    if (name == "currency") {
      getData(
        filter.dateRange,
        filter.from_date,
        filter.to_date,
        value,
        filter.country
      );
    }
    if (name == "country") {
      getData(
        filter.dateRange,
        filter.from_date,
        filter.to_date,
        filter.currency,
        value
      );
    }
    setFilter({
      ...filter,
      [name]: value,
    });
    return true;
  };

  const getData = (dateRange, from_date, to_date, currency, country) => {
    getDashboardData(dateRange, from_date, to_date, currency, country);
    getKycData(dateRange, from_date, to_date, currency, country);
    gePremiumData(dateRange, from_date, to_date, currency, country);
  };

  const getDateRange = (value) => {
    let from_date = moment().format("YYYY-MM-DD");
    let to_date = moment().format("YYYY-MM-DD");
    if (value == "Today") {
      from_date = moment().format("YYYY-MM-DD");
      to_date = moment().format("YYYY-MM-DD");
    } else if (value == "Yesterday") {
      from_date = moment().subtract(1, "day").format("YYYY-MM-DD");
      to_date = moment().subtract(1, "day").format("YYYY-MM-DD");
    } else if (value == "This Week") {
      from_date = moment().startOf("isoWeek").format("YYYY-MM-DD");
      to_date = moment().endOf("isoWeek").format("YYYY-MM-DD");
    } else if (value == "This Month") {
      from_date = moment().startOf("month").format("YYYY-MM-DD");
      to_date = moment().endOf("month").format("YYYY-MM-DD");
    } else if (value == "This Year") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    } else if (value == "Custom") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    }
    return { from_date, to_date };
  };

  const getDashboardData = async (
    dateRange,
    from_date,
    to_date,
    currency,
    country
  ) => {
    try {
      const res = await AxiosBase.get(
        `/admin/dashboard/transaction?dateRange=${dateRange}&from_date=${from_date}&to_date=${to_date}&currency=${currency}&country=${country}`
      );
      setData(res.data);
      setLoader(false);
    } catch (error) {
      setData({});
    }
  };

  const getKycData = async (
    dateRange,
    from_date,
    to_date,
    currency,
    country
  ) => {
    try {
      const res = await AxiosBase.get(
        `/admin/dashboard/kyc-approved-count?dateRange=${dateRange}&from_date=${from_date}&to_date=${to_date}&currency=${currency}&country=${country}`
      );
      setKycCount(res.data.data);
      setLoader(false);
    } catch (error) {
      setData({});
    }
  };

  const gePremiumData = async (
    dateRange,
    from_date,
    to_date,
    currency,
    country
  ) => {
    try {
      const res = await AxiosBase.get(
        `/admin/dashboard/premium-purchase-count?dateRange=${dateRange}&from_date=${from_date}&to_date=${to_date}&currency=${currency}&country=${country}`
      );
      setPremiumCount(res.data.data);
      setLoader(false);
    } catch (error) {
      setData({});
    }
  };

  // if (loader) {
  //   return <h2>Loading...</h2>;
  // }

  return (
    <div>
      <h1 className="merchant_title">Dashboard</h1>
      <div className="dashboard-section">
        <div className="dashboard-section-main">
          <Filter
            handleFilter={handleFilter}
            handleClear={handleClear}
            filter={filter}
            handledates={handledates}
          />
          <FirstSection data={data} filter={filter} />
          <SecondSection data={data} filter={filter} />
        </div>
        <div className="dashboard-section-main">
          <BusinessSection
            data={data}
            filter={filter}
            kycCount={kycCount}
            premiumCount={premiumCount}
          />
        </div>
        <div className="dashboard-section-main">
          <ThirdSection data={data} filter={filter} />
        </div>
        <div className="dashboard-section-main">
          <FourthSection data={data} filter={filter} />
        </div>
      </div>
    </div>
  );
}
