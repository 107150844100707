export const AUTH_START = "AUTH_START";
export const AUTH_RESTART = "AUTH_RESTART";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_UPDATE = "AUTH_UPDATE";
export const RESET_ERROR = "RESET_ERROR";
export const AUTH_UPDATE_TOKEN = "AUTH_UPDATE_TOKEN";
export const SESSION_WARNING = "SESSION_WARNING";
export const LOCKED_OUT = "LOCKED_OUT";
