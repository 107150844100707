import React from 'react'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import moment from "moment";

const SectionA =  React.forwardRef((props) => {
  return (
    <>
      <img
        src="/pdf_top_sec.svg"
        alt=""
        className="settlement_pdf_templete header-img"
      />
      <div className="inner_pdf_sec">
        <h3 className="Settlement_breakdown_heading">Settlement BREAKDOWN</h3>
        <div className="Settlement_date">
          <p className="first-txt">STATEMENT DATE:</p>
          <p className="second-txt">{moment(props.data?.createdAt).format("DD/MM/YYYY")}</p>
        </div>
        <div className="Settlement_date">
          <p className="first-txt">STATEMENT PERIOD:</p>
          <p className="second-txt">{moment(props.data?.from_date).format("DD/MM/YYYY")} to {moment(props.data?.to_date).format("DD/MM/YYYY")}</p>
        </div>
        <h3 className="Settlement_breakdown_heading">SECTION A</h3>
        <div>
          <Button className="order_lst_btn">ORDER LIST</Button>
        </div>
        <div className="settlement_view_pdf_tbl">
          <TableContainer>
            <Table className='order-list-table' stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row_pdf settlement_view_pdf_tbl_head">
                <TableRow style={{ background: "none" }}>
                  <TableCell className='order-id-cell'>Order Id</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Order Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>date</TableCell>
                  <TableCell>UL Fixed</TableCell>
                  <TableCell>UL Margin</TableCell>
                  <TableCell>VAT</TableCell>
                  <TableCell>Total Amount Payable</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              {props.data?.transactionData.map((transaction) => (
                <TableBody className="data_tbl_body_row_pdf ">
                  <TableCell>{transaction?.order_id}</TableCell>
                  <TableCell>{transaction?.currency}</TableCell>
                  <TableCell>{transaction?.amount}</TableCell>
                  <TableCell>{transaction?.status}</TableCell>
                  <TableCell>{moment(transaction?.completed).format("DD/MM/YYYY")}</TableCell>
                  <TableCell>{transaction?.fixed_fees}</TableCell>
                  <TableCell>{transaction?.margin_amount}</TableCell>
                  <TableCell>{transaction?.vatAmount}</TableCell>
                  <TableCell>{transaction?.settlement_amount}</TableCell>
                  <TableCell>{transaction?.capture_amount}</TableCell>
                </TableBody>   
              ))}
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
})

export default SectionA