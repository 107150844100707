import AxiosBase from "../axiosBase";

export const getMerchantFees = (customerId) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/admin/customers/config/${customerId}`).then((respFees) => {
      resolve(respFees);
    })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  })
}
export const updateMerchantFees = (reqBody) => {
  return new Promise((resolve, reject) => {
      AxiosBase.patch(
          `/admin/customers/config/`+reqBody._id,reqBody
        ).then((response) => {
          resolve(response);
      }).catch((err) => {
          console.log("error", err);
          resolve(err);
        });
  })
}