import React from 'react';
import Welcome from './welcome';
import MainDataLayout from './main-data-layout';

function CustomersHome() {
  return (
    <div>
      <Welcome/>
      <MainDataLayout/>
    </div>
  )
}

export default CustomersHome