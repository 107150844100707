import React, { useEffect } from "react";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import TablePagination from "@mui/material/TablePagination";
import { getAllUsers } from "../../../networkRequest/request/useraccesscontrol";
import Grid from "@mui/material/Grid";
import "./index.css";
import Filter from "../../filter-component/Filter";
import CircularProgress from "@mui/material/CircularProgress";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import NavbarHoc from "../../../pages/navhoc/index";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import Config from "../../../config.json";

import { useNavigate } from "react-router-dom";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});
function dynamicSort(property) {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    if (sortOrder == -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
}
const UserList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearch] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [searchBar, setSearchBar] = React.useState("");
  const [rolePermission, setRolePermission] = React.useState([]);
  const history = useNavigate();
  const [filter, setFilter] = React.useState([
    { key: "", type: "", value: "" },
  ]);
  const filterOptons = [
    { property: "Name", value: "full_name" },
    { property: "Email", value: "email_id" },
    { property: "Roles", value: "roleData.role_name" },
    { property: "Status", value: "status" },
  ];
  const filterContains = [
    { property: "Is", value: "is" },
    { property: "Like", value: "contain" },
    { property: "Not Like", value: "not_contain" },
  ];
  const AnotherOptions = [
    { property: "Active", value: "Active" },
    { property: "InActive", value: "InActive" },
  ];

  useEffect(() => {
    let shareString = "";
    let innerFilter = filter;
    let SearchIndex = 0;
    for (let i = 0; i < filter.length; i++) {
      if (filter[i].key == "status") {
        if (filter[i].value == "Active") {
          shareString += "is_active=true";
          // delete filter[i];
        } else {
          shareString += "is_active=false";
          //
        }
        SearchIndex = i;
      }
    }
    shareString = shareString !== "" ? shareString + "&" : "";
    const filtersval =
      innerFilter.length !== 0 && innerFilter[0].key != ""
        ? JSON.stringify(innerFilter)
        : "";
    getAllUsers(
      page,
      shareString,
      rowsPerPage,
      "_id",
      "desc",
      searchBy,
      filtersval,
      SearchIndex
    ).then((userlist) => {
      userlist.data.data.sort(dynamicSort("first_name"));
      setData(userlist.data.data);
      setCount(userlist.data.count);
      setLoading(false);
    });
  }, [page, rowsPerPage, count, searchBy, filter]);

  const handleSearch = (event) => {
    if (event.key === "Enter" || event.key === undefined) {
      setSearch(searchBar);
      setPage(0);
      setFilter([{ key: "", type: "", value: "" }]);
    }
  };

  const ResetFilter = async (event) => {
    setFilter([{ key: "", type: "", value: "" }]);
  };
  const [anchorE2, setAnchorE2] = React.useState(null);
  const handleFilter = async (JSONfilter) => {
    setFilter(JSONfilter);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const pophandleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const pophandleClose = () => {
    setAnchorE2(null);
  };

  const redirecttodetail = (_id) => {
    history(`/users/information/${_id}`);
  };

  const openpop = Boolean(anchorE2);
  const id1 = openpop ? "simple-popover" : undefined;
  return (
    <NavbarHoc
      id={Config.PermissionId.Users}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">User List</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter innerFilterUser">
              <Grid
                item
                xs={12}
                // sm={12}
                md={8}
                lg={6}
                // xl={4}
                className="merchant_grid_sec"
              >
                <div
                  className="merchant_list_filter_div"
                  style={{ height: "100%", width: "auto" }}
                >
                  {/* 
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  placeholder="Search"
                  variant="outlined"
                  fullWidth
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton style={{marginLeft:'0px !important'}} className="merchant_search_icon">
                          <img style={{marginLeft:"-26px"}}  src="/search.svg" alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                /> */}
                  <CssTextField
                    className="serachBar"
                    id="new-menu"
                    placeholder="Search"
                    variant="outlined"
                    // onChange={(e)=>{setSearchBar(e.target.value)}}
                    // fullWidth="false"
                    onChange={(e) => {
                      setSearchBar(e.target.value);
                    }}
                    onKeyUp={handleSearch}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton className="merchant_search_icon">
                            <i
                              onClick={handleSearch}
                              class="fa fa-search"
                              aria-hidden="true"
                            ></i>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <img
                    className="merchant_list_filter_img"
                    src="/Filter.svg"
                    aria-describedby={id1}
                    alt=""
                    onClick={pophandleClick}
                  /> */}

                  <Tooltip title="Filter records">
                    <div>
                      <i
                        style={{
                          fontSize: "30px",
                          marginLeft: "10px",
                          color: "#757575",
                          cursor: "pointer",
                        }}
                        onClick={pophandleClick}
                        class="fa fa-filter"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Tooltip>

                  {filter[0].key != "" && (
                    <Tooltip title="Refresh to records">
                      <div>
                        <i
                          style={{
                            fontSize: "30px",
                            marginLeft: "10px",
                            color: "#757575",
                            cursor: "pointer",
                          }}
                          onClick={ResetFilter}
                          class="fa fa-refresh"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={4}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className="merchant_grid_sec"
                style={{ display: "contents" }}
              >
                {rolePermission.includes("ADD") && (
                  <Link to={`/users/information`}>
                    <Button
                      className="merchant_list_add_new_merchant"
                      variant="contained"
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                    >
                      Add User
                    </Button>
                  </Link>
                )}
              </Grid>
            </div>
          </div>
          <TableContainer
            className={
              data.length > 0 && !loading
                ? "table_container"
                : "table_container table_container_nodata"
            }
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>User ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Created at</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              {!loading ? (
                <TableBody className="data_tbl_body_row">
                  {data?.map((curElm) => {
                    const {
                      _id,
                      full_name,
                      email_id,
                      roleData,
                      is_invited,
                      createdAt,
                      is_active,
                    } = curElm;
                    let RolesData =
                      roleData.length !== 0
                        ? roleData
                            .reduce((a, b) => (a += b.role_name + ","), "")
                            .replace(/,\s*$/, "")
                        : "NA";
                    return (
                      <TableRow
                        onClick={(e) =>
                          (rolePermission.includes("EDIT") ||
                            rolePermission.includes("VIEW")) &&
                          redirecttodetail(_id)
                        }
                        className=" tbl_id merchant_data_tbl_row merchant_tbl_edit_icon"
                      >
                        <TableCell>{_id}</TableCell>

                        <TableCell>
                          {full_name?.length > 15 ? (
                            <Tooltip title={full_name}>
                              <Button className="tbl_id">
                                {checklenght(full_name, 15)}
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button className="tbl_id">
                              {checklenght(full_name, 15)}
                            </Button>
                          )}
                        </TableCell>
                        <TableCell>
                          {email_id?.length > 15 ? (
                            <Tooltip title={email_id}>
                              <Button className="tbl_id">
                                {checklenght(email_id, 15)}
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button className="tbl_id">
                              {checklenght(email_id, 15)}
                            </Button>
                          )}
                        </TableCell>
                        <TableCell>
                          {RolesData?.length > 25 ? (
                            <Tooltip title={RolesData}>
                              <Button className="tbl_id">
                                {checklenght(RolesData, 25)}
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button className="tbl_id">
                              {checklenght(RolesData, 25)}
                            </Button>
                          )}
                        </TableCell>

                        <TableCell>
                          {moment(createdAt).format("YYYY/MM/DD hh:mm A")}
                          {/* {moment(createdAt).utc().format("YYYY/MM/DD hh:mm A")} */}
                        </TableCell>
                        <TableCell>
                          {is_active ? "Active" : "Inactive"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <></>
              )}
            </Table>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress style={{ color: "#1058ff" }} />
              </div>
            ) : data == "" ? (
              <div className="no_data_found loader_data_tbl">
                <p>No Data Found</p>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          {!loading ? (
            <TablePagination
              className="table_pagination"
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </div>
        <Popover
          id={id1}
          open={openpop}
          anchorEl={anchorE2}
          onClose={pophandleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ marginLeft: "135px", height: "500px", marginTop: "10px" }}
        >
          <Typography sx={{ p: 2 }}>
            <Filter
              filter1={handleFilter}
              pophandleClose={pophandleClose}
              optionns={filterOptons}
              contains={filterContains}
              AnotherOptions={AnotherOptions}
              filter={filter}
            />
          </Typography>
        </Popover>
      </div>
    </NavbarHoc>
  );
};

function checklenght(val, lenght) {
  if (val?.length > lenght) {
    return val?.substring(0, lenght) + "..";
  }
  if (!val) {
    return "NA";
  }
  return val;
}

export default UserList;
