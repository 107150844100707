import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/bootstrap.css";
import "react18-json-view/src/style.css";
import CustomersMain from "./pages/customers";
import KycMain from "./pages/kyc";
import SuperMerchants from "./pages/merchants";
import SettlementsPage from "./pages/settlements";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Resetpassword from "./pages/resetpassword";
import Orders from "./pages/orders";
import PremiumOrderRout from "./pages/premium-order/PremiumOrder";
import UsersMain from "./pages/users";
import RoleList1 from "./pages/rolematser";
import MenuListPage from "./pages/menu";
import Fileupload from "./universal-components/fileupload";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import reduxStore from "./redux/stores";
import ProtectedRoutes from "./page-components/ProtectedRoutes";
// import Supermerchant from "./page-components/merchants/CreateMerchants/index";
import Merchant from "./pages/merchant/Index";
import UserList from "./pages/userList/index";
import ConfigurationMain from "./pages/configuration-page/index";
import AccessControlRout from "./pages/access-control/AccessControl";
import AcquirerMain from "./pages/acquirer-page/index";
import Statusmaster from "./pages/statusmaster";
import RefundRouts from "./pages/refund/RefundRouts";
import AcquirerListMain from "./pages/acquirerlist";
import ReportsPage from "./pages/reports";
import Warning from "./Components/warning";
import LockedOut from "./Components/locked";
import Timeout from "./Components/Timeout";
import { useEffect, useState } from "react";
import { logoutUser } from "./redux/actions/auth";
import AxiosBase from "./networkRequest/axiosBase";
import PosRequest from "./pages/pos-request";
import NotFound from "./Components/error/NotFound";

const theme = createTheme({
  typography: {
    fontFamily: "'Whitney', sans-serif",
  },
  // components: {
  //   MuiCssBaseline: {
  //     styleOverrides: `
  //       @font-face {
  //         font-family: 'Raleway';
  //         font-style: normal;
  //         font-display: swap;
  //         font-weight: 400;
  //         src: local('Raleway'), local('Raleway-Regular'), url(${RalewayWoff2}) format('woff2');
  //         unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  //       }
  //     `,
  //   },
  // },
});

function App() {
  const [loading, setLoading] = useState(true);
  const { store, persistor } = reduxStore();

  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = async () => {
    try {
      let authData = store.getState();
      if (authData?.auth?.refreshToken) {
        const resp = await AxiosBase.post(`/admin/refresh-login-check`, {
          token: authData?.auth?.refreshToken,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (
        error?.response?.status === 444 &&
        error.response.data.message === "Second login active"
      ) {
        store.dispatch(logoutUser("/warning", "Timeout"));
      }
      store.dispatch(logoutUser("/timeout", "Timeout"));
    }
  };

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img src={"/loader.gif"} style={{ width: 90 }} />
      </div>
    );
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <ToastContainer hideProgressBar={true} autoClose={3000} />
            <Router>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <>
                      <Navigate to="/login" />
                    </>
                  }
                />
                <Route path="login" element={<Login />} />
                <Route path="warning" element={<Warning />} />
                <Route path="locked" element={<LockedOut />} />
                <Route path="timeout" element={<Timeout />} />

                <Route path="reset" element={<Resetpassword />} />
                <Route element={<ProtectedRoutes setLoading={setLoading} />}>
                  <Route path="accesscontrol" element={<AccessControlRout />} />
                  <Route path="customers/*" element={<CustomersMain />} />
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="kyc/*" element={<KycMain />} />
                  <Route path="merchants/*" element={<Merchant />} />
                  <Route path="merchant-customer/*" element={<UserList />} />
                  <Route path="reports" element={<ReportsPage />} />
                  <Route path="supermerchant/*" element={<SuperMerchants />} />
                  <Route path="orders" element={<Orders />} />
                  <Route path="premiumorders" element={<PremiumOrderRout />} />
                  <Route path="settlements/*" element={<SettlementsPage />} />
                  <Route path="users/*" element={<UsersMain />} />
                  <Route path="role/*" element={<RoleList1 />} />
                  <Route path="menu/*" element={<MenuListPage />} />
                  <Route path="refund" element={<RefundRouts />} />
                  <Route
                    path="configuration/*"
                    element={<ConfigurationMain />}
                  />
                  <Route path="acquirer/*" element={<AcquirerMain />} />
                  <Route path="acquirerlist/*" element={<AcquirerListMain />} />
                  <Route path="statuscode" element={<Statusmaster />} />
                  <Route path="fileupload" element={<Fileupload />} />
                  <Route path="pos-request/*" element={<PosRequest />} />
                </Route>

                <Route path="404" exact={true} render={<NotFound />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Router>
          </div>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
