import React from "react";
import { useState, useEffect } from "react";
import "./MOPSettings.css";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const MCssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
    background: "white",
  },
});

const MOPSettings = (props) => {
  const {
    rolePermission,
    allStatusCodeMapping,
    id,
    AddAcquirerProp,
    UpdateAcquirerProp,
  } = props;
  const [loader, setLoader] = React.useState(false);
  const [controlDisable, setControlDisable] = React.useState(false);
  const [allStatusData, setAllStatusData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [innerLoader, setInnerLoader] = React.useState(false);
  const [addCode, setAddCode] = React.useState({
    service: "",
    acode: "",
    desc: "",
  });

  React.useEffect(() => {
    CommonCallFunction();
  }, [id, allStatusCodeMapping]);

  const CommonCallFunction = () => {
    id && setControlDisable(true);
    setAllStatusData(JSON.parse(JSON.stringify(allStatusCodeMapping)));
    setLoader(false);
    setInnerLoader(false);
    setOpen(false);
    setAddCode({
      SERVICE: "",
      code: "",
      desc: "",
    });
  };

  const CancelModal = () => {
    setOpen(false);
    setAddCode({
      service: "",
      code: "",
      desc: "",
    });
  };

  const SaveModal = () => {
    if (addCode?.service !== "") {
      setInnerLoader(true);
      let data = allStatusCodeMapping;
      data.mops.push(addCode);
      UpdateAcquirerProp(data, data._id);
    } else {
      toast("Service name Are Mandatory.", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };

  const SaveCode = () => {
    setLoader(true);
    let data = allStatusData;
    UpdateAcquirerProp(data, data._id);
  };

  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="MOPSettingMain">
          <Grid
            className="MOPSettingMapping"
            container
            spacing={2}
            justifyContent="flex-end"
          >
            {rolePermission.includes("ADD") && (
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                className="merchant_list_add_new_merchant"
                style={{ marginRight: "29px" }}
                variant="contained"
              >
                Add
              </Button>
            )}
          </Grid>
          <TableContainer
            component={Paper}
            style={{ border: "1px solid #e0e0e0" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Service name
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: "bold",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    Acquirer MOP Code
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allStatusData?.mops?.map((row, index) => (
                  <TableRow
                    key={row.Service}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{ width: "26%", borderRight: "1px solid #e0e0e0" }}
                      component="th"
                    >
                      <CssTextField
                        className=""
                        id="new-menu"
                        placeholder="Service name"
                        variant="outlined"
                        fullWidth
                        disabled={true}
                        value={row.service}
                        onChange={(e) => {
                          let data = allStatusData;
                          data.mops[index].service = e.target.value;
                          setAllStatusData({ ...data });
                        }}
                        style={{ background: "#eeeef6", borderRadius: "4px" }}
                      />
                    </TableCell>
                    <TableCell style={{ borderRight: "1px solid #e0e0e0" }}>
                      <CssTextField
                        className=""
                        id="new-menu"
                        placeholder="Acquirer MOP Code"
                        variant="outlined"
                        fullWidth
                        disabled={controlDisable}
                        value={row.code}
                        onChange={(e) => {
                          let data = allStatusData;
                          data.mops[index].code = e.target.value;
                          setAllStatusData({ ...data });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CssTextField
                        className=""
                        id="new-menu"
                        placeholder="Description"
                        variant="outlined"
                        fullWidth
                        disabled={controlDisable}
                        value={row.desc}
                        onChange={(e) => {
                          let data = allStatusData;
                          data.mops[index].desc = e.target.value;
                          setAllStatusData({ ...data });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {allStatusData?.mops?.length !== 0 && (
            <>
              {!id ? (
                <Grid
                  className="BasicMopSetting"
                  container
                  spacing={2}
                  justifyContent="flex-end"
                >
                  <Button
                    className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                    style={{ marginRight: "29px" }}
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button
                    className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Grid>
              ) : (
                <Grid
                  className="BasicMopSetting"
                  container
                  spacing={2}
                  justifyContent="flex-end"
                >
                  {!controlDisable ? (
                    <>
                      <Button
                        onClick={SaveCode}
                        className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                        style={{ marginRight: "29px" }}
                        variant="contained"
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          CommonCallFunction();
                          setControlDisable(true);
                        }}
                        className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      {rolePermission.includes("EDIT") && (
                        <Button
                          onClick={() => {
                            setControlDisable(false);
                          }}
                          className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                          style={{ marginRight: "29px" }}
                          variant="contained"
                        >
                          Edit
                        </Button>
                      )}
                    </>
                  )}
                </Grid>
              )}
            </>
          )}

          <Modal open={open} onClose={CancelModal}>
            <div className="ModalStatusMapping">
              <div className="ModalHeader">MOP Setting</div>
              {innerLoader ? (
                <div className="no_data_found loader_data_tbl">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div className="ModalBody">
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      className=""
                      style={{ marginTop: "-7%" }}
                    >
                      <p className="ModalTitle">
                        Service name <span style={{ color: "red" }}>*</span>
                      </p>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <MCssTextField
                          className=""
                          id="new-menu"
                          placeholder="Service name"
                          variant="outlined"
                          fullWidth
                          value={addCode?.aq_code}
                          onChange={(e) => {
                            setAddCode({ ...addCode, service: e.target.value });
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
                      <p className="ModalTitle">Code</p>
                      <MCssTextField
                        className=""
                        id="new-menu"
                        placeholder="Code"
                        variant="outlined"
                        fullWidth
                        value={addCode?.aq_code}
                        onChange={(e) => {
                          setAddCode({ ...addCode, code: e.target.value });
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
                      <p className="ModalTitle">Description</p>
                      <MCssTextField
                        className=""
                        id="new-menu"
                        placeholder="Description"
                        variant="outlined"
                        fullWidth
                        value={addCode?.desc}
                        onChange={(e) => {
                          setAddCode({ ...addCode, desc: e.target.value });
                        }}
                      />
                    </Grid>
                  </div>
                  <div className="ModalFooter">
                    <Button
                      onClick={SaveModal}
                      className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                      style={{ marginRight: "14px" }}
                      variant="contained"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={CancelModal}
                      className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default MOPSettings;
