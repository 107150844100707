import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./ConfigurationTab.css";
import Gateway from "./gateway/Gateway";
import FeesAndMargin from "./fees-and-margin/FeesAndMargin";
import FraudAndRisk from "./Fraud-and-Risk/FraudAndRisk";
import MerchantOnBoarding from "./merchant-onboarding/MerchantOnBoarding";
import PremiumPlan from "./Premium-Plan/PremiumPlan";
import Settlement from "./Settlements/Settlement";
import TDRSettings from "./TDRSettings/TDRSettings";
import CronJob from "./CronJob/CronJob";
import Kyc from "./Kyc/Kyc";
import Country from "./Country/Country";
import { useNavigate } from "react-router-dom";
import NavbarHoc from "../../pages/navhoc/index";
import Config from "../../config.json";
import AxiosBase from "../../networkRequest/axiosBase";
import { toast } from "react-toastify";
import Custodian from "./Custodian";
import { connect } from "react-redux";
import MerchantPanelMenus from "./MerchantPanelMenus";
import Banks from "./Banks";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: "15px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ConfigurationTab = (props) => {
  const [rolePermission, setRolePermission] = React.useState([]);
  const [allPermission, setAllPermission] = React.useState([]);
  const [spinner, setSpinner] = useState(false);
  const history = useNavigate();
  const redirecttodetail = (_id) => {
    history(`?id=${_id}`);
  };

  useEffect(() => {}, []);

  return (
    <NavbarHoc
      id={Config.PermissionId.Configuration}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
        setAllPermission(e.AllData);
      }}
    >
      <Box>
        <h1 className="merchant_title">Configuration</h1>
      </Box>
      <div className="merchant_list_main_div configuration_tab_div">
        <Box
          className="configuration_tab"
          sx={{ borderColor: "divider", ml: "-15px" }}
        >
          <Tabs
            value={props.value}
            // onChange={props.handleChange}
            aria-label="basic tabs example"
          >
            {allPermission[Config.PermissionId.FeesandMargin] && (
              <Tab
                id="1"
                label="MDR Settings"
                onClick={() => redirecttodetail(0)}
                {...a11yProps(0)}
              />
            )}
            {allPermission[Config.PermissionId.FraudandRisk] && (
              <Tab
                id="2"
                label="Fraud and Risk"
                onClick={() => redirecttodetail(1)}
                {...a11yProps(1)}
              />
            )}
            {allPermission[Config.PermissionId.MerchantOnboarding] && (
              <Tab
                id="3"
                label="App Setting"
                onClick={() => redirecttodetail(2)}
                {...a11yProps(2)}
              />
            )}
            {allPermission[Config.PermissionId.PremiumPlan] && (
              <Tab
                id="4"
                label="Subscription"
                onClick={() => redirecttodetail(3)}
                {...a11yProps(3)}
              />
            )}
            {allPermission[Config.PermissionId.Gateway] && (
              <Tab
                id="5"
                label="Settlement"
                onClick={() => redirecttodetail(4)}
                {...a11yProps(4)}
              />
            )}
            {allPermission[Config.PermissionId.CronJob] && (
              <Tab
                id="6"
                label="Cron Job"
                onClick={() => redirecttodetail(5)}
                {...a11yProps(5)}
              />
            )}
            {allPermission[Config.PermissionId.KycMaster] && (
              <Tab
                id="7"
                label="Kyc"
                onClick={() => redirecttodetail(6)}
                {...a11yProps(6)}
              />
            )}
            {allPermission[Config.PermissionId.CountryMaster] && (
              <Tab
                id="8"
                label="Country & Tax"
                onClick={() => redirecttodetail(7)}
                {...a11yProps(7)}
              />
            )}
            {allPermission[Config.PermissionId.MerchantPanelMenu] && (
              <Tab
                id="9"
                label="Merchant Panel Menus"
                onClick={() => redirecttodetail(8)}
                {...a11yProps(8)}
              />
            )}
            {(props.userData.is_first_custodian ||
              props.userData.is_second_custodian) && (
              <Tab
                id="10"
                label="Custodian"
                onClick={() => redirecttodetail(9)}
                {...a11yProps(9)}
              />
            )}
            {allPermission[Config.PermissionId.BanksMaster] && (
              <Tab
                id="11"
                label="Banks"
                onClick={() => redirecttodetail(10)}
                {...a11yProps(10)}
              />
            )}
          </Tabs>
        </Box>
        {allPermission[Config.PermissionId.FeesandMargin] && (
          <TabPanel value={props.value} index={0}>
            <TDRSettings
              rolePermission={allPermission[Config.PermissionId.Settlement]}
            />
          </TabPanel>
        )}
        {allPermission[Config.PermissionId.FraudandRisk] && (
          <TabPanel value={props.value} index={1}>
            <FraudAndRisk
              rolePermission={allPermission[Config.PermissionId.FraudandRisk]}
              fromSource="global"
            />
          </TabPanel>
        )}
        {allPermission[Config.PermissionId.MerchantOnboarding] && (
          <TabPanel selectedIndex="true" value={props.value} index={2}>
            <MerchantOnBoarding
              rolePermission={
                allPermission[Config.PermissionId.MerchantOnboarding]
              }
            />
          </TabPanel>
        )}
        {allPermission[Config.PermissionId.PremiumPlan] && (
          <TabPanel value={props.value} index={3}>
            <PremiumPlan
              rolePermission={allPermission[Config.PermissionId.PremiumPlan]}
            />
          </TabPanel>
        )}
        {/* {allPermission[Config.PermissionId.Gateway] && (
          <TabPanel value={props.value} index={4}>
            <Gateway
              rolePermission={allPermission[Config.PermissionId.Gateway]}
            />
          </TabPanel>
        )} */}
        {allPermission[Config.PermissionId.Settlement] && (
          <TabPanel value={props.value} index={4}>
            <Settlement
              rolePermission={allPermission[Config.PermissionId.Settlement]}
            />
          </TabPanel>
        )}
        {allPermission[Config.PermissionId.CronJob] && (
          <TabPanel value={props.value} index={5}>
            <CronJob
              rolePermission={allPermission[Config.PermissionId.CronJob]}
            />
          </TabPanel>
        )}
        {allPermission[Config.PermissionId.KycMaster] && (
          <TabPanel value={props.value} index={6}>
            <Kyc
              rolePermission={allPermission[Config.PermissionId.KycMaster]}
            />
          </TabPanel>
        )}
        {allPermission[Config.PermissionId.CountryMaster] && (
          <TabPanel value={props.value} index={7}>
            <Country
              rolePermission={allPermission[Config.PermissionId.CountryMaster]}
            />
          </TabPanel>
        )}
        {allPermission[Config.PermissionId.MerchantPanelMenu] && (
          <TabPanel value={props.value} index={8}>
            <MerchantPanelMenus
              rolePermission={
                allPermission[Config.PermissionId.MerchantPanelMenu]
              }
            />
          </TabPanel>
        )}
        {(props.userData.is_first_custodian ||
          props.userData.is_second_custodian) && (
          <TabPanel value={props.value} index={9}>
            <Custodian />
          </TabPanel>
        )}
        {allPermission[Config.PermissionId.BanksMaster] && (
          <TabPanel value={props.value} index={10}>
            <Banks
              rolePermission={allPermission[Config.PermissionId.BanksMaster]}
            />
          </TabPanel>
        )}
      </div>
    </NavbarHoc>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps, null)(ConfigurationTab);
