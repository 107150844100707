import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import "../viewdoc.css";
import CustomizedDialogs from "../viewdoc";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import AxiosBase from "../../../../../../../../networkRequest/axiosBase";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import UploadDoc from "../UploadDoc";
import SeccessfullUpload from "../SuccessfullUpload";
const Input = styled("input")({
  display: "none",
});

function TradeLicense(props) {
  const [showPopup, setShowPopup] = useState(true);
  const inputFile = useRef(null);
  const search = useLocation().search;
  const dtlImgUrl = new URLSearchParams(search).get("dtlImgUrl");
  const customerId = new URLSearchParams(search).get("customerId");
  const name = new URLSearchParams(search).get("name");
  const kycId = new URLSearchParams(search).get("kycId");

  const styles = {
    popUp: {
      width: "230px",
      height: "80px",
      zIndex: "1",
      backgroundColor: "white",
      position: "absolute",
      boxShadow: "0px 5px 10px grey",
      borderRadius: "5px",
      paddingTop: "15px",
      left: "600px",
      top: "140px",
    },
    popUpButton: {
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      color: "#1058ff",
      display: "flex",
      alignItems: "center",
    },
    popUpButtonImage: { height: "20px", margin: "0px 5px" },
  };

  const [data, setData] = React.useState([]);
  const [uploadDoc, setUploadDoc] = React.useState(false);
  const [open, setOpen] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const url = [
    {
      name: "Trade License",
      url: data.dtlImgUrl ? data.dtlImgUrl : "/default-upload-doc.svg",
    },
  ];

  const [emiratesLoader, setEmiratesLoader] = React.useState(false);

  const uploaddocument = () => {
    setUploadDoc(true);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const handleDocumentClose = () => {
    setOpen(false);
  };
  const handleUploadDocClose = () => {
    setUploadDoc(false);
  };

  const onFileUpload = async (e) => {
    try {
      setLoader(true);
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "TRADE_LICENSE");
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const res = await AxiosBase.post(
        `/admin/kyc/upload/${kycId}`,
        formData,
        config
      );
      setData({
        ...data,
        dtlImgUrl: res.data.url,
      });
      props.setData(res.data.data);
      setLoader(false);
      return true;
    } catch (e) {
      setLoader(false);
      props.setSnackbar({
        open: true,
        message: "Invalid File",
        severiety: "error",
      });
    }
  };

  useEffect(() => {
    AxiosBase.get(`/admin/kyc/view-kyc-details/${kycId}`).then((response) => {
      setData(response.data);
    });
  }, []);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [validFiles, setValidFiles] = useState([]);

  // dragdrop img sec
  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };
  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        // add to an array so we can display the name of file
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
      } else {
        // add a new property called invalid
        // files\[i\]['invalid'] = true;
        // add to the same array so we can display the name of the file
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        // set error message
        setErrorMessage("File type not permitted");
      }
    }
  };

  const validateFile = (file) => {
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      // "image/gif",
      // "image/x-icon",
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };
  const fileSize = (size) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };
  const fileType = (fileName) => {
    return (
      fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
      fileName
    );
  };

  const removeFile = (name) => {
    // find the index of the item
    // remove the item from array

    const validFileIndex = validFiles.findIndex((e) => e.name === name);
    validFiles.splice(validFileIndex, 1);
    // update validFiles array
    setValidFiles([...validFiles]);
    const selectedFileIndex = selectedFiles.findIndex((e) => e.name === name);
    selectedFiles.splice(selectedFileIndex, 1);
    // update selectedFiles array
    setSelectedFiles([...selectedFiles]);
  };
  useEffect(() => {
    let filteredArray = selectedFiles.reduce((file, current) => {
      const x = file.find((item) => item.name === current.name);
      if (!x) {
        return file.concat([current]);
      } else {
        return file;
      }
    }, []);
    setValidFiles([...filteredArray]);
  }, [selectedFiles]);

  return (
    <Card
      className="tradlicence_doc"
      sx={{
        maxWidth: 500,
        maxHeight: 380,
        border: "0.4px solid #dadada",
        marginLeft: "auto",
      }}
    >
      <CardHeader
        className="trade_doc_view_card"
        action={
          <IconButton aria-label="settings">
            <Input
              accept="image/*"
              id="contained-button-file"
              multiple
              ref={inputFile}
              type="file"
              value=""
              onChange={(e) => onFileUpload(e)}
            />

            <Button
              variant="contained"
              disabled={
                props.data.tradeLicenseVerified == "Approved" ||
                props.data.tradeLicenseVerified == "Rejected" ||
                props.data.emiratesIdVerified == "Rejected"
                  ? true
                  : false
              }
              // onClick={() => {
              //   inputFile.current.click();
              // }}
              onClick={uploaddocument}
              style={{
                backgroundColor:
                  props.data.tradeLicenseVerified == "Rejected" ||
                  props.data.emiratesIdVerified == "Rejected"
                    ? "#a3a1a1"
                    : "#1058ff",
                fontSize: "10px",
                marginTop: "-5px",
              }}
              startIcon={<BackupOutlinedIcon />}
              component="span"
            >
              Upload Document
            </Button>
          </IconButton>
        }
        title="Trade License"
      />
      {loader ? (
        <div className="imagecontainer">
          <div className="center">
            <CircularProgress />
          </div>
        </div>
      ) : (
        <CardMedia
          className="trade_view_inner_img"
          component="img"
          height="100%"
          image={data.dtlImgUrl ? data.dtlImgUrl : "/default-upload-doc.svg"}
          alt="Trade License"
          onClick={openDialog}
        />
      )}
      {open == true ? (
        <CustomizedDialogs
          open={open}
          handleDocumentClose={handleDocumentClose}
          item={url}
        />
      ) : (
        <></>
      )}
      {uploadDoc == true ? (
        <UploadDoc
          open={uploadDoc}
          handleDocumentClose={handleUploadDocClose}
          selectedFiles={selectedFiles}
          // dragOver={dragOver}
          // dragEnter={dragEnter}
          // dragLeave={dragLeave}
          // fileDrop={fileDrop}
          // selectedFiles={selectedFiles}
          // errorMessage={errorMessage}
          // validFiles={validFiles}
          // removeFile={removeFile(data.name)}
          // validFiles={validFiles}
          // fileType={fileType}
          // fileSize={fileSize}
          // errorMessage={errorMessage}
        />
      ) : (
        <></>
      )}
    </Card>
  );
}

export default TradeLicense;
