import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { saveKycProfileImage } from "../../../networkRequest/request/kyc";
import { useLocation } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { KycStatus } from "../kyc-grid-table/index";
import "./KycDetail.css";
import KycDefaultProfile from "../../../Icons/KycDefaultPic.jpg";
import AxiosBase from "../../../networkRequest/axiosBase";
import NavbarHoc from "../../../pages/navhoc/index";
import Config from "../../../config.json";
import KycDetails from "../../merchant/kyc-details/KycDetails";
import Button from "@mui/material/Button";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "250px",
    height: "4px",
    color: "#666666",
    fontWeight: "bold",
  },
});

const KycDetail = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const search = useLocation().search;
  const [rolePermission, setRolePermission] = React.useState([]);
  const customerId = new URLSearchParams(search).get("customerId");
  const kycId = new URLSearchParams(search).get("kycId");
  const [image, setImage] = React.useState("/yash1.jpg");
  const [userDetail, setUserDetail] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile_no: "",
    gender: "",
    dateOfBirth: "",
    address: "",
  });
  const submitUserData = (e) => {
    e.preventDefault();
  };
  const clicked = (e) => {
    const file = e.target.files;
    setImage(file);
  };
  const selectProfilePic = () => {
    document.getElementById("chooseFile").click();
  };
  const fileChange = (e) => {
    const image = e.target.files[0];
    if (e.target.files && image) {
      if (image.type === "image/jpeg" || image.type === "image/x-png") {
        const reader = new FileReader();
        const test1 = (reader.onload = (e) => setImage(e.target.result));
        const test = reader.readAsDataURL(e.target.files[0]);
        saveKycProfileImage(test);
      } else {
        toast("Invalid file type", { type: "error", autoClose: 5000 });
      }
    }
  };
  const stringVal = React.useState();
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [spinner, setSpinner] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severiety: "success",
  });
  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: "", severiety: "success" });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const changeTab = () => {
    setValue(0);
  };
  const changeTab1 = () => {
    setValue(2);
  };
  useEffect(() => {
    setSpinner(true);
    AxiosBase.get(`/admin/kyc/view-kyc-details/${kycId}`).then((response) => {
      if (response?.data) {
        setData({
          ...response.data,
        });
      } else {
        setData(response.data);
      }
      setSpinner(false);
    });
  }, []);

  return (
    <NavbarHoc
      id={Config.PermissionId.KYC}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <div>
        <div style={{ display: "flex" }}>
          <div className="kyc-detail-top-profileArea">
            <div className="kyc-detail-profile">
              <img src={KycDefaultProfile} alt="Profile Pic" accept="" />
            </div>
            <p
              style={{
                fontSize: "28px",
              }}
            >
              {data?.customersData?.first_name} {data?.customersData?.last_name}
            </p>
            <p
              style={{
                fontSize: "14px",
                color: "#2160f6",
              }}
            >
              {data?._id}
            </p>

            <KycStatus
              status={data?.kycStatus}
              style={{ margin: "0", fontSize: "18px" }}
            />
            {/* {data?.kycStatus} */}
          </div>

          <input
            id="chooseFile"
            type="file"
            onChange={(e) => fileChange(e)}
            style={{ display: "none" }}
          />
          <div className="kyc-detail-inputFeild">
            <div style={{ display: "flex", marginLeft: "1rem" }}>
              <div className="kyc-detail-data-sec-div">
                <p>First Name:</p>

                <CssTextField
                  id="new-menu"
                  variant="outlined"
                  value={data?.customersData?.first_name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonOutlineIcon className="kyc-icon-label" />
                      </InputAdornment>
                    ),
                  }}
                  disabled
                />
              </div>
              <div className="kyc-detail-data-sec-div">
                <p>Last Name:</p>
                <CssTextField
                  id="new-menu"
                  variant="outlined"
                  value={data?.customersData?.last_name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <PersonOutlineIcon className="kyc-icon-label" />
                      </InputAdornment>
                    ),
                  }}
                  disabled
                />
              </div>
            </div>

            <div style={{ display: "flex", marginLeft: "1rem" }}>
              <div className="kyc-detail-data-sec-div">
                <p>Email:</p>
                <CssTextField
                  id="new-menu"
                  variant="outlined"
                  value={data?.customersData?.email_id}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <EmailOutlinedIcon className="kyc-icon-label" />
                      </InputAdornment>
                    ),
                  }}
                  disabled
                />
              </div>
              <div className="kyc-detail-data-sec-div">
                <p>Phone Number:</p>
                <CssTextField
                  id="new-menu"
                  variant="outlined"
                  value={data?.customersData?.mobile_no}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <LocalPhoneOutlinedIcon className="kyc-icon-label" />
                      </InputAdornment>
                    ),
                  }}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            // display: "flex",
            padding: "10px 20px",
            marginTop: "20px",
          }}
        >
          <div>
            {!spinner && (
              <>
                {/* <div style={{"text-align": "right"}}>
                    <Button
                      className="request_additional_doc_btn"
                      variant="contained"
                      onClick={() => {
                        
                      }}
                    >
                      Request for additional document
                    </Button>
                  </div> */}
                <KycDetails
                  merchantDocument={data}
                  user={data?.customersData}
                  showFrom="change_status_doc"
                  rolePermission={true}
                  status={data?.kycStatus}
                />
              </>
            )}
          </div>
        </div>
        {snackbar.message && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbar.severiety}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        )}
      </div>
    </NavbarHoc>
  );
};

export default KycDetail;
