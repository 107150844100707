import React from "react";
import { useState, useEffect } from "react";
import "./CronJob.css";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import AxiosBase from "../../../networkRequest/axiosBase";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { unstable_styleFunctionSx } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import CronJobDetail from "./CronJob-Detail/CronJobDetail";

//side bar table code
const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 45,
  height: 22,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const labelList = [
  {
    value: "ID Document",
    label: "ID Document",
  },
  {
    value: "License",
    label: "License",
  },
  {
    value: "Bank Document",
    label: "Bank Document",
  },
  {
    value: "Tax",
    label: "Tax",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const CronJob = (props) => {
  const { rolePermission } = props;
  const [spinner, setSpinner] = useState(false);

  const [cronjobdata, setCronJobData] = useState([]);
  const [state, setState] = React.useState({
    right: false,
  });
  const [selectedCronJob, setSelectedCronJob] = React.useState({});

  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  useEffect(() => {
    setSpinner(true);
    AxiosBase.get(`/admin/cronjob?sortOrder=asc`).then((res) => {
      setCronJobData(res?.data?.data);
      setSpinner(false);
    });
  }, []);

  const handleDelete = (id) => {
    setSpinner(true);
    AxiosBase.delete(`/admin/cronjob/${id}`).then((res) => {
      toast("CronJob deleted successfully", {
        type: "success",
        autoClose: 2000,
      });
      cancelCronJob({}, selectedCronJob?._id);
    });
  };

  const cancelCronJob = (e, id) => {
    setSpinner(true);
    AxiosBase.get(`/admin/cronjob?sortOrder=asc`).then((res) => {
      setCronJobData(res?.data?.data);
      setSpinner(false);
    });
  };
  const addCronJob = () => {
    const obj = {
      _id: "add_new",
      ConvolverNode: "",
      name: "",
      time: "",
      status: true,
    };
    cronjobdata.push(obj);
    setSelectedCronJob(obj);
    setCronJobData(cronjobdata);
  };
  const saveCronJob = (e, _id, cronjob) => {
    try {
      setSpinner(true);
      if (_id == "add_new") {
        delete cronjob._id;
        AxiosBase.post("/admin/cronjob", cronjob)
          .then((res) => {
            if (res?.status == 200) {
              if (res?.data?._id !== "") {
                cancelCronJob({}, selectedCronJob?._id);
                return toast(
                  "A new CronJob configuration added successfully.",
                  {
                    type: "success",
                    autoClose: 2000,
                  }
                );
              }
            } else {
              setSpinner(false);
              return toast(
                res?.response?.data?.message || "Something went wrong!",
                {
                  type: "error",
                  autoClose: 2000,
                }
              );
            }
          })
          .catch((error) => {
            setSpinner(false);
            AxiosBase.get(`/admin/cronjob?sortOrder=asc`)
              .then((res) => {
                setCronJobData(res?.data?.data);
                setSpinner(false);
              })
              .catch((err) => {});
            return toast(
              error?.response?.data?.message || "Something went wrong!",
              {
                type: "error",
                autoClose: 2000,
              }
            );
          });
      } else {
        AxiosBase.patch("/admin/cronjob/" + _id, cronjob)
          .then((res) => {
            if (res?.status == 200) {
              if (res?.data?._id !== "") {
                cancelCronJob({}, selectedCronJob?._id);
                return toast("CronJob configuration Updated successfully.", {
                  type: "success",
                  autoClose: 2000,
                });
              }
            } else {
              setSpinner(false);
              return toast(
                res?.response?.data?.message || "Something went wrong!",
                {
                  type: "error",
                  autoClose: 2000,
                }
              );
            }
          })
          .catch((error) => {
            setSpinner(false);
            AxiosBase.get(`/admin/cronjob?sortOrder=asc`)
              .then((res) => {
                setCronJobData(res?.data?.data);
                setSpinner(false);
              })
              .catch((err) => {});
            return toast(
              error?.response?.data?.message || "Something went wrong!",
              {
                type: "error",
                autoClose: 2000,
              }
            );
          });
      }
    } catch (err) {
      setSpinner(false);
      return toast(JSON.stringify(err), {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  const success = (anchor) => {
    const index = cronjobdata?.findIndex((x) => x._id == selectedCronJob?._id);
    setState({ ...state, [anchor]: false });
    saveCronJob({}, cronjobdata[index]?._id, cronjobdata[index]);
  };

  const detailCronJob = (anchor, open, selected) => {
    setSelectedCronJob(selected);
    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      {spinner ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress style={{ color: "#1058ff" }} />
        </div>
      ) : (
        <CronJobDetail
          data={cronjobdata}
          cancelCronJob={cancelCronJob}
          saveCronJob={saveCronJob}
          addCronJob={addCronJob}
          handleDelete={handleDelete}
          rolePermission={rolePermission}
        />
      )}
    </>
  );
};

export default CronJob;
