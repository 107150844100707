import React from "react";
import { Route, Routes } from "react-router-dom";
import StatusCode from "../../Components/statuscode/StatusCode";
import Topheader from "../../universal-components/header/topHeader";

function Statusmaster() {
  return (
    <div style={{ padding: "15px" }}>
      <Topheader />
      <StatusCode />
    </div>
  );
}
export default Statusmaster;
