import React from "react";
import Topheader from "../../universal-components/header/topHeader";
import { Route, Routes } from "react-router-dom";
import AcquirerTab from "../../Components/acquirer/index";

const AcquirerMain = () => {
  return (
    <>
      <div style={{ padding: "15px", borderRadius: "5rem", width: "98%" }}>
        <Topheader />
        <AcquirerTab />
        <Routes>
          <Route path="" element={<AcquirerTab />} />
        </Routes>
      </div>
    </>
  );
};

export default AcquirerMain;
