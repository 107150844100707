const baseURL = () => {
  const sandbox_url = process.env.REACT_APP_SANDBOX_URL;
  const live_url = process.env.REACT_APP_API_URL;

  try {
    const root = JSON.parse(localStorage.getItem("persist:root"));
    const authData = root?.auth ? JSON.parse(root?.auth) : "";

    const sandbox_mode = localStorage.getItem("sandbox_mode");
    if (!authData?.accessToken) {
      return live_url;
    }
    if (sandbox_mode) {
      return sandbox_url;
    }
  } catch (error) {
    return live_url;
  }
  return live_url;
};

export default baseURL;
