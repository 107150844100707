import React from "react";
import PaymentMethod from "./PaymentMethod";
import "./index.scss";

export default function FourthSection(props) {
  return (
    <div className="fourth-section">
      <div className="fourth-section__left fourth-section-card">
        <h6>Payment Method Volume</h6>
        <div className="fourth-section-cart">
          <PaymentMethod
            data={props?.data?.payment_method}
            filter={props.filter}
          />
        </div>
      </div>
      {/* <div className="fourth-section__right fourth-section-card">
        <h6>Payment Method Volume</h6>
        <div className="fourth-section-cart"></div>
      </div> */}
    </div>
  );
}
