import React from "react";
import { useState, useEffect } from "react";
import "./OtherSettings.css";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const OtherSettings = (props) => {
  const {
    rolePermission,
    allStatusCodeMapping,
    id,
    AddAcquirerProp,
    UpdateAcquirerProp,
  } = props;
  const [loader, setLoader] = React.useState(true);
  const [controlDisable, setControlDisable] = React.useState(false);
  const [allStatusData, setAllStatusData] = React.useState({});
  let month_days = [];

  for (let i = 0; i <= 30; i++) {
    month_days.push(i);
  }
  React.useEffect(() => {
    CommonCallFunction();
  }, [id, allStatusCodeMapping]);

  const CommonCallFunction = () => {
    id && setControlDisable(true);
    setAllStatusData(allStatusCodeMapping);
    setLoader(false);
  };

  const SaveCode = () => {
    const {
      setupcost,
      maintenance_cost,
      refund_fees,
      settlement_cycle,
      rolling_reserve_days,
      rolling_reserve,
      chargeback_fees,
    } = allStatusData;
    if (
      setupcost !== "" &&
      maintenance_cost !== "" &&
      refund_fees !== "" &&
      settlement_cycle !== "" &&
      rolling_reserve_days !== "" &&
      rolling_reserve !== "" &&
      chargeback_fees !== ""
    ) {
      setLoader(true);
      let data = allStatusData;
      UpdateAcquirerProp(data, data._id);
    } else {
      toast("Please Fill All The Mandatory Details.", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };

  const AddCode = () => {
    const {
      setupcost,
      maintenance_cost,
      refund_fees,
      settlement_cycle,
      rolling_reserve_days,
      rolling_reserve,
      chargeback_fees,
    } = allStatusData;
    if (
      setupcost &&
      maintenance_cost &&
      refund_fees &&
      settlement_cycle &&
      rolling_reserve_days &&
      rolling_reserve &&
      chargeback_fees &&
      setupcost !== "" &&
      maintenance_cost !== "" &&
      refund_fees !== "" &&
      settlement_cycle !== "" &&
      rolling_reserve_days !== "" &&
      rolling_reserve !== "" &&
      chargeback_fees !== ""
    ) {
      setLoader(true);
      AddAcquirerProp(allStatusData, 3);
    } else {
      toast("Please Fill All The Mandatory Details.", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };
  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="OtherSettings">
          <Grid className="OtherSettingsRow" container spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Setup Cost <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Setup Cost"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={allStatusData?.setupcost ? allStatusData?.setupcost : 0}
                onChange={(e) => {
                  let val = e.target.value.match("^[0-9]*$");
                  if (val) {
                    setAllStatusData({
                      ...allStatusData,
                      setupcost: Number(val),
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Maintenance Cost <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Maintenance Cost"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={
                  allStatusData?.maintenance_cost
                    ? allStatusData?.maintenance_cost
                    : 0
                }
                onChange={(e) => {
                  let val = e.target.value.match("^[0-9]*$");
                  if (val) {
                    setAllStatusData({
                      ...allStatusData,
                      maintenance_cost: Number(val),
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Rolling Reserve <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Rolling Reserve"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={
                  allStatusData?.rolling_reserve
                    ? allStatusData?.rolling_reserve
                    : 0
                }
                onChange={(e) => {
                  let val = e.target.value.match("^[0-9]*$");
                  if (val) {
                    setAllStatusData({
                      ...allStatusData,
                      rolling_reserve: Number(val),
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Rolling Reserve Days <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Rolling Reserve Days"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={
                  allStatusData?.rolling_reserve_days
                    ? allStatusData?.rolling_reserve_days
                    : 0
                }
                onChange={(e) => {
                  let val = e.target.value.match("^[0-9]*$");
                  if (val) {
                    setAllStatusData({
                      ...allStatusData,
                      rolling_reserve_days: Number(val),
                    });
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid className="OtherSettingsRow" container spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Refund Fees <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Refund Fees"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={
                  allStatusData?.refund_fees ? allStatusData?.refund_fees : 0
                }
                onChange={(e) => {
                  let val = e.target.value.match("^[0-9]*$");
                  if (val) {
                    setAllStatusData({
                      ...allStatusData,
                      refund_fees: Number(val),
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Chargeback Fees <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Chargeback Fees"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={
                  allStatusData?.chargeback_fees
                    ? allStatusData?.chargeback_fees
                    : 0
                }
                onChange={(e) => {
                  let val = e.target.value.match("^[0-9]*$");
                  if (val) {
                    setAllStatusData({
                      ...allStatusData,
                      chargeback_fees: Number(val),
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Settlement Cycle <span className="Mandatory"></span>
              </p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  allStatusData?.settlement_cycle
                    ? allStatusData?.settlement_cycle
                    : 0
                }
                label=""
                onChange={(e) => {
                  setAllStatusData({
                    ...allStatusData,
                    settlement_cycle: e.target.value,
                  });
                }}
                fullWidth
                disabled={controlDisable}
              >
                {month_days.map((day) => {
                  return <MenuItem value={day}>{day}</MenuItem>;
                })}
              </Select>
            </Grid>
          </Grid>
          {!id ? (
            <Grid
              className="OtherSettingsRow2"
              container
              spacing={2}
              justifyContent="flex-end"
            >
              <Button
                onClick={AddCode}
                className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                style={{ marginRight: "29px" }}
                variant="contained"
              >
                Save
              </Button>
            </Grid>
          ) : (
            <Grid
              className="OtherSettingsRow2"
              container
              spacing={2}
              justifyContent="flex-end"
            >
              {!controlDisable ? (
                <>
                  <Button
                    onClick={SaveCode}
                    className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                    style={{ marginRight: "29px" }}
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      CommonCallFunction();
                      setControlDisable(true);
                    }}
                    className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  {rolePermission.includes("EDIT") && (
                    <Button
                      onClick={() => {
                        setControlDisable(false);
                      }}
                      className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                      style={{ marginRight: "29px" }}
                      variant="contained"
                    >
                      Edit
                    </Button>
                  )}
                </>
              )}
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

export default OtherSettings;
