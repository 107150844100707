import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});

const BanksDetail = (props) => {
  return (
    <div style={{ width: "100%" }} className="kyc_detail_ctrl">
      <div className="">
        <div>
          <Grid item xs={12} className="gatway_second_grid_sec country-field">
            <p>Bank Name:</p>
            <CssTextField
              className="merchant_list_search_bar"
              name="name"
              placeholder="Name"
              variant="outlined"
              value={props.data.name}
              onChange={props.handleChange}
            />
          </Grid>
          <Grid item xs={12} className="gatway_second_grid_sec country-field">
            <p>Logo:</p>
            <CssTextField
              className="merchant_list_search_bar"
              name="icon"
              variant="outlined"
              value={props.data.icon}
              onChange={props.handleChange}
            />
          </Grid>
          <Grid item xs={12} className="gatway_second_grid_sec country-field">
            <p>Bank Instalments:</p>
            <Autocomplete
              multiple
              options={[
                "1",
                " 2",
                " 3",
                " 4",
                " 5",
                " 6",
                " 7",
                " 8",
                " 9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
              ]}
              // defaultValue={props.data?.instalment || []}
              value={props.data?.instalment || []}
              freeSolo
              onChange={props.handleInstallmentChange}
              name="instalment"
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      variant="outlined"
                      color="primary"
                      label={option}
                      key={key}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <CssTextField {...params} variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12} className="gatway_second_grid_sec country-field">
            <p>Minimum Instalments Amount:</p>
            <CssTextField
              className="merchant_list_search_bar"
              name="min_epp_amount"
              variant="outlined"
              value={props.data.min_epp_amount}
              onChange={props.handleChange}
            />
          </Grid>
          <Grid item xs={12} className="gatway_second_grid_sec kyc-field">
            <Checkbox
              name="is_epp_available"
              color="primary"
              checked={props.data.is_epp_available}
              onChange={props.handleChange}
            />
            <label>Instalments</label>
          </Grid>
          <Grid item xs={12} className="gatway_second_grid_sec kyc-field">
            <Checkbox
              name="is_active"
              color="primary"
              checked={props.data.is_active}
              onChange={props.handleChange}
            />
            <label>Active</label>
          </Grid>
        </div>
      </div>
      <div className="country_edit_drawer_btn_sec">
        <Button
          className="drawer_merchant_cancel_btn"
          variant="contained"
          onClick={props.closeDrawer}
        >
          Cancel
        </Button>
        <Button
          className="drawer_merchant_save_btn"
          variant="contained"
          onClick={props.handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default BanksDetail;
