import React from "react";
import { Route, Routes } from "react-router-dom";
import KycHome from "../../page-components/kyc/kycHome/home";
import KycDetails from "../../page-components/kyc/kycDetails/home/components/kycDetails";
import KycHistory from "../../page-components/kyc/kycDetails/home/components/kycDetails/history";
import History from "../../page-components/kyc/kycHome/home/components/kycRequests/History/History";
import KycRequestDetails from "../../Components/KYC/kyc-tabs/index";
import { Box, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useLocation } from "react-router-dom";
import Topheader from "../../universal-components/header/topHeader";
import "./kycLink.css";
import KycDataTable from "../../Components/KYC/kyc-grid-table/index";

function KycMain() {
  const location = useLocation();

  return (
    <div style={{ padding: "15px", borderRadius: "5rem", width: "98%" }}>
      <Topheader />

      {/* <Box className="kyc-main-title-box">
        {location.pathname !== "/kyc" && (
          <IconButton sx={{ borderRadius: "50%" }}>
            <Link to="/kyc">
              <ArrowBackIosNewIcon className="kyc-back-btn" />
            </Link>
          </IconButton>
        )}
        <h1 className="title-kyc">KYC</h1>
      </Box> */}

      <Routes>
        {/* <Route exact path="/kyc" element={<KycHome />} /> */}
        <Route exact path="" element={<KycDataTable />} />
        <Route exact path="details" element={<KycRequestDetails />} />
        {/* <Route exact path="documents" element={<KycDetails />} /> */}
        {/* <Route exact path="history" element={<History />} /> */}
      </Routes>
    </div>
  );
}

export default KycMain;
