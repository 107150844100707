import React from "react";
import { useState, useEffect } from "react";
import "./TaxSettings.css";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const TaxSettings = (props) => {
  const {
    rolePermission,
    allStatusCodeMapping,
    id,
    AddAcquirerProp,
    UpdateAcquirerProp,
  } = props;
  const [loader, setLoader] = React.useState(false);
  const [controlDisable, setControlDisable] = React.useState(false);
  const [allStatusData, setAllStatusData] = React.useState({ tax: {} });

  React.useEffect(() => {
    CommonCallFunction();
  }, [id, allStatusCodeMapping]);

  const CommonCallFunction = () => {
    id && setControlDisable(true);
    setAllStatusData(JSON.parse(JSON.stringify(allStatusCodeMapping)));
    setLoader(false);
  };

  const SaveCode = () => {
    const {
      tax: { service_charge, gst, pancard, tan_no, tds },
    } = allStatusData;
    if (
      service_charge !== "" &&
      gst !== "" &&
      pancard !== "" &&
      tan_no !== "" &&
      tds !== ""
    ) {
      setLoader(true);
      let data = allStatusData;
      UpdateAcquirerProp(data, data._id);
    } else {
      toast("Please Fill All The Mandatory Details.", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };
  const AddCode = () => {
    const {
      tax: { service_charge, gst, pancard, tan_no, tds },
    } = allStatusData;
    if (
      service_charge &&
      gst &&
      pancard &&
      tan_no &&
      tds &&
      service_charge !== "" &&
      gst !== "" &&
      pancard !== "" &&
      tan_no !== "" &&
      tds !== ""
    ) {
      AddAcquirerProp(allStatusData, 2);
    } else {
      toast("Please Fill All The Mandatory Details.", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="TaxSettings">
          <Grid className="TaxSettingsRow" container spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="TaxTitle">
                Tax Number <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Code"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={allStatusData?.tax?.gst ? allStatusData?.tax?.gst : 0}
                onChange={(e) => {
                  let val = e.target.value.match("^[0-9]*$");
                  if (val) {
                    let data = allStatusData;
                    data.tax.gst = Number(val);
                    setAllStatusData({ ...data });
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="TaxTitle">
                Service Charge <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Code"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={
                  allStatusData?.tax?.service_charge
                    ? allStatusData?.tax?.service_charge
                    : 0
                }
                onChange={(e) => {
                  let val = e.target.value.match("^[0-9]*$");
                  if (val) {
                    let data = allStatusData;
                    data.tax.service_charge = Number(val);
                    setAllStatusData({ ...data });
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="TaxTitle">
                Pancard No. <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Pancard No."
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={allStatusData?.tax?.pancard}
                onChange={(e) => {
                  let data = allStatusData;
                  data.tax.pancard = e.target.value;
                  setAllStatusData({ ...data });
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="TaxTitle">
                TAN No. <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="TAN No."
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={allStatusData?.tax?.tan_no}
                onChange={(e) => {
                  let data = allStatusData;
                  data.tax.tan_no = e.target.value;
                  setAllStatusData({ ...data });
                }}
              />
            </Grid>
          </Grid>
          <Grid className="TaxSettingsRow" container spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="TaxTitle">
                TDS <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Code"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={allStatusData?.tax?.tds ? allStatusData?.tax?.tds : 0}
                onChange={(e) => {
                  let val = e.target.value.match("^[0-9]*$");
                  if (val) {
                    let data = allStatusData;
                    data.tax.tds = e.target.value;
                    setAllStatusData({ ...data });
                  }
                }}
              />
            </Grid>
          </Grid>
          {!id ? (
            <Grid
              className="TaxSettingsRow2"
              container
              spacing={2}
              justifyContent="flex-end"
            >
              <Button
                onClick={AddCode}
                className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                style={{ marginRight: "29px" }}
                variant="contained"
              >
                Next
              </Button>
            </Grid>
          ) : (
            <Grid
              className="TaxSettingsRow2"
              container
              spacing={2}
              justifyContent="flex-end"
            >
              {!controlDisable ? (
                <>
                  <Button
                    onClick={SaveCode}
                    className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                    style={{ marginRight: "29px" }}
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      CommonCallFunction();
                      setControlDisable(true);
                    }}
                    className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  {rolePermission.includes("EDIT") && (
                    <Button
                      onClick={() => {
                        setControlDisable(false);
                      }}
                      className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                      style={{ marginRight: "29px" }}
                      variant="contained"
                    >
                      Edit
                    </Button>
                  )}
                </>
              )}
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

export default TaxSettings;
