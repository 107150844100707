import React from "react";
import { Route, Routes } from "react-router-dom";
import CustomersHome from "../../page-components/customers/customerHome";
import ProfileHome from "../../page-components/customers/profileHome/home";
import CustomerFees from "../../page-components/customers/customerFees";

function CustomersMain() {
  return (
    <div style={{ padding: "15px" }}>
      <Routes>
        <Route exact path="" element={<CustomersHome />} />
        <Route path="profile" element={<ProfileHome />} />
        <Route path="fees" element={<CustomerFees />} />
      </Routes>
    </div>
  );
}

export default CustomersMain;
