import React, { useState, useEffect } from "react";
import {
  ListItemText,
  ListItem,
  ListItemIcon,
  Drawer,
  Divider,
  List,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import LogoutIcon from "@mui/icons-material/Logout";
import { NavLink } from "react-router-dom";
import styles from "./navbar.module.scss";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/auth";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import "./CollapseNavbar.css";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import Config from "../../config.json";
import FormGroup from "@mui/material/FormGroup";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1456F5",
    borderRadius: "0 15px 15px 0",
    border: "none",
  },
}));
function CollapseNavBar(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(true);
  const [openNavbar, setOpenNavbar] = React.useState(false);

  const [opennavlink, setOpenNavlink] = React.useState(false);
  const [opennavlink1, setOpenNavlink1] = React.useState(false);
  const [opennavlink2, setOpenNavlink2] = React.useState(false);

  const [navbarData, setNavbarData] = React.useState({});
  const handleClickNavlink1 = () => {
    setOpenNavlink1(!opennavlink1);
  };
  const handleClickNavlink = () => {
    setOpenNavlink(!opennavlink);
  };

  const handleClickNavlink2 = () => {
    setOpenNavlink2(!opennavlink2);
  };

  const navs = [
    {
      icon: "/AccessControl-01.svg",
      title: "Dashboard",
      url: "/dashboard",
      id: Config.PermissionId.Dashboard,
    },
    {
      icon: "/order.svg",
      title: "Orders",
      url: "/orders",
      id: Config.PermissionId.Orders,
    },
    {
      icon: "/kyc.svg",
      title: "Know Your Customers(KYC)",
      url: "/kyc",
      id: Config.PermissionId.KYC,
    },
    {
      icon: "/super-merchant.svg",
      title: "Super Merchants",
      url: "/supermerchant",
      id: Config.PermissionId.SuperMerchants,
    },
    {
      icon: "/Merchant.svg",
      title: "Merchants",
      url: "/merchants",
      id: Config.PermissionId.Merchants,
      // submenu: [
      //   { title: "Create Merchant", link: "/merchants/createmerchant" }
      //   // { title: "Merchant Invoice", link: "/merchants/merchantinvoice" },
      // ],
      onclick: handleClickNavlink1,
      open: opennavlink1,
    },
    {
      icon: "/Customer list-01.svg",
      title: "Customer List",
      url: "/merchant-customer",
      id: Config.PermissionId.MerchantUserList,
      onclick: handleClickNavlink1,
      open: opennavlink1,
    },
    {
      icon: "/Invoices-01.svg",
      title: "Invoices",
      url: "/pos-request",
      id: Config.PermissionId.PosRequest,
      onclick: handleClickNavlink1,
      open: opennavlink1,
    },
    {
      icon: "/refund-logo.svg",
      title: "Refunds",
      url: "/refund",
      id: Config.PermissionId.Refunds,
    },

    {
      icon: "/Premium-Order.svg",
      title: "Premium Orders",
      url: "/premiumorders",
      id: Config.PermissionId.PremiumOrders,
    },

    // {
    //   icon: "/user.svg",
    //   title: "Users",
    //   url: "/users",
    // },
    // {
    //   icon: "/AccessControl-01.svg",
    //   title: "Access Control",
    //   url: "/accesscontrol",
    // },

    {
      icon: "/settlement.svg",
      title: "Settlements",
      url: "/settlements",
      id: Config.PermissionId.Settlements,
    },
    {
      icon: "/AccessControl-01.svg",
      title: "Reports",
      url: "/reports",
      id: Config.PermissionId.Reports,
    },
    {
      icon: "/Configuration-01.svg",
      title: "Configuration",
      url: "#",
      id: Config.PermissionId.Configuration,
      submenu: [
        {
          title: "MDR Settings",
          link: "/configuration?id=0",
          icon: "/fees and margin.svg",
          id: Config.PermissionId.FeesandMargin,
        },
        {
          title: "Fraud and Risk",
          link: "/configuration?id=1",
          icon: "/fraud and risk.svg",
          id: Config.PermissionId.FraudandRisk,
        },
        {
          title: "Merchant Onboarding",
          link: "/configuration?id=2",
          icon: "/merchant onboarding.svg",
          id: Config.PermissionId.MerchantOnboarding,
        },
        {
          title: "Subscription",
          link: "/configuration?id=3",
          icon: "/premium plan.svg",
          id: Config.PermissionId.PremiumPlan,
        },
        {
          title: "Settlement",
          link: "/configuration?id=4",
          icon: "/settlement.svg",
          id: Config.PermissionId.Settlement,
        },
        {
          title: "Cron Job",
          link: "/configuration?id=5",
          icon: "/CronJob.svg",
          id: Config.PermissionId.CronJob,
        },
        {
          title: "Kyc",
          link: "/configuration?id=6",
          icon: "/kyc.svg",
          id: Config.PermissionId.KycMaster,
        },
        {
          title: "Country & Tax",
          link: "/configuration?id=7",
          icon: "/Country-02.svg",
          id: Config.PermissionId.CountryMaster,
        },
        {
          title: "Merchant Panel Menus",
          link: "/configuration?id=8",
          icon: "/AccessControl-01.svg",
          id: Config.PermissionId.MerchantPanelMenu,
        },
        {
          title: "Custodian",
          link: "/configuration?id=9",
          icon: "/Key.svg",
          id: Config.PermissionId.Configuration,
          custodian_page: true,
        },
        {
          title: "Banks",
          link: "/configuration?id=10",
          icon: "/AccessControl-01.svg",
          id: Config.PermissionId.BanksMaster,
        },
      ],
      onclick: handleClickNavlink,
      open: opennavlink,
    },
    {
      icon: "/MOP.svg",
      title: "Acquirer",
      url: "/acquirerlist",
      id: Config.PermissionId.Acquirer,
    },
    {
      icon: "/status code mapping.svg",
      title: "Status Code",
      url: "/statuscode",
      id: Config.PermissionId.StatusCode,
    },
    {
      icon: "/AccessControl-01.svg",
      title: "Access Control",
      url: "#",
      id: Config.PermissionId.AccessControl,
      submenu: [
        {
          title: "Users",
          link: "/users",
          icon: "/Profile.svg",
          id: Config.PermissionId.Users,
        },
        {
          title: "Role",
          link: "/role",
          icon: "/user.svg",
          id: Config.PermissionId.Role,
        },
        {
          title: "Menu",
          link: "/menu",
          icon: "/menu.svg",
          id: Config.PermissionId.Menu,
        },
      ],
      onclick: handleClickNavlink2,
      open: opennavlink2,
    },
  ];

  React.useEffect(() => {
    setNavbarData(props.NavBarData);
    setChecked(true);
  }, []);

  React.useEffect(() => {
    setChecked(props.openNavbar);
    // setNavbarData(props.NavBarData);
  }, [props.openNavbar]);

  return (
    <Paper
      // onMouseEnter={props.closeNavbarHandler}
      // onMouseLeave={props.openNavbarHandler}
      className={`navbar_paper ${styles.css_cets00_MuiPaper_root}`}
    >
      <div className="navbar_arrow_div">
        <IconButton
          // onClick={props.openNavbarHandler}
          className={!checked ? `position_change` : "btn_icon_rad"}
        >
          {!checked ? (
            <ArrowBackIosNewOutlinedIcon
              onClick={props.openNavbarHandler}
              className="colleps_arrow "
            />
          ) : (
            <ArrowForwardIosOutlinedIcon
              onClick={props.closeNavbarHandler}
              className="colleps_arrow"
            />
          )}
        </IconButton>
      </div>

      <div className="navbar_logo_dev">
        <div>
          <img src="/urban-logo.svg" alt="Urban Ledger Logo" width="260px" />
        </div>
      </div>

      <List className={`navlist ${styles.link_list_navbar}`}>
        <FormControlLabel
          className="sandbox-mode-section"
          control={
            <Switch
              checked={props.sandboxMode}
              onChange={props.handleMode}
              name="sandbox_mode"
            />
          }
          label="Sandbox Mode"
        />
        {navs.map((nav, index) => {
          {
            if (navbarData[nav.id] !== undefined || nav.id == "Default") {
              return (
                <>
                  <NavLink
                    onMouseEnter={props.closeNavbarHandler}
                    onMouseLeave={props.openNavbarHandler}
                    className={styles.navlink}
                    activeClassName={!nav.submenu ? styles.navlink_active : ""}
                    to={nav.url}
                    key={index}
                    onClick={nav.submenu ? nav.onclick : ""}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <img src={nav.icon} alt="" />
                      </ListItemIcon>
                      <ListItemText
                        className="navbar_lits_item_text"
                        primary={nav.title}
                      />
                      {nav.submenu ? (
                        nav.open ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      ) : (
                        ""
                      )}
                    </ListItem>
                  </NavLink>
                  {nav.submenu ? (
                    <div className="configuration_collaps">
                      {nav.submenu.map((s) => {
                        {
                          if (navbarData[s.id] !== undefined) {
                            if (
                              s.custodian_page &&
                              !props.userData.is_first_custodian &&
                              !props.userData.is_second_custodian
                            ) {
                              return null;
                            }
                            return (
                              <>
                                <Collapse
                                  in={nav.open}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List component="div" disablePadding>
                                    <NavLink
                                      onMouseEnter={props.closeNavbarHandler}
                                      onMouseLeave={props.openNavbarHandler}
                                      className={styles.navlink}
                                      to={s.link}
                                    >
                                      <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon>
                                          <img src={s.icon} alt="" />
                                        </ListItemIcon>

                                        <ListItemText primary={s.title} />
                                      </ListItemButton>
                                    </NavLink>
                                  </List>
                                </Collapse>
                              </>
                            );
                          }
                        }
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            }
          }
        })}

        <ListItem
          onMouseEnter={props.closeNavbarHandler}
          onMouseLeave={props.openNavbarHandler}
          button
          onClick={() => props.onLogout()}
        >
          <ListItemIcon>
            <img src="/logout-01.svg" alt="" />
            {/* <LogoutIcon className="logout_icon" /> */}
          </ListItemIcon>
          <ListItemText className="logout_text" primary={"Logout"} />
        </ListItem>
      </List>
    </Paper>
  );
}
const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
    NavBarData: state.auth.NavbarAccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapseNavBar);
