import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./Emirates.css";
import "../viewdoc.css";
import CustomizedDialogs from "../viewdoc";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import AxiosBase from "../../../../networkRequest/axiosBase";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import UploadDoc from "../UploadDoc";
import { toast } from "react-toastify";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";

const Input = styled("input")({
  display: "none",
});

function EmiratesId(props) {
  const { rolePermission } = props;
  const [showPopup, setShowPopup] = useState(true);
  const inputFile = useRef(null);
  const search = useLocation().search;
  const dtlImgUrl = new URLSearchParams(search).get("dtlImgUrl");
  const customerId = new URLSearchParams(search).get("customerId");
  const name = new URLSearchParams(search).get("name");
  const kycId = new URLSearchParams(search).get("kycId");

  const styles = {
    popUp: {
      width: "230px",
      height: "80px",
      zIndex: "1",
      backgroundColor: "white",
      position: "absolute",
      boxShadow: "0px 5px 10px grey",
      borderRadius: "5px",
      paddingTop: "15px",
      left: "600px",
      top: "140px",
    },
    popUpButton: {
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      color: "#1058ff",
      display: "flex",
      alignItems: "center",
    },
    popUpButtonImage: { height: "20px", margin: "0px 5px" },
  };

  const uploaddocument = () => {
    setUploadDoc(true);
  };
  const handleUploadDocClose = () => {
    setUploadDoc(false);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const handleDocumentClose = () => {
    setOpen(false);
  };

  const handleData = (data1) => {
    if (data1 && data1.url && data1.emiratesData && data1.emiratesData) {
      data1.emiratesData.expiryDate = new Date(
        moment(
          data1.emiratesData.expiryDate.split("/").reverse().join("-")
        ).format("YYYY-MM-DD")
      );
      setData({
        ...data,
        emiratesImgUrl: data1.url,
        emiratesData: data1?.emiratesData,
      });

      props.handleEmiratessetdetails({
        ...data,
        emiratesImgUrl: data1.url,
        emiratesData: data1?.emiratesData,
      });
      setUploadDoc(false);
    } else {
      setUploadDoc(false);

      return (
        toast("Invalid File for Emirates ID"),
        {
          type: "error",
          autoClose: 4000,
        }
      );
    }
  };
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState();
  const [emiratesLoader, setEmiratesLoader] = React.useState(false);
  const [uploadDoc, setUploadDoc] = React.useState(false);

  useEffect(() => {}, [emiratesLoader]);

  const onFileUpload = async (e, type) => {
    try {
      if (type === "EMIRATES_ID") {
        //   setTimeout(() => {
        //     setEmiratesLoader(true)
        // }, 2000);

        setEmiratesLoader(true);
      }

      const file = e.target.files[0];
      const formData = new FormData();

      formData.append("file", file);

      formData.append("type", type);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      const res = await AxiosBase.post(
        `/admin/kyc/upload/${kycId}`,
        formData,
        config
      );
      if (type === "EMIRATES_ID") {
        setData({
          ...data,
          emiratesImgUrl: res.data.url,
        });
        props.setData(res.data.data);
        setEmiratesLoader(false);
        return true;
      }
      setData({
        ...data,
        dtlImgUrl: res.data.url,
      });
      props.setData(res.data.data);
      return false;
    } catch (e) {
      console.log("error", e);
    }
  };

  const url = [
    {
      name: "Emirates ID",
      url: data.emiratesImgUrl
        ? data.emiratesImgUrl
        : "/default-upload-doc.svg",
    },
  ];

  useEffect(() => {
    if (kycId != null) {
      AxiosBase.get(`/admin/kyc/view-kyc-details/${kycId}`).then((response) => {
        setData(response.data);
      });
    }
  }, []);

  const setloader = async (s) => {
    setEmiratesLoader(s);
    props.handleEmiratesLoader({
      status: s,
    });
  };

  return (
    <>
      <Card
        className="emiratesID"
        sx={{
          // width: 500,
          width: "100%",
          marginInline: 2,
          maxHeight: 380,
          border: "0.4px solid #dadada",
          mt: "20px",
        }}
      >
        <Tooltip
          title={
            props.data.tradeLicenseVerified == "Rejected" ||
            props.data.emiratesIdVerified == "Rejected"
              ? "KYC already Rejected"
              : props.data.emiratesIdVerified == "Approved"
              ? "KYC already Approved"
              : ""
          }
        >
          <CardHeader
            action={
              props.disableUpload == "true" ? (
                <></>
              ) : (
                <IconButton aria-label="settings">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    ref={inputFile}
                    type="file"
                    onChange={(e) => onFileUpload(e, "EMIRATES_ID")}
                  />

                  {rolePermission && rolePermission.includes("EDIT") && (
                    <Button
                      disabled={props.disablecontent ? true : false}
                      style={{
                        backgroundColor:
                          props.disablecontent ||
                          props.data.tradeLicenseVerified == "Rejected" ||
                          props.data.emiratesIdVerified == "Rejected"
                            ? "#a3a1a1"
                            : "#1058ff",
                        fontSize: "10px",
                        marginTop: "-5px",
                        color: "white",
                      }}
                      variant="contained"
                      onClick={uploaddocument}
                      // onClick={() => {
                      //   inputFile.current.click();
                      // }}
                      startIcon={<BackupOutlinedIcon />}
                      component="span"
                    >
                      Upload Document
                    </Button>
                  )}
                </IconButton>
              )
            }
            title="Emirates ID "
            className="emirates_view_inner_img"
          />
        </Tooltip>
        {emiratesLoader ? (
          <div className="imagecontainer">
            <div className="center">
              <CircularProgress />
            </div>
          </div>
        ) : props?.data?.emiratesImgUrl &&
          props?.data?.emiratesImgUrl.endsWith("pdf") ? (
          <embed
            src={props?.data?.emiratesImgUrl}
            width="100%"
            height="380px"
            className="emirate_view_inner_img"
          />
        ) : (
          <CardMedia
            className="emirate_view_inner_img"
            component="img"
            height="100%"
            image={
              props?.data?.emiratesImgUrl
                ? props?.data?.emiratesImgUrl
                : "/default-upload-doc.svg"
            }
            alt="Emirates ID"
            onClick={openDialog}
          />
        )}
      </Card>
      {open == true ? (
        <CustomizedDialogs
          open={open}
          handleDocumentClose={handleDocumentClose}
          item={url}
          rolePermission={rolePermission}
        />
      ) : (
        <></>
      )}
      {uploadDoc == true ? (
        <UploadDoc
          open={uploadDoc}
          handleDocumentClose={handleUploadDocClose}
          handleData={handleData}
          setloader={setloader}
          type="EMIRATES_ID"
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default EmiratesId;
