import AxiosBase from "../axiosBase";

export const getAllMenus = (
  pageNo,
  rowsPerPage,
  sortBy,
  sortOrder,
  search,
  filter,
  dateparams
) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(
      `/admin/menus?pageNo=${pageNo}&rowsPerPage=${rowsPerPage}&sortOrder=${sortOrder}` +
        `&sortBy=${sortBy}` +
        `&search=${search ? search : ""}` +
        `&filter=${filter}` +
        dateparams
    ).then((response) => {
      resolve(response);
    });
  });
};
export const getAllRoles = (
  pageNo,
  rowsPerPage,
  sortBy,
  sortOrder,
  search,
  filter,
  dateparams
) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(
      `/admin/roles?pageNo=${pageNo}&rowsPerPage=${rowsPerPage}&sortOrder=${sortOrder}` +
        `&sortBy=${sortBy}` +
        `&search=${search ? search : ""}` +
        `&filter=${filter}` +
        dateparams
    ).then((response) => {
      resolve(response);
    });
  });
};

export const generatePasswordLink = (data) => {
  return AxiosBase.post(`/admin/password/generatePasswordResetLink`, data);
};

export const getAllUsers = (
  pageNo,
  string,
  rowsPerPage,
  sortBy,
  sortOrder,
  search,
  filter,
  SearchIndex
) => {
  return new Promise((resolve, reject) => {
    if (string !== "") {
      filter = JSON.parse(filter);
      filter.splice(SearchIndex, 1);
      filter = filter.length !== 0 ? JSON.stringify(filter) : "";
    }
    AxiosBase.get(
      `/admin/user?pageNo=${pageNo}&${string}rowsPerPage=${rowsPerPage}&sortOrder=${sortOrder}` +
        `&sortBy=${sortBy}` +
        `&search=${search ? search : ""}` +
        `&filter=${filter}`
    ).then((response) => {
      resolve(response);
    });
  });
};

export const getUsersById = (id) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/admin/user/${id}`).then((response) => {
      resolve(response);
    });
  });
};
export const AddUser = (reqBody) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/admin/user`, reqBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("error", err.response);
        resolve(err.response);
      });
  });
};

export const UpdateUser = (reqBody) => {
  return new Promise((resolve, reject) => {
    AxiosBase.patch(`/admin/user/${reqBody.id}`, reqBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("error", err.response);
        resolve(err.response);
      });
  });
};

export const getTemporaryPassword = () => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/admin/user/temp-password`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("error", err.response);
        resolve(err.response);
      });
  });
};

export const getRolesById = (id) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/admin/roles/${id}`).then((response) => {
      resolve(response);
    });
  });
};

export const getRoles = (id) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/admin/roles`).then((response) => {
      resolve(response);
    });
  });
};

export const AddRole = (reqBody) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/admin/roles`, reqBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("error", err.response);
        resolve(err.response);
      });
  });
};

export const UpdateRole = (reqBody) => {
  return new Promise((resolve, reject) => {
    AxiosBase.patch(`/admin/roles/${reqBody.id}`, reqBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("error", err.response);
        resolve(err.response);
      });
  });
};
