import React from "react";
import Paper from "@mui/material/Paper";
import DataTable from "../data-table";

function MainDataLayout() {
  return (
    <div>
      <Paper elevation={1} style={{ borderRadius: "25px" }}>
        <DataTable />
      </Paper>
    </div>
  );
}

export default MainDataLayout;
