import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Slider from "react-slick";
import Carousel from "react-material-ui-carousel";
import "./UploadDoc.css";

const Input = styled("input")({
  display: "none",
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function SeccessfullUpload(props) {
  const [open, setOpen] = React.useState(props.open);
  const inputFile = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  var items = props.item;

  return (
    <div className="success_upload">
      <BootstrapDialog
        onClose={props.handleDocumentClose}
        className="success_dilog_box"
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ textAlign: "center" }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className="customized-dialog-title"
          onClose={props.handleDocumentClose}
          sx={{ textAlign: "center" }}
        >
          Documents Upload Successfully
        </BootstrapDialogTitle>
        <DialogContent>
          <div className="success_dilog_body">
            <img src="/Upload-Successfull.svg" alt="" />
          </div>
          <div>
            <Button
              className="upload_btn"
              variant="contained"
              onClick={props.handleDocumentClose}
            >
              OKAY
            </Button>
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button
            className="success_ok_btn"
            autoFocus
            onClick={props.handleDocumentClose}
          >
            OKAY
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}
