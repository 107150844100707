import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import styles from "./login.module.css";
import "font-awesome/css/font-awesome.css";
import { login } from "../../networkRequest/request/auth";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/auth";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";
import AxiosBase from "../../networkRequest/axiosBase";
import OtpInput from "react-otp-input";

function Login(props) {
  const recaptchaRef = useRef(null);
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpActive, setOtpActive] = useState(false);
  const history = useNavigate();
  const [token, setToken] = useState("");
  const [timer, setTimer] = useState("00");
  const Ref = useRef(null);
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    return {
      total,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(seconds > 9 ? seconds : "0" + seconds);
    }
  };

  const clearTimer = (e) => {
    setTimer("30");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
      if (new Date() > e) {
        // resetKek();
      }
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  useEffect(() => {
    props.onSessionWarning({ value: false });
    props.onLockedOut({ value: false });
  }, []);

  useEffect(() => {
    if (props.error) {
      props.onResetError();
      toast(props.error || "Wrong email or password!", {
        type: "error",
        autoClose: 5000,
      });
      setSpinner(false);
    }
  }, [props.error]);

  const resendOtp = async (e) => {
    try {
      e.preventDefault();
      const res = await AxiosBase.post("/admin/resend-otp", {
        token,
      });
      console.log(res.data.message);
      toast(res.data.message, {
        type: "success",
        autoClose: 5000,
      });
      clearTimer(getDeadTime());
    } catch (error) {
      return toast(error?.response?.data?.message || "Something went wrong!", {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  const submitClick = async () => {
    const captchaToken = await recaptchaRef.current.getValue();
    if (!captchaToken) {
      return toast("Captcha is required", {
        type: "error",
        autoClose: 5000,
      });
    }
    setSpinner(true);
    recaptchaRef.current.reset();
    let otp_expiry = localStorage.getItem(`EOL${emailId}`);
    let system_id = null;
    if (moment().isBefore(otp_expiry)) {
      system_id = localStorage.getItem(`sid_${emailId}`);
    }
    try {
      const res = await AxiosBase.post("/admin/login", {
        email_id: emailId,
        password,
        captcha_token: captchaToken,
        system_id,
      });
      if (!res.data.send_otp) {
        if (res.data.reset_pass) {
          res.data.userData.isTemporaryPasswordMsg =
            "Your password has expired and needs to be reset. This is because you have not changed your password in the last 90 days as required by our security policy.";
        }
        props.onLogin({ is_error: false, res });
      } else {
        toast(res?.data?.message, {
          type: "success",
          autoClose: 6000,
        });
        clearTimer(getDeadTime());
        setToken(res.data.token);
        setOtpActive(true);
        setSpinner(false);
      }
    } catch (error) {
      props.onLogin({ is_error: true, error: error?.response?.data });
    }
  };

  const verifyOtp = async () => {
    setSpinner(true);
    try {
      const res = await AxiosBase.post(
        "/admin/verify-otp",
        { otp },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem(
        `EOL${res.data.userData.email_id}`,
        moment().startOf("day").add(14, "day").toISOString()
      );
      localStorage.setItem(
        `sid_${res.data.userData.email_id}`,
        res.data.system_id
      );
      if (res.data.reset_pass) {
        res.data.userData.isTemporaryPasswordMsg =
          "Your password has expired and needs to be reset. This is because you have not changed your password in the last 90 days as required by our security policy.";
      }
      props.onLogin({ is_error: false, res });
    } catch (error) {
      props.onLogin({ is_error: true, error: error?.response?.data });
    }
  };

  if (props.isAuthenticated && !props.isTemporaryPassword) {
    window.location.href = "/dashboard";
    return <></>;
    // return <Navigate to="/dashboard" />;
  }
  if (props.isTemporaryPassword) {
    window.location.href = "/reset";
    return <></>;
    // return <Navigate to="/reset" />;
  }

  return (
    <>
      <div
        className={styles.outer}
        style={{ backgroundImage: 'url("/images/Login-BG-01.svg")' }}
      >
        <div className={styles.loginBox}>
          <div
            className={styles.inner}
            style={{
              "text-align": "center",
              "margin-bottom": "1.5rem",
            }}
          >
            <img
              src="/urban-logo.svg"
              alt="UL_Logo"
              style={{ width: "210px", height: "48px" }}
            />
          </div>
          <div className={styles.inner}>
            <div
              className={`${styles.justify_content_center} ${styles.align_items_center} ${styles.h_100}`}
            >
              <div className={styles.loginContainer}>
                <div className={styles.card}>
                  <div className={styles.card_body}>
                    <h4 className={styles.heading}>Sign In</h4>
                    {!otpActive ? (
                      <form className={styles.form} autocomplete="off">
                        <div className={styles.inputContainer}>
                          <TextField
                            id="emailId"
                            name="emailId"
                            placeholder="Enter Email ID"
                            value={emailId}
                            onChange={(e) => setEmailId(e.target.value)}
                            fullWidth
                            variant="outlined"
                            size="small"
                            className={styles.login_input}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon htmlColor="#8f8c8cbf" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className={styles.inputContainer}>
                          <div className={styles.inputContainer}>
                            <TextField
                              id="pass"
                              type="password"
                              name="pass"
                              value={password}
                              placeholder="Enter Password"
                              onChange={(e) => setPassword(e.target.value)}
                              // onKeyDown={(e) => {
                              //   if (e.key === "Unidentified") {
                              //     setPassword("");
                              //   } else {
                              //     setPassword(`${password}${e.key}`);
                              //   }
                              // }}
                              fullWidth
                              variant="outlined"
                              size="small"
                              autoComplete="off"
                              className={styles.login_input}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <KeyIcon htmlColor="#8f8c8cbf" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <input
                              name="password1"
                              type="password"
                              value={`${Date.now()}${Date.now()}`}
                              autocomplete="new-password"
                              style={{ display: "none" }}
                            ></input>
                          </div>
                        </div>
                        {/* <a className={styles.forgetPassword}>Forgot Password?</a> */}
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        />
                        <button
                          type="submit"
                          className={styles.signupBtn}
                          id="login-btn"
                          disabled={spinner ? true : false}
                          onClick={(e) => {
                            e.preventDefault();
                            submitClick();
                          }}
                        >
                          {spinner ? (
                            <i
                              class="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            "Login"
                          )}
                        </button>
                      </form>
                    ) : (
                      <form className={styles.form} autocomplete="off">
                        <div className={styles.otpInputContainer}>
                          <label>Enter OTP</label>
                          <OtpInput
                            value={otp}
                            onChange={(value) => setOtp(value)}
                            numInputs={6}
                            isInputNum={true}
                            shouldAutoFocus={false}
                            inputStyle={"otp-input"}
                            separator={<span> &nbsp; </span>}
                            isInputSecure={true}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                        <button
                          type="submit"
                          className={styles.signupBtn}
                          id="login-btn"
                          disabled={spinner ? true : false}
                          onClick={(e) => {
                            e.preventDefault();
                            verifyOtp();
                          }}
                        >
                          {spinner ? (
                            <i
                              class="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            "Verify"
                          )}
                        </button>
                        {timer == "00" ? (
                          <button
                            type="submit"
                            className={styles.signupBtn}
                            id="login-btn"
                            disabled={timer !== "00" ? true : false}
                            onClick={(e) => {
                              resendOtp(e);
                            }}
                          >
                            Resend OTP
                          </button>
                        ) : (
                          <div className={styles.loginResend}>
                            <span className={styles.loginResendText}>
                              Resend OTP
                            </span>
                            <span className={styles.loginResendTimer}>
                              {timer}
                            </span>
                          </div>
                        )}
                      </form>
                    )}
                  </div>
                </div>
                <div className={`${styles.card} ${styles.bottom_card}`}></div>
              </div>
            </div>
            <div className={styles.login_footer}>
              <a href="https://geturbanledger.com/" target="_blank">
                &copy; UrbanLedger
              </a>{" "}
              |
              <a href="https://geturbanledger.com/contact" target="_blank">
                Contact us
              </a>{" "}
              |
              <a href="https://geturbanledger.com/help" target="_blank">
                Help
              </a>{" "}
              |
              <a
                href="https://geturbanledger.com/terms-and-conditions"
                target="_blank"
              >
                Terms & Conditions
              </a>{" "}
              |
              <a
                href="https://geturbanledger.com/cookie-policy"
                target="_blank"
              >
                Cookie Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
    accessToken: state.auth.accessToken,
    refreshToken: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated,
    isTemporaryPassword: state.auth.isTemporaryPassword,
    error: state.auth.error,
    loading: state.auth.loading,
    sessionWarning: state.auth.sessionWarning,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (data) => dispatch(actions.authLogin(data)),
    onLogout: () => dispatch(actions.logoutUser()),
    onResetError: () => dispatch(actions.resetError()),
    onSessionWarning: (data) => dispatch(actions.sessionWarning(data)),
    onLockedOut: (data) => dispatch(actions.lockedOut(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
