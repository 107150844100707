import { Route, Routes } from "react-router-dom";
import Topheader from "../../universal-components/header/topHeader";
import MerchantUserList from "../../Components/merchant/user-list";
import MerchantUserDetail from "../../Components/merchant/user-list/user-detail";
function UserList() {
  return (
    <div style={{ padding: "15px", borderRadius: "5rem", width: "98%" }}>
      <Topheader />
      <Routes>
        <Route exact path="" element={<MerchantUserList />} />
        <Route
          exact
          path=":id"
          element={<MerchantUserDetail />}
        />
      </Routes>
    </div>
  );
}
export default UserList;
