import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./UserInfo.css";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneInput from "react-phone-input-2";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { generatePasswordLink } from "../../../networkRequest/request/useraccesscontrol";

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const UserInfo = (props) => {
  const { rolePermission } = props;
  const [user, setUser] = useState({
    first_name: "",
    mobile_no: "",
    last_name: "",
    email_id: "",
    start_at: null,
    expiryDate: null,
    is_active: false,
  });
  const [userId, setUserId] = useState("");
  const [enable, setEnable] = useState(false);
  const [passwordKey, setPasswordKey] = useState(true);
  const [rotateFlag, setRotateFlag] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    setUser({ ...props.data });
    setUserId(props.userId);
  }, [props.data]);

  const saveData = async (e) => {
    props.submitCode();
  };

  const getpassword = async (e) => {
    props.getPassword(user);
  };

  const [firsterror, setFirstError] = useState({
    error: false,
    message: "",
  });

  const [lasterror, setLastError] = useState({
    error: false,
    message: "",
  });

  const [EmailError, setEmailError] = useState({
    error: false,
    message: "",
  });

  const [numbererror, setNumberError] = useState({
    error: false,
    message: "",
  });

  const GeneratePassword = async () => {
    try {
      setRotateFlag(true);
      let res = await generatePasswordLink({ id: user._id });
      setRotateFlag(false);
      if (res.data.status) {
        history("/users");
        toast(res.data.message, {
          type: "success",
          autoClose: 2000,
        });
      }
    } catch (error) {
      setRotateFlag(false);
      if (error?.response?.status == 429) {
        toast(error?.response?.data?.message || "Something went wrong!", {
          type: "error",
          autoClose: 2000,
        });
        return false;
      }
      toast("Something went wrong!", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  const CHARACTER_LIMIT = 50;
  const ZIP_LIMIT = 6;
  const mobile_LIMIT = 16;

  return (
    <>
      <div className="user_information_main_div">
        {rotateFlag ? (
          <div className="no_data_found loader_data_tbl">
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid item xs={4}>
              <p>
                First Name<span>*</span>
              </p>
            </Grid>
            <Grid item xs={8}>
              <CssTextField
                id="new-menu"
                className="create_merchant_input_feild"
                variant="outlined"
                type="text"
                placeholder="First name"
                fullWidth
                value={user.first_name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="create_merchn_inpt_icon">
                      <IconButton color="primary">
                        <PersonOutlinedIcon className="merchant_inpt_icon" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  if (e.target.value.length < 1 || e.target.value.length > 50) {
                    setFirstError({
                      error: true,
                      message: "First Name must be between 3 to 15 characters",
                    });
                  } else {
                    setFirstError({ error: false, message: "" });
                  }
                  props.userdatainfo({
                    ...user,
                    first_name: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <p>
                Last Name<span>*</span>
              </p>
            </Grid>
            <Grid item xs={8}>
              <CssTextField
                id="new-menu"
                className="create_merchant_input_feild"
                variant="outlined"
                placeholder="Last Name"
                fullWidth
                value={user.last_name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="create_merchn_inpt_icon">
                      <IconButton color="primary">
                        <PersonOutlinedIcon className="merchant_inpt_icon" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  if (e.target.value.length < 1 || e.target.value.length > 50) {
                    setLastError({
                      error: true,
                      message: "Last Name must be between 3 to 15 characters",
                    });
                  } else {
                    setLastError({ error: false, message: "" });
                  }
                  props.userdatainfo({
                    ...user,
                    last_name: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <p>Email</p>
            </Grid>
            <Grid item xs={8}>
              <CssTextField
                id="new-menu"
                className="create_merchant_input_feild"
                variant="outlined"
                placeholder="Enter Email Here"
                fullWidth
                value={user.email_id}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="create_merchn_inpt_icon">
                      <IconButton color="primary">
                        <img
                          src={`${process.env.PUBLIC_URL}/top-email.svg`}
                          alt=""
                          style={{ width: "25px", height: "25px" }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ) &&
                    e.target.value !== ""
                  ) {
                    setEmailError({
                      error: true,
                      message: "Please enter Business Email Id",
                    });
                  } else {
                    setEmailError({ error: false, message: "" });
                  }
                  props.userdatainfo({ ...user, email_id: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <p>Phone Number</p>
            </Grid>
            <Grid item xs={8}>
              <PhoneInput
                className="mobile-input"
                name="phone"
                country={"ae"}
                countryCodeEditable={true}
                value={user.mobile_no}
                onChange={(e, countrycode) => {
                  let val = e.replace(/-|\s/g, "");
                  if (val.length < 12 || val.length > 13) {
                    setNumberError({
                      error: true,
                      message: "Please enter valid mobile number",
                    });
                  } else {
                    setNumberError({ error: false, message: "" });
                  }
                  props.userdatainfo({
                    ...user,
                    mobile_no: val,
                    mobile_code: countrycode.dialCode,
                  });
                }}
              />
            </Grid>
            {userId != "" && rolePermission.includes("EDIT") && (
              <>
                <Grid item xs={4}>
                  <p>Reset Password</p>
                </Grid>
                <Grid
                  item
                  xs={8}
                  onClick={() => {
                    GeneratePassword();
                  }}
                >
                  <BootstrapButton variant="contained" disableRipple fullWidth>
                    Reset Password With Email
                  </BootstrapButton>
                </Grid>
                <Grid item xs={4}>
                  <p>Set Temporary Password</p>
                </Grid>
                <Grid item xs={8}>
                  <BootstrapButton1
                    variant="contained"
                    disableRipple
                    fullWidth
                    onClick={(event) => getpassword(event)}
                  >
                    Set A Temporary Password
                  </BootstrapButton1>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={8} style={{ position: "relative" }}>
                  <CssTextField
                    id="new-menu"
                    className="create_merchant_input_feild"
                    variant="outlined"
                    placeholder="Password"
                    fullWidth
                    value={user.pass}
                    disabled={true}
                    type={passwordKey ? "password" : "text"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment className="create_merchn_inpt_icon">
                          <IconButton color="primary">
                            <img
                              src="/pin-icon2.svg"
                              alt=""
                              style={{
                                width: "25px",
                                height: "25px",
                                color: "#1058ff !important",
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment>
                          <img
                            onClick={() => {
                              setPasswordKey(!passwordKey);
                            }}
                            src={passwordKey ? "/eyeclose.svg" : "/eyeopen.svg"}
                            alt=""
                            style={{
                              width: "25px",
                              height: "25px",
                              color: "#1058ff !important",
                              cursor: "pointer",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <img
                    src="/copy.svg"
                    style={{
                      width: "24px",
                      position: "absolute",
                      right: "-34px",
                      marginTop: "7px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(user?.pass);
                      return toast("Password copied successfully", {
                        type: "success",
                        autoClose: 2000,
                      });
                    }}
                  ></img>
                </Grid>
              </>
            )}
            <Grid item xs={4}>
              <p>Start Date</p>
            </Grid>
            <Grid item xs={8} className="CalendarDesktop">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={2}>
                  <DesktopDatePicker
                    value={user.start_at}
                    minDate={new Date()}
                    onChange={(newValue) => {
                      props.userdatainfo({ ...user, start_at: newValue });
                    }}
                    // inputFormat = 'dd MMMM,yyyy'
                    renderInput={(params) => <TextField {...params} />}
                    label="Start Date"
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <p>Expiry Date</p>
            </Grid>
            <Grid item xs={8} className="CalendarDesktop">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={2}>
                  <DesktopDatePicker
                    label="Expiry Date"
                    value={user.expiryDate}
                    minDate={
                      user.start_at ? new Date(user.start_at) : new Date()
                    }
                    onChange={(newValue) => {
                      props.userdatainfo({ ...user, expiryDate: newValue });
                    }}
                    // inputFormat = 'dd MMMM,yyyy'
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <p>Status</p>
            </Grid>
            {/* </> : <></>} */}
            <Grid item xs={8} style={{ display: "flex" }}>
              <IOSSwitch
                sx={{ m: 1 }}
                checked={user.is_active}
                onChange={() => {
                  props.userdatainfo({
                    ...user,
                    is_active: !user.is_active,
                  });
                }}
              />
              <div
                style={{
                  marginTop: "2%",
                  marginLeft: "2%",
                  color: user.is_active ? "#43caa6" : "grey",
                }}
              >
                {user.is_active ? "Active" : "InActive"}
              </div>
            </Grid>
            {userId != "" && rolePermission.includes("EDIT") && (
              <>
                <Grid item xs={4}>
                  <p>Account Locked</p>
                </Grid>
                <Grid item xs={8} style={{ display: "flex" }}>
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={user.is_locked}
                    onChange={() => {
                      props.userdatainfo({
                        ...user,
                        is_locked: !user.is_locked,
                      });
                    }}
                  />
                  <div
                    style={{
                      marginTop: "2%",
                      marginLeft: "2%",
                      color: user.is_locked ? "#FF5B55" : "grey",
                    }}
                  >
                    {user.is_locked ? "Locked" : ""}
                  </div>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <div style={{ hight: "30px" }}></div>
              <div className="merchant_edit_button_dev Fees_Margin_save_cancel_btn">
                <Button
                  className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={() => {
                    history("/users");
                  }}
                >
                  Cancel
                </Button>
                {rolePermission.includes(userId != "" ? "EDIT" : "ADD") && (
                  <Button
                    className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                    variant="contained"
                    onClick={(event) => saveData(event)}
                  >
                    Save
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#34C27B",
  borderColor: "#34C27B",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#34C27B",
    borderColor: "#34C27B",
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const BootstrapButton1 = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#1058ff",
  borderColor: "#1058ff",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#1058ff",
    borderColor: "#1058ff",
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

export default UserInfo;
