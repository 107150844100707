import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./AcquirerTab.css";
import { toast } from "react-toastify";
import BasicAndInformation from "./BasicAndInformation/BasicInformation";
import MOPSettings from "./MOPSettings/MOPSettings";
import OtherSettings from "./OtherSettings/OtherSettings";
import ServicesMapping from "./ServicesMapping/ServicesMapping";
import StatusCodeMapping from "./StatusCodeMapping/StatusCodeMapping";
import TDRSettings from "./TDRSettings/TDRSettings";
import TaxSettings from "./TaxSettings/TaxSettings";
import RefundCharges from "./../../RefundCharges/RefundCharges";
import AcquirerMapping from "./AcquirerMapping/AcquirerMapping";
import Credential from "./Credential/Credential";
import DecimalSettings from "./DecimalSettings";
import { useNavigate } from "react-router-dom";
import NavbarHoc from "../../../pages/navhoc/index";
import Config from "../../../config.json";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import {
  GetAcquirer,
  GetAllStatus,
  GetCurrency,
  AddAcquirer,
  UpdateAcquirer,
} from "../../../networkRequest/request/acquirer";
import axios from "axios";
// import AxiosBase from "./../networkRequest/axiosBase";
import {
  getMerchantFees,
  updateMerchantFees,
} from "../../../networkRequest/request/configuration";
import baseURL from "../../../utils/base_url";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: "15px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AcquireTab = (props) => {
  const [rolePermission, setRolePermission] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [allStatusCode, setAllStatusCode] = React.useState([]);
  const [allStatusCodeMapping, setAllStatusCodeMapping] = React.useState([]);
  const [allCurrency, setAllCurrency] = React.useState([]);
  const [spinner, setSpinner] = useState(false);
  const [feesdata, setFeesData] = useState({});
  const history = useNavigate();
  const [refundCharges, setRefundCharges] = React.useState(null);
  const [acquirers, setAcquirers] = React.useState(null);

  React.useEffect(() => {
    if (props.value) {
      CheckAcquirer();
    }
  }, [props.value]);

  const CheckAcquirer = async () => {
    let valObj = JSON.parse(JSON.stringify(props?.value));
    if (!valObj?.acquirer_services) {
      valObj["services"] = {
        creditcards: {},
        debitcards: {},
        upis: {},
        wallets: {},
        banks: {},
        applepay: {},
        googlepay: {},
      };
    } else {
      valObj["services"] = valObj?.acquirer_services;
    }
    setAllStatusCodeMapping(valObj);
    getConfigData();
    axios.get(`${baseURL()}/api/country-master`).then((res) => {
      setAllCurrency(res?.data);
    });
    let statuscode = await GetAllStatus();
    statuscode?.data?.data && setAllStatusCode(statuscode?.data?.data);
  };

  const UpdateAcquirerFunction = async (req = {}, id) => {
    try {
      let obj = {
        acquirer_services: req?.services,
        acquirer_other_setting: req?.acquirer_other_setting,
        acquirer_decimal_mdr_format: req?.acquirer_decimal_mdr_format,
      };
      obj._id = props.customerId;
      let acquirer_services = {};
      Object.keys(obj.acquirer_services).forEach(function (key, index) {
        if (obj.acquirer_services[key]?.status) {
          Object.keys(obj.acquirer_services[key]).forEach(function (
            key1,
            index1
          ) {
            if (obj.acquirer_services[key][key1] && key1 != "status") {
              obj.acquirer_services[key][key1] = Number(
                obj.acquirer_services[key][key1]
              );
            }
          });
          acquirer_services[key] = obj.acquirer_services[key];
        } else {
          acquirer_services[key] = {};
        }
      });
      obj.acquirer_services = acquirer_services;
      setSpinner(true);
      console.log(obj);
      var res = await updateMerchantFees(obj);
      if (res?.status == 200) {
        setSpinner(false);
        props.getConfigDetail();
        props.handleChange({
          ...props.value,
          ...obj,
        });
        return toast("Setting Updated Successfully.", {
          type: "success",
          autoClose: 2000,
        });
      } else {
        setSpinner(false);
        props.getConfigDetail();
        return toast(res?.response?.data?.message || "Something went wrong", {
          type: "error",
          autoClose: 2000,
        });
      }
    } catch (err) {
      console.log(err);
      props.getConfigDetail();
      return toast(res?.response?.data?.message || "Something went wrong", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  const getConfigData = () => {
    setSpinner(true);
    getMerchantFees(props.customerId).then((response) => {
      setRefundCharges(response?.data?.refund_charges);
      setAcquirers(response?.data?.acquirers ? response?.data?.acquirers : []);
      setSpinner(false);
    });
  };

  const saveFees = async (refundCharges = null, acquirers = null) => {
    try {
      let feesdata = {};
      feesdata._id = props.customerId;
      if (refundCharges) {
        feesdata.refund_charges = refundCharges;
      }
      if (acquirers) {
        feesdata.acquirers = acquirers;
      }
      setSpinner(true);
      var res = await updateMerchantFees(feesdata);
      if (res?.status == 200) {
        setSpinner(false);
        getConfigData();
        return toast("Setting Updated Successfully.", {
          type: "success",
          autoClose: 2000,
        });
      } else {
        setSpinner(false);
        getConfigData();
        return toast(res?.response?.data?.message || "Something went wrong", {
          type: "error",
          autoClose: 2000,
        });
      }
    } catch (err) {
      setSpinner(false);
      getConfigData();
      return toast(res?.response?.data?.message || "Something went wrong", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  const updateRefundCharges = (refund_charges) => {
    saveFees(refund_charges);
  };

  const updateAcquirers = (acquirers) => {
    saveFees(null, acquirers);
  };

  return (
    <NavbarHoc
      id={[Config.PermissionId.Acquirer, Config.PermissionId.Merchants]}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <div className="">
        <Box
          className="configuration_tab"
          sx={{ borderColor: "divider", ml: "-15px" }}
        >
          <Tabs
            value={selectedIndex}
            aria-label="basic tabs example"
            className="acquirer-tabs"
          >
            <Tab
              id="1"
              label="Basic Information"
              onClick={() => setSelectedIndex(0)}
              {...a11yProps(0)}
            />
            <Tab
              id="2"
              label="Services Mapping"
              onClick={() => setSelectedIndex(1)}
              {...a11yProps(1)}
            />
            <Tab
              id="3"
              label="Acquirer Mapping"
              onClick={() => setSelectedIndex(2)}
              {...a11yProps(2)}
            />
            <Tab
              id="4"
              label="MDR Settings"
              onClick={() => setSelectedIndex(3)}
              {...a11yProps(3)}
            />
            <Tab
              id="5"
              label="Other Settings"
              onClick={() => setSelectedIndex(4)}
              {...a11yProps(4)}
            />
            {/* <Tab
              id="6"
              label="Decimal precision"
              onClick={() => setSelectedIndex(5)}
              {...a11yProps(4)}
            /> */}
            {/* <Tab id="6" label="Refund Charges" onClick={() => setSelectedIndex(5)} {...a11yProps(4)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={selectedIndex} index={0}>
          <BasicAndInformation
            id={props.value?._id}
            allCurrency={allCurrency}
            allStatusCodeMapping={allStatusCodeMapping}
            rolePermission={rolePermission}
            UpdateAcquirerProp={UpdateAcquirerFunction}
          />
        </TabPanel>
        <TabPanel value={selectedIndex} index={1}>
          <ServicesMapping
            id={props.value?._id}
            allStatusCodeMapping={allStatusCodeMapping}
            rolePermission={rolePermission}
            UpdateAcquirerProp={UpdateAcquirerFunction}
          />
        </TabPanel>
        <TabPanel value={selectedIndex} index={2}>
          <AcquirerMapping
            acquirers={acquirers}
            rolePermission={rolePermission}
            updateAcquirerMapping={updateAcquirers}
          />
        </TabPanel>
        <TabPanel value={selectedIndex} index={3}>
          <TDRSettings
            id={props.value?._id}
            allStatusCodeMapping={allStatusCodeMapping}
            rolePermission={rolePermission}
            UpdateAcquirerProp={UpdateAcquirerFunction}
          />
        </TabPanel>
        <TabPanel value={selectedIndex} index={4}>
          <OtherSettings
            id={props.value?._id}
            allStatusCodeMapping={allStatusCodeMapping}
            rolePermission={rolePermission}
            UpdateAcquirerProp={UpdateAcquirerFunction}
          />
        </TabPanel>
        {/* <TabPanel value={selectedIndex} index={5}>
          <DecimalSettings
            id={props.value?._id}
            allStatusCodeMapping={allStatusCodeMapping}
            rolePermission={rolePermission}
            UpdateAcquirerProp={UpdateAcquirerFunction}
          />
        </TabPanel> */}
        {/* <TabPanel value={selectedIndex} index={5}>
            <RefundCharges
              refundCharges={refundCharges}
              rolePermission={rolePermission}
              updateRefundCharges={updateRefundCharges}
            />
          </TabPanel> */}
      </div>
    </NavbarHoc>
  );
};

export default AcquireTab;
