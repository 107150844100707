import React from 'react';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/styles';

const CssTextField = styled(TextField)({
    'input#new-menu':{
        width: "520px",
        height: "4px",
    }
});

function CustomerFees(){
    return (
        <div style={{backgroundColor:"#ffffff",padding:"15px"}}>

          <p style={{color:"#666666",fontWeight:600,fontSize:"20px"}}>Fees & Margin</p>
          <Divider/>

          <div style={{display:"flex",alignItems:"center",marginTop:"20px",width:"80%"}}>
            <div style={{width:"20%"}}>
                <p style={{color:"#666666",fontWeight:600,fontSize:"20px",marginBlockEnd:"40px"}}>Margin Percentage</p>
                <p style={{color:"#666666",fontWeight:600,fontSize:"20px",marginBlockStart:"40px",marginBlockEnd:"40px"}}>Fixed Fees</p>
            </div>
            <div style={{width:"80%"}}>
                <CssTextField id="new-menu" variant="outlined" sx={{marginBottom:"30px"}}/>
                <CssTextField id="new-menu" variant="outlined" sx={{marginBottom:"30px"}}/>
            </div>
          </div>

          <p style={{color:"#666666",fontWeight:600,fontSize:"20px"}}>Settlement Time</p>
          <Divider/>

          <div style={{display:"flex",alignItems:"center",marginTop:"20px",width:"80%"}}>
            <div style={{width:"20%"}}>
                <p style={{color:"#666666",fontWeight:600,fontSize:"20px",marginBlockEnd:"40px"}}>Settlement Period</p>
            </div>
            <div style={{width:"80%"}}>
                <CssTextField id="new-menu" variant="outlined" sx={{marginBottom:"30px"}}/>
            </div>
          </div>

          <p style={{color:"#666666",fontWeight:600,fontSize:"20px"}}>Taxes</p>
          <Divider/>

          <div style={{display:"flex",alignItems:"center",marginTop:"20px",width:"80%"}}>
            <div style={{width:"20%"}}>
                <p style={{color:"#666666",fontWeight:600,fontSize:"20px",marginBlockEnd:"40px"}}>VAT</p>
            </div>
            <div style={{width:"80%"}}>
                <CssTextField id="new-menu" variant="outlined" sx={{marginBottom:"30px"}}/>
            </div>
          </div>

        </div>
    )
}

export default CustomerFees