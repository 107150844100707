import React from "react";
import { useState, useEffect } from "react";
import "./TDRSettings.css";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const TDRData = [
  { Card: "Credit Card", type: "creditcards", Icon: "/creditCards.svg" },
  { Card: "Debit Card", type: "debitcards", Icon: "/creditCards.svg" },
  { Card: "UPI", type: "upis", Icon: "/upi.svg" },
  { Card: "Wallet", type: "wallets", Icon: "/wallet.svg" },
  { Card: "Net Banking", type: "banks", Icon: "/net-banking.svg" },
  { Card: "Apple Pay", type: "applepay", Icon: "/Apple Pay.svg" },
  { Card: "Google Pay", type: "googlepay", Icon: "/G pay.svg" },
];
const TDRSettings = (props) => {
  const {
    rolePermission,
    allStatusCodeMapping,
    id,
    AddAcquirerProp,
    UpdateAcquirerProp,
  } = props;
  const [loader, setLoader] = React.useState(false);
  const [controlDisable, setControlDisable] = React.useState(false);
  const [allStatusData, setAllStatusData] = React.useState({});
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [setTdrData, setSetTdrData] = React.useState(TDRData);

  React.useEffect(() => {
    CommonCallFunction();
  }, [id, allStatusCodeMapping]);

  const CommonCallFunction = () => {
    id && setControlDisable(true);
    setAllStatusData(JSON.parse(JSON.stringify(allStatusCodeMapping)));
    let array = allStatusCodeMapping?.services
      ? Object.keys(allStatusCodeMapping?.services)
      : TDRData;
    let InnerTdrData = TDRData.filter(
      (fact) =>
        array.includes(fact.type) &&
        allStatusCodeMapping?.services[fact.type].status
    );
    setSetTdrData(InnerTdrData);
    setLoader(false);
  };

  const SaveCode = () => {
    setLoader(true);
    let data = allStatusData;
    UpdateAcquirerProp(data, data._id);
  };

  const AddCode = () => {
    AddAcquirerProp(allStatusData, 1);
  };

  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="TDRSettings">
          <div className="TDRInnerSettings">
            <div className="TDRLeftSettings">
              <div>
                {setTdrData.map((row, index) => {
                  return (
                    <div
                      className={
                        index == selectedIndex
                          ? "InnerTab selected"
                          : "InnerTab"
                      }
                      key={row.Card}
                      onClick={() => {
                        setSelectedIndex(index);
                      }}
                    >
                      <img
                        style={{ marginRight: "8px", width: "29px" }}
                        src={row.Icon}
                      ></img>
                      {row.Card}
                    </div>
                  );
                })}
              </div>
            </div>
            {setTdrData.length !== 0 && (
              <div className="TDRRightSettings">
                <div className="TDRInnerRow">
                  {setTdrData[selectedIndex]?.Card}
                </div>
                <Grid className="TDRInnerSettingRow" container spacing={2}>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="">
                    <p className="TDRInnerTitle">% Fees </p>
                    <CssTextField
                      className=""
                      id="new-menu"
                      placeholder="% Fees"
                      variant="outlined"
                      fullWidth
                      disabled={controlDisable}
                      type="number"
                      value={
                        allStatusData?.services &&
                        allStatusData?.services?.[
                          setTdrData[selectedIndex]?.type
                        ]?.margin
                      }
                      onChange={(e) => {
                        let val = e.target.value.match(
                          "^-?[0-9]+(?:.[0-9]{1,2})?$"
                        );
                        if (val || !e.target.value) {
                          let data = allStatusData;
                          data.services[setTdrData[selectedIndex].type].margin =
                            Number(val);
                          setAllStatusData({ ...data });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="">
                    <p className="TDRInnerTitle">Fixed Fees </p>
                    <CssTextField
                      className=""
                      id="new-menu"
                      placeholder="Fixed Fees"
                      variant="outlined"
                      fullWidth
                      disabled={controlDisable}
                      type="number"
                      onChange={(e) => {
                        let val = e.target.value.match(
                          "^-?[0-9]+(?:.[0-9]{1,2})?$"
                        );
                        if (val || !e.target.value) {
                          let data = allStatusData;
                          data.services[
                            setTdrData[selectedIndex].type
                          ].fixed_fees = Number(val);
                          setAllStatusData({ ...data });
                        }
                      }}
                      value={
                        allStatusData?.services &&
                        allStatusData?.services?.[
                          setTdrData[selectedIndex]?.type
                        ]?.fixed_fees
                      }
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="">
                    <p className="TDRInnerTitle">TAX </p>
                    <CssTextField
                      className=""
                      id="new-menu"
                      placeholder="TAX"
                      variant="outlined"
                      fullWidth
                      type="number"
                      disabled={controlDisable}
                      onChange={(e) => {
                        let val = e.target.value.match(
                          "^-?[0-9]+(?:.[0-9]{1,2})?$"
                        );
                        if (val || !e.target.value) {
                          let data = allStatusData;
                          data.services[setTdrData[selectedIndex].type].vat =
                            Number(val);
                          setAllStatusData({ ...data });
                        }
                      }}
                      value={
                        allStatusData?.services &&
                        allStatusData?.services?.[
                          setTdrData[selectedIndex]?.type
                        ]?.vat
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
          {setTdrData?.length !== 0 && (
            <>
              {" "}
              {!id ? (
                <Grid
                  className="TDRSettingButton"
                  container
                  spacing={2}
                  justifyContent="flex-end"
                >
                  <Button
                    onClick={AddCode}
                    className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                    style={{ marginRight: "29px" }}
                    variant="contained"
                  >
                    Next
                  </Button>
                </Grid>
              ) : (
                <Grid
                  className="TDRSettingButton"
                  container
                  spacing={2}
                  justifyContent="flex-end"
                >
                  {!controlDisable ? (
                    <>
                      <Button
                        onClick={SaveCode}
                        className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                        style={{ marginRight: "29px" }}
                        variant="contained"
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          CommonCallFunction();
                          setControlDisable(true);
                        }}
                        className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      {rolePermission.includes("EDIT") && (
                        <Button
                          onClick={() => {
                            setControlDisable(false);
                          }}
                          className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                          style={{ marginRight: "29px" }}
                          variant="contained"
                        >
                          Edit
                        </Button>
                      )}
                    </>
                  )}
                </Grid>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default TDRSettings;
