import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./AcquirerTab.css";
import { toast } from "react-toastify";
import BasicAndInformation from "./BasicAndInformation/BasicInformation";
import MOPSettings from "./MOPSettings/MOPSettings";
import OtherSettings from "./OtherSettings/OtherSettings";
import ServicesMapping from "./ServicesMapping/ServicesMapping";
import StatusCodeMapping from "./StatusCodeMapping/StatusCodeMapping";
import TDRSettings from "./TDRSettings/TDRSettings";
import TaxSettings from "./TaxSettings/TaxSettings";
import RefundCharges from "./../RefundCharges/RefundCharges";
import Credential from "./Credential/Credential";
import DecimalSettings from "./DecimalSettings";
import { useNavigate } from "react-router-dom";
import NavbarHoc from "../../pages/navhoc/index";
import Config from "../../config.json";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import {
  GetAcquirer,
  GetAllStatus,
  GetCurrency,
  AddAcquirer,
  UpdateAcquirer,
} from "../../networkRequest/request/acquirer";
import axios from "axios";
import baseURL from "../../utils/base_url";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: "15px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AcquireTab = (props) => {
  const [rolePermission, setRolePermission] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [allStatusCode, setAllStatusCode] = React.useState([]);
  const [allStatusCodeMapping, setAllStatusCodeMapping] = React.useState([]);
  const [allCurrency, setAllCurrency] = React.useState([]);
  const history = useNavigate();

  const redirecttodetail = () => {
    history("/acquirerlist");
  };

  React.useEffect(() => {
    CheckAcquirer();
  }, [props.value]);

  const CheckAcquirer = async () => {
    if (props.value) {
      let acquirer = await GetAcquirer(props.value);
      acquirer?.data?.data && setAllStatusCodeMapping(acquirer?.data?.data);
    } else {
      setAllStatusCodeMapping({
        services: {
          creditcards: {},
          debitcards: {},
          upis: {},
          wallets: {},
          banks: {},
          applepay: {},
          googlepay: {},
        },
        status: true,
        tax: {},
        status_mapping: [],
      });
    }
    axios.get(`${baseURL()}/api/country-master`).then((res) => {
      setAllCurrency(res?.data);
    });
    let statuscode = await GetAllStatus();
    statuscode?.data?.data && setAllStatusCode(statuscode?.data?.data);
  };

  const AddAcquirerFunction = async (req, number) => {
    if (number !== 3) {
      let data = {
        ...allStatusCodeMapping,
        ...req,
      };
      setAllStatusCodeMapping(data);
      setSelectedIndex(number + 1);
    } else {
      let data = {
        ...allStatusCodeMapping,
        ...req,
      };
      let {
        chargeback_fees,
        maintenance_cost,
        name,
        refund_fees,
        rolling_reserve,
        rolling_reserve_days,
        services,
        setupcost,
        type,
        tax: { service_charge, gst, pancard, tan_no, tds },
      } = data;
      if (
        chargeback_fees !== "" &&
        maintenance_cost !== "" &&
        name !== "" &&
        refund_fees !== "" &&
        rolling_reserve !== "" &&
        rolling_reserve_days !== "" &&
        services &&
        setupcost !== "" &&
        type !== ""
      ) {
        let ShareData = await AddAcquirer(data);
        history("/acquirerlist");
      } else {
        toast("Please Fill All Mandatory Details", {
          type: "Warning",
          autoClose: 2000,
        });
      }
    }
  };

  const UpdateAcquirerFunction = async (req, Id) => {
    let services = {};
    Object.keys(req.services).forEach(function (key, index) {
      if (req.services[key]?.status) {
        Object.keys(req.services[key]).forEach(function (key1, index1) {
          if (req.services[key][key1] && key1 != "status") {
            req.services[key][key1] = Number(req.services[key][key1]);
          }
        });
        services[key] = req.services[key];
      } else {
        services[key] = {};
      }
    });
    req.services = services;
    let data = await UpdateAcquirer(req, Id);
    if (!data?.data?.status) {
      toast(data?.data?.message, {
        type: "error",
        autoClose: 2000,
      });
    } else {
      toast("Acqurier Successfully Updated", {
        type: "success",
        autoClose: 2000,
      });
    }
    await CheckAcquirer();
  };

  const updateRefundCharges = (refund_charges) => {
    allStatusCodeMapping.refund_charges = refund_charges;
    UpdateAcquirerFunction(allStatusCodeMapping, props.value);
  };

  return (
    <NavbarHoc
      id={Config.PermissionId.Acquirer}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <Box>
        <h1 className="merchant_title">Manage Acquirer</h1>
      </Box>
      <div className="merchant_list_main_div configuration_tab_div">
        <Grid
          style={{ marginTop: "1%", marginBottom: "0.5%" }}
          container
          spacing={2}
          justifyContent="flex-start"
        >
          <Button
            onClick={redirecttodetail}
            className="merchant_list_add_new_merchant"
            style={{ marginLeft: "2%" }}
            variant="contained"
          >
            <i
              class="fa fa-solid fa-chevron-left"
              aria-hidden="true"
              style={{ padding: "5px" }}
            ></i>
          </Button>
        </Grid>
        <Box
          className="configuration_tab"
          sx={{ borderColor: "divider", ml: "-15px" }}
        >
          {props.value ? (
            <Tabs
              value={selectedIndex}
              aria-label="basic tabs example"
              className="acquirer-tabs"
            >
              <Tab
                id="1"
                label="Basic Information"
                onClick={() => setSelectedIndex(0)}
                {...a11yProps(0)}
              />
              <Tab
                id="2"
                label="Services Mapping"
                onClick={() => setSelectedIndex(1)}
                {...a11yProps(1)}
              />
              <Tab
                id="3"
                label="MOP Settings"
                onClick={() => setSelectedIndex(2)}
                {...a11yProps(2)}
              />
              <Tab
                id="4"
                label="MDR Settings"
                onClick={() => setSelectedIndex(3)}
                {...a11yProps(3)}
              />
              <Tab
                id="5"
                label="Tax Settings"
                onClick={() => setSelectedIndex(4)}
                {...a11yProps(4)}
              />
              <Tab
                id="6"
                label="Other Settings"
                onClick={() => setSelectedIndex(5)}
                {...a11yProps(5)}
              />
              <Tab
                id="7"
                label="Status Code Mapping"
                onClick={() => setSelectedIndex(6)}
                {...a11yProps(6)}
              />
              <Tab
                id="8"
                label="Refund Charges"
                onClick={() => setSelectedIndex(7)}
                {...a11yProps(7)}
              />
              <Tab
                id="9"
                label="Credential"
                onClick={() => setSelectedIndex(8)}
                {...a11yProps(8)}
              />
              <Tab
                id="10"
                label="Decimal precision"
                onClick={() => setSelectedIndex(9)}
                {...a11yProps(9)}
              />
            </Tabs>
          ) : (
            <Tabs value={selectedIndex} aria-label="basic tabs example">
              <Tab
                id="1"
                label="Basic Information"
                onClick={() => setSelectedIndex(0)}
                {...a11yProps(0)}
              />
              <Tab
                id="2"
                label="MDR Settings"
                onClick={() => setSelectedIndex(1)}
                {...a11yProps(1)}
              />
              <Tab
                id="3"
                label="Tax Settings"
                onClick={() => setSelectedIndex(2)}
                {...a11yProps(2)}
              />
              <Tab
                id="4"
                label="Other Settings"
                onClick={() => setSelectedIndex(3)}
                {...a11yProps(3)}
              />
            </Tabs>
          )}
        </Box>
        {props.value ? (
          <>
            <TabPanel value={selectedIndex} index={0}>
              <BasicAndInformation
                id={props.value}
                allCurrency={allCurrency}
                allStatusCodeMapping={allStatusCodeMapping}
                rolePermission={rolePermission}
                AddAcquirerProp={AddAcquirerFunction}
                UpdateAcquirerProp={UpdateAcquirerFunction}
              />
            </TabPanel>
            <TabPanel value={selectedIndex} index={1}>
              <ServicesMapping
                id={props.value}
                allStatusCodeMapping={allStatusCodeMapping}
                rolePermission={rolePermission}
                AddAcquirerProp={AddAcquirerFunction}
                UpdateAcquirerProp={UpdateAcquirerFunction}
              />
            </TabPanel>
            <TabPanel value={selectedIndex} index={2}>
              <MOPSettings
                id={props.value}
                allStatusCodeMapping={allStatusCodeMapping}
                rolePermission={rolePermission}
                AddAcquirerProp={AddAcquirerFunction}
                UpdateAcquirerProp={UpdateAcquirerFunction}
              />
            </TabPanel>
            <TabPanel value={selectedIndex} index={3}>
              <TDRSettings
                id={props.value}
                allStatusCodeMapping={allStatusCodeMapping}
                rolePermission={rolePermission}
                AddAcquirerProp={AddAcquirerFunction}
                UpdateAcquirerProp={UpdateAcquirerFunction}
              />
            </TabPanel>
            <TabPanel value={selectedIndex} index={4}>
              <TaxSettings
                id={props.value}
                allStatusCodeMapping={allStatusCodeMapping}
                rolePermission={rolePermission}
                AddAcquirerProp={AddAcquirerFunction}
                UpdateAcquirerProp={UpdateAcquirerFunction}
              />
            </TabPanel>
            <TabPanel value={selectedIndex} index={5}>
              <OtherSettings
                id={props.value}
                allStatusCodeMapping={allStatusCodeMapping}
                rolePermission={rolePermission}
                AddAcquirerProp={AddAcquirerFunction}
                UpdateAcquirerProp={UpdateAcquirerFunction}
              />
            </TabPanel>
            <TabPanel value={selectedIndex} index={6}>
              <StatusCodeMapping
                id={props.value}
                allStatusCode={allStatusCode}
                allStatusCodeMapping={allStatusCodeMapping}
                rolePermission={rolePermission}
                AddAcquirerProp={AddAcquirerFunction}
                UpdateAcquirerProp={UpdateAcquirerFunction}
              />
            </TabPanel>
            <TabPanel value={selectedIndex} index={7}>
              <RefundCharges
                refundCharges={allStatusCodeMapping?.refund_charges}
                rolePermission={rolePermission}
                updateRefundCharges={updateRefundCharges}
              />
            </TabPanel>
            <TabPanel value={selectedIndex} index={8}>
              <Credential
                id={props.value}
                allStatusCodeMapping={allStatusCodeMapping}
                rolePermission={rolePermission}
                AddAcquirerProp={AddAcquirerFunction}
                UpdateAcquirerProp={UpdateAcquirerFunction}
              />
            </TabPanel>
            <TabPanel value={selectedIndex} index={9}>
              <DecimalSettings
                id={props.value}
                allStatusCodeMapping={allStatusCodeMapping}
                rolePermission={rolePermission}
                AddAcquirerProp={AddAcquirerFunction}
                UpdateAcquirerProp={UpdateAcquirerFunction}
              />
            </TabPanel>
          </>
        ) : (
          <>
            <TabPanel value={selectedIndex} index={0}>
              <BasicAndInformation
                id={props.value}
                allCurrency={allCurrency}
                allStatusCodeMapping={allStatusCodeMapping}
                rolePermission={rolePermission}
                AddAcquirerProp={AddAcquirerFunction}
                UpdateAcquirerProp={UpdateAcquirerFunction}
              />
            </TabPanel>
            <TabPanel value={selectedIndex} index={1}>
              <TDRSettings
                id={props.value}
                allStatusCodeMapping={allStatusCodeMapping}
                rolePermission={rolePermission}
                AddAcquirerProp={AddAcquirerFunction}
                UpdateAcquirerProp={UpdateAcquirerFunction}
              />
            </TabPanel>
            <TabPanel value={selectedIndex} index={2}>
              <TaxSettings
                id={props.value}
                allStatusCodeMapping={allStatusCodeMapping}
                rolePermission={rolePermission}
                AddAcquirerProp={AddAcquirerFunction}
                UpdateAcquirerProp={UpdateAcquirerFunction}
              />
            </TabPanel>
            <TabPanel value={selectedIndex} index={3}>
              <OtherSettings
                id={props.value}
                allStatusCodeMapping={allStatusCodeMapping}
                rolePermission={rolePermission}
                AddAcquirerProp={AddAcquirerFunction}
                UpdateAcquirerProp={UpdateAcquirerFunction}
              />
            </TabPanel>
          </>
        )}
      </div>
    </NavbarHoc>
  );
};

export default AcquireTab;
