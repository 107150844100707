import React from "react";
import { useState, useEffect } from "react";
import "./OtherSettings.css";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const OtherSettings = (props) => {
  const {
    rolePermission,
    allStatusCodeMapping,
    id,
    AddAcquirerProp,
    UpdateAcquirerProp,
  } = props;
  const [loader, setLoader] = React.useState(true);
  const [controlDisable, setControlDisable] = React.useState(false);
  const [allStatusData, setAllStatusData] = React.useState({});
  const [otherSetting, setAllOtherSetting] = React.useState({});
  const [open, setOpen] = React.useState(false);

  let month_days = [];

  for (let i = 0; i <= 30; i++) {
    month_days.push(i);
  }
  React.useEffect(() => {
    CommonCallFunction();
  }, [id, allStatusCodeMapping]);

  const CommonCallFunction = () => {
    setAllStatusData(allStatusCodeMapping);
    setAllOtherSetting(allStatusCodeMapping?.acquirer_other_setting);
    setLoader(false);
  };

  const SaveCode = async () => {
    const {
      setupcost,
      maintenance_cost,
      pay_in_settlement_cycle,
      pay_out_settlement_cycle,
      rolling_reserve_days,
      rolling_reserve,
      chargeback_fees,
    } = otherSetting;
    if (
      setupcost !== "" &&
      maintenance_cost !== "" &&
      pay_in_settlement_cycle !== "" &&
      pay_out_settlement_cycle != "" &&
      rolling_reserve_days !== "" &&
      rolling_reserve !== "" &&
      chargeback_fees !== ""
    ) {
      setLoader(true);
      let data = {
        ...allStatusData,
        acquirer_other_setting: otherSetting,
      };
      allStatusData.acquirer_other_setting = otherSetting;
      setAllStatusData(allStatusData);
      await UpdateAcquirerProp(data, data._id);
      setLoader(false);
    } else {
      toast("Please Fill All The Mandatory Details.", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };

  const confirmReset = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resetData = async () => {
    let data = {
      ...allStatusData,
      acquirer_other_setting: {},
    };
    allStatusData.acquirer_other_setting = {};
    setAllStatusData(allStatusData);
    setAllOtherSetting({});
    await UpdateAcquirerProp(data, data._id);
    setOpen(false);
    setLoader(false);
  };

  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="OtherSettings">
          <Grid className="OtherSettingsRow" container spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Setup Cost <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Setup Cost"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={otherSetting?.setupcost ? otherSetting?.setupcost : 0}
                onChange={(e) => {
                  let val = e.target.value;
                  const isDecimal = val % 1 != 0;
                  if (isDecimal) {
                    val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                  }
                  if (val) {
                    setAllOtherSetting({
                      ...otherSetting,
                      setupcost: val,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Maintenance Cost <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Maintenance Cost"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={
                  otherSetting?.maintenance_cost
                    ? otherSetting?.maintenance_cost
                    : 0
                }
                onChange={(e) => {
                  let val = e.target.value;
                  const isDecimal = val % 1 != 0;
                  if (isDecimal) {
                    val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                  }
                  if (val) {
                    setAllOtherSetting({
                      ...otherSetting,
                      maintenance_cost: val,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Rolling Reserve <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Rolling Reserve"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={
                  otherSetting?.rolling_reserve
                    ? otherSetting?.rolling_reserve
                    : 0
                }
                onChange={(e) => {
                  let val = e.target.value;
                  const isDecimal = val % 1 != 0;
                  if (isDecimal) {
                    val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                  }
                  if (val) {
                    setAllOtherSetting({
                      ...otherSetting,
                      rolling_reserve: val,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Rolling Reserve Days <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Rolling Reserve Days"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={
                  otherSetting?.rolling_reserve_days
                    ? otherSetting?.rolling_reserve_days
                    : 0
                }
                onChange={(e) => {
                  let val = e.target.value;
                  const isDecimal = val % 1 != 0;
                  if (isDecimal) {
                    val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                  }
                  if (val) {
                    setAllOtherSetting({
                      ...otherSetting,
                      rolling_reserve_days: Number(val),
                    });
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid className="OtherSettingsRow" container spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Chargeback Fees <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Chargeback Fees"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={
                  otherSetting?.chargeback_fees
                    ? otherSetting?.chargeback_fees
                    : 0
                }
                onChange={(e) => {
                  let val = e.target.value;
                  const isDecimal = val % 1 != 0;
                  if (isDecimal) {
                    val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                  }
                  if (val) {
                    setAllOtherSetting({
                      ...otherSetting,
                      chargeback_fees: val,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Pay In Settlement Cycle <span className="Mandatory"></span>
              </p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  otherSetting?.pay_in_settlement_cycle
                    ? otherSetting?.pay_in_settlement_cycle
                    : 0
                }
                label=""
                onChange={(e) => {
                  setAllOtherSetting({
                    ...otherSetting,
                    pay_in_settlement_cycle: e.target.value,
                  });
                }}
                fullWidth
                disabled={controlDisable}
              >
                {month_days.map((day) => {
                  return <MenuItem value={day}>{day}</MenuItem>;
                })}
              </Select>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Pay Out Settlement Cycle <span className="Mandatory"></span>
              </p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  otherSetting?.pay_out_settlement_cycle
                    ? otherSetting?.pay_out_settlement_cycle
                    : 0
                }
                label=""
                onChange={(e) => {
                  setAllOtherSetting({
                    ...otherSetting,
                    pay_out_settlement_cycle: e.target.value,
                  });
                }}
                fullWidth
                disabled={controlDisable}
              >
                {month_days.map((day) => {
                  return <MenuItem value={day}>{day}</MenuItem>;
                })}
              </Select>
            </Grid>
          </Grid>
          <Grid
            className="OtherSettingsRow2"
            container
            spacing={2}
            justifyContent="flex-end"
          >
            <Button
              className="merchant_reset_btn merchant_detail_tab_save-cancel_btn reset_btn"
              style={{ "background-color": "#1058FF" }}
              variant="contained"
              disabled={!otherSetting || JSON.stringify(otherSetting) == "{}"}
              onClick={confirmReset}
            >
              Reset
            </Button>
            <Button
              disabled={!otherSetting || JSON.stringify(otherSetting) == "{}"}
              onClick={SaveCode}
              className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
              style={{ marginRight: "29px" }}
              variant="contained"
            >
              Save
            </Button>
          </Grid>
        </div>
      )}
      <div>
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              <p>Confirm Reset</p>
              <span>Are you sure want to reset configuration?</span>
              <div className="order_detail_refund_popup_btn">
                <Button
                  className="refund_save_btn"
                  variant="contained"
                  autoFocus
                  fullWidth
                  onClick={resetData}
                >
                  Confirm
                </Button>
                <Button
                  className="refund_cancel_btn"
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  fullWidth
                >
                  Cancel
                </Button>
              </div>
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default OtherSettings;
