import React from "react";
import { useState, useEffect } from "react";
import "./Country.css";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Switch from "@mui/material/Switch";
import AxiosBase from "../../../networkRequest/axiosBase";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Box, Paper } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { unstable_styleFunctionSx } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import CountryDetail from "./Country-Detail/CountryDetail";

//side bar table code
const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 45,
  height: 22,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Country = (props) => {
  const { rolePermission } = props;
  const [spinner, setSpinner] = useState(false);
  const [controlDisable, setControlDisable] = useState(true);
  const [currentSaveCancelId, setCurrentSaveCancelId] = useState("");
  const [currentEditId, setCurrentEditId] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [state, setState] = React.useState({
    right: false,
  });
  const [open, setOpen] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState({});

  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  useEffect(() => {
    getCountryData();
  }, []);

  const getCountryData = () => {
    setSpinner(true);
    setControlDisable(true);
    AxiosBase.get(`/admin/country-master`).then((res) => {
      setCountryList(res?.data);
      setSpinner(false);
    });
  };

  const deleteKyc = (e, kyc) => {
    setSelectedCountry(kyc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    setSpinner(true);
    AxiosBase.delete(`/admin/country-master/${selectedCountry?._id}`)
      .then((res) => {
        toast("Country deleted successfully", {
          type: "success",
          autoClose: 2000,
        });
        cancelCountry({}, selectedCountry?._id);
      })
      .catch((error) => {
        setSpinner(false);
        getCountryData();
        return toast(
          error?.response?.data?.message || "Something went wrong!",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      });
  };

  const cancelCountry = () => {
    setCurrentEditId("");
    getCountryData();
  };
  const addKyc = () => {
    if (currentEditId == "add_new") {
      return;
    }
    const obj = {
      _id: "add_new",
      country_name: "",
      alphaTwoCode: "",
      alphaThreeCode: "",
      mobile_no_regex: "",
      currency_name: "",
      currency_code: "",
      currency_symbol: "",
      mobile_code: "",
      numeric_code: "",
      icon: "",
      timezone: "",
      timezone_name: "",
      tax: {
        value: [],
        name: "",
        document: "",
      },
      is_active: true,
      is_payment_allowed: false,
    };
    setCurrentEditId("add_new");
    setSelectedCountry(obj);
    setState({ ...state, ["right"]: true });
  };

  const saveCountry = (e, _id, country) => {
    try {
      setSpinner(true);
      if (_id == "add_new") {
        delete country._id;
        console.log(country);
        AxiosBase.post("/admin/country-master", country)
          .then((res) => {
            if (res?.status == 200) {
              if (res?.data?._id !== "") {
                setCurrentSaveCancelId("");
                setCurrentEditId("");
                setControlDisable(true);
                cancelCountry({}, selectedCountry._id);
                return toast("A new Country added successfully.", {
                  type: "success",
                  autoClose: 2000,
                });
              }
            } else {
              setCurrentSaveCancelId("");
              setCurrentEditId("");
              setControlDisable(true);
              setSpinner(false);
              return toast(JSON.stringify(res?.response?.data), {
                type: "error",
                autoClose: 2000,
              });
            }
          })
          .catch((error) => {
            setSpinner(false);
            getCountryData();
            return toast(
              error?.response?.data?.message || "Something went wrong!",
              {
                type: "error",
                autoClose: 2000,
              }
            );
          });
      } else {
        AxiosBase.patch("/admin/country-master/" + _id, country)
          .then((res) => {
            if (res?.status == 200) {
              if (res?.data?._id !== "") {
                setCurrentSaveCancelId("");
                setCurrentEditId("");
                setControlDisable(true);
                cancelCountry({}, selectedCountry._id);
                return toast("Country Updated successfully.", {
                  type: "success",
                  autoClose: 2000,
                });
              }
            } else {
              setCurrentSaveCancelId("");
              setCurrentEditId("");
              setControlDisable(true);
              setSpinner(false);
              return toast(JSON.stringify(res?.response?.data), {
                type: "error",
                autoClose: 2000,
              });
            }
          })
          .catch((error) => {
            setSpinner(false);
            getCountryData();
            return toast(
              error?.response?.data?.message || "Something went wrong!",
              {
                type: "error",
                autoClose: 2000,
              }
            );
          });
      }
    } catch (err) {
      setCurrentSaveCancelId("");
      setCurrentEditId("");
      setControlDisable(true);
      setSpinner(false);
      return toast(JSON.stringify(err), {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    cancelCountry({}, selectedCountry?._id);
  };

  const detailCountry = (anchor, open, selected) => {
    setSelectedCountry(selected);
    setState({ ...state, [anchor]: open });
  };

  const onSubmitCountry = (p) => {
    setState({ ...state, ["right"]: false });
    saveCountry({}, p._id, p);
  };

  const list = (anchor) => (
    <Box
      className="kyc_detail_box"
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
        overflowX: "hidden",
      }}
      role="presentation"
    >
      <List>
        <ListItem className="merchant_edit_drower_menu_top_sec">
          <p>Country & Tax master</p>
        </ListItem>
      </List>
      <Divider />
      {
        <>
          <div className="kyc_array">
            {
              <CountryDetail
                SelectedCountry={selectedCountry}
                onSubmitCountry={onSubmitCountry}
                toggleDrawer={toggleDrawer}
              />
            }
          </div>
        </>
      }
    </Box>
  );

  return (
    <>
      <div className="kyc_kyc_top_div">
        {spinner ? (
          <div className="no_data_found loader_data_tbl">
            <CircularProgress style={{ color: "#1058ff" }} />
          </div>
        ) : (
          <>
            <div
              className="Fees_Margin_title_div"
              style={{ position: "relative" }}
            >
              <p>Country Master</p>
              {rolePermission.includes("ADD") && (
                <Link
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "16px",
                  }}
                >
                  <Button
                    className="merchant_list_add_new_merchant"
                    variant="contained"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    onClick={(e) => {
                      addKyc();
                    }}
                  >
                    Add
                  </Button>
                </Link>
              )}
            </div>
            <div className="kyc_kyc_input_div">
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                xl={1}
                className="kyc_kyc_input_grid th_heading doc-name-cell"
              >
                <p>{"Country Name"}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                xl={1}
                className="kyc_kyc_input_grid th_heading"
              >
                <p>{"Country Code"}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                xl={1}
                className="kyc_kyc_input_grid th_heading"
              >
                <p>{"Currency Code"}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                xl={1}
                className="kyc_kyc_input_grid"
              >
                <p>{"Currency Name"}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                xl={1}
                className="kyc_kyc_input_grid th_heading"
              >
                <p>{"Mobile Code"}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                xl={1}
                className="kyc_kyc_input_grid th_heading"
              >
                <p>{"TimeZone"}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                xl={1}
                className="kyc_kyc_input_grid th_heading"
              >
                <p>{"Action"}</p>
              </Grid>
            </div>
            {countryList.map((elm) => {
              const { _id } = elm;
              return (
                <div key={_id} className="kyc_kyc_input_div kyc_row">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    className="kyc_kyc_input_grid kyc_row_name doc-name doc-name-cell"
                  >
                    <p>{elm.country_name}</p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    className="kyc_kyc_input_grid"
                  >
                    <p>{elm.alphaThreeCode}</p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    className="kyc_kyc_input_grid kyc_row_name"
                  >
                    <p>{elm.currency_code}</p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    className="kyc_kyc_input_grid kyc_row_name"
                  >
                    <p>{elm.currency_name}</p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    className="kyc_kyc_input_grid kyc_row_name"
                  >
                    <p>{elm.mobile_code}</p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    className="kyc_kyc_input_grid kyc_row_name"
                  >
                    <p>{elm.timezone}</p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    className="kyc_kyc_second_grid_sec action-cell"
                  >
                    <div className="kyc_kyc_edit_button_dev kyc_kyc_drawer_save_cancel_btn">
                      {["right"].map((anchor) => (
                        <React.Fragment key={anchor}>
                          <Button
                            className="kyc_detailed_btn"
                            onClick={(e) => {
                              detailCountry(anchor, true, elm);
                            }}
                            variant="contained"
                            startIcon={
                              <img src="/edit-icon-white.svg" alt="" />
                            }
                            title="Detail"
                          >
                            <i className="fa fa-pencil"></i>
                          </Button>
                        </React.Fragment>
                      ))}
                    </div>
                  </Grid>
                </div>
              );
            })}
          </>
        )}
      </div>
      <div>
        {["right"].map((anchor) => (
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        ))}
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              {
                <>
                  {" "}
                  <p>Confirm Delete</p>
                  <span>Are you sure want to delete this country?</span>
                  <div className="order_detail_refund_popup_btn">
                    <Button
                      className="refund_save_btn"
                      variant="contained"
                      autoFocus
                      fullWidth
                      onClick={handleDelete}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="refund_cancel_btn"
                      variant="contained"
                      autoFocus
                      onClick={handleClose}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              }
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default Country;
