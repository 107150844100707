import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import * as getLabels from "../utils";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.scss";

const getOptions = (times = null) => {
  let timesData = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];
  if (times) {
    timesData = times;
  }

  let data = {
    chart: {
      height: 350,
      type: "area",
      stacked: true,
      dropShadow: {
        enabled: true,
        enabledSeries: [0],
        top: -2,
        left: 2,
        blur: 5,
        opacity: 0.06,
      },
    },
    colors: ["#0F79DB"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: timesData,
    },
    markers: {
      size: 4,
      strokeColor: "#fff",
      strokeWidth: 3,
      strokeOpacity: 1,
      fillOpacity: 1,
      hover: {
        size: 6,
      },
    },
  };

  if (times == "Custom") {
    data = {
      ...data,
      xaxis: {
        type: "datetime",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          offsetX: 14,
          offsetY: -5,
        },
        tooltip: {
          enabled: true,
        },
      },
      grid: {
        padding: {
          left: -5,
          right: 5,
        },
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
    };
  }
  return data;
};

export default function Volume(props) {
  const [options, setOptions] = useState(getOptions());
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let data;
    setLoading(true);
    if (props.filter.dateRange == "Today") {
      data = getToday();
    }
    if (props.filter.dateRange == "Yesterday") {
      data = getYesterday();
    }
    if (props.filter.dateRange == "This Week") {
      data = getThisWeek();
    }
    if (props.filter.dateRange == "This Month") {
      data = getThisMonth();
    }
    if (props.filter.dateRange == "This Year") {
      data = getThisYear();
    }
    if (props.filter.dateRange == "Custom") {
      data = getCustom();
    }

    setOptions(getOptions(data.times));
    setSeries([{ name: "Orders", data: data.total_count || [] }]);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [props.data]);

  const getCustom = () => {
    const total_count = [];
    props.data?.map((res) => {
      total_count.push([new Date(res._id).getTime(), res.amount]);
    });
    return {
      total_count,
      times: "Custom",
    };
  };

  const getToday = () => {
    const total_count = new Array(Number(24)).fill(0);
    props.data?.map((res) => {
      const title = res._id.split(" ")[1].split(":")[0];
      total_count[Number(title)] += res.amount;
    });
    return { total_count, times: getLabels.getDayLabel() };
  };

  const getYesterday = () => {
    const total_count = new Array(Number(24)).fill(0);
    props.data?.map((res) => {
      const title = res._id.split(" ")[1].split(":")[0];
      total_count[Number(title)] += res.amount;
    });
    return { total_count, times: getLabels.getDayLabel() };
  };

  const getThisWeek = () => {
    const total_count = [0, 0, 0, 0, 0, 0, 0];
    props.data?.map((res) => {
      total_count[moment(res._id).isoWeekday() - 1] += res.amount;
    });
    return { total_count, times: getLabels.getWeeklyLabel() };
  };

  const getThisMonth = () => {
    const total_count = new Array(
      Number(moment().endOf("month").format("DD"))
    ).fill(0);
    props.data?.map((res) => {
      total_count[Number(moment(res._id).format("DD")) - 1] += res.amount;
    });
    return { total_count, times: getLabels.getMonthlyLabel() };
  };

  const getThisYear = () => {
    const total_count = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    props.data?.map((res) => {
      total_count[Number(res._id.split("-")[1]) - 1] += res.amount;
    });
    return { total_count, times: getLabels.getYearlyLabel() };
  };

  if (!props.data || !series) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div id="chart">
      {!loading && (
        <Chart options={options} series={series} type="area" height={350} />
      )}
    </div>
  );
}
