import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Paper } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "./datatable.css";
import moment from "moment";
import Iconsearch from "../../../../../../../Icons/SearchIcon.jpg";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AxiosBase from "../../../../../../../networkRequest/axiosBase";
import {
  viewAllKyc,
  changeKycStatus,
} from "../../../../../../../networkRequest/request/kyc";
import CircularProgress from "@mui/material/CircularProgress";

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    border: "1px",
    borderRadius: "5px",
    font: "9px solid  #333333",
    fontWeight: 500,
  },
});

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState([]);
  //api data state
  const [userdata, setData] = React.useState([]);
  const [sortOrder, setOrderBy] = React.useState("ASC");
  const [sortBy, setSortBy] = React.useState();
  const [searchBy, setSearch] = React.useState();
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severiety: "success",
  });
  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: "", severiety: "success" });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAction = async () => {
    try {
      let isverified = action.action;
      if (!comments) {
        setSnackbar({
          open: true,
          message: "Comments are required",
          severiety: "error",
        });
        return false;
      }
      if (action.action == true) {
        setSnackbar({
          open: true,
          message: "Emirates id & Trade license is approved",
          severiety: "success",
        });
      } else {
        setSnackbar({
          open: true,
          message: "Emirates id & Trade license is rejected",
          severiety: "success",
        });
      }
      await AxiosBase.post(`/admin/kyc/change-kyc-status/${action.id}`, {
        isverified,
        comments,
      });
      const newUserData = { ...userdata };
      const data = userdata.map((d) => {
        if (d._id === action.id) {
          if (isverified) {
            return {
              ...d,
              isverified: true,
              tradeLicenseVerified: "Approved",
              emiratesIdVerified: "Approved",
              kycStatus: "Approved",
            };
          }
          return {
            ...d,
            isverified: true,
            tradeLicenseVerified: "Rejected",
            emiratesIdVerified: "Rejected",
            kycStatus: "Rejected",
          };
        }
        return d;
      });
      setData(data);
      handleCloseDialog();
    } catch (error) {
      console.log(error);
    }
  };
  const [action, setAction] = useState({
    id: "",
    action: "",
  });
  const [comments, setcomments] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [loader, setLoader] = React.useState(true);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setcomments("");
    setAction({
      id: "",
      action: "",
    });
  };

  const handleCommentsChanges = (e) => {
    try {
      let value = e.target.value;
      setcomments(value.trimLeft());
    } catch (error) {
      console.log(error);
    }
  };

  const handleCommentsAction = (id, action) => {
    setAction({
      id: id,
      action: action,
    });
    setOpenDialog(true);
  };

  useEffect(() => {
    AxiosBase.get(
      `/admin/kyc/view-all-kyc?pageNo=${page}&rowsPerPage=${rowsPerPage}&sortBy=createdAt&search=${
        searchBy ? searchBy : ""
      }`
    ).then((response) => {
      setData(response.data.data);
      setCount(response.data.count);
      setLoader(false);
    });
  }, [searchBy, page, rowsPerPage]);

  if (loader) {
    return (
      <div className="center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="kyc-grid-main-div">
        <Box sx={{ mx: "15px" }}>
          <Link to="/kyc" style={{ textDecoration: "none" }}>
            <h3 className="pagetitle">KYC Request List</h3>
          </Link>
        </Box>
        <CssTextField
          className="kycsearch"
          id="new-menu"
          placeholder="Search Number/Name/Email ID"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img className="search-icon" src={Iconsearch} alt="search" />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="kyc-grid-table">
        <TableContainer sx={{ maxHeight: 380, ml: "-5px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className="tbldata" style={{ background: "none" }}>
                <Div className="tbl-box-rows" sx={{ py: 1, border: 1 }}>
                  <TableCell style={{ width: 110 }}>Request ID</TableCell>

                  <TableCell style={{ width: 150 }}>Merchant Name</TableCell>

                  <TableCell style={{ width: 120 }}>Merchant Number</TableCell>

                  <TableCell style={{ width: 90 }}>Created on</TableCell>

                  <TableCell style={{ width: 60 }}>Status</TableCell>

                  <TableCell style={{ width: 125 }}>Action</TableCell>
                </Div>
              </TableRow>
            </TableHead>
            <TableBody>
              {userdata != " " &&
                userdata.map((row, index) => {
                  {
                    /* const { _id, firstName, lastName, emailId, roleData, status, } = row; */
                  }

                  return (
                    <TableRow
                      className="tblrowdata"
                      style={{ background: "none" }}
                    >
                      <Div className="tbl-box-rows-body" sx={{ py: 1 }}>
                        <TableCell style={{ width: 110 }}>
                          <Link
                            to={`/kyc/details?kycId=${row._id}&customerId=${row.customer_id}`}
                            style={{ textDecoration: "none", color: "#2160F6" }}
                          >
                            {row._id}
                          </Link>
                        </TableCell>

                        <TableCell style={{ width: 150 }}>
                          {row.customersData.first_name}{" "}
                          {row.customersData.last_name}
                        </TableCell>

                        <TableCell style={{ width: 120 }}>
                          {row.customersData.mobile_no}
                        </TableCell>

                        <TableCell style={{ width: 90 }}>
                          {moment(row.createdAt).format("YYYY/MM/DD hh:mm A")}
                        </TableCell>

                        <TableCell style={{ width: 60 }}>
                          <KycStatus status={row.kycStatus} />
                        </TableCell>

                        <TableCell style={{ width: 125 }}>
                          <div style={{ display: "flex" }}>
                            {/* { !["Approved", "Rejected", "Expired"].includes(row.kycStatus) &&  */}

                            <Button
                              className="btn-action"
                              variant="contained"
                              startIcon={
                                <CancelIcon
                                  style={{
                                    backgroundColor: [
                                      "Approved",
                                      "Rejected",
                                      "Expired",
                                    ].includes(row.kycStatus)
                                      ? "#c0c0c0"
                                      : "#ff0000",
                                    borderRadius: "50%",
                                    marginRight: "-10px",
                                  }}
                                />
                              }
                              disabled={[
                                "Approved",
                                "Rejected",
                                "Expired",
                              ].includes(row.kycStatus)}
                              style={{
                                backgroundColor: [
                                  "Approved",
                                  "Rejected",
                                  "Expired",
                                ].includes(row.kycStatus)
                                  ? "#0000001a"
                                  : "rgba(255, 0, 0, 0.3)",
                                width: "10px",
                                width: "5px",
                                marginRight: "5px",
                              }}
                              onClick={
                                () => handleCommentsAction(row._id, false)
                                // setSnackbar({
                                //   open: true,
                                //   message: "Kyc status rejected",
                                //   severiety: "error",
                                // })
                              }
                            ></Button>

                            <Button
                              className="btn-action"
                              variant="contained"
                              startIcon={
                                <CheckCircleOutlineIcon
                                  style={{
                                    backgroundColor: [
                                      "Approved",
                                      "Rejected",
                                      "Expired",
                                    ].includes(row.kycStatus)
                                      ? "#c0c0c0"
                                      : "#2ed06d",
                                    borderRadius: "50%",
                                    marginRight: "-10px",
                                  }}
                                />
                              }
                              disabled={[
                                "Approved",
                                "Rejected",
                                "Expired",
                              ].includes(row.kycStatus)}
                              style={{
                                backgroundColor: [
                                  "Approved",
                                  "Rejected",
                                  "Expired",
                                ].includes(row.kycStatus)
                                  ? "#0000001a"
                                  : "rgba(46, 208, 109, 0.25)",
                                width: "10px",
                                marginRight: "10px",
                              }}
                              onClick={() =>
                                handleCommentsAction(row._id, true)
                              }
                            ></Button>

                            {/* } */}
                          </div>
                        </TableCell>
                      </Div>
                    </TableRow>
                  );
                })}
              {userdata == "" ? (
                <p style={{ textAlign: "center" }}>No Data Found</p>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {snackbar.message && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbar.severiety}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        )}

        {
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Please add your review comments:</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="comments"
                type="text"
                fullWidth
                variant="standard"
                value={comments}
                onChange={handleCommentsChanges}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button onClick={handleAction}>submit</Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    </>
  );
}

export const KycStatus = (props) => {
  const statusColor = (status) => {
    switch (status) {
      case "Approved":
        return "#2ed06d";
      case "Initiated":
        return "#1058ff";
      case "In progress":
        return "#e3b123";
      case "Expired":
        return "#ff0000";
      default:
        return "#ff0000";
    }
  };
  return (
    <p
      style={{
        color: statusColor(props.status),
        fontSize: "inherit",
        ...props.style,
      }}
    >
      {props.status}
    </p>
  );
};
