import React, { useEffect, useState } from "react";
import AcquireTab from "./AcquirerTab";
import { Link, NavLink, useLocation } from "react-router-dom";

const MerchantAcquireTabMain = (props) => {
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState([]);

  const search = useLocation().search;

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (props?.value) {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <>
      <AcquireTab
        value={value}
        setValue={setValue}
        handleChange={handleTabChange}
        redirecttodetail={props.redirecttodetail}
        getConfigDetail={props.getConfigDetail}
        customerId={props.customerId}
      />
    </>
  );
};

export default MerchantAcquireTabMain;
