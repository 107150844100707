import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./Emirates.css";
import "../viewdoc.css";
import CustomizedDialogs from "../viewdoc";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import AxiosBase from "../../../../../../../../networkRequest/axiosBase";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import UploadDoc from "../UploadDoc";

const Input = styled("input")({
  display: "none",
});

function EmiratesId(props) {
  const [showPopup, setShowPopup] = useState(true);
  const inputFile = useRef(null);
  const search = useLocation().search;
  const dtlImgUrl = new URLSearchParams(search).get("dtlImgUrl");
  const customerId = new URLSearchParams(search).get("customerId");
  const name = new URLSearchParams(search).get("name");
  const kycId = new URLSearchParams(search).get("kycId");

  const styles = {
    popUp: {
      width: "230px",
      height: "80px",
      zIndex: "1",
      backgroundColor: "white",
      position: "absolute",
      boxShadow: "0px 5px 10px grey",
      borderRadius: "5px",
      paddingTop: "15px",
      left: "600px",
      top: "140px",
    },
    popUpButton: {
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      color: "#1058ff",
      display: "flex",
      alignItems: "center",
    },
    popUpButtonImage: { height: "20px", margin: "0px 5px" },
  };

  const uploaddocument = () => {
    setUploadDoc(true);
  };
  const handleUploadDocClose = () => {
    setUploadDoc(false);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const handleDocumentClose = () => {
    setOpen(false);
  };

  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState();
  const [uploadDoc, setUploadDoc] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const onFileUpload = async (e) => {
    try {
      setLoader(true);
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "EMIRATES_ID");
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const res = await AxiosBase.post(
        `/admin/kyc/upload/${kycId}`,
        formData,
        config
      );

      setData({
        ...data,
        emiratesImgUrl: res.data.url,
      });
      props.setData(res.data.data);
      setLoader(false);
      return true;
    } catch (e) {
      setLoader(false);
      if (e.response.data.message == "Expired Document") {
        props.setData(e.response.data.data);
        props.setSnackbar({
          open: true,
          message: "Uploaded document expired",
          severiety: "error",
        });
        return false;
      }

      props.setSnackbar({
        open: true,
        message: "Invalid File",
        severiety: "error",
      });
    }
  };

  const url = [
    {
      name: "Emirates ID",
      url: data.emiratesImgUrl
        ? data.emiratesImgUrl
        : "/default-upload-doc.svg",
    },
  ];

  useEffect(() => {
    AxiosBase.get(`/admin/kyc/view-kyc-details/${kycId}`).then((response) => {
      setData(response.data);
    });
  }, []);

  return (
    <>
      <Card
        className="emiratesID"
        sx={{
          width: 500,
          maxHeight: 380,
          border: "0.4px solid #dadada",
          mt: "20px",
          marginLeft: "auto",
        }}
      >
        <CardHeader
          action={
            <IconButton aria-label="settings">
              <Input
                accept="image/*"
                id="contained-button-file"
                multiple
                ref={inputFile}
                type="file"
                value=""
                onChange={(e) => onFileUpload(e)}
              />

              <Button
                disabled={
                  props.data.emiratesIdVerified == "Approved" ||
                  props.data.emiratesIdVerified == "Rejected" ||
                  props.data.tradeLicenseVerified == "Rejected"
                    ? true
                    : false
                }
                style={{
                  backgroundColor:
                    props.data.emiratesIdVerified == "Rejected" ||
                    props.data.tradeLicenseVerified == "Rejected"
                      ? "#a3a1a1"
                      : "#1058ff",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
                variant="contained"
                onClick={uploaddocument}
                // onClick={() => {
                //   inputFile.current.click();
                // }}
                startIcon={<BackupOutlinedIcon />}
                component="span"
              >
                Upload Document
              </Button>
            </IconButton>
          }
          title="Emirates ID "
          className="emirates_view_inner_img"
        />

        {loader ? (
          <div className="imagecontainer">
            <div className="center">
              <CircularProgress />
            </div>
          </div>
        ) : (
          <CardMedia
            className="emirate_view_inner_img"
            component="img"
            height="100%"
            image={
              data.emiratesImgUrl
                ? data.emiratesImgUrl
                : "/default-upload-doc.svg"
            }
            alt="Emirates ID"
            onClick={openDialog}
          />
        )}
      </Card>
      {open == true ? (
        <CustomizedDialogs
          open={open}
          handleDocumentClose={handleDocumentClose}
          item={url}
        />
      ) : (
        <></>
      )}
      {uploadDoc == true ? (
        <UploadDoc
          open={uploadDoc}
          handleDocumentClose={handleUploadDocClose}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default EmiratesId;
