import React from "react";
import Volume from "./Volume";
import TransactionVsPaymentMethod from "./TransactionVsPaymentMethod";
import "./index.scss";

export default function SecondSection(props) {
  return (
    <div className="second-main">
      <div className="second-section">
        <div className="second-section__left second-section-card">
          <h6>Transactions Vs Payment Method</h6>
          <div className="second-section-cart">
            <TransactionVsPaymentMethod
              data={props?.data?.transaction_vs_payment_method}
              filter={props.filter}
            />
          </div>
        </div>
        <div className="second-section__right second-section-card">
          <h6>
            Order Volume{" "}
            <span className="second-section-card__hint">(Amount)</span>
          </h6>
          <div className="second-section-cart">
            <Volume data={props?.data?.volume_chart} filter={props.filter} />
          </div>
        </div>
      </div>
    </div>
  );
}
