import React from "react";
import { Route, Routes } from "react-router-dom";
import MenuList from "../../Components/Users/menu/menuList/index";
import AddMenu from "../../Components/Users/menu/add-menu/AddMenu";
// import EditMenu from '../../page-components/Users/menu/editMenu';
import Topheader from "../../universal-components/header/topHeader";

function MenuListPage() {
  return (
    <div style={{ padding: "15px" }}>
      <Topheader />

      <Routes>
        <Route exact path="" element={<MenuList />} />
        <Route exact path="addmenu/:id" element={<AddMenu />} />
        <Route exact path="addmenu" element={<AddMenu />} />
        {/* <Route path="editmenu" element={<EditMenu />} /> */}
      </Routes>
    </div>
  );
}

export default MenuListPage;
