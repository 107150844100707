import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import AxiosBase from "../../../networkRequest/axiosBase";
import CircularProgress from "@mui/material/CircularProgress";
import "./UserRole.css";
import { useNavigate } from "react-router-dom";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});
const UserRole = (props) => {
  const { rolePermission } = props;
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedIds, setselectedIds] = React.useState([]);
  const [hideIds, setHideIds] = React.useState([]);
  const history = useNavigate();

  useEffect(() => {
    AxiosBase.get(`/admin/roles?sortOrder=asc`).then((response) => {
      setData(response.data.data);
      setLoading(false);
    });
  }, []);

  const handleChange = (index) => {
    let innerIds = selectedIds;
    let innerdata = data;
    if (innerIds.includes(innerdata[index]._id)) {
      innerIds.splice(innerIds.indexOf(innerdata[index]._id), 1);
    } else {
      innerIds.push(innerdata[index]._id);
    }
    setselectedIds([...innerIds]);
    props.roleinfo(selectedIds);
  };

  const saveData = async (e) => {
    props.submitCode();
  };
  const handleSearch = (e) => {
    setLoading(true);
    AxiosBase.get(`/admin/roles?sortOrder=asc&search=${e.target.value}`).then(
      (response) => {
        setData(response.data.data);
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    let InnerObj = {};

    for (let i = 0; i < props.data.roleData?.length; i++) {
      InnerObj[props.data.roleData[i]._id] = props.data.roleData[i].is_active
        ? "true"
        : "false";
    }
    setHideIds(InnerObj);
    setselectedIds(props.data.roleId);
  }, [props.data]);

  return (
    <>
      <div className="user_role_main_div">
        <div>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            className="merchant_grid_sec"
          >
            <CssTextField
              className=""
              id="new-menu"
              placeholder="search"
              variant="outlined"
              fullWidth
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <IconButton className="merchant_search_icon">
                      <img src="/search.svg" alt="" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </div>

        <div>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="merchant_grid_sec"
          >
            <div className="user_role_box_div purple_box">
              <p className="assign">Assigned</p>
              <p className="assign">Role</p>
            </div>
          </Grid>
        </div>
        {loading ? (
          <div className="no_data_found loader_data_tbl">
            <CircularProgress />
          </div>
        ) : data == "" ? (
          <div className="no_data_found loader_data_tbl">
            <p>No Data Found</p>
          </div>
        ) : (
          ""
        )}
        {data.map(
          (row, index) =>
            ((hideIds[row._id] && hideIds[row._id] !== "false") ||
              !hideIds[row._id]) && (
              <>
                <div>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="merchant_grid_sec"
                  >
                    <div className="user_role_box_div">
                      <div className="user_role_check_box">
                        <Checkbox
                          value={row._id}
                          checked={selectedIds.includes(row._id)}
                          onChange={(e) => handleChange(index)}
                          // onSelectionChange={
                          //   handleChange
                          // }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </div>
                      <p className="assign check_box_name">{row.role_name}</p>
                    </div>
                  </Grid>
                </div>
              </>
            )
        )}

        <div className="merchant_edit_button_dev gateway_save_cancel_btn">
          <Button
            className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
            variant="contained"
            onClick={() => {
              history(`/users`);
            }}
          >
            Cancel
          </Button>
          {rolePermission &&
            rolePermission.includes(props.userId != "" ? "EDIT" : "ADD") && (
              <Button
                className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                variant="contained"
                onClick={(event) => saveData(event)}
              >
                Save
              </Button>
            )}
        </div>
      </div>
    </>
  );
};

export default UserRole;
