import React from "react";
import Topheader from "../../universal-components/header/topHeader";
import { Route, Routes } from "react-router-dom";
import AcquirerList from "../../Components/acquirerlist/AcquirerList";
import AcquirerTab from "../../Components/acquirer/index";

const AcquirerListMain = () => {
  return (
    <>
      <div style={{ padding: "15px", borderRadius: "5rem", width: "98%" }}>
        <Topheader />
        <Routes>
          <Route path="" element={<AcquirerList />} />
          <Route path="acquirer" element={<AcquirerTab />} />
        </Routes>
      </div>
    </>
  );
};

export default AcquirerListMain;
