import AxiosBase from "../axiosBase";

export const getAllMerchantList = (
  pageNo,
  rowsPerPage,
  sortBy,
  sortOrder,
  search,
  filter
) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(
      `/admin/merchant?pageNo=${pageNo}&rowsPerPage=${rowsPerPage}&sortOrder=${sortOrder}` +
        `&sortBy=${sortBy}` +
        `&search=${search ? search : ""}` +
        `&filter=${filter}`
    ).then((response) => {
      resolve(response);
    });
  });
};

export const getAllMerchantUserList = (
  pageNo,
  rowsPerPage,
  sortBy,
  sortOrder,
  search,
  filter
) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(
      `/admin/merchant-contact?pageNo=${pageNo}&rowsPerPage=${rowsPerPage}&sortOrder=${sortOrder}` +
        `&sortBy=${sortBy}` +
        `&search=${search ? search : ""}` +
        `&filter=${filter}`
    ).then((response) => {
      resolve(response);
    });
  });
};

export const addMerchant = (reqBody) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/admin/merchant`, reqBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("error", err);
        resolve(err);
      });
  });
};

export const updateMerchant = (reqBody) => {
  return new Promise((resolve, reject) => {
    AxiosBase.patch(`/admin/merchant/` + reqBody._id, reqBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("error", err);
        resolve(err);
      });
  });
};

export const getAllSuperMerchantList = (
  pageNo,
  rowsPerPage,
  sortBy,
  sortOrder,
  search,
  filter
) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(
      `/admin/super-merchant?pageNo=${pageNo}&rowsPerPage=${rowsPerPage}&sortOrder=${sortOrder}` +
        `&sortBy=${sortBy}` +
        `&search=${search ? search : ""}` +
        `&filter=${filter}`
    ).then((response) => {
      resolve(response);
    });
  });
};

export const AddSuperMerchant = (validation) => {
  return new Promise((resolve, reject) => {
    AxiosBase.post(`/admin/super-merchant`, validation)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("===========>>err", err);
        resolve(err.response);
      });
  });
};

export const UpdateSuperMerchant = (id, validation) => {
  return new Promise((resolve, reject) => {
    AxiosBase.patch(`/admin/super-merchant/${id}`, validation)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err.response);
      });
  });
};

export const getMerchantList = () => {
  return AxiosBase.get("/admin/merchant/list");
};
