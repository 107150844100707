import React from "react";
import { useState, useEffect } from "react";
import "./StatusCode.css";
import {
  GetAllStatus,
  UpdateStatus,
  AddStatus,
} from "../../networkRequest/request/acquirer";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import NavbarHoc from "../../pages/navhoc/index";
import Config from "../../config.json";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "6px",
    background: "white",
  },
});

const StatusCode = () => {
  const [statusCode, setStatusCode] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [checkFlag, setCheckFlag] = React.useState(false);
  const [updateData, setUpdateData] = React.useState({});
  const [loader, setLoader] = React.useState(true);
  const [innerLoader, setInnerLoader] = React.useState(false);
  const [rolePermission, setRolePermission] = React.useState([]);
  useEffect(() => {
    UpdateCodeList();
    setLoader(false);
  }, []);

  const UpdateCodeList = async () => {
    let data = await GetAllStatus();
    data?.data?.data && setStatusCode(data?.data?.data);
  };
  const OpenModal = (req, flag) => {
    setOpen(true);
    setUpdateData({ ...req });
    setCheckFlag(flag);
  };

  const AddOrUpdateStatusCode = async () => {
    if (
      updateData.code &&
      updateData.code !== "" &&
      updateData.code.trim() !== ""
    ) {
      setInnerLoader(true);
      try {
        let response = !checkFlag
          ? await UpdateStatus(updateData)
          : await AddStatus(updateData);
        if (response?.status == 200) {
          let innerVar = !checkFlag ? "Updated" : "Added";
          toast(`Status Code Successfully ${innerVar}`, {
            type: "success",
            autoClose: 2000,
          });
          setOpen(false);
          await UpdateCodeList();
        } else {
          toast(response?.data?.message || "Something went wrong!", {
            type: "error",
            autoClose: 2000,
          });
        }
      } catch (err) {
        toast(err?.response?.data?.message || "Something went wrong!", {
          type: "error",
          autoClose: 2000,
        });
      }
      setInnerLoader(false);
    } else {
      toast("Status Code Is Mandatory", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };

  const InputType = (String, value) => {
    let data = updateData;
    data[String] = value;
    setUpdateData({ ...data });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  return (
    <>
      <NavbarHoc
        id={Config.PermissionId.StatusCode}
        onGetReports={(e) => {
          setRolePermission(e.ParticularData);
        }}
      >
        <div className="add_merchant_list">
          <h3 className="merchant_title">Status Code</h3>
        </div>
        {loader ? (
          <div className="no_data_found loader_data_tbl">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="StatusCode">
              <Grid
                className="AddGrid"
                container
                spacing={2}
                justifyContent="flex-end"
              >
                {rolePermission.includes("ADD") && (
                  <Button
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    onClick={() => {
                      OpenModal({}, true);
                    }}
                    className="merchant_list_add_new_merchant "
                    style={{ marginRight: "29px" }}
                    variant="contained"
                  >
                    Add Code
                  </Button>
                )}
              </Grid>
              <TableContainer className="StatusTableContainer">
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className={
                    statusCode.length > 0 && !loader
                      ? "table_container"
                      : "table_container table_container_nodata"
                  }
                >
                  <TableHead className="data_tbl_head_row">
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          color: "#666666",
                          borderTop: "0.5px solid #c9c9c9",
                        }}
                      >
                        Code
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontWeight: "bold",
                          color: "#666666",
                          borderTop: "0.5px solid #c9c9c9",
                        }}
                      >
                        Http Code
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontWeight: "bold",
                          color: "#666666",
                          borderTop: "0.5px solid #c9c9c9",
                        }}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontWeight: "bold",
                          color: "#666666",
                          borderTop: "0.5px solid #c9c9c9",
                        }}
                      >
                        Abbreviation
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontWeight: "bold",
                          color: "#666666",
                          borderTop: "0.5px solid #c9c9c9",
                        }}
                      >
                        Message
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontWeight: "bold",
                          color: "#666666",
                          borderTop: "0.5px solid #c9c9c9",
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontWeight: "bold",
                          color: "#666666",
                          borderTop: "0.5px solid #c9c9c9",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="data_tbl_body_row">
                    {statusCode
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((fact, index) => {
                        return (
                          <TableRow key={index} style={{ background: "white" }}>
                            <TableCell>{fact.code}</TableCell>
                            <TableCell>{fact.http_code}</TableCell>
                            <TableCell>{fact.desc}</TableCell>
                            <TableCell>{fact.abbreviation}</TableCell>
                            <TableCell>{fact.message}</TableCell>
                            <TableCell>
                              {fact.status ? "Active" : "Suspended"}
                            </TableCell>
                            <TableCell>
                              <IconButton sx={{ borderRadius: "50%" }}>
                                <img
                                  src="/editt.svg"
                                  alt=""
                                  style={{ height: "25px", width: "25px" }}
                                  onClick={() => {
                                    rolePermission.includes("EDIT") &&
                                      OpenModal(fact, false);
                                  }}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {!loader && statusCode.length > 0 ? (
                <TablePagination
                  className="table_pagination"
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={statusCode.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : (
                <></>
              )}
              <Modal
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
              >
                <div className="ModalStatus">
                  <div className="ModalHeader">
                    {!checkFlag ? `Manage ` : `Add `}Status Code
                  </div>
                  {innerLoader ? (
                    <div className="no_data_found loader_data_tbl">
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      <div className="ModalBody">
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={3}
                          className=""
                          style={{ marginTop: "-7%" }}
                        >
                          <p className="ModalTitle">
                            Code <span className="Mandatory">*</span>
                          </p>
                          <CssTextField
                            className=""
                            id="new-menu"
                            placeholder="Code"
                            variant="outlined"
                            fullWidth
                            value={updateData?.code}
                            onChange={(e) => {
                              InputType("code", e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={3}
                          className=""
                        >
                          <p className="ModalTitle">Http Code</p>
                          <CssTextField
                            className=""
                            id="new-menu"
                            placeholder="Description"
                            variant="outlined"
                            fullWidth
                            value={updateData?.http_code}
                            onChange={(e) => {
                              let val = e.target.value.match("^[0-9]*$");
                              if (val) {
                                InputType("http_code", e.target.value);
                              } else {
                                setUpdateData({
                                  http_code: updateData?.http_code,
                                });
                              }
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={3}
                          className=""
                        >
                          <p className="ModalTitle">Description</p>
                          <CssTextField
                            className=""
                            id="new-menu"
                            placeholder="Description"
                            variant="outlined"
                            fullWidth
                            value={updateData?.desc}
                            onChange={(e) => {
                              InputType("desc", e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={3}
                          className=""
                        >
                          <p className="ModalTitle">Abbreviation</p>
                          <CssTextField
                            className=""
                            id="new-menu"
                            placeholder="Abbreviation"
                            variant="outlined"
                            fullWidth
                            value={updateData?.abbreviation}
                            onChange={(e) => {
                              InputType("abbreviation", e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={3}
                          className=""
                        >
                          <p className="ModalTitle">Message</p>
                          <CssTextField
                            className=""
                            id="new-menu"
                            placeholder="Message"
                            variant="outlined"
                            fullWidth
                            value={updateData?.message}
                            onChange={(e) => {
                              InputType("message", e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={3}
                          className=""
                        >
                          <p className="ModalTitle">Status</p>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={updateData?.status}
                              label=""
                              placeholder="Select Status"
                              onChange={(e) => {
                                InputType("status", e.target.value);
                              }}
                            >
                              <MenuItem value={true}>Active</MenuItem>
                              <MenuItem value={false}>Suspended</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </div>
                      <div className="ModalFooter">
                        <Button
                          onClick={AddOrUpdateStatusCode}
                          className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                          style={{ marginRight: "14px" }}
                          variant="contained"
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => {
                            setOpen(false);
                          }}
                          className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                          variant="contained"
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Modal>
            </div>
          </>
        )}
      </NavbarHoc>
    </>
  );
};

export default StatusCode;
