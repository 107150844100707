import React from 'react'
import MerchantSettlement from './merchant_settlement';

function Merchants() {
  return (
    <div>
      <MerchantSettlement/>
    </div>
  )
}

export default Merchants
