import React, { useEffect } from "react";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import TablePagination from "@mui/material/TablePagination";
import { getAllMerchantList } from "../../../networkRequest/request/merchant";
import Grid from "@mui/material/Grid";
import "./Merchant.css";
import Filter from "../../filter-component/Filter";
import CircularProgress from "@mui/material/CircularProgress";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import Search from "@mui/icons-material/Search";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { useNavigate } from "react-router-dom";
import NavbarHoc from "../../../pages/navhoc/index";
import Config from "../../../config.json";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "500px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});
const MerchantList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearch] = React.useState();
  const [searchByCalled, setsearchByCalled] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const history = useNavigate();
  const [rolePermission, setRolePermission] = React.useState([]);
  const [filter, setFilter] = React.useState([
    { key: "", type: "", value: "" },
  ]);
  const filterOptons = [
    { property: "Full Name", value: "full_name" },
    { property: "Email ID", value: "email_id" },
    { property: "Phone Number", value: "mobile_no" },
  ];
  const filterContains = [
    { property: "Is", value: "is" },
    { property: "Like", value: "contain" },
    { property: "Not Like", value: "not_contain" },
  ];

  useEffect(() => {
    getAllMerchantData();
  }, [page, rowsPerPage, count, searchByCalled]);

  const getAllMerchantData = async () => {
    setLoading(true);
    setData([]);
    const filtersval = filter[0].key != "" ? JSON.stringify(filter) : "";
    if (containsSpecialCharacters(searchBy)) {
      setData([]);
      setCount(0);
      setLoading(false);
    } else {
      const merchantList = await getAllMerchantList(
        page,
        rowsPerPage,
        "_id",
        "desc",
        searchBy,
        filtersval
      );
      setData(merchantList.data.data);
      setCount(merchantList.data.count);
      setLoading(false);
    }
  };

  useEffect(() => {
    getFilterData();
  }, [filter]);

  const getFilterData = async () => {
    const filtersval = filter[0].key != "" ? JSON.stringify(filter) : "";
    if (filtersval != "") {
      setLoading(true);
      setData([]);
      const merchantList = await getAllMerchantList(
        page,
        rowsPerPage,
        "_id",
        "desc",
        searchBy,
        filtersval
      );
      setData(merchantList.data.data);
      setCount(merchantList.data.count);
      setLoading(false);
    }
  };

  const handleSearchButton = (e) => {
    setPage(0);
    setFilter([{ key: "", type: "", value: "" }]);
    setsearchByCalled(!searchByCalled);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.key === "Enter") {
      setPage(0);
      setFilter([{ key: "", type: "", value: "" }]);
      setsearchByCalled(!searchByCalled);
    }
  };
  const ResetFilter = async (event) => {
    setFilter([{ key: "", type: "", value: "" }]);
    setsearchByCalled(!searchByCalled);
  };

  const [anchorE2, setAnchorE2] = React.useState(null);
  const handleFilter = async (JSONfilter) => {
    setFilter(JSONfilter);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const pophandleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const pophandleClose = () => {
    setAnchorE2(null);
  };

  const redirecttodetail = (_id) => {
    history(`/merchants/profile?merchantId=${_id}`);
  };

  const openpop = Boolean(anchorE2);
  const id1 = openpop ? "simple-popover" : undefined;
  return (
    <NavbarHoc
      id={Config.PermissionId.Merchants}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Merchant</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                // sm={12}
                md={12}
                lg={12}
                // xl={4}
                className="merchant_grid_sec"
              >
                <div
                  className="merchant_list_filter_div"
                  style={{ height: "100%" }}
                >
                  <CssTextField
                    className="serachBar"
                    id="new-menu"
                    placeholder="Search"
                    variant="outlined"
                    // fullWidth="false"
                    onKeyUp={handleSearch}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton className="merchant_search_icon">
                            <i
                              onClick={handleSearchButton}
                              class="fa fa-search"
                              aria-hidden="true"
                            ></i>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Tooltip title="Filter records">
                    <div>
                      <i
                        style={{
                          fontSize: "30px",
                          marginLeft: "10px",
                          color: "#757575",
                          cursor: "pointer",
                        }}
                        onClick={pophandleClick}
                        class="fa fa-filter"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Tooltip>

                  {filter[0].key != "" ? (
                    <Tooltip title="Refresh to records">
                      <div>
                        <i
                          style={{
                            fontSize: "30px",
                            marginLeft: "10px",
                            color: "#757575",
                            cursor: "pointer",
                          }}
                          onClick={ResetFilter}
                          class="fa fa-refresh"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </div>
              </Grid>
              {rolePermission.includes("ADD") && (
                <Link className="margin-auto" to={`/merchants/createmerchant`}>
                  <Button
                    className="merchant_list_add_new_merchant"
                    variant="contained"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                  >
                    Add
                  </Button>
                </Link>
              )}
            </div>
            <div className="add_merchant_list"></div>
          </div>
          <TableContainer
            className={
              data.length > 0 && !loading
                ? "table_container"
                : "table_container table_container_nodata"
            }
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Merchant ID</TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Email ID</TableCell>
                  <TableCell>Created at</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              {!loading ? (
                <TableBody className="data_tbl_body_row">
                  {data?.map((curElm) => {
                    const {
                      _id,
                      full_name,
                      mobile_no,
                      email_id,
                      createdAt,
                      is_active,
                    } = curElm;
                    return (
                      <TableRow
                        onClick={(e) =>
                          (rolePermission.includes("EDIT") ||
                            rolePermission.includes("VIEW")) &&
                          redirecttodetail(_id)
                        }
                        className=" tbl_id merchant_data_tbl_row merchant_tbl_edit_icon"
                      >
                        <TableCell>{_id}</TableCell>

                        <TableCell>
                          {full_name?.length > 10 ? (
                            <Tooltip title={full_name}>
                              <Button className="tbl_id">
                                {checklenght(full_name, 10)}
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button className="tbl_id">
                              {checklenght(full_name, 10)}
                            </Button>
                          )}
                        </TableCell>
                        <TableCell>{checkphone(mobile_no)}</TableCell>
                        <TableCell>
                          {email_id?.length > 15 ? (
                            <Tooltip title={email_id}>
                              <Button className="tbl_id1">
                                {checklenght(email_id, 15)}
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button className="tbl_id1">
                              {checklenght(email_id, 15)}
                            </Button>
                          )}
                        </TableCell>
                        <TableCell>
                          {moment(createdAt).format("YYYY/MM/DD hh:mm A")}
                        </TableCell>
                        <TableCell>
                          {is_active ? "Active" : "Inactive"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <></>
              )}
            </Table>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress style={{ color: "#1058ff" }} />
              </div>
            ) : data == "" ? (
              <div className="no_data_found loader_data_tbl">
                <p>No Records Found</p>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          {!loading && data.length > 0 ? (
            <TablePagination
              className="table_pagination"
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </div>
        <Popover
          id={id1}
          open={openpop}
          anchorEl={anchorE2}
          onClose={pophandleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ marginLeft: "135px", height: "500px", marginTop: "10px" }}
        >
          <Typography sx={{ p: 2 }}>
            <Filter
              filter1={handleFilter}
              pophandleClose={pophandleClose}
              optionns={filterOptons}
              contains={filterContains}
              filter={filter}
            />
          </Typography>
        </Popover>
      </div>
    </NavbarHoc>
  );
};

export default MerchantList;

function checklenght(val, lenght) {
  if (val?.length > lenght) {
    return val?.substring(0, lenght) + "..";
  }
  if (!val) {
    return "NA";
  }
  return val;
}

function checkphone(val) {
  if (val.charAt(0) == "+") {
    return checklenght(val, 14);
  } else {
    return "+" + checklenght(val, 14);
  }
}
function containsSpecialCharacters(str) {
  var regex = /[~`!#$%\^&*+=\-\[\]\';,/{}|\\":<>\?]/g;
  return regex.test(str);
}
