import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const DecimalSettings = (props) => {
  const {
    rolePermission,
    allStatusCodeMapping,
    id,
    AddAcquirerProp,
    UpdateAcquirerProp,
  } = props;
  const [loader, setLoader] = React.useState(true);
  const [controlDisable, setControlDisable] = React.useState(false);
  const [ogData, setOgData] = React.useState({});
  const [allStatusData, setAllStatusData] = React.useState({});

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    CommonCallFunction();
  }, [id, allStatusCodeMapping]);

  const CommonCallFunction = () => {
    setOgData(allStatusCodeMapping);
    setAllStatusData(allStatusCodeMapping.acquirer_decimal_mdr_format);
    setLoader(false);
  };

  const SaveCode = async () => {
    if (
      allStatusData?.fixed_fees &&
      allStatusData?.margin &&
      allStatusData?.vat &&
      allStatusData?.settlement_amount &&
      allStatusData?.settlement_commission_amount
    ) {
      setLoader(true);
      let data = {
        ...ogData,
        acquirer_decimal_mdr_format: allStatusData,
      };
      ogData.acquirer_decimal_mdr_format = allStatusData;
      setOgData(ogData);
      await UpdateAcquirerProp(data, data._id);
      setLoader(false);
    } else {
      toast("Please fill all the mandatory details.", {
        type: "warning",
        autoClose: 2000,
      });
    }
  };

  const onChange = (e) => {
    const value = e.target.value;
    if (!value) {
      setAllStatusData({
        ...allStatusData,
        [e.target.name]: "",
      });
      return true;
    }
    if (Number(value) > -1 && Number(value) < 10) {
      setAllStatusData({
        ...allStatusData,
        [e.target.name]: parseInt(value),
      });
    }
  };

  const confirmReset = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resetData = async () => {
    let data = {
      ...ogData,
      acquirer_decimal_mdr_format: {},
    };
    ogData.acquirer_decimal_mdr_format = {};
    setOgData(ogData);
    setAllStatusData({});
    await UpdateAcquirerProp(data, data._id);
    setOpen(false);
    setLoader(false);
  };

  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="OtherSettings">
          <Grid className="OtherSettingsRow" container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Fixed Fees <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Fixed Fees"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                name="fixed_fees"
                value={allStatusData?.fixed_fees}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Margin <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Margin"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                name="margin"
                value={allStatusData?.margin}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Vat <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Vat"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                name="vat"
                value={allStatusData?.vat}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Settlement Amount <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Settlement Amount"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                name="settlement_amount"
                value={allStatusData?.settlement_amount}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className="">
              <p className="OtherTitle">
                Settlement Commission Amount{" "}
                <span className="Mandatory">*</span>
              </p>
              <CssTextField
                className=""
                id="new-menu"
                placeholder="Settlement Commission Amount"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                name="settlement_commission_amount"
                value={allStatusData?.settlement_commission_amount}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <Grid
            className="OtherSettingsRow2"
            container
            spacing={2}
            justifyContent="flex-end"
          >
            <Button
              className="merchant_reset_btn merchant_detail_tab_save-cancel_btn reset_btn"
              style={{ "background-color": "#1058FF" }}
              variant="contained"
              disabled={!allStatusData || JSON.stringify(allStatusData) == "{}"}
              onClick={confirmReset}
            >
              Reset
            </Button>
            <Button
              onClick={SaveCode}
              className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
              style={{ marginRight: "29px" }}
              variant="contained"
            >
              Save
            </Button>
          </Grid>
          <div>
            <BootstrapDialog
              className="order_detail_refund_popup"
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogContent>
                <Typography
                  className="order_detail_refund_popup_title"
                  gutterBottom
                >
                  <p>Confirm Reset</p>
                  <span>Are you sure want to reset configuration?</span>
                  <div className="order_detail_refund_popup_btn">
                    <Button
                      className="refund_save_btn"
                      variant="contained"
                      autoFocus
                      fullWidth
                      onClick={resetData}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="refund_cancel_btn"
                      variant="contained"
                      autoFocus
                      onClick={handleClose}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </div>
                </Typography>
              </DialogContent>
            </BootstrapDialog>
          </div>
          <div>
            <BootstrapDialog
              className="order_detail_refund_popup"
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogContent>
                <Typography
                  className="order_detail_refund_popup_title"
                  gutterBottom
                >
                  <p>Confirm Reset</p>
                  <span>Are you sure want to reset configuration?</span>
                  <div className="order_detail_refund_popup_btn">
                    <Button
                      className="refund_save_btn"
                      variant="contained"
                      autoFocus
                      fullWidth
                      onClick={resetData}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="refund_cancel_btn"
                      variant="contained"
                      autoFocus
                      onClick={handleClose}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </div>
                </Typography>
              </DialogContent>
            </BootstrapDialog>
          </div>
        </div>
      )}
    </>
  );
};

export default DecimalSettings;
