import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.scss";

const getOptions = (labels) => {
  return {
    chart: {
      width: 300,
      type: "donut",
    },
    colors: ["#38EDCC","#0F79DB", "#D14DFF" ],
    dataLabels: {
      enabled: true,
    },
    fill: {
      type: "gradient",
    },
    labels: labels,
    plotOptions: {
      pie: {
        donut: {
          size: '45%'
        }
      }
    },
    responsive: [
      {
        breakpoint: 400,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
};

export default function PaymentMethod(props) {
  const [options, setOptions] = useState(getOptions());
  const [series, setSeries] = useState([]);

  useEffect(() => {
    let lablesData = [];

    
  const newData =["CREDIT_CARD","DEBIT_CARD"]?.map((key) => {
      let resData = null;
        props?.data?.map((res) => {
          if (key == res._id) {
            resData = res
          }
        });
        if (!resData) {
          resData = {
            _id: key,
            amount: 0,
            count:0,
          };
        }
      return resData;
    })
    const seriesData = newData?.map((res) => {
      lablesData.push(res._id ? res._id : "Others");
      return res.amount;
    });

    setOptions(getOptions(lablesData));
    setSeries(seriesData);
  }, [props.data]);

  if (!props.data || !series) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div id="chart">
      <Chart options={{...options,}} series={series} type="donut" height={210} />
    </div>
  );
}
