import React, { useEffect } from "react";
import { Paper } from "@mui/material";
import TradeLicense from "./TradeLicense";
import EmiratesId from "./emiratesId/EmiratesId";
import { toast } from "react-toastify";
import {
  saveKycProfileImage,
  viewKycDetails,
} from "../../../../../../../networkRequest/request/kyc";
import { useLocation } from "react-router-dom";
import { viewCustomerDetails } from "../../../../../../../networkRequest/request/customers";
import { Tab, Tabs } from "@mui/material";
import KycHistory from "../history";

import IconButton from "@mui/material/IconButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import axios from "axios";
// import Usericon from '../../../../../../../Icons/'
import Usericon from "../../../../../../../Icons/UserIcon.jpg";
import Container from "@mui/material/Container";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import TradeTab from "./TradeLicense/TradeTab";
import AxiosBase from "../../../../../../../networkRequest/axiosBase";
const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "330px",
    height: "4px",
  },
});
function KycRequestDetails() {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get("customerId");
  const kycId = new URLSearchParams(search).get("kycId");
  const [image, setImage] = React.useState("/yash1.jpg");
  const [userDetail, setUserDetail] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile_no: "",
    gender: "",
    dateOfBirth: "",
    address: "",
  });

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const submitUserData = (e) => {
    e.preventDefault();
  };
  const clicked = (e) => {
    const file = e.target.files;
    setImage(file);
  };
  const selectProfilePic = () => {
    document.getElementById("chooseFile").click();
  };
  const fileChange = (e) => {
    const image = e.target.files[0];
    if (e.target.files && image) {
      if (image.type === "image/jpeg" || image.type === "image/x-png") {
        const reader = new FileReader();
        const test1 = (reader.onload = (e) => setImage(e.target.result));
        const test = reader.readAsDataURL(e.target.files[0]);
        saveKycProfileImage(test);
      } else {
        toast("Invalid file type", { type: "error", autoClose: 5000 });
      }
    }
  };

  const [data, setData] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severiety: "success",
  });
  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: "", severiety: "success" });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const changeTab = () => {
    setValue(0);
  };
  const changeTab1 = () => {
    setValue(2);
  };

  const getData = () => {
    AxiosBase.get(`/admin/kyc/view-kyc-details/${kycId}`).then((response) => {
      setData(response.data);
    });
  };

  useEffect(() => {
    getData();
    setValue(0);
  }, [kycId]);

  useEffect(() => {
    getData();
  }, []);

  const handleTradeLicenseReject = async () => {
    try {
      const res = await AxiosBase.post(
        `/admin/kyc/change-kyc-trade-license-status/${kycId}`,
        { isverified: false }
      );
      setData({
        ...data,
        tradeLicenseVerified: "Rejected",
        kycStatus: "Rejected",
        isverified: false,
      });
      setSnackbar({
        open: true,
        message: "kyc request is rejected",
        severiety: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTradeLicenseApproved = async () => {
    try {
      const res = await AxiosBase.post(
        `/admin/kyc/change-kyc-trade-license-status/${kycId}`,
        { isverified: true }
      );
      setData({
        ...data,
        tradeLicenseVerified: "Approved",
      });
      setSnackbar({
        open: true,
        message: "Kyc request is approved",
        severiety: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTradeLicenseChange = (e) => {
    try {
      setData({
        ...data,
        tradeLicenseData: {
          ...data.tradeLicenseData,
          [e.target.name]: e.target.value,
        },
      });
      setSnackbar({
        open: true,
        message: "This is updated successfully",
        severiety: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTradeLicenseSaveApproved = async () => {
    try {
      const res = await AxiosBase.post(
        `/admin/kyc/update-trade-license/${kycId}`,
        { ...data.tradeLicenseData }
      );
      setData({
        ...data,
        tradeLicenseVerified: "Approved",
      });
      setSnackbar({
        open: true,
        message: "This is updated successfully",
        severiety: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  // emirates  handle changes starte here
  const handleEmiratesReject = async () => {
    try {
      const res = await AxiosBase.post(
        `/admin/kyc/change-kyc-emirates-id-status/${kycId}`,
        { isverified: false }
      );
      setData({
        ...data,
        emiratesIdVerified: "Rejected",
        kycStatus: "Rejected",
        isverified: false,
      });
      setSnackbar({
        open: true,
        message: "This is updated successfully",
        severiety: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmiratesApproved = async () => {
    try {
      const res = await AxiosBase.post(
        `/admin/kyc/change-kyc-emirates-id-status/${kycId}`,
        { isverified: true }
      );
      setData({
        ...data,
        emiratesIdVerified: "Approved",
      });
      setSnackbar({
        open: true,
        message: "This is updated successfully",
        severiety: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmiratesChange = (e) => {
    try {
      setData({
        ...data,
        emiratesData: {
          ...data.emiratesData,
          [e.target.name]: e.target.value,
        },
      });
      setSnackbar({
        open: true,
        message: "This is updated successfully",
        severiety: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmiratesSaveApproved = async () => {
    try {
      const res = await AxiosBase.post(
        `/admin/kyc/update-emirates-id/${kycId}`,
        { ...data.emiratesData }
      );
      setData({
        ...data,
        emiratesIdVerified: "Approved",
      });
      setSnackbar({
        open: true,
        message: "This is updated successfully",
        severiety: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewDocument = () => {
    setValue(2);
  };

  if (!kycId || !customerId) {
    return <h1>404 - Not Found</h1>;
  }
  return (
    <>
      <TradeTab
        value={value}
        setValue={setValue}
        handleChange={handleTabChange}
        handleViewDocument={handleViewDocument}
        data={data}
      />
    </>
  );
}
export default KycRequestDetails;
