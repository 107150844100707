import React, { useState, useEffect } from "react";
import { Box, Paper, InputAdornment, styled } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});
const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});

// main function

const AccessControl = (props) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearch] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSearch = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };
  //{{url}}/api/admin/roles?pageNo=0&rowsPerPage=10&sortBy=id&sortOrder=asc&search=
  useEffect(() => {
    AxiosBase.get(
      `/admin/roles?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        `&sortBy=id&sortOrder=asc&search=${searchBy ? searchBy : ""}`
    ).then((response) => {
      setData(response.data.data);
      setCount(response.data.count);
      setLoading(false);
    });
  }, [page, rowsPerPage, count, searchBy]);

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Access Control</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                className="merchant_grid_sec"
              >
                <CssTextField
                  className={`"merchant_list_search_bar" ${classes.root}`}
                  id="new-menu"
                  placeholder="search"
                  variant="outlined"
                  fullWidth
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton className="merchant_search_icon">
                          <img src="/search.svg" alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </div>
            <div className="add_merchant_list">
              <h3 className="merchant_list_inner_title">User Role List</h3>
            </div>
          </div>
          <TableContainer
            className={
              data.length > 0 && !loading
                ? "table_container"
                : "table_container table_container_nodata"
            }
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>ID</TableCell>
                  <TableCell>Role Name</TableCell>
                  <TableCell>Created by</TableCell>
                  <TableCell>Created at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row branche_tbl_body">
                {data != " " &&
                  data.map((row, index) => (
                    <TableRow
                      className="order_list_data_body"
                      style={{ background: "none" }}
                    >
                      <TableCell>
                        <Link to="#" className="table_id_data">
                          {row._id}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link to="#" className="table_id_data">
                          {row.role_name}
                        </Link>
                      </TableCell>

                      <TableCell>{row.created_by}</TableCell>
                      <TableCell>{row.createdAt}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress />
              </div>
            ) : data == "" ? (
              <div className="no_data_found loader_data_tbl">
                <p>No Data Found</p>
              </div>
            ) : (
              ""
            )}
          </TableContainer>

          <TablePagination
            className="table_pagination"
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default AccessControl;
