import AxiosBase from "../axiosBase";

export const saveKycProfileImage = (image) => {
  return new Promise(async (resolve, reject) => {
    try {
      let form = new FormData();
      form.append("image", image);
      const url = "/app/image/upload";
      const saveImgResp = await AxiosBase({
        method: "post",
        url: url,
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      });
      resolve(saveImgResp);
    } catch (e) {
      console.log(e);
    }
  });
};

export const viewAllKyc = (pageNo, rowsPerPage, sortBy, sortOrder) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(
      `/admin/kyc/view-all-kyc?pageNo=${pageNo}&&rowsPerPage=${rowsPerPage}&&sortBy=${sortBy}&&sortOrder=${sortOrder}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });
};
export const GetAllKyc = (pageNo, rowsPerPage, searchBy, filter) => {
   try {
     return new Promise((resolve, reject) => {
       AxiosBase.get(
         `/admin/kyc/view-all-kyc?pageNo=${pageNo}&rowsPerPage=${rowsPerPage}&sortBy=updatedAt&search=${
           searchBy ? searchBy : ""
         }&filter=${filter}`
       )
         .then((res) => {
           resolve(res.data);
         })
         .catch((err) => {
           console.log("error", err);
           reject(err);
         });
     });
   } catch (error) {
     console.log(error);
     return;
   }
};

export const viewKycDetails = (kycId) => {
 
  return new Promise((resolve, reject) => {
   if(kycId){ AxiosBase.get(`/admin/kyc/view-kyc-details?kycId=${kycId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });}
  });

};

export const editBothKyc = (customerId) => {
  return new Promise((resolve, reject) => {
    const data = { customerId };
    AxiosBase.post(`/admin/kyc/view-all-kyc`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });
};

export const changeKycStatus = (kycId, isverified, email_id, customer_Id) => {
  return new Promise((resolve, reject) => {
    const data = { kycId, isverified, email_id, customer_Id };
    AxiosBase.post(`/admin/kyc/change-kyc-status`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });
};

export const viewKycHistory = (customerId) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(`/admin/kyc/view-kyc-history?customerId=${customerId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
        reject(err);
      });
  });
};
