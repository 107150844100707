import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TradeTab from "../TradeLicense/TradeTab";
import AxiosBase from "../../../networkRequest/axiosBase";

function KycRequestDetails() {
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get("customerId");
  const kycId = new URLSearchParams(search).get("kycId");
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  
  const [data, setData] = React.useState([]);
  const getData = () => {
    AxiosBase.get(`/admin/kyc/view-kyc-details/${kycId}`).then((response) => {
      setData(response.data);
    });
  };

  useEffect(() => {
    getData();
    setValue(0);
  }, [kycId]);

  useEffect(() => {
    getData();
  }, []);

  
  const handleViewDocument = () => {
    setValue(2);
  };

  if (!kycId || !customerId) {
    return <h1>404 - Not Found</h1>;
  }
  return (
    <>
      <TradeTab
        value={value}
        setValue={setValue}
        handleChange={handleTabChange}
        handleViewDocument={handleViewDocument}
        data={data}
      />
    </>
  );
}
export default KycRequestDetails;
