import AxiosBase from "../axiosBase";

export const cashBookDetails = (DateFilter,id) => {
  return new Promise((resolve, reject) => {
    AxiosBase.get(
      `/admin/business-account/${id}/cashbook`+DateFilter
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        console.log("error", err);
        resolve(err);
      });
  });
};