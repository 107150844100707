import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../networkRequest/axiosBase";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import SummarizeIcon from "@mui/icons-material/Summarize";
import moment from "moment";
import { toast } from "react-toastify";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import NavbarHoc from "../../pages/navhoc/index";
import DateRangeComp from "../datepicker";
import Config from "../../config.json";
import "./index.scss";
import { getMerchantList } from "../../networkRequest/request/merchant";
import { getAcquirerList } from "../../networkRequest/request/acquirer";

import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/styles";
import { VariableSizeList } from "react-window";
import Typography from "@mui/material/Typography";

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + 8,
    textTransform: "capitalize",
  };
  return (
    <>
      <Typography
        component="li"
        {...dataSet[0]}
        key={dataSet[1]._id}
        noWrap
        style={inlineStyle}
      >
        {/* {`${dataSet[0].first_name || ""} ${dataSet[1].last_name || ""} ${
        dataSet[1].merchant_name ? `(${dataSet[1].merchant_name})` : ""
      }`} */}
        {dataSet[0].key}
      </Typography>
    </>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * 8}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

let status = {
  merchant_details: "Merchant Details",
  order_details: "Order Details",
  premium_details: "Premium Details",
  kyc_details: "Kyc Details",
  failed_details: "Failed Details",
  blocked_users: "BLocked Users",
  settled_order: "Settled Order",
  unsettled_order: "Unsettled Order",
  sms_delivery_report: "SMS Delivery Report",
  reconciliation_acquirer_mdr: "Reconciliation Acquirer MDR",
};
const Reports = (props) => {
  const [data, setData] = useState([]);
  const [acquirers, setAcquirers] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [count, setCount] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [genLoading, setGenLoading] = useState(false);
  const [rolePermission, setRolePermission] = useState([]);
  const [type, setType] = useState("");
  const [selectedMerchant, setSelectedMerchantId] = useState({});
  const [selectedAcquirer, setSelectedAcquirer] = useState("");
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const handledates = (values) => {
    setDate(values);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, count]);

  useEffect(() => {
    getMerchantsData();
    getAcquirersData();
  }, []);

  const getMerchantsData = async () => {
    const merchantData = await getMerchantList();
    setMerchants(merchantData.data?.data || []);
  };

  const getAcquirersData = async () => {
    const acquirerData = await getAcquirerList();
    setAcquirers(acquirerData.data?.data || []);
  };

  const getData = () => {
    setLoading(true);
    setData([]);
    AxiosBase.get(
      `admin/reports?pageNo=` + page + `&rowsPerPage=` + rowsPerPage
      // + `&sortBy=completed&sortOrder=asc`
    )
      .then((response) => {
        setData(response.data.data);
        setCount(response.data.count);
        setLoading(false);
        setGenLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setGenLoading(false);
        toast("Something wen't wrong while getting report list!", {
          type: "error",
          autoClose: 2000,
        });
      });
  };
  const onGenerate = async () => {
    try {
      if (!type) {
        toast("Report type required", {
          type: "error",
          autoClose: 2000,
        });
        return false;
      }
      if (!date.startDate) {
        toast("From date required", {
          type: "error",
          autoClose: 2000,
        });
        return false;
      }
      if (!date.startDate) {
        toast("To date required", {
          type: "error",
          autoClose: 2000,
        });
        return false;
      }
      setGenLoading(true);
      await AxiosBase.post("admin/reports/generate", {
        type: type,
        from_date: moment(date.startDate).format("YYYY-MM-DD"),
        to_date: moment(date.endDate).format("YYYY-MM-DD"),
        merchant_id: selectedMerchant._id,
        acquirer_id: selectedAcquirer._id,
      });
      setType("");
      setDate({
        startDate: "",
        endDate: "",
      });
      setSelectedAcquirer({});
      setSelectedMerchantId({});
      toast("Report is Generating, please wait for few second", {
        type: "success",
        autoClose: 2000,
      });
      getData();
    } catch (error) {
      setLoading(false);
      setGenLoading(false);
      toast("Something wen't wrong while generating report!", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await AxiosBase.delete(`admin/reports/${id}`);
      toast("Report is deleted successfully", {
        type: "success",
        autoClose: 2000,
      });
      getData();
    } catch (error) {
      setLoading(false);
      toast("Something wen't wrong while deleting report!", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  return (
    <NavbarHoc
      id={Config.PermissionId.Reports}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <div style={{ width: "100%" }} className="reports-main">
        <Box>
          <h1 className="merchant_title">Reports</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                // sm={12}
                md={8}
                lg={6}
                // xl={4}
                className="merchant_grid_sec"
              >
                <div
                  className="merchant_list_filter_div report-filter-selection"
                  style={{ height: "100%" }}
                >
                  <FormControl>
                    <InputLabel id="report-type">Report Type</InputLabel>
                    <Select
                      labelId="report-type"
                      id="report-type-select"
                      label="Report Type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      {Object.keys(status).map((sta) => (
                        <MenuItem value={sta}>{status[sta]}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {["order_details", "reconciliation_acquirer_mdr"].includes(
                    type
                  ) && (
                    <Autocomplete
                      id="merchant-id-select"
                      sx={{ width: 470 }}
                      disableListWrap
                      PopperComponent={StyledPopper}
                      ListboxComponent={ListboxComponent}
                      options={merchants}
                      getOptionLabel={(option) =>
                        `${option.first_name || ""} ${option.last_name || ""} ${
                          option.merchant_name
                            ? `(${option.merchant_name})`
                            : ""
                        } (${option.mobile_no})`
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Merchant" />
                      )}
                      renderOption={(props, option, state) => [
                        props,
                        option,
                        state.index,
                      ]}
                      // value={selectedMerchant}
                      onChange={(e, v) => {
                        if (v) {
                          setSelectedMerchantId(v);
                        } else {
                          setSelectedMerchantId({});
                        }
                      }}
                    />
                  )}
                  {["order_details", "reconciliation_acquirer_mdr"].includes(
                    type
                  ) && (
                    <Autocomplete
                      id="acquirer-id-select"
                      sx={{ width: 300 }}
                      disableListWrap
                      PopperComponent={StyledPopper}
                      ListboxComponent={ListboxComponent}
                      options={acquirers}
                      getOptionLabel={(option) =>
                        `${option.label} - ${option.name}`
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Acquirer" />
                      )}
                      renderOption={(props, option, state) => [
                        props,
                        option,
                        state.index,
                      ]}
                      // value={selectedAcquirer}
                      onChange={(e, v) => {
                        if (v) {
                          setSelectedAcquirer(v);
                        } else {
                          setSelectedAcquirer({});
                        }
                      }}
                    />
                  )}
                  <DateRangeComp handledates={handledates}> </DateRangeComp>
                </div>
              </Grid>
              {rolePermission.includes("ADD") &&
                (genLoading ? (
                  <div className="generate-loading">
                    <CircularProgress />
                  </div>
                ) : (
                  <Button
                    className="merchant_list_add_new_merchant"
                    variant="contained"
                    startIcon={<SummarizeIcon />}
                    onClick={onGenerate}
                    disabled={genLoading}
                  >
                    generate
                  </Button>
                ))}
            </div>
          </div>
          <TableContainer
            className={
              data.length > 0 && !loading
                ? "table_container"
                : "table_container table_container_nodata"
            }
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Id</TableCell>
                  <TableCell>Reports Type</TableCell>
                  {/* <TableCell>Filter</TableCell> */}
                  <TableCell>From Date</TableCell>
                  <TableCell>To Date</TableCell>
                  <TableCell>Created On</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row ">
                {data != " " &&
                  data.map((row, index) => (
                    <TableRow
                      style={{ background: "none" }}
                      className="order_list_data_body"
                    >
                      <TableCell>{row._id}</TableCell>
                      <TableCell>{status[row.type]}</TableCell>
                      {/* <TableCell>{row.filter}</TableCell> */}
                      <TableCell>
                        {moment(row.from_date).format("YYYY/MM/DD")}
                      </TableCell>
                      <TableCell>
                        {moment(row.to_date).format("YYYY/MM/DD")}
                      </TableCell>
                      <TableCell>
                        {moment(row.createdAt).format("YYYY/MM/DD hh:mm A")}
                      </TableCell>
                      <TableCell>
                        <div className="reports-action">
                          {row.status == "COMPLETED" ? (
                            <a href={row.url} download>
                              <DownloadIcon htmlColor="#00C9A5" />
                            </a>
                          ) : (
                            <CircularProgress className="reports-action-loading" />
                          )}
                          <DeleteIcon
                            htmlColor="#FF5B55"
                            onClick={() => handleDelete(row._id)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress />
              </div>
            ) : data == "" ? (
              <div className="no_data_found loader_data_tbl">
                <p>No Data Found</p>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          {!loading ? (
            <TablePagination
              className="table_pagination"
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </NavbarHoc>
  );
};

export default Reports;
