import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Box, Divider } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import "./../../orders/order-list/Orderlist.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AxiosBase from "../../../networkRequest/axiosBase";
import moment from "moment";
import CopyAll from "@mui/icons-material/CopyAll";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { refundSubscription } from "../../../networkRequest/request/refund";
import { voidInit } from "../../../networkRequest/request/refund";
import CircularProgress from "@mui/material/CircularProgress";
import JsonView from "react18-json-view";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const PremiumOrderDetails = (props) => {
  const { rolePermission } = props;
  const [state, setState] = React.useState({
    right: false,
  });
  const [data, setdata] = React.useState([]);
  const [historyData, sethistoryData] = React.useState([]);
  const [loadmore, setmore] = React.useState(true);
  const [count, setcount] = React.useState(2);
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [openvoid, setVoidOpen] = React.useState(false);
  const [refund, setrefund] = React.useState(0);
  const [processing, setprocessing] = React.useState(false);
  const [errormsg, seterrormsg] = React.useState("");
  const [confirmrrefund, setconfirmrrefund] = React.useState(false);
  const [refunded, setRefunded] = React.useState(false);
  const [logsOpen, setLogsOpen] = React.useState(false);
  const [logs, setLogs] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
    seterrormsg("");
    setprocessing(false);
    setconfirmrrefund(false);
  };

  const handleClose = () => {
    setOpen(false);
    setVoidOpen(false);
  };

  const handleLogsClose = () => {
    setLogsOpen(false);
  };

  const confirmRefund = () => {
    setconfirmrrefund(true);
  };
  const handleRefund = () => {
    try {
      if (refunded) {
        seterrormsg("This Payment is already refunded");
        return;
      }
      setprocessing(true);
      refundSubscription(props.subscription_id).then((response) => {
        if (response?.status) {
          setRefunded(true);
        }
        setprocessing(false);
        seterrormsg(response.message);
      });
    } catch (err) {
      alert(err);
      setOpen(false);
      return (
        toast(err),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    }
  };
  useEffect(() => {}, []);

  const toggleDrawer = (anchor, open) => async (event) => {
    if (!props.order_id) {
      return (
        toast("Cannot return this order because this is upgraded by admin"),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    }
    // if (!props.plan?.planStatus) {
    //   return (
    //     toast("This plan is not active"),
    //     {
    //       type: "error",
    //       autoClose: 2000,
    //     }
    //   );
    // }
    setLoading(true);
    AxiosBase.get(`admin/order/subscription/${props.order_id}`).then(
      (response) => {
        setdata(response?.data);
        sethistoryData(response?.data?.historyData);
        if (response?.data?.refunded_amount) {
          setrefund(response?.data?.amount - response?.data?.refunded_amount);
        } else {
          setrefund(response?.data?.amount);
        }
        setLoading(false);
      }
    );

    try {
      const logs_resp = await AxiosBase.get(
        `admin/order/${props.order_id}/logs`
      );
      setLogs(logs_resp.data.data);
    } catch (error) {}

    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
        overflowX: "hidden",
        // backgroundColor: "#F3F4F6",
      }}
      role="presentation"
    >
      <List className="order_details_drawer_top_sec_list">
        <ListItem className="order_details_drawer_top_sec">
          <p>Order Details</p>
          <span>{data?.transactionId}</span>
        </ListItem>
      </List>
      {loading ? (
        <div
          style={{ marginTop: "500px" }}
          className="no_data_found loader_data_tbl"
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <List className="order_details_drawer_top_sec_list">
            <ListItem className="order_details_drawer_top_sec refund_sec">
              <p className="ade_points_p">
                {data?.amount}
                <span>{data?.currency}</span>
                {data?.refunded_amount > 0 && (
                  <>
                    <br />
                    <span className="info">
                      Refunded Amount{" "}
                      {data?.refunded_amount + " " + data?.currency}
                    </span>
                  </>
                )}
              </p>
              <div className="order-details-action-section">
                {(rolePermission.includes("EDIT") &&
                  data?.status == "Captured") ||
                data?.status == "Partially Refunded" ||
                data?.status == "Partially Captured" ||
                data?.status == "Refund Declined" ? (
                  <>
                    <div
                      className="order_details_drawer_refund_div"
                      onClick={handleClickOpen}
                    >
                      <p>
                        <img src="/refund-01.svg" />
                        Refund
                      </p>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div
                  className="order_details_drawer_refund_div logs-btn"
                  onClick={() => setLogsOpen(true)}
                >
                  <p>
                    <img src="/Details-01.svg" />
                    Logs
                  </p>
                </div>
              </div>
            </ListItem>
          </List>

          <div className="order_details_drawer_cards_body_div">
            <List className="order_details_drawer_cards_sec">
              <ListItem>
                <div className="order_details_drawer_cards_title_div">
                  <p>Payment details</p>
                </div>
              </ListItem>
              <ListItem>
                <div className="order_details_drawer_cards_body_inner">
                  <div className="card_data">
                    <p>Card Holder Name</p>
                    <span>
                      {data?.payment_details?.card_holder_name ?? "NA"}
                    </span>
                  </div>
                  <div className="card_data">
                    <p>
                      UL Payment ID
                      <Button
                        endIcon={<CopyAll />}
                        onClick={() => {
                          navigator.clipboard.writeText(data?.urbanledgerId);
                          return (
                            toast("UL Id copied successfully"),
                            {
                              type: "success",
                              autoClose: 2000,
                            }
                          );
                        }}
                      ></Button>
                    </p>
                    <span>{data?.urbanledgerId ?? "NA"}</span>
                  </div>
                  <div className="card_data">
                    <p>Card Number</p>
                    <span>{data?.endingWith ?? "NA"}</span>
                  </div>
                </div>
              </ListItem>
              <ListItem>
                <div className="order_details_drawer_cards_body_inner">
                  <div className="card_data">
                    <p>Issuing Bank</p>
                    <span>{data?.payment_details?.bankName ?? "NA"}</span>
                  </div>
                  <div className="card_data">
                    <p>Action Id</p>
                    <span>{data?.payment_details?.action_id ?? "NA"}</span>
                  </div>
                  <div className="card_data">
                    <p>Card Expiry Date</p>
                    <span>
                      {data?.payment_details?.expiry_year}/
                      {data?.payment_details?.expiry_month}
                    </span>
                  </div>
                </div>
              </ListItem>
              <ListItem>
                <div className="order_details_drawer_cards_body_inner">
                  <div className="card_data">
                    <p>Payment Id</p>
                    <span>{data?.transactionId ?? "NA"}</span>
                    <Button
                      endIcon={<CopyAll />}
                      onClick={() => {
                        navigator.clipboard.writeText(data?.transactionId);
                        return (
                          toast("Payment Id copied successfully"),
                          {
                            type: "success",
                            autoClose: 2000,
                          }
                        );
                      }}
                    ></Button>
                  </div>
                </div>
              </ListItem>
              <ListItem>
                <div className="order_details_drawer_cards_body_inner">
                  <div className="card_data order-id">
                    <p>Order Id</p>
                    <span>{data?.order_id ?? "NA"}</span>
                  </div>
                </div>
              </ListItem>
            </List>
          </div>

          <div className="order_details_drawer_cards_body_div">
            <List className="order_details_drawer_cards_sec">
              <ListItem>
                <div className="order_details_drawer_cards_title_div">
                  <p>Merchant details</p>
                </div>
              </ListItem>
              <ListItem>
                <div className="order_details_drawer_cards_body_inner">
                  <div className="card_data">
                    <p>Merchant Name</p>
                    <span>
                      {data?.toCustomerData?.first_name ?? "NA"}{" "}
                      {data?.toCustomerData?.last_name}
                    </span>
                  </div>
                  <div className="card_data">
                    <p>Email ID</p>
                    <span>{data?.toCustomerData?.email_id ?? "NA"}</span>
                  </div>
                  <div className="card_data">
                    <p>Phone Number</p>
                    <span>{data?.toCustomerData?.mobile_no ?? "NA"}</span>
                  </div>
                </div>
              </ListItem>
              <ListItem>
                <div className="order_details_drawer_cards_body_inner">
                  <div className="card_data">
                    <p>Branch</p>
                    <span>
                      {data?.toCustomerData?.branch?.branch_name ?? "NA"}
                    </span>
                  </div>
                  <div className="card_data">
                    <p>Terminal</p>
                    <span>{data?.terminalData?.terminal_name ?? "NA"}</span>
                  </div>
                </div>
              </ListItem>
            </List>
          </div>
          {/* popup / dilogbox/ */}
          <div>
            <BootstrapDialog
              className="order_detail_refund_popup"
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
              ></BootstrapDialogTitle>
              <DialogContent>
                <Typography
                  className="order_detail_refund_popup_title"
                  gutterBottom
                >
                  {!confirmrrefund ? (
                    <>
                      <p>Refund Information</p>
                      <span>Refund amount</span>
                      <div className="order_detail_refund_input_div">
                        <TextField
                          // className={`"merchant_list_search_bar" ${classes.root}`}
                          id="outlined-start-adornment"
                          fullWidth
                          value={refund}
                          disabled={true}
                          onChange={(e) => {
                            if (e.target.value <= data.amount) {
                              setrefund(e.target.value);
                            }
                          }}
                        />
                      </div>
                      <div className="order_detail_refund_popup_btn">
                        <Button
                          className="refund_save_btn"
                          variant="contained"
                          autoFocus
                          fullWidth
                          onClick={confirmRefund}
                        >
                          Refund Payment
                        </Button>
                        <Button
                          className="refund_cancel_btn"
                          variant="contained"
                          autoFocus
                          onClick={handleClose}
                          fullWidth
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <p>Confirm Refund</p>
                      <span>
                        Refunding a payment is permenant. Are you sure you want
                        to refund this payment?
                      </span>
                      <div className="order_detail_refund_popup_btn">
                        <Button
                          className="refund_save_btn"
                          variant="contained"
                          autoFocus
                          fullWidth
                          disabled={processing || errormsg != ""}
                          onClick={handleRefund}
                        >
                          Confirm
                        </Button>
                        <Button
                          className="refund_cancel_btn"
                          variant="contained"
                          autoFocus
                          onClick={handleClose}
                          fullWidth
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  )}
                  {processing && (
                    <div className="no_data_found loader_data_tbl">
                      <CircularProgress />
                    </div>
                  )}

                  {errormsg != "" && (
                    <div style={{ color: "red" }}>{errormsg}</div>
                  )}
                </Typography>
              </DialogContent>
            </BootstrapDialog>
          </div>
        </>
      )}
    </Box>
  );
  return (
    <>
      <div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Link
              to="#"
              className={props.className}
              onClick={
                (rolePermission.includes("EDIT") ||
                  rolePermission.includes("VIEW")) &&
                toggleDrawer(anchor, true)
              }
            >
              {props.display_order}
            </Link>
            <SwipeableDrawer
              className="order_drawer_main"
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </div>
      <div>
        <BootstrapDialog
          className="order-logs-modal"
          onClose={handleLogsClose}
          aria-labelledby="logs-dialog"
          open={logsOpen}
          fullWidth={true}
          maxWidth={"lg"}
        >
          <BootstrapDialogTitle id="logs-dialog">
            Transaction Logs
          </BootstrapDialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleLogsClose}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            {logs.map((log) => (
              <>
                <div className="order-logs-card">
                  <h4>
                    {log.action}
                    {" - "}
                    {moment(log?.createdAt).format("DD MMM YYYY hh:mm:ss A")}
                  </h4>
                  <JsonView
                    src={log}
                    theme="atom"
                    collapsed={3}
                    editable={false}
                    enableClipboard={false}
                  />
                </div>
                <Divider />
              </>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLogsClose}>Close</Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default PremiumOrderDetails;
