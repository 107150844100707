import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Paper } from "@mui/material";
import UserInfo from "./user-information/UserInfo";
import UserRole from "./user-role/UserRole";
import AxiosBase from "../../networkRequest/axiosBase";
import { useParams } from "react-router-dom";
import {
  getUsersById,
  AddUser,
  UpdateUser,
  getTemporaryPassword,
} from "../../networkRequest/request/useraccesscontrol";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import NavbarHoc from "../../pages/navhoc/index";
import Config from "../../config.json";
import { connect } from "react-redux";
import Custodian from "./Custodian";

function UserTabs(props) {
  const [value, setValue] = React.useState(0);
  const [userId, setId] = React.useState("");
  const [data, setData] = React.useState({
    first_name: "",
    mobile_no: "",
    last_name: "",
    email_id: "",
    start_at: null,
    expiryDate: null,
    roleId: [],
    is_active: false,
  });
  const [rolePermission, setRolePermission] = React.useState([]);
  const [savedata, setsavedata] = React.useState(false);
  const history = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const id = useParams();

  useEffect(() => {
    if (id.id) {
      setId(id.id);
      getUsersById(id.id).then((userlist) => {
        userlist.data.mobile_no = `+${userlist.data.mobile_no}`;
        setData(userlist.data);
      });
    }
  }, []);

  const submitCode = async () => {
    try {
      var validation = checkValidation(data);
      // if (savedata) {
      if (validation != "") {
        setsavedata(false);
        return toast(validation, {
          type: "error",
          autoClose: 2000,
        });
      } else {
        if (userId) {
          if (data.pass) {
            data.password = data.pass;
          }
          data.id = userId;
          data.mobile_no = data.mobile_no.replace(/\+/g, "");
          const res = await UpdateUser(data);
          setsavedata(false);
          if (res.status === 200) {
            history(`/users`);
            return toast("User Updated successfully", {
              type: "success",
              autoClose: 2000,
            });
          } else {
            return toast(res?.data?.message || "Something went wrong!", {
              type: "error",
              autoClose: 2000,
            });
          }
        } else {
          data.mobile_no = data.mobile_no.replace(/\+/g, "");
          const res = await AddUser(data);
          setsavedata(false);
          if (res.status === 200) {
            history(`/users`);
            return toast("User Added successfully", {
              type: "success",
              autoClose: 2000,
            });
          } else {
            return toast(res?.data?.message || "Something went wrong!", {
              type: "error",
              autoClose: 2000,
            });
          }
        }
      }
      // } else {
      //   if (userId) {
      //     return toast("Please Update To Proceed", {
      //       type: "warning",
      //       autoClose: 2000,
      //     });
      //   } else {
      //     if (validation != "") {
      //       setsavedata(false);
      //       return toast(validation, {
      //         type: "error",
      //         autoClose: 2000,
      //       });
      //     }
      //   }
      // }
    } catch (error) {
      return toast("Something went wrong!", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  const userdatainfo = (data1) => {
    setData(data1);
    setsavedata(true);
  };

  const roleinfo = (data1) => {
    setData({
      ...data,
      roleId: data1,
    });
    setsavedata(true);
  };

  const checkValidation = (data) => {
    let startDateCheck = !userId
      ? !moment(data.start_at).isSameOrAfter(moment(), "day")
      : false;
    if (data.first_name.length < 1 || data.first_name.length > 50) {
      return "Please Enter First Name between 1 to 50 characters";
    } else if (data.last_name.length < 1 || data.last_name.length > 50) {
      return "Please Enter Last Name between 1 to 50 characters";
    } else if (data.email_id.length < 1 || data.email_id.length > 40) {
      return "Please enter a valid email id between 1 to 40 characters.";
    } else if (
      data.start_at == "Invalid Date" ||
      data.expiryDate == "Invalid Date"
    ) {
      return data.start_at == "Invalid Date"
        ? "Start Date Is Invalid Date"
        : "Expiry Date Is Invalid Date";
    } else if (data.start_at !== null && startDateCheck) {
      return "Start Date Is Not Valid";
    } else if (
      (data.expiryDate !== null && data.expiryDate,
      moment(data.expiryDate).isSameOrBefore(data.start_at))
    ) {
      return "Expiry Date Is Not Valid";
    } else if (data.roleId.length == 0) {
      return "Please select atleast one role";
    } else {
      return "";
    }
  };
  const getPassword = async (user) => {
    const res = await getTemporaryPassword();
    setData({ ...user, pass: res?.data?.tempPassword });
    setsavedata(true);
  };

  return (
    <NavbarHoc
      id={Config.PermissionId.Users}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <Box>
        <h1 className="merchant_title">Users</h1>
      </Box>
      <div className="merchant_list_main_div merchant_detail_tab_sec padding15">
        <div className="user_tab">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="User Information" />
            <Tab label="User Role" />
            {props.NavBarData?.[Config.PermissionId.Custodian]?.includes(
              "VIEW"
            ) && <Tab label="Custodian" />}
          </Tabs>
        </div>
        <div>
          {value === 0 && (
            <UserInfo
              getPassword={getPassword}
              userdatainfo={userdatainfo}
              data={data}
              userId={userId}
              submitCode={submitCode}
              rolePermission={rolePermission}
            />
          )}
          {value === 1 && (
            <UserRole
              roleinfo={roleinfo}
              userId={userId}
              data={data}
              submitCode={submitCode}
              rolePermission={rolePermission}
            />
          )}
          {value === 2 && (
            <Custodian
              rolePermission={props.NavBarData?.[Config.PermissionId.Custodian]}
              userId={userId}
            />
          )}
        </div>
      </div>
    </NavbarHoc>
  );
}

const mapStateToProps = (state) => {
  return {
    NavBarData: state.auth.NavbarAccess,
  };
};

export default connect(mapStateToProps, null)(UserTabs);
