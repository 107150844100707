import React from "react";
// import Header from '../universal-components/header';
// import Footer from '../universal-components/footer';
import Navbar from "../universal-components/navbar";
import CollapseNavBar from "../universal-components/navbar/CollapseNavBar";
import Container from "@mui/material/Container";

// This will be our main layout page

function MainLayout(props) {
  const { children } = props;
  return (
    <div style={{ width: "calc(100% - 10px)", display: "flex" }}>
      <div>
        <CollapseNavBar {...props} />
      </div>
      <div className="main_children">
        <Container
          maxWidth="ls"
          // className="containerwidthextra"
        >
          {children}
        </Container>
      </div>
      {/* <Footer></Footer> */}
    </div>
  );
}

export default MainLayout;
