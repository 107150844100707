import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useLocation } from 'react-router-dom'
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "./Filter.css";
import IconButton from "@mui/material/IconButton";
import ListSubheader from "@mui/material/ListSubheader";
import TextField from "@mui/material/TextField";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { toast } from "react-toastify";

let input;

export default function Filter(props) {
  const [age, setAge] = React.useState("");
  const [age1, setAge1] = React.useState("");
  const [counter, setCounter] = React.useState(props?.filter?.length);
  const [filterOption, setFilterOption] = React.useState(props?.optionns);
  const [filterContains, setFilterContains] = React.useState(props?.contains);
  const [formValues, setFormValues] = React.useState(props?.filter);
  const [formDefaValues, setDefaultFormValues] = React.useState(JSON.parse(JSON.stringify(props?.filter)));
  const [btnfilter, setbtnfilter] = React.useState("add");
  const search = useLocation().search;

  let handleChange2 = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  // React.useEffect(()=>{
  //   !formValues && setFormValues([
  //     { key: "", type: "", value: "" },
  //   ])
  // },[formValues])

  let cancelFilter = () => {
    props.filter1(formDefaValues);
    props.pophandleClose();
  }
  let passFilter = () => {
    var isvalid = true;
    formValues?.map((elm) => {
      if ((elm.key == "" || elm.type == "" || elm.value == "")) {
        isvalid = false;
      }
    })
    if (!isvalid) {
      return (
        toast("All filter values are mandatory.", {
          type: "error",
          autoClose: 2000,
        }))
    }
    else {
      props.filter1(formValues);
      props.pophandleClose();
      // return (
      //   toast("Filter applied successfully.", {
      //     type: "success",
      //     autoClose: 2000,
      //   }))
    }
  }

  let addFormFields = () => {
    if (counter < 4) {
      setCounter(counter + 1);
      setFormValues([...formValues, { key: "", type: "", value: "" }]);
      setbtnfilter("delete");
    }

    // if(counter < 3) {}
  };

  let removeFormFields = (i) => {
    setCounter(counter - 1);
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    // alert(JSON.stringify(formValues));
  };
  return (
    <>
      <div>
        <form onSubmit={handleSubmit}>
          <Box sx={{ minWidth: 700 }}>
            {formValues?.map((element, index) => (
              <div className="filter_group" key={index}>
                <FormControl sx={{ minWidth: 260 }}>
                  <InputLabel htmlFor="grouped-native-select">
                    Select Property*
                  </InputLabel>
                  <Select
                    name="key"
                    native
                    defaultValue=""
                    id="grouped-native-select"
                    label="Select Property*"
                    value={element?.key}
                    onChange={(e) => handleChange2(index, e)}
                  >
                    <option aria-label="None" value="" />
                    {filterOption.map((elm) => {
                      {
                        return (
                          <option value={elm.value}>{elm.property}</option>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 260 }}>
                  <InputLabel htmlFor="grouped-native-select">
                    Contains*
                  </InputLabel>
                  <Select
                    name="type"
                    native
                    defaultValue=""
                    id="grouped-native-select"
                    label="Contains*"
                    value={element?.type}
                    onChange={(e) => handleChange2(index, e)}
                  >
                    {(element?.key == 'status' && props?.AnotherOptions) ?
                     <>
                     <option aria-label="None" value="" />
                     <option value='contain'>Like</option>
                     </>
                     :
                     <>
                    <option aria-label="None" value="" />
                    {filterContains.map((elm) => {
                      return <option value={elm.value}>{elm.property}</option>;
                    })}
                    </>
                  }
                  </Select>
                </FormControl>              
                   <FormControl
                    className="filter_textField_sec"
                    sx={{ minWidth: 260 }}
                  >
                {(element?.key == 'status' && props?.AnotherOptions) ?
                <>
                <InputLabel htmlFor="grouped-native-select">
                Keyword*
               </InputLabel>    
                  <Select
                  name="value"
                  native
                  defaultValue=""
                  id="grouped-native-select"
                  label="Keywords*"
                  value={element?.value}
                  onChange={(e) => handleChange2(index, e)}
                >
                 <option aria-label="None" value="" />
                  {props.AnotherOptions.map(fact=>{
                   return <option value={fact.key}>{fact.value}</option>
                  })}
                </Select></>
                
                :
                    <TextField
                      name="value"
                      id="outlined-basic"
                      label="Keyword*"
                      variant="outlined"
                      className="filter_textFeild"
                      value={element?.value}
                      onChange={(e) => handleChange2(index, e)}
                    />
                    }
                  </FormControl>

                {index <= 3 && (
                  index == 0 ? <Button
                    className="filter_add_button"
                    variant="contained"
                    onClick={() => addFormFields()}
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                  >
                    Add Filter
                  </Button> :
                    <DeleteForeverOutlinedIcon onClick={() => { removeFormFields(index) }} style={{ color: 'red', fontSize: '34px', marginLeft: '2%', cursor: 'pointer' }} />
                )}
              </div>
            ))}
          </Box>
        </form>
      </div>
      <div className="apply-filter-btn-dev">
        <Button
          className="filter-popup-applay-btn"
          variant="contained"
          onClick={passFilter}
        >
          Apply Filter
        </Button>
        <Button
          className="filter-popup-cancel-btn"
          variant="contained"
          onClick={cancelFilter}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
