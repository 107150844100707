import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { viewKycDetails } from "../../../../../../../../networkRequest/request/kyc";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SaveIcon from "@mui/icons-material/Save";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";
import "./index.css";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "0.5px",
    fontSize: "14px",
    fontFamily: "SF Pro Display",
  },
  "input#comment-text": {
    minHeight: "1rem",
  },
});

const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

// EmiratesId
function TradeLicense(props) {
  const { rolePermission } = props;
  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  let date = new Date();
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const search = useLocation().search;

  const [data, setData] = useState();
  const [customerId, setCutomerid] = useState();
  // const [tradelicense, settradeLicenseData] = React.useState([]);

  // useEffect(() => {
  //    settradeLicenseData(props.data)
  // }, [props.data])

  // const [tradeLicense, setTradeLicense] = React.useState({
  //     companyName: '',
  //     mainLiceNr: '',
  //     capitalDetails: '',
  //     registerNo: '',
  //     licenseAddress: '',
  //     regDate: '',
  //     commerceAddress: '',
  //     commRegDate: '',
  //     RegisterActivities: '',
  //     legalType: '',
  //     comment: ''
  // })

  const commercialHandleChange = (e) => {
    const { name, value } = e.target;
  };

  const submitCommercialRegData = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <div className="tradeLicense-mai-div">
        {!props.data.dtlImgUrl ? (
          <Tooltip title="Please Upload Trade License">
            <div className="tradeLicense-header-div">
              <p
                style={{ color: "#666666", fontWeight: "normal", fontSize: 20 }}
              >
                Trade License
              </p>
              <p style={{ color: "#a7a7a7", fontWeight: 500 }}>
                Commercial Register
              </p>
            </div>
          </Tooltip>
        ) : (
          <div className="tradeLicense-header-div">
            <p style={{ color: "#666666", fontWeight: "normal", fontSize: 20 }}>
              Trade License
            </p>
            <p style={{ color: "#a7a7a7", fontWeight: 500 }}>
              Commercial Register
            </p>
          </div>
        )}

        <div className="tradeLicense-body-sec">
          <div className="tradeLicense-data-sec-div">
            <p>
              License Number<span>*</span>:
            </p>
            <CssTextField
              required
              id="new-menu"
              variant="outlined"
              sx={{ mx: "1.5rem", width: "67%" }}
              helperText={
                props?.data?.tradeLicenseData?.licenseNumber == ""
                  ? "Please enter valid license number"
                  : ""
              }
              value={props?.data?.tradeLicenseData?.licenseNumber}
              name="licenseNumber"
              onChange={(e) => props.handleTradeLicenseChange(e)}
              placeholder="Enter License Number"
              disabled={
                !props.data.dtlImgUrl ||
                props.disablecontent ||
                props.data.tradeLicenseVerified == "Approved"
                  ? // ||
                    // props.data.tradeLicenseVerified == "Rejected" ||
                    // props.data.emiratesIdVerified == "Rejected"
                    props.data.kycStatus != "Expired"
                    ? true
                    : false
                  : false
              }
            />
          </div>

          {/* <div style={{ display: 'flex', alignItems: 'center', width: '280px' }}>
                        <p style={{ fontSize: '11px', width: '75px', paddingLeft: '0px', fontWeight: 700 }}>First Name:</p>
                        <CssTextField id="new-menu" variant="outlined" sx={{ mx: '1.5rem' }} value={props?.data?.tradeLicenseData?.firstName} name="firstName" onChange={(e)=>props.handleTradeLicenseChange(e)} placeholder='enter details' disabled={(!props.data.dtlImgUrl || props.disablecontent || props.data.tradeLicenseVerified == "Approved" ||  props.data.tradeLicenseVerified == "Rejected") ? true : false}/>
                    </div> */}
        </div>

        <div className="tradeLicense-body-sec">
          <div className="tradeLicense-data-sec-div">
            <p>
              Trade name<span>*</span>:
            </p>
            <CssTextField
              required
              id="new-menu"
              variant="outlined"
              sx={{ mx: "1.5rem", width: "67%" }}
              helperText={
                props?.data?.tradeLicenseData?.tradeName == ""
                  ? "Please enter trade name"
                  : ""
              }
              value={props?.data?.tradeLicenseData?.tradeName}
              name="tradeName"
              onChange={(e) => props.handleTradeLicenseChange(e)}
              placeholder="Enter Trade Name"
              disabled={
                !props.data.dtlImgUrl ||
                props.disablecontent ||
                props.data.tradeLicenseVerified == "Approved"
                  ? // ||
                    //   props.data.tradeLicenseVerified == "Rejected" ||
                    //   props.data.emiratesIdVerified == "Rejected"
                    props.data.kycStatus != "Expired"
                    ? true
                    : false
                  : false
              }
            />
          </div>

          {/* <div style={{ display: 'flex', alignItems: 'center', width: '280px' }}>
                        <p style={{ fontSize: '11px', width: '75px', paddingLeft: '0px', fontWeight: 700 }}>Issue Date:</p>
                        <CssTextField id="new-menu" variant="outlined" sx={{ mx: '1.5rem' }} value={props?.data?.tradeLicenseData?.issueDate} name="issueDate" onChange={(e)=>props.handleTradeLicenseChange(e)} placeholder='enter details' disabled={(!props.data.dtlImgUrl || props.disablecontent || props.data.tradeLicenseVerified == "Approved" ||  props.data.tradeLicenseVerified == "Rejected") ? true : false}/>
                    </div> */}
        </div>

        <div className="tradeLicense-body-sec">
          <div className="tradeLicense-data-sec-div" style={{ width: "50%" }}>
            <p style={{ width: "30%" }}>
              Issue Date<span>*</span>:
            </p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className="mui-date-picker"
                // maxDate={new Date()}
                clearable
                disabled={
                  !props.data.dtlImgUrl ||
                  props.disablecontent ||
                  props.data.tradeLicenseVerified == "Approved"
                    ? // ||
                      //   props.data.tradeLicenseVerified == "Rejected" ||
                      //   props.data.emiratesIdVerified == "Rejected"
                      props.data.kycStatus != "Expired"
                      ? true
                      : false
                    : false
                }
                inputProps={{ readOnly: true }}
                inputFormat="MM/dd/yyyy"
                value={
                  props?.data?.tradeLicenseData?.issueDate
                    ? props?.data?.tradeLicenseData?.issueDate
                    : null
                }
                onChange={(e) =>
                  props.handleTradeLicenseDateChange(e, "issueDate")
                }
                renderInput={(params) => (
                  <CssTextField
                    id="new-menu"
                    variant="outlined"
                    sx={{
                      mx: "1.5rem",
                      width: "44%",
                    }}
                    placeholder="Issue Date"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </div>

          <div className="tradeLicense-data-sec-div" style={{ width: "50%" }}>
            <p style={{ width: "65px" }}>
              End Date<span>*</span>:
            </p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className="mui-date-picker"
                clearable
                minDate={
                  props?.data?.tradeLicenseData?.issueDate
                    ? props?.data?.tradeLicenseData?.issueDate
                    : date.addDays(1)
                }
                inputProps={{ readOnly: true }}
                inputFormat="MM/dd/yyyy"
                value={
                  props?.data?.tradeLicenseData?.endDate
                    ? props?.data?.tradeLicenseData?.endDate
                    : null
                }
                disabled={
                  !props.data.dtlImgUrl ||
                  props.disablecontent ||
                  props.data.tradeLicenseVerified == "Approved"
                    ? // ||
                      //   props.data.tradeLicenseVerified == "Rejected" ||
                      //   props.data.emiratesIdVerified == "Rejected"
                      props.data.kycStatus != "Expired"
                      ? true
                      : false
                    : false
                }
                onChange={(e) =>
                  props.handleTradeLicenseDateChange(e, "endDate")
                }
                renderInput={(params) => (
                  <CssTextField
                    id="new-menu"
                    variant="outlined"
                    sx={{
                      mx: "0.8rem",
                      width: "44%",
                    }}
                    placeholder="End Date"
                    disabled={
                      !props.data.dtlImgUrl ||
                      props.disablecontent ||
                      props.data.tradeLicenseVerified == "Approved"
                        ? // ||
                          //   props.data.tradeLicenseVerified == "Rejected" ||
                          //   props.data.emiratesIdVerified == "Rejected"
                          props.data.kycStatus != "Expired"
                          ? true
                          : false
                        : false
                    }
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>

        <div className="tradeLicense-body-sec">
          <div className="tradeLicense-data-sec-div">
            <p>
              Comments<span>*</span>:
            </p>
            <CssTextArea
              id="comment-text"
              variant="outlined"
              sx={{
                width: "67%",
                mt: "1rem",
                borderRadius: "5px",
                borderColor: "#a7a7a7",
                margin: "0 1.5rem",
                fontSize: "14px",
              }}
              value={props?.data?.tradeLicenseData?.comments}
              name="comments"
              onChange={(e) => props.handleTradeLicenseChange(e)}
              disabled={
                !props.data.dtlImgUrl ||
                props.disablecontent ||
                props.data.tradeLicenseVerified == "Approved"
                  ? // ||
                    //   props.data.tradeLicenseVerified == "Rejected" ||
                    //   props.data.emiratesIdVerified == "Rejected"
                    props.data.kycStatus != "Expired"
                    ? true
                    : false
                  : false
              }
              minRows={3}
            />
          </div>
          <div></div>
        </div>
        {props?.hidebuttons == "true" ? (
          <></>
        ) : (
          <div style={{ marginTop: "10px" }}></div>
        )}

        {props?.hidebuttons == "true" ? (
          <></>
        ) : (
          <div style={{ borderTop: "1px solid #dadada" }}>
            <div
              style={{
                display: "flex",
                width: "90%",
                height: "50px",
                alignItems: "center",
                marginLeft: "15px",
                marginTop: "0px",
                paddingTop: "5px",
                margin: "0 auto",
                justifyContent: "flex-end",
              }}
            >
              {/* <Button  onClick={() => props.handleViewDocument()} style={{ backgroundColor: '#666666', borderRadius: '1rem', color: '#fff', fontSize: '11px', fontWeight: 500, paddingTop: '4px', paddingBottom: '4px' }}  >
                                View Document
                            </Button> */}

              <div style={{ display: "flex" }}>
                <Button
                  className="kyc-action-btn"
                  onClick={() => props.handleTradeLicenseReject()}
                  disabled={
                    !props.data.dtlImgUrl ||
                    props.disablecontent ||
                    props.data.tradeLicenseVerified == "Approved"
                      ? // ||
                        //   props.data.tradeLicenseVerified == "Rejected" ||
                        //   props.data.emiratesIdVerified == "Rejected"
                        props.data.kycStatus != "Expired"
                        ? true
                        : false
                      : false
                  }
                  style={{
                    backgroundColor:
                      props.data.tradeLicenseVerified == "Rejected"
                        ? "#f00"
                        : !props.data.dtlImgUrl ||
                          props.disablecontent ||
                          props.data.tradeLicenseVerified == "Approved" ||
                          props.data.emiratesIdVerified == "Rejected"
                        ? "#a3a1a1"
                        : "#f00",
                  }}
                  endIcon={<CancelIcon style={{ marginLeft: "0rem" }} />}
                >
                  {props.data.tradeLicenseVerified == "Rejected"
                    ? "Rejected"
                    : "Reject"}
                </Button>
              </div>

              <div style={{ display: "flex" }}>
                <Button
                  className="kyc-action-btn"
                  onClick={() => props.handleTradeLicenseApproved()}
                  disabled={
                    !props.data.dtlImgUrl ||
                    props.disablecontent ||
                    props.data.tradeLicenseVerified == "Approved"
                      ? // ||
                        //   props.data.tradeLicenseVerified == "Rejected" ||
                        //   props.data.emiratesIdVerified == "Rejected"
                        props.data.kycStatus != "Expired"
                        ? true
                        : false
                      : false
                  }
                  style={{
                    backgroundColor:
                      props.data.tradeLicenseVerified == "Rejected" ||
                      props.data.emiratesIdVerified == "Rejected"
                        ? "#a3a1a1"
                        : "#2ed06d",
                  }}
                  endIcon={<CheckCircleIcon />}
                >
                  {props.data.tradeLicenseVerified == "Approved"
                    ? "Approved"
                    : "Approve"}
                </Button>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  className="kyc-action-btn"
                  onClick={() => props.handleTradeLicenseSaveApproved()}
                  disabled={
                    !props.data.dtlImgUrl ||
                    props.disablecontent ||
                    props.data.tradeLicenseVerified == "Approved"
                      ? // ||
                        //   props.data.tradeLicenseVerified == "Rejected" ||
                        //   props.data.emiratesIdVerified == "Rejected"
                        props.data.kycStatus != "Expired"
                        ? true
                        : false
                      : false
                  }
                  style={{
                    backgroundColor:
                      !props.data.dtlImgUrl ||
                      props.disablecontent ||
                      props.data.tradeLicenseVerified == "Approved"
                        ? // ||
                          //   props.data.tradeLicenseVerified == "Rejected" ||
                          //   props.data.emiratesIdVerified == "Rejected"
                          "#a3a1a1"
                        : "#1058ff",
                  }}
                  endIcon={<SaveIcon />}
                >
                  Save & Approve
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      {props?.hidebuttons == "true" ? (
        <></>
      ) : (
        <div className="kyc-note">
          <p className="kyc-note-iconText">
            <ErrorIcon
              sx={{
                height: "16px",
                width: "16px",
                color: "#ff5722",
                mr: "0px",
              }}
            />
            <p className="error_text">
              No changes would be allowed after accepting or rejecting the KYC
            </p>
          </p>
        </div>
      )}
    </div>
  );
}

export default TradeLicense;
