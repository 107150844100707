import axios from "axios";
import { logoutUser, updateToken } from "../redux/actions/auth";
import reduxStore from "../redux/stores";
import CryptoJS from "crypto-js";
import baseURL from "../utils/base_url";

let REACT_APP_PANEL_ENC_SECRET_KEY = "Th!$_0ur_$3cr3t";

const encryptyData = (data, secret_key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secret_key).toString();
};
const decryptData = (data, secret_key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, secret_key).toString(CryptoJS.enc.Utf8)
  );
};

const AxiosBase = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
});

AxiosBase.interceptors.request.use(
  (req) => {
    req.baseURL = `${baseURL()}/api/v1`;
    if (!req?.headers?.rerequest) {
      const root = JSON.parse(localStorage.getItem("persist:root"));
      const authData = root?.auth ? JSON.parse(root?.auth) : "";
      if (authData?.accessToken) {
        req.headers = {
          Authorization: `Bearer ${authData?.accessToken}`,
        };
      }

      const skip_url = [
        { regex: /\/admin\/login/, method: "POST" },
        { regex: /\/admin\/refresh-login/, method: "POST" },
        { regex: /\/admin\/refresh-login-check/, method: "POST" },
        { regex: /\/admin\/logout/, method: "POST" },
        { regex: /\/admin\/user\/resetpasswordfromtemprory/, method: "POST" },
        { regex: /\/admin\/country-master/, method: "GET" },
      ];
      let secret_key = REACT_APP_PANEL_ENC_SECRET_KEY;
      const isMatch = skip_url.some(
        (exc) =>
          exc.regex.test(req.url) &&
          req.method.toLowerCase() == exc.method.toLowerCase()
      );

      if (!isMatch && authData?.accessToken) {
        secret_key = authData?.accessToken;
      }
      if (req.data instanceof FormData) {
        req.data = formDataConvert(req.data, secret_key);
      } else {
        req.data = { data: encryptyData(req.data, secret_key) };
      }
    }

    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let errorUrl; // using this to avoid any loop
// response call intercept
AxiosBase.interceptors.response.use(
  (res) => {
    if (res.config.url === errorUrl) {
      errorUrl = "";
    }
    try {
      const root = JSON.parse(localStorage.getItem("persist:root"));
      const authData = root?.auth ? JSON.parse(root?.auth) : "";
      const skip_url = [
        { regex: /\/admin\/login/, method: "POST" },
        { regex: /\/admin\/refresh-login/, method: "POST" },
        { regex: /\/admin\/refresh-login-check/, method: "POST" },
        { regex: /\/admin\/logout/, method: "POST" },
        { regex: /\/admin\/user\/resetpasswordfromtemprory/, method: "POST" },
        { regex: /\/admin\/country-master/, method: "GET" },
      ];
      let secret_key = REACT_APP_PANEL_ENC_SECRET_KEY;
      const isMatch = skip_url.some(
        (exc) =>
          exc.regex.test(res.config.url) &&
          res.config.method.toLowerCase() == exc.method.toLowerCase()
      );
      if (!isMatch && authData?.accessToken) {
        secret_key = authData?.accessToken;
      }
      let res_body = decryptData(res.data, secret_key);
      try {
        res.data = JSON.parse(res_body.data);
      } catch (err) {
        res.data = res_body.data;
      }
    } catch (err) {
      console.log("res dec error====>", err);
    }
    if (res.config.url === `/admin/login`) {
      AxiosBase.defaults.headers = {
        Authorization: `Bearer ${res.data.accessToken}`,
      };
    }
    return res;
  },
  async (error) => {
    try {
      const root = JSON.parse(localStorage.getItem("persist:root"));
      const authData = root?.auth ? JSON.parse(root?.auth) : "";
      const skip_url = [
        { regex: /\/admin\/login/, method: "POST" },
        { regex: /\/admin\/refresh-login/, method: "POST" },
        { regex: /\/admin\/refresh-login-check/, method: "POST" },
        { regex: /\/admin\/logout/, method: "POST" },
        { regex: /\/admin\/user\/resetpasswordfromtemprory/, method: "POST" },
        { regex: /\/admin\/country-master/, method: "GET" },
      ];
      let secret_key = REACT_APP_PANEL_ENC_SECRET_KEY;
      const isMatch = skip_url.some(
        (exc) =>
          exc.regex.test(error?.response?.config?.url) &&
          error?.response?.config.method.toLowerCase() ==
            exc.method.toLowerCase()
      );
      if (!isMatch && authData?.accessToken) {
        secret_key = authData?.accessToken;
      }
      let res_body = decryptData(error?.response?.data, secret_key);
      try {
        error.response.data = JSON.parse(res_body.data);
      } catch (err) {
        error.response.data = res_body.data;
      }
    } catch (errs) {
      console.log("res err dec error====>", errs);
    }
    if (
      error?.response?.status === 444 &&
      error.response.data.message === "Second login active"
    ) {
      const { store } = reduxStore();
      let redirect = "/warning";
      store.dispatch(logoutUser(redirect, "Timeout"));
    } else if (
      error?.response?.status === 401 &&
      error?.response.config.url !== errorUrl &&
      error?.response.config.url !== `/admin/refresh-login` &&
      error?.response.config.url !== `/admin/login` &&
      error?.response.config.url !== `/admin/refresh-login-check`
    ) {
      errorUrl = error?.response.config.url;
      const { store } = reduxStore();
      try {
        const root = JSON.parse(localStorage.getItem("persist:root"));
        const authData = root?.auth ? JSON.parse(root?.auth) : "";
        if (authData?.refreshToken) {
          const res = await AxiosBase.post(`/admin/refresh-login`, {
            token: authData?.refreshToken,
          });
          store.dispatch(
            updateToken({
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken,
            })
          );
          AxiosBase.defaults.headers = {
            Authorization: `Bearer ${res.data.accessToken}`,
          };
          error.response.config.headers = {
            Authorization: `Bearer ${res.data.accessToken}`,
            rerequest: true,
          };

          if (error?.response?.config?.data) {
            error.response.config.data = JSON.parse(error.response.config.data);
          }
          return AxiosBase.request(error.response.config);
        }
        store.dispatch(logoutUser("/timeout", "Timeout"));
        // localStorage.clear();
      } catch (err) {
        store.dispatch(logoutUser("/timeout", "Timeout"));
        // localStorage.clear();
      }
    } else {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default AxiosBase;

function formDataConvert(fData, secret_key) {
  let fd = new FormData();
  fd.append("fd_chk", "true");
  for (let pair of fData.entries()) {
    if (pair[1] instanceof File || pair[1] == undefined || pair[1] == null) {
      fd.append(pair[0], pair[1]);
    } else {
      fd.append(pair[0], encryptyData(pair[1], secret_key));
    }
  }
  return fd;
}
