import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";
import AxiosBase from "../../../networkRequest/axiosBase";
import moment from "moment";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import TablePagination from "@mui/material/TablePagination";

const History = (props) => {
  const [data, setData] = useState([]);
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get("merchantId");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  useEffect(() => {
    AxiosBase.get(
      `admin/subscriptions/getPlanByMerchant/${customerId}?pageNo=` +
        page +
        `&rowsPerPage=${rowsPerPage}`
    ).then((response) => {
      setData(response.data?.data);
      setCount(response.data?.data?.length);
      setLoading(false);
    });
  }, [page, rowsPerPage, count]);

  return (
    <>
      <div>
        <TableContainer className="table_container premium_history_tbl">
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="data_tbl_head_row">
              <TableRow style={{ background: "none" }}>
                <TableCell>Order id</TableCell>
                <TableCell>Plan</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Created on</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="data_tbl_body_row ">
              {data != " " &&
                data.map((row, index) => (
                  <TableRow
                    style={{ background: "none" }}
                    className="order_list_data_body"
                    key={index}
                  >
                    <TableCell className="table_id_data">
                      {row?.order_id}
                      {/* <OrderDetails
                        order_id={row._id}
                        display_order={row.order_id}
                        className={`table_id_data`}
                      /> */}
                    </TableCell>
                    <TableCell>{row.planData?.name}</TableCell>
                    <TableCell>{!row.amount ? "0" : row.amount}</TableCell>
                    <TableCell>
                      {moment(row.start_at).format("YYYY/MM/DD hh:mm A")}
                    </TableCell>
                    <TableCell>
                      {moment(row.expiry_at).format("YYYY/MM/DD hh:mm A")}
                    </TableCell>
                    <TableCell>
                      {moment(row.createdAt).format("YYYY/MM/DD hh:mm A")}
                      {/* {moment(row.updatedAt).format("YYYY/MM/DD hh:mm A")} */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {loading ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress />
            </div>
          ) : data == "" ? (
            <div className="no_data_found loader_data_tbl">
              <p>No results found</p>
            </div>
          ) : (
            ""
          )}
        </TableContainer>
        <TablePagination
          className="table_pagination"
          rowsPerPageOptions={[5, 25, 100]}
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default History;
