import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../networkRequest/axiosBase";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import "./Order.css";
import OrderDetails from "../../orders/order-details/OrderDetails";
const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});

// main function

const SettlementOrders = (props) => {
  const { rolePermission, from = "settlement" } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearch] = React.useState();
  const [merchantSearch, setMerchantSearch] = React.useState();
  const [merchantsBy, setMerchantBy] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // filter popup sections
  const [anchorE2, setAnchorE2] = React.useState(null);
  const pophandleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const pophandleClose = () => {
    setAnchorE2(null);
  };

  const openpop = Boolean(anchorE2);
  const id1 = openpop ? "simple-popover" : undefined;
  // filter popup sections end

  return (
    <>
      <div>
        <div>
          <div className="merchant_list_inner_title_div">
            <div className="add_merchant_list settlement_order_upper_div">
              <h3 className="merchant_list_inner_title">Order List</h3>
            </div>
          </div>
          <TableContainer className="table_container">
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Merchant Name</TableCell>
                  <TableCell>Payment Type</TableCell>
                  <TableCell>Total Amount</TableCell>
                  {props.from == "settlement" && (
                    <>
                      <TableCell> Customer Name</TableCell>
                      <TableCell>Customer Email</TableCell>
                      <TableCell>Customer Phone</TableCell>
                    </>
                  )}
                  <TableCell>Status</TableCell>
                  <TableCell>Payment Request At</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row merchant_list_tbl_body">
                {props.data.transactionData != " " &&
                  props.data.transactionData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => (
                      <TableRow style={{ background: "none" }}>
                        <TableCell>
                          <OrderDetails
                            order_id={row._id}
                            display_order={row.order_id}
                            className={`table_id_data`}
                            rolePermission={rolePermission}
                            from={from}
                          />
                        </TableCell>

                        {props.from == "settlement" && (
                          <TableCell>{row.to}</TableCell>
                        )}
                        {props.from == "customer" && (
                          <TableCell>
                            {row.merchantData
                              ? `${row.merchantData?.first_name} ${row.merchantData?.last_name}`
                              : row.to}
                          </TableCell>
                        )}

                        <TableCell>{row.type}</TableCell>

                        <TableCell>{row.amount}</TableCell>

                        {props.from == "settlement" && (
                          <>
                            <TableCell>{row.from}</TableCell>
                            <TableCell>{row.fromEmail}</TableCell>
                            <TableCell>{row.fromMobileNumber}</TableCell>
                          </>
                        )}

                        <TableCell>{row.status}</TableCell>

                        <TableCell>
                          {row?.payment_request_data
                            ? moment(
                                row?.payment_request_data?.createdAt
                              ).format("YYYY/MM/DD hh:mm A")
                            : "NA"}
                        </TableCell>

                        <TableCell>
                          {moment(row.createdAt).format("YYYY/MM/DD hh:mm A")}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress />
              </div>
            ) : props.data.transactionData == "" ? (
              <div className="no_data_found loader_data_tbl">
                <p>No Data Found</p>
              </div>
            ) : (
              ""
            )}
          </TableContainer>

          <TablePagination
            className="table_pagination"
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={props.data.transactionData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        {/* <Popover
          id={id1}
          open={openpop}
          anchorEl={anchorE2}
          onClose={pophandleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ marginLeft: "-275px", height: "500px" }}
        >
          <Typography sx={{ p: 2 }}>
            <Filter />
            <div className="apply-filter-btn-dev">
              <Button className="filter-popup-applay-btn" variant="contained">
                Apply Filter
              </Button>
              <Button
                className="filter-popup-cancel-btn"
                variant="contained"
                onClick={pophandleClose}
              >
                Cancel
              </Button>
            </div>
          </Typography>
        </Popover> */}
      </div>
    </>
  );
};

export default SettlementOrders;
