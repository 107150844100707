import React from 'react';

function Welcome() {
  return (
    <>
      <div style={{display: 'flex'}}>
        <h1 style={{color:'#2160F6', margin:'0'}}>Welcome! John </h1>
        <p style={{paddingLeft:'5px', color:'#666666', fontSize:'16px', margin:'14px 0px 0px 0px'}}> | Last login on 1:00 PM, Wednesday 03 Mar 2021</p>
      </div>
    </>
  )
}

export default Welcome
