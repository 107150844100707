import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import "./CollapseNavbar.css";
import NavBar from "./NavBar";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1456F5",
    borderRadius: "0 15px 15px 0",
    border: "none",
  },
}));
export default function CollapseNavBar(props) {
  const classes = useStyles();
  const [navBarFlag, setNavBarFlag] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [openNavbar, setOpenNavbar] = React.useState(true);

  const openNavbarHandler = () => {
    setChecked(false);
    setOpenNavbar(true);
  };

  const closeNavbarHandler = () => {
    setChecked(true);
    setOpenNavbar(false);
  };

  return (
    <div className={classes.root}>
      <Box className="navbar_collapes_box">
        <Collapse
          orientation="horizontal"
          in={checked}
          collapsedSize={60}
          timeout={1000}
          className="collapes_close_navbar"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <NavBar
            openNavbarHandler={openNavbarHandler}
            closeNavbarHandler={closeNavbarHandler}
            openNavbar={openNavbar}
            {...props}
          />
        </Collapse>
      </Box>
    </div>
  );
}
