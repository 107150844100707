import React from "react";
import { useState, useEffect } from "react";
import "./PremiumPlan.css";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Switch from "@mui/material/Switch";
import AxiosBase from "../../../networkRequest/axiosBase";
import { toast } from "react-toastify";

import { Link } from "react-router-dom";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Box, Paper } from "@mui/material";
import List, { listClasses } from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import { unstable_styleFunctionSx } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import PlanGrid from "./Plan-Grid/PlanGrid";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});

const PremiumPlan = (props) => {
  const { rolePermission } = props;
  const [spinner, setSpinner] = useState(false);
  const [controlDisable, setControlDisable] = useState(true);
  const [premiumdata, setPremiumData] = useState([]);
  const [module, setModule] = React.useState([]);

  const list = () => {
    AxiosBase.get(`/admin/premium`).then((res) => {
      setPremiumData(res?.data?.data);
      AxiosBase.get(`/admin/modules`).then((res) => {
        setModule(res?.data);
        setSpinner(false);
      });
    });
  };

  useEffect(() => {
    setSpinner(true);
    setControlDisable(true);
    list();
  }, []);

  const cancelPremium = (e, id) => {
    // setSpinner(true);
    // AxiosBase.get(`/admin/premium`).then((res) => {
    //   setPremiumData(res?.data?.data);
    //   setSpinner(false);
    // });
  };

  return (
    <>
      <div className="premium_plan_top_div">
        <div className="Fees_Margin_title_div" style={{ position: "relative" }}>
          <p>Premium Plan</p>
        </div>
        {spinner ? (
          <div className="no_data_found loader_data_tbl">
            <CircularProgress style={{ color: "#1058ff" }} />
          </div>
        ) : (
          <>
            <PlanGrid
              list={list}
              data={premiumdata}
              rolePermission={rolePermission}
              module={module}
              cancelPremium={cancelPremium}
            ></PlanGrid>
          </>
        )}
      </div>
    </>
  );
};

export default PremiumPlan;
