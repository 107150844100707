import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

function NavbarHoc(props) {
  const history = useNavigate();

  useEffect(() => {
    if (Array.isArray(props.id)) {
      const data = props.id?.filter((i) => props.NavBarData[i]);
      if (data && data.length) {
        props.onGetReports({
          ParticularData: props.NavBarData[data[0]],
          AllData: props.NavBarData,
        });
      } else {
        history(`/dashboard`);
      }
    } else {
      if (props.NavBarData[props.id]) {
        props.onGetReports({
          ParticularData: props.NavBarData[props.id],
          AllData: props.NavBarData,
        });
      } else {
        history(`/dashboard`);
      }
    }
  }, [props.NavBarData]);

  return <>{props.children}</>;
}

const mapStateToProps = (state) => {
  return {
    NavBarData: state.auth.NavbarAccess,
  };
};

export default connect(mapStateToProps, null)(NavbarHoc);
