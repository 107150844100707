import React from "react";
import { Route, Routes } from "react-router-dom";
import Topheader from "../../universal-components/header/topHeader";
import RoleList from "../../Components/Users/rolemaster/rolelist";
import RoleTabs from "../../Components/Users/RoleTabs";
function RoleList1() {
  return (
    <div style={{ padding: "15px" }}>
      <Topheader />
      <Routes>
        <Route exact path="information/:id" element={<RoleTabs />} />
        <Route exact path="information" element={<RoleTabs />} />
        <Route exact path="" element={<RoleList />} />
      </Routes>
    </div>
  );
}

export default RoleList1;
