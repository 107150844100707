import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { viewKycDetails } from "../../../../../../../../networkRequest/request/kyc";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SaveIcon from "@mui/icons-material/Save";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ErrorIcon from "@mui/icons-material/Error";
import "./EmiratesID.css";
import Tooltip from "@mui/material/Tooltip";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "100%",
    height: "0.5px",
    fontFamily: "SF Pro Display",
    fontSize: "12px !important",
  },
});

const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
    borderRadius: "10px",
  },
});

// EmiratesId
function EmiratesId(props) {
  const { rolePermission } = props;
  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  let date = new Date();

  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const search = useLocation().search;

  const [data, setData] = useState();
  const [customerId, setCutomerid] = useState();

  useEffect(() => {
    const kycId = new URLSearchParams(search).get("kycId");
    setCutomerid(customerId);
    viewKycDetails(kycId)
      .then((resp) => {
        setData(resp);
      })
      .catch((e) => console.log(e));
  }, []);

  const [tradeLicense, setTradeLicense] = React.useState({
    companyName: "",
    mainLiceNr: "",
    capitalDetails: "",
    registerNo: "",
    licenseAddress: "",
    regDate: "",
    commerceAddress: "",
    commRegDate: "",
    RegisterActivities: "",
    legalType: "",
    comment: "",
  });

  const commercialHandleChange = (e) => {
    const { name, value } = e.target;
    setTradeLicense({
      ...tradeLicense,
      [name]: value,
    });
  };

  const submitCommercialRegData = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <div className="emiratesId-main-div">
        {!props.data.emiratesImgUrl ? (
          <Tooltip title="Please Upload Emirates ID">
            <div className="emiratesID-header-sec">
              <p
                style={{ color: "#666666", fontWeight: "normal", fontSize: 20 }}
              >
                Emirates ID
              </p>
              <p style={{ color: "#a7a7a7", fontWeight: 500 }}>Identity Card</p>
            </div>
          </Tooltip>
        ) : (
          <div className="emiratesID-header-sec">
            <p style={{ color: "#666666", fontWeight: "normal", fontSize: 20 }}>
              Emirates ID
            </p>
            <p style={{ color: "#a7a7a7", fontWeight: 500 }}>Identity Card</p>
          </div>
        )}

        <div className="emiratesID-bodysec-div">
          <div className="emiratesID-data-feild">
            <p>
              Emirates ID<span>*</span>:
            </p>
            <CssTextField
              id="new-menu"
              variant="outlined"
              sx={{ mx: "1.5rem", width: "67%" }}
              name="idNumber"
              value={props?.data?.emiratesData?.idNumber}
              onChange={(e) => props.handleEmiratesChange(e)}
              placeholder="Enter Emirates ID"
              disabled={
                (!props.data.emiratesImgUrl ||
                  props.disablecontent ||
                  props.data.emiratesIdVerified == "Approved") &&
                props.data.kycStatus != "Expired"
                  ? // ||
                    // props.data.emiratesIdVerified == "Rejected" ||
                    // props.data.tradeLicenseVerified == "Rejected"
                    true
                  : false
              }
            />
          </div>
        </div>

        <div className="emiratesID-bodysec-div">
          <div className="emiratesID-data-feild">
            <p>Name:</p>
            <CssTextField
              id="new-menu"
              variant="outlined"
              sx={{
                mx: "1.5rem",
                width: "67%",
                fontSize: "12px",
              }}
              name="name"
              value={props?.data?.emiratesData?.name}
              onChange={(e) => props.handleEmiratesChange(e)}
              placeholder="Enter Name"
              disabled={
                (!props.data.emiratesImgUrl ||
                  props.disablecontent ||
                  props.data.emiratesIdVerified == "Approved") &&
                props.data.kycStatus != "Expired"
                  ? // ||
                    // props.data.emiratesIdVerified == "Rejected" ||
                    // props.data.tradeLicenseVerified == "Rejected"
                    true
                  : false
              }
            />
          </div>
        </div>

        <div className="emiratesID-bodysec-div">
          <div className="emiratesID-data-feild">
            <p>
              Expiry Date<span>*</span>:
            </p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className="mui-date-picker"
                clearable
                minDate={date.addDays(1)}
                inputProps={{ readOnly: true }}
                inputFormat="MM/dd/yyyy"
                value={
                  props?.data?.emiratesData?.expiryDate
                    ? props?.data?.emiratesData?.expiryDate
                    : null
                }
                disabled={
                  (!props.data.dtlImgUrl ||
                    props.disablecontent ||
                    props.data.emiratesIdVerified == "Approved") &&
                  props.data.kycStatus != "Expired"
                    ? // ||
                      // props.data.emiratesIdVerified == "Rejected" ||
                      // props.data.tradeLicenseVerified == "Rejected"
                      true
                    : false
                }
                onChange={(e) =>
                  props.handleEmiratesDateChange(e, "expiryDate")
                }
                renderInput={(params) => (
                  <CssTextField
                    className="datepicker"
                    id="new-menu"
                    variant="outlined"
                    sx={{
                      mx: "1.5rem",
                      width: "67%",
                      pr: "10px",
                      fontSize: "12px",
                    }}
                    placeholder="Expiry Date"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>

        <div className="emiratesID-bodysec-div">
          <div className="emiratesID-data-feild">
            <p>
              Comments<span style={{ color: "red" }}>*</span>:
            </p>
            <CssTextArea
              id="comment-text"
              variant="outlined"
              sx={{
                width: "67%",
                mt: "1rem",
                borderRadius: "5px",
                borderColor: "#a7a7a7",
                margin: "0 1.5rem",
                fontSize: "14px",
              }}
              value={props?.data?.emiratesData?.comments}
              name="comments"
              onChange={(e) => props.handleEmiratesChange(e)}
              disabled={
                (!props.data.emiratesImgUrl ||
                  props.disablecontent ||
                  props.data.emiratesIdVerified == "Approved") &&
                props.data.kycStatus != "Expired"
                  ? // ||
                    // props.data.emiratesIdVerified == "Rejected" ||
                    // props.data.tradeLicenseVerified == "Rejected"
                    true
                  : false
              }
              minRows={3}
            />
          </div>
        </div>
        {props?.hidebuttons == "true" ? (
          <></>
        ) : (
          <div style={{ marginTop: "10px" }}></div>
        )}

        {props?.hidebuttons == "true" ? (
          <></>
        ) : (
          <div style={{ borderTop: "1px solid #dadada" }}>
            <div className="emirates-btn-div">
              {/* <Button  onClick={() => props.handleViewDocument()} style={{ backgroundColor: '#666666', borderRadius: '1rem', color: '#fff', fontSize: '11px', fontWeight: 500, paddingTop: '4px', paddingBottom: '4px',  }}  >
                                View Document
                            </Button> */}

              <div style={{ display: "flex" }}>
                <Button
                  className="kyc-action-btn"
                  onClick={() => props.handleEmiratesReject()}
                  disabled={
                    (!props.data.emiratesImgUrl ||
                      props.disablecontent ||
                      props.data.emiratesIdVerified == "Approved") &&
                    props.data.kycStatus != "Expired"
                      ? // ||
                        // props.data.emiratesIdVerified == "Rejected" ||
                        // props.data.tradeLicenseVerified == "Rejected"
                        true
                      : false
                  }
                  style={{
                    backgroundColor:
                      props.data.emiratesIdVerified == "Rejected"
                        ? "#f00"
                        : !props.data.emiratesImgUrl ||
                          props.disablecontent ||
                          props.data.emiratesIdVerified == "Approved" ||
                          props.data.tradeLicenseVerified == "Rejected"
                        ? "#a3a1a1"
                        : "#f00",
                  }}
                  endIcon={<CancelIcon style={{ marginLeft: "0rem" }} />}
                >
                  {props.data.emiratesIdVerified == "Rejected"
                    ? "Rejected"
                    : "Reject"}
                </Button>
              </div>

              <div style={{ display: "flex" }}>
                <Button
                  className="kyc-action-btn"
                  onClick={() => props.handleEmiratesApproved()}
                  disabled={
                    (!props.data.emiratesImgUrl ||
                      props.disablecontent ||
                      props.data.emiratesIdVerified == "Approved") &&
                    props.data.kycStatus != "Expired"
                      ? // ||
                        // props.data.emiratesIdVerified == "Rejected" ||
                        // props.data.tradeLicenseVerified == "Rejected"
                        true
                      : false
                  }
                  style={{
                    backgroundColor:
                      props.data.emiratesIdVerified == "Rejected" ||
                      props.data.tradeLicenseVerified == "Rejected"
                        ? "#a3a1a1"
                        : "#2ed06d",
                  }}
                  endIcon={<CheckCircleIcon />}
                >
                  {props.data.emiratesIdVerified == "Approved"
                    ? "Approved"
                    : "Approve"}
                </Button>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  className="kyc-action-btn"
                  onClick={() => props.handleEmiratesSaveApproved()}
                  disabled={
                    (!props.data.emiratesImgUrl ||
                      props.disablecontent ||
                      props.data.emiratesIdVerified == "Approved") &&
                    props.data.kycStatus != "Expired"
                      ? // ||
                        // props.data.emiratesIdVerified == "Rejected" ||
                        // props.data.tradeLicenseVerified == "Rejected"
                        true
                      : false
                  }
                  style={{
                    backgroundColor:
                      !props.data.emiratesImgUrl ||
                      props.disablecontent ||
                      props.data.emiratesIdVerified == "Approved"
                        ? // ||
                          // props.data.emiratesIdVerified == "Rejected" ||
                          // props.data.tradeLicenseVerified == "Rejected"
                          "#a3a1a1"
                        : "#1058ff",
                  }}
                  endIcon={<SaveIcon />}
                >
                  Save & Approve
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      {props?.hidebuttons == "true" ? (
        <></>
      ) : (
        <div className="kyc-note">
          <p className="kyc-note-iconText">
            <ErrorIcon
              sx={{
                height: "16px",
                width: "16px",
                color: "#ff5722",
                mr: "5px",
              }}
            />
            <p className="kyc-note-iconText">
              No changes would be allowed after accepting or rejecting the KYC
            </p>
          </p>
        </div>
      )}
    </div>
  );
}

export default EmiratesId;
