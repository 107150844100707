import React from "react";
import { useState, useEffect } from "react";
import "./KycDocument.css";
import AxiosBase from "../../../../networkRequest/axiosBase";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import UploadDoc from "../upload-doc/UploadDoc";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dynamicFieldArr from "../../../../utils/kycDoc.json";
import { saveAs } from "file-saver";
import moment from "moment";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});
const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

const labelList = [
  {
    value: "ID Document",
    label: "ID Document",
  },
  {
    value: "License",
    label: "License",
  },
  {
    value: "Bank Document",
    label: "Bank Document",
  },
  {
    value: "Tax",
    label: "Tax",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const KycDocument = (props) => {
  const { SelectedKyc, kyc_id } = props;
  const [kyc, setKyc] = React.useState({});
  const [spinner, setSpinner] = React.useState(false);
  const [nextSpinner, setNextSpinner] = React.useState(false);
  const [spinnerReject, setSpinnerReject] = React.useState(false);
  const [spinnerApprove, setSpinnerApprove] = React.useState(false);
  const [spinnerFinal, setSpinnerFinal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [uploadDoc, setUploadDoc] = React.useState(false);
  const hiddenFileInput = React.useRef(null);
  const [selectedObj, setSelectedObj] = React.useState([]);
  const [selectedKycId, setSelectedKycId] = React.useState(null);
  const [ext, setExt] = React.useState("");

  useEffect(() => {
    setSelectedKycId(kyc_id);
    let ind = dynamicFieldArr.findIndex((x) => x.type == SelectedKyc?.type);
    if (ind > -1) {
      if (SelectedKyc?.is_expiry) {
        const ind1 = dynamicFieldArr[ind]?.fields.findIndex(
          (x) => x.key == "expiryDate"
        );
        if (ind1 == -1) {
          dynamicFieldArr[ind]?.fields.push({
            key: "expiryDate",
            label: "Expiry Date",
            type: "date",
          });
        }
      }
      setSelectedObj(dynamicFieldArr[ind].fields);
    } else {
      let defaultDocObj = [
        {
          key: "name",
          label: "Name",
          type: "text",
        },
        {
          key: "additional_detail",
          label: "Additional Detail",
          type: "textarea",
        },
      ];
      if (SelectedKyc?.is_expiry) {
        defaultDocObj.push({
          key: "expiryDate",
          label: "Expiry Date",
          type: "date",
        });
      }
      setSelectedObj(defaultDocObj);
    }
    if (!SelectedKyc?.data) {
      SelectedKyc.data = {};
    }
    setKyc(SelectedKyc);
    if (SelectedKyc?.url) {
      var file_ext = SelectedKyc?.url.substr(
        SelectedKyc?.url.lastIndexOf(".") + 1,
        SelectedKyc?.url.length
      );
      setExt(file_ext);
    }
  }, [SelectedKyc]);

  const handleFileClick = (event) => {
    // hiddenFileInput.current.click();
    setUploadDoc(true);
    setOpenUpload(true);
  };

  const uploadFile = (e) => {
    kyc.file = e.target.files[0];
    kyc.url = URL.createObjectURL(e.target.files[0]);
    setKyc({
      ...kyc,
      file: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    });
    e.target.value = null;
  };

  const onSubmitKyc = () => {
    if ((!kyc.file && !kyc.url) || !kyc.type || !kyc.name) {
      if (!kyc.file && !kyc.url) {
        return toast("Please upload the required document", {
          type: "error",
          autoClose: 2000,
        });
      }
      if (!kyc.type) {
        return toast("Document type is required", {
          type: "error",
          autoClose: 2000,
        });
      }
      if (!kyc.name) {
        return toast("Document name is required", {
          type: "error",
          autoClose: 2000,
        });
      }
      return;
    }
    if (kyc.is_expiry) {
      if (!kyc?.data?.expiryDate) {
        return toast("Expiry date is required", {
          type: "error",
          autoClose: 2000,
        });
      }
      if (
        new Date(kyc?.data?.expiryDate).getTime() <=
        new Date(moment().startOf("days")).getTime()
      ) {
        return toast("Date should be greater than or equal to today", {
          type: "error",
          autoClose: 2000,
        });
      }
    }
    setSpinner(true);
    const formData = new FormData();
    formData.append("file", kyc?.file);
    formData.append("url", kyc?.url);
    formData.append("type", kyc.type);
    formData.append("name", kyc.name);
    formData.append("document_type", kyc.document_type);
    formData.append("data", JSON.stringify(kyc?.data ? kyc.data : null));
    if (selectedKycId) {
      formData.append("kyc_id", selectedKycId);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    AxiosBase.post(
      `/admin/kyc/upload-kyc-document/${props.user ? props.user?._id : -1}`,
      formData,
      config
    )
      .then((res) => {
        if (res?.status == 200) {
          setSelectedKycId(res?.data.kycId);
          props.onSubmitKyc(res?.data, kyc);
          return toast("Successfully uploaded kyc document", {
            type: "success",
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        setSpinner(false);
        return toast(
          err?.response?.data?.message
            ? err?.response?.data?.message.toString()
            : "Something went wrong",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      });
  };

  const onNextDoc = () => {
    if ((!kyc.file && !kyc.url) || !kyc.type || !kyc.name) {
      if (!kyc.file && !kyc.url) {
        return toast("Please upload the required document", {
          type: "error",
          autoClose: 2000,
        });
      }
      if (!kyc.type) {
        return toast("Document type is required", {
          type: "error",
          autoClose: 2000,
        });
      }
      if (!kyc.name) {
        return toast("Document name is required", {
          type: "error",
          autoClose: 2000,
        });
      }
      return;
    }
    if (kyc.is_expiry) {
      if (!kyc?.data?.expiryDate) {
        return toast("Expiry date is required", {
          type: "error",
          autoClose: 2000,
        });
      }
      if (
        new Date(kyc?.data?.expiryDate).getTime() <=
        new Date(moment().startOf("days")).getTime()
      ) {
        return toast("Date should be greater than or equal to today", {
          type: "error",
          autoClose: 2000,
        });
      }
    }
    setNextSpinner(true);
    const formData = new FormData();
    formData.append("file", kyc?.file);
    formData.append("url", kyc?.url);
    formData.append("type", kyc.type);
    formData.append("name", kyc.name);
    formData.append("document_type", kyc.document_type);
    formData.append("data", JSON.stringify(kyc?.data ? kyc.data : null));
    if (selectedKycId) {
      formData.append("kyc_id", selectedKycId);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    AxiosBase.post(
      `/admin/kyc/upload-kyc-document/${props.user ? props.user?._id : -1}`,
      formData,
      config
    )
      .then((res) => {
        if (res?.status == 200) {
          setNextSpinner(false);
          setSelectedKycId(res?.data.kycId);
          props.onNextDoc(res?.data, kyc, "save_and_next");
        }
      })
      .catch((err) => {
        console.log(err);
        setNextSpinner(false);
        return toast(
          err?.response?.data?.message
            ? err?.response?.data?.message.toString()
            : "Something went wrong",
          {
            type: "error",
            autoClose: 2000,
          }
        );
      });
  };

  const deleteKycDocument = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenUpload(false);
  };

  const handleDelete = () => {
    setOpen(false);
    kyc.file = "";
    kyc.url = "";
    setKyc({
      ...kyc,
      file: "",
      url: "",
    });
  };

  const handleUploadDocClose = () => {
    setOpenUpload(false);
    setUploadDoc(false);
  };

  const handleData = (file) => {
    setOpenUpload(false);
    setUploadDoc(false);
    var file_ext = file.name.substr(
      file.name.lastIndexOf(".") + 1,
      file.name.length
    );
    setExt(file_ext);
    kyc.file = file;
    kyc.url = URL.createObjectURL(file);
    setKyc({
      ...kyc,
      file: file,
      url: URL.createObjectURL(file),
    });
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const onChangeDocStatus = (val) => {
    if (val) {
      setSpinnerApprove(true);
    } else {
      setSpinnerReject(true);
    }
    const obj = {
      type: kyc.type,
      isverified: val,
      data: kyc.data,
    };
    const is_verified = val ? "Approved" : "Rejected";
    kyc.is_verified = is_verified;
    props.onChangeKycStatus(obj, kyc);
    setKyc({
      ...kyc,
      is_verified: is_verified,
    });
    setSpinnerApprove(false);
    setSpinnerReject(false);
    return toast("Successfully updated kyc document status", {
      type: "success",
      autoClose: 2000,
    });
  };

  const downloadDoc = (doc) => {
    if (doc.url == "") {
      return;
    }
    if (doc?.file?.name) {
      var file_ext = doc?.file?.name.substr(
        doc?.file?.name.lastIndexOf(".") + 1,
        doc?.file?.name.length
      );
    } else {
      var file_ext = doc?.url.substr(
        doc?.url.lastIndexOf(".") + 1,
        doc?.url.length
      );
    }
    saveAs(doc?.url, `${doc?.name}.${file_ext}`);
  };

  return (
    <>
      <div style={{ width: "100%" }} className="kyc_detail_ctrl">
        <div className="">
          <div>
            <div className="add_menu_role_star">
              <p>Document Name:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec kyc-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"name_"}
                  placeholder="name"
                  variant="outlined"
                  aria-readonly
                  value={kyc.name}
                  disabled={kyc?._id || props.showFrom != "upload_doc"}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val.length <= 50) {
                      kyc.name = val;
                      setKyc({
                        ...kyc,
                        name: val,
                      });
                    }
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Document Type:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec kyc-field kyc-doc-list"
              >
                {kyc?._id ? (
                  <CssTextField
                    className="merchant_list_search_bar"
                    id="new-menu"
                    name={"name_"}
                    placeholder="name"
                    variant="outlined"
                    aria-readonly
                    value={kyc.document_type}
                    disabled={true}
                  />
                ) : (
                  <Select
                    name="key1"
                    labelId="country-lbl"
                    id="label"
                    value={
                      kyc.document_type ? kyc.document_type : labelList[0].value
                    }
                    style={{ width: "100%" }}
                    disabled={kyc?._id || props.showFrom != "upload_doc"}
                    onChange={(e) => {
                      let val = e.target.value;
                      kyc.document_type = val;
                      setKyc({
                        ...kyc,
                        document_type: val,
                      });
                    }}
                  >
                    {labelList &&
                      labelList.map((c) => {
                        return (
                          <MenuItem key={c.value} value={c.value}>
                            {c.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                )}
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Type:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec kyc-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"type_"}
                  placeholder="type"
                  variant="outlined"
                  aria-readonly
                  value={kyc.type}
                  disabled={kyc?._id || props.showFrom != "upload_doc"}
                  onChange={(e) => {
                    let val = e.target.value;
                    kyc.type = val;
                    setKyc({
                      ...kyc,
                      type: val,
                    });
                  }}
                />
              </Grid>
            </div>
            <div className="septate-kyc-doc">
              <br />
            </div>
            {selectedObj.map((elm) => {
              if (elm.type == "text") {
                return (
                  <>
                    <div className="add_menu_role_star">
                      <p>{elm.label}:</p>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="gatway_second_grid_sec kyc-field"
                      >
                        <CssTextField
                          className="merchant_list_search_bar"
                          id="new-menu"
                          name={elm.key + "_" + elm.type}
                          placeholder={elm.label}
                          variant="outlined"
                          aria-readonly
                          value={kyc?.data[elm.key] || ""}
                          disabled={
                            kyc.is_verified == "Approved" ||
                            kyc.is_verified == "Rejected" ||
                            kyc.is_verified == "Expired"
                          }
                          onChange={(e) => {
                            let val = e.target.value;
                            kyc.data[elm.key] = val;
                            setKyc({
                              ...kyc,
                            });
                          }}
                        />
                      </Grid>
                    </div>
                  </>
                );
              }
              if (elm.type == "number") {
                return (
                  <>
                    <div className="add_menu_role_star">
                      <p>{elm.label}:</p>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="gatway_second_grid_sec kyc-field"
                      >
                        <CssTextField
                          className="merchant_list_search_bar"
                          id="new-menu"
                          name={elm.key + "_" + elm.type}
                          placeholder={elm.label}
                          variant="outlined"
                          aria-readonly
                          value={kyc?.data[elm.key] || ""}
                          type="number"
                          onWheel={numberInputOnWheelPreventChange}
                          disabled={
                            kyc.is_verified == "Approved" ||
                            kyc.is_verified == "Rejected" ||
                            kyc.is_verified == "Expired"
                          }
                          onChange={(e) => {
                            let val = e.target.value;
                            const isDecimal = val % 1 != 0;
                            if (isDecimal) {
                              val = val
                                .toString()
                                .match(/^-?\d+(?:\.\d{0,2})?/)[0];
                            }
                            kyc.data[elm.key] = val;
                            setKyc({
                              ...kyc,
                            });
                          }}
                        />
                      </Grid>
                    </div>
                  </>
                );
              }
              if (elm.type == "date") {
                return (
                  <>
                    <div className="add_menu_role_star kyc-doc-date">
                      <p>{elm.label}:</p>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="gatway_second_grid_sec kyc-field"
                      >
                        <LocalizationProvider
                          className="merchant_list_search_bar"
                          dateAdapter={AdapterDateFns}
                        >
                          <DatePicker
                            className="merchant_list_search_bar mui-date-picker"
                            inputFormat="MM/dd/yyyy"
                            value={kyc?.data[elm.key] || ""}
                            disabled={
                              kyc.is_verified == "Approved" ||
                              kyc.is_verified == "Rejected" ||
                              kyc.is_verified == "Expired"
                            }
                            onChange={(e) => {
                              kyc.data[elm.key] = e;
                              setKyc({
                                ...kyc,
                              });
                            }}
                            renderInput={(params) => (
                              <CssTextField
                                id={elm.key + "_" + elm.type}
                                name={elm.key + "_" + elm.type}
                                className="merchant_list_search_bar premium_detail_field"
                                variant="outlined"
                                placeholder={elm.label}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </div>
                  </>
                );
              }
              if (elm.type == "textarea") {
                return (
                  <>
                    <div className="add_menu_role_star">
                      <p>{elm.label}:</p>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="gatway_second_grid_sec kyc-field"
                      >
                        <CssTextArea
                          id="text"
                          variant="outlined"
                          sx={{
                            width: "96%",
                            p: "10px",
                            borderRadius: "5px",
                            fontSize: "14px",
                          }}
                          value={kyc?.data[elm.key] || ""}
                          name={elm.key + "_" + elm.type}
                          disabled={
                            kyc.is_verified == "Approved" ||
                            kyc.is_verified == "Rejected" ||
                            kyc.is_verified == "Expired"
                          }
                          onChange={(e) => {
                            let val = e.target.value;
                            kyc.data[elm.key] = val;
                            setKyc({
                              ...kyc,
                            });
                          }}
                          minRows={3}
                        />
                      </Grid>
                    </div>
                  </>
                );
              }
            })}
            {props.showFrom != "upload_doc" ? (
              <div className="add_menu_role_star">
                <p>Comment:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec kyc-field"
                >
                  <CssTextArea
                    id="text"
                    variant="outlined"
                    sx={{
                      width: "96%",
                      p: "10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                    }}
                    value={kyc?.data?.comment || ""}
                    name="Comment"
                    disabled={
                      kyc.is_verified == "Approved" ||
                      kyc.is_verified == "Rejected" ||
                      kyc.is_verified == "Expired"
                    }
                    onChange={(e) => {
                      let val = e.target.value;
                      kyc.data.comment = val;
                      setKyc({
                        ...kyc,
                      });
                    }}
                    minRows={3}
                  />
                </Grid>
              </div>
            ) : (
              <></>
            )}
            {props.showFrom == "upload_doc" ? (
              <div className="">
                <Button
                  className="kyc_upload_doc_btn"
                  onClick={handleFileClick}
                  disabled={kyc.url != ""}
                >
                  <i className="fa fa-upload"></i>&nbsp;&nbsp; Upload Document
                </Button>
              </div>
            ) : (
              <></>
            )}
            <hr />
            <div className="row">
              {kyc.url && (
                <>
                  <div style={{ width: "33.33%" }}>
                    <div className="kyc-upload-image-col">
                      <label className="doc-section-heading">{kyc?.name}</label>
                      <div
                        className="doc-section"
                        style={{ "text-align": "center" }}
                      >
                        {ext == "pdf" ? (
                          <i
                            className="fa fa-file-pdf-o"
                            style={{ "font-size": "75px" }}
                          ></i>
                        ) : ext == "csv" || ext == "xlsx" || ext == "xls" ? (
                          <i
                            className="fa fa-file"
                            style={{ "font-size": "75px" }}
                          ></i>
                        ) : (
                          <img src={kyc.url} width="100%" />
                        )}
                      </div>
                      {props.showFrom == "upload_doc" ? (
                        <img
                          onClick={() => {
                            deleteKycDocument();
                          }}
                          src="/cross-icon.svg"
                          className="trash"
                          width="15px"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      style={{ "text-align": "right", "margin-top": "10px" }}
                    >
                      <label
                        className="download-kyc-document"
                        onClick={() => {
                          downloadDoc(kyc);
                        }}
                      >
                        <i className="fa fa-arrow-circle-o-down"></i> Download
                      </label>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          {props.showFrom == "upload_doc" ? (
            <div className="kyc-footer-btn" style={{ "margin-top": "20px" }}>
              <Button
                className="detail_merchant_preview_btn"
                variant="contained"
                disabled={spinner || nextSpinner || !kyc.allow_preview}
                onClick={() => {
                  props.onPreviewDoc();
                }}
              >
                Previous
              </Button>
              <Button
                className="detail_merchant_next_btn"
                variant="contained"
                disabled={spinner || nextSpinner || !kyc.allow_next}
                onClick={() => {
                  onNextDoc();
                }}
              >
                {nextSpinner ? (
                  <i
                    className="fa fa-spinner fa-spin spinner"
                    aria-hidden="true"
                    style={{ padding: "5px" }}
                  >
                    {" "}
                  </i>
                ) : (
                  "Save & Next"
                )}
              </Button>
              <Button
                className="drawer_merchant_cancel_btn"
                variant="contained"
                disabled={spinner || nextSpinner}
                onClick={props.toggleDrawer("right", false)}
              >
                Cancel
              </Button>

              <Button
                className="drawer_merchant_save_btn"
                variant="contained"
                disabled={spinner || nextSpinner}
                onClick={() => {
                  onSubmitKyc();
                }}
              >
                {spinner ? (
                  <i
                    className="fa fa-spinner fa-spin spinner"
                    aria-hidden="true"
                    style={{ padding: "5px" }}
                  >
                    {" "}
                  </i>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          ) : (
            <div className="kyc-doc-btn-group" style={{ "margin-top": "20px" }}>
              {kyc.is_verified != "Approved" &&
              kyc.is_verified != "Rejected" &&
              kyc.is_verified != "Expired" ? (
                <>
                  <Button
                    className="drawer_merchant_cancel_btn"
                    variant="contained"
                    disabled={
                      spinnerFinal ||
                      spinnerReject ||
                      spinnerApprove ||
                      kyc.is_verified == "Approved" ||
                      kyc.is_verified == "Rejected" ||
                      kyc.is_verified == "Expired"
                    }
                    onClick={() => {
                      onChangeDocStatus(false);
                    }}
                  >
                    {spinnerReject ? (
                      <i
                        className="fa fa-spinner fa-spin spinner"
                        aria-hidden="true"
                        style={{ padding: "5px" }}
                      >
                        {" "}
                      </i>
                    ) : (
                      "Reject"
                    )}
                  </Button>

                  <Button
                    className="drawer_merchant_save_btn"
                    variant="contained"
                    disabled={
                      spinnerFinal ||
                      spinnerReject ||
                      spinnerApprove ||
                      kyc.is_verified == "Approved" ||
                      kyc.is_verified == "Rejected" ||
                      kyc.is_verified == "Expired"
                    }
                    onClick={() => {
                      onChangeDocStatus(true);
                    }}
                  >
                    {spinnerApprove ? (
                      <i
                        className="fa fa-spinner fa-spin spinner"
                        aria-hidden="true"
                        style={{ padding: "5px" }}
                      >
                        {" "}
                      </i>
                    ) : (
                      "Approve"
                    )}
                  </Button>

                  <Button
                    className="drawer_merchant_save_btn"
                    variant="contained"
                    disabled={
                      spinnerFinal ||
                      spinnerReject ||
                      spinnerApprove ||
                      kyc.is_verified == "Approved" ||
                      kyc.is_verified == "Rejected" ||
                      kyc.is_verified == "Expired"
                    }
                    onClick={() => {
                      onChangeDocStatus(true);
                    }}
                  >
                    {spinnerApprove ? (
                      <i
                        className="fa fa-spinner fa-spin spinner"
                        aria-hidden="true"
                        style={{ padding: "5px" }}
                      >
                        {" "}
                      </i>
                    ) : (
                      "Save & Approve"
                    )}
                  </Button>

                  <br />
                </>
              ) : (
                <></>
              )}
              <Button
                className="drawer_merchant_next_btn"
                variant="contained"
                disabled={
                  spinnerFinal ||
                  spinnerReject ||
                  spinnerApprove ||
                  !kyc.allow_next
                }
                onClick={() => {
                  props.onNextDoc(null, null, "next");
                }}
              >
                Next
              </Button>
              <Button
                className="drawer_merchant_final_Submit_btn"
                variant="contained"
                disabled={
                  spinnerFinal ||
                  spinnerReject ||
                  spinnerApprove ||
                  props.status == "Approved" ||
                  props.status == "Rejected" ||
                  props.status == "Expired"
                }
                onClick={() => {
                  setSpinnerFinal(true);
                  const rest = props.onFinalSubmitDocStatus();
                  if (!rest) {
                    setSpinnerFinal(false);
                  }
                }}
              >
                {spinnerFinal ? (
                  <i
                    className="fa fa-spinner fa-spin spinner"
                    aria-hidden="true"
                    style={{ padding: "5px" }}
                  >
                    {" "}
                  </i>
                ) : (
                  "Final Submit"
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div>
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openUpload}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              {
                <>
                  <UploadDoc
                    open={true}
                    handleDocumentClose={handleUploadDocClose}
                    handleData={handleData}
                    type={kyc.type}
                  />
                </>
              }
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
      <div>
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              {
                <>
                  {" "}
                  <p>Confirm Delete</p>
                  <span>Are you sure want to delete this document?</span>
                  <div className="order_detail_refund_popup_btn">
                    <Button
                      className="refund_save_btn"
                      variant="contained"
                      autoFocus
                      fullWidth
                      onClick={handleDelete}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="refund_cancel_btn"
                      variant="contained"
                      autoFocus
                      onClick={handleClose}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              }
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default KycDocument;
