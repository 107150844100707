import React, { useState, useEffect } from "react";
import { Box, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../../networkRequest/axiosBase";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Filter from "../../filter-component/Filter";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import "./RefundList.css";
import OrderDetails from "../order-details/OrderDetails";
import { makeStyles } from "@mui/styles";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import CloseRounded from "@mui/icons-material/CloseRounded";
import Tooltip from "@mui/material/Tooltip";
import DateRangeComp from "../../datepicker";
import NavbarHoc from "../../../pages/navhoc/index";
import Config from "../../../config.json";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});
const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});

// main function

const RefundList = (props) => {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [date, setDate] = React.useState({
    startDate: "",
    endDate: "",
  });
  const [count, setCount] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearch] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rolePermission, setRolePermission] = React.useState([]);
  const open = Boolean(anchorEl);
  const [searchByCalled, setsearchByCalled] = React.useState(false);

  const [filter, setFilter] = React.useState([
    { key: "", type: "", value: "" },
  ]);
  //  Transaction Id, Payment Type, Status, Customer name, email
  const filterOptons = [
    { property: "Transaction id", value: "transactionId" },
    { property: "Merchant name", value: "to_customer_name" },
    { property: "Merchant number", value: "toCustomerData.mobile_no" },
    { property: "Customer Name", value: "from_customer_name" },
    { property: "Customer number", value: "fromCustomerData.mobile_no" },
    { property: "Order Amount", value: "amount_str" },
    {
      property: "Refunded amount",
      value: "refunded_amount_str",
    },
    {
      property: "Status",
      value: "status",
      values: ["Refunded", "Partially Refunded"],
    },
    { property: "Created on", value: "createdAt" },
  ];
  const filterContains = [
    { property: "Is", value: "is" },
    { property: "Like", value: "contain" },
    { property: "Not Like", value: "not_contain" },
  ];

  const handleSearchButton = (e) => {
    setPage(0);
    setFilter([{ key: "", type: "", value: "" }]);
    setsearchByCalled(!searchByCalled);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.key === "Enter") {
      setPage(0);
      setFilter([{ key: "", type: "", value: "" }]);
      setsearchByCalled(!searchByCalled);
    }
  };
  const ResetFilter = async (event) => {
    setFilter([{ key: "", type: "", value: "" }]);
  };
  const [anchorE2, setAnchorE2] = React.useState(null);
  const handleFilter = async (JSONfilter) => {
    setFilter(JSONfilter);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const pophandleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const pophandleClose = () => {
    setAnchorE2(null);
  };

  const handledates = (values) => {
    setDate(values);
  };

  useEffect(() => {
    setLoading(true);
    setData([]);
    setCount(0);
    const filtersval = filter[0].key != "" ? JSON.stringify(filter) : "";
    let dateparams = "";
    if (date.startDate != "") {
      dateparams = `dateRange=custom&dateFrom=${date.startDate}&dateTo=${date.endDate}&dateField=createdAt`;
    }
    AxiosBase.get(
      `admin/order/refund?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        `&search=${searchBy ? searchBy : ""}` +
        `&filter=${filtersval}&${dateparams}`
    ).then((response) => {
      setData(response.data.data);
      setCount(response.data.count);
      setLoading(false);
    });
  }, [page, rowsPerPage, searchByCalled, filter, date]);

  const openpop = Boolean(anchorE2);
  const id1 = openpop ? "simple-popover" : undefined;

  // filter popup sections end

  return (
    <NavbarHoc
      id={Config.PermissionId.Refunds}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Refunds</h1>
        </Box>
        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                // sm={12}
                md={12}
                lg={12}
                // xl={4}
                className="merchant_grid_sec"
              >
                <div
                  className="merchant_list_filter_div"
                  style={{ height: "100%" }}
                >
                  <CssTextField
                    className="serachBar"
                    id="new-menu"
                    placeholder="Search"
                    variant="outlined"
                    // fullWidth="false"
                    onKeyUp={handleSearch}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton className="merchant_search_icon">
                            <i
                              onClick={handleSearchButton}
                              class="fa fa-search"
                              aria-hidden="true"
                            ></i>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Tooltip title="Filter records">
                    <div>
                      <i
                        style={{
                          fontSize: "30px",
                          marginLeft: "10px",
                          color: "#757575",
                          cursor: "pointer",
                        }}
                        onClick={pophandleClick}
                        class="fa fa-filter"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Tooltip>
                  <DateRangeComp handledates={handledates}> </DateRangeComp>
                  {filter[0].key != "" ? (
                    <Tooltip title="Refresh to records">
                      <div>
                        <i
                          style={{
                            fontSize: "30px",
                            marginLeft: "10px",
                            color: "#757575",
                            cursor: "pointer",
                          }}
                          onClick={ResetFilter}
                          class="fa fa-refresh"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Tooltip>
                  ) : (
                    // {filter[0].key != "" ? (
                    //   <Button
                    //     className="ClearFilter"
                    //     variant="contained"
                    //     startIcon={<CloseRounded />}
                    //     onClick={ResetFilter}
                    //   >
                    //     Clear
                    //   </Button>
                    // <Button
                    //   variant="outlined"
                    //   color="secondary"
                    //   startIcon={<CloseTwoTone />}
                    //   onClick={ResetFilter}
                    //  className="clearfilter"
                    // >
                    //   Clear
                    // </Button>
                    <></>
                  )}
                </div>
              </Grid>
            </div>
          </div>
          <TableContainer
            className={
              data.length > 0 && !loading
                ? "table_container"
                : "table_container table_container_nodata"
            }
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  {/* <TableCell>Order ID</TableCell> */}
                  <TableCell>Transaction ID</TableCell>
                  <TableCell>Refund ID</TableCell>
                  <TableCell>Merchant Name</TableCell>
                  <TableCell>Merchant Number</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Customer Number</TableCell>
                  <TableCell>Order Amount</TableCell>
                  <TableCell>Refunded Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created on</TableCell>
                  <TableCell>Updated on</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row ">
                {data.length > 0 &&
                  data.map((row, index) => (
                    <TableRow style={{ background: "none" }}>
                      {/* <TableCell className="table_id_data">
                        <OrderDetails
                          order_id={row._id}
                          display_order={row.order_id}
                          className={`table_id_data`}
                          ResetFilter={ResetFilter}
                        />
                      </TableCell> */}
                      <TableCell className="tbl_id">
                        <OrderDetails
                          order_id={row._id}
                          display_order={row.transactionId}
                          className={`table_id_data`}
                          ResetFilter={ResetFilter}
                        />
                      </TableCell>
                      <TableCell className="tbl_id">{row.refund_id}</TableCell>

                      {/* <TableCell>{row.status}</TableCell> */}

                      <TableCell>
                        {!row.to_customer_name ? "NA" : row.to_customer_name}
                      </TableCell>
                      <TableCell>{row.toCustomerData?.mobile_no}</TableCell>
                      <TableCell>{!row.from ? "NA" : row.from}</TableCell>
                      <TableCell>
                        {!row.fromMobileNumber ? "NA" : row.fromMobileNumber}
                      </TableCell>
                      <TableCell>
                        {row.amount_str}&nbsp;
                        {row.currency}
                      </TableCell>
                      <TableCell>
                        {row.refunded_amount_str}&nbsp;
                        {row.currency}
                      </TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>
                        {moment(row.createdAt).format("YYYY/MM/DD hh:mm A")}
                      </TableCell>
                      <TableCell>
                        {moment(row.updatedAt).format("YYYY/MM/DD hh:mm A")}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress />
              </div>
            ) : data == "" ? (
              <div className="no_data_found loader_data_tbl">
                <p>No Data Found</p>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          {!loading ? (
            <TablePagination
              className="table_pagination"
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div>
        <Popover
          id={id1}
          open={openpop}
          anchorEl={anchorE2}
          onClose={pophandleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ marginLeft: "135px", height: "500px", marginTop: "10px" }}
        >
          <Typography sx={{ p: 2 }}>
            <Filter
              filter1={handleFilter}
              pophandleClose={pophandleClose}
              optionns={filterOptons}
              contains={filterContains}
              filter={filter}
            />
          </Typography>
        </Popover>
      </div>
    </NavbarHoc>
  );
};

export default RefundList;
