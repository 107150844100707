import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import MainLayout from "../main-layout/main-layout";

function ProtectedRoutes(props) {
  let [sandboxMode, setSandboxMode] = useState(
    typeof window !== "undefined" ? localStorage.getItem("sandbox_mode") : false
  );

  useEffect(() => {
    setSandboxMode(localStorage.getItem("sandbox_mode"));
  }, []);

  const handleMode = () => {
    props.setLoading(true);
    if (sandboxMode) {
      localStorage.removeItem("sandbox_mode");
      setSandboxMode(false);
    } else {
      localStorage.setItem("sandbox_mode", true);
      setSandboxMode(true);
    }
    window.location.reload();
  };

  if(!props.isAuthenticated){
    return  <Navigate to="/login" />
  }
  
  if(props.isAuthenticated && props?.isTemporaryPassword){
    return   <Navigate to="/reset" />
  }

  return (
    <>
    {sandboxMode && (
      <div className="test-mode-warning">
        <span className="test-mode-title">Sandbox mode</span>
        <span className="test-mode-message">
          The sandbox mode is currently active.
        </span>
      </div>
    )}
    <MainLayout sandboxMode={sandboxMode} handleMode={handleMode}>
      <Outlet />
    </MainLayout>
  </>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isTemporaryPassword: state.auth.isTemporaryPassword,
  };
};

export default connect(mapStateToProps)(ProtectedRoutes);
