import React, { useEffect } from "react";
import "./index.css";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CircularProgress from "@mui/material/CircularProgress";

const CssTextField = styled(TextField)({
  "input#new-menuTransaction": {
    height: "5px",
    color: "#666666",
  },
});

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ReceiverVelocity = (props) => {
  const {
    rolePermission,
    errorList,
    controlDisable,
    loader,
    ChangeValue,
    toggleDrawer,
    list,
    state,
  } = props;

  useEffect(() => {}, []);

  const loadToolTip = (message, title, code, value) => {
    if (!controlDisable) {
      return;
    }
    return (
      <React.Fragment>
        <div style={{ margin: "20px 30px 20px 20px" }}>
          <label className="velocity-error-message-heading">
            Transaction Amount*
          </label>
          <br />
          <div className="row velocity-error-message-value">{value || " "}</div>

          <label className="velocity-error-message-heading">Error Code*</label>
          <br />
          <div className="row velocity-error-message-value">{code || " "}</div>

          <label className="velocity-error-message-heading">Title*</label>
          <br />
          <div className="row velocity-error-message-value">{title || " "}</div>

          <label className="velocity-error-message-heading">Body*</label>
          <br />
          <div className="row velocity-error-message-value">{message}</div>
        </div>
      </React.Fragment>
    );
  };

  return loader ? (
    <div className="no_data_found loader_data_tbl">
      <CircularProgress />
    </div>
  ) : (
    <>
      {errorList?.max_amt_receiver?.map((elm, index) => {
        const {
          value,
          error_msg,
          error_code,
          _id,
          status,
          type,
          title,
          helperText,
        } = elm;
        return (
          <div className="fraud_risk_input_div">
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              className="fraud_risk_input_grid velocity-title"
            >
              <p>{title}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              className="fraud_risk_input_grid velocity-type"
            >
              <p>{type}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              className="fraud_risk_input_grid"
            >
              <CssTextField
                className="merchant_list_search_bar"
                id="new-menuTransaction"
                placeholder="Value"
                onChange={(e) => {
                  if (e.target.value) {
                    let val = e.target.value;
                    const isDecimal = val % 1 != 0;
                    if (isDecimal) {
                      val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                    }
                    val && ChangeValue(val, "max_amt_receiver", "", index);
                  } else {
                    ChangeValue("", "max_amt_receiver", "", index);
                  }
                }}
                value={value}
                disabled={controlDisable}
                helperText={helperText ? helperText : ""}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <div className="fraud_risk_toggal_btn">
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={status}
                    onChange={(e) => {
                      ChangeValue(
                        e.target.checked,
                        "max_amt_receiver",
                        "status",
                        index
                      );
                    }}
                  />
                }
                label=""
                disabled={controlDisable || !rolePermission.includes("EDIT")}
              />
            </div>
            <div className="fraud_risk_view_error_msg">
              <div>
                {["right"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Tooltip
                      title={loadToolTip(error_msg, title, error_code, value)}
                      placement="right"
                    >
                      <Button
                        sx={{ textTransform: "none" }}
                        onClick={toggleDrawer(
                          anchor,
                          true,
                          elm,
                          "max_amt_receiver"
                        )}
                      >
                        <p>
                          {controlDisable
                            ? "View Error Message"
                            : "Edit Error Message"}
                        </p>
                      </Button>
                    </Tooltip>
                    <SwipeableDrawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false, {}, "")}
                      onOpen={toggleDrawer(anchor, true, {}, "")}
                    >
                      {list(anchor)}
                    </SwipeableDrawer>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        );
      })}

      {errorList?.transaction_limit_receiver?.map((elm, index) => {
        const {
          value,
          error_msg,
          error_code,
          _id,
          status,
          type,
          title,
          helperText,
        } = elm;
        return (
          <div className="fraud_risk_input_div">
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              className="fraud_risk_input_grid velocity-title"
            >
              <p>{title}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              className="fraud_risk_input_grid velocity-type"
            >
              <p>{type}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              className="fraud_risk_input_grid"
            >
              <CssTextField
                className="merchant_list_search_bar"
                id="new-menuTransaction"
                placeholder="Value"
                onChange={(e) => {
                  if (e.target.value) {
                    let expiryval = e.target.value.match("^[1-9][0-9]*$");
                    expiryval &&
                      ChangeValue(
                        Number(e.target.value),
                        "transaction_limit_receiver",
                        "",
                        index
                      );
                  } else {
                    ChangeValue("", "transaction_limit_receiver", "", index);
                  }
                }}
                value={value}
                disabled={controlDisable}
                helperText={helperText ? helperText : ""}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <div className="fraud_risk_toggal_btn">
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={status}
                    onChange={(e) => {
                      ChangeValue(
                        e.target.checked,
                        "transaction_limit_receiver",
                        "status",
                        index
                      );
                    }}
                  />
                }
                label=""
                disabled={controlDisable || !rolePermission.includes("EDIT")}
              />
            </div>
            <div className="fraud_risk_view_error_msg">
              <div>
                {["right"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Tooltip
                      title={loadToolTip(error_msg, title, error_code, value)}
                      placement="right"
                    >
                      <Button
                        sx={{ textTransform: "none" }}
                        onClick={toggleDrawer(
                          anchor,
                          true,
                          elm,
                          "transaction_limit_receiver"
                        )}
                      >
                        <p>
                          {controlDisable
                            ? "View Error Message"
                            : "Edit Error Message"}
                        </p>
                      </Button>
                    </Tooltip>
                    <SwipeableDrawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(
                        anchor,
                        false,
                        {},
                        "transaction_limit_receiver"
                      )}
                      onOpen={toggleDrawer(
                        anchor,
                        true,
                        {},
                        "transaction_limit_receiver"
                      )}
                    >
                      {list(anchor)}
                    </SwipeableDrawer>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        );
      })}

      <div className="fraud_risk_input_div">
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          className="fraud_risk_input_grid velocity-title"
        >
          <p>{errorList?.min_single_amt_receiver?.title}</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          className="fraud_risk_input_grid velocity-type"
        >
          <p>{errorList?.min_single_amt_receiver?.type}</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          className="fraud_risk_input_grid"
        >
          <CssTextField
            className="merchant_list_search_bar"
            id="new-menuTransaction"
            placeholder="Value"
            onChange={(e) => {
              if (e.target.value) {
                let val = e.target.value;
                const isDecimal = val % 1 != 0;
                if (isDecimal) {
                  val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                }
                val && ChangeValue(val, "min_single_amt_receiver", "", 0);
              } else {
                ChangeValue("", "min_single_amt_receiver", "", 0);
              }
            }}
            value={errorList?.min_single_amt_receiver?.value}
            helperText={
              errorList?.min_single_amt_receiver?.helperText
                ? errorList?.min_single_amt_receiver?.helperText
                : ""
            }
            disabled={controlDisable}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <div className="fraud_risk_toggal_btn">
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={errorList?.min_single_amt_receiver?.status}
                onChange={(e) => {
                  ChangeValue(
                    e.target.checked,
                    "min_single_amt_receiver",
                    "status",
                    0
                  );
                }}
              />
            }
            label=""
            disabled={controlDisable || !rolePermission.includes("EDIT")}
          />
        </div>
        <div className="fraud_risk_view_error_msg">
          <div>
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Tooltip
                  title={loadToolTip(
                    errorList?.min_single_amt_receiver?.error_msg,
                    errorList?.min_single_amt_receiver?.title,
                    errorList?.min_single_amt_receiver?.error_code,
                    errorList?.min_single_amt_receiver?.value
                  )}
                  placement="right"
                >
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={toggleDrawer(
                      anchor,
                      true,
                      errorList?.min_single_amt_receiver,
                      "min_single_amt_receiver"
                    )}
                  >
                    <p>
                      {controlDisable
                        ? "View Error Message"
                        : "Edit Error Message"}
                    </p>
                  </Button>
                </Tooltip>
                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false, {}, "")}
                  onOpen={toggleDrawer(anchor, true, {}, "")}
                >
                  {list(anchor)}
                </SwipeableDrawer>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <div className="fraud_risk_input_div">
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          className="fraud_risk_input_grid velocity-title"
        >
          <p>{errorList?.max_single_amt_receiver?.title}</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          className="fraud_risk_input_grid velocity-type"
        >
          <p>{errorList?.max_single_amt_receiver?.type}</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          className="fraud_risk_input_grid"
        >
          <CssTextField
            className="merchant_list_search_bar"
            id="new-menuTransaction"
            placeholder="Value"
            onChange={(e) => {
              if (e.target.value) {
                let val = e.target.value;
                const isDecimal = val % 1 != 0;
                if (isDecimal) {
                  val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                }
                val && ChangeValue(val, "max_single_amt_receiver", "", 0);
              } else {
                ChangeValue("", "max_single_amt_receiver", "", 0);
              }
            }}
            value={errorList?.max_single_amt_receiver?.value}
            helperText={
              errorList?.max_single_amt_receiver?.helperText
                ? errorList?.max_single_amt_receiver?.helperText
                : ""
            }
            disabled={controlDisable}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <div className="fraud_risk_toggal_btn">
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={errorList?.max_single_amt_receiver?.status}
                onChange={(e) => {
                  ChangeValue(
                    e.target.checked,
                    "max_single_amt_receiver",
                    "status",
                    0
                  );
                }}
              />
            }
            label=""
            disabled={controlDisable || !rolePermission.includes("EDIT")}
          />
        </div>
        <div className="fraud_risk_view_error_msg">
          <div>
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Tooltip
                  title={loadToolTip(
                    errorList?.max_single_amt_receiver?.error_msg,
                    errorList?.max_single_amt_receiver?.title,
                    errorList?.max_single_amt_receiver?.error_code,
                    errorList?.max_single_amt_receiver?.value
                  )}
                  placement="right"
                >
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={toggleDrawer(
                      anchor,
                      true,
                      errorList?.max_single_amt_receiver,
                      "max_single_amt_receiver"
                    )}
                  >
                    <p>
                      {controlDisable
                        ? "View Error Message"
                        : "Edit Error Message"}
                    </p>
                  </Button>
                </Tooltip>
                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false, {}, "")}
                  onOpen={toggleDrawer(anchor, true, {}, "")}
                >
                  {list(anchor)}
                </SwipeableDrawer>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceiverVelocity;
