import React from "react";
import { useState, useEffect } from "react";
import "./Kyc.css";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Switch from "@mui/material/Switch";
import AxiosBase from "../../../networkRequest/axiosBase";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Box, Paper } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import { unstable_styleFunctionSx } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import KycDetail from "./Kyc-Detail/KycDetail";

//side bar table code
const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 45,
  height: 22,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const labelList = [
  {
    value: "ID Document",
    label: "ID Document",
  },
  {
    value: "License",
    label: "License",
  },
  {
    value: "Bank Document",
    label: "Bank Document",
  },
  {
    value: "Tax",
    label: "Tax",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const businessTypeList = [
  {
    value: "llc",
    label: "llc",
  },
  {
    value: "sole_establishment",
    label: "sole_establishment",
  },
  {
    value: "free_zone_llc",
    label: "free_zone_llc",
  },
  {
    value: "free_zone_establishment",
    label: "free_zone_establishment",
  },
];

const Kyc = (props) => {
  const { rolePermission } = props;
  const [spinner, setSpinner] = useState(false);
  const [controlDisable, setControlDisable] = useState(true);

  const [currentSaveCancelId, setCurrentSaveCancelId] = useState("");
  const [currentEditId, setCurrentEditId] = useState("");

  const [kycdata, setKycData] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [state, setState] = React.useState({
    right: false,
  });
  const [rows, setRows] = React.useState([]);
  const [module, setModule] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedKyc, setSelectedKyc] = React.useState({});
  const [kyc, setKyc] = React.useState([]);
  const [monthly, setMonthly] = React.useState({});
  const [selectedCountry, setSelectedCountry] = React.useState({});
  const [selectedBusiness, setSelectedBusiness] = React.useState({});
  const [onBoarding, setOnBoarding] = useState({
    automatic_kyc: false,
  });

  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  let todayDate = new Date();
  useEffect(() => {
    getKycData();
  }, []);

  const getKycData = () => {
    setSpinner(true);
    setControlDisable(true);

    AxiosBase.get(`/admin/app/config`).then((response) => {
      setOnBoarding({
        automatic_kyc: response.data.automatic_kyc,
      });
      setSpinner(false);
    });

    AxiosBase.get(`/admin/country-master`).then((res) => {
      setCountryList(res?.data);
      if (res?.data?.length) {
        let ind = res.data.findIndex((x) => x.mobile_code == "971");
        setSelectedCountry(
          ind != -1 ? res.data[ind].alphaThreeCode : res.data[0].alphaThreeCode
        );
        AxiosBase.get(
          `/admin/kyc/country/${
            ind != -1
              ? res.data[ind].alphaThreeCode
              : res.data[0].alphaThreeCode
          }`
        ).then((res) => {
          setKycData(res?.data);
          if (res?.data.length) {
            setSelectedBusiness(res.data[0].business_type);
          } else {
            setSelectedBusiness(businessTypeList[0].value);
          }
          setSpinner(false);
        });
      }
      setSpinner(false);
    });

    AxiosBase.get(`/admin/modules`).then((res) => {
      setModule(res?.data);
      setSpinner(false);
    });
  };
  const editKyc = (e, id) => {
    setCurrentSaveCancelId(id);
    setCurrentEditId(id);
    setControlDisable(false);
  };

  const deleteKyc = (e, kyc) => {
    setSelectedKyc(kyc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    setSpinner(true);
    AxiosBase.delete(`/admin/kyc/${selectedKyc?._id}`).then((res) => {
      toast("Kyc document deleted successfully", {
        type: "success",
        autoClose: 2000,
      });
      cancelKyc({}, selectedKyc?._id);
    });
  };

  const cancelKyc = (e, id) => {
    setSpinner(true);
    AxiosBase.get(`/admin/kyc/country/${selectedCountry}`).then((res) => {
      setKycData(res?.data);
      if (res?.data.length) {
        setSelectedBusiness(res.data[0].business_type);
      } else {
        setSelectedBusiness(businessTypeList[0].value);
      }
      setCurrentSaveCancelId("");
      setCurrentEditId("");
      setControlDisable(true);
      setSpinner(false);
    });
  };
  const addKyc = () => {
    if (currentEditId == "add_new") {
      return;
    }
    const obj = {
      _id: "add_new",
      name: "",
      document_type: "ID Document",
      country: selectedCountry,
      business_type: selectedBusiness,
      status: true,
    };
    // kycdata.push(obj);
    // setKycData(kycdata);
    setCurrentEditId("add_new");
    setSelectedKyc(obj);
    setState({ ...state, ["right"]: true });
  };
  const saveKyc = (e, _id, kyc) => {
    try {
      if (!kyc?.name || kyc?.name == "") {
        return toast("name is required", {
          type: "error",
          autoClose: 2000,
        });
      }
      setSpinner(true);
      kyc.country = selectedCountry;
      if (_id == "add_new") {
        delete kyc._id;
        AxiosBase.post("/admin/kyc", kyc)
          .then((res) => {
            if (res?.status == 200) {
              if (res?.data?._id !== "") {
                setCurrentSaveCancelId("");
                setCurrentEditId("");
                setControlDisable(true);
                cancelKyc({}, selectedKyc._id);
                return toast("A new KYC configuration added successfully.", {
                  type: "success",
                  autoClose: 2000,
                });
              }
            } else {
              setCurrentSaveCancelId("");
              setCurrentEditId("");
              setControlDisable(true);
              setSpinner(false);
              return toast(JSON.stringify(res?.response?.data), {
                type: "error",
                autoClose: 2000,
              });
            }
          })
          .catch((error) => {
            setSpinner(false);
            getKycData();
            return toast(
              error?.response?.data?.message || "Something went wrong!",
              {
                type: "error",
                autoClose: 2000,
              }
            );
          });
      } else {
        AxiosBase.patch("/admin/kyc/" + _id, kyc)
          .then((res) => {
            if (res?.status == 200) {
              if (res?.data?._id !== "") {
                setCurrentSaveCancelId("");
                setCurrentEditId("");
                setControlDisable(true);
                cancelKyc({}, selectedKyc._id);
                return toast("KYC configuration Updated successfully.", {
                  type: "success",
                  autoClose: 2000,
                });
              }
            } else {
              setCurrentSaveCancelId("");
              setCurrentEditId("");
              setControlDisable(true);
              setSpinner(false);
              return toast(JSON.stringify(res?.response?.data), {
                type: "error",
                autoClose: 2000,
              });
            }
          })
          .catch((error) => {
            setSpinner(false);
            getKycData();
            return toast(
              error?.response?.data?.message || "Something went wrong!",
              {
                type: "error",
                autoClose: 2000,
              }
            );
          });
      }
    } catch (err) {
      setCurrentSaveCancelId("");
      setCurrentEditId("");
      setControlDisable(true);
      setSpinner(false);
      return toast(JSON.stringify(err), {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  const handleClick = (event, name, index) => {
    let innerrow = rows;
    let innerSelected = selected;
    let innerindex = page * rowsPerPage + index;
    innerrow[innerindex].isSelected = !innerrow[innerindex].isSelected;
    if (!innerrow[innerindex].isSelected) {
      innerSelected = innerSelected.filter((fact) => {
        return fact != name._id;
      });
    } else {
      innerSelected.push(name._id);
    }
    setRows([...innerrow]);
    setSelected([...innerSelected]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const success = (anchor) => {
    const index = kycdata?.findIndex((x) => x._id == selectedKyc?._id);
    setState({ ...state, [anchor]: false });
    saveKyc({}, kycdata[index]?._id, kycdata[index]);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    cancelKyc({}, selectedKyc?._id);
  };

  const detailKyc = (anchor, open, selected) => {
    setSelectedKyc(selected);
    setState({ ...state, [anchor]: open });
  };

  const onSubmitKyc = (p) => {
    setState({ ...state, ["right"]: false });
    saveKyc({}, p._id, p);
  };

  const onChangeCountry = (country) => {
    setSelectedCountry(country);
    setSpinner(true);
    AxiosBase.get(`/admin/kyc/country/${country}`).then((res) => {
      setKycData(res?.data);
      if (res?.data.length) {
        setSelectedBusiness(res.data[0].business_type);
      } else {
        setSelectedBusiness(businessTypeList[0].value);
      }
      setSpinner(false);
    });
  };

  const onChangeBusiness = (business) => {
    setSelectedBusiness(business);
    setSpinner(true);
    AxiosBase.post(`/admin/kyc/change-business/${selectedCountry}`, {
      business: business,
    }).then((res) => {
      setSpinner(false);
    });
  };

  const handleChange = (event) => {
    try {
      setSpinner(true);
      setOnBoarding({
        automatic_kyc: event.target.checked,
      });
      AxiosBase.patch("/admin/app/config", {
        automatic_kyc: event.target.checked,
      }).then((response) => {
        if (response?.status == 200) {
          if (response?.data?.message === "App Config Updated Successfully!") {
            setSpinner(false);
            setControlDisable(false);
            return toast(response?.data?.message, {
              type: "success",
              autoClose: 2000,
            });
          }
        } else {
          setSpinner(false);
          setControlDisable(false);
          return toast(JSON.stringify(response?.response?.data), {
            type: "error",
            autoClose: 2000,
          });
        }
      });
    } catch (err) {
      setSpinner(false);
      setControlDisable(false);
      return toast(JSON.stringify(err), {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  const list = (anchor) => (
    <Box
      className="kyc_detail_box"
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
        overflowX: "hidden",
      }}
      role="presentation"
    >
      <List>
        <ListItem className="merchant_edit_drower_menu_top_sec">
          <p>Configure document</p>
        </ListItem>
      </List>
      <Divider />
      {
        <>
          <div className="kyc_array">
            {
              <KycDetail
                SelectedKyc={selectedKyc}
                onSubmitKyc={onSubmitKyc}
                toggleDrawer={toggleDrawer}
              />
            }
          </div>
        </>
      }
    </Box>
  );

  return (
    <>
      <div className="kyc_kyc_top_div">
        {spinner ? (
          <div className="no_data_found loader_data_tbl">
            <CircularProgress style={{ color: "#1058ff" }} />
          </div>
        ) : (
          <>
            <div
              className="Fees_Margin_title_div"
              style={{ position: "relative" }}
            >
              <p>KYC</p>
              {rolePermission.includes("ADD") && (
                <Link
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "16px",
                  }}
                >
                  <Button
                    className="merchant_list_add_new_merchant"
                    variant="contained"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    onClick={(e) => {
                      addKyc();
                    }}
                  >
                    Add
                  </Button>
                </Link>
              )}
            </div>
            <div className="Fees_Margin_input_field">
              <label className="auto-kyc-label">Automatic KYC</label>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec"
              >
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={onBoarding.automatic_kyc || false}
                  onChange={handleChange}
                />
              </Grid>
            </div>
            <div className="merchant_list_search_filter kyc-list">
              <Grid
                item
                xs={12}
                // sm={12}
                md={12}
                lg={12}
                // xl={4}
                className="kyc-filter"
              >
                <div
                  className="merchant_list_filter_div"
                  style={{ height: "100%" }}
                >
                  <Select
                    name="key1"
                    labelId="country-lbl"
                    label=""
                    id="country"
                    value={selectedCountry}
                    style={{ width: "50%" }}
                    onChange={(e) => {
                      onChangeCountry(e.target.value);
                    }}
                  >
                    {countryList &&
                      countryList.map((c) => {
                        return (
                          <MenuItem key={c._id} value={c.alphaThreeCode}>
                            {c.country_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <Select
                    name="key1"
                    labelId="country-lbl"
                    label=""
                    id="country"
                    value={selectedBusiness}
                    style={{ width: "50%", "margin-left": "5px" }}
                    onChange={(e) => {
                      onChangeBusiness(e.target.value);
                    }}
                  >
                    {businessTypeList &&
                      businessTypeList.map((c) => {
                        return (
                          <MenuItem key={c.value} value={c.value}>
                            {c.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>
              </Grid>
              <Grid
                item
                xs={4}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className="merchant_grid_sec"
                style={{ display: "contents" }}
              ></Grid>
            </div>
            <div className="add_merchant_list"></div>
            <div className="kyc_kyc_input_div">
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                xl={1}
                className="kyc_kyc_input_grid th_heading doc-name-cell"
              >
                <p>{"Document Name"}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                xl={1}
                className="kyc_kyc_input_grid th_heading"
              >
                <p>{"Required"}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                xl={1}
                className="kyc_kyc_input_grid"
              >
                <p>{"Image Quality Check"}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                xl={1}
                className="kyc_kyc_input_grid th_heading"
              >
                <p>{"Data Extraction"}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                xl={1}
                className="kyc_kyc_input_grid th_heading"
              >
                <p>{"ID_Classification"}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                xl={1}
                className="kyc_kyc_input_grid th_heading"
              >
                <p>{"Active"}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                lg={1}
                xl={1}
                className="kyc_kyc_input_grid th_heading"
              >
                <p>{"Action"}</p>
              </Grid>
            </div>
            {labelList.map((lb) => {
              const filterArr = kycdata.filter(
                (x) => x.document_type == lb.value
              );
              return (
                filterArr.length != 0 && (
                  <>
                    {
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={1}
                        lg={1}
                        xl={1}
                        className="kyc_kyc_input_grid kyc_row_name document_heading"
                      >
                        <p>{lb.value}</p>
                      </Grid>
                    }
                    {filterArr.map((elm, index) => {
                      const { _id } = elm;
                      return (
                        <div key={_id} className="kyc_kyc_input_div kyc_row">
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                            xl={1}
                            className="kyc_kyc_input_grid kyc_row_name doc-name doc-name-cell"
                          >
                            <p>{elm.name}</p>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                            xl={1}
                            className="kyc_kyc_input_grid"
                          >
                            <p>{elm.is_required ? "Yes" : "No"}</p>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                            xl={1}
                            className="kyc_kyc_input_grid kyc_row_name"
                          >
                            <p>{elm.image_quality ? "Yes" : "No"}</p>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                            xl={1}
                            className="kyc_kyc_input_grid kyc_row_name"
                          >
                            <p>{elm.identities_extractions ? "Yes" : "No"}</p>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                            xl={1}
                            className="kyc_kyc_input_grid kyc_row_name"
                          >
                            <p>{elm.id_classification ? "Yes" : "No"}</p>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                            xl={1}
                            className="kyc_kyc_input_grid kyc_row_name"
                          >
                            <p>{elm.status ? "Yes" : "No"}</p>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={1}
                            lg={1}
                            xl={1}
                            className="kyc_kyc_second_grid_sec action-cell"
                          >
                            <div className="kyc_kyc_edit_button_dev kyc_kyc_drawer_save_cancel_btn">
                              {["right"].map((anchor) => (
                                <React.Fragment key={anchor}>
                                  <Button
                                    className="kyc_detailed_btn"
                                    onClick={(e) => {
                                      detailKyc(anchor, true, elm);
                                    }}
                                    variant="contained"
                                    startIcon={
                                      <img src="/edit-icon-white.svg" alt="" />
                                    }
                                    title="Detail"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </Button>
                                </React.Fragment>
                              ))}
                              <span
                                className={
                                  currentSaveCancelId === _id
                                    ? "visible"
                                    : "hidden"
                                }
                              >
                                <Button
                                  id={"BtnCancel_" + _id}
                                  name={"BtnCancel_" + _id}
                                  className="kyc_cancel_btn"
                                  variant="contained"
                                  title="Cancel"
                                  onClick={(e) => {
                                    cancelKyc(e, _id);
                                  }}
                                >
                                  <i className="fa fa-times"></i>
                                </Button>
                              </span>
                              <span
                                className={
                                  currentEditId !== _id ? "visible" : "hidden"
                                }
                              >
                                <Button
                                  id={"BtnEdit_" + _id}
                                  name={"BtnEdit_" + _id}
                                  className="kyc_delete_btn"
                                  variant="contained"
                                  title="Delete Kyc"
                                  onClick={(e) => {
                                    deleteKyc(e, elm);
                                  }}
                                >
                                  <i class="fa fa-trash"></i>
                                </Button>
                              </span>
                            </div>
                          </Grid>
                        </div>
                      );
                    })}
                  </>
                )
              );
            })}
          </>
        )}
      </div>
      <div>
        {["right"].map((anchor) => (
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        ))}
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              {
                <>
                  {" "}
                  <p>Confirm Delete</p>
                  <span>Are you sure want to delete this document?</span>
                  <div className="order_detail_refund_popup_btn">
                    <Button
                      className="refund_save_btn"
                      variant="contained"
                      autoFocus
                      fullWidth
                      onClick={handleDelete}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="refund_cancel_btn"
                      variant="contained"
                      autoFocus
                      onClick={handleClose}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              }
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default Kyc;
