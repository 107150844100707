import React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import "./Gateway.css";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import AxiosBase from "../../../networkRequest/axiosBase";
import { toast } from "react-toastify";
import axios from "axios";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "5px",
  },
});
const Gateway = (props) => {
  const { rolePermission } = props;
  const [spinner, setSpinner] = useState(false);
  const [currencyArray, setCurrencyArray] = useState([]);
  const [success, setSuccess] = useState(false);
  const [controlDisable, setControlDisable] = useState(true);
  const [editVisibility, setEditVisibility] = useState("visible");
  const [saveCancelVisibility, setSaveCancelVisibility] = useState("hidden");
  //const [isChecked, setIsChecked] = useState(false);
  const [id, setId] = useState("");
  const [gatewaydata, setGatewayData] = useState({
    name: "",
    margin: 0,
    fixed_fees: 0,
    collection_currency: "",
    settlement_currency: "",
    cross_border_charges: "",
    cross_border_fixed_fees: 0,
    vat: 0,
    isActive: false,
  });
  //const [currencyOption,setCurrencyOption]=useState("AED","INR","USD");
  useEffect(() => {
    setSpinner(true);
    setControlDisable(true);
    AxiosBase.get(`/admin/country-master`).then((res) => {
      let Innerdata = res?.data ? res?.data : [];
      Innerdata = Innerdata.filter((fact) => fact.is_active);
      setCurrencyArray(Innerdata);
    });
    AxiosBase.get(`/admin/getaway`).then((res) => {
      let gateway = res?.data?.data;
      setGatewayData({
        name: gateway.name,
        margin: gateway.margin,
        fixed_fees: gateway.fixed_fees,
        collection_currency: gateway.collection_currency,
        settlement_currency: gateway.settlement_currency,
        cross_border_charges: gateway.cross_border_charges,
        cross_border_fixed_fees: gateway.cross_border_fixed_fees,
        vat: gateway.vat,
        isActive: gateway.isActive,
      });
      //setIsChecked((gateway.cross_border_charges=="Yes")?true:false);
      setId(gateway._id);
      setSpinner(false);
    });
  }, []);
  const editGateway = () => {
    setSaveCancelVisibility("visible");
    setEditVisibility("hidden");
    setControlDisable(false);
  };
  const editGatewayText = () => {
    if (rolePermission.includes("EDIT")) {
      if (controlDisable) {
        setSaveCancelVisibility("visible");
        setEditVisibility("hidden");
        setControlDisable(false);
      } else {
        setSaveCancelVisibility("hidden");
        setEditVisibility("visible");
        setControlDisable(true);
      }
    }
  };
  const cancelGateway = () => {
    setSpinner(true);
    AxiosBase.get(`/admin/getaway`).then((res) => {
      let gateway = res?.data?.data;
      setGatewayData({
        name: gateway.name,
        margin: gateway.margin,
        fixed_fees: gateway.fixed_fees,
        collection_currency: gateway.collection_currency,
        settlement_currency: gateway.settlement_currency,
        cross_border_charges: gateway.cross_border_charges,
        cross_border_fixed_fees: gateway.cross_border_fixed_fees,
        vat: gateway.vat,
        isActive: gateway.isActive,
      });
      //  setIsChecked(gateway.cross_border_charges=="Yes"?true:false);
      setSaveCancelVisibility("hidden");
      setEditVisibility("visible");
      setControlDisable(true);
      setSpinner(false);
    });
  };

  const saveGateway = () => {
    try {
      setSpinner(true);
      AxiosBase.patch("/admin/getaway/" + id, gatewaydata).then((res) => {
        if (res?.status == 200) {
          let gateway = res?.data?.data;
          setGatewayData({
            name: gateway.name,
            margin: gateway.margin,
            fixed_fees: gateway.fixed_fees,
            collection_currency: gateway.collection_currency,
            settlement_currency: gateway.settlement_currency,
            cross_border_charges: gateway.cross_border_charges,
            cross_border_fixed_fees: gateway.cross_border_fixed_fees,
            vat: gateway.vat,
            isActive: gateway.isActive,
          });
          setId(gateway._id);
          setSuccess(true);
          setSaveCancelVisibility("hidden");
          setEditVisibility("visible");
          setControlDisable(true);
          setSpinner(false);
          return toast("Updated successfully!", {
            type: "success",
            autoClose: 2000,
          });
        }
      });
    } catch (err) {
      setSaveCancelVisibility("hidden");
      setEditVisibility("visible");
      setControlDisable(true);
      setSpinner(false);
      return toast(JSON.stringify(err), {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  const handleChange = (event) => {
    setGatewayData({
      ...gatewaydata,
      cross_border_charges: event.target.checked,
      cross_border_fixed_fees: event.target.checked
        ? gatewaydata.cross_border_fixed_fees
        : 0,
      collection_currency:
        gatewaydata.collection_currency === gatewaydata.settlement_currency
          ? ""
          : gatewaydata.collection_currency,
    });
  };

  const handleActiveChange = (event) => {
    if (gatewaydata.length > 1) {
      setGatewayData({ ...gatewaydata, isActive: event.target.checked });
      setSpinner(true);
      AxiosBase.patch("/admin/getaway/" + id, {
        name: gatewaydata.name,
        margin: gatewaydata.margin,
        fixed_fees: gatewaydata.fixed_fees,
        collection_currency: gatewaydata.collection_currency,
        settlement_currency: gatewaydata.settlement_currency,
        cross_border_charges: gatewaydata.cross_border_charges,
        cross_border_fixed_fees: gatewaydata.cross_border_fixed_fees,
        vat: gatewaydata.vat,
        isActive: event.target.checked,
      }).then((res) => {
        if (res?.status == 200) {
          setSpinner(false);
          cancelGateway();
          return toast("Updated successfully!", {
            type: "success",
            autoClose: 2000,
          });
        }
      });
    } else {
      toast("To deactivate, Atleast you have 2 or more gateway settings!", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      <div className="gatway_main_div">
        <div>
          <div className="gateway_label_div">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="gatway_toggal_btn">
                <Switch
                  checked={gatewaydata.isActive || false}
                  label=""
                  onChange={handleActiveChange}
                  disabled={!rolePermission.includes("EDIT") || controlDisable}
                />
              </div>
            </div>
          </div>
          <div className="gatway_grid_feild_div">
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              lg={2}
              xl={2}
              className="gatway_second_grid_sec"
            >
              <p>Name</p>
              <CssTextField
                className="merchant_list_search_bar"
                id="new-menu"
                placeholder="Name"
                variant="outlined"
                fullWidth
                disabled
                value={gatewaydata.name || "No Name"}
                onChange={(e) => {
                  if (e.target.value.length > 0 && e.target.value.length < 100)
                    setGatewayData({ ...gatewaydata, name: e.target.value });
                  else setGatewayData({ ...gatewaydata, name: "" });
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              lg={2}
              xl={2}
              className="gatway_second_grid_sec"
            >
              <p>Margin%</p>
              <CssTextField
                className="merchant_list_search_bar"
                id="new-menu"
                placeholder="Margin%"
                variant="outlined"
                fullWidth
                disabled={controlDisable}
                value={gatewaydata.margin || 0}
                onChange={(e) => {
                  if (!Object.is(NaN, parseInt(e.target.value))) {
                    let val = parseInt(e.target.value);
                    if (val >= 0 && val <= 100) {
                      setGatewayData({ ...gatewaydata, margin: val });
                    }
                  } else {
                    setGatewayData({ ...gatewaydata, margin: 0 });
                  }
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              lg={2}
              xl={2}
              className="gatway_second_grid_sec"
            >
              <p>Fixed Fees</p>
              <CssTextField
                className="merchant_list_search_bar"
                id="new-menu"
                placeholder="Fixed Fees"
                variant="outlined"
                fullWidth
                value={gatewaydata.fixed_fees || 0}
                disabled={controlDisable}
                onChange={(e) => {
                  if (!Object.is(NaN, parseInt(e.target.value))) {
                    let val = parseInt(e.target.value);
                    if (val >= 0 && val <= 999999) {
                      setGatewayData({ ...gatewaydata, fixed_fees: val });
                    }
                  } else {
                    setGatewayData({ ...gatewaydata, fixed_fees: 0 });
                  }
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              lg={2}
              xl={2}
              className="gatway_second_grid_sec"
            >
              <p>Collection Currency</p>
              <FormControl sx={{ m: 1, minWidth: 130 }}>
                <InputLabel id="collection_currency-lbl"></InputLabel>
                <Select
                  name="key1"
                  labelId="collection_currency-lbl"
                  fullwidth
                  label=""
                  id="collection_currency"
                  value={gatewaydata.collection_currency}
                  disabled={controlDisable}
                  onChange={(event) => {
                    if (
                      !gatewaydata.cross_border_charges ||
                      event.target.value !== gatewaydata.settlement_currency
                    ) {
                      setGatewayData({
                        ...gatewaydata,
                        collection_currency: event.target.value,
                      });
                    } else {
                      return toast(
                        "System currency and Collection Currency should be different.",
                        {
                          type: "error",
                          autoClose: 5000,
                        }
                      );
                    }
                  }}
                >
                  {currencyArray.map((fact) => {
                    return (
                      <MenuItem
                        key={fact.currency_code}
                        value={fact.currency_code}
                      >
                        {fact.currency_code}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </div>
          <div className="gateway_second_input_div">
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              lg={2}
              xl={2}
              className="gatway_second_grid_sec"
            >
              <p>Settlement Currency</p>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="settlement_currency-lbl"></InputLabel>
                <Select
                  name="key2"
                  labelId="settlement_currency-lbl"
                  label=""
                  id="settlement_currency"
                  value={gatewaydata.settlement_currency}
                  disabled={controlDisable}
                  onChange={(event) => {
                    if (
                      !gatewaydata.cross_border_charges ||
                      event.target.value !== gatewaydata.collection_currency
                    ) {
                      setGatewayData({
                        ...gatewaydata,
                        settlement_currency: event.target.value,
                      });
                    } else {
                      return toast(
                        "System currency and Collection Currency should be different.",
                        {
                          type: "error",
                          autoClose: 5000,
                        }
                      );
                    }
                  }}
                >
                  {currencyArray.map((fact) => {
                    return (
                      <MenuItem
                        key={fact.currency_code}
                        value={fact.currency_code}
                      >
                        {fact.currency_code}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              lg={2}
              xl={2}
              className="gatway_second_grid_sec"
            >
              <p>Cross Border</p>
              <CssTextField
                className="merchant_list_search_bar"
                id="new-menu"
                placeholder="Cross Border Fixed Fees"
                variant="outlined"
                fullWidth
                value={gatewaydata.cross_border_fixed_fees || 0}
                disabled={controlDisable || !gatewaydata.cross_border_charges}
                onChange={(e) => {
                  if (!Object.is(NaN, parseInt(e.target.value))) {
                    let val = parseInt(e.target.value);
                    if (val >= 0 && val <= 999999) {
                      setGatewayData({
                        ...gatewaydata,
                        cross_border_fixed_fees: val,
                      });
                    }
                  } else {
                    setGatewayData({
                      ...gatewaydata,
                      cross_border_fixed_fees: 0,
                    });
                  }
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              lg={2}
              xl={2}
              className="gatway_second_grid_sec"
            >
              <p>Cross Border Fixed Fees</p>
              <div className="gatway_toggal_btn">
                <Switch
                  checked={gatewaydata.cross_border_charges}
                  disabled={controlDisable}
                  //label={gatewaydata.cross_border_charges ? "Yes" : "No"}
                  onChange={handleChange}
                />
              </div>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              lg={2}
              xl={2}
              className="gatway_second_grid_sec"
            >
              <p>VAT</p>
              <CssTextField
                className="merchant_list_search_bar"
                id="new-menu"
                placeholder="VAT"
                variant="outlined"
                fullWidth
                value={gatewaydata.vat || 0}
                disabled={controlDisable}
                onChange={(e) => {
                  if (!Object.is(NaN, parseInt(e.target.value))) {
                    let val = parseInt(e.target.value);
                    if (val >= 0 && val <= 100) {
                      setGatewayData({ ...gatewaydata, vat: val });
                    }
                  } else {
                    setGatewayData({ ...gatewaydata, vat: 0 });
                  }
                }}
              />
            </Grid>
          </div>
        </div>
        <div className="merchant_edit_button_dev Fees_Margin_save_cancel_btn edit_button_set">
          <span className={saveCancelVisibility}>
            <Button
              className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn "
              variant="contained"
              onClick={cancelGateway}
            >
              Cancel
            </Button>
            <Button
              className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
              variant="contained"
              onClick={saveGateway}
            >
              Save
            </Button>
          </span>
          <span className={editVisibility}>
            {rolePermission.includes("EDIT") && (
              <Button
                className="merchant_save_btn merchant_detail_tab_save-cancel_btn "
                variant="contained"
                onClick={editGateway}
              >
                Edit
              </Button>
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default Gateway;
