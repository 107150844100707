import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import AxiosBase from "../../../networkRequest/axiosBase";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import "./RoleFunctionality.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useNavigate } from "react-router-dom";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    value="checkedA"
    inputProps={{ "aria-label": "Switch A" }}
  />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme?.palette?.mode === "dark" ? "#2ECA45" : "#00C9A5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme?.palette?.mode === "light"
          ? theme?.palette?.grey[100]
          : theme?.palette?.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme?.transitions?.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});
const UserRole = (props) => {
  const { rolePermission } = props;
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedIds, setselectedIds] = React.useState([]);
  const [selectedMenuIds, setMenuselectedIds] = React.useState([]);
  const [roledeatils, setroledeatils] = React.useState({ is_active: false });
  const [searchVal, setSearchVal] = React.useState("");
  const history = useNavigate();

  useEffect(() => {
    AxiosBase.get(
      `/admin/menus?sortOrder=asc` + `&search=${searchVal ? searchVal : ""}`
    ).then((response) => {
      let innerdata = response.data.data.filter(
        (data) => data.menu_type == "MAIN_MENU"
      );
      setData(innerdata);
      setLoading(false);
    });
  }, [searchVal]);

  const handleChange = (e, code) => {
    let id = e;
    let data1 = [...selectedIds];
    let data2 = { ...selectedMenuIds };
    let dataval = JSON.parse(JSON.stringify(data));
    if (data1.indexOf(id) == -1) {
      let innerData = dataval.filter((fact) => fact._id == id);
      innerData = innerData[0];
      data1.push(id);
      if (innerData.subMenuData.length == 0) {
        let obj = {};
        let obj1 = { ...innerData };
        if (id) {
          delete obj1?.userData;
          obj[obj1._id] = {
            ...obj1,
            ...{ main_menu_id: id, sub_menu_id: id, code },
          };
          data2 = { ...data2, ...obj };
        }
      } else {
        let obj = {};
        let obj1 = { ...innerData };
        if (id) {
          delete obj1?.userData;
          obj[obj1._id] = {
            ...obj1,
            ...{ main_menu_id: id, sub_menu_id: id, code },
          };
          data2 = { ...data2, ...obj };
        }
        innerData.subMenuData.map((innFact) => {
          data1.push(innFact._id);
          let obj = {};
          let obj1 = { ...innFact };
          if (id) {
            delete obj1?.userData;
            obj[obj1._id] = {
              ...obj1,
              ...{
                main_menu_id: id,
                sub_menu_id: innFact._id,
                code: innFact.code,
              },
            };
            data2 = { ...data2, ...obj };
          }
        });
      }
    } else {
      let innerData = dataval.filter((fact) => fact._id == id);
      innerData = innerData[0];
      if (innerData.subMenuData.length !== 0) {
        innerData.subMenuData.map((innfact) => {
          var index = data1.indexOf(innfact._id);
          data1.splice(index, 1);
          delete data2[innfact._id];
        });
      }
      var index = data1.indexOf(id);
      if (index !== -1) {
        data1.splice(index, 1);
        delete data2[id];
      }
    }
    setMenuselectedIds(data2);
    setselectedIds(data1);
  };

  const handleChange1 = (e, id, check, outerindex, mainId, code) => {
    let val = e;
    let data3 = { ...selectedMenuIds };
    let data1 = [...selectedIds];
    let dataval = JSON.parse(JSON.stringify(data));

    if (check) {
      if (data3[id]?.permissions.indexOf(val) == -1) {
        if (data3[id]) {
          data3[id].permissions.push(val);
        }
      } else {
        if (data3[id]) {
          var index = data3[id].permissions.indexOf(val);
          if (index !== -1) {
            data3[id].permissions.splice(index, 1);
            if (data3[id].permissions.length == 0) {
              delete data3[id];
              let innerInder = data1.indexOf(id);
              data1.splice(innerInder, 1);
            }
          }
        } else {
          data3[id] = {
            permissions: [val],
            main_menu_id: id,
            sub_menu_id: id,
            _id: id,
          };
          data1.push(id);
        }
      }
    } else {
      if (data3[id]) {
        if (data3[id].permissions.indexOf(val) == -1) {
          data3[id].permissions.push(val);
          data3[id].code = code;
        } else {
          var index = data3[id].permissions.indexOf(val);
          if (index !== -1) {
            data3[id].permissions.splice(index, 1);
            if (data3[id].permissions.length == 0) {
              delete data3[id];
              let innerInder = data1.indexOf(id);
              data1.splice(innerInder, 1);
              let mainIndex = dataval.filter((val) => mainId == val._id);
              mainIndex = mainIndex[0];
              let innerflag = true;
              for (let k = 0; k < mainIndex.subMenuData.length; k++) {
                if (data3[mainIndex.subMenuData[k]._id]) {
                  innerflag = false;
                }
              }
              if (innerflag) {
                delete data3[mainId];
                let innerInder = data1.indexOf(mainId);
                data1.splice(innerInder, 1);
              }
            }
          }
        }
      } else {
        let mainIndex = dataval.filter((val) => mainId == val._id);
        mainIndex = mainIndex[0];
        mainIndex = mainIndex.subMenuData[outerindex];
        let obj1 = { ...mainIndex };
        obj1.permissions = [val];
        data3[obj1._id] = {
          ...obj1,
          ...{ main_menu_id: id, sub_menu_id: obj1._id, code },
        };
        data1.push(obj1._id);
      }
    }
    setMenuselectedIds(data3);
    setselectedIds(data1);
  };

  const saveData = async (e) => {
    var menu_permissions = [];
    for (var key in selectedMenuIds) {
      menu_permissions.push(selectedMenuIds[key]);
    }
    let roledata = JSON.parse(JSON.stringify(roledeatils));
    roledata.menu_permissions = menu_permissions;
    setroledeatils({ ...roledata });
    props.roleinfo(roledata);
  };

  useEffect(() => {
    setroledeatils(props?.data);
    let menus = [];
    let menusPermisssion = {};
    if (props.data) {
      props.data?.menu_permissions?.map((rows) => {
        if (rows.main_menu_id == rows.sub_menu_id) {
          menus.push(rows.main_menu_id);
          var obj = {};
          obj[rows.main_menu_id] = rows;
          if (rows.main_menu_id) {
            menusPermisssion = { ...menusPermisssion, ...obj };
          }
        } else {
          menus.push(rows.sub_menu_id);
          var obj = {};
          obj[rows.sub_menu_id] = rows;
          if (rows.sub_menu_id) {
            menusPermisssion = { ...menusPermisssion, ...obj };
          }
        }
      });
      setselectedIds(menus);
      setMenuselectedIds({ ...selectedMenuIds, ...menusPermisssion });
    }
  }, [props.data]);

  return (
    <>
      <div className="user_role_main_div">
        <Grid item xs={4}>
          <p className="RoleHeader">
            Role Name<span>*</span>
          </p>
        </Grid>
        <Grid item xs={8}>
          <CssTextField
            id="new-menu"
            className="create_merchant_input_feild"
            variant="outlined"
            placeholder="Role name"
            fullWidth
            value={roledeatils?.role_name}
            InputProps={{
              startAdornment: (
                <InputAdornment className="create_merchn_inpt_icon">
                  <IconButton color="primary">
                    <PersonOutlinedIcon className="merchant_inpt_icon" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setroledeatils({
                ...roledeatils,
                role_name: e.target.value,
              });
            }}
          />
        </Grid>
        <div>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            className="merchant_grid_sec"
          >
            <CssTextField
              className=""
              id="new-menu"
              placeholder="search"
              variant="outlined"
              fullWidth
              value={searchVal}
              onChange={(e) => {
                setSearchVal(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <IconButton className="merchant_search_icon1">
                      <img src="/search.svg" alt="" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </div>

        <Grid item xs={4}>
          <p className="RoleHeader">Status</p>
        </Grid>
        <Grid item xs={8}>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            value={roledeatils?.is_active == true ? "Active" : "InActive"}
            name="row-radio-buttons-group"
            onChange={(e) => {
              setroledeatils({
                ...roledeatils,
                is_active: e.target.value == "Active" ? true : false,
              });
            }}
          >
            <FormControlLabel
              value="Active"
              control={<Radio />}
              label="Active"
            />
            <FormControlLabel
              value="InActive"
              control={<Radio />}
              label="InActive"
            />
            {/* <FormControlLabel disabled={true} value="Banned" control={<Radio />} label="Banned" /> */}
          </RadioGroup>
        </Grid>
        {loading ? (
          <div className="no_data_found loader_data_tbl">
            <CircularProgress />
          </div>
        ) : data == "" ? (
          <div className="no_data_found loader_data_tbl">
            <p>No Data Found</p>
          </div>
        ) : (
          ""
        )}
        {data.map((row) => (
          <div style={{ marginTop: "1%" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="">
              <div className="role_box_div">
                <div className="user_role_check_box">
                  <span
                    className="assign check_box_name"
                    style={{ textTransform: "uppercase", color: "black" }}
                  >
                    {row.title}
                  </span>

                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={selectedIds.includes(row._id) ? true : false}
                    onChange={(e) => handleChange(row._id, row.code)}
                  />
                </div>
              </div>
            </Grid>

            {row.subMenuData.length == 0
              ? row.permissions.map((d) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className=""
                  >
                    <div className="Inner_user_role_check_box">
                      <span
                        className="assign check_box_name"
                        style={{ textTransform: "uppercase", color: "black" }}
                      >
                        {d + " " + row.title}
                      </span>
                      <IOSSwitch
                        value={d}
                        sx={{ m: 1 }}
                        checked={
                          selectedMenuIds[row._id]?.permissions?.includes(d)
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          handleChange1(d, row._id, true, 0, 0, row.code)
                        }
                      />
                    </div>
                  </Grid>
                ))
              : row.subMenuData.map((ei, innerIndex) =>
                  ei.permissions.map((d) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className=""
                    >
                      <div className="Inner_user_role_check_box">
                        <span
                          className="assign check_box_name"
                          style={{ textTransform: "uppercase", color: "black" }}
                        >
                          {d + " " + ei.title}
                        </span>
                        <IOSSwitch
                          value={d}
                          sx={{ m: 1 }}
                          checked={
                            selectedMenuIds[ei._id]?.permissions?.includes(d)
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleChange1(
                              d,
                              ei._id,
                              false,
                              innerIndex,
                              row._id,
                              ei.code
                            )
                          }
                        />
                      </div>
                    </Grid>
                  ))
                )}
          </div>
        ))}

        <div className="merchant_edit_button_dev">
          <Button
            className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
            variant="contained"
            onClick={() => history("/role")}
          >
            Cancel
          </Button>
          {rolePermission.includes(props.roleId ? "EDIT" : "ADD") && (
            <Button
              className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
              variant="contained"
              onClick={(event) => saveData(event)}
            >
              {props.roleId ? "Update" : "Save"}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default UserRole;
