import React from "react";
import { useState, useEffect } from "react";
import "./TDRSettings.css";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const TDRData = [
  { Card: "Credit Card", type: "creditcards", Icon: "/creditCards.svg" },
  { Card: "Debit Card", type: "debitcards", Icon: "/creditCards.svg" },
  { Card: "UPI", type: "upis", Icon: "/upi.svg" },
  { Card: "Wallet", type: "wallets", Icon: "/wallet.svg" },
  { Card: "Net Banking", type: "banks", Icon: "/net-banking.svg" },
  { Card: "Apple Pay", type: "applepay", Icon: "/Apple Pay.svg" },
  { Card: "Google Pay", type: "googlepay", Icon: "/G pay.svg" },
];
const TDRSettings = (props) => {
  const {
    rolePermission,
    allStatusCodeMapping,
    id,
    AddAcquirerProp,
    UpdateAcquirerProp,
  } = props;
  const [loader, setLoader] = React.useState(false);
  const [controlDisable, setControlDisable] = React.useState(false);
  const [allStatusData, setAllStatusData] = React.useState({});
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [setTdrData, setSetTdrData] = React.useState(TDRData);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    CommonCallFunction();
  }, [id, allStatusCodeMapping]);

  const CommonCallFunction = () => {
    setAllStatusData(allStatusCodeMapping);
    let array = allStatusCodeMapping?.services
      ? Object.keys(allStatusCodeMapping?.services)
      : TDRData;
    let InnerTdrData = TDRData.filter(
      (fact) =>
        array.includes(fact.type) &&
        allStatusCodeMapping?.services[fact.type].status
    );
    setSetTdrData(InnerTdrData);
    setLoader(false);
  };

  const SaveCode = async () => {
    setLoader(true);
    let data = allStatusData;
    await UpdateAcquirerProp(data, data._id);
    setLoader(false);
  };

  const confirmReset = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resetData = async () => {
    let data = allStatusData;
    data.services = {};
    setSetTdrData([]);
    setLoader(true);
    setAllStatusData({ ...data });
    await UpdateAcquirerProp(data, data._id);
    setOpen(false);
    setLoader(false);
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="TDRSettings">
          <div className="TDRInnerSettings">
            <div className="TDRLeftSettings">
              <div>
                {setTdrData.map((row, index) => {
                  return (
                    <div
                      className={
                        index == selectedIndex
                          ? "InnerTab selected"
                          : "InnerTab"
                      }
                      key={row.Card}
                      onClick={() => {
                        setSelectedIndex(index);
                      }}
                    >
                      <img
                        style={{ marginRight: "8px", width: "29px" }}
                        src={row.Icon}
                      ></img>
                      {row.Card}
                    </div>
                  );
                })}
              </div>
            </div>
            {setTdrData.length !== 0 && (
              <div className="TDRRightSettings">
                <div className="TDRInnerRow">
                  {setTdrData[selectedIndex]?.Card}
                </div>
                <Grid className="TDRInnerSettingRow" container spacing={2}>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="">
                    <p className="TDRInnerTitle">% Fees </p>
                    <CssTextField
                      className=""
                      id="new-menu"
                      placeholder="% Fees"
                      variant="outlined"
                      fullWidth
                      disabled={controlDisable}
                      onWheel={numberInputOnWheelPreventChange}
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      value={
                        allStatusData?.services &&
                        allStatusData?.services?.[
                          setTdrData[selectedIndex]?.type
                        ]?.margin !== undefined &&
                        allStatusData?.services?.[
                          setTdrData[selectedIndex]?.type
                        ]?.margin !== null
                          ? allStatusData?.services?.[
                              setTdrData[selectedIndex]?.type
                            ]?.margin
                          : ""
                      }
                      onChange={(e) => {
                        let val = e.target.value;
                        const isDecimal = val % 1 != 0;
                        if (isDecimal) {
                          val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                        }
                        if (val || !e.target.value) {
                          let data = allStatusData;
                          data.services[setTdrData[selectedIndex].type].margin =
                            val;
                          setAllStatusData({ ...data });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="">
                    <p className="TDRInnerTitle">Fixed Fees </p>
                    <CssTextField
                      className=""
                      id="new-menu"
                      placeholder="Fixed Fees"
                      variant="outlined"
                      fullWidth
                      disabled={controlDisable}
                      onWheel={numberInputOnWheelPreventChange}
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      onChange={(e) => {
                        let val = e.target.value;
                        const isDecimal = val % 1 != 0;
                        if (isDecimal) {
                          val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                        }
                        if (val || !e.target.value) {
                          let data = allStatusData;
                          data.services[
                            setTdrData[selectedIndex].type
                          ].fixed_fees = val;
                          setAllStatusData({ ...data });
                        }
                      }}
                      value={
                        allStatusData?.services &&
                        allStatusData?.services?.[
                          setTdrData[selectedIndex]?.type
                        ]?.fixed_fees !== undefined &&
                        allStatusData?.services?.[
                          setTdrData[selectedIndex]?.type
                        ]?.fixed_fees !== null
                          ? allStatusData?.services?.[
                              setTdrData[selectedIndex]?.type
                            ]?.fixed_fees
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className="">
                    <p className="TDRInnerTitle">TAX </p>
                    <CssTextField
                      className=""
                      id="new-menu"
                      placeholder="TAX"
                      variant="outlined"
                      fullWidth
                      disabled={controlDisable}
                      onWheel={numberInputOnWheelPreventChange}
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                      onChange={(e) => {
                        let val = e.target.value;
                        const isDecimal = val % 1 != 0;
                        if (isDecimal) {
                          val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                        }
                        if (val || !e.target.value) {
                          let data = allStatusData;
                          data.services[setTdrData[selectedIndex].type].vat =
                            val;
                          setAllStatusData({ ...data });
                        }
                      }}
                      value={
                        allStatusData?.services &&
                        allStatusData?.services?.[
                          setTdrData[selectedIndex]?.type
                        ]?.vat !== undefined &&
                        allStatusData?.services?.[
                          setTdrData[selectedIndex]?.type
                        ]?.vat !== null
                          ? allStatusData?.services?.[
                              setTdrData[selectedIndex]?.type
                            ]?.vat
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
          <Grid
            className="TDRSettingButton"
            container
            spacing={2}
            justifyContent="flex-end"
          >
            <Button
              className="merchant_reset_btn merchant_detail_tab_save-cancel_btn reset_btn"
              style={{ "background-color": "#1058FF" }}
              variant="contained"
              disabled={!setTdrData.length}
              onClick={confirmReset}
            >
              Reset
            </Button>
            <Button
              disabled={!setTdrData.length}
              onClick={SaveCode}
              className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
              style={{ marginRight: "29px" }}
              variant="contained"
            >
              Save
            </Button>
          </Grid>
        </div>
      )}
      <div>
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              <p>Confirm Reset</p>
              <span>Are you sure want to reset configuration?</span>
              <div className="order_detail_refund_popup_btn">
                <Button
                  className="refund_save_btn"
                  variant="contained"
                  autoFocus
                  fullWidth
                  onClick={resetData}
                >
                  Confirm
                </Button>
                <Button
                  className="refund_cancel_btn"
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  fullWidth
                >
                  Cancel
                </Button>
              </div>
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default TDRSettings;
