import React, { useEffect, useState } from "react";
import AcquireTab from './AcquirerTab';
import { Link, NavLink, useLocation } from "react-router-dom";

const AcquireTabMain = () => {
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState([]);

  const search = useLocation().search;

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const id = new URLSearchParams(search).get("id");
    if(id){
    let value = id.split('=');
    setValue(value[0]);
    }
  })
  return (
    <>
      <AcquireTab
        value={value}
        setValue={setValue}
        handleChange={handleTabChange}
      />
    </>
  );
};

export default AcquireTabMain;
