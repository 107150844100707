import React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState, useEffect } from "react";
import AxiosBase from "../../../networkRequest/axiosBase";
import { Box, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import "./AddNewMember.css";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});

const AddNewMember = () => {
  const [data, setData] = React.useState([]);
  const [userdata, setUserdata] = React.useState({
    first_name: "",
    last_name: "",
    email_id: "",
    mobile_no: "",
    roleId: [],
    expiryDate: "",
    password: "",
  });

  const [role, setRole] = React.useState(false);

  const [value, setValue] = React.useState(
    new Date().setDate(new Date().getDate() + 1)
  );

  const success = (status) => {
    toast(status, {
      type: "success",
      autoClose: 2000,
    });
  };

  const handleChange = (newValue) => {
    setValue(newValue);
    setUserdata({ ...userdata, expiryDate: newValue });
  };

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setUserdata(
      // On autofill we get a stringified value.
      {
        ...userdata,
        roleId: typeof value === "string" ? value.split(",") : value,
      }
    );
    setRole(true);
  };

  const addUser = () => {
    AxiosBase.post(`/admin/user`, userdata).then((response) => {
      success(response.data.message);
    });
  };

  useEffect(() => {
    AxiosBase.get(`/admin/roles`).then((response) => {
      setData(response.data.data);
    });
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Users</h1>
        </Box>
        <div className="merchant_list_main_div">
          <div>
            <div className="Fees_Margin_input_field add_menu_role_star">
              <p>
                First Name:<span>*</span>
              </p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  placeholder="First Name"
                  value={userdata.first_name}
                  onChange={(e) => {
                    setUserdata({ ...userdata, first_name: e.target.value });
                  }}
                  variant="outlined"
                  fullWidth
                  required
                  helperText={`${userdata.first_name.length}/${50}`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment className="merchant_edit_field_icongroup">
                        <IconButton color="primary">
                          <img src="/person.svg" alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    maxlength: 50,
                  }}
                />
              </Grid>
            </div>
            <div className="Fees_Margin_input_field add_menu_role_star">
              <p>
                Last Name:<span>*</span>
              </p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  placeholder="Last Name"
                  value={userdata.last_name}
                  onChange={(e) => {
                    setUserdata({ ...userdata, last_name: e.target.value });
                  }}
                  variant="outlined"
                  fullWidth
                  required
                  helperText={`${userdata.last_name.length}/${50}`}
                  maxlength={50}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment className="merchant_edit_field_icongroup">
                        <IconButton color="primary">
                          <img src="/person.svg" alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    maxlength: 50,
                  }}
                />
              </Grid>
            </div>
            <div className="Fees_Margin_input_field add_menu_role_star">
              <p>Mobile Number:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  placeholder="Mobile Number"
                  value={userdata.mobile_no}
                  onChange={(e) => {
                    setUserdata({ ...userdata, mobile_no: e.target.value });
                  }}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment className="merchant_edit_field_icongroup">
                        <IconButton color="primary">
                          <img src="/contact.svg" alt="" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </div>
            <div className="Fees_Margin_input_field add_menu_role_star">
              <p>
                email id:<span>*</span>
              </p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  placeholder="Email"
                  value={userdata.email_id}
                  onChange={(e) => {
                    setUserdata({ ...userdata, email_id: e.target.value });
                  }}
                  variant="outlined"
                  fullWidth
                  required
                  helperText={`${userdata.email_id.length}/${100}`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment className="merchant_edit_field_icongroup">
                        <IconButton color="primary">
                          <img
                            src={`${process.env.PUBLIC_URL}/top-email.svg`}
                            alt=""
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </div>
            <div className="Fees_Margin_input_field add_menu_role_star">
              <p>Role:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec"
              >
                <Select
                  id="demo-simple-select"
                  value={userdata.roleId}
                  fullWidth
                  multiple
                  onChange={(e) => {
                    handleChange1(e);
                  }}
                >
                  {role == false ? (
                    <MenuItem disabled value="">
                      Select Role(Multiple)
                    </MenuItem>
                  ) : (
                    ""
                  )}
                  {data.map((row) => (
                    <MenuItem value={row._id}>{row.role_name}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </div>
            <div className="Fees_Margin_input_field add_menu_role_star">
              <p>Expiry date:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec"
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className="mui-date-picker"
                    value={value}
                    minDate={new Date().setDate(new Date().getDate() + 1)}
                    onChange={handleChange}
                    inputFormat="MM/dd/yyyy"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment className="merchant_edit_field_icongroup calender_icon">
                          <IconButton color="primary">
                            <img src="/calender-01.svg" alt="" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    renderInput={(params) => (
                      <CssTextField
                        className="datepicker"
                        id="new-menu"
                        variant="outlined"
                        fullWidth
                        sx={{
                          fontSize: "12px",
                        }}
                        placeholder="Enter details"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </div>
          </div>
          <div className="bottom_save_cancel_btn">
            <Button variant="contained" className="cancel_btn">
              Cancel
            </Button>
            <Button variant="contained" className="save_btn" onClick={addUser}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewMember;
