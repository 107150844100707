import React, { useEffect } from "react";
import { styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import TablePagination from "@mui/material/TablePagination";
import LedgerDetails from "./ledger";
import ArrowCircleUpOutlined from "@mui/icons-material/ArrowCircleUpOutlined";
import ArrowCircleDownOutlined from "@mui/icons-material/ArrowCircleDownOutlined";
import Delete from "@mui/icons-material/Delete";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import Button from "@mui/material/Button";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { getContacts } from "../../../networkRequest/request/getBusiness";
import CircularProgress from "@mui/material/CircularProgress";

const Business = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState("0");
  const [loading, setLoading] = React.useState(true);

  const [tabDetails, settabDetails] = React.useState([]);
  const [count, settabcount] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const Root = styled("div")`
    table {
      font-family: "whitney", sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td,
    th {
      border: 0.5px solid #c9c9c9;
      text-align: left;
      padding: 8px 25px;
    }
    td {
      background-color: #ffffff;
      width: 160;
    }
    th {
      background-color: #f5f5f5;
    }
  `;

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: "8px",
  }));
  const CssTextField = styled(TextField)({
    "input#new-menu": {
      width: "520px",
      height: "5px",
      backgroundColor: "#FEFEFE",
    },
  });

  useEffect(() => {
    const id = props.tabdetails.uid;
    setLoading(true);
    getContacts(page, rowsPerPage, "_id", "desc", id).then((data1) => {
      if (data1.count > 0) {
        settabcount(data1?.count);
        settabDetails(data1?.data);
        // setValue(0);
        // settabdetails(data1?.data[0]);
      } else {
        settabcount(0);
        settabDetails([]);
      }
      setLoading(false);
    });
  }, [props.tabdetails, page, rowsPerPage]);

  return (
    <>
      {/* <div className="premium_tabs_btns"> */}

      {loading ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : tabDetails.length > 0 ? (
        <div>
          {/* <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={12}
            // className="merchant_grid_sec premium_tabs_grid"
            color="primary"
          > */}
          <div className="backgroundColor">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AssignmentIndIcon />}
            >
              {count} Customers
            </Button>

            <Button
              variant="outlined"
              // color="secondary"
              className="buttonmargin"
              size="small"
              startIcon={<ArrowCircleUpOutlined />}
            >
              You will give{" "}
              {amountchecker(
                props?.tabdetails?.ledger_data_give,
                props.currency
              )}
            </Button>

            <Button
              variant="outlined"
              // color="secondary"
              className="buttonmargin"
              startIcon={<ArrowCircleDownOutlined />}
            >
              You will get{" "}
              {amountchecker(
                props?.tabdetails?.ledger_data_get,
                props.currency
              )}
            </Button>

            {props?.tabdetails?.isdeleted == true ? (
              <Button
                variant="outlined"
                size="small"
                color="inherit"
                // color="secondary"
                //  className="buttonmargin"
                startIcon={<Delete />}
              >
                Deleted
              </Button>
            ) : (
              ""
            )}
          </div>

          <AccordionDetails sx={{ p: "0px 8px " }}>
            <div className="table_container-margin cashbook_table_div">
              <Root sx={{ maxWidth: "100%" }}>
                <table
                  className="cashbook_table"
                  aria-label="custom pagination table"
                >
                  <thead className="cashbook_tbl_head">
                    <tr className="cashbook_tbl_head_frow">
                      <th>ID</th>
                      <th>Name</th>
                      <th>Mobile Number</th>
                      <th>Transaction Type</th>
                      <th>Balance</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody className="cashbook_tbl_body">
                    {tabDetails.map((curElm) => {
                      const {
                        name,
                        mobilenumber,
                        balanceAmount,
                        ledger_data,
                        createdDate,
                        business,
                        uid,
                        isdeleted,
                      } = curElm;
                      return (
                        <tr key={uid}>
                          <td>
                            <div className="status_td">
                              <LedgerDetails
                                id={uid}
                                name={name}
                                bid={business}
                                currency={props.currency}
                              ></LedgerDetails>
                              {isdeleted ? <span>Deleted</span> : ""}
                            </div>
                          </td>
                          <td align="right">{name}</td>
                          <td align="right">{mobilenumber}</td>
                          <td align="right">
                            {ledger_data && balanceAmount != 0
                              ? balanceAmount > 0
                                ? "You will give"
                                : "You will get"
                              : "Nothing Pending"}
                          </td>
                          <td align="right">
                            {amountchecker(balanceAmount, props.currency)}
                          </td>
                          <td align="right">
                            {moment(createdDate).format("YYYY/MM/DD hh:mm A")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>

                  {/* <tfoot className="cashbook_tbl_footer">
                <tr>
                  <td align="right">Grand Total</td>
                  <td align="right">abc</td>
                  <td align="right">abc</td>
                  <td align="right">abc</td>
                  <td align="right">abc</td>
                </tr>
              </tfoot> */}
                </table>
                <TablePagination
                  className="table_pagination"
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Root>
            </div>
          </AccordionDetails>
        </div>
      ) : (
        <div className="no_data_found loader_data_tbl">
          <p>No Data Found</p>
        </div>
      )}
    </>
  );
};

function amountchecker(amount, currency) {
  try {
    let cur = "AED";
    if (currency && currency != "") {
      cur = currency;
    }
    return (
      cur +
      " " +
      Number(Math.abs(amount).toFixed(2))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  } catch (err) {
    return amount;
  }
}
export default Business;
