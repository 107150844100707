import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import TDRSettings from "./TDRSettings/TDRSettings";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});

const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const PlanDate = (props) => {
  const { SelectedPlan, Name } = props;
  const [plan, setPlan] = React.useState(null);
  let todayDate = new Date();

  useEffect(() => {
    setPlan(SelectedPlan);
  }, [SelectedPlan]);

  const handleChange = (newValue) => {
    setPlan({ ...plan, expiryDate: newValue });
  };

  const isExpired = (first, last) => {
    if (!last || last == null || last == undefined) return false;
    var start = new Date(first);
    var end = new Date(last);
    end.setDate(end.getDate() + 1);
    return start.getTime() > end.getTime();
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const loadToolTipAmount = () => {
    return (
      <React.Fragment>
        <p>Please enter the refund charges.</p>
      </React.Fragment>
    );
  };

  const loadToolTipPercent = () => {
    return (
      <React.Fragment>
        <p>Please enter the refund charges in percentage</p>
      </React.Fragment>
    );
  };

  const onChangeAcquirerServices = (services) => {
    plan.acquirer_services = services;
    setPlan(plan);
    props.onChangeFieldVal("acquirer_services", services, "", Name);
  };

  const onChangeFieldVal = async (key, val, type = "String") => {
    if (type == "Int") {
      val = val.toString().replaceAll("+", "");
      let val1 = val.match(/[0-9]/);
      if (val1) {
        val = isNaN(parseInt(val)) ? "" : parseInt(val);
      } else if (!val) {
        val = "";
      } else {
        return;
      }
    }
    if (type == "Float") {
      val = val.toString().replaceAll("+", "");
      const isDecimal = val % 1 != 0;
      if (isDecimal) {
        val = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      }
      if (!val) {
        val = "";
      }
    }
    if (key == "amount") {
      if (val > 0 && val <= 99999) {
        plan.amount = val;
        plan.final_amount =
          Number(plan.amount) -
          (Number(plan.amount) * Number(plan.discount)) / 100;
        if (plan.final_amount % 1 != 0) {
          plan.final_amount = plan.final_amount
            .toString()
            .match(/^-?\d+(?:\.\d{0,2})?/)[0];
        }
      } else {
        plan.amount = "";
        plan.final_amount = 0;
      }
    }
    if (key == "discount") {
      if (val > 0 && val <= 100) {
        plan.final_amount =
          Number(plan.amount) - (Number(plan.amount) * val) / 100;
        if (plan.final_amount % 1 != 0) {
          plan.final_amount = plan.final_amount
            .toString()
            .match(/^-?\d+(?:\.\d{0,2})?/)[0];
        }
      } else {
        plan.discount = "";
        plan.final_amount = plan.amount;
      }
    }
    if (key == "final_amount") {
      if (val > 0) {
        let amt = "";
        let dsc = "";
        if (plan.discount == 0 || plan.discount == "") {
          amt = Number(val);
        } else if (Number(plan.amount) < Number(val)) {
          amt = Number(val);
          dsc = 0;
        } else {
          dsc =
            ((Number(val) - Number(plan.amount)) * 100) / Number(plan.amount);
          dsc = Math.abs(Number(dsc.toFixed(2)));
          amt = plan.amount;
        }
        plan.amount = amt;
        plan.discount = dsc;
      } else {
        plan.final_amount = "";
        plan.amount = "";
        plan.discount = "";
      }
    }
    if (key == "refund_charges_percent") {
      if (!val) {
        val = isNaN(parseInt(val)) ? "" : 0;
      } else if (val < 0 || val > 100) {
        val = plan[key];
      }
    }
    plan[key] = val;
    await setPlan({ ...plan });
    await props.onChangeFieldVal(key, val, "", Name);
  };

  return (
    <>
      <div style={{ width: "100%" }} className="plan_detail_ctrl">
        <fieldset>
          <legend>{Name}:</legend>
          <div className="merchant_list_main_div">
            <div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Days:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextField
                    className="merchant_list_search_bar premium_detail_field"
                    id={"Days_" + plan?._id}
                    name={"Days_" + plan?._id}
                    placeholder="Days"
                    variant="outlined"
                    value={plan?.days}
                    onWheel={numberInputOnWheelPreventChange}
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    onChange={(e) =>
                      onChangeFieldVal("days", e.target.value, "Int")
                    }
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Amount ({props?.currency_code}):</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextField
                    className="merchant_list_search_bar premium_detail_field"
                    id={"Amount_" + plan?._id}
                    name={"Amount_" + plan?._id}
                    placeholder="Amount"
                    variant="outlined"
                    value={plan?.amount || ""}
                    onWheel={numberInputOnWheelPreventChange}
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    onChange={(e) =>
                      onChangeFieldVal("amount", e.target.value, "Float")
                    }
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Discount (%):</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextField
                    className="merchant_list_search_bar premium_detail_field"
                    id={"Discount_" + plan?._id}
                    name={"Discount_" + plan?._id}
                    placeholder="Discount"
                    variant="outlined"
                    aria-readonly
                    value={plan?.discount}
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    onWheel={numberInputOnWheelPreventChange}
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    onChange={(e) =>
                      onChangeFieldVal("discount", e.target.value, "Float")
                    }
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Final Amount:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextField
                    className="merchant_list_search_bar premium_detail_field"
                    id={"FinalAmount_" + plan?._id}
                    name={"FinalAmount_" + plan?._id}
                    placeholder="Final Amount"
                    variant="outlined"
                    aria-readonly
                    value={plan?.final_amount}
                    onWheel={numberInputOnWheelPreventChange}
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    onChange={(e) =>
                      onChangeFieldVal("final_amount", e.target.value, "Float")
                    }
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Settlement Time:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextField
                    className="merchant_list_search_bar premium_detail_field"
                    id={"settlement_time_" + plan?._id}
                    name={"settlement_time_" + plan?._id}
                    placeholder="settlement time"
                    variant="outlined"
                    aria-readonly
                    value={plan?.settlement_time}
                    onWheel={numberInputOnWheelPreventChange}
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    onChange={(e) =>
                      onChangeFieldVal("settlement_time", e.target.value, "Int")
                    }
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Settlement Fees:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextField
                    className="merchant_list_search_bar premium_detail_field"
                    id={"settlement_fees_" + plan?._id}
                    name={"settlement_fees_" + plan?._id}
                    placeholder="settlement fees"
                    variant="outlined"
                    aria-readonly
                    value={plan?.settlement_fees}
                    onWheel={numberInputOnWheelPreventChange}
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "settlement_fees",
                        e.target.value,
                        "Float"
                      )
                    }
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <Tooltip title={loadToolTipAmount()} placement="right">
                  <p>Refund Charges(amount):</p>
                </Tooltip>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextField
                    className="merchant_list_search_bar premium_detail_field"
                    id={"refund_charges_" + plan?._id}
                    name={"refund_charges_" + plan?._id}
                    placeholder="refund charges"
                    variant="outlined"
                    aria-readonly
                    value={plan?.refund_charges}
                    onWheel={numberInputOnWheelPreventChange}
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "refund_charges",
                        e.target.value,
                        "Float"
                      )
                    }
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <Tooltip title={loadToolTipPercent()} placement="right">
                  <p>Refund Charges(%):</p>
                </Tooltip>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextField
                    className="merchant_list_search_bar premium_detail_field"
                    id={"refund_charges_" + plan?._id}
                    name={"refund_charges_" + plan?._id}
                    placeholder="refund charges"
                    variant="outlined"
                    aria-readonly
                    value={plan?.refund_charges_percent}
                    onWheel={numberInputOnWheelPreventChange}
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "refund_charges_percent",
                        e.target.value,
                        "Float"
                      )
                    }
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Discount Label:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextField
                    className="merchant_list_search_bar premium_detail_field"
                    id={"secondary_label_" + plan?._id}
                    name={"secondary_label_" + plan?._id}
                    placeholder="Discount label"
                    variant="outlined"
                    aria-readonly
                    value={plan?.secondary_label || ""}
                    type="text"
                    onChange={(e) =>
                      onChangeFieldVal("secondary_label", e.target.value)
                    }
                  />
                </Grid>
              </div>
              {/* transaction */}
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Pay Transaction:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextField
                    className="merchant_list_search_bar premium_detail_field"
                    id={"payer_transaction_limit_" + plan?._id}
                    name={"payer_transaction_limit_" + plan?._id}
                    placeholder="Payer transaction limit"
                    variant="outlined"
                    value={plan?.payer_transaction_limit}
                    onWheel={numberInputOnWheelPreventChange}
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "payer_transaction_limit",
                        e.target.value,
                        "Int"
                      )
                    }
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Receive Transaction:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextField
                    className="merchant_list_search_bar premium_detail_field"
                    id={"payee_transaction_limit_" + plan?._id}
                    name={"payee_transaction_limit_" + plan?._id}
                    placeholder="Receiver transaction limit"
                    variant="outlined"
                    value={plan?.payee_transaction_limit}
                    onWheel={numberInputOnWheelPreventChange}
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "payee_transaction_limit",
                        e.target.value,
                        "Int"
                      )
                    }
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Pay Transaction Limit Exceed Message:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextArea
                    id="text"
                    variant="outlined"
                    sx={{
                      width: "265px",
                      borderColor: "#c4c4c4",
                      p: "10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                    }}
                    placeholder="Payer transaction message"
                    value={plan?.payer_transaction_message}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "payer_transaction_message",
                        e.target.value,
                        "String"
                      )
                    }
                    minRows={3}
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Receive Transaction Limit Exceed Message:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextArea
                    id="text"
                    variant="outlined"
                    sx={{
                      width: "265px",
                      borderColor: "#c4c4c4",
                      p: "10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                    }}
                    placeholder="Payer transaction message"
                    value={plan?.payer_transaction_message_for_receiver}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "payer_transaction_message_for_receiver",
                        e.target.value,
                        "String"
                      )
                    }
                    minRows={3}
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Both User Transaction Limit Exceed Message:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextArea
                    id="text"
                    variant="outlined"
                    sx={{
                      width: "265px",
                      borderColor: "#c4c4c4",
                      p: "10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                    }}
                    placeholder="Payer transaction message"
                    value={plan?.payer_transaction_message_for_both}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "payer_transaction_message_for_both",
                        e.target.value,
                        "String"
                      )
                    }
                    minRows={3}
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>
                  Receive Transaction Limit Exceed Message(Push Notification):
                </p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextArea
                    id="text"
                    variant="outlined"
                    sx={{
                      width: "265px",
                      borderColor: "#c4c4c4",
                      p: "10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                    }}
                    placeholder="Payee transaction message"
                    value={plan?.payee_transaction_message}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "payee_transaction_message",
                        e.target.value,
                        "String"
                      )
                    }
                    minRows={3}
                  />
                </Grid>
              </div>

              {/* amount */}
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Pay Amount:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextField
                    className="merchant_list_search_bar premium_detail_field"
                    id={"payer_amount_limit_" + plan?._id}
                    name={"payer_amount_limit_" + plan?._id}
                    placeholder="Payer amount limit"
                    variant="outlined"
                    value={plan?.payer_amount_limit}
                    onWheel={numberInputOnWheelPreventChange}
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "payer_amount_limit",
                        e.target.value,
                        "Int"
                      )
                    }
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Receive Amount:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextField
                    className="merchant_list_search_bar premium_detail_field"
                    id={"payee_amount_limit_" + plan?._id}
                    name={"payee_amount_limit_" + plan?._id}
                    placeholder="Receiver amount limit"
                    variant="outlined"
                    value={plan?.payee_amount_limit}
                    onWheel={numberInputOnWheelPreventChange}
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "payee_amount_limit",
                        e.target.value,
                        "Int"
                      )
                    }
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Pay Amount Limit Exceed Message:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextArea
                    id="text"
                    variant="outlined"
                    sx={{
                      width: "265px",
                      borderColor: "#c4c4c4",
                      p: "10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                    }}
                    placeholder="Payer amount message"
                    value={plan?.payer_amount_message}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "payer_amount_message",
                        e.target.value,
                        "String"
                      )
                    }
                    minRows={3}
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Receive Amount Limit Exceed Message:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextArea
                    id="text"
                    variant="outlined"
                    sx={{
                      width: "265px",
                      borderColor: "#c4c4c4",
                      p: "10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                    }}
                    placeholder="Payer amount message"
                    value={plan?.payer_amount_message_for_receiver}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "payer_amount_message_for_receiver",
                        e.target.value,
                        "String"
                      )
                    }
                    minRows={3}
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Both User Amount Limit Exceed Message:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextArea
                    id="text"
                    variant="outlined"
                    sx={{
                      width: "265px",
                      borderColor: "#c4c4c4",
                      p: "10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                    }}
                    placeholder="Payer amount message"
                    value={plan?.payer_amount_message_for_both}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "payer_amount_message_for_both",
                        e.target.value,
                        "String"
                      )
                    }
                    minRows={3}
                  />
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Receive Amount Limit Exceed Message(Push Notification):</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <CssTextArea
                    id="text"
                    variant="outlined"
                    sx={{
                      width: "265px",
                      borderColor: "#c4c4c4",
                      p: "10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                    }}
                    placeholder="Payee amount message"
                    value={plan?.payee_amount_message}
                    onChange={(e) =>
                      onChangeFieldVal(
                        "payee_amount_message",
                        e.target.value,
                        "String"
                      )
                    }
                    minRows={3}
                  />
                </Grid>
              </div>

              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Activate On:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <LocalizationProvider
                    className="merchant_list_search_bar"
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      className="merchant_list_search_bar mui-date-picker"
                      inputFormat="MM/dd/yyyy"
                      value={plan?.active_at}
                      onChange={(SelectedActiveFromDate) => {
                        if (
                          !plan?.expired_at ||
                          isExpired(plan.expired_at, SelectedActiveFromDate)
                        ) {
                          onChangeFieldVal("active_at", SelectedActiveFromDate);
                        } else if (plan?.active_at && plan?.expired_at) {
                          return toast(
                            "Active date must be less then Plan Expiry date.",
                            {
                              type: "error",
                              autoClose: 5000,
                            }
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <CssTextField
                          id={"ActivatedAt_" + plan?._id}
                          name={"ActivatedAt_" + plan?._id}
                          className="merchant_list_search_bar premium_detail_field"
                          variant="outlined"
                          placeholder="Activate On"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Expired On:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <LocalizationProvider
                    className="merchant_list_search_bar"
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      className="merchant_list_search_bar mui-date-picker"
                      // maxDate={new Date()}
                      inputFormat="MM/dd/yyyy"
                      value={plan?.expired_at}
                      onChange={(SelectedExpiredDate) => {
                        if (
                          !plan?.active_at ||
                          !isExpired(plan.active_at, SelectedExpiredDate)
                        ) {
                          onChangeFieldVal("expired_at", SelectedExpiredDate);
                        } else if (plan?.active_at && plan?.expired_at) {
                          return toast(
                            "Expired date must be grater then Plan Active date.",
                            {
                              type: "error",
                              autoClose: 5000,
                            }
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <CssTextField
                          id={"ExpiredAt_" + plan?._id}
                          name={"ExpiredAt_" + plan?._id}
                          className="merchant_list_search_bar premium_detail_field"
                          variant="outlined"
                          placeholder="Expired On"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </div>
              <div className="Fees_Margin_input_field add_menu_role_star">
                <p>Status:</p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="gatway_second_grid_sec"
                >
                  <Switch
                    id={"ActiveStatus1_" + plan?._id}
                    name={"ActiveStatus1_" + plan?._id}
                    label={plan?.activeStatus ? "yes" : "no"}
                    checked={plan?.activeStatus || false}
                    onChange={(e) =>
                      onChangeFieldVal("activeStatus", e.target.checked)
                    }
                  />
                </Grid>
              </div>
              {plan && (
                <TDRSettings
                  rolePermission={true}
                  acquirer_services={plan?.acquirer_services}
                  plan={plan}
                  onChangeAcquirerServices={onChangeAcquirerServices}
                />
              )}
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
};

export default PlanDate;
