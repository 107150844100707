import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useEffect } from "react";
import axios from "axios";
import TablePagination from "@mui/material/TablePagination";
import { Link } from "react-router-dom";
import AxiosBase from "../../../networkRequest/axiosBase";

const theme = createTheme({
  components: {
    // Name of the component
    MuiTableHead: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: 10,
        },
      },
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ebebeb",
    color: "#666666",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme?.palette?.action?.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function MerchantSettlement() {
  const columns = [
    { id: "settlementId", label: "Settlement ID" },
    { id: "amount", label: "Amount" },
    { id: "bankName", label: "Bank Name" },
    { id: "startDate", label: "Start Date" },
    { id: "endDate", label: "End Date" },
    { id: "compDate", label: "Completion Date" },
    { id: "margin", label: "Margins" },
    { id: "ulCharges", label: "UL Charges" },
    { id: "status", label: "Status" },
  ];

  const rows = [];

  const [data, setData] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [count, setCount] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    AxiosBase.get(
      `/admin/merchant-settlement?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        `&sortBy=createdAt&sortOrder=asc`
    ).then((response) => {
      setData(response.data.data);
      setCount(response.data.count);
    });
  }, [page, rowsPerPage]);

  return (
    <div style={{ backgroundColor: "#ffffff", padding: "15px" }}>
      {data.length != 0 &&
        data.map((d) => {
          rows.push(d);
        })}
      <div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <ThemeProvider theme={theme}>
              <TableHead>
                <StyledTableRow>
                  {columns.map((column) => (
                    <StyledTableCell style={{ minWidth: column.minWidth }}>
                      <p> {column.label} </p>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
            </ThemeProvider>
            <TableBody>
              {rows.map((row) => {
                return (
                  <StyledTableRow tabIndex={-1}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return column.id === "settlementId" ? (
                        <StyledTableCell key={column.id}>
                          <Link to={`/settlements/${row._id}`}>
                            {row.settlement_id}
                          </Link>
                        </StyledTableCell>
                      ) : column.id === "amount" ? (
                        <StyledTableCell key={column.id}>
                          {row.total_settlement_amount}
                        </StyledTableCell>
                      ) : column.id === "bankName" ? (
                        <StyledTableCell key={column.id}>
                          {row.bank_name}
                        </StyledTableCell>
                      ) : column.id === "startDate" ? (
                        <StyledTableCell key={column.id}>
                          {row.from_date}
                        </StyledTableCell>
                      ) : column.id === "endDate" ? (
                        <StyledTableCell key={column.id}>
                          {row.to_date}
                        </StyledTableCell>
                      ) : column.id === "compDate" ? (
                        <StyledTableCell key={column.id}></StyledTableCell>
                      ) : column.id === "margin" ? (
                        <StyledTableCell key={column.id}>
                          {row.total_margin_amount}
                        </StyledTableCell>
                      ) : column.id === "ulCharges" ? (
                        <StyledTableCell key={column.id}>
                          {row.total_fixed_fees_amount}
                        </StyledTableCell>
                      ) : column.id === "status" ? (
                        <StyledTableCell key={column.id}>
                          {row.status === "PENDING_FROM_BANK"
                            ? "pending"
                            : row.status === "FAILED"
                            ? "failed"
                            : row.status === "COMPLETED"
                            ? "successful"
                            : ""}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell key={column.id}></StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

export default MerchantSettlement;
