import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useState, useEffect } from "react";
import axios from "axios";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import moment from "moment";
import { useLocation } from "react-router-dom";
import AxiosBase from "../../../../../../../networkRequest/axiosBase";
import CircularProgress from "@mui/material/CircularProgress";

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
    borderRadius: "10px",
  },
});

export default function KycHistory() {
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get("customerId");
  const kycId = new URLSearchParams(search).get("kycId");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [count, setCount] = useState([]);
  //api data state
  const [userdata, setUserData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    AxiosBase.get(
      `/admin/kyc/view-kyc-history/${customerId}?kycId=${kycId}&pageNo=${page}&rowsPerPage=${rowsPerPage}&sortBy=createdAt`
    )
      .then((response) => {
        setLoader(false);
        setUserData(response.data.data);
        setCount(response.data.count);
      })
      .catch((err) => setLoader(false));
  }, []);

  if (loader) {
    return (
      <div className="center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#ffffff",
          padding: "10px 0px 5px 10px",
          borderRadius: "10px",
          boxShadow: "0 5px 5px 0 rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        <TableContainer sx={{ maxHeight: 365, ml: "-15px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className="tbldata">
                <Div
                  className="tbl-box-rows"
                  sx={{
                    py: 1,
                  }}
                >
                  <TableCell
                    style={{
                      width: 100,
                    }}
                  >
                    Request ID
                  </TableCell>

                  <TableCell
                    style={{
                      width: 110,
                    }}
                  >
                    Merchant Name
                  </TableCell>

                  <TableCell
                    style={{
                      width: 120,
                    }}
                  >
                    {" "}
                    Merchant Number
                  </TableCell>

                  <TableCell
                    style={{
                      width: 120,
                    }}
                  >
                    Created on
                  </TableCell>

                  <TableCell
                    style={{
                      width: 100,
                    }}
                  >
                    Status
                  </TableCell>
                </Div>
              </TableRow>
            </TableHead>
            <TableBody>
              {userdata &&
                userdata?.map((row, index) => {
                  {
                    /* const { _id, firstName, lastName, emailId, roleData, status, } = row; */
                  }
                  return (
                    <TableRow
                      className="tblrowdata"
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      style={{ background: "none" }}
                    >
                      <Div
                        className="tbl-box-rows-body"
                        sx={{
                          py: 1,
                        }}
                      >
                        <TableCell
                          style={{
                            width: 100,
                          }}
                        >
                          <Link
                            to={`/kyc/details?kycId=${row._id}&customerId=${row.customer_id}`}
                            style={{ textDecoration: "none", color: "#2160F6" }}
                          >
                            {row._id}
                          </Link>
                        </TableCell>

                        <TableCell
                          style={{
                            width: 110,
                          }}
                        >
                          {row?.customersData?.first_name}{" "}
                          {row?.customersData?.last_name}
                        </TableCell>

                        <TableCell
                          style={{
                            width: 120,
                          }}
                        >
                          {row?.customersData?.mobile_no}
                        </TableCell>

                        <TableCell
                          style={{
                            width: 120,
                          }}
                        >
                          {moment(row.createdAt).format("YYYY/MM/DD hh:mm A")}
                        </TableCell>

                        <TableCell
                          style={{
                            width: 100,
                          }}
                        >
                          <KycStatus status={row.kycStatus} />
                        </TableCell>
                      </Div>
                    </TableRow>
                  );
                })}
              {userdata == "" && (
                <p style={{ textAlign: "center" }}>No Data Found</p>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="pagination-sec"
        />
      </div>
    </>
  );
}

const KycStatus = (props) => {
  const statusColor = (status) => {
    switch (status) {
      case "Approved":
        return "#2ed06d";
      case "Initiated":
        return "#1058ff";
      case "In progress":
        return "#ff0000";
      case "Expired":
        return "#ff0000";
      default:
        return "#ff0000";
    }
  };
  return (
    <p style={{ color: statusColor(props.status), fontSize: "inherit" }}>
      {props.status}
    </p>
  );
};
