import React, { useState } from "react";
import "./CreateMerchant.css";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneInput from "react-phone-input-2";
import KYCDetails from "./KYCDetails";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});

// const cuntryLength=[{india}]

const CreateMerchant = () => {
  const [validation, setValidation] = useState({
    firstName: "",
    phone: "",
    lastName: "",
    email: "",
    address: "",
    zipCode: "",
    merchant: "",
  });

  const [firsterror, setFirstError] = useState({
    error: false,
    message: "",
  });
  const [lasterror, setLastError] = useState({
    error: false,
    message: "",
  });

  const [numbererror, setNumberError] = useState({
    error: false,
    message: "",
  });
  const [age, setAge] = React.useState("1");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);
  };

  const CHARACTER_LIMIT = 50;
  const ZIP_LIMIT = 6;

  return (
    <div>
      <div className="CreateMerchants_main_div">
        <p className="create_merchant_title">Create Merchant</p>
        <form onSubmit={handleSubmit}>
          <div className="create_merhant_inpt_bdy">
            <Grid container spacing={2} className="merchant_main_grid">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="merchant_grid_sec"
              >
                <div className="sep_inpt_sec">
                  <p>
                    First Name<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="First name"
                      fullWidth
                      helperText={
                        firsterror.error == true ? firsterror.message : ""
                      }
                      inputProps={{
                        maxlength: 15,
                      }}
                      value={validation.firstName}
                      required
                      onChange={(e) => {
                        if (
                          e.target.value.length < 3 ||
                          e.target.value.length > 15
                        ) {
                          setFirstError({
                            error: true,
                            message:
                              "First Name must be between 3 to 15 characters",
                          });
                        } else {
                          setFirstError({ error: false, message: "" });
                        }
                        setValidation({
                          ...validation,
                          firstName: e.target.value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <PersonOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Mobile Number<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <PhoneInput
                      className="mobile-input"
                      name="phone"
                      country={"ae"}
                      countryCodeEditable={true}
                      value={validation.phone}
                      onChange={(val) => {
                        if (val.length < 14 || val.length > 16) {
                          setNumberError({
                            error: true,
                            message: "Enter valid Number",
                          });
                        } else {
                          setNumberError({ error: false, message: "" });
                        }
                        setValidation({ ...validation, phone: val });
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Last Name<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      helperText={
                        lasterror.error == true ? lasterror.message : ""
                      }
                      fullWidth
                      placeholder="Last name"
                      value={validation.lastName}
                      required
                      inputProps={{
                        maxlength: 15,
                      }}
                      onChange={(e) => {
                        if (
                          (e.target.value.length > 0 &&
                            e.target.value.length < 3) ||
                          e.target.value.length > 15
                        ) {
                          setLastError({
                            error: true,
                            message:
                              "Last Name must be between 3 to 15 characters",
                          });
                        } else if (e.target.value.length == 0) {
                          setLastError({ error: false, message: "" });
                        }

                        setValidation({
                          ...validation,
                          lastName: e.target.value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <PersonOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Business Email<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="email"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Business Email"
                      fullWidth
                      value={validation.email}
                      required
                      inputProps={{
                        maxlength: 30,
                      }}
                      error={
                        !validation.email.match(
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        ) && validation.email !== ""
                      }
                      helperText={
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                          validation.email
                        ) || validation.email === ""
                          ? " "
                          : "email must be between 6 to 30 characters"
                      }
                      onChange={(e) => {
                        setValidation({
                          ...validation,
                          email: e.target.value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <img
                                src={`${process.env.PUBLIC_URL}/top-email.svg`}
                                alt=""
                                style={{ width: "25px", height: "25px" }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>Address</p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Address"
                      fullWidth
                      inputProps={{
                        maxlength: CHARACTER_LIMIT,
                      }}
                      helperText={`${validation.address.length}/${CHARACTER_LIMIT}`}
                      value={validation.address}
                      onChange={(e) =>
                        setValidation({
                          ...validation,
                          address: e.target.value,
                        })
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <LocationOnOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>Zip Code</p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="text"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Zipcode"
                      fullWidth
                      inputProps={{
                        maxlength: ZIP_LIMIT,
                      }}
                      helperText={`${validation.zipCode.length}/${ZIP_LIMIT}`}
                      value={validation.zipCode}
                      onChange={(e) =>
                        setValidation({
                          ...validation,
                          zipCode: e.target.value,
                        })
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <LocationOnOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>Merchant Name</p>

                  <div className="inp_sep_div">
                    {/* <CssTextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      fullWidth
                      placeholder="Super Merchant name"
                      value={validation.merchant}
                      onChange={(e) =>
                        setValidation({
                          ...validation,
                          merchant: e.target.value,
                        })
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <PersonOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                    <Select
                      id="demo-simple-select"
                      value={age}
                      fullWidth
                      placeholder=""
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>AED</MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div style={{ padding: "20px" }}>
            <Button variant="contained" sx={{ backgroundColor: "#FF5B55" }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#C9C9C9", marginLeft: "1rem" }}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateMerchant;
