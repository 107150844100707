import React from "react";
import { useState, useEffect } from "react";
import "./KycDetail.css";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Checkbox from "@mui/material/Checkbox";
import AxiosBase from "../../../../networkRequest/axiosBase";
import { Link } from "react-router-dom";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import CircularProgress from "@mui/material/CircularProgress";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import Switch from "@mui/material/Switch";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import { unstable_styleFunctionSx } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});

const labelList = [
  {
    value: "ID Document",
    label: "ID Document",
  },
  {
    value: "License",
    label: "License",
  },
  {
    value: "Bank Document",
    label: "Bank Document",
  },
  {
    value: "Tax",
    label: "Tax",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const businessTypeList = [
  {
    value: "llc",
    label: "llc",
  },
  {
    value: "sole_establishment",
    label: "sole_establishment",
  },
  {
    value: "free_zone_llc",
    label: "free_zone_llc",
  },
  {
    value: "free_zone_establishment",
    label: "free_zone_establishment",
  },
];

const KycDetail = (props) => {
  const { SelectedKyc } = props;
  const [kyc, setKyc] = React.useState([]);
  let todayDate = new Date();

  useEffect(() => {
    setKyc(SelectedKyc);
  }, [props.SelectedKyc]);

  const addKyc = () => {
    console.log(kyc);
    if (!kyc?.name || kyc?.name == "") {
      return toast("name is required", {
        type: "error",
        autoClose: 2000,
      });
    }
    if (!kyc?.business_type || kyc?.business_type == "") {
      return toast("business type is required", {
        type: "error",
        autoClose: 2000,
      });
    }
    if (!kyc?.type || kyc?.type == "") {
      return toast("type is required", {
        type: "error",
        autoClose: 2000,
      });
    }
    if (!kyc?.document_type || kyc?.document_type == "") {
      kyc.document_type = labelList[0].value;
    }
    props.onSubmitKyc(kyc);
  };

  const loadToolTip = () => {
    return (
      <React.Fragment>
        <h4>Image Quality</h4>
        <p>
          This is to detect and correct the quality of an image, based upon 4
          types of distortions:
        </p>
        <div className="img-quality-tooltip" style={{ "padding-left": "15px" }}>
          <ul>
            <li>Sharpness check</li>
            <li>Brightness check</li>
            <li>Compression quality check</li>
            <li>Text readability</li>
          </ul>
        </div>
        <h4>Data Extraction</h4>
        <p>Auto-reading & extracting data from National ID</p>
        <h4>ID Classification</h4>
        <p>
          Classify/Determine that customer is uploading only the specified ID or
          any of the 180 countries passports rather than uploading any unknown
          or irrelevant documents. As this will save time and effort by
          eliminating the garbage data getting into the system
        </p>
      </React.Fragment>
    );
  };

  return (
    <>
      <div style={{ width: "100%" }} className="kyc_detail_ctrl">
        <div className="">
          <div>
            <div className="add_menu_role_star">
              <p>Document Name:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec kyc-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"name_"}
                  placeholder="name"
                  variant="outlined"
                  aria-readonly
                  value={kyc.name}
                  onChange={(e) => {
                    let val = e.target.value;
                    val = val.replace(/^\s+/, "").replace("  ", " ");
                    kyc.name = val;
                    setKyc({
                      ...kyc,
                      name: val,
                    });
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Document Type:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec kyc-field kyc-list"
              >
                <Select
                  name="key1"
                  labelId="country-lbl"
                  id="label"
                  value={
                    kyc.document_type ? kyc.document_type : labelList[0].value
                  }
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    let val = e.target.value;
                    kyc.document_type = val;
                    setKyc({
                      ...kyc,
                      document_type: val,
                    });
                  }}
                >
                  {labelList &&
                    labelList.map((c) => {
                      return (
                        <MenuItem key={c.value} value={c.value}>
                          {c.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <p>Type:</p>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec kyc-field"
              >
                <CssTextField
                  className="merchant_list_search_bar"
                  id="new-menu"
                  name={"type_"}
                  placeholder="type"
                  variant="outlined"
                  aria-readonly
                  value={kyc.type}
                  onChange={(e) => {
                    let val = e.target.value;
                    val = val.replace(/^\s+/, "").replace(" ", "");
                    kyc.type = val;
                    setKyc({
                      ...kyc,
                      type: val,
                    });
                  }}
                />
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec kyc-field"
              >
                <Checkbox
                  id={"is_required_"}
                  name={"is_required_"}
                  label={kyc.is_required ? "yes" : "no"}
                  checked={kyc.is_required ? true : false}
                  onChange={(e) => {
                    kyc.is_required = e.target.checked;
                    setKyc({
                      ...kyc,
                      is_required: e.target.checked,
                    });
                  }}
                />
                <label>Required</label>
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <label>Signzy API</label>
              <Tooltip title={loadToolTip()} placement="right">
                <img className="info-img" src="/images/info.svg" alt="info" />
              </Tooltip>
              <div className="clearfix"></div>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                className="gatway_second_grid_sec kyc-cell-signzy"
              >
                <Checkbox
                  color="primary"
                  id={"image_quality_"}
                  name={"image_quality_"}
                  label={kyc.image_quality ? "yes" : "no"}
                  checked={kyc.image_quality ? true : false}
                  onChange={(e) => {
                    kyc.image_quality = e.target.checked;
                    setKyc({
                      ...kyc,
                      image_quality: e.target.checked,
                    });
                  }}
                />
                <label>Image Quality Check</label>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                className="gatway_second_grid_sec kyc-cell-signzy"
              >
                <Checkbox
                  id={"ActiveStatus_"}
                  name={"ActiveStatus_"}
                  label={kyc.identities_extractions ? "yes" : "no"}
                  checked={kyc.identities_extractions ? true : false}
                  onChange={(e) => {
                    kyc.identities_extractions = e.target.checked;
                    setKyc({
                      ...kyc,
                      identities_extractions: e.target.checked,
                    });
                  }}
                />
                <label>Data Extraction</label>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                className="gatway_second_grid_sec kyc-cell-signzy"
              >
                <Checkbox
                  id={"id_classification_"}
                  name={"id_classification_"}
                  label={kyc.id_classification ? "yes" : "no"}
                  checked={kyc.id_classification ? true : false}
                  onChange={(e) => {
                    kyc.id_classification = e.target.checked;
                    setKyc({
                      ...kyc,
                      id_classification: e.target.checked,
                    });
                  }}
                />
                <label>ID Classification</label>
              </Grid>
            </div>
            <div className="clearfix"></div>
            <div className="add_menu_role_star">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec kyc-field"
              >
                <p style={{ height: "30px" }}> </p>
                <Checkbox
                  id={"is_expiry_"}
                  name={"is_expiry_"}
                  label={kyc.is_expiry ? "yes" : "no"}
                  checked={kyc.is_expiry ? true : false}
                  onChange={(e) => {
                    kyc.is_expiry = e.target.checked;
                    setKyc({
                      ...kyc,
                      is_expiry: e.target.checked,
                    });
                  }}
                />
                <label>Expiry Date Check</label>
              </Grid>
            </div>
            <div className="add_menu_role_star">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="gatway_second_grid_sec kyc-field"
              >
                <Checkbox
                  id={"ActiveStatus_"}
                  name={"ActiveStatus_"}
                  label={kyc.status ? "yes" : "no"}
                  checked={kyc.status ? true : false}
                  onChange={(e) => {
                    kyc.status = e.target.checked;
                    setKyc({
                      ...kyc,
                      status: e.target.checked,
                    });
                  }}
                />
                <label>Active</label>
              </Grid>
            </div>
          </div>
        </div>
        <div>
          <div className="merchant_edit_drawer_btn_sec">
            <Button
              className="drawer_merchant_cancel_btn"
              variant="contained"
              onClick={props.toggleDrawer("right", false)}
            >
              Cancel
            </Button>

            <Button
              className="drawer_merchant_save_btn"
              variant="contained"
              onClick={() => {
                addKyc();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default KycDetail;
