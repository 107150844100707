import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.scss";

const getOptions = (times = null) => {
  return {
    chart: {
      type: "bar",
      height: 380,
    },
    colors: [
      "#38EDCC",
      "#0F79DB",
      "#D14DFF",
      "#F58500",
      "#1058ff",
      "#2C1867",
      "#C90000",
      "#A7A7A7",
      "#abeb34",
      "#a9d0de",
    ],
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#000"],
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + " -  " + val;
      },
      offsetX: 0,
      // dropShadow: {
      //   enabled: true,
      // },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: times?.length ? times : ['N/A'],
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function () {
            return "";
          },
        },
      },
    },
  };
};

export default function TopBank(props) {
  const [options, setOptions] = useState(getOptions());
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const data = [];
    const xData = props.data?.map((res, index) => {
      data.push(res.amount);
      return res._id ? res._id : index.toString();
    });
    setOptions(getOptions(xData));
    setSeries([{ name: "Bank Name", data }]);
  }, [props.data]);

  if (!props.data || !series) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div id="chart">
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
}
