import AxiosBase from "../axiosBase";

export const ViewSettelments = (Id) => {
  try {
    return new Promise((resolve, reject) => {
      AxiosBase.get(`/admin/merchant-settlement/`+Id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("error", err);
          reject(err);
        });
    });
  } catch (error) {
    console.log(error);
    return;
  }
};

export const GetAllSettelments = (pageNo, rowsPerPage, searchBy, filter) => {
  try {
    return new Promise((resolve, reject) => {
      AxiosBase.get(
        `/admin/merchant-settlement?pageNo=${pageNo}&rowsPerPage=${rowsPerPage}&sortBy=createdAt&search=${
          searchBy ? searchBy : ""
        }&filter=${filter}`
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("error", err);
          reject(err);
        });
    });
  } catch (error) {
    console.log(error);
    return;
  }
};

export const GenerateSettlementPdf = (Id) => {
  try {
    return new Promise((resolve, reject) => {
      AxiosBase.get(`/admin/merchant-settlement/generate-pdf/`+Id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("error", err);
          reject(err);
        });
    });
  } catch (error) {
    console.log(error);
    return;
  }
};