import React from "react";
import { Box, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import EditIcon from "@mui/icons-material/Edit";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import { unstable_styleFunctionSx } from "@mui/system";
import ListItem from "@mui/material/ListItem";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon1 from "../../../Icons/Edit-Blue.jpg";
import "./EditList.css";
import TextField from "@mui/material/TextField";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// side bar
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
//side bar table
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";

//side bar table code
function createData(name) {
  return {
    name,
  };
}

const rows = [
  createData("Cupcake"),
  createData("Donut"),
  createData("Eclair"),
  createData("Frozen yoghurt"),
  createData("Gingerbread"),
  createData("Honeycomb"),
  createData("Ice cream sandwich"),
  createData("Jelly Bean"),
  createData("KitKat"),
  createData("Lollipop"),
  createData("Marshmallow"),
  createData("Nougat"),
  createData("Oreo"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//side bar table code end

const Div = styled("div")(unstable_styleFunctionSx);
const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});

// main function

const EditList = (props) => {
  const [data, setData] = React.useState([props.rows]);

  // side bar table code
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [alrt, setAlrt] = React.useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const success = () => {
    return (
      // <Collapse in={alrt}>
      //     <Alert
      //         action={
      //             <IconButton
      //                 aria-label="close"
      //                 color="inherit"
      //                 size="small"
      //                 onClick={() => {
      //                     setAlrt(false);
      //                 }}
      //             >
      //                 <CloseIcon fontSize="inherit" />
      //             </IconButton>
      //         }
      //         sx={{ mb: 2 }}
      //     >
      //         Close me!
      //     </Alert>
      // </Collapse>

      toast("Merchant Successfully Updated", {
        type: "success",
        autoClose: 2000,
      })
    );
  };

  // side bar table code end

  // side bar merchant edit
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const listIItem = [
    { name: "alam" },
    { name: "alam" },
    { name: "alam" },
    { name: "alam" },
    { name: "alam" },
    { name: "alam" },
    { name: "alam" },
  ];

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
        overflowX: "hidden",
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem
          style={{
            backgroundColor: "#2160F6",
            fontSize: "1.5rem",
            fontWeight: 650,
            color: "#fff",
            height: "60px",
          }}
        >
          <p style={{ marginLeft: "1rem" }}>
            {" "}
            Add Mapping{" "}
            <span
              style={{ fontSize: "1.2rem", fontWeight: 500 }}
            >{` ( hindustan uniliver)`}</span>
          </p>
        </ListItem>
      </List>
      <Divider />
      <List>
        <CssTextField
          id="new-menu"
          placeholder="search"
          variant="outlined"
          sx={{
            width: "87%",
            borderRadius: "50px",
            px: "20px",
            py: "10px",
            my: "0.5rem",
            borderColor: "#C0C0C0",
            mx: "2rem",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton sx={{ mr: "-1.5rem", color: "#C0C0C0" }}>
                  <SearchOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </List>
      <List>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p
            style={{
              marginLeft: "3.5rem",
              fontSize: "1rem",
              fontWeight: "bold",
              color: "#666666",
            }}
          >
            Merchant:
          </p>
          <div style={{ marginRight: "2rem" }}>
            <IconButton style={{ color: "#2160F6" }}>
              <AddCircleOutlineIcon />
            </IconButton>
            <Link
              style={{
                color: "#2160F6",
                textDecoration: "none",
                fontWeight: 500,
              }}
            >
              ADD NEW MERCHANT
            </Link>
          </div>
        </div>
        <div>
          <Box sx={{ width: "100%" }}>
            <TableContainer style={{ overflowX: "hidden" }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                          style={{
                            background: "none",
                            border: "none",
                            display: "flex",
                          }}
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            style={{
                              border: 1,
                              borderColor: "#C0C0C0",
                              borderRadius: "10px",
                            }}
                          />
                          <Div
                            className=""
                            sx={{
                              mx: "0.5rem",
                              mt: "0.3rem",
                              py: 1,
                              border: "0.5px solid",
                              borderRadius: "0.5rem",
                              height: "1rem",
                              width: "70%",
                              borderColor: "#C0C0C0",
                            }}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              style={{
                                background: "none",
                                border: "none",
                                paddingLeft: "10px",
                              }}
                            >
                              {row.name}
                            </TableCell>
                          </Div>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ margin: "2rem", marginLeft: "3.2rem" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#ff5b55", width: "6rem" }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                style={{
                  marginLeft: "0.5rem",
                  backgroundColor: "#00c9a5",
                  width: "6rem",
                }}
                onClick={success}
              >
                Save
              </Button>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </div>
      </List>
    </Box>
  );
  // end side bar

  const [enabl, setEnabl] = React.useState("disabled");
  const [enabl1, setEnabl1] = React.useState("disabled");
  const [enabl2, setEnabl2] = React.useState("disabled");
  const [enabl3, setEnabl3] = React.useState("disabled");

  const EnableField = (e) => {
    setEnabl();
  };
  const EnableField1 = (e) => {
    setEnabl1();
  };
  const EnableField2 = (e) => {
    setEnabl2();
  };
  const EnableField3 = (e) => {
    setEnabl3();
  };

  const listName = [
    { name: "alam" },
    { name: "alam" },
    { name: "alam" },
    { name: "alam" },
    { name: "alam" },
    { name: "alam" },
    { name: "alam" },
    { name: "alam" },
    { name: "alam" },
    { name: "alam" },
    { name: "alam" },
  ];

  return (
    <>
      {/* super merchant heading  */}
      <Box sx={{ display: "flex", mt: "-3rem", mb: "2rem" }}>
        <IconButton sx={{ p: "10px" }} aria-label="search">
          <Link to="/merchants">
            <ArrowBackIosNewIcon
              style={{
                border: "3px solid",
                color: "#2160F6",
                borderColor: "#7c7c7c",
                borderRadius: "7px",
              }}
            />
          </Link>
        </IconButton>
        <h1 style={{ color: "#2160F6", margin: "0", fontweight: "600" }}>
          Super Merchant ID
        </h1>
      </Box>
      {/* super merchant heading end  */}

      {/* Edit page code */}

      <div
        style={{
          backgroundColor: "#ffffff",
          padding: "10px",
          marginTop: "2rem",
          borderRadius: "15px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <p
          style={{
            color: "#666666",
            fontWeight: 600,
            fontSize: "25px",
            marginTop: "10px",
            marginLeft: "2rem",
          }}
        >
          Edit Super Merchant
        </p>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
            width: "80%",
          }}
        >
          <div
            style={{ width: "30%", marginTop: "-1.5rem", marginLeft: "2rem" }}
          >
            <p
              style={{
                color: "#A9A9A9",
                fontWeight: 500,
                fontSize: "20px",
                marginBlockStart: "40px",
                marginBlockEnd: "40px",
              }}
            >
              Super Merchant Id :
            </p>
            <p
              style={{
                color: "#A9A9A9",
                fontWeight: 500,
                fontSize: "20px",
                marginBlockStart: "40px",
                marginBlockEnd: "40px",
              }}
            >
              Super Merchant Name :
            </p>
            <p
              style={{
                color: "#A9A9A9",
                fontWeight: 500,
                fontSize: "20px",
                marginBlockStart: "40px",
                marginBlockEnd: "40px",
              }}
            >
              Mobile Number :
            </p>
            <p
              style={{
                color: "#A9A9A9",
                fontWeight: 500,
                fontSize: "20px",
                marginBlockStart: "40px",
                marginBlockEnd: "40px",
              }}
            >
              Email :
            </p>
          </div>
          <div style={{ width: "70%" }}>
            <CssTextField
              id="new-menu"
              variant="outlined"
              sx={{ marginBottom: "30px" }}
              value="User ID"
              disabled={enabl}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton color="primary">
                      <img
                        src={EditIcon1}
                        style={{ height: "2rem", width: "2rem" }}
                        alt=""
                        onClick={EnableField}
                      />
                      {/* <EditIcon onClick={EnableField} /> */}
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment>
                    <IconButton color="primary">
                      <AccountBoxOutlinedIcon
                        style={{ fontSize: "1.5rem", color: "#3D63FF" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <CssTextField
              id="new-menu"
              variant="outlined"
              sx={{ marginBottom: "30px" }}
              value="Merchant Name"
              disabled={enabl1}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton color="primary">
                      <img
                        src={EditIcon1}
                        style={{ height: "2rem", width: "2rem" }}
                        alt=""
                        onClick={EnableField1}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment>
                    <IconButton color="primary">
                      <PersonOutlinedIcon
                        style={{ fontSize: "1.5rem", color: "#3D63FF" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <CssTextField
              id="new-menu"
              variant="outlined"
              sx={{ marginBottom: "30px" }}
              value="Mobile number"
              disabled={enabl2}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton color="primary">
                      <img
                        src={EditIcon1}
                        style={{ height: "2rem", width: "2rem" }}
                        alt=""
                        onClick={EnableField2}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment>
                    <IconButton color="primary">
                      <LocalPhoneOutlinedIcon
                        style={{ fontSize: "1.5rem", color: "#3D63FF" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <CssTextField
              id="new-menu"
              variant="outlined"
              sx={{ marginBottom: "30px" }}
              value="Email"
              disabled={enabl3}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton color="primary">
                      <img
                        src={EditIcon1}
                        style={{ height: "2rem", width: "2rem" }}
                        alt=""
                        onClick={EnableField3}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment>
                    <IconButton color="primary">
                      <EmailOutlinedIcon
                        style={{ fontSize: "1.5rem", color: "#3D63FF" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>

        <div style={{ display: "flex", width: "80%" }}>
          <div style={{ width: "30%", marginLeft: "2rem" }}>
            <p style={{ color: "#A9A9A9", fontWeight: 500, fontSize: "20px" }}>
              Merchant :
            </p>
          </div>
          <div style={{ width: "70%" }}>
            <Div
              sx={{
                my: "0.2rem",
                p: 1,
                border: 1,
                borderRadius: "0.5rem",
                height: "18rem",
                width: "650px",
                borderColor: "#C0C0C0",
              }}
            >
              <div style={{ display: "flex" }}>
                <IconButton style={{ height: "3rem" }} color="primary">
                  <PersonOutlinedIcon
                    style={{ fontSize: "1.5rem", color: "#3D63FF" }}
                  />
                </IconButton>

                <TableContainer
                  sx={{ maxHeight: 275, overflowX: "hidden", width: "40rem" }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead></TableHead>
                    <TableBody>
                      {listName.map((lst) => {
                        return (
                          <TableRow style={{ background: "none" }}>
                            <TableCell
                              style={{
                                border: "none",
                                fontSize: "15px",
                                color: "#A9A9A9",
                              }}
                            >
                              {lst.name}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Div>

            {/* Edit page code */}

            {/* edit button drawer  */}
            <div>
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Button
                    onClick={toggleDrawer(anchor, true)}
                    variant="contained"
                    startIcon={<EditIcon />}
                    sx={{ mt: "1rem" }}
                  >
                    EDIT
                  </Button>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    {list(anchor)}
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </div>

            {/* edit button drawer  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditList;
