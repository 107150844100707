import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Filter from "../../filter-component/Filter";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import AxiosBase from "../../../networkRequest/axiosBase";
import CloseRounded from "@mui/icons-material/CloseRounded";
import Search from "@mui/icons-material/Search";
import {
  GetAllSettelments,
  ViewSettelments,
  GenerateSettlementPdf,
} from "../../../networkRequest/request/settelment";
import "./History.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import PdfSection from "./invoice-pdf/PdfSection";
import Tooltip from "@mui/material/Tooltip";
import NavbarHoc from "../../../pages/navhoc/index";
import Config from "../../../config.json";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ErrorIcon from "@mui/icons-material/Error";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import baseURL from "../../../utils/base_url";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});
const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
// main function

const SettlementHistory = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [count, setCount] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchBy, setSearch] = useState();
  const [loading, setLoading] = useState(true);
  const [anchorE2, setAnchorE2] = useState(null);
  const [filter, setFilter] = useState([{ key: "", type: "", value: "" }]);
  const [searchByCalled, setsearchByCalled] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [rolePermission, setRolePermission] = React.useState([]);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [settlementFile, setSettlementFile] = React.useState(null);
  const [genLoading, setGenLoading] = React.useState(false);

  const filterOptons = [
    { property: "Settlement ID", value: "settlement_id" },
    { property: "Amount", value: "total_settlement_amount_str" },
    { property: "Merchant name", value: "full_name" },
    { property: "Bank Name", value: "bank_name" },
    { property: "Status", value: "status" },
  ];
  const filterContains = [
    { property: "Is", value: "is" },
    { property: "Like", value: "start_with" },
    { property: "Not Like", value: "not_contain" },
  ];
  const handleSearchButton = (e) => {
    setPage(0);
    setFilter([{ key: "", type: "", value: "" }]);
    setsearchByCalled(!searchByCalled);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.key === "Enter") {
      setPage(0);
      setFilter([{ key: "", type: "", value: "" }]);
      setsearchByCalled(!searchByCalled);
    }
  };

  const ResetFilter = async (event) => {
    setPage(0);
    setFilter([{ key: "", type: "", value: "" }]);
    setSearch("");
  };
  const handleFilter = async (JSONfilter) => {
    setPage(0);
    setFilter(JSONfilter);
    setSearch("");
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const pophandleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const pophandleClose = () => {
    setAnchorE2(null);
  };

  useEffect(() => {
    let options = {
      format: "A4",
    };
    const filtersval = filter[0].key != "" ? JSON.stringify(filter) : "";
    GetAllSettelments(page, rowsPerPage, searchBy, filtersval).then(
      (response) => {
        setData(response.data);
        setCount(response.count);
        setLoading(false);
      }
    );
  }, [page, rowsPerPage, filter]);

  const handleClickOpen = async (row) => {
    let HandleData = await ViewSettelments(row._id);
    setSelectedData(HandleData);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const openpop = Boolean(anchorE2);
  const id1 = openpop ? "simple-popover" : undefined;

  const componentRef = useRef();
  const contactLink = "https://geturbanledger.com/contact";
  const appLink = `https://play.google.com/store/apps/details?id=com.ul.urbanledger`;

  const handlePrint = useReactToPrint({
    content: () => {
      const tableStat = componentRef.current.cloneNode(true);
      const PrintElem = document.createElement("div");
      const header =
        `<div class="page-header"><img
          src="/pdf_top_sec.svg"
          alt=""
          className="settlement_pdf_templete"
        /></div>` +
        `<div class="page-footer">
          <div style="width: 100%;position: relative;");">
            <img src="/images/${"Urban Ledger Export PDF-07.jpg"}" alt="img">
          </div>
          <div style="width: 45%;position: absolute; bottom: 15%; left: 6%;z-index:999;">
                <b style="color: #fff"><i class="fa fa-phone-square" aria-hidden="true"></i> Helpline: </b>
                <a target="_blank" href="${contactLink}" style="color: #fff;text-decoration: none">${contactLink}</a><br>
                <span style="color: #fff;font-size: 12px;">T & C apply.</span>
            </div>
            <div style="width: 40%;position: absolute; bottom: 22%; right: 6%;text-align: right;">
                <span style="color: white;">Use Urban Ledger Now</span>
                <a target="_blank" href="${appLink}" style="margin-left: 5px;color: #726f7a;background-color: white;border-radius: 4px;padding: 3px 10px;text-decoration: none;">Install <img style="width: 13px;" src="/images/${"Install-01.svg"}" alt="img"></a>
            </div>         
        </div>`;
      PrintElem.innerHTML = header;
      PrintElem.appendChild(tableStat);
      return PrintElem;
    },
  });

  const downloadSettlement = async () => {
    if (showSpinner) {
      return;
    }
    setShowSpinner(true);
    const pdfRes = await GenerateSettlementPdf(selectedData?.data?._id);
    if (pdfRes?.status) {
      fetch(pdfRes?.link, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.download = `Settlement_Detail_${selectedData?.data?._id}.pdf`;
          link.click();
          setShowSpinner(false);
        });
    } else {
      setShowSpinner(false);
      toast(pdfRes?.message ? pdfRes.message : "Something went wrong", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  const onFileChange = async (event) => {
    setSettlementFile(event.target.files[0]);
  };

  const onFileUpload = async () => {
    try {
      setGenLoading(true);
      const formData = new FormData();
      formData.append("file", settlementFile);
      await AxiosBase.post(
        "/admin/merchant-settlement/transaction-file",
        formData
      );
      setSettlementFile(null);
      setGenLoading(false);
      toast("Settlement Files Uploaded", {
        type: "success",
        autoClose: 2000,
      });
    } catch (error) {
      setSettlementFile(null);
      setGenLoading(false);
      toast("Something went wrong", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  return (
    <NavbarHoc
      id={Config.PermissionId.Settlements}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Settlement</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                // sm={12}
                md={12}
                lg={12}
                // xl={4}
                className="merchant_grid_sec"
              >
                <div
                  className="merchant_list_filter_div"
                  style={{ height: "100%" }}
                >
                  <CssTextField
                    className="serachBar"
                    id="new-menu"
                    placeholder="Search"
                    variant="outlined"
                    // fullWidth="false"
                    onKeyUp={handleSearch}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton className="merchant_search_icon">
                            <i
                              onClick={handleSearchButton}
                              class="fa fa-search"
                              aria-hidden="true"
                            ></i>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Tooltip title="Filter records">
                    <div>
                      <i
                        style={{
                          fontSize: "30px",
                          marginLeft: "10px",
                          color: "#757575",
                          cursor: "pointer",
                        }}
                        onClick={pophandleClick}
                        class="fa fa-filter"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Tooltip>

                  {filter[0].key != "" ? (
                    <Tooltip title="Refresh to records">
                      <div>
                        <i
                          style={{
                            fontSize: "30px",
                            marginLeft: "10px",
                            color: "#757575",
                            cursor: "pointer",
                          }}
                          onClick={ResetFilter}
                          class="fa fa-refresh"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </div>
              </Grid>
              {!settlementFile && (
                <div>
                  <Button
                    className="merchant_list_add_new_merchant"
                    variant="contained"
                    startIcon={<SummarizeIcon />}
                    component="label"
                  >
                    Upload Settlement File
                    <input
                      hidden
                      name="file"
                      type="file"
                      onChange={onFileChange}
                    />
                  </Button>
                  <div className="merchant-settlement-sample-file">
                    <ErrorIcon />
                    <a
                      style={{ display: "block" }}
                      href={`${baseURL()}/api/merchant-settlement/sample-settlement-file`}
                      download
                    >
                      Click to download sample file
                    </a>
                  </div>
                </div>
              )}
              {settlementFile && !genLoading && (
                <>
                  <Button
                    variant="contained"
                    startIcon={<HighlightOffIcon />}
                    color="error"
                    onClick={() => {
                      setSettlementFile(null);
                    }}
                    style={{ borderRadius: "10px" }}
                    disabled={genLoading}
                  >
                    cancel
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<UploadFileIcon />}
                    onClick={onFileUpload}
                    style={{ borderRadius: "10px" }}
                    disabled={genLoading}
                  >
                    Submit
                  </Button>
                </>
              )}
              {genLoading && (
                <div style={{ margin: "0 auto" }}>
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
          <TableContainer
            className={
              data.length > 0 && !loading
                ? "table_container"
                : "table_container table_container_nodata"
            }
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Settlement id</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Merchant name</TableCell>
                  <TableCell>Bank name</TableCell>
                  <TableCell>Start date</TableCell>
                  <TableCell>End date</TableCell>
                  <TableCell>Completion date</TableCell>
                  <TableCell>UL charges</TableCell>
                  <TableCell>Margin</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Invoice</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row ">
                {data != " " &&
                  data.map((row, index) => (
                    <TableRow
                      style={{ background: "none" }}
                      className="tbl_id merchant_tbl_edit_icon"
                      key={index}
                    >
                      <TableCell className="tbl_id">
                        <Link
                          to={`/settlements/${row._id}`}
                          style={{ textDecoration: "none", color: "#1058ff" }}
                        >
                          {row.settlement_id}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {row.total_settlement_amount.toFixed(2)} {row.currency}
                      </TableCell>
                      <TableCell>{row.full_name}</TableCell>
                      <TableCell>
                        {!row.bank_name ? "NAN" : row.bank_name}
                      </TableCell>
                      <TableCell>
                        {moment(row.from_date).format("YYYY/MM/DD hh:mm A")}
                      </TableCell>

                      <TableCell>
                        {moment(row.to_date).format("YYYY/MM/DD hh:mm A")}
                      </TableCell>
                      <TableCell>
                        {row.completion_date
                          ? moment(row.completion_date)
                              .add(15, "days")
                              .format("YYYY/MM/DD hh:mm A")
                          : "NA"}
                      </TableCell>

                      <TableCell>
                        {row.total_fixed_fees_amount} {row.currency}
                      </TableCell>
                      <TableCell>
                        {row.total_margin_amount} {row.currency}
                      </TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>
                        <Button
                          className="settlement_view_btn"
                          onClick={() => handleClickOpen(row)}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress />
              </div>
            ) : data == "" ? (
              <div className="no_data_found loader_data_tbl">
                <p>No Data Found</p>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          {!loading && data.length > 0 ? (
            <TablePagination
              className="table_pagination"
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div>
        <Popover
          id={id1}
          open={openpop}
          anchorEl={anchorE2}
          onClose={pophandleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ marginLeft: "135px", height: "500px", marginTop: "10px" }}
        >
          <Typography sx={{ p: 2 }}>
            <Filter
              filter1={handleFilter}
              pophandleClose={pophandleClose}
              optionns={filterOptons}
              contains={filterContains}
              filter={filter}
            />
          </Typography>
        </Popover>
      </div>
      <div>
        <BootstrapDialog
          className="settlement_pdf_pop"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              className="settlement_pdf_pop_inner"
              id="pdf-section"
              gutterBottom
            >
              <div>
                <PdfSection
                  ref={componentRef}
                  data={selectedData?.data}
                  contactLink={contactLink}
                  appLink={appLink}
                />
              </div>
            </Typography>
          </DialogContent>
          <Button onClick={downloadSettlement}>
            {showSpinner ? "Pdf Downloading..." : "Download Pdf"}
          </Button>
        </BootstrapDialog>
      </div>
    </NavbarHoc>
  );
};

export default SettlementHistory;
