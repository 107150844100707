import React, { useState } from "react";
import { Paper } from "@mui/material";
import "./role.css";

function Role() {
  const [checkBox, setCheckBox] = useState(true);
  return (
    <Paper>
      <div style={{ marginLeft: "20px", height: "880px" }}>
        this is user role page
        <div
          style={{
            width: "360px",
            height: "40px",
            border: "1px solid grey",
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
            borderRadius: "5px",
          }}
        >
          <div className="searchBar">
            <input type="text" placeholder="search.." />
          </div>
          <div>
            <img
              style={{ width: "25px" }}
              src="/images/orderDetail/userIcon.svg"
            />
          </div>
        </div>
        <div>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "25px" }}
          >
            <div
              style={{
                width: "10%",
                height: "50px",
                backgroundColor: "#1058ff",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "white", fontWeight: 600, fontSize: "19px" }}>
                {" "}
                Assigned
              </p>
            </div>

            <div
              style={{
                width: "85%",
                height: "50px",
                backgroundColor: "#1058ff",
                display: "flex",
                alignItems: "center",
                marginLeft: "15px",
                borderRadius: "5px",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontWeight: 600,
                  fontSize: "19px",
                  marginLeft: "15px",
                }}
              >
                {" "}
                Role
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid  #789bf0",
              width: "95.7%",
              height: "60px",
              marginTop: "15px",
              backgroundColor: "#f2f6ff",
              borderRadius: "5px",
            }}
          >
            {/* <input style={{marginLeft:'20px'}} type='checkbox' />
                        <p style={{marginLeft:'30px',color:'#666666',fontSize:'20px',fontWeight:'500'}}>Administrator </p> */}
            <div style={{ marginTop: "5px" }}>
              <label className="container">
                <p
                  style={{
                    fontWeight: 600,
                    color: "#666666",
                    fontSize: "25px",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  Administrator
                </p>
                <input
                  onClick={() => setCheckBox(!checkBox)}
                  type="checkbox"
                  checked={checkBox}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid  grey",
              width: "95.7%",
              height: "60px",
              marginTop: "15px",
              backgroundColor: "none",
              borderRadius: "5px",
            }}
          >
            {/* <input style={{marginLeft:'20px'}} type='checkbox' />
                        <p style={{marginLeft:'30px',color:'#666666',fontSize:'20px',fontWeight:'500'}}>Agent </p> */}
            <div style={{ marginTop: "5px" }}>
              <label className="container">
                <p
                  style={{
                    fontWeight: 600,
                    color: "#666666",
                    fontSize: "25px",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  {" "}
                  Agent
                </p>
                <input
                  onClick={() => setCheckBox(!checkBox)}
                  type="checkbox"
                  checked={checkBox}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid  #789bf0",
              width: "95.7%",
              height: "60px",
              marginTop: "15px",
              backgroundColor: "#f2f6ff",
              borderRadius: "5px",
            }}
          >
            {/* <input style={{marginLeft:'20px'}} type='checkbox' />
                        <p style={{marginLeft:'30px',color:'#666666',fontSize:'20px',fontWeight:'500'}}>Administrator </p> */}
            <div style={{ marginTop: "5px" }}>
              <label className="container">
                <p
                  style={{
                    fontWeight: 600,
                    color: "#666666",
                    fontSize: "25px",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  {" "}
                  sssss
                </p>
                <input
                  onClick={() => setCheckBox(!checkBox)}
                  type="checkbox"
                  checked={checkBox}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid  #789bf0",
              width: "95.7%",
              height: "60px",
              marginTop: "15px",
              backgroundColor: "#f2f6ff",
              borderRadius: "5px",
            }}
          >
            {/* <input style={{marginLeft:'20px'}} type='checkbox' />
                        <p style={{marginLeft:'30px',color:'#666666',fontSize:'20px',fontWeight:'500'}}>Administrator </p> */}
            {/* <div style={{marginTop:'5px'}} >
                            <label className="container"><p style={{fontWeight:600,color:'#666666',fontSize:'25px',margin:'0',padding:'0'}} >Administrator</p>
                                    <input onClick={()=> setCheckBox(!checkBox)} type="checkbox" checked={checkBox}  />
                                    <span className="checkmark"></span>
                            </label>
                        </div> */}
          </div>
        </div>
      </div>
    </Paper>
  );
}

export default Role;
