import React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import AxiosBase from "../../../networkRequest/axiosBase";
import "./index.css";
import moment from "moment";
import Grid from "@mui/material/Grid";
import PosOrders from "../pos-orders";
import CircularProgress from "@mui/material/CircularProgress";
import NavbarHoc from "../../../pages/navhoc/index";
import Config from "../../../config.json";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
  },
  "input#another-menu": {
    width: "520px",
    height: "30px",
  },
});

function PosPaymentRequestDetails() {
  const [data, setData] = useState({});
  const [loading, setLoading] = React.useState(true);
  const [rolePermission, setRolePermission] = React.useState([]);

  const id = useParams();

  useEffect(() => {
    Checkchanges();
  }, []);

  const Checkchanges = async () => {
    if (!id.id) {
      setLoading(false);
    }
    try {
      const response = await AxiosBase.get(`/admin/pos/request/${id.id}`);
      console.log(response);
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <NavbarHoc
      id={Config.PermissionId.PosRequest}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <Box>
        <h1 className="merchant_title">Invoice Details</h1>
      </Box>
      <div className="merchant_list_main_div">
        <div className="settlement_detail_inner_title_div">
          <p className="settlement_title">Invoice ID</p>
          <h3>{data._id}</h3>
        </div>

        {loading ? (
          <div className="no_data_found loader_data_tbl">
            <CircularProgress />
          </div>
        ) : data == "" ? (
          <div className="no_data_found loader_data_tbl">
            <p>No Data Found</p>
          </div>
        ) : (
          <>
            <div className="settlement_detail_input_dev">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">ID</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={data._id}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span>Split Type</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={
                        data.split_type
                          ? data.split_type == "EQUAL_SPLIT"
                            ? "Equal Split"
                            : "Custom Amount"
                          : "N/A"
                      }
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Merchant name</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={data.merchant_name}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span>Split User</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={
                        data.split_type
                          ? data.split_type == "EQUAL_SPLIT"
                            ? data.number_of_split
                            : data.total_split_pay_for
                          : 1
                      }
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Branch name</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={data.branch_name}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span>Amount</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={data.amount?.toFixed(2) + " " + data.currency}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Terminal name</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={data.terminal_name}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span>Customer Paid</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={
                        data.total_paid_amount?.toFixed(2) + " " + data.currency
                      }
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span className="settlementDetails">Created Date</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={moment(data.createdAt).format(
                        "YYYY/MM/DD hh:mm A"
                      )}
                      disabled
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span>Tip amount</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={
                        data.total_tip_amount?.toFixed(2) + " " + data.currency
                      }
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span>Merchant invoice ID</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={data.merchant_invoice_id || "N/A"}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span>Total received</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={
                        data.total_received_amount?.toFixed(2) +
                        " " +
                        data.currency
                      }
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span>status</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={data.payment_status}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="settlementDetails_inner_grid_div">
                    <span>Unpaid amount</span>
                    <CssTextField
                      id="new-menu"
                      fullWidth
                      variant="outlined"
                      value={
                        Number(
                          (
                            data.amount -
                            Number(data.total_paid_amount.toFixed(2))
                          ).toFixed(2)
                        ) +
                        " " +
                        data.currency
                      }
                      disabled
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div>
              <PosOrders data={data} rolePermission={rolePermission} />
            </div>
          </>
        )}
      </div>
    </NavbarHoc>
  );
}

export default PosPaymentRequestDetails;
