import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Paper } from "@mui/material";
import RoleFun from "./rolefunctionlity/RoleFunctionality";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import { useParams } from "react-router-dom";
import {
  getRolesById,
  getRoles,
  AddRole,
  UpdateRole,
} from "../../networkRequest/request/useraccesscontrol";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import NavbarHoc from "../../pages/navhoc/index";
import Config from "../../config.json";

function RoleTabs() {
  const [value, setValue] = React.useState(0);
  const [roleId, setId] = React.useState("");
  const [data, setData] = React.useState([]);
  const [savedata, setsavedata] = React.useState(false);
  const [update, setUpdate] = React.useState("Add");
  const [loader, setLoader] = React.useState(false);
  const history = useNavigate();
  const [rolePermission, setRolePermission] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const id = useParams();
  useEffect(() => {
    if (id.id) {
      setUpdate("Edit");
      setId(id.id);
      getRolesById(id.id).then((rolelist) => {
        setData(rolelist.data);
      });
    }
  }, []);

  useEffect(() => {
    saveRoleDetails();
  }, [savedata]);

  const saveRoleDetails = async () => {
    try {
      var reqBody = {
        role_name: data.role_name,
        menu_permissions: data.menu_permissions,
        is_active: data.is_active ? data.is_active : false,
      };
      var isvalidata = validate(reqBody.menu_permissions);
      if (
        reqBody.role_name == "" ||
        reqBody.role_name.length < 2 ||
        reqBody.role_name.length > 20
      ) {
        return toast("Rolename should be between 2 to 20 characters", {
          type: "error",
          autoClose: 2000,
        });
      } else if (!isvalidata) {
        return toast("Please Select Menu permission", {
          type: "error",
          autoClose: 2000,
        });
      } else {
        if (savedata) {
          if (roleId) {
            reqBody.id = roleId;
            setLoader(true);
            const res = await UpdateRole(reqBody);
            setLoader(false);
            if (res.status === 200) {
              setUpdate(true);
              history("/role");
              return toast("Role Updated successfully", {
                type: "success",
                autoClose: 2000,
              });
            } else {
              return toast(res.data.message, {
                type: "error",
                autoClose: 2000,
              });
            }
          } else {
            setLoader(true);
            const res = await AddRole(reqBody);
            setLoader(false);
            if (res.status === 200) {
              setUpdate(true);
              history("/role");
              return toast("Role Added successfully", {
                type: "success",
                autoClose: 2000,
              });
            } else {
              return toast(res.data.message, {
                type: "error",
                autoClose: 2000,
              });
            }
          }
        }
      }
    } catch (error) {}
  };

  const saveRoleData = async () => {};
  const roleinfo = (data1) => {
    setData({
      ...data1,
    });
    if (savedata) {
      setsavedata(false);
    } else {
      setsavedata(true);
    }
  };

  return (
    <NavbarHoc
      id={Config.PermissionId.Role}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <List className="">
        <ListItem className="Role_merchant_edit_drower_menu_top_sec">
          <p>{update}: Permission</p>
        </ListItem>
      </List>
      <div className="merchant_list_main_divinner merchant_detail_tab_sec padding15">
        <div>
          {!loader ? (
            <RoleFun
              roleinfo={roleinfo}
              roleId={roleId}
              data={data}
              rolePermission={rolePermission}
            />
          ) : (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress style={{ color: "#1058ff" }} />
            </div>
          )}
        </div>
      </div>
    </NavbarHoc>
  );
}

function validate(permissions1) {
  try {
    var isvalidate = true;
    if (permissions1.length == 0) {
      return false;
    }
    permissions1.map((e) => {
      if (
        e.main_menu_id != "" &&
        e.permissions.length > 0 &&
        e.sub_menu_id != ""
      ) {
      } else {
        isvalidate = false;
      }
    });
    return isvalidate;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export default RoleTabs;
