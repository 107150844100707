import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import KycHistory from '../kyc-history/index';
// import ViewDocument from '../../viewDocument'
import KycDetail from '../kyc-details/KycDetail'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{mt:'15px'}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TradeTab(props) {
    

    return (
      <Box sx={{ maxWidth: "100%" }}>
        <Box>
          <h1 className="merchant_title">KYC</h1>
        </Box>
        <div className="merchant_list_main_div">
          <Box className='kyc_tabs' sx={{ borderColor: "divider" , ml:'15px' }}>
            <Tabs
              value={props.value}
              onChange={props.handleChange}
              aria-label="basic tabs example"
              className="kycmarginleft"
            >
              <Tab label="KYC Request Details" {...a11yProps(0)} />
              <Tab label="History" {...a11yProps(1)} />
              {/* <Tab label="View Documents" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={props.value} index={0}>
            <KycDetail {...props} />
          </TabPanel>
          <TabPanel value={props.value} index={1}>
            <KycHistory />
          </TabPanel>
        </div>
      </Box>
    );
}
