import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./business.css";
import Button from "@mui/material/Button";
import Business from "./business.js";

import { getAllBusiness } from "../../../networkRequest/request/getBusiness";

function BusinessTab(props) {
  const [value, setValue] = React.useState(0);
  const [tabcount, settabcount] = React.useState(0);
  const [tabsdata, settabsdata] = React.useState([]);
  const [tabdetails, settabdetails] = React.useState([]);
  useEffect(() => {
    const id = props._id;
    if (id) {
      getAllBusiness(id).then((data1) => {
        if (data1.count > 0) {
          settabcount(data1?.count);
          settabsdata(data1?.data);
          setValue(0);
          settabdetails(data1?.data[0]);
        }
      });
    }
  }, [props._id]);
  const handleChange = (event, newValue) => {
    setValue(parseInt(newValue));
    settabdetails(tabsdata[parseInt(newValue)]);
  };
  return (
    <>
      <div className="premium_plan_tabs_div">
        {tabcount > 0 ? (
          <div className="premium_tabs_btns">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabsdata?.map((t, index) => {
                return (
                  <Tab
                    style={{ textAlign: "center" }}
                    label={t.name}
                    wrapper="true"
                    wrapped="true"
                  />
                );
              })}
            </Tabs>

            {/* <Button
                className="merchant_list_add_new_merchant branch_add_btn"
                variant="contained"
                startIcon={<img src="/Upgrade-01.svg" alt="" />}
              >
                Export To CSV
          </Button> */}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div>
        <Business currency={props.currency} tabdetails={tabdetails}></Business>
      </div>
    </>
  );
}

export default BusinessTab;
