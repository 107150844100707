import React from "react";
import Topheader from "../../universal-components/header/topHeader";
import { Route, Routes } from "react-router-dom";
import KycDataTable from "../../Components/KYC/kyc-grid-table/index";
import ConfigTabMain from "../../Components/confiigurations/index";

const ConfigurationMain = () => {
  return (
    <>
      <div style={{ padding: "15px", borderRadius: "5rem", width: "98%" }}>
        <Topheader />
        <Routes>
          <Route exact path="" element={<ConfigTabMain />} />
          <Route exact path="/gateway" element={<ConfigTabMain />} />
        </Routes>
      </div>
    </>
  );
};

export default ConfigurationMain;
