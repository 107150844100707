import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import TablePagination from "@mui/material/TablePagination";
import AxiosBase from "../../networkRequest/axiosBase";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Filter from "../filter-component/Filter";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import PremiumOrderDetails from "./premium-order-details/PremiumOrderDetails";
import { makeStyles } from "@mui/styles";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import NavbarHoc from "../../pages/navhoc/index";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseRounded from "@mui/icons-material/CloseRounded";
import Tooltip from "@mui/material/Tooltip";
import DateRangeComp from "../datepicker";
import Config from "../../config.json";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});
const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
    backgroundColor: "#FEFEFE",
  },
});

// main function

const PremiumOrders = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [count, setCount] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchBy, setSearch] = useState();
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rolePermission, setRolePermission] = React.useState([]);
  const open = Boolean(anchorEl);
  const [filter, setFilter] = useState([{ key: "", type: "", value: "" }]);
  const [searchByCalled, setsearchByCalled] = useState(false);
  const [date, setDate] = React.useState({
    startDate: "",
    endDate: "",
  });
  //  transaction Id, Payment Type, Status, Customer name, email
  const filterOptons = [
    { property: "Transaction Id", value: "transactionId" },
    { property: "Super Merchant name", value: "supermerchant_name" },
    { property: "Merchant name", value: "merchant_name" },
    { property: "Plan", value: "planData.name" },
    { property: "Amount", value: "amountStr" },
  ];
  const filterContains = [
    { property: "Is", value: "is" },
    { property: "Like", value: "start_with" },
    { property: "Not Like", value: "not_contain" },
  ];
  const handledates = (values) => {
    setDate(values);
  };
  const handleSearchButton = (e) => {
    setPage(0);
    setFilter([{ key: "", type: "", value: "" }]);
    setsearchByCalled(!searchByCalled);
  };
  const handleSearch = (event) => {
    setSearch(event.target.value);
    setPage(0);
    setFilter([{ key: "", type: "", value: "" }]);
  };

  const ResetFilter = async (event) => {
    setFilter([{ key: "", type: "", value: "" }]);
    setDate({
      startDate: "",
      endDate: "",
    });
  };
  const [anchorE2, setAnchorE2] = React.useState(null);
  const handleFilter = async (JSONfilter) => {
    setFilter(JSONfilter);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const pophandleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const pophandleClose = () => {
    setAnchorE2(null);
  };

  useEffect(() => {
    const filtersval = filter[0].key != "" ? JSON.stringify(filter) : "";
    let dateparams = "";
    if (date.startDate != "") {
      dateparams = `dateRange=custom&dateFrom=${date.startDate}&dateTo=${date.endDate}&dateField=createdAt`;
    }
    AxiosBase.get(
      `admin/subscriptions?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        // `&sortBy=completed&sortOrder=asc` +
        `&search=${searchBy ? searchBy : ""}` +
        `&filter=${filtersval}&${dateparams}`
    )
      .then((response) => {
        setData(response.data.data);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [page, rowsPerPage, count, searchBy, filter, date]);

  const openpop = Boolean(anchorE2);
  const id1 = openpop ? "simple-popover" : undefined;

  // filter popup sections end

  return (
    <NavbarHoc
      id={Config.PermissionId.PremiumOrders}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <div style={{ width: "100%" }}>
        <Box>
          <h1 className="merchant_title">Premium Orders</h1>
        </Box>

        <div className="merchant_list_main_div">
          <div className="merchant_list_inner_title_div">
            <div className="merchant_list_search_filter">
              <Grid
                item
                xs={12}
                // sm={12}
                md={8}
                lg={6}
                // xl={4}
                className="merchant_grid_sec"
              >
                <div
                  className="merchant_list_filter_div"
                  style={{ height: "100%" }}
                >
                  <CssTextField
                    className="serachBar"
                    id="new-menu"
                    placeholder="Search"
                    variant="outlined"
                    // fullWidth="false"
                    onKeyUp={handleSearch}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton className="merchant_search_icon">
                            <i
                              onClick={handleSearchButton}
                              class="fa fa-search"
                              aria-hidden="true"
                            ></i>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Tooltip title="Filter records">
                    <div>
                      <i
                        style={{
                          fontSize: "30px",
                          marginLeft: "10px",
                          color: "#757575",
                          cursor: "pointer",
                        }}
                        onClick={pophandleClick}
                        class="fa fa-filter"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Tooltip>
                  <DateRangeComp handledates={handledates}> </DateRangeComp>

                  {filter[0].key != "" || date.startDate != "" ? (
                    <Tooltip title="Refresh to records">
                      <div>
                        <i
                          style={{
                            fontSize: "30px",
                            marginLeft: "10px",
                            color: "#757575",
                            cursor: "pointer",
                          }}
                          onClick={ResetFilter}
                          class="fa fa-refresh"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Tooltip>
                  ) : (
                    // {filter[0].key != "" ? (
                    //   <Button
                    //     className="ClearFilter"
                    //     variant="contained"
                    //     startIcon={<CloseRounded />}
                    //     onClick={ResetFilter}
                    //   >
                    //     Clear
                    //   </Button>
                    // <Button
                    //   variant="outlined"
                    //   color="secondary"
                    //   startIcon={<CloseTwoTone />}
                    //   onClick={ResetFilter}
                    //  className="clearfilter"
                    // >
                    //   Clear
                    // </Button>
                    <></>
                  )}
                </div>
              </Grid>
            </div>
          </div>
          <TableContainer
            className={
              data?.length && !loading
                ? "table_container"
                : "table_container table_container_nodata"
            }
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Transaction id</TableCell>
                  <TableCell>Super Merchant Name</TableCell>
                  <TableCell>Merchant name</TableCell>
                  <TableCell>Plan</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Start date</TableCell>
                  <TableCell>End date</TableCell>
                  <TableCell>Created on</TableCell>
                  <TableCell>Updated on</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row ">
                {data?.length > 0 &&
                  data.map((row, index) => (
                    <TableRow
                      style={{ background: "none" }}
                      className="order_list_data_body"
                    >
                      <TableCell className="tbl_id">
                        {/* <Link
                          to="#"
                          style={{ textDecoration: "none", color: "#1058ff" }}
                        >
                          {row.order_id}
                        </Link> */}

                        <PremiumOrderDetails
                          order_id={row.trans_id}
                          subscription_id={row._id}
                          plan={row}
                          display_order={row.transactionId}
                          className={`table_id_data`}
                          ResetFilter={ResetFilter}
                          rolePermission={rolePermission}
                        />
                      </TableCell>
                      <TableCell>
                        {!row.supermerchant_name
                          ? "NA"
                          : row.supermerchant_name}
                      </TableCell>
                      <TableCell>{row.merchant_name}</TableCell>
                      <TableCell>{row.planData?.name}</TableCell>
                      <TableCell>
                        {!row.amountStr ? "0" : row.amountStr}
                      </TableCell>
                      <TableCell>
                        {moment(row.start_at).format("YYYY/MM/DD hh:mm A")}
                      </TableCell>
                      <TableCell>
                        {moment(row.expiry_at).format("YYYY/MM/DD hh:mm A")}
                      </TableCell>
                      <TableCell>
                        {moment(row.createdAt).format("YYYY/MM/DD hh:mm A")}
                      </TableCell>
                      <TableCell>
                        {moment(row.updatedAt).format("YYYY/MM/DD hh:mm A")}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress />
              </div>
            ) : !data?.length ? (
              <div className="no_data_found loader_data_tbl">
                <p>No Data Found</p>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
          {!loading ? (
            <TablePagination
              className="table_pagination"
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div>
        <Popover
          id={id1}
          open={openpop}
          anchorEl={anchorE2}
          onClose={pophandleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ marginLeft: "135px", height: "500px", marginTop: "10px" }}
        >
          <Typography sx={{ p: 2 }}>
            <Filter
              filter1={handleFilter}
              pophandleClose={pophandleClose}
              optionns={filterOptons}
              contains={filterContains}
              filter={filter}
            />
          </Typography>
        </Popover>
      </div>
    </NavbarHoc>
  );
};

export default PremiumOrders;
