import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link, NavLink, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { viewCustomerDetails } from "../../../networkRequest/request/customers";
import { merchantsettlement } from "../../../networkRequest/request/customers";
import { kycbymerchantid } from "../../../networkRequest/request/customers";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MobileFriendly from "@mui/icons-material/MobileFriendly";
import Web from "@mui/icons-material/Web";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ApprovalIcon from "@mui/icons-material/Approval";
import Branches from "../branches/Branches";
import MerchantKyc from "../merchant-kyc/Merchant-kyc";
import PremiumTabs from "../premium-plan/PremiumTabs";
import BusinessTab from "../business/businessTab";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Radio from "@mui/material/Radio";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CashBookDetails from "../cashbook-details/CashBookDetails";
import AddBranch from "../branches/AddBranch";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { updateMerchant } from "../../../networkRequest/request/merchant";
import { toast } from "react-toastify";
import KycDetails from "../kyc-details/KycDetails";
import Settlement from "../settlement/Settlement";

import FeesAndMargin from "../../confiigurations/fees-and-margin/FeesAndMargin";
import SettlementConf from "../../confiigurations/Settlements/Settlement";
import Gateway from "../../confiigurations/gateway/Gateway";
import MerchantOnBoarding from "../../confiigurations/merchant-onboarding/MerchantOnBoarding";
import PremiumPlan from "../../confiigurations/Premium-Plan/PremiumPlan";
import FraudAndRisk from "../../confiigurations/Fraud-and-Risk/FraudAndRisk";

import ConfigurationTab from "../../confiigurations/ConfigurationTab";
import CopyAll from "@mui/icons-material/CopyAll";
import Config from "../../../config.json";
import Tooltip from "@mui/material/Tooltip";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AxiosBase from "../../../networkRequest/axiosBase";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MerchantAcquirerlist from "../MerchantAcquirerlist/MerchantAcquirerlist";
import MerchantAcquireTabMain from "../MerchantAcquirerDetail/index";
import MerchantPanelMenus from "../../confiigurations/MerchantPanelMenus";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});
const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme?.palette?.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: "8px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "16px",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function MerchantDetails(props) {
  const { rolePermission, allPermission } = props;
  const search = useLocation().search;
  const [data, setData] = React.useState({});
  const [cust_id, setcustomerId] = React.useState("");
  const [kycdata, setKycData] = React.useState({});
  const [kycdataid, setKycDataid] = React.useState({});
  const [settlementdtl, setsettlement] = React.useState({});
  const [enable, setEnable] = useState({});
  const [update, setupdate] = useState(false);
  const [enablemerchant, setenablemerchant] = React.useState(true);
  const [enablekyc, setenablekyc] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = React.useState("panel1");
  const [commentmandatory, setcommentmandatory] = React.useState(false);
  const [allowDocRequest, setAllowDocRequest] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const CHARACTER_LIMIT = 50;
  const ZIP_LIMIT = 6;
  const mobile_LIMIT = 16;
  const [acquirerData, setAcquirerData] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [acquirerShowType, setAcquirerShowType] = React.useState("list");
  const [selectedAcquirer, setSelectedAcquirer] = React.useState(null);
  const history = useNavigate();
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ mt: "15px" }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (panel) => (event) => {
    // setExpanded(panel);
    if (panel == expanded) {
      setExpanded("");
    } else {
      setExpanded(panel);
    }
  };

  const kycdatafetch = (data1) => {
    setKycData(data1);
  };

  const [firsterror, setFirstError] = useState({
    error: false,
    message: "",
  });
  const [merchanterror, setmerchanterror] = useState({
    error: false,
    message: "",
  });
  const [lasterror, setLastError] = useState({
    error: false,
    message: "",
  });

  const [merchantError, setMerchantError] = useState({
    error: false,
    message: "",
  });

  const [numbererror, setNumberError] = useState({
    error: false,
    message: "",
  });

  const [BusinessEmailError, setBusinessEmailError] = useState({
    error: false,
    message: "",
  });

  const [AddressError, setAddressError] = useState({
    error: false,
    message: "",
  });

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severiety: "success",
  });

  useEffect(() => {
    const customerId = new URLSearchParams(search).get("merchantId");
    setcustomerId(customerId);
  }, []);

  useEffect(() => {
    const customerId = new URLSearchParams(search).get("merchantId");
    setLoading(true);
    viewCustomerDetails(customerId).then((custdata) => {
      setData(custdata?.data);
      setcustomerId(customerId);
      kycbymerchantid(custdata?.data?._id).then((kycdatares) => {
        setKycData(kycdatares?.kycdata);
        setKycDataid(kycdatares?.kycdata?._id);
        setLoading(false);
      });
    });
    merchantsettlement(customerId).then((setdata) => {
      setsettlement(setdata?.respSettlement);
    });
  }, [search, update, cust_id]);

  const getConfigDetail = () => {};

  const handleEdit = () => {
    setenablemerchant(false);
  };
  const handleCancel = () => {
    setenablemerchant(true);
  };
  const handleKycEdit = () => {
    setenablekyc(false);
  };
  const handleKycCancel = () => {
    setenablekyc(true);
  };
  const handleUpdate = async () => {
    if (data.first_name != "" && firsterror.error) {
      setupdate(!update);
      return (
        toast(firsterror.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (data.last_name != "" && lasterror.error) {
      setupdate(!update);
      return (
        toast(lasterror.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (numbererror.error) {
      setupdate(!update);
      return (
        toast(numbererror.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (BusinessEmailError.error) {
      setupdate(!update);
      return (
        toast(BusinessEmailError.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (AddressError.error) {
      setupdate(!update);
      return (
        toast(AddressError.message.toString()),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (
      data.mobile_no == "" ||
      data.email_id == "" ||
      data.merchant_name == ""
    ) {
      setupdate(!update);
      return (
        toast("Please fill merchant information"),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else if (commentmandatory && (data.comments == "" || !data.comments)) {
      setupdate(!update);
      return (
        toast("Please enter comments "),
        {
          type: "error",
          autoClose: 2000,
        }
      );
    } else {
      var merchant_data = {
        ...data,
        first_name: data.firstName,
        last_name: data.lastName,
        mobile_no: data.phoneNumber,
        mobile_code: data.countrycode ? data.countrycode : data.mobile_code,
      };
      merchant_data.mobile_no = merchant_data.mobile_no.replace(/\+/g, "");
      const reqBody = {
        MERCHANT_DATA: JSON.stringify(merchant_data),
      };
      try {
        reqBody._id = cust_id;
        setLoading(true);
        var res = await updateMerchant(reqBody);
        setenablemerchant(true);
        setLoading(false);
        if (res?.status == 200) {
          setupdate(!update);
          setenablekyc(true);
          return toast("Merchant Data Updated Successfully.", {
            type: "success",
            autoClose: 2000,
          });
        } else {
          setupdate(!update);
          return toast(res?.response?.data?.message?.toString(), {
            type: "error",
            autoClose: 2000,
          });
        }
      } catch (err) {
        setupdate(!update);
        return toast(JSON.stringify(res), {
          type: "error",
          autoClose: 2000,
        });
      }
    }
  };

  const edit = (data) => {
    setEnable({ ...enable, data });
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  const onUploadDocument = (kycId) => {
    setKycDataid(kycId);
    setAllowDocRequest(true);
  };

  const approvalRequest = () => {
    if (
      kycdata?.kyc_data.filter((x) => x.is_verified == "Pending").length == 0 &&
      !allowDocRequest
    ) {
      return toast("Please upload all the required documents", {
        type: "error",
        autoClose: 2000,
      });
    }
    setSpinner(true);
    AxiosBase.get(`/admin/kyc/approval-request-kyc-document/${kycdataid}`)
      .then((res) => {
        if (res?.status == 200) {
          kycdata.kycStatus = "In progress";
          toast("Successfully document approval request sent", {
            type: "success",
            autoClose: 2000,
          });
          setSpinner(false);
          return true;
        }
      })
      .catch((err) => {
        toast(
          err?.response?.data?.message
            ? err?.response?.data?.message.toString()
            : "Something went wrong",
          {
            type: "error",
            autoClose: 2000,
          }
        );
        setSpinner(false);
        return false;
      });
  };

  const redirecttodetail = (val, type) => {
    setSelectedAcquirer(val);
    setAcquirerShowType(type);
  };

  return (
    <div className="merchant_detail_tab_main_page">
      <Accordion
        className="expanded_bar margintop"
        sx={{ mt: "20px" }}
        expanded={expanded === "panel1"}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography onClick={handleChange("panel1")}>
            Merchant Information
          </Typography>

          {data.channelId == "Admin" ? (
            <>
              <Tooltip title="This Merchant onboarding done by Admin Pannel">
                <IconButton color="primary">
                  <Web className="merchant_inpt_icon" />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="This Merchant onboarding done by Mobile App">
                <IconButton color="primary">
                  <MobileFriendly className="merchant_inpt_icon" />
                </IconButton>
              </Tooltip>
            </>
          )}

          <Stack className="topmargin10" spacing={2} alignItems="center">
            <Stack direction="row" spacing={1}>
              {data.is_active ? (
                <Chip
                  variant="outlined"
                  size="large"
                  label="Active"
                  color="success"
                />
              ) : (
                <Tooltip title="Only active merchants will be able to login into the mobile app and web app">
                  <Chip
                    size="large"
                    variant="outlined"
                    label="Inactive"
                    color="success"
                    icon=""
                  />
                </Tooltip>
              )}

              {!data?.is_payment_allowed ? (
                <Tooltip title="Payment Disabled' means the user will be able to login into the app however would not be able to make a request for the money or receive any payments.">
                  <Chip
                    size="large"
                    variant="outlined"
                    label="Payment disabled"
                    color="primary"
                  />
                </Tooltip>
              ) : (
                <></>
              )}

              {data.is_locked ? (
                <Tooltip title="The account Lock means more than three incorrect OTP attempts were made. The account will be automatically unlocked after 24 hours or click here 'Unlock' to Lift restrictions.                  ">
                  <Chip
                    size="large"
                    variant="outlined"
                    label="Locked"
                    color="primary"
                  />
                </Tooltip>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>

          {rolePermission.includes("EDIT") && (
            <Button
              onClick={!loading ? handleEdit : () => {}}
              className="merchant_list_add_new_merchant branch_add_btn kycedit"
              variant="contained"
              startIcon={<EditIcon />}
            >
              Edit Merchant Information
            </Button>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress />
            </div>
          ) : (
            <Grid container spacing={2} className="merchant_main_grid">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="merchant_grid_sec"
              >
                <div className="sep_inpt_sec">
                  <p>First Name</p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="First name"
                      fullWidth
                      helperText={
                        firsterror.error == true ? firsterror.message : ""
                      }
                      disabled={enablemerchant}
                      inputProps={{
                        maxlength: 15,
                      }}
                      value={data?.firstName}
                      required
                      onChange={(e) => {
                        if (
                          e.target.value.length < 3 ||
                          e.target.value.length > 15
                        ) {
                          setFirstError({
                            error: true,
                            message:
                              "First Name must be between 3 to 15 characters",
                          });
                        } else {
                          setFirstError({ error: false, message: "" });
                        }
                        setData({
                          ...data,
                          firstName: e.target.value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <PersonOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="merchant_grid_sec"
              >
                <div className="sep_inpt_sec">
                  <p>
                    Business Name<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Business Name"
                      fullWidth
                      helperText={
                        firsterror.error == true ? firsterror.message : ""
                      }
                      disabled={enablemerchant}
                      inputProps={{
                        maxlength: 15,
                      }}
                      value={data?.merchant_name}
                      required
                      onChange={(e) => {
                        if (
                          e.target.value.length < 3 ||
                          e.target.value.length > 15
                        ) {
                          setmerchanterror({
                            error: true,
                            message:
                              "Merchant Name must be between 3 to 15 characters",
                          });
                        } else {
                          setmerchanterror({ error: false, message: "" });
                        }
                        setData({
                          ...data,
                          merchant_name: e.target.value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <PersonOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>Last Name</p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      disabled={enablemerchant}
                      fullWidth
                      placeholder="Last name"
                      value={data.lastName}
                      required
                      inputProps={{
                        maxlength: 15,
                      }}
                      onChange={(e) => {
                        if (
                          (e.target.value.length > 0 &&
                            e.target.value.length < 3) ||
                          e.target.value.length > 15
                        ) {
                          setLastError({
                            error: true,
                            message:
                              "Last Name must be between 3 to 15 characters",
                          });
                        } else if (e.target.value.length == 0) {
                          setLastError({ error: false, message: "" });
                        } else {
                          setLastError({ error: false, message: "" });
                        }

                        setData({
                          ...data,
                          lastName: e.target.value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <PersonOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Mobile Number<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <PhoneInput
                      className="mobile-input"
                      name="mobile_no"
                      country={"ae"}
                      countryCodeEditable={true}
                      disabled={enablemerchant}
                      inputProps={{
                        maxlength: mobile_LIMIT,
                      }}
                      value={data.phoneNumber}
                      onChange={(val, countrycode) => {
                        val = val.replace(/-|\s/g, "");
                        if (val.length < 12 || val.length > 16) {
                          setNumberError({
                            error: true,
                            message: "Enter valid Number",
                          });
                        } else {
                          setNumberError({ error: false, message: "" });
                        }
                        setData({
                          ...data,
                          phoneNumber: val,
                          countrycode: countrycode.dialCode,
                        });
                      }}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>Address</p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      disabled={enablemerchant}
                      placeholder="Address"
                      fullWidth
                      inputProps={{
                        maxlength: CHARACTER_LIMIT,
                      }}
                      value={data?.address}
                      onChange={(e) =>
                        setData({
                          ...data,
                          address: e.target.value,
                        })
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <LocationOnOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>
                    Business Email<span>*</span>
                  </p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="email"
                      disabled={enablemerchant}
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Business Email"
                      fullWidth
                      value={data?.email_id}
                      required
                      inputProps={{
                        maxlength: CHARACTER_LIMIT,
                      }}
                      error={
                        !data?.email_id?.match(
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        ) && data?.email_id !== ""
                      }
                      helperText={
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                          data?.email_id
                        ) || data?.email_id === ""
                          ? " "
                          : "Business email must be between 6 to 50 characters"
                      }
                      onChange={(e) => {
                        if (
                          !e.target.value.match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                          ) &&
                          e.target.value !== ""
                        ) {
                          setBusinessEmailError({
                            error: true,
                            message: "Please enter valid business email id",
                          });
                        } else {
                          setBusinessEmailError({ error: false, message: "" });
                        }
                        setData({
                          ...data,
                          email_id: e.target.value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <img
                                src={`${process.env.PUBLIC_URL}/top-email.svg`}
                                alt=""
                                style={{ width: "25px", height: "25px" }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            {data?.is_email_verified ? (
                              <Tooltip title="Email Verified">
                                <IconButton color="primary">
                                  <img
                                    src="/8.png"
                                    alt=""
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Email Not Verified.Please click here to resend email.">
                                <IconButton color="primary">
                                  <img
                                    src="/2.png"
                                    alt=""
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>Zip Code</p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="text"
                      disabled={enablemerchant}
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Zipcode"
                      fullWidth
                      inputProps={{
                        maxlength: ZIP_LIMIT,
                      }}
                      value={data?.zipCode}
                      onChange={(e) =>
                        setData({
                          ...data,
                          zipCode: e.target.value,
                        })
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <LocationOnOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>MID</p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="text"
                      disabled="true"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Merchant Id"
                      fullWidth
                      inputProps={{
                        maxlength: ZIP_LIMIT,
                      }}
                      value={data?.mid}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <Tooltip title="Click her to copy Merchant Id">
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(data?.mid);
                                  return (
                                    toast("MID copied successfully"),
                                    {
                                      type: "success",
                                      autoClose: 2000,
                                    }
                                  );
                                }}
                                color="primary"
                              >
                                {<CopyAll />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="sep_inpt_sec">
                  <p>Public Key</p>

                  <div className="inp_sep_div">
                    <CssTextField
                      id="new-menu"
                      type="text"
                      disabled="true"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Public Key"
                      fullWidth
                      inputProps={{
                        maxlength: ZIP_LIMIT,
                      }}
                      value={data?.public_key}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <Tooltip title="Click her to copy Public Key">
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    data?.public_key
                                  );
                                  return (
                                    toast("Public Key copied successfully"),
                                    {
                                      type: "success",
                                      autoClose: 2000,
                                    }
                                  );
                                }}
                                color="primary"
                              >
                                {<CopyAll />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Grid>
              {enablemerchant ? (
                <></>
              ) : (
                <div className="statusbar">
                  <p>Status:</p>
                  <Grid container spacing={2} className="merchant_main_grid">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="merchant_grid_sec"
                    >
                      <div className="sep_inpt_sec ">
                        <p>
                          Is Active:<span>*</span>
                        </p>
                        <div className="inp_sep_div">
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue={
                                !data.is_active ? "InActive" : "Active"
                              }
                              name="row-radio-buttons-group"
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  is_active:
                                    e.target.value == "InActive" ? false : true,
                                });
                              }}
                            >
                              <Tooltip title="Only active merchants will be able to login into the mobile app and web app.">
                                <FormControlLabel
                                  disabled={enablemerchant}
                                  value="Active"
                                  control={<Radio />}
                                  label="Active"
                                />
                              </Tooltip>
                              <Tooltip title="Only active merchants will be able to login into the mobile app and web app.">
                                <FormControlLabel
                                  disabled={enablemerchant}
                                  value="InActive"
                                  control={<Radio />}
                                  label="InActive"
                                />
                              </Tooltip>

                              {/* <FormControlLabel disabled={true} value="Banned" control={<Radio />} label="Banned" /> */}
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="merchant_grid_sec"
                    >
                      <div className="sep_inpt_sec ">
                        <p>
                          Payment Status:<span>*</span>
                        </p>
                        <div className="inp_sep_div">
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue={
                                data?.is_payment_allowed
                                  ? "Payment Enable"
                                  : "Payment Disabled"
                              }
                              name="row-radio-buttons-group"
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  is_payment_allowed:
                                    e.target.value == "Payment Enable"
                                      ? true
                                      : false,
                                });
                              }}
                            >
                              <Tooltip title="Payment Disabled' means the user will be able to login into the app however would not be able to make a request for the money or receive any payments.">
                                <FormControlLabel
                                  onClick={() => {
                                    setcommentmandatory(true);
                                  }}
                                  disabled={enablemerchant}
                                  value="Payment Enable"
                                  control={<Radio />}
                                  label="Payment Enable"
                                />
                              </Tooltip>
                              <Tooltip title="Payment Disabled' means the user will be able to login into the app however would not be able to make a request for the money or receive any payments.">
                                <FormControlLabel
                                  onClick={() => {
                                    setcommentmandatory(true);
                                  }}
                                  disabled={enablemerchant}
                                  value="Payment Disabled"
                                  control={<Radio />}
                                  label="Payment Disabled"
                                />
                              </Tooltip>
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="merchant_grid_sec"
                    >
                      <Tooltip title={data.is_locked ? "" : ""}>
                        <div
                          className="sep_inpt_sec "
                          style={{ cursor: "pointer" }}
                        >
                          <p>Is Lock: *</p>

                          <div className="inp_sep_div">
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={
                                  !data.is_locked ? "Unlock" : "Lock"
                                }
                                name="row-radio-buttons-group"
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    is_locked:
                                      e.target.value == "Lock" ? true : false,
                                  });
                                }}
                              >
                                <Tooltip title="The account Lock means more than three incorrect OTP attempts were made. The account will be automatically unlocked after 24 hours or click here 'Unlock' to Lift restrictions.">
                                  <FormControlLabel
                                    disabled={enablemerchant}
                                    value="Lock"
                                    control={<Radio />}
                                    label="Lock"
                                  />
                                </Tooltip>{" "}
                                <Tooltip title="The account Lock means more than three incorrect OTP attempts were made. The account will be automatically unlocked after 24 hours or click here 'Unlock' to Lift restrictions.">
                                  <FormControlLabel
                                    disabled={enablemerchant}
                                    value="Unlock"
                                    control={<Radio />}
                                    label="Unlock"
                                  />
                                </Tooltip>{" "}
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="merchant_grid_sec"
                    >
                      <div className="sep_inpt_sec ">
                        <p>Comments</p>

                        <div className="inp_sep_div ">
                          <FormControl>
                            <CssTextArea
                              id="new-menu"
                              type="text"
                              disabled={enablemerchant}
                              className="create_merchant_input_feild blockcomments"
                              variant="outlined"
                              placeholder="Comments"
                              minRows={2}
                              sx={{
                                width: "67%",
                                mt: "1rem",
                                // borderRadius: "5px",
                                // borderColor: "#a7a7a7",

                                // fontSize: "14px",
                              }}
                              // fullWidth
                              value={data?.comments}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  comments: e.target.value,
                                })
                              }
                              // InputProps={{
                              //   startAdornment: (
                              //     <InputAdornment className="create_merchn_inpt_icon">
                              //       <IconButton color="primary">
                              //         <LocationOnOutlinedIcon className="merchant_inpt_icon" />
                              //       </IconButton>
                              //     </InputAdornment>
                              //   ),
                              // }}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          )}

          {!enablemerchant && !loading ? (
            <div className="merchant_edit_button_dev">
              <Button
                className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                variant="contained"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                variant="contained"
                onClick={handleUpdate}
              >
                Update
              </Button>
            </div>
          ) : (
            <></>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        className="expanded_bar"
        sx={{ mt: "20px" }}
      >
        <AccordionSummary
          className="merchant-kyc-detail-panel"
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography onClick={handleChange("panel3")}>KYC Details</Typography>
          <Button
            onClick={() => {
              approvalRequest();
            }}
            className="merchant_list_add_new_merchant branch_add_btn kycedit"
            variant="contained"
            startIcon={
              <img src="/approval-request.png" alt="request" width="25px" />
            }
            disabled={spinner}
          >
            {spinner ? (
              <i
                className="fa fa-spinner fa-spin spinner"
                aria-hidden="true"
                style={{ padding: "5px" }}
              >
                {" "}
              </i>
            ) : kycdata?.kycStatus == "Initiated" || !kycdata?.kycStatus ? (
              "Submit Kyc Request"
            ) : (
              "Update KYC"
            )}
          </Button>
        </AccordionSummary>
        {allPermission[Config.PermissionId.MerchantKyc] && (
          <AccordionDetails>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress />
              </div>
            ) : (
              <div>
                <KycDetails
                  merchantDocument={kycdata}
                  user={data}
                  showFrom="upload_doc"
                  rolePermission={
                    allPermission[Config.PermissionId.MerchantKyc]
                  }
                  onUploadDocument={onUploadDocument}
                />
              </div>
            )}
          </AccordionDetails>
        )}
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        className="expanded_bar"
        sx={{ mt: "20px" }}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography onClick={handleChange("panel4")}>Businesses</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BusinessTab currency={data?.currency_code} _id={cust_id} />
        </AccordionDetails>
      </Accordion>
      {allPermission[Config.PermissionId.Branch] && (
        <Accordion
          className="expanded_bar"
          expanded={expanded === "panel2"}
          sx={{ mt: "20px" }}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography onClick={handleChange("panel2")}>Branches</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Branches
              currency={data?.currency_code}
              branchdata={data?.branch}
              rolePermission={allPermission[Config.PermissionId.Branch]}
              allPermission={allPermission}
            />
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion
        className="expanded_bar"
        sx={{ mt: "20px" }}
        expanded={expanded === "panel5"}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography onClick={handleChange("panel5")}>
            Premium Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PremiumTabs rolePermission={rolePermission} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel6"}
        className="expanded_bar"
        sx={{ mt: "20px" }}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography onClick={handleChange("panel6")}>
            Cashbook Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CashBookDetails />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel7"}
        className="expanded_bar"
        sx={{ mt: "20px" }}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography onClick={handleChange("panel7")}>Settlement</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress />
            </div>
          ) : (
            <Settlement merchant_id={data?._id} settlementdtl={settlementdtl} />
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel8"}
        className="expanded_bar"
        sx={{ mt: "20px" }}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography onClick={handleChange("panel8")}>
            Local Configuration
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress />
            </div>
          ) : (
            <div className="merchant_list_main_div configuration_tab_div">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    aria-label="basic tabs example"
                  >
                    <Tab id="1" label="Settlement" {...a11yProps(0)} />
                    <Tab id="2" label="Fraud and Risk" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  {expanded == "panel8" && (
                    <SettlementConf
                      customerId={cust_id}
                      fromSource="merchant"
                      rolePermission={rolePermission}
                    />
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {expanded == "panel8" && (
                    <FraudAndRisk
                      rolePermission={rolePermission}
                      fromSource="merchant"
                      customerId={cust_id}
                    />
                  )}
                </TabPanel>
              </Box>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        className="expanded_bar"
        sx={{ mt: "20px" }}
        expanded={expanded === "panel9"}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography onClick={handleChange("panel9")}>
            Acquirer Management section
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MerchantAcquireTabMain
            value={data}
            redirecttodetail={redirecttodetail}
            getConfigDetail={getConfigDetail}
            customerId={cust_id}
          ></MerchantAcquireTabMain>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className="expanded_bar"
        sx={{ mt: "20px" }}
        expanded={expanded === "panel10"}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography onClick={handleChange("panel10")}>
            Merchant Panel Menus
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MerchantPanelMenus
            parent="MERCHANT_DETAILS"
            data={data}
            customerId={cust_id}
            rolePermission={rolePermission}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

function validateKYCDATA(kycdata) {
  var errormsg = "";
  if (kycdata) {
    if (kycdata?.dtlImgUrl != "" || kycdata?.emiratesImgUrl != "") {
      if (!kycdata?.tradeLicenseData) {
        errormsg = "Please enter valid Trade license data";
      } else if (
        !kycdata?.tradeLicenseData.licenseNumber ||
        kycdata?.tradeLicenseData.licenseNumber == ""
      ) {
        errormsg = "Please enter valid license number";
      } else if (
        !kycdata?.tradeLicenseData?.tradeName ||
        kycdata?.tradeLicenseData?.tradeName == ""
      ) {
        errormsg = "Please enter valid Trade Name";
      } else if (
        !kycdata?.tradeLicenseData.issueDate ||
        kycdata?.tradeLicenseData.issueDate == ""
      ) {
        errormsg = "Please enter Trade license valid Issue Date";
      } else if (
        !kycdata?.tradeLicenseData.endDate ||
        kycdata?.tradeLicenseData.endDate == ""
      ) {
        errormsg = "Please enter Trade license valid End Date";
      } else if (
        new Date(kycdata?.tradeLicenseData.endDate) -
          new Date(kycdata?.tradeLicenseData.issueDate) <
        0
      ) {
        errormsg = "issue date is greater than end date.";
      } else if (!kycdata?.emiratesData) {
        errormsg = "Please enter valid Emirates data";
      } else if (
        !kycdata?.emiratesData.idNumber ||
        kycdata?.emiratesData.idNumber == "" ||
        kycdata?.emiratesData.idNumber.length != 15
      ) {
        errormsg = "Please enter valid 15 characters Emirates id Number";
      } else if (
        !kycdata?.emiratesData.name ||
        kycdata?.emiratesData.name == ""
      ) {
        errormsg = "Please enter Emirates name";
      } else if (
        !kycdata?.emiratesData.expiryDate ||
        kycdata?.emiratesData.expiryDate == ""
      ) {
        errormsg = "Please enter valid Emirates expiryDate";
      }
    }
  }
  return errormsg;
}

export default MerchantDetails;
