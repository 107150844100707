import React from "react";
import { useState, useEffect } from "react";
import "./FeesAndMargin.css";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AxiosBase from "../../../networkRequest/axiosBase";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  updateMerchantFees,
  getMerchantFees,
} from "../../../networkRequest/request/configuration";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "5px",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: "8px",
  },
}));
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1058ff",
    },
  },
});
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const FeesAndMargin = (props) => {
  const { fromSource, customerId, rolePermission } = props;
  const [spinner, setSpinner] = useState(false);
  const [controlDisable, setControlDisable] = useState(false);
  const [editVisibility, setEditVisibility] = useState("hidden");
  const [saveCancelVisibility, setSaveCancelVisibility] = useState("visible");
  const [feesdata, setFeesData] = useState({
    _id: "",
    fixed_fees: 0,
    margin: 0,
    vat: 0,
    settlement_fees: 0,
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSpinner(true);
    if (fromSource == "merchant") {
      if (customerId !== "")
        getMerchantFees(customerId).then((response) => {
          setFeesData({
            fixed_fees: response?.data?.fixed_fees,
            margin: response?.data?.margin,
            vat: response?.data?.vat,
            settlement_fees: response?.data?.settlement_fees,
          });
          setSpinner(false);
        });
      else setSpinner(false);
    } else {
      AxiosBase.get(`/admin/app/config`).then((response) => {
        setFeesData({
          fixed_fees: response?.data?.fixed_fees,
          margin: response?.data?.margin,
          vat: response?.data?.vat,
          settlement_fees: response?.data?.settlement_fees,
        });
        setSpinner(false);
      });
    }
  }, []);
  const editFees = () => {
    setSaveCancelVisibility("visible");
    setEditVisibility("hidden");
    setControlDisable(false);
  };
  const cancelFees = () => {
    setSpinner(true);
    if (fromSource == "merchant") {
      if (customerId !== "")
        getMerchantFees(customerId).then((response) => {
          setFeesData({
            fixed_fees: response?.data?.fixed_fees,
            margin: response?.data?.margin,
            vat: response?.data?.vat,
            settlement_fees: response?.data?.settlement_fees,
          });
          setSpinner(false);
        });
      else {
        setSpinner(false);
      }
    } else {
      AxiosBase.get(`/admin/app/config`).then((response) => {
        setFeesData({
          fixed_fees: response?.data?.fixed_fees,
          margin: response?.data?.margin,
          vat: response?.data?.vat,
          settlement_fees: response?.data?.settlement_fees,
        });
        setSpinner(false);
      });
    }
  };
  const saveFees = async () => {
    try {
      feesdata._id = customerId;
      setSpinner(true);
      if (fromSource == "merchant") {
        var res = await updateMerchantFees(feesdata);
        if (res?.status == 200) {
          setSpinner(false);
          return toast("Fees Data Updated Successfully.", {
            type: "success",
            autoClose: 2000,
          });
        } else {
          setSpinner(false);
          return toast(JSON.stringify(res?.response?.data), {
            type: "error",
            autoClose: 2000,
          });
        }
      } else {
        AxiosBase.patch("/admin/app/config", feesdata).then((response) => {
          if (response?.status == 200) {
            if (
              response?.data?.message === "App Config Updated Successfully!"
            ) {
              setSpinner(false);
              return toast(response?.data?.message, {
                type: "success",
                autoClose: 2000,
              });
            }
          } else {
            setSpinner(false);
            return toast(JSON.stringify(response?.response?.data), {
              type: "error",
              autoClose: 2000,
            });
          }
        });
      }
    } catch (err) {
      setSpinner(false);
      return toast(JSON.stringify(err), {
        type: "error",
        autoClose: 5000,
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmReset = () => {
    setOpen(true);
  };

  const resetFees = async () => {
    try {
      feesdata._id = customerId;
      feesdata.margin = "";
      feesdata.fixed_fees = "";
      feesdata.vat = "";
      feesdata.settlement_fees = "";
      setFeesData({ ...feesdata });
      setSpinner(true);
      if (fromSource == "merchant") {
        var res = await updateMerchantFees(feesdata);
        setOpen(false);
        if (res?.status == 200) {
          setSpinner(false);
          return toast("Fees Data Reset Successfully.", {
            type: "success",
            autoClose: 2000,
          });
        } else {
          setSpinner(false);
          return toast(JSON.stringify(res?.response?.data), {
            type: "error",
            autoClose: 2000,
          });
        }
      }
    } catch (err) {
      setSpinner(false);
      return toast(JSON.stringify(err), {
        type: "error",
        autoClose: 5000,
      });
    }
  };
  return (
    <>
      {spinner ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="Fees_Margin_main_div">
          {fromSource !== "merchant" ? (
            <div className="Fees_Margin_title_div">
              <p>Fees & Margin</p>
            </div>
          ) : (
            <></>
          )}
          <div className="Fees_Margin_input_field">
            <p>Margin</p>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="gatway_second_grid_sec"
            >
              <CssTextField
                className="merchant_list_search_bar"
                value={feesdata.margin}
                disabled={controlDisable}
                name="margin"
                id="new-menu"
                placeholder="Margin"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  const value = e.target.value;
                  if (!value) {
                    setFeesData({ ...feesdata, margin: "" });
                    return false;
                  }
                  if (!isNaN(value)) {
                    const splitValue = value.split(".")[1];
                    if (splitValue?.length > 2) {
                      return false;
                    }
                    if (Number(value) >= 0 && Number(value) <= 100) {
                      setFeesData({ ...feesdata, margin: value });
                    }
                    return true;
                  }
                }}
              />
              <label style={{ "line-height": "2.2" }}> %</label>
              <label style={{ "line-height": "2.2" }}>(0 to 100)</label>
            </Grid>
          </div>
          <div className="Fees_Margin_input_field">
            <p>UL Fixed Fee</p>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="gatway_second_grid_sec"
            >
              <CssTextField
                className="merchant_list_search_bar"
                value={feesdata.fixed_fees}
                disabled={controlDisable}
                name="fixed_fees"
                id="new-menu"
                placeholder="Fixed Fees"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  const value = e.target.value;
                  if (!value) {
                    setFeesData({ ...feesdata, fixed_fees: "" });
                    return false;
                  }
                  if (!isNaN(value)) {
                    const splitValue = value.split(".")[1];
                    if (splitValue?.length > 2) {
                      return false;
                    }
                    setFeesData({ ...feesdata, fixed_fees: value });
                    return true;
                  }
                }}
              />
              <label style={{ "line-height": "2.2", "padding-left": "5px" }}>
                AED
              </label>
            </Grid>
          </div>

          <div className="Fees_Margin_Taxes_title_div">
            <p>Taxes</p>
          </div>
          <div className="Fees_Margin_input_field">
            <p>VAT</p>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="gatway_second_grid_sec"
            >
              <CssTextField
                className="merchant_list_search_bar"
                value={feesdata.vat}
                disabled={controlDisable}
                name="vat"
                id="new-menu"
                placeholder="vat"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  const value = e.target.value;
                  if (!value) {
                    setFeesData({ ...feesdata, vat: "" });
                    return false;
                  }
                  if (!isNaN(value)) {
                    const splitValue = value.split(".")[1];
                    if (splitValue?.length > 2) {
                      return false;
                    }
                    if (Number(value) >= 0 && Number(value) <= 100) {
                      setFeesData({ ...feesdata, vat: value });
                    }
                    return true;
                  }
                }}
              />
              <label style={{ "line-height": "2.2" }}> %</label>
              <label style={{ "line-height": "2.2" }}>(0 to 100)</label>
            </Grid>
          </div>
          <div className="Fees_Margin_input_field">
            <p>Settlement Fees</p>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="gatway_second_grid_sec"
            >
              <CssTextField
                className="merchant_list_search_bar"
                value={feesdata.settlement_fees}
                disabled={controlDisable}
                name="settlement_fees"
                id="new-menu"
                placeholder="settlement fees"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  const value = e.target.value;
                  if (!value) {
                    setFeesData({ ...feesdata, settlement_fees: "" });
                    return false;
                  }
                  if (!isNaN(value)) {
                    const splitValue = value.split(".")[1];
                    if (splitValue?.length > 2) {
                      return false;
                    }
                    if (Number(value) >= 0) {
                      setFeesData({ ...feesdata, settlement_fees: value });
                    }
                    return true;
                  }
                }}
              />
            </Grid>
          </div>
          <div className="merchant_edit_button_dev Fees_Margin_save_cancel_btn edit_button_set">
            <span className={saveCancelVisibility}>
              {fromSource == "merchant" ? (
                <Button
                  className="merchant_reset_btn merchant_detail_tab_save-cancel_btn"
                  style={{ "background-color": "#1058FF" }}
                  variant="contained"
                  disabled={
                    !feesdata.margin &&
                    !feesdata.fixed_fees &&
                    !feesdata.vat &&
                    !feesdata.settlement_fees
                  }
                  onClick={confirmReset}
                >
                  Reset
                </Button>
              ) : (
                <></>
              )}
              {rolePermission.includes("ADD") && (
                <Button
                  className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={saveFees}
                >
                  Save
                </Button>
              )}
            </span>
          </div>
        </div>
      )}
      <div>
        <BootstrapDialog
          className="order_detail_refund_popup"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Typography
              className="order_detail_refund_popup_title"
              gutterBottom
            >
              <p>Confirm Reset</p>
              <span>Are you sure want to reset configuration?</span>
              <div className="order_detail_refund_popup_btn">
                <Button
                  className="refund_save_btn"
                  variant="contained"
                  autoFocus
                  fullWidth
                  onClick={resetFees}
                >
                  Confirm
                </Button>
                <Button
                  className="refund_cancel_btn"
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  fullWidth
                >
                  Cancel
                </Button>
              </div>
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};
FeesAndMargin.propTypes = {
  fromSource: PropTypes.string,
  customerId: PropTypes.string,
};
export default FeesAndMargin;
