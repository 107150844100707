import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { Link, NavLink, useLocation } from "react-router-dom";
import { viewCustomerDetails } from "../../../../../../../networkRequest/request/customers";

function CustomerDetails() {
  const search = useLocation().search;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const customerId = new URLSearchParams(search).get("customerId");
    setLoading(true);
    viewCustomerDetails(customerId).then((data) => {
      setData(data);
      setLoading(false);
    });
  }, [search]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          backgroundColor: "#1455F5",
          marginBottom: "10px",
          paddingLeft: "15px",
          borderRadius: "15px",
        }}
      >
        <p style={{ color: "white", fontWeight: 800 }}>Customer Details</p>
      </div>
      <Paper>
        {loading ? (
          "loading ,,,,,,,,,"
        ) : (
          <div
            style={{ padding: "15px", display: "flex", alignItems: "center" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "0px 20px",
              }}
            >
              <img
                src="/yash1.jpg"
                alt="Profile Pic"
                style={{ borderRadius: "50%", width: "120px" }}
              />
              {console.log("Data::::::::::>", data)}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                width: "100%",
                padding: "20px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    margin: "0px 12px",
                    marginLeft: "0",
                  }}
                >
                  <div>
                    <p
                      style={{
                        color: "#9f9f9f",
                        fontSize: "14px",
                        marginTop: "0px",
                      }}
                    >
                      First Name:
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #9f9f9f",
                      padding: "8px",
                    }}
                  >
                    <img
                      src="/images/customer-profile/name.svg"
                      alt="firstname.svg"
                      width="18px"
                      height="18px"
                    />
                    <p
                      style={{
                        margin: "0",
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {data.firstName}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    margin: "0px 12px",
                  }}
                >
                  <div>
                    <p
                      style={{
                        color: "#9f9f9f",
                        fontSize: "14px",
                        marginTop: "0px",
                      }}
                    >
                      Last Name:
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #9f9f9f",
                      padding: "8px",
                    }}
                  >
                    <img
                      src="/images/customer-profile/name.svg"
                      alt="firstname.svg"
                      width="18px"
                      height="18px"
                    />
                    <p
                      style={{
                        margin: "0",
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {data.lastName}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    margin: "0px 12px",
                  }}
                >
                  <div>
                    <p
                      style={{
                        color: "#9f9f9f",
                        fontSize: "14px",
                        marginTop: "0px",
                      }}
                    >
                      Email:
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #9f9f9f",
                      padding: "8px",
                    }}
                  >
                    <img
                      src="/images/customer-profile/email.svg"
                      alt="firstname.svg"
                      width="18px"
                      height="18px"
                    />
                    <p
                      style={{
                        margin: "0",
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {data.email_id !== "Null" ? data.email_id : ""}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    margin: "0px 12px",
                  }}
                >
                  <div>
                    <p
                      style={{
                        color: "#9f9f9f",
                        fontSize: "14px",
                        marginTop: "0px",
                      }}
                    >
                      Phone Number:
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #9f9f9f",
                      padding: "8px",
                    }}
                  >
                    <img
                      src="/images/customer-profile/phoneNumber.svg"
                      alt="firstname.svg"
                      width="18px"
                      height="18px"
                    />
                    <p
                      style={{
                        margin: "0",
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {data.phoneNumber}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    margin: "0px 12px",
                    marginRight: "0",
                  }}
                >
                  <div>
                    <p
                      style={{
                        color: "#9f9f9f",
                        fontSize: "14px",
                        marginTop: "0px",
                      }}
                    >
                      Date of Birth:
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #9f9f9f",
                      padding: "8px",
                    }}
                  >
                    <img
                      src="/images/customer-profile/dateOfBirth.svg"
                      alt="firstname.svg"
                      width="18px"
                      height="18px"
                    />
                    <p
                      style={{
                        margin: "0",
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {data.dateOfBirth !== "Null" ? data.dateOfBirth : ""}
                    </p>
                  </div>
                </div>
              </div>
              <hr
                style={{
                  border: "1px solid #CCCCCC",
                  width: "100%",
                  margin: "18px 0px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "80%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    margin: "0px 12px",
                    marginLeft: "0",
                  }}
                >
                  <div>
                    <p
                      style={{
                        color: "#9f9f9f",
                        fontSize: "14px",
                        marginTop: "0px",
                      }}
                    >
                      Gender
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #9f9f9f",
                      padding: "8px",
                    }}
                  >
                    <img
                      src="/images/customer-profile/gender.svg"
                      alt="firstname.svg"
                      width="18px"
                      height="18px"
                    />
                    <p
                      style={{
                        margin: "0",
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {data.gender !== "Null" ? data.gender : ""}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    margin: "0px 12px",
                  }}
                >
                  <div>
                    <p
                      style={{
                        color: "#9f9f9f",
                        fontSize: "14px",
                        marginTop: "0px",
                      }}
                    >
                      Address:
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #9f9f9f",
                      padding: "8px",
                    }}
                  >
                    <img
                      src="/images/customer-profile/address.svg"
                      alt="firstname.svg"
                      width="18px"
                      height="18px"
                    />
                    <p
                      style={{
                        margin: "0",
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {data.address !== "Null" ? data.address : ""}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    margin: "0px 12px",
                  }}
                >
                  <div>
                    <p
                      style={{
                        color: "#9f9f9f",
                        fontSize: "14px",
                        marginTop: "0px",
                      }}
                    >
                      Is ID Verified:
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #9f9f9f",
                      padding: "8px",
                    }}
                  >
                    <img
                      src="/images/customer-profile/id.svg"
                      alt="firstname.svg"
                      width="18px"
                      height="18px"
                    />
                    <p
                      style={{
                        margin: "0",
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {data.isVerified === true ? "Yes" : "No"}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    margin: "0px 12px",
                    marginRight: "0",
                  }}
                >
                  <div>
                    <p
                      style={{
                        color: "#9f9f9f",
                        fontSize: "14px",
                        marginTop: "0px",
                      }}
                    >
                      National ID Number:
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #9f9f9f",
                      padding: "8px",
                    }}
                  >
                    <img
                      src="/images/customer-profile/id.svg"
                      alt="firstname.svg"
                      width="18px"
                      height="18px"
                    />
                    <p
                      style={{
                        margin: "0",
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {data.idNumber !== "Null" ? (
                        <Link
                          to={`/kyc/details?customerId=${new URLSearchParams(
                            search
                          ).get("customerId")}`}
                        >
                          {data.idNumber}
                        </Link>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
}

export default CustomerDetails;
