import AxiosBase from "../axiosBase";


export const getBankList = () => {
    return new Promise((resolve, reject) => {
        AxiosBase.get(`/admin/bank-list`).then((response) => {
            resolve(response);
        });
    })
}

export const getBranchBankList = (BranchId) => {
    return new Promise((resolve, reject) => {
        AxiosBase.get(`/admin/bank-account/${BranchId}/branch`).then((response) => {
            resolve(response);
        }).catch(error=>{
            resolve(error.response)
        })
    })
}

export const AddAccount = (reqbody) => {
    return new Promise((resolve, reject) => {
        AxiosBase.post(`/admin/bank-account`,reqbody).then((response) => {
            resolve(response);
        }).catch(error=>{
            resolve(error.response);
        })
    })
}

export const UpdateAccount = (BranchId,reqbody) => {
    return new Promise((resolve, reject) => {
        AxiosBase.put(`/admin/bank-account/${BranchId}`,reqbody).then((response) => {
            resolve(response);
        }).catch(error=>{
            resolve(error.response)
        })
    })
}