import React from "react";
import { useState, useEffect } from "react";
import "./ServicesMapping.css";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';



const ServicesMapping = (props) => {
    const { rolePermission, allStatusCodeMapping, id } = props;
    const [loader, setLoader] = React.useState(false);
    const [controlDisable, setControlDisable] = React.useState(false);
    const [allStatusData, setAllStatusData] = React.useState({});

    React.useEffect(() => {
        id && setControlDisable(true);
        setAllStatusData(allStatusCodeMapping);
    }, [id, allStatusCodeMapping])

    const SetCheckedStatus = (type,method,checked) =>{
     let data = allStatusData;
     data.services[type].methods[method].selected = checked
     setAllStatusData({...data})
    }

    return (
        <>
            {
                loader ?
                    <div className="no_data_found loader_data_tbl">
                        <CircularProgress />
                    </div> :
                    <div className="ServiceMappingMain">
                        <Grid className="ServiceMappingMainRow" container spacing={2}>
                            <Grid
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                            >
                                <TableContainer component={Paper} style={{ border: '1px solid #e0e0e0' }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', background: '#8175fc', color: 'white' }}>Wallets</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allStatusData?.services?.wallets?.methods && Object.keys(allStatusData?.services?.wallets?.methods).map(fact => {
                                                return <TableRow>
                                                    <TableCell align="left">
                                                        <FormGroup>
                                                            <FormControlLabel control={<Checkbox onChange={(e)=>{rolePermission.includes('EDIT') && SetCheckedStatus('wallets',fact,e.target.checked)}} checked={allStatusData?.services?.wallets?.methods[fact].selected} />} label={fact} />
                                                        </FormGroup>
                                                    </TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                            >
                                <TableContainer component={Paper} style={{ border: '1px solid #e0e0e0' }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', background: '#8175fc', color: 'white' }}>Banks</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allStatusData?.services?.banks?.methods && Object.keys(allStatusData?.services?.banks?.methods).map(fact => {
                                                return <TableRow>
                                                    <TableCell align="left">
                                                    <FormGroup>
                                                            <FormControlLabel  control={<Checkbox onChange={(e)=>{rolePermission.includes('EDIT') && SetCheckedStatus('banks',fact,e.target.checked)}}  checked={allStatusData?.services?.banks?.methods[fact].selected} />} label={fact} />
                                                        </FormGroup>
                                                    </TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </div>
            }
        </>
    );
};


export default ServicesMapping;
