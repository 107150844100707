import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MerchantDetails from "./MerchantDetails";
import { Box, Paper } from "@mui/material";
import MerchantHistory from "../merchant-history/MerchantHistory";
import "./MerchantDetails.css";
import NavbarHoc from "../../../pages/navhoc/index";
import Config from "../../../config.json";

function MerchantDetailsTabs() {
  const [value, setValue] = React.useState(0);
  const [rolePermission, setRolePermission] = React.useState([]);
  const [allPermission, setAllPermission] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <NavbarHoc
      id={Config.PermissionId.Merchants}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
        setAllPermission(e.AllData);
      }}
    >
      <Box>
        <h1 className="merchant_title">Merchants</h1>
      </Box>
      <div className="merchant_list_main_div merchant_detail_tab_sec">
        {/* <div className="add_merchant_list merchant_details_inner_title">
          <h3 className="merchant_list_inner_title">Merchant Details</h3>
        </div> */}
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Merchant Details" />
          {/* <Tab label="Order History" /> */}
        </Tabs>
        <div>
          {value === 0 ? (
            <MerchantDetails
              rolePermission={rolePermission}
              allPermission={allPermission}
            />
          ) : (
            <MerchantHistory />
          )}
        </div>
      </div>
    </NavbarHoc>
  );
}
{
  /* <History />; */
}
export default MerchantDetailsTabs;
