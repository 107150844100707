import React from "react";
import Topheader from "../../universal-components/header/topHeader";
import PremiumOrders from "../../Components/premium-orders/PremiumOrder";

function PremiumOrderRout() {
  return (
    <div style={{ padding: "15px" }}>
      <Topheader />
      <PremiumOrders />
    </div>
  );
}

export default PremiumOrderRout;
