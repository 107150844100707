import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import { unstable_styleFunctionSx } from "@mui/system";
import List from "@mui/material/List";
import { Box, Paper } from "@mui/material";
import { Container, Draggable } from "@edorivai/react-smooth-dnd";

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "4px",
  },
});

const ModulePermission = (props) => {
  const { rows, setRows } = props;
  const [dense, setDense] = React.useState(false);

  const handleClick = (event, name, index) => {
    let innerrow = JSON.parse(JSON.stringify(rows));
    let innerSelected = JSON.parse(JSON.stringify(rows));
    let innerindex = index;
    innerrow[innerindex].isSelected = !innerrow[innerindex].isSelected;
    if (!innerrow[innerindex].isSelected) {
      innerSelected = innerSelected.filter((fact) => {
        return fact != name._id;
      });
    } else {
      innerSelected.push(name._id);
      innerrow[index].sequence = index;
    }
    setRows([...innerrow]);
  };

  const drop = (dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) {
      return;
    }
    const element = rows.splice(removedIndex, 1)[0];
    rows.splice(addedIndex, 0, element);
    setRows([...rows]);
  };

  return (
    <List className="merchant_edit_drower_menu_body_sec">
      <Box>
        <TableContainer className="module_edit_drawer_tbl">
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <TableBody>
              <TableRow className="premium_module">
                <Div className="name_heading">Name</Div>
                <Div className="limit_heading">Limits</Div>
                <Div className="label_heading">Label</Div>
              </TableRow>
              <Container onDrop={(e) => drop(e)}>
                {rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <Draggable key={row._id}>
                      <TableRow
                        className="merchant_edit_drawer_tblBody_row"
                        hover
                        role="checkbox"
                        aria-checked={row.isSelected}
                        selected={row.selected}
                        key={index}
                      >
                        <label className="drag-section">::</label>
                        <Checkbox
                          onClick={(event) => handleClick(event, row, index)}
                          color="primary"
                          checked={row.isSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                        <Div className="premium_edit_drawer_tbl_list_box">
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.name}
                          </TableCell>
                        </Div>
                        {row.isSelected && (
                          <CssTextField
                            className="premium_limit_input"
                            id={"limit_" + index}
                            name={"limit_" + index}
                            placeholder="limit"
                            variant="outlined"
                            value={row.limit || "Unlimited"}
                            onChange={(e) => {
                              row.limit = e.target.value;
                              setRows(
                                Object.values({
                                  ...rows,
                                  [index]: {
                                    ...rows[index],
                                    limit: e.target.value,
                                  },
                                })
                              );
                            }}
                          />
                        )}
                        {row.isSelected && (
                          <CssTextField
                            className="premium_label_input"
                            id={"label_" + index}
                            name={"label_" + index}
                            placeholder="label"
                            variant="outlined"
                            value={row.label || row.name}
                            onChange={(e) => {
                              row.label = e.target.value;
                              setRows(
                                Object.values({
                                  ...rows,
                                  [index]: {
                                    ...rows[index],
                                    label: e.target.value,
                                  },
                                })
                              );
                            }}
                          />
                        )}
                      </TableRow>
                    </Draggable>
                  );
                })}
              </Container>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </List>
  );
};

export default ModulePermission;
