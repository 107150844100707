import React from "react";
import AxiosBase from "../../networkRequest/axiosBase";

function Fileupload() {
  const upload_url = "/universal/fileupload";

  const uploadFile = async (e) => {
    const formData = new FormData();
    formData.append("file_upload", e.target.files[0]);
    try {
      const res = await AxiosBase.post(upload_url, formData);
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <div>
      <input type="file" onChange={uploadFile} />
    </div>
  );
}

export default Fileupload;
