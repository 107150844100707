import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./AcquirerList.css";
import { useNavigate } from "react-router-dom";
import NavbarHoc from "../../pages/navhoc/index";
import Config from "../../config.json";
import { GetAcquirer } from "../../networkRequest/request/acquirer";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material";
import { unstable_styleFunctionSx } from "@mui/system";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    height: "8px",
  },
});

const AcquirerList = (props) => {
  const [rolePermission, setRolePermission] = React.useState([]);
  const [acquirerData, setAcquirerData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const history = useNavigate();

  const redirecttodetail = (_id) => {
    history(_id ? `acquirer?id=${_id}` : `acquirer`);
  };

  React.useEffect(() => {
    getAcq();
  }, []);

  const getAcq = async () => {
    let data = await GetAcquirer();
    data?.data?.data && setAcquirerData(data?.data?.data);
    setLoader(false);
  };

  return (
    <NavbarHoc
      id={Config.PermissionId.Acquirer}
      onGetReports={(e) => {
        setRolePermission(e.ParticularData);
      }}
    >
      <Box>
        <h1 className="merchant_title">Acquirer Management</h1>
      </Box>
      {loader ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress />
        </div>
      ) : (
        <div className="AcquireListMain">
          <Grid
            className="BaseAcquireList"
            container
            spacing={2}
            justifyContent="flex-end"
          >
            {rolePermission.includes("ADD") && (
              <Button
                startIcon={<AddCircleOutlineOutlinedIcon />}
                className="merchant_list_add_new_merchant"
                variant="contained"
                onClick={() => {
                  redirecttodetail("");
                }}
              >
                {" "}
                Add Acquirer{" "}
              </Button>
            )}
          </Grid>
          <TableContainer className="AcquireTableContainer">
            <Table
              stickyHeader
              aria-label="sticky table"
              className={
                acquirerData.length > 0 && !loader
                  ? "table_container"
                  : "table_container table_container_nodata"
              }
            >
              <TableHead className="data_tbl_head_row">
                <TableRow>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: "bold",
                      color: "#666666",
                      borderTop: "0.5px solid #c9c9c9",
                    }}
                  >
                    TYPE
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: "bold",
                      color: "#666666",
                      borderTop: "0.5px solid #c9c9c9",
                    }}
                  >
                    NAME
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: "bold",
                      color: "#666666",
                      borderTop: "0.5px solid #c9c9c9",
                    }}
                  >
                    CURRENCY
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: "bold",
                      color: "#666666",
                      borderTop: "0.5px solid #c9c9c9",
                    }}
                  >
                    PRIORITY
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: "bold",
                      color: "#666666",
                      borderTop: "0.5px solid #c9c9c9",
                    }}
                  >
                    SUBSCRIPTION
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: "bold",
                      color: "#666666",
                      borderTop: "0.5px solid #c9c9c9",
                    }}
                  >
                    EPP
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: "bold",
                      color: "#666666",
                      borderTop: "0.5px solid #c9c9c9",
                    }}
                  >
                    STATUS
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: "bold",
                      color: "#666666",
                      borderTop: "0.5px solid #c9c9c9",
                    }}
                  >
                    ACTIONS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row">
                {acquirerData.map((row, index) => (
                  <TableRow key={index} style={{ background: "white" }}>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.label}</TableCell>
                    <TableCell>{row.basecurrency}</TableCell>
                    <TableCell>{row.priority}</TableCell>
                    <TableCell>{row.subscription ? "Yes" : "No"}</TableCell>
                    <TableCell>{row.epp_payment ? "Yes" : "No"}</TableCell>
                    <TableCell>{row.status ? "Active" : "InActive"}</TableCell>
                    <TableCell>
                      <IconButton sx={{ borderRadius: "50%" }}>
                        <img
                          src="/editt.svg"
                          alt=""
                          style={{ height: "25px", width: "25px" }}
                          onClick={() => {
                            (rolePermission.includes("EDIT") ||
                              rolePermission.includes("VIEW")) &&
                              redirecttodetail(row._id);
                          }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </NavbarHoc>
  );
};

export default AcquirerList;
