import AxiosBase from "../axiosBase";

export const getBankList = (query = "") => {
  return AxiosBase.get(`/admin/bank${query}`);
};

export const getBankById = (id) => {
  return AxiosBase.get(`/admin/bank/${id}`);
};

export const addBank = (data) => {
  return AxiosBase.post("/admin/bank", data);
};

export const updateBank = (id, data) => {
  return AxiosBase.patch(`/admin/bank/${id}`, data);
};
