import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomerDetails from "./CustomerDetails/customerDetails";
import History from "./history/history";

function OverviewAndEvents() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Customer Details" />
        <Tab label="History" />
      </Tabs>
      <div style={{ padding: "15px" }}>
        {value === 0 ? <CustomerDetails /> : <History />}
      </div>
    </div>
  );
}

export default OverviewAndEvents;
