import React, { useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import Merchants from "../../page-components/merchants/";
import SuperMerchantList from "../../Components/merchants/merchant-list/SuperMerchantList";
import EditMerchant from "../../Components/merchants/edit-merchant/EditMerchant";
import AddMerchant from "../../Components/merchants/add-merchant/AddMerchant";
import IconButton from "@mui/material/IconButton";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Icon from "@mui/material/Icon";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import EditList from "../../page-components/merchants/Merchant_EditList/EditList";
import Topheader from "../../universal-components/header/topHeader";
// import CreateMerchant from '../../Components/SuperMerchant/CreateMerchant/CreateMerchant'
import CreateMerchants from "../../page-components/merchants/CreateMerchants/CreateMerchants";
import Supermerchant from "../../page-components/merchants/CreateMerchants/index";
import CreateMerchant from "../../Components/merchants/CreateMerchants/index";
function SuperMerchant() {
  return (
    <div style={{ padding: "15px", borderRadius: "5rem", width: "98%" }}>
      <Topheader />

      <Routes>
        <Route exact path="" element={<SuperMerchantList />} />
        <Route
          exact
          path="editmerchant/:id"
          element={<EditMerchant />}
        />
        <Route
          exact
          path="addmerchant"
          element={<AddMerchant />}
        />
        <Route
          exact
          path="addmerchant/:id"
          element={<AddMerchant />}
        />
        <Route exact path="settlement" element={<Merchants />} />
      </Routes>
    </div>
  );
}

export default SuperMerchant;
