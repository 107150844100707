import React from "react";
import { Route, Routes } from "react-router-dom";
import Settlements from "../../Components/settlements/settlement-details/Settlement-details";
import SettlementHistory from "../../Components/settlements/settlement-history/History";
import Topheader from "../../universal-components/header/topHeader";

function SettlementsPage() {
  return (
    <div style={{ padding: "15px" }}>
      <Topheader />
      <Routes>
        <Route exact path=":id" element={<Settlements />} />
        <Route path="" element={<SettlementHistory />} />
      </Routes>
    </div>
  );
}
export default SettlementsPage;
