import * as actionTypes from "../actions/action-types";
import { updateObject } from "../utility";

const initialState = {
  userData: null,
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  isTemporaryPassword: false,
  isTemporaryPasswordMsg: "",
  NavbarAccess: {},
  error: null,
  loading: false,
  sessionWarning: false,
  lockedOut: false,
};
const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const authSuccess = (state, action) => {
  let AccessObj = {};
  let data = action.payload.rolesData;
  for (let i = 0; i < data.length; i++) {
    let menuPermission = data[i].is_active ? data[i].menu_permissions : [];
    for (let j = 0; j < menuPermission.length; j++) {
      if (AccessObj[menuPermission[j].code]) {
        AccessObj[menuPermission[j].code].push(
          ...menuPermission[j].permissions
        );
        AccessObj[menuPermission[j].code] = [
          ...new Set(AccessObj[menuPermission[j].code]),
        ];
      } else {
        AccessObj[menuPermission[j].code] = menuPermission[j].permissions;
      }
    }
  }

  let newState = {
    userData: action.payload.userData,
    accessToken: action.payload.accessToken,
    refreshToken: action.payload.refreshToken,
    isAuthenticated: true,
    isTemporaryPassword: action.payload?.userData.is_temp_pass,
    isTemporaryPasswordMsg: action.payload?.userData.isTemporaryPasswordMsg,
    NavbarAccess: AccessObj,
    error: null,
    loading: false,
    sessionWarning: false,
    lockedOut: false,
  };
  localStorage.setItem("persist:root", JSON.stringify({ auth: newState }));
  return updateObject(state, newState);
};
const authRestart = (state, action) => {
  return updateObject(state, {
    userData: null,
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
    isTemporaryPassword: false,
    isTemporaryPasswordMsg: "",
    NavbarAccess: {},
    error: null,
    loading: false,
    sessionWarning: false,
    lockedOut: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error?.message
      ? action.error.message
      : "Wrong email or password!",
    loading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    userData: null,
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
    isTemporaryPassword: false,
    isTemporaryPasswordMsg: "",
    error: null,
    loading: false,
  });
};

const authUpdate = (state, action) => {
  return updateObject(state, {
    userData: { ...state.userData, ...action.userData },
  });
};
const authUpdateToken = (state, action) => {
  return updateObject(state, {
    accessToken: action.payload.accessToken,
    refreshToken: action.payload.refreshToken,
  });
};

const resetError = (state, action) => {
  return updateObject(state, {
    error: null,
  });
};

const sessionWarning = (state, action) => {
  return updateObject(state, {
    sessionWarning: action.payload.value,
  });
};

const lockedOut = (state, action) => {
  return updateObject(state, {
    lockedOut: action.payload.value,
  });
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_RESTART:
      return authRestart(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_UPDATE:
      return authUpdate(state, action);
    case actionTypes.RESET_ERROR:
      return resetError(state, action);
    case actionTypes.AUTH_UPDATE_TOKEN:
      return authUpdateToken(state, action);
    case actionTypes.SESSION_WARNING:
      return sessionWarning(state, action);
    case actionTypes.LOCKED_OUT:
      return lockedOut(state, action);
    default:
      return state;
  }
};
export default authReducer;
