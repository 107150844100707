import React from "react";
import Button from "@mui/material/Button";
import { Box, Paper } from "@mui/material";
import List from "@mui/material/List";
import { useLocation } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import ListItem from "@mui/material/ListItem";
import PhoneInput from "react-phone-input-2";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { PhoneNumberUtil } from "google-libphonenumber";
import { toast } from "react-toastify";
import Terminal from "./Terminal";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import {
  getAllBranchList,
  addBranch,
  addTerminal,
  getBranch,
} from "../../../networkRequest/request/branch";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});

const AddBranch = (props) => {
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get("merchantId");
  const [allInnerList, setAllInnerList] = React.useState([]);
  const [loaderFlag, setLoaderFlag] = React.useState(false);
  React.useEffect(() => {
    getBranchData();
  }, []);

  const getBranchData = async () => {
    let AllInnerList = await getBranch(true, customerId, null);
    setAllInnerList(AllInnerList.data.data);
  };

  const CheckBranck = (variable) => {
    return allInnerList.filter((fact) => {
      return fact.branch_name == variable;
    });
  };
  const success = async (anchor) => {
    if (inputData.branch_name.trim() && inputData.email_id.trim()) {
      let NumberFlag = false;

      if (inputData.mobile_no.length > 5) {
        let phoneUtil = PhoneNumberUtil.getInstance();
        NumberFlag = phoneUtil.isValidNumber(
          phoneUtil.parse(inputData.mobile_no)
        );
        if (!NumberFlag)
          return toast("Invalid Mobile Number", {
            type: "warning",
            autoClose: 2000,
          });
      }
      if (inputData.email_id) {
        let Setflag = inputData.email_id.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        if (!Setflag)
          return toast("Email ID is invalid", {
            type: "warning",
            autoClose: 2000,
          });
      }
      let innerres = await CheckBranck(inputData.branch_name);
      if (innerres.length == 0) {
        setLoaderFlag(true);
        let addBranchBody = {
          branch_name: inputData.branch_name.trim(),
          email_id: inputData.email_id.trim(),
          mobile_no:
            inputData.mobile_no == "+" + inputData.mobile_code
              ? ""
              : inputData.mobile_no.replace(/[^0-9]/gi, ""),
          address: inputData.address,
          zipCode: inputData.zipCode,
          mobile_code: inputData.mobile_code,
        };
        let addTerminalBody = [];
        let branchSuccess = await addBranch(customerId, addBranchBody);
        setLoaderFlag(false);
        if (branchSuccess?.status !== 400) {
          setState({ ...state, [anchor]: false });
          let AllList = await getBranch(true, customerId, null);
          props.outCall(AllList.data.data);
          return toast("Branch added successfully!", {
            type: "success",
            autoClose: 2000,
          });
        } else {
          return toast(branchSuccess?.data?.message, {
            type: "warning",
            autoClose: 2000,
          });
        }
      } else {
        return toast("Branch Name Must Be Unique", {
          type: "warning",
          autoClose: 2000,
        });
      }
    } else {
      return toast(
        !inputData.branch_name.trim()
          ? "Please Fill Branch Name"
          : "Please Fill Email",
        {
          type: "warning",
          autoClose: 2000,
        }
      );
    }
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const [inputData, setInputData] = React.useState({
    branch_name: "",
    email_id: "",
    mobile_no: "",
    address: "",
    zipCode: "",
    mobile_code: "",
  });

  // const [addterminalData, setAddTerminalData] = React.useState([]);
  const toggleDrawer = (anchor, open) => (event) => {
    setInputData({
      branch_name: "",
      email_id: "",
      mobile_no: "",
      address: "",
      zipCode: "",
      mobile_code: "",
    });
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 600,
        overflowX: "hidden",
      }}
      role="presentation"
    >
      {loaderFlag ? (
        <div className="center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <List className="merchant_edit_drower_menu_top">
            <ListItem className="merchant_edit_drower_menu_top_sec">
              <p>Add Branch</p>
            </ListItem>
          </List>
          <List>
            <div className="edit_branch_inpt_div">
              <p>
                <IconButton color="primary">
                  <img
                    style={{ height: "15px", width: "25px" }}
                    src="/user-icon.svg"
                    alt=""
                  />
                </IconButton>
                Branch Name <span style={{ color: "red" }}>*</span>
              </p>
              <CssTextField
                name="branch_name"
                className="merchant_edit_drower_serch_field"
                id="new-menu"
                placeholder="Branch Name"
                variant="outlined"
                required
                value={inputData.branch_name}
                helperText={`${inputData.branch_name.length}/${100}`}
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    branch_name: e.target.value,
                  });
                }}
                inputProps={{
                  maxlength: 100,
                }}
              />
            </div>
            <div className="edit_branch_inpt_div">
              <p>
                <IconButton color="primary">
                  <img
                    style={{ height: "15px", width: "25px" }}
                    src={`${process.env.PUBLIC_URL}/top-email.svg`}
                    alt=""
                  />
                </IconButton>
                Email ID <span style={{ color: "red" }}>*</span>
              </p>
              <CssTextField
                name="email"
                className="merchant_edit_drower_serch_field"
                id="new-menu"
                placeholder="Email"
                variant="outlined"
                required
                value={inputData.email_id}
                error={
                  !inputData.email_id.match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ) && inputData.email_id !== ""
                }
                helperText={
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    inputData.email_id
                  ) || inputData.email_id === ""
                    ? "can accept up to 30 Alphanumeric characters "
                    : "email must be between 6 to 30 characters"
                }
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    email_id: e.target.value,
                  });
                }}
                inputProps={{
                  maxlength: 30,
                }}
              />
            </div>
            <div className="edit_branch_inpt_div">
              <p>
                <IconButton color="primary">
                  <img
                    style={{ height: "15px", width: "25px" }}
                    src="/contact.svg"
                    alt=""
                  />
                </IconButton>
                Phone Number
              </p>
              {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={top100Films}
              placeholder="Enter Phone Number"
              fullWidth
              name="phone"
              type="number"
              required
              helperText={`${inputData.phone.length}/${100}`}
              value={inputData.phone}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  phone: e.target.value,
                });
              }}
              inputProps={{
                maxlength: 10,
              }}
              className="merchant_edit_drower_serch_field"
              renderInput={(params) => <TextField {...params}  />}
            /> */}
              {/* <CssTextField
            name="phone"
            type="number"
            className="merchant_edit_drower_serch_field"
            id="new-menu"
            placeholder="Enter Phone Number"
            variant="outlined"
            required
            helperText={`${inputData.mobile_no.length}/${100}`}
            value={inputData.mobile_no}
            onChange={(e) => {
              setInputData({
                ...inputData,
                mobile_no: e.target.value,
              });
            }}
            inputProps={{
              maxlength: 10,
            }}
          /> */}

              <PhoneInput
                className="mobile-input branch-mobile-input"
                name="phone"
                country={"ae"}
                countryCodeEditable={true}
                placeholder="Enter Phone Number"
                required
                value={inputData.mobile_no}
                onChange={(e, countryCode) => {
                  setInputData({
                    ...inputData,
                    mobile_code: countryCode.dialCode,
                    mobile_no: e,
                  });
                }}
              />
            </div>
            <div className="edit_branch_inpt_div">
              <p>
                <IconButton color="primary">
                  <img
                    style={{ height: "25px", width: "25px" }}
                    src="/location-icon-feild.svg"
                    alt=""
                  />
                </IconButton>
                Address
              </p>
              <CssTextField
                name="address"
                className="merchant_edit_drower_serch_field"
                id="new-menu"
                placeholder="Address"
                variant="outlined"
                required
                value={inputData.address}
                inputProps={{
                  maxlength: 100,
                }}
                helperText={`${inputData.address.length}/${100}`}
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    address: e.target.value,
                  });
                }}
              />
            </div>

            <div className="edit_branch_inpt_div">
              <p>
                <IconButton color="primary">
                  <img
                    style={{ height: "25px", width: "25px" }}
                    src="/location-icon-feild.svg"
                    alt=""
                  />
                </IconButton>
                Zipcode
              </p>
              <CssTextField
                name="zipcode"
                className="merchant_edit_drower_serch_field"
                id="new-menu"
                placeholder="Zip Code"
                variant="outlined"
                value={inputData.zipCode}
                required
                helperText={`${inputData.zipCode.length}/${6}`}
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    zipCode: e.target.value.replace(/[^0-9]/gi, ""),
                  });
                }}
                inputProps={{
                  maxlength: 6,
                }}
              />
            </div>
            <div className="merchant_edit_drower_serch_field edit_branch_drawer_devider"></div>
          </List>
          <div className="">
            {/* <Terminal AddBranch = {false} BranchId = {inputData?._id} UpdateTerminalData = {(terminal)=>{setAddTerminalData(terminal)}} /> */}
          </div>
          <div className="merchant_edit_drawer_btn_sec edit_branch_drawer_btn">
            <Button
              className="drawer_merchant_cancel_btn"
              variant="contained"
              onClick={toggleDrawer(anchor, false)}
            >
              Cancel
            </Button>

            <Button
              className="drawer_merchant_save_btn"
              variant="contained"
              onClick={() => success(anchor)}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </Box>
  );

  return (
    <>
      <div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button
              className={props.className}
              onClick={toggleDrawer(anchor, true)}
              variant="contained"
              startIcon={<img src="/edit-icon-white.svg" alt="" />}
            >
              {props.btnName}
            </Button>
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default AddBranch;
