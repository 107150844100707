import { Route, Routes } from "react-router-dom";
import Topheader from "../../universal-components/header/topHeader";
import CreateMerchant from "../../Components/merchant/CreateMerchants/index";
import CustomerFees from "../../page-components/customers/customerFees";
import MerchantList from "../../Components/merchant/merchant-list/MerchantList";
import MerchantDetailsTabs from "../../Components/merchant/merchant-details/MerchantDetailsTabs";
function Merchant() {
  return (
    <div style={{ padding: "15px", borderRadius: "5rem", width: "98%" }}>
      <Topheader />
      <Routes>
        <Route exact path="" element={<MerchantList />} />
        <Route
          exact
          path="createmerchant"
          element={<CreateMerchant />}
        />
        <Route path="profile" element={<MerchantDetailsTabs />} />
        <Route path="fees" element={<CustomerFees />} />
      </Routes>
    </div>
  );
}
export default Merchant;
