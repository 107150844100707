import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

const SectionB = (props) => {
  let filterArr = [];
  props.data.transactionData.map(item => {
    let ind = filterArr.findIndex(x => x.paymentMethod == item.type);
    if(ind == -1){
      filterArr.push({
        paymentMethod: item.type,
        count: 1,
        total_settlement_amount: item.settlement_amount,
        total_settlement_commission_Amount: item.settlement_commission_Amount,
        total_actual_amount: item.amount,
        charges: item.margin_amount + item.vatAmount,
      })
    } else {
      filterArr[ind].count += 1;
      filterArr[ind].total_settlement_amount += item.settlement_amount;
      filterArr[ind].total_settlement_commission_Amount += item.settlement_commission_Amount;
      filterArr[ind].total_actual_amount += item.amount;
      filterArr[ind].charges += (item.margin_amount + item.vatAmount);
    }
  });
  return (
    <>
      <div className="inner_pdf_sec">
        <h3 className="Settlement_breakdown_heading">SECTION B</h3>
        
        <div className="Settlement_pdf_sectionD_chargeback">
          <Button className="order_lst_btn">Processed amounts</Button>
          {/* <p>total amount processed.</p> */}
        </div>
        <div className="settlement_view_pdf_tbl">
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row_pdf settlement_view_pdf_tbl_head">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Processing Currency</TableCell>
                  <TableCell>Payment Type</TableCell>
                  <TableCell>Number of Transactions</TableCell>
                  <TableCell>Amount in Processing Currency</TableCell>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>Total Charges</TableCell>
                  <TableCell>Adjustment</TableCell>
                  <TableCell>Net Amount Payable</TableCell>
                </TableRow>
              </TableHead>
              {filterArr.map((item) => (
                <TableBody className="data_tbl_body_row_pdf ">
                  <TableCell>{props.data?.currency}</TableCell>
                  <TableCell style={{"overflow-wrap": "break-word"}}>{item.paymentMethod}</TableCell>
                  <TableCell>{item.count}</TableCell>
                  <TableCell>{Number(item?.total_actual_amount).toFixed(2)}</TableCell>
                  <TableCell>{Number(item?.total_actual_amount).toFixed(2)}</TableCell>
                  <TableCell>{Number(item?.charges).toFixed(2)}</TableCell>
                  <TableCell>NA</TableCell>
                  <TableCell>{item?.total_actual_amount - item?.charges}</TableCell>
                </TableBody>   
              ))}
            </Table>
          </TableContainer>
        </div>
      </div>
      <div class="footer-img" style={{"position": "relative"}}>
        <div style={{"width": "100%"}}>
          <img src="/images/Urban Ledger Export PDF-07.jpg" style={{"height": "90px", "width": "100%"}} alt="img" />
        </div>
        <div style={{"width": "45%","position": "absolute", "bottom": "30%", "left": "6%","z-index":"999"}}>
              <b style={{"color":" #fff"}}><i class="fa fa-phone-square" aria-hidden="true"></i> Helpline: </b>
              <a target="_blank" href={props.contactLink
            } style={{"color": "#fff","text-decoration": "none"}}>{props.contactLink}</a><br/>
              <span style={{"color": "#fff", "font-size": "12px"}}>T & C apply.</span>
        </div>
        <div style={{"width": "40%","position": "absolute", "bottom": "37%", "right": "6%","text-align": "right"}}>
            <span style={{"color": "white"}}>Use Urban Ledger Now</span>
            <a target="_blank" href={props.appLink
          } style={{"margin-left": "5px", "color": "#726f7a","background-color": "white","border-radius": "4px","padding": "3px 10px","text-decoration": "none"}}>Install <img style={{"width": "13px"}} src="/images/Install-01.svg" alt="img" /></a>
        </div>
      </div>
    </>
  );
};

export default SectionB;
