import React from 'react'
import CreateMerchant from './CreateMerchants'

const SuperMerchant = () => {
    return (
        <div>
        <div>
            <p className='merchant_title'>Merchant</p>
        </div>
        <CreateMerchant/>
            
        </div>
    )
}

export default SuperMerchant
